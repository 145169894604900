<template>
  <AgDataTable
    :url="url"
    :columns="columns"
    :actions="actions"
    :url-params="urlParams"
    :table-filters="true"
    :transform-data="mapData"
    :get-empty-row="getEmptyEntry"
    :add-text="$t('New entry')"
    :rowDragManaged="true"
    :animateRows="true"
    :compact="true"
    :sortable="false"
    :pagination="false"
    :read-only="readOnly"
    domLayout="autoHeight"
    ref="gridTable"
    id="gridTable"
    @rowDragEnd="onRowDragEnd"
    @grid-ready="grid = $event"
  >
    <template #extra-actions="{ row }">
      <TableDeleteButton
        v-if="$isAuthorized('authorizedToDelete', row)"
        @click="onDeleteEntry(row)"
      />
    </template>
  </AgDataTable>
</template>
<script lang="ts" setup>
  import { computed, ref, watch } from 'vue'
  import { rateTypes } from '@/modules/payroll/components/rates/util'
  import i18n from "@/i18n";
  import { EmployeeDirectDeposit } from "@/modules/common/types/models";
  import { globalResources } from "@/components/form/util";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { Column } from "@/components/ag-grid/tableTypes";
  import { findGlobalResource, findOptionLabel } from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import { getTableData, storeEntriesProgress } from "@/components/ag-grid/tableUtils";
  import { cellClasses, getCellClasses } from "@/components/ag-grid/columnUtils";
  import Data = API.Data;
  import {
    CellClassParams,
    ICellEditorParams,
    SuppressKeyboardEventParams,
    ValueFormatterParams
  } from '@ag-grid-community/core';
  import { formatPercent } from "@/plugins/formatPercent";
  import { formatPrice } from "@/plugins/formatPrice";
  import { routingNumberValidator } from "@/modules/common/util/validators";
  import { warning } from '@/components/common/NotificationPlugin'
  import { DirectDepositAccountTypes, DirectDepositStatuses } from '@/modules/payroll/components/direct-deposit/directDepositUtil'
  import sumBy from "lodash/sumBy";
import axios from 'axios';
import { $confirm } from "@/components/common/modal/modalPlugin";
import store from '@/store'

  const props = defineProps({
    employeeId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  })

  const statusOptions = [
    {
      label: i18n.t('Active'),
      value: DirectDepositStatuses.ACTIVE,
    },
    {
      label: i18n.t('Inactive'),
      value: DirectDepositStatuses.INACTIVE,
    },
    {
      label: i18n.t('Pre Notice'),
      value: DirectDepositStatuses.PRE_NOTICE,
    },
  ]

  const accountTypeOptions = [
    {
      label: i18n.t('Checking'),
      value: DirectDepositAccountTypes.CHECKING,
    },
    {
      label: i18n.t('Savings'),
      value: DirectDepositAccountTypes.SAVINGS,
    },
  ]

  const calculateByOptions = [
    {
      label: i18n.t('$'),
      value: rateTypes.AMOUNT,
    },
    {
      label: i18n.t('%'),
      value: rateTypes.PERCENT,
    },
  ]

  const actions = computed(() => {
    if (props.readOnly) {
      return
    }
    return 'add,refresh,search'
  })

  const directDepositCodes = computed(() => {
    return store.getters['globalLists/getResourceList'](globalResources.DirectDepositCodes) || []
  })

  watch(() => directDepositCodes.value.length, () => {
    grid.value.api?.refreshCells()
  })

  const columns = computed<Column[]>(() => {
    return [
      {
        headerName: i18n.t('Priority'),
        field: 'order',
        minWidth: 70,
        maxWidth: 70,
        rowDrag: true,
      },
      {
        headerName: i18n.t('Status'),
        field: 'status',
        minWidth: 60,
        editable: true,
        valueFormatter: (params: ValueFormatterParams) => findOptionLabel(statusOptions, params.value),
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: statusOptions,
        },
      },
      {
        headerName: i18n.t('DD Code'),
        field: 'direct_deposit_code_id',
        editable: true,
        valueFormatter: (params: ValueFormatterParams) => {
          const option = findGlobalResource(globalResources.DirectDepositCodes, params.value, 'id')
          return option?.description ? `${option.code} (${option.description})` : option.code
        },
        cellEditor: cellEditors.GlobalResourceSelect,
        cellEditorParams: (params: ICellEditorParams) => {
          const resourceName = globalResources.DirectDepositCodes
          return {
            resourceName,
            filterMethod: (option: Data<EmployeeDirectDeposit>) => filterDirectDepositCodes(option, params),
          }
        },
        cellClass: getCellClasses,
        minWidth: 100,
      },
      {
        headerName: i18n.t('Account Type'),
        field: 'account_type',
        width: 70,
        valueFormatter: (params: ValueFormatterParams) => findOptionLabel(accountTypeOptions, params.value),
        editable: true,
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: accountTypeOptions,
        },
        cellClass: getCellClasses,
      },
      {
        headerName: i18n.t('Transit Routing'),
        field: 'routing_number',
        width: 70,
        editable: true,
        cellClass: (params) => {
          if (props.readOnly) {
            return ''
          }
          if (!params.value || !routingNumberValidator(params.value)) {
            return cellClasses.Invalid
          }
          return ''
        },
      },
      {
        headerName: i18n.t('Account'),
        field: 'account_number',
        width: 70,
        editable: true,
        cellEditor: cellEditors.Numeric,
        cellClass: getCellClasses,
      },
      {
        headerName: i18n.t('Calc By'),
        field: 'calculate_by',
        valueFormatter: (params: ValueFormatterParams) => findOptionLabel(calculateByOptions, params.value),
        editable: true,
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: calculateByOptions,
        },
        cellClass: getCellClasses,
      },
      {
        headerName: i18n.t('Amount Or Percent'),
        field: 'amount',
        minWidth: 70,
        editable: true,
        cellEditor: cellEditors.Numeric,
        valueFormatter: (params: ValueFormatterParams) => {
          const {calculate_by} = params.data
          if (calculate_by === rateTypes.PERCENT) {
            return formatPercent(params.value)
          }
          return formatPrice(params.value)
        },
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          let isTabKey = params.event.key === 'Tab'
          if (isTabKey) {
            params.api.stopEditing()
          }
        },
        cellClass: (params: CellClassParams) => {
          let classes = 'flex w-full justify-end'

          if (params.data.calculate_by === rateTypes.AMOUNT) {
            return classes
          }

          if (params.data.amount > 100) {
            classes = classes + ' ' + cellClasses.Invalid
            warning(i18n.t('Percent cannot be greater than 100%.'))
          }

          return classes
        },
      },
    ]
  })

  const url = computed(() => {
    return props.employeeId ? '/restify/employee-direct-deposits' : ''
  })

  const urlParams = computed(() => {
    return !props.employeeId ? {} : {
      employee_id: props.employeeId,
      sort: 'order',
    }
  })

  const loading = ref(false)
  const grid = ref()
  const entries = ref<EmployeeDirectDeposit[]>([])

  const attachedDirectDepositCodeIds = computed(() => {
    const tableData = getTableData(grid.value.api);
    return tableData.map((el: EmployeeDirectDeposit) => el.direct_deposit_code_id);
  });

  function filterDirectDepositCodes(option: Data<EmployeeDirectDeposit>, params: ICellEditorParams) {
    const codeId = params.data?.direct_deposit_code_id
    // @ts-ignore
    return codeId === option.id || !attachedDirectDepositCodeIds.value.includes(option.id)
  }

  function getEmptyEntry(): EmployeeDirectDeposit {
    const entries = getTableData(grid.value.api)
    const percentageEntries = entries.filter(entry => entry.calculate_by === rateTypes.PERCENT)
    const getTotalPercentage = sumBy(percentageEntries, 'amount')
    let amount
    if (getTotalPercentage >= 100) {
      amount = 0
    } else {
      amount = 100 - getTotalPercentage
    }
    return {
      _localId: crypto.randomUUID(),
      order: entries.length + 1,
      employee_id: props.employeeId,
      direct_deposit_code_id: '',
      status: DirectDepositStatuses.ACTIVE,
      account_type: DirectDepositAccountTypes.CHECKING,
      routing_number: '',
      account_number: '',
      calculate_by: rateTypes.PERCENT,
      amount,
    }
  }

  function mapData(data: Data<EmployeeDirectDeposit>[]) {
    entries.value = data.map(el => el.attributes)
    return entries.value
  }

  async function updateOrder() {
    grid.value?.api.forEachNode((node: any) => {
      node.data.order = node.rowIndex + 1
      node.data.dirty = true
      node.setData(node.data)
    })

    await storeProgress()
  }

  function onRowDragEnd() {
    updateOrder()
  }

  const gridTable = ref()

  function refresh() {
    gridTable.value?.refresh()
  }

  async function onDeleteEntry(entry: EmployeeDirectDeposit) {
    if (!entry.id) {
      grid.value.api.applyTransaction({
        remove: [entry],
      })

      return
    }

    const confirmed = await $confirm({
      title: i18n.t('Delete entry'),
      description: i18n.t('Are you sure you want to delete this entry?'),
      type: 'danger',
    })

    if (!confirmed) {
      return
    }

    const deleteUrl = `${url.value}/${entry.id}`
    await axios.delete(deleteUrl)

    grid.value.api.applyTransaction({
      remove: [entry],
    })

    updateOrder()
  }

  async function storeProgress() {
    entries.value = getTableData(grid.value.api)
    await storeEntriesProgress(entries.value, url.value)
  }

  defineExpose({
    storeProgress,
    refresh,
  })
</script>
