<template>
  <InvoiceDetailsPage
      :data="billing"
      :loading="billingLoading"
      :hide-attachments="true"
      :hide-history="true"
      entity="billings"
      class="shared-invoice"
  >
    <template #title-right>
      <div class="flex items-center">
        <span class="text-base print:text-print leading-6 font-semibold text-gray-900 mr-2">
            {{ $t('Current Payment Due:') }}
          </span>
        <div class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 print:px-1 print:text-print mr-3 tracking-wide">
          {{ $formatPrice(totals.amount_no_retention) }}
        </div>
      </div>
    </template>
    <template #header-left>
      <div class="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
        <CompanyContactInfo
            :title="$t('From')"
            :company="company"
            :show-details-on-hover="false"
        />
        <CustomerContactInfo
            :title="$t('Invoice For')"
            :customer="customer"
            :show-details-on-hover="false"
        />
      </div>
    </template>
    <template #header-right>
      <div v-if="billing.attributes">
        <InvoiceHeaderRow
            :label="$t('Invoice No: ')"
            :value="billing.attributes.number"
        />
        <InvoiceHeaderRow
            :label="$t('Invoice Date: ')"
            :value="$formatDate(billing.attributes.date)"
        />
        <InvoiceHeaderRow class="my-2"/>
        <InvoiceHeaderRow
            v-if="billing.attributes.application_number"
            :label="$t('Application Number: ')"
            :value="billing.attributes.application_number || ''"
        />
        <InvoiceHeaderRow
            v-if="billing.attributes.period_to"
            :label="$t('Completed Thru: ')"
            :value="$formatDate(billing.attributes.period_to)"
        />
        <InvoiceHeaderRow
            v-if="billing.attributes.architect"
            :label="$t('Owner Job No: ')"
            :value="billing.attributes.architect || ''"
        />
        <InvoiceHeaderRow class="my-2"/>
        <InvoiceHeaderRow v-if="job.attributes" :label="$t('Contract: ')">
          {{ `${job.attributes.number} (${job.attributes.description})` }}
        </InvoiceHeaderRow>
      </div>
    </template>
    <template #content>
      <PublicProgressBillingEntries :data="entries" :loading="billingLoading"/>
    </template>
    <template #totals>
      <dl v-if="billing.attributes" class="col-span-3 md:col-span-1 mb-8">
        <div class="totals-row">
          <span>{{ $t('Scheduled of Values:') }}</span>
          <span class="text-sm">{{ $formatPrice(totalsWithoutChangeOrders.budget) }}</span>
        </div>
        <div class="totals-row">
          <span>{{ $t('Plus Change Orders:') }}</span>
          <span class="text-sm">{{ $formatPrice(totalsWithChangeOrders.budget) }}</span>
        </div>
        <div class="totals-row my-2">
          <span>{{ $t('To-Date Scheduled of Values:') }}</span>
          <span class="text-sm">{{ $formatPrice(totals.budget) }}</span>
        </div>
        <div class="totals-row">
          <span>{{ $t('Total Completed To-Date:') }}</span>
          <span class="text-sm">{{ $formatPrice(totals.total_completed) }}</span>
        </div>
        <div class="totals-row">
          <span>{{ $t('Less Retainage: ') }}</span>
          <span class="text-sm">{{ $formatPrice(totals.retention) }}</span>
        </div>

        <div class="totals-row my-2">
          <span></span>
          <span class="text-sm">{{ $formatPrice(totals.total_completed - totals.retention) }}</span>
        </div>

        <div class="totals-row">
          <span>{{ $t('Less Previous Billing: ') }}</span>
          <span class="text-sm">{{ $formatPrice(totals.completed) }}</span>
        </div>

        <div class="totals-row summary bg-gray-100 rounded-sm">
          <span>{{ $t('Current Payment Due:') }}</span>
          <span>{{ $formatPrice(totals.amount_no_retention) }}</span>
        </div>
      </dl>
    </template>
  </InvoiceDetailsPage>
</template>
<script>
  import axios from "axios";
  import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
  import BillingDetails from "@/modules/accounts-receivable/pages/billings/BillingDetails";
  import CompanyContactInfo from "@/modules/accounts-payable/components/invoice/CompanyContactInfo";
  import CustomerContactInfo from "@/modules/accounts-receivable/components/CustomerContactInfo";
  import InvoiceDetailsPage from "@/components/invoice/InvoiceDetailsPage";
  import PublicProgressBillingEntries from "@/modules/public/components/PublicProgressBillingEntries";
  import sortBy from "lodash/sortBy";
  import InvoiceHeaderRow from "@/components/invoice/InvoiceHeaderRow";
  import { getTotals } from "@/modules/public/utils/publicBillingUtils";

  export default {
    components: {
      PublicProgressBillingEntries,
      BillingDetails,
      CompanyContactInfo,
      CustomerContactInfo,
      InvoiceDetailsPage,
      InvoiceHeaderRow,
    },
    data() {
      return {
        billingTypes,
        billing: {},
        customer: {},
        company: {},
        job: {},
        entries: [],
        billingLoading: true,
      }
    },
    computed: {
      billingId() {
        return this.$route.params.id
      },
      itemsWithoutChangeOrders() {
        return this.entries.filter(r => r.relationships?.addlSource?.attributes?.change_order === 0)
      },
      itemsWithChangeOrders() {
        return this.entries.filter(r => r.relationships?.addlSource?.attributes?.change_order !== 0)
      },
      totalsWithoutChangeOrders() {
        return getTotals(this.itemsWithoutChangeOrders)
      },
      totalsWithChangeOrders() {
        return getTotals(this.itemsWithChangeOrders)
      },
      totals() {
        return getTotals(this.entries)
      },
    },
    methods: {
      async getBilling() {
        try {
          this.billingLoading = true
          const { data } = await axios.get(`/shareable/billings/${this.billingId}`)
          const { customer, company, job, entries } = data?.relationships || {}
          this.customer = customer?.attributes
          this.company = company?.attributes
          this.job = job
          this.billing = data
          this.entries = sortBy(entries, 'relationships.addlSource.attributes.phase_code', [])

          const { number } = this.billing?.attributes || {}

          document.title = `Progress Billing # ${number} @ ${this.customer?.name}`
        } finally {
          this.billingLoading = false
        }
      }
    },
    created() {
      this.getBilling()
    }
  }
</script>
<style>
</style>
