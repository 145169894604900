import TableAccountLink from "@/components/table/cells/TableAccountLink";
import TableLineItemLink from "@/components/table/cells/TableLineItemLink.vue";
import TableTypeLink from "@/components/table/cells/TableTypeLink";
import TableSourceLink from "@/components/table/cells/TableSourceLink";
import TableSpecialSourceLink from "@/components/table/cells/TableSpecialSourceLink";
import TableAddlSourceLink from "@/components/table/cells/TableAddlSourceLink";
import TableUserLink from "@/components/table/cells/TableUserLink";
import PrimaryAddressLink from "@/components/table/cells/PrimaryAddressLink";
import AddressLink from "@/components/table/cells/AddressLink";
import LocationLink from "@/components/table/cells/LocationLink";
import Status from "@/components/table/cells/Status";
import Count from "@/components/table/cells/Count";
import EmailLink from "@/components/table/cells/EmailLink";
import PhoneLink from "@/components/table/cells/PhoneLink";
import EntityLink from "@/components/table/cells/EntityLink";
import FormattedDate from "@/components/table/cells/FormattedDate";
import FormattedPrice from "@/components/table/cells/FormattedPrice";
import FormattedPercent from "@/components/table/cells/FormattedPercent";
import FormattedHours from "@/components/table/cells/FormattedHours.vue";
import FormattedQuantity from "@/components/table/cells/FormattedQuantity.vue";
import FormattedAmount from "@/components/table/cells/FormattedAmount";
import FormattedFloat from "@/components/table/cells/FormattedFloat";
import FormattedText from "@/components/table/cells/FormattedText";
import FormattedSSN from "@/components/table/cells/FormattedSSN.vue";
import TableServiceCodeLink from "@/components/table/cells/TableServiceCodeLink";
import TableBankLink from "@/components/table/cells/TableBankLink";
import TableSubAccountLink from "@/components/table/cells/TableSubAccountLink";
import JobLink from "@/components/links/JobLink";
import VendorLink from "@/components/links/VendorLink";
import EmployeeLink from "@/components/links/EmployeeLink";
import CustomerLink from "@/components/links/CustomerLink";
import JobTypeLink from "@/components/links/JobTypeLink";
import MaterialLink from "@/components/links/MaterialLink";
import StatusLink from "@/components/links/StatusLink";
import ColorPickerPreview from "@/components/table/cells/ColorPickerPreview.vue";
import TimesheetStatus from "@/components/table/cells/TimesheetStatus.vue";
import EquipmentLink from  "@/components/links/EquipmentLink";
import CraftCodeLink from '@/components/links/CraftCodeLink.vue'
import SubTradeLink from '@/components/links/SubTradeLink.vue'
import RatesLink from '@/components/links/RatesLink.vue'
import JournalLink from "@/components/links/JournalLink.vue";
import ReviewStatus from "@/components/links/ReviewStatus.vue";

export const tableColumns = {
  AccountLink: TableAccountLink,
  SubAccountLink: TableSubAccountLink,
  BankLink: TableBankLink,
  TypeLink: TableTypeLink,
  ServiceCodeLink: TableServiceCodeLink,
  SourceLink: TableSourceLink,
  SpecialSourceLink: TableSpecialSourceLink,
  AddlSourceLink: TableAddlSourceLink,
  UserLink: TableUserLink,
  LineItemLink: TableLineItemLink,
  PrimaryAddressLink,
  AddressLink,
  LocationLink,
  Status,
  Count,
  EmailLink,
  PhoneLink,
  EntityLink,
  FormattedDate,
  FormattedPrice,
  FormattedHours,
  FormattedQuantity,
  FormattedPercent,
  FormattedAmount,
  FormattedFloat,
  FormattedText,
  FormattedSSN,
  JobLink,
  VendorLink,
  JournalLink,
  CustomerLink,
  EmployeeLink,
  JobTypeLink,
  MaterialLink,
  StatusLink,
  ReviewStatus,
  ColorPickerPreview,
  TimesheetStatus,
  EquipmentLink,
  CraftCodeLink,
  SubTradeLink,
  RatesLink,
}
