<template>
  <DetailLayout
    :loading="repairOrderLoading"
    :current-resource="currentResource"
    :get-current-resource="getCurrentResource"
    :select-component="RepairOrderSelect"
    :items="items"
  >
    <template #page-title>
      <div class="truncate">
        <div
          v-if="currentResource.id"
          class="flex"
        >
          {{ $t('Repair Order') }} #{{ currentResource.attributes.number }}
          <span class="mx-1 md:mx-2 flex items-center">
            <status-badge :status="currentResource.attributes.status"/>
          </span>
        </div>
      </div>
    </template>
    <template #entity-name>
      <span>
        {{ pageTitle }}
      </span>
    </template>
    <template #extra-buttons>
      <StimulsoftPrintButton
        :url="`/restify/repair-orders/${currentResource.id}/stimulsoft`"
        :entity="StimulsoftPrintEntities.RepairOrder"
        :extra-data="extraPrintData"
        :name="pageTitle"
        class="mb-4"
      >
        <template #extra-data>
          <div class="col-span-full">
            <label class="form-label">
              {{ $t('Print Options') }}
            </label>
            <BaseCheckbox
              v-model="extraPrintData.flags.print_amounts"
              :label="$t('Print Amounts')"
              id="print_amounts"
            />
          </div>
        </template>
      </StimulsoftPrintButton>
    </template>
  </DetailLayout>
</template>
<script>
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import RepairOrderSelect from "@/components/select/entities/RepairOrderSelect.vue";
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";

export default {
  components: {
    DetailLayout,
  },
  data() {
    return {
      RepairOrderSelect,
      StimulsoftPrintEntities,
      dataLoading: true,
      entries: [],
      extraPrintData: {
        flags: {
          print_amounts: true,
        }
      }
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/equipment/repair-orders/${ this.$route.params.id }/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/equipment/repair-orders/${ this.$route.params.id }/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Timesheets'),
          path: `/equipment/repair-orders/${ this.$route.params.id }/timesheets`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Invoices'),
          path: `/equipment/repair-orders/${ this.$route.params.id }/invoices`,
          authorizedTo: 'authorizedToShow',
          permission: 'invoices_show',
        },
        {
          name: this.$t('Purchase Orders'),
          path: `/equipment/repair-orders/${ this.$route.params.id }/purchase-orders`,
          authorizedTo: 'authorizedToShow',
          permission: 'purchase_orders_show',
        },
        {
          name: this.$t('Transactions'),
          path: `/equipment/repair-orders/${ this.$route.params.id }/transactions`,
          authorizedTo: 'authorizedToShow',
          permission: 'transaction_show',
        },
      ]
    },
    repairOrderLoading() {
      return this.$store.state.equipmentManagement.repairOrderLoading
    },
    currentResource() {
      return this.$store.state.equipmentManagement.currentRepairOrder
    },
    pageTitle() {
      let baseTitle = `${this.$t('Repair Order ')} #${this.currentResource.attributes.number}`
      let equipmentDescription = this.get(this.currentResource, 'relationships.equipment.attributes.description', '')
      if (equipmentDescription) {
        return `${baseTitle} (${equipmentDescription})`
      }
      return baseTitle
    },
  },
  methods: {
    async getCurrentResource(id) {
      return await this.$store.dispatch('equipmentManagement/getRepairOrder', id)
    },
  },
}
</script>
