<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :append-to-body="true"
    :title="data?.id ? $t('Edit Task') : $t('Create Task')"
    size="lg"
  >
    <BaseForm
      layout="modal"
      :save-text="data?.id ? $t('Update Task') : $t('Create Task')"
      :loading="loading"
      :focus-on-first-input="false"
      :show-cancel="true"
      @cancel="$emit('update:open')"
      @submit="onSubmit"
    >
      <TaskTypeSelect
        v-model="model.cost_center"
        class="col-span-3 md:col-span-2"
        @change="onCostCenterChange"
      />
      <template v-if="model.cost_center === costCenterTypes.Job">
        <JobSelectNew
          v-model="model.source_id"
          :filter-available-in-timesheets="true"
          class="col-span-3 md:col-span-2"
          rules="required"
          @change="onJobChange"
        />
        <LineItemSelect
          v-model="model.addl_source_id"
          ref="lineItemSelect"
          :job-id="model.source_id"
          :label="$t('Line Item')"
          :filter-available-in-timesheets="true"
          class="col-span-3 md:col-span-2"
          rules="required"
          @change="onSpecialSourceChange"
        />
      </template>

      <EquipmentSelect
        v-else-if="model.cost_center === costCenterTypes.Equipment"
        v-model="model.source_id"
        :filter-available-in-timesheets="true"
        class="col-span-3 md:col-span-2"
        rules="required"
        @change="onSourceChange"
      />
      <WorkOrderSelect
        v-else-if="model.cost_center === costCenterTypes.WorkOrder"
        v-model="model.source_id"
        :filter-available-in-timesheets="true"
        class="col-span-3 md:col-span-2"
        rules="required"
        @entity-change="onSourceChange"
      />
      <base-textarea
        v-model="model.name"
        :label="$t('Name')"
        :placeholder="$t('Name')"
        :rows="2"
        id="name"
        rules="required|max:150"
        class="col-span-6"
      />
      <base-textarea
        v-model="model.notes"
        :label="$t('Instructions')"
        :placeholder="$t('Instructions')"
        :rows="2"
        id="description"
        rules="max:150"
        class="col-span-6"
      />
      <div class="col-span-6"></div>
      <CrewSelect
        v-model="model.crew_id"
        :label="$t('Crew')"
        :url-params="{
          related: 'employees[id|name]'
        }"
        clearable
        @entity-change="onCrewChange"
        class="col-span-6 border-0 p-0"
      />
      <EmployeeSelectNew
        v-model="model.employee_ids"
        :label="$t('Assigned Employees')"
        :own-filter="employeeFilter"
        rules="required"
        multiple
        class="col-span-6 border-0 p-0"
      />
      <TimesheetEntryDuration
        v-model="taskDuration"
        class="col-span-6 md:col-span-2"
      />
      <div class="col-span-6"></div>

      <base-date-picker
        v-model="model.start_at"
        :label="$t('Start Date & Time')"
        type="datetime"
        class="col-span-6 md:col-span-3"
        rules="required"
        @change="onStartAtChange"
      />
      <base-date-picker
        v-model="model.finish_at"
        :label="$t('End Date & Time')"
        :picker-options="finishPickerOptions"
        type="datetime"
        class="col-span-6 md:col-span-3"
        rules="required"
        @change="onFinishChange"
      />

      <el-collapse v-model="activeGroups" class="col-span-6">
        <base-collapse-section :title="$t('Address Information')"
                               key="address_information"
                               name="address_information"
        >
          <GoogleMapsAddressIframe
            :item="source"
            :cost-center="model.cost_center"
          />
        </base-collapse-section>
        <base-collapse-section :title="$t('Payroll Information')"
                               key="payroll_information"
                               name="payroll_information"
        >
          <div class="grid grid-cols-6 gap-4">
            <craft-select v-model="model.craft_code_id"
                          :label="$t('Craft Code')"
                          :name="$t('Craft Code')"
                          :add-entity="false"
                          :display-level="true"
                          id="craft_code_id"
                          class="col-span-6 md:col-span-3"
                          clearable
                          @change="onCraftCodeChange"
            />
            <sub-trade-select
              v-model="model.sub_trade_id"
              :label="$t('Sub Trade')"
              :add-entity="false"
              :disabled="!model.craft_code_id"
              :craft-code-id="model.craft_code_id"
              :allow-selection-without-craft-code="false"
              key="sub_trade_id"
              id="sub_trade_id"
              clearable
              class="col-span-6 md:col-span-3"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section
          v-if="model.id"
          key="timesheet_entries"
          name="timesheet_entries"
        >
          <template #title>
            <div class="flex justify-between w-full">
              <span>{{$t('Timesheet Entries')}}</span>
              <span class="font-medium text-gray-500 pr-2">{{ formattedLoggedTime }}</span>
            </div>
          </template>
          <TimesheetEntryList
            :entries="entries"
            :loading="entriesLoading"
          />
        </base-collapse-section>
      </el-collapse>

      <template #extra-buttons-left>
        <DeleteButton
          v-if="data.id"
          @click="onDelete"
        />
      </template>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import CostCenterSelect from "@/components/select/entities/CostCenterSelect.vue";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import LineItemSelect from "@/components/select/entities/LineItemSelect.vue";
import axios from "axios";
import {
  getAddlSource,
  getAddlSourceType,
  getSource,
  getSourceType,
} from "@/components/grid-table/utils/cost-center-cell";
import format from "date-fns/format";
import { diffDays } from "@fullcalendar/core/internal";
import TaskTypeSelect from "@/modules/payroll/components/tasks/TaskTypeSelect.vue";
import DeleteButton from "@/components/common/DeleteButton.vue";
import { getTaskName, TaskStatus } from "@/modules/payroll/components/tasks/taskUtils";
import SubTradeSelect from "@/components/select/entities/SubTradeSelect.vue";
import TimesheetEntryList from "@/modules/payroll/components/timesheets/TimesheetEntryList.vue";
import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";
import TimesheetEntryDuration
  from "@/modules/payroll/components/timesheets/weekly-timesheets/TimesheetEntryDuration.vue";
import { addMinutes, differenceInMinutes } from "date-fns";
import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";
import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";
import GoogleMapsAddressIframe from "@/modules/payroll/components/tasks/GoogleMapsAddressIframe.vue";

export default {
  components: {
    GoogleMapsAddressIframe,
    TimesheetEntryDuration,
    TimesheetEntryList,
    JobSelectNew,
    SubTradeSelect,
    TaskTypeSelect,
    LineItemSelect,
    CostCenterSelect,
    DeleteButton,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      costCenterTypes,
      loading: false,
      entriesLoading: false,
      entries: [],
      model: {
        cost_center: costCenterTypes.GeneralAndAdministrative,
        name: this.$t('General & Administrative'),
        notes: '',
        source_id: null,
        source_type: null,
        addl_source_id: null,
        addl_source_type: null,
        is_lunch: false,
        is_break: false,
        crew_id: null,
        employee_ids: [],
        status: TaskStatus.ToDo,
        start_at: null,
        finish_at: null,
        craft_code_id: undefined,
        sub_trade_id: undefined,
      },
      selectedCrew: null,
      activeGroups: [],
      duration: 0,
    }
  },
  computed: {
    taskDuration: {
      get() {
        if (this.duration) {
          return this.duration
        }
        if (!this.parsedStartAt && !this.parsedFinishAt) {
          return 0
        }
        return differenceInMinutes(this.parsedFinishAt, this.parsedStartAt)
      },
      set(value) {
        this.duration = value
        if (this.parsedStartAt) {
          this.model.finish_at = addMinutes(this.parsedStartAt, value)
        }
      }
    },
    parsedStartAt() {
      let start_at = this.model.start_at
      if (!start_at) {
        return null
      }
      if (typeof start_at == 'string') {
        start_at = new Date(start_at)
      }
      return start_at
    },
    parsedFinishAt() {
      let finish_at = this.model.finish_at
      if (!finish_at) {
        return null
      }
      if (typeof finish_at == 'string') {
        finish_at = new Date(finish_at)
      }
      return finish_at
    },
    finishPickerOptions() {
      return {
        disabledDate: (date) => {
          if (!this.parsedStartAt) {
            return false
          }
          return diffDays(date, this.parsedStartAt) >= 1
        }
      }
    },
    formattedLoggedTime() {
      const loggedMinutes = this.model.logged_minutes || 0
      return minutesToHours(loggedMinutes)
    },
    source() {
      return getSource(this.model)
    },
    addlSource() {
      return getAddlSource(this.model)
    }
  },
  methods: {
    computeTaskName() {
      this.model.name = getTaskName(this.model)
    },
    onCraftCodeChange(craftCode) {
      if (!craftCode) {
        this.model.sub_trade_id = undefined
      }
    },
    async onJobChange() {
      this.model.addl_source_id = null
      await this.$nextTick()
      this.computeTaskName()
      this.$refs.lineItemSelect.showMasterLineItems = false
    },
    async onSourceChange() {
      await this.$nextTick()
      this.computeTaskName()
    },
    async onSpecialSourceChange() {
      await this.$nextTick()
      this.computeTaskName()
    },
    onCostCenterChange() {
      this.model.source_id = null
      this.model.addl_source_id = null
      this.model.name = ''
    },
    onStartAtChange(date) {
      if (this.model.finish_at && this.parsedFinishAt?.getTime() > date?.getTime()) {
        return
      }
      if (this.taskDuration) {
        this.model.finish_at = addMinutes(date, this.taskDuration)
        return
      }
      this.model.finish_at = date
    },
    onFinishChange(date) {
      if (!this.parsedStartAt) {
        return
      }
      if (date?.getTime() < this.parsedStartAt?.getTime()) {
        this.model.finish_at = this.parsedStartAt
        this.$warning(this.$t('End date should be greater than start date'))
      }
    },
    getPayload() {
      const cost_center = this.model.cost_center
      const dateFormat = 'yyyy-MM-dd HH:mm'
      let start_at = this.model.start_at
      let finish_at = this.model.finish_at
      if (typeof start_at == 'string') {
        start_at = new Date(start_at)
      }
      if (typeof finish_at == 'string') {
        finish_at = new Date(finish_at)
      }
      return {
        ...this.model,
        source_type: getSourceType(cost_center),
        addl_source_type: getAddlSourceType(cost_center),
        employee_ids: this.model.employee_ids || [],
        start_at: format(start_at, dateFormat, true),
        finish_at: format(finish_at, dateFormat, true),
      }
    },
    async getTimesheetEntries() {
      if (!this.model.id) {
        return
      }
      try {
        this.entriesLoading = true
        const { data } = await axios.get(`/restify/timesheet-entries`, {
          params: {
            task_id: this.model?.id,
            related: `${entityPreviewFields.Source},addlSource`,
            perPage: 50,
          }
        })
        this.entries = data
      } finally {
        this.entriesLoading = false
      }
    },
    async onSubmit() {
      try {
        this.loading = true
        let task
        if (this.data?.id) {
          task = await axios.put(`/restify/tasks/${this.data.id}`, this.getPayload())
        } else {
          task = await axios.post(`/restify/tasks`, this.getPayload(), {
            params: {
              related: 'source,addlSource',
            }
          })
          await this.$store.dispatch('timesheets/addRecentTask', task.data)
        }
        this.$success(this.$t('Task created successfully'))
        this.$emit('save', task.data)
      } catch (err) {
        if (err.handled) {
          return
        }
        console.log(err)
        this.$error(this.$t('Could not create task'))
      } finally {
        this.loading = false
      }
    },
    async onDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete task?'),
        description: this.$t('Are you sure you want to delete this task?'),
        buttonText: this.$t('Delete'),
      })
      if (!confirmed) {
        return
      }
      try {
        this.loading = true
        await axios.delete(`/restify/tasks/${this.data.id}`)
        this.$success(this.$t('Task deleted successfully'))
        this.$emit('delete-confirm', this.data)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not delete task'))
      } finally {
        this.loading = false
      }
    },
    employeeFilter(employees) {
      if (!this.selectedCrew) {
        return employees
      }
      const crewEmployees = this.selectedCrew?.relationships?.employees.map(e => e.id)
      return employees.filter(e => {
        return crewEmployees.includes(e.id)
      })
    },
    onCrewChange(crew) {
      this.selectedCrew = crew
      this.model.employee_ids = []
    },
    async getCrew() {
      if (!this.model.crew_id || this.selectedCrew) {
        return
      }
      const { data } = await axios.get(`/restify/crews/${this.model.crew_id}`, {
        params: {
          related: 'employees[id|name]'
        }
      })
      this.selectedCrew = data
    }
  },
  mounted() {
    this.getTimesheetEntries()
    this.getCrew()
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value.id) {
          return
        }
        this.model = {
          ...value?.attributes,
        }
        if (!this.model.name) {
          this.computeTaskName()
        }
      }
    }
  }
}
</script>
