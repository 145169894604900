<template>
  <entity-preview
    :entity="equipmentData"
    :key="`preview-${equipmentId}`"
    :url="`/equipment/list/${equipmentId}/view`"
    :value-to-display="valueToDisplay"
    :parent-entity-id="equipmentId"
    :target="target"
    :is-link="!editableCell"
    :show-preview="showPreview"
    :link-class="linkClass"
    entity-name="equipment"
  />
</template>
<script>
  export default {
    name: 'EquipmentLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      target: String,
      linkClass: String,
      editableCell: Boolean,
    },
    computed: {
      allEquipments() {
        return this.$store.state.globalLists.equipment
      },
      equipmentId() {
        return this.equipmentData?.id || this.id
      },
      equipmentData() {
        if (this.data?.code) {
          return this.data
        }

        if (this.data?.attributes) {
          return this.data?.attributes
        }

        if (this.id) {
          return this.allEquipments.find(e => e.id === this.id)
        }
        return this.data?.attributes || this.data || {};
      },
      valueToDisplay() {
        if (!this.equipmentData?.id) {
          return ''
        }

        if (!this.showDescription || !this.equipmentData.description) {
          return this.equipmentData.code
        }

        return `${this.equipmentData.code} (${this.equipmentData.description})`
      },
    },
  }
</script>
