<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :has-summary="false"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{ row }">
      <div class="flex space-x-2 items-center">
        <div class="w-1/3 flex items-center space-x-2">
          <span> {{ $t('Job:') }} </span>
          <JobLink
            :data="row.header.job"
            class="inline"
          />
        </div>
        <div
          v-if="row.header?.line_item"
          class="w-2/3 flex items-center space-x-2"
        >
          <span> {{ $t('Line Item:') }} </span>
          <LineItemLink
            :data="row.header.line_item"
            :show-preview="false"
            :show-name="true"
          />
          <span> ({{ row.header.line_item.type }}) </span>
        </div>
      </div>
    </template>

    <template #amount="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ row.amount.label || $t('Line Item') }}</span>
          <span>{{ $formatPrice(row.amount.line_item) }}</span>
        </dt>
        <dt
          v-if="row.line_type !== 'budget'"
          class="flex justify-between"
        >
          <span>{{ $t('Budgets') }}</span>
          <span
            :class="{
              'text-red-500': row.amount.diff
            }"
          >
            {{ $formatPrice(row.amount.budgets) }}
          </span>
        </dt>
      </dl>
    </template>

    <template #amount_to_date="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ row.amount_to_date.label || $t('Line Item') }}</span>
          <span>{{ $formatPrice(row.amount_to_date.line_item) }}</span>
        </dt>
        <dt
          v-if="row.line_type !== 'budget'"
          class="flex justify-between"
        >
          <span>{{ $t('Budgets') }}</span>
          <span
            :class="{
              'text-red-500': row.amount_to_date.budgets_diff
            }"
          >
            {{ $formatPrice(row.amount_to_date.budgets) }}
          </span>
        </dt>
        <dt v-else>
          <span>&nbsp;</span>
        </dt>
      </dl>

      <div class="font-semibold !text-base my-2">JC + GL</div>

      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ $t('Inits') }}</span>
          <span>{{ $formatPrice(row.amount_to_date.inits) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Transactions') }}</span>
          <span>{{ $formatPrice(row.amount_to_date.transactions) }}</span>
        </dt>
        <div class="my-2 border-b border-gray-300"></div>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Total') }}</span>
          <span
            :class="{
              'text-red-500': row.amount_to_date.transactions_inits_diff
            }"
          >
            {{ $formatPrice(row.amount_to_date.transactions_inits) }}
          </span>
        </dt>
      </dl>

      <div class="font-semibold !text-base my-2">JC AP PR AR</div>

      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ $t('Inits') }}</span>
          <span>{{ $formatPrice(row.amount_to_date.inits) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Invoices') }}</span>
          <span>{{ $formatPrice(row.amount_to_date.invoices) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Billings') }}</span>
          <span>{{ $formatPrice(row.amount_to_date.billings) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Payrolls') }}</span>
          <span>{{ $formatPrice(row.amount_to_date.payrolls) }}</span>
        </dt>
        <div class="my-2 border-b border-gray-300"></div>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Total') }}</span>
          <span
            :class="{
              'text-red-500': row.amount_to_date.invoices_billings_payrolls_inits_diff
            }"
          >
            {{ $formatPrice(row.amount_to_date.invoices_billings_payrolls_inits) }}
          </span>
        </dt>
      </dl>
    </template>

    <template #retention_amount="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ row.retention_amount.label || $t('Line Item') }}</span>
          <span>{{ $formatPrice(row.retention_amount.line_item) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ getRetentionsLabel(row) }}</span>
          <span
            :class="{
              'text-red-500': row.retention_amount.business_retentions_diff
            }"
          >
            {{ $formatPrice(row.retention_amount.business_retentions) }}
          </span>
        </dt>
      </dl>

      <div class="font-semibold !text-base my-2">JC + GL</div>

      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ $t('Inits') }}</span>
          <span>{{ $formatPrice(row.retention_amount.inits) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Transactions') }}</span>
          <span>{{ $formatPrice(row.retention_amount.transactions) }}</span>
        </dt>
        <div class="my-2 border-b border-gray-300"></div>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Total') }}</span>
          <span
            :class="{
              'text-red-500': row.retention_amount.transactions_inits_diff
            }"
          >
            {{ $formatPrice(row.retention_amount.transactions_inits) }}
          </span>
        </dt>
      </dl>

      <div class="font-semibold !text-base my-2">JC AP AR</div>

      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ $t('Inits') }}</span>
          <span>{{ $formatPrice(row.retention_amount.inits) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Invoices') }}</span>
          <span>{{ $formatPrice(row.retention_amount.invoices) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Billings') }}</span>
          <span>{{ $formatPrice(row.retention_amount.billings) }}</span>
        </dt>
        <dt><span>&nbsp;</span></dt>
        <div class="my-2 border-b border-gray-300"></div>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Total') }}</span>
          <span
            :class="{
              'text-red-500': row.retention_amount.invoices_billings_inits_diff
            }"
          >
            {{ $formatPrice(row.retention_amount.invoices_billings_inits) }}
          </span>
        </dt>
      </dl>
    </template>

    <template #committed_po_amount="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ row.committed_po_amount.label || $t('Line Item') }}</span>
          <span>{{ $formatPrice(row.committed_po_amount.line_item) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Purchase Orders') }}</span>
          <span
            :class="{
              'text-red-500': row.committed_po_amount.diff
            }"
          >
            {{ $formatPrice(row.committed_po_amount.purchase_orders) }}
          </span>
        </dt>
      </dl>
    </template>

    <template #invoiced_po_amount="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ row.invoiced_po_amount.label || $t('Line Item') }}</span>
          <span>{{ $formatPrice(row.invoiced_po_amount.line_item) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Invoices') }}</span>
          <span
            :class="{
              'text-red-500': row.invoiced_po_amount.diff
            }"
          >
            {{ $formatPrice(row.invoiced_po_amount.invoices) }}
          </span>
        </dt>
      </dl>
    </template>

    <template #material_stored_amount="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span>{{ row.material_stored_amount.label || $t('Line Item') }}</span>
          <span>{{ $formatPrice(row.material_stored_amount.line_item) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span>{{ $t('Billings') }}</span>
          <span
            :class="{
              'text-red-500': row.material_stored_amount.diff
            }"
          >
            {{ $formatPrice(row.material_stored_amount.billings) }}
          </span>
        </dt>
      </dl>
    </template>

    <template #quantity=" { row }">
      <dl
        v-if="row.line_type === 'budget'"
        class="w-full"
      >
        <dt class="flex justify-between">
          <span>{{ $t('Quantity') }}</span>
          <span>{{ $formatHours(row.quantity) }}</span>
        </dt>
      </dl>
    </template>

    <template #quantity_to_date=" { row }">
      <template v-if="row.line_type === 'budget'">
        <dl class="w-full">
          <dt class="flex justify-between">
            <span>{{ $t('Budget') }}</span>
            <span>{{ $formatHours(row.quantity) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Qty To Date') }}</span>
            <span>{{ $formatHours(row.quantity_to_date.budget) }}</span>
          </dt>
        </dl>

        <div class="font-semibold !text-base my-2">JC + GL</div>

        <dl class="w-full">
          <dt class="flex justify-between">
            <span>{{ $t('Inits') }}</span>
            <span>{{ $formatHours(row.quantity_to_date.inits) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Transactions') }}</span>
            <span>{{ $formatHours(row.quantity_to_date.transactions) }}</span>
          </dt>
          <div class="my-2 border-b border-gray-300"></div>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('Total') }}</span>
            <span
              :class="{
                'text-red-500': row.quantity_to_date.transactions_inits_diff
              }"
            >
              {{ $formatHours(row.quantity_to_date.transactions_inits) }}
            </span>
          </dt>
        </dl>

        <div class="font-semibold !text-base my-2">JC AP PR AR</div>

        <dl class="w-full">
          <dt class="flex justify-between">
            <span>{{ $t('Inits') }}</span>
            <span>{{ $formatHours(row.quantity_to_date.inits) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Invoices') }}</span>
            <span>{{ $formatHours(row.quantity_to_date.invoices) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Billings') }}</span>
            <span>{{ $formatHours(row.quantity_to_date.billings) }}</span>
          </dt>
          <dt class="flex justify-between">
            <span>{{ $t('Payrolls') }}</span>
            <span>{{ $formatHours(row.quantity_to_date.payrolls) }}</span>
          </dt>
          <div class="my-2 border-b border-gray-300"></div>
          <dt class="flex justify-between">
            <span class="font-semibold">{{ $t('Total') }}</span>
            <span
              :class="{
                'text-red-500': row.quantity_to_date.invoices_billings_payrolls_diff
              }"
            >
              {{ $formatHours(row.quantity_to_date.invoices_billings_payrolls) }}
            </span>
          </dt>
        </dl>
      </template>
    </template>


    <template #html-row="{ htmlData }">
      <tr class="content-row font-semibold">
        <td colspan="13">
          <div class="py-4 !text-lg">
            {{ htmlData }}
          </div>
        </td>
      </tr>
    </template>

  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'

export default {
  extends: ReportTableWrapper,
  computed: {
    columns() {
      return [
        {
          label: this.$t('Amount'),
          prop: 'amount',
          verticalAlign: 'top',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Amount To Date'),
          prop: 'amount_to_date',
          verticalAlign: 'top',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Retention <br> Amount'),
          prop: 'retention_amount',
          verticalAlign: 'top',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Committed<br>PO Amount'),
          prop: 'committed_po_amount',
          verticalAlign: 'top',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Invoiced<br>PO Amount'),
          prop: 'invoiced_po_amount',
          verticalAlign: 'top',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Material<br>Stored Amount'),
          prop: 'material_stored_amount',
          verticalAlign: 'top',
          minWidth: 180,
          maxWidth: 200,
        },
        {
          label: this.$t('Quantity To Date'),
          prop: 'quantity_to_date',
          verticalAlign: 'top',
          minWidth: 180,
          maxWidth: 200,
        },
      ]
    },
  },
  methods: {
    addJobLineItems(jobGroup) {
      for (const lineItemGroup of jobGroup.line_items) {
        this.rows.push({
          header: {
            job: jobGroup.job,
            line_item: lineItemGroup.line_item,
          },
        })

        this.rows.push(lineItemGroup)
        this.rows.push({
          htmlData: this.$t('BUDGETS'),
        })

        this.addBudgetLines(lineItemGroup)
      }
    },
    addBudgetLines(lineItemGroup) {
      lineItemGroup.budgets.forEach((budget) => {
        const label = `${budget.job_type.abbr} (${budget.job_type.type})`
        const budgetsLabel = this.$t('Budget')
        const amount = {
          line_item: budget.amount,
          label,
        }
        const amount_to_date = {
          ...budget.amount_to_date,
          line_item: budget.amount_to_date.budget,
          label: budgetsLabel,
        }

        const retention_amount = {
          ...budget.retention_amount,
          line_item: budget.retention_amount.budget,
          label: budgetsLabel,
        }

        const committed_po_amount = {
          ...budget.committed_po_amount,
          line_item: budget.committed_po_amount.budget,
          label: budgetsLabel,
        }

        const invoiced_po_amount = {
          ...budget.invoiced_po_amount,
          line_item: budget.invoiced_po_amount.budget,
          label: budgetsLabel,
        }

        const material_stored_amount = {
          ...budget.material_stored_amount,
          line_item: budget.material_stored_amount.budget,
          label: budgetsLabel,
        }

        const quantity_to_date = {
          ...budget.quantity_to_date,
          line_item: budget.quantity_to_date.budget,
          label: budgetsLabel,
        }

        this.rows.push({
          ...budget,
          line_item: lineItemGroup.line_item,
          line_type: 'budget',
          amount,
          amount_to_date,
          retention_amount,
          committed_po_amount,
          invoiced_po_amount,
          material_stored_amount,
          quantity_to_date,
        })
      })
    },
    getRetentionsLabel(row) {
      const lineType = row.line_item.type
      if (lineType === 'income') {
        return this.$t('AR Retentions')
      }

      return this.$t('AP Retentions')
    },
    composeRows(data) {
      for (const jobGroup of data) {
        this.addJobLineItems(jobGroup)
      }

      return this.rows
    },
  }
}
</script>
