<template>
  <div
    class="col-span-8 grid grid-cols-8 gap-x-4"
    :class="{
      'sales-tax-exempt': model?.meta.exempt_from_sales_tax && !model.sales_tax_percent,
    }"
  >
    <div class="col-span-8">
      <h5 class="form-section-title">
        <slot name="title">
          <template v-if="showRetention && applyTaxToBillings">
            {{ $t('Tax & Retention') }}
          </template>
          <template v-else-if="showRetention && applyTaxToBillings">
            {{ $t('Tax') }}
          </template>
          <template v-else-if="showRetention && !applyTaxToBillings">
            {{ $t('Retention') }}
          </template>
        </slot>
      </h5>
    </div>
    <template v-if="applyTaxToBillings">
      <sales-tax-district-select
        v-if="model.meta"
        v-model="model.meta.district_id"
        :add-entity="true"
        :initial-value="get(data, 'relationships.district')"
        :class="inputClass"
        @entity-change="onChangeDistrict"
        clearable
      />
      <base-input
        :value="salesTaxPercent"
        v-bind="$validator.salesTaxPercent"
        :tip="model.meta?.exempt_from_sales_tax ? $t('This selected resource is exempt from sales tax') : null"
        :name="$t('Sales Tax %')"
        :label="$t('Sales Tax %')"
        format="percent"
        :class="inputClass"
        id="sales_tax_percent"
        type="number"
        @input="onSalesTaxPercentChange"
        @change="$emit('sales-tax-change', $event)"
      />
      <slot name="after-tax-percentage"></slot>
    </template>

    <base-input
      v-if="showRetention"
      v-model="model.retention_percent"
      :min="0.00"
      :max="100"
      :name="$t('Retention')"
      :label="$t('Retention')"
      :class="inputClass"
      id="retention_percent"
      type="number"
      rules="max_value:100|min_value:0"
      format="percent"
      @change="onRetentionChange"
    />
  </div>
</template>
<script>

import {
  getDistrictId,
  getRetentionPercentage,
  getSalesTaxPercentage
} from "@/modules/accounts-receivable/utils/billingUtils";

export default {
  props: {
    model: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    showRetention: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: 'col-span-8 md:col-span-1',
    },
  },
  data() {
    return {
      localSalesTaxPercent: 0,
    }
  },
  computed: {
    applyTaxToBillings() {
      return this.$settings(this.$modules.AR, 'apply_tax_to_billings')
    },
    salesTaxPercent() {
      if (this.localSalesTaxPercent) {
        return this.localSalesTaxPercent
      }
      return this.model.sales_tax_percent || this.model.meta?.sales_tax_percent
    }
  },
  methods: {
    onChangeDistrict(district) {
      const { sales_tax_percent } = district?.attributes || {}
      const taxPercent = sales_tax_percent ? sales_tax_percent : 0
      this.model.sales_tax_percent = taxPercent
      this.model.meta.sales_tax_percent = taxPercent
      this.model.district_id = district?.id
      this.onSalesTaxPercentChange(taxPercent)
      this.$emit('sales-tax-change', taxPercent)
    },
    onSalesTaxPercentChange(value) {
      this.model.sales_tax_percent = +value
      if (this.model.meta) {
        this.model.meta.sales_tax_percent = +value
      }
      this.localSalesTaxPercent = +value
    },
    onRetentionChange(value) {
      this.$emit('retention-change', +value)
    },
    async setTaxPercentage() {
      if (!this.applyTaxToBillings) {
        return
      }
      const params = {
        district_id: this.model.meta?.district_id,
        customer_id: this.model.customer_id,
        job_id: this.model.job_id,
        work_order_id: this.model.work_order_id,
      }
      const { percentage, exempt_from_sales_tax } = await getSalesTaxPercentage(params)
      const taxPercent = this.model.sales_tax_percent || percentage || 0
      if (taxPercent) {
        this.model.sales_tax_percent = taxPercent
      }
      if (this.model.meta) {
        this.model.meta.exempt_from_sales_tax = exempt_from_sales_tax
        this.model.meta.sales_tax_percent = taxPercent
      }
      const districtId = await getDistrictId(params)

      if (districtId && this.model.meta) {
        this.model.meta.district_id = districtId
      }
    },
    setRetentionPercentage() {
      if (!this.showRetention) {
        return
      }
      const retentionPercentage = getRetentionPercentage(this.model.job_id)
      if (retentionPercentage) {
        this.model.retention_percent = retentionPercentage
      }
    },
    resetDistrictAndTax() {
      this.model.district_id = null
      this.model.sales_tax_percent = 0
      this.localSalesTaxPercent = 0
      if (this.model.meta) {
        this.model.meta.district_id = null
        this.model.meta.sales_tax_percent = 0
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (!this.model.meta) {
          this.model.meta = {
            district_id: null
          }
        }
        if (this.model?.district_id && !this.model.meta.district_id) {
          this.model.meta.district_id = this.model.district_id
        }
        this.setTaxPercentage()
        this.setRetentionPercentage()
      },
    },
    'model.customer_id'() {
      this.setTaxPercentage()
    },
    'model.work_order_id'() {
      this.setTaxPercentage()
    },
    'model.job_id'(value, oldValue) {
      if (!oldValue) {
        return
      }
      this.resetDistrictAndTax()
      this.setTaxPercentage()
      this.setRetentionPercentage()
    }
  }
}
</script>
<style>
.sales-tax-exempt #sales_tax_percent {
  @apply bg-orange-100;
}
</style>
