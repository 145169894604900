import { resourceStatuses } from "@/enum/enums";
import { globalResources } from '@/components/form/util';
import i18n from "@/i18n";

export const repairOrderStatusOptions = [
  {
    label: i18n.t('To Do'),
    value: resourceStatuses.ToDo,
  },
  {
    label: i18n.t('Awaiting Materials'),
    value: resourceStatuses.AwaitingMaterials,
  },
  {
    label: i18n.t('In Progress'),
    value: resourceStatuses.InProgress,
  },
  {
    label: i18n.t('Completed'),
    value: resourceStatuses.Completed,
  },
  {
    label: i18n.t('Cancelled'),
    value: resourceStatuses.Cancelled,
  },
]

export const sourceTypes = {
  Labor: 'craft-code',
  Material: 'material',
}

export const abbrToSourceType = {
  REP: sourceTypes.Labor,
  PAR: sourceTypes.Material
}

export const additionalSourceMap = {
  [sourceTypes.Labor]: globalResources.CraftCodes,
  [sourceTypes.Material]: globalResources.Materials,
  default: ' ',
}

export const sourceCellTitles = {
  [sourceTypes.Labor]: 'Craft Code',
  [sourceTypes.Material]: 'Material',
  default: '',
}
