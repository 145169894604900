<template>
  <div>
    <div class="flex justify-between w-full leading-4">
      <div v-if="level === 0" class="flex space-x-6">
      <span class="font-medium">
        {{ $t('Vendor:') }} {{ vendor.code }} ({{ vendor.name }})
      </span>
      </div>
      <div v-if="level === 1" class="flex space-x-6">
        <span class="font-medium">
          {{ $t('Invoice:') }} #{{ invoice.number }} {{ invoice.description }}
        </span>
        <span v-if="invoice.po_number">
          {{ $t('PO Number:') }} {{ invoice.po_number }}
        </span>
        <span>
          {{ $t('Date:') }} {{ $formatDate(invoice.dates?.date) }}
        </span>
        <span>
          {{ $t('Discount Date:') }} {{ $formatDate(invoice.dates?.discount_date) }}
        </span>
        <span>
          {{ $t('Due Date:') }} {{ $formatDate(invoice.dates?.due_date) }}
        </span>
        <span class="capitalize">
          {{ $t('Type: ') }} {{ invoice?.type }}
        </span>
        <template v-if="invoice?.type === InvoiceTypes.Manual">
          <span class="capitalize">
            {{ $t('Check Number: ') }} {{ invoice?.payment_number }}
          </span>
          <span class="capitalize">
            {{ $t('Check Date: ') }} {{ $formatDate(invoice?.payment_date) }}
          </span>
        </template>
      </div>
    </div>
    <template v-if="level === 1">
      <div class="text-red-500 leading-none"
           v-for="error in errors">
        <span>{{ error.message }}</span>
        <span v-if="error.open_amount">
        {{ $formatPrice(error.open_amount) }}
      </span>
      </div>
    </template>
  </div>
</template>
<script>
import { InvoiceTypes } from "@/modules/accounts-payable/enum/invoice";

export default {
  computed: {
    InvoiceTypes() {
      return InvoiceTypes
    },
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    errors() {
      return this.data.invoice?.errors || []
    },
    invoice() {
      return this.data?.invoice || {}
    },
    vendor() {
      return this.data?.vendor || {}
    },
    level() {
      return this.params.node.level
    }
  }
}
</script>
