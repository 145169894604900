<template>
  <div class="recurring-billings">
    <LayoutTabs
      v-if="!onViewResourcePage"
      :items="filteredItems"
    />
    <router-view />
  </div>
</template>
<script>
export default {
  computed: {
    items() {
      return [
        {
          name: this.$t('Lump Sum'),
          path: '/accounts-receivable/recurring-billings/lump-sum',
          permission: 'recurring_billings_show',
        },
        {
          name: this.$t('Service'),
          path: '/accounts-receivable/recurring-billings/service',
          permission: 'recurring_billings_show',
        },
        {
          name: this.$t('All Billings'),
          path: '/accounts-receivable/recurring-billings/all',
          permission: 'recurring_billings_show',
        },
      ]
    },
    filteredItems() {
      const permissions = this.$store.getters['auth/permissions']
      return this.items.filter(item => {
        if (!item.productionReady && this.isProduction) {
          return false
        }
        return permissions.includes(item.permission)
      })
    },
  },
}
</script>
