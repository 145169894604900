<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div
        :title="$t('Code')"
        class="detail-row"
      >
        <HashIcon class="detail-icon"/>
        <div>{{ agencyData.code }}</div>
        <StatusBadge :status="agencyData.status"/>
      </div>
      <div
        :title="$t('Website')"
        class="detail-row"
      >
        <ExternalLinkIcon class="detail-icon"/>
        <a
          v-if="agencyData.website_url"
          :href="agencyData.website_url"
          ref="construction partner"
          target="_blank"
        >
          {{ agencyData.website_url }}
        </a>
        <span v-else>{{ $t('No website provided') }}</span>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { Agency } from '@/modules/common/types/models'
import { HashIcon, ExternalLinkIcon } from 'vue-feather-icons'
import Data = API.Data;

const props = defineProps({
  agency: {
    type: Object as PropType<Data<Agency>>,
    required: true,
  },
})

const agencyData = computed<Agency>(() => {
  return props.agency?.attributes || {}
})
</script>
