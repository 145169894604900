import {computed, onMounted, onUnmounted, ref} from "vue";
import {MultimodalLiveClient,} from "../lib/multimodal-live-client";
import {AudioStreamer} from "../lib/audio-streamer";
import {audioContext} from "../lib/utils";
import VolMeterWorklet from "../lib/worklets/vol-meter";
import {LiveConfig} from "@/modules/support/types/multimodal-live-types";

const API_KEY = import.meta.env.VITE_GEMINI_API_KEY as string;
if (typeof API_KEY !== "string") {
  console.error("set VITE_GEMINI_API_KEY in .env");
}

const host = "generativelanguage.googleapis.com";
const url = `wss://${host}/ws/google.ai.generativelanguage.v1alpha.GenerativeService.BidiGenerateContent`;
const apiKey = API_KEY

export type UseLiveAPIResults = {
  client: MultimodalLiveClient;
  setConfig: (config: LiveConfig) => void;
  config: LiveConfig;
  connected: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  volume: number;
};

const config = ref<LiveConfig>({
  model: "models/gemini-2.0-flash-exp",
});

export default function useLiveApi() {
  const client = computed(() => new MultimodalLiveClient({url, apiKey}));
  const audioStreamerRef = ref<AudioStreamer | null>(null);

  const connected = ref(false);
  const volume = ref(0);

  // Register audio for streaming (server -> speakers)
  onMounted(() => {
    if (!audioStreamerRef.value) {
      audioContext({id: "audio-out"}).then((audioCtx: any) => {
        audioStreamerRef.value = new AudioStreamer(audioCtx);
        audioStreamerRef.value
          .addWorklet("vumeter-out", VolMeterWorklet, (ev: any) => {
            volume.value = ev.data.volume;
          })
          .then(() => {
            // Worklet added successfully
          });
      });
    }

    const onClose = () => {
      connected.value = false;
    };

    const stopAudioStreamer = () => {
      if (audioStreamerRef.value) {
        audioStreamerRef.value.stop();
      }
    };

    const onAudio = (data: any) => {
      if (audioStreamerRef.value) {
        audioStreamerRef.value.addPCM16(new Uint8Array(data));
      }
    };

    client.value
      .on("close", onClose)
      .on("interrupted", stopAudioStreamer)
      .on("audio", onAudio);

    onUnmounted(() => {
      client.value
        .off("close", onClose)
        .off("interrupted", stopAudioStreamer)
        .off("audio", onAudio);
    });
  });

  const connect = async () => {
    if (!config.value) {
      console.error("config has not been set");
    }
    client.value.disconnect();
    await client.value.connect(config.value);
    connected.value = true;
  };

  const disconnect = async () => {
    client.value.disconnect();
    connected.value = false;
  };

  return {
    client,
    config,
    setConfig: (newConfig: LiveConfig) => {
      config.value = newConfig
    },
    connected,
    connect,
    disconnect,
    volume,
  };

}

