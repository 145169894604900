<template>
  <div class="min-w-[280px] options-header flex flex-wrap items-center absolute top-0 px-5 py-2 w-full z-10 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider overflow-visible">
    <div class="basis-full flex items-center mb-1">
      <EquipmentCostAddlSourcesRadio
        v-model="costSourceModel"
        @change="$emit('change', $event)"
      />
    </div>
    <span
      v-for="column in tableColumns"
      :key="column.name"
      :style="{ 
        minWidth: `${column.minWidth}px`,
        maxWidth: `${column.maxWidth}px`
      }"
      class="pr-2"
      :class="{
        [column.class]: column.class,
      }"
    >
      <div
        class="flex items-center relative truncate"
        v-html="column.name"
      />
    </span>
    <RefreshResourceButton :get-data="() => $emit('refresh')" />
  </div>
</template>
<script>
import EquipmentCostAddlSourcesRadio from '@/modules/equipment/components/equipment/EquipmentCostAddlSourcesRadio.vue'
import RefreshResourceButton from "@/components/select/RefreshResourceButton.vue";

export default {
  name: 'MaintenanceSelectHeader',
  inheritAttrs: false,
  components: {
    RefreshResourceButton,
    EquipmentCostAddlSourcesRadio,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    tableColumns: {
      type: Array,
      default: () => tableColumns,
    },
  },
  computed: {
    costSourceModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
}
</script>
