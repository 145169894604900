import axios from 'axios'
import Vue from 'vue'
import i18n from "@/i18n";
import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";

const notifier = new Vue();

const types = {
  SET_CURRENT_CUSTOMER: 'SET_CURRENT_CUSTOMER',
  SET_CURRENT_CUSTOMER_LOADING: 'SET_CURRENT_CUSTOMER_LOADING',
  SET_CURRENT_BILLING: 'SET_CURRENT_BILLING',
  SET_CURRENT_BILLING_LOADING: 'SET_CURRENT_BILLING_LOADING',
  SET_CURRENT_RECURRING_BILLING: 'SET_CURRENT_RECURRING_BILLING',
  SET_CURRENT_RECURRING_BILLING_LOADING: 'SET_CURRENT_RECURRING_BILLING_LOADING',
};
const state = {
  currentCustomer: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  customerLoading: false,
  currentBilling: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  billingLoading: false,
  currentRecurringBilling: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  recurringBillingLoading: false,
};

const mutations = {
  [types.SET_CURRENT_CUSTOMER]: (state, customer) => {
    state.currentCustomer = {
      ...state.currentCustomer,
      ...customer,
    }
  },
  [types.SET_CURRENT_CUSTOMER_LOADING]: (state, value) => {
    state.customerLoading = value
  },
  [types.SET_CURRENT_BILLING]: (state, billing) => {
    state.currentBilling = {
      ...state.currentBilling,
      ...billing,
      ...billing.relationships,
    }
  },
  [types.SET_CURRENT_BILLING_LOADING]: (state, value) => {
    state.billingLoading = value
  },
  [types.SET_CURRENT_RECURRING_BILLING]: (state, billing) => {
    state.currentRecurringBilling = {
      ...state.currentRecurringBilling,
      ...billing,
      ...billing.relationships,
    }
  },
  [types.SET_CURRENT_RECURRING_BILLING_LOADING]: (state, value) => {
    state.recurringBillingLoading = value
  },

};

const actions = {
  async getCustomer({ commit }, customerId) {
    try {
      commit(types.SET_CURRENT_CUSTOMER_LOADING, true)
      const params = {
        related: 'addresses,contacts,district[id|code],activities,billingRateType[id|title]'
      }
      const { data } = await axios.get(`/restify/customers/${customerId}`, {
        params
      })
      commit(types.SET_CURRENT_CUSTOMER, data)
    } finally {
      commit(types.SET_CURRENT_CUSTOMER_LOADING, false)
    }
  },
  updateCustomer({ state }, customer = {}) {
    state.currentCustomer.attributes = customer.attributes
  },
  async patchCustomer({ state }, data = {}) {
    await axios.patch(`/restify/customers/${data.id}`, data)
  },
  async getBilling({ commit }, billingId) {
    try {
      commit(types.SET_CURRENT_BILLING_LOADING, true)

      const params = {
        related : `payments,${entityPreviewFields.Job},${entityPreviewFields.Customer},${entityPreviewFields.WorkOrder},customer.addresses,customer.contacts,workOrder`,
      }

      const { data } = await axios.get(`/restify/billings/${billingId}`, {
        params,
      })
      commit(types.SET_CURRENT_BILLING, data)
    } finally {
      commit(types.SET_CURRENT_BILLING_LOADING, false)
    }
  },
  async getRecurringBilling({ commit }, billingId) {
    try {
      commit(types.SET_CURRENT_RECURRING_BILLING_LOADING, true)
      const params = {
        related: `${entityPreviewFields.Customer},workOrder`
      }
      const { data } = await axios.get(`/restify/recurring-billings/${billingId}`, {
        params,
      })
      if (!data?.attributes?.customer_id) {
        data.attributes.customer_id = data?.relationships?.customer?.id
      }
      commit(types.SET_CURRENT_RECURRING_BILLING, data)
    } finally {
      commit(types.SET_CURRENT_RECURRING_BILLING_LOADING, false)
    }
  },
  async deactivateRecurringBilling({ commit, dispatch }, billingId) {
    await axios.post(`/restify/recurring-billings/${billingId}/actions?action=deactivate-recurring-billing`)
    dispatch('getRecurringBilling', billingId)
  },
  async activateRecurringBilling({ commit, dispatch }, billingId) {
    await axios.post(`/restify/recurring-billings/${billingId}/actions?action=activate-recurring-billing`)
    dispatch('getRecurringBilling', billingId)
  },
  async sendEntityEmail({ state }, data = {}) {
    const { row, action, entityName } = data
    const customer = state.currentCustomer?.attributes || {}
    const confirmed = await notifier.$confirm({
      title: i18n.t(`Send ${entityName} Email to ${customer.name}`),
      description: i18n.t(`This action will send a ${entityName} email to ${customer.name}.`),
      buttonText: i18n.t('Send'),
    })

    if (!confirmed) {
      return
    }
    try {
      Vue.set(row, 'loading', true)
      const data = {
        repositories: [row.id],
      }
      await axios.post(action, data)
      notifier.$success(i18n.t(`Email was queued to be sent. ${customer.name} will receive the email in a couple of minutes.`))
    } catch (err) {
      notifier.$error(`An error occurred while trying to send the ${entityName} email(s)`)
    } finally {
      Vue.set(row, 'loading', false)
    }
  }
};

const getters = {
  canSendEmail: state => {
    return Boolean(state.currentCustomer?.email || state.currentCustomer?.attributes?.email)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
