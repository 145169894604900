<template>
  <span>
    {{ getValue }}
  </span>
</template>
<script>
  import { getNodeValue, isSummaryRow } from "@/components/ag-grid/columnUtils";

  export default {
    props: {
      column: {
        type: Object,
        default: () => ({}),
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      params: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      getValue() {
        let value = getNodeValue({
          params: this.params,
          row: this.row,
          column: this.column,
        })

         const isZeroValue = value === 0 || value === '0.00'
         const isSummary = isSummaryRow(this.params)

        if (value === '') {
          return value
        }

        const hideZero = this.params.hideZero || this.column.hideZero

        if (value === undefined || value === null) {
          return hideZero ? '' : '0%'
        }

        if (hideZero && isZeroValue && !isSummary) {
          return ''
        }

        value = parseFloat(value)

        if (!value) {
          return '0%'
        }
        return this.$formatPercent(value, {
          maximumFractionDigits: 2,
        })
      },
    }
  }
</script>
