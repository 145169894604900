<template>
  <BaseEntityFilterRow
    v-bind="$attrs"
    :title="$t('Employees')"
    :value="value"
    list-key="employee_ids"
    @change="resetFilters"
  >
    <template #default="{ selectList }">
      <EmployeeSelect
        v-if="selectList"
        v-model="value.employee_ids"
        :add-entity="false"
        :collapse-tags="true"
        :placeholder="$t('Select Employees')"
        :hide-label="true"
        value-key="id"
        multiple
      />
      <EmployeeRangeFilter
        v-else
        v-model="value"
        :as-row="false"
      />
    </template>
    <template #extra-filters>
      <slot name="extra-filters" />
    </template>
  </BaseEntityFilterRow>
</template>
<script>
import {
  EmployeeRangeFilter,
} from '@/components/range-filters'

export default {
  name: 'EmployeeFilterRow',
  components: {
    EmployeeRangeFilter,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    resetFilters() {
      this.value.employee_ids = [];
      this.value.employee_code_from = this.value.employee_code_to = null
      this.$emit('reset')
    }
  }
}
</script>
