<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :options="timezoneOptions"
    :placeholder="$t('Select Timezone')"
    />
</template>
<script>
import { timezoneOptions } from '@/enum/timezones'

export default {
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    filterMethod: {
      type: Function,
    },
  },
  data() {
    return {
      timezoneOptions,
    }
  },
}
</script>
