import get from "lodash/get";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";

// We'll use this later on for custom select search
export function rankedSearch({ options, query, labelKey, valueKey }) {
  query = query.toLowerCase()
  let filteredOptions = []

  options.forEach(option => {
    const primaryField = get(option, labelKey)?.toString()?.toLowerCase()
    if (primaryField.startsWith(query)) {
      filteredOptions.push({
        ...option,
        rank: 1,
      })
    } else if (primaryField.includes(query)) {
      filteredOptions.push({
        ...option,
        rank: 2,
      })
    } else {
      const keysToSearch = Object.keys(option).filter(key => {
        return !['id'].includes(key)
      })
      keysToSearch.forEach(key => {
        const value = option[key]?.toString()?.toLowerCase()
        if (value.includes(query)) {
          filteredOptions.push({
            ...option,
            rank: 3,
          })
        }
      })
    }
  })
  filteredOptions = uniqBy(filteredOptions, valueKey)
  return orderBy(filteredOptions, 'rank')
}

function searchByFields(data, fields, query) {
  const fieldValues = fields.map(field => get(data, field)?.toLowerCase())
  return fieldValues.some(value => value?.includes(query.toLowerCase()))
}

export function filterSelectOptions(fields) {
  return function (options, query) {
    return options.filter(option => {
      return searchByFields(option, fields, query)
    })
  }
}
