<template>
  <div>
    <BaseForm
      :loading="loading"
      :save-text="$t('Create recurring service invoice')"
      :update-text="$t('Update recurring service invoice')"
      :can-create-another-entity="!model.id"
      :submit-disabled="gridContext.loading"
      :show-cancel="true"
      layout="vertical"
      grid-classes="grid grid-cols-1 gap-x-4"
      ref="baseForm"
      @submit="onSubmit"
      @cancel="$router.push('/service-billing/recurring-service-invoices/pending')"
    >
      <ElCollapse
        v-model="expandedSections"
        class="col-span-1"
      >
        <BaseCollapseSection
          key="form-header"
          name="form-header"
          class="-m-6 mb-6"
          has-background
        >
          <template #title>
            <div class="flex flex-1 items-center justify-between form-header-summary">
              <div>
                <span class="mx-2">
                  {{ $t('Recurring Service Invoice') }}
                </span>
              </div>
              <div
                v-if="!expandedSections.includes('form-header')"
                class="summary"
              >
                {{ $t('Tax') }}
                <span>
                {{ $formatPercent(model.sales_tax_percent) }}
              </span>
              </div>
            </div>
          </template>
          <div class="grid grid-cols-8 gap-x-4 pt-5 px-6">
            <div class="col-span-6 grid grid-cols-6 gap-x-4">
              <WorkOrderSelect
                v-model="model.work_order_id"
                :add-entity="true"
                :url-params="woUrlParams"
                :disabled="!!$route.params.id"
                :showStatusColumn="false"
                :initial-value="get(data, 'relationships.workOrder') || selectedWorkOrder"
                id="wo-input"
                class="col-span-6 md:col-span-2"
                rules="required"
                @entity-change="onChangeWo"
              />
              <CustomerSelect
                v-model="model.customer_id"
                :key="model.work_order_id"
                :add-entity="false"
                :initial-value="get(data, 'relationships.customer', selectedCustomer)"
                class="col-span-6 md:col-span-2"
                rules="required"
                @entity-change="onChangeCustomer"
              />

              <BaseTextarea
                v-model="model.description"
                :label="$t('Description')"
                :placeholder="$t('Descriptions')"
                rules="max:500"
                class="col-span-6"
              />
              <!-- Invoice Configuration -->
              <div class="col-span-6">
                <h5 class="form-section-title">
                  {{ $t('Invoice Configuration') }}
                </h5>
                <div class="grid grid-cols-8 gap-x-3">
                  <div class="col-span-8 md:col-span-4">
                    <BillingTax
                      :model="model"
                      :data="data"
                      input-class="col-span-8 md:col-span-4"
                      @sales-tax-change="shouldEntriesChange"
                    />
                  </div>

                  <BillingRateTypeSelect
                    v-model="model.billing_rate_type_id"
                    :label="$t('Billing Rate')"
                    :add-entity="false"
                    :autocomplete="!data?.id"
                    class="col-span-6 md:col-span-2"
                  />
                  <BaseSwitch
                    v-model="model.should_post"
                    :label-info="$t('Post Automatically')"
                    :placeholder="$t('Post Automatically')"
                    id="should_post"
                    class="col-span-8 md:col-span-1"
                  />
                  <!-- Hidden for now, we don't support recurring prepaid yet
                  <BaseSwitch
                    v-model="model.is_prepaid"
                    :label-info="$t('Is Prepaid')"
                    :placeholder="$t('Is Prepaid')"
                    id="is_prepaid"
                    class="col-span-8 md:col-span-1"
                  /> 
                  <template v-if="model.is_prepaid">
                    <BankSelect
                      v-model="model.bank_id"
                      :name="$t('Bank')"
                      :label="$t('Bank')"
                      rules="required"
                      class="col-span-8 md:col-span-3"
                    />
                    <BaseAlert
                      class="col-span-8"
                    >
                      {{ $t('Check Date and Check Number will be generated automatically based on invoice date and number.') }}
                    </BaseAlert>
                  </template>
                  -->
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <WorkOrderPreview
                :key="selectedWorkOrder.id"
                :entity="selectedWorkOrder"
                class="flex-1 mb-5"
              />
            </div>
            <div class="col-span-full grid grid-cols-6 gap-x-4">
              <!-- Schedule + Terms -->
              <div class="col-span-6 lg:col-span-3">
                <BaseCollapseSection
                  key="schedule"
                  name="schedule"
                >
                  <template #title>
                    <div class="flex items-end space-x-2">
                      <h5 class="form-section-title">
                        {{ $t('Schedule') }}
                      </h5>
                      <div
                        v-if="!expandedSections.includes('schedule') && recurrenceRuleFormatted"
                        class="text-sm font-normal text-gray-500"
                      >
                        ({{  recurrenceRuleFormatted }})
                      </div>
                    </div>
                  </template>
                  <RecurringScheduleInputs
                    v-model="model.recurring_rule"
                    :start-date.sync="model.start_date"
                    :stop-type.sync="stopType"
                    :invoice="data"
                    :entity="$t('Billing')"
                    @first-occurence-changed="model.meta.work_date_from = $event"
                    @last-occurence-changed="model.meta.work_date_to = $event"
                    @recurrence-rule-formatted="recurrenceRuleFormatted = $event"
                  >
                    <template #additional-fields>
                      <div v-if="!isRecurringForever">
                        <BaseSwitch
                          v-model="model.close_wo_after"
                          :label-info="$t('Close W.O. After')"
                          :disabled="!model.work_order_id"
                          vertical
                          class="ml-4"
                        />
                      </div>
                    </template>
                  </RecurringScheduleInputs>
                  <div class="grid grid-cols-8 gap-4 mt-2">
                    <BaseDatePicker
                      v-model="model.meta.work_date_from"
                      :label="$t('Service Start Date')"
                      :placeholder="$t('Start Date')"
                      class="col-span-8 md:col-span-4"
                    />
                    <BaseDatePicker
                      v-model="model.meta.work_date_to"
                      :label="$t('Service End Date')"
                      :placeholder="$t('End Date')"
                      class="col-span-8 md:col-span-4"
                    />
                  </div>
                  <h5 class="form-section-title">
                    {{ $t('Terms (Due Date, Discount Date)') }}
                  </h5>
                  <div class="grid grid-cols-8 gap-4">
                    <BaseInput
                      v-model="model.due_date_days_after"
                      :label="$t('Due Date Days After Billing Date')"
                      :placeholder="$t('Due Date Days After Billing Date')"
                      :step="1"
                      :min="0"
                      type="number"
                      id="due_date_days_after"
                      class="col-span-8 md:col-span-3"
                    />
                    <BaseInput
                      v-model="model.discount_date_days_after"
                      :label="$t('Discount Date Days After Billing Date')"
                      :placeholder="$t('Discount Date Days After Billing Date')"
                      :step="1"
                      :min="0"
                      id="discount_date_days_after"
                      type="number"
                      rules="max_value:30|min_value:0"
                      class="col-span-8 md:col-span-3"
                    />
                  </div>
                  
                </BaseCollapseSection>
              </div>
              <!-- Email Options -->
              <div class="col-span-6 lg:col-span-3">
                <RecurringReceivableSendEmails
                  v-model="model"
                  :billing="data"
                  :billingType="billingTypes.Service"
                  :entity="StimulsoftPrintEntities.ServiceInvoice"
                  @update:sendAutomatically="onSendAutomaticallyChanged"
                />
              </div>
            </div>
          </div>
        </BaseCollapseSection>
      </ElCollapse>
      <ServiceInvoiceEntries
        resource-endpoint="/restify/recurring-billing-entries"
        parentEntityField="recurring_billing_id"
        :invoice="model"
        :customer-billing-rate-type-id="billingRateTypeId"
        :work-order-service-billing-code-ids="workOrderServiceBillingCodeIds"
        class="mt-3"
        ref="gridTable"
        @on-collapse-form-header="collapseFormHeader"
      />
    </BaseForm>
  </div>
</template>
<script>
import WorkOrderPreview from '@/modules/common/components/entity-preview/WorkOrderPreview.vue'
import ServiceInvoiceEntries from '@/modules/service-billing/components/ServiceInvoiceEntries.vue'
import BillingTax from "@/modules/accounts-receivable/components/billings/BillingTax.vue";
import RecurringScheduleInputs from "@/modules/accounts-payable/components/recurring-invoice/RecurringScheduleInputs.vue";
import RecurringReceivableSendEmails from '@/modules/common/components/recurring/RecurringReceivableSendEmails.vue'

import axios from 'axios'
import { resourceStatuses } from '@/enum/enums'
import { billingTypeAbbr, billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
import { RestifyResources } from "@/components/form/util";
import { shouldEntriesChange } from "@/modules/accounts-receivable/utils/billingUtils";
import { gridContext } from "@/components/ag-grid/gridContext";
import { StopType } from "@/modules/accounts-payable/components/recurring-invoice/recurringUtils";
import { StimulsoftPrintEntities } from '@/enum/stimulsoft';
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import { getTimezone } from "@/modules/payroll/utils/timeCardUtils";

const meta = {
  district_id: '',
  work_date_from: '',
  work_date_to: '',
  exempt_from_sales_tax: false,
}

const init_sending_options = {
  sender_email: '',
  template_id: null,
  flags: {
    print_additional_info: false,
  },
  options: {
    include_attachments: false,
    include_pdf: true,
    send_a_copy: false,
    bcc_email: '',
  }
}

export default {
  components: {
    BillingTax,
    WorkOrderPreview,
    ServiceInvoiceEntries,
    RecurringScheduleInputs,
    RecurringReceivableSendEmails,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    listPath: {
      type: String,
      default: '/service-billing/recurring-service-invoices/pending',
    },
  },
  data() {
    return {
      billingTypes,
      StimulsoftPrintEntities,
      loading: false,
      expandedSections: [],
      resourceStatuses,
      model: {
        work_order_id: '',
        customer_id: '',
        date: this.$now,
        due_date: null,
        discount_date: null,
        billing_rate_type_id: '',
        bank_id: '',
        description: '',
        discount_percent: 0,
        gross_amount: 0,
        sales_tax_percent: 0,
        retention_percent: 0,
        type: billingTypeAbbr.Service,
        meta,
        // recurring stuff
        recurring_rule: '',
        start_date: new Date(),
        due_date_days_after: 0,
        discount_date_days_after: 0,
        issued_days_before: 0,
        should_post: true,
        is_prepaid: false,
        close_wo_after: false,
        timezone: getTimezone(),
        // email
        send_automatically: true,
        recipients: [],
        sending_options: {
          ...init_sending_options,
        }
      },
      gridContext,
      customerBillingRateTypeId: '',
      selectedCustomer: {},
      selectedWorkOrder: {},
      workOrderServiceBillingCodeIds: [],
      recurrenceRuleFormatted: '',
      stopType: null,
      createdId: null,
    }
  },
  computed: {
    isRecurringForever() {
      return this.stopType === StopType.Never
    },
    woUrlParams() {
      return {
        sort: '-date',
        status: 'open',
        related: `${entityPreviewFields.Customer},serviceBillingCodes[id]`,
      }
    },
    woRelatedUrlParams() {
      return {
        related: `${entityPreviewFields.Customer},serviceBillingCodes[id]`,
      }
    },
    billingRateTypeId() {
      return this.model.billing_rate_type_id || this.customerBillingRateTypeId
    },
  },
  methods: {
    onChangeWo(workOrder) {
      const { attributes } = workOrder
      const { customer, serviceBillingCodes: sbCodes = [] } = workOrder?.relationships || {}

      this.workOrderServiceBillingCodeIds = sbCodes.map(code => code.id)

      this.customerBillingRateTypeId = this.get(customer, 'attributes.billing_rate_type_id')
      this.selectedWorkOrder = workOrder?.attributes || {}
      if (this.model.work_order_id === workOrder.id && this.model.id) {
        return
      }

      this.model.work_order_id = workOrder.id
      this.selectedCustomer = customer

      this.model.work_order_id = attributes.id
      this.model.customer_id = attributes.customer_id
      this.model.billing_rate_type_id = attributes.billing_rate_type_id
    },
    onSendAutomaticallyChanged(value) {
      if (value && !this.expandedSections.includes('email-options')) {
        this.expandedSections.push('email-options')
      }
      else if (!value && this.expandedSections.includes('email-options')) {
        this.expandedSections = this.expandedSections.filter(section => section !== 'email-options')
      }
    },
    async tryAssignWorkOrder(id) {
      try {
        this.loading = true
        const { data } = await axios.get(`/restify/work-orders/${id}`, { params: this.woRelatedUrlParams })
        this.onChangeWo(data)
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
    onChangeCustomer(customer) {
      this.selectedCustomer = customer
    },
    onChangeDistrict(district) {
      const { sales_tax_percent } = district?.attributes || {}
      this.model.meta.sales_tax_percent = sales_tax_percent ? sales_tax_percent: 0
    },
    async shouldEntriesChange() {
      await shouldEntriesChange(this.$refs.gridTable)
    },
    async getWorkOrder(woId) {
      const { data } = await axios.get(`/restify/work-orders/${woId}`, { params: this.woRelatedUrlParams })
      this.onChangeWo(data)
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        this.loading = true

        const data = {
          ...this.model,
          start_date: this.$formatDateOnly(new Date(this.model.start_date), ApiDateFormat),
        }

        delete data.tags
        delete data.bank_id // TODO: remove this line when prepaid is supported

        if (this.model.id) {
          delete data.gross_amount

          await this.$refs.gridTable.storeProgress(this.model.id)
          await axios.patch(`/restify/recurring-billings/${this.model.id}`, data)

          this.$success(this.$t('Recurring Service Invoice updated.'))
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.RecurringBillings,
          //   resourceId: this.model.id,
          //   isEdit: true
          // })
        } else {

          const { data: result } = await axios.post('/restify/recurring-billings', data)
          this.createdId = result.id
          await this.$refs.gridTable.storeProgress(result.id)

          this.$success(this.$t('Recurring Service Invoice created.'))
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.RecurringBillings,
          //   resourceId: data.id,
          // })
          if (this.$createAnotherEntity) {
            return this.$emit('create-another')
          }
          this.$router.push(this.listPath)
        }
      } catch (err) {
        console.warn(err)
        if (this.createdId) {
          await axios.delete(`/restify/recurring-billings/${this.createdId}`)
          this.createdId = null
        }

        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not store Recurring Service Invoice'))
      } finally {
        this.loading = false
      }
    },
    async collapseFormHeader() {
      if (!this.expandedSections.length) {
        return
      }

      const isValidForm = await this.$refs.baseForm?.validate()

      if (!isValidForm) {
        return
      }

      this.expandedSections = []
    },
    expandFormHeader() {
      this.expandedSections = ['form-header', 'schedule', 'email-options']
    },
    async initCreateModel() {
      if (this.$route.query.customer_id) {
        this.model.customer_id = this.$route.query.customer_id
      }

      this.expandFormHeader()
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          this.initCreateModel()
          return
        }

        this.model = {
          ...this.model,
          ...meta,
          ...value.attributes,
        }

        if (!this.model.sending_options || Array.isArray(this.model.sending_options)) {
          this.model.sending_options = {
            ...init_sending_options,
          }
        }

        if (this.model.work_order_id) {
          this.getWorkOrder(this.model.work_order_id)
        }
        this.collapseFormHeader()

        const { district, customer } = value.relationships
        this.selectedCustomer = customer
        if (!district) {
          return
        }

        this.onChangeDistrict(district)
      },
    },
    '$route.params': {
      immediate: true,
      handler(params) {
        const { workOrder } = params
        if (!workOrder) {
          return
        }

        this.tryAssignWorkOrder(workOrder)
      },
    },
    stopType: {
      async handler(value) {
        await this.$nextTick()

        if (this.stopType === StopType.Never) {
          this.model.close_wo_after = false
        }
      },
      immediate: true,
    },
  },
}
</script>
