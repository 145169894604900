<template>
  <div>
    <BaseButton
      v-bind="$attrs"
      variant="primary-light"
      :loading="loading"
      @click="openDialog"
    >
      <PrinterIcon class="w-5 h-5 mr-2" />
      <span>{{ printButtonText }}</span>
    </BaseButton>

    <BaseFormDialog
      v-if="showDialog"
      :open.sync="showDialog"
      :title="$t('Print Unused Blank Checks')"
      :appendToBody="true"
      @close="$emit('close')"
    >
      <BaseForm
        :save-text="$t('Print')"
        :loading="loading"
        :focus-on-first-input="false"
        layout="modal"
        :showCancel="true"
        @cancel="showDialog = false; $emit('close')"
        @submit="onSubmit"
      >

        <div class="col-span-6 md:col-span-3">
          <BankSelect
            :value="props.bank.id"
            :disabled="true"
            :editEntity="false"
            class="flex-1"
          />
        </div>
        <div class="col-span-6 md:col-span-3">
          <BaseDatePicker
            v-model="model.date"
            :placeholder="$t('Check Date')"
            :label="$t('Check Date')" 
            :name="$t('Check Date')"
            class="col-span-6"
            clearable
          />
        </div>
        <div class="items-center text-sm text-gray-800 col-span-full mb-4 md:mb-2">
          <div class="font-medium">{{ $t('Check Numbers') }}</div>
          <div>
            {{ selectedChecksDescription }}
          </div>
        </div>
        <div class="col-span-6">
          <BaseCheckbox
            id="include_signature"
            v-model="model.include_signature"
          >
            <template #label>
              {{ $t('Print Signature') }}
              <template v-if="model.include_signature">
                {{ $t(' (if the bank has a signature, the signature will be included on each check)') }}
              </template>
            </template>
          </BaseCheckbox>
        </div>
        <div class="col-span-6">
          <BaseCheckbox
            id="cut_section"
            v-model="model.flags.cut"
            :label="$t('Show cut section with scissors?')"
          />
        </div>
      </BaseForm>
    </BaseFormDialog>
    <StimulsoftPrintButton
      ref="stimulsoftPrint"
      method="post"
      :url="`/blank-checks/stimulsoft`"
      :url-data="printParams"
      :hide-button="true"
      entity="blank-check"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, PropType } from "vue";
import i18n from "@/i18n";
import { info } from "@/components/common/NotificationPlugin";
import { PrinterIcon } from "vue-feather-icons";
import { Bank } from '@/modules/common/types/models'
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import { formatDate } from "@/plugins/dateFormatPlugin";
import Data = API.Data;

const props = defineProps({
  blankChecks: {
    type: Array,
    default: () => [],
  },
  bank: {
    type: Object as PropType<Data<Bank>>,
    required: true,
  },
  buttonText: {
    type: String,
    default: ''
  }
})

const loading = ref(false)
const showDialog = ref(false)

const model = ref({
  include_signature: false,
  date: formatDate(new Date(), ApiDateFormat),
  flags: {
    cut: true,
  }
})

const printParams = computed(() => {
  let repositories, bank_id
  if (props.blankChecks?.length) {
    repositories = props.blankChecks.map((c: any) => c.id)
  }
  else {
    bank_id = props.bank.id
  }

  return {
    include_signature: Number(model.value.include_signature),
    flags: model.value.flags,
    date: model.value.date,
    repositories,
    bank_id,
  }
})

const printButtonText = computed(() => {
  if (props.buttonText) {
    return props.buttonText
  }

  if (props.blankChecks.length) {
    return i18n.t('Print Selected Checks')
  }

  return i18n.t('Print All Unused Checks')
})

const selectedChecksDescription = computed(() => {
  if (!props.blankChecks.length) {
    return i18n.t('All Unused Blank Checks')
  }

  const displayMax = 7
  const checkNumbers = props.blankChecks.map((c: any) => c.number || c.attributes.number)

  if (props.blankChecks.length <= displayMax) {
    return checkNumbers.join(', ')
  }

  const countMore = i18n.tc('and count more', undefined, {
    count: props.blankChecks.length - displayMax,
  })

  return `${checkNumbers.slice(0, displayMax).join(', ')} (${countMore})`

})

async function onSubmit() {
  await previewPDF()
}

const stimulsoftPrint = ref()

async function previewPDF() {
  if (!stimulsoftPrint.value) {
    info(i18n.t('The PDF preview module is still initializing... Please wait a few more seconds and try again.'))
    return
  }
  try {
    loading.value = true
    await stimulsoftPrint.value?.triggerPreview()
  } finally {
    loading.value = false
  }
}

function resetModel() {
  model.value = {
    include_signature: false,
    date: formatDate(new Date(), ApiDateFormat),
    flags: {
      cut: true,
    }
  }
}

function openDialog() {
  resetModel()
  showDialog.value = true
}

defineExpose({
  openDialog,
})
</script>
