<template>
  <div>
    <AgDataTable
      url="/restify/timesheet-entries"
      :url-params="finalUrlParams"
      :columns="columns"
      :groupDefaultExpanded="-1"
      :groupIncludeFooter="true"
      :groupIncludeTotalFooter="true"
      :suppressAggFuncInHeader="true"
      :transform-data="transformData"
      domLayout="autoHeight"
    >
      <template #attributes.date="{row, params}">
        <div>
        <span v-if="row">
          {{ row.attributes?.notes }}
        </span>
          <span v-if="!row && params.node.level === 0">
            {{ $t('Totals') }}
          </span>
          <span v-if="!row && params.node.level === -1">
            {{ $t('Grand Totals') }}
          </span>
        </div>
      </template>
      <template #attributes.special_source="{row}">
        <EquipmentLink
          v-if="row.attributes.special_source?.type === codeTypes.EQUIPMENT"
          :id="row.attributes.special_source?.id"
        />
        <SpecialSourceLink v-else :data="row.attributes.special_source"/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
import parse from "date-fns/parse";
import format from "date-fns/format";
import { LongDateFormat } from "@/plugins/dateFormatPlugin";
import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";
import TimesheetDialog from "@/modules/payroll/components/timesheets/TimesheetDialog.vue";
import TimesheetStatus from "@/components/table/cells/TimesheetStatus.vue";
import SpecialSourceLink from "@/components/links/SpecialSourceLink.vue";
import { codeTypes } from "@/modules/payroll/components/rates/util";

export default {
  components: {
    TimesheetStatus,
    SpecialSourceLink,
    TimesheetDialog,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      LongDateFormat
    }
  },
  computed: {
    codeTypes() {
      return codeTypes
    },
    finalUrlParams() {
      return {
        related: 'timesheet',
        sort: '-date',
        ...this.urlParams,
      }
    },
    columns() {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'attributes.employee_id',
          component: 'EmployeeLink',
          minWidth: 200,
        },
        {
          headerName: this.$t('Equipment'),
          field: 'attributes.special_source',
          minWidth: 250,
          maxWidth: 400,
        },
        {
          headerName: this.$t('Line Item'),
          field: 'attributes.addl_source_id',
          component: 'AddlSourceLink',
          minWidth: 130,
          maxWidth: 350,
        },
        {
          headerName: this.$t('Duration'),
          field: 'attributes.special_source.units',
          minWidth: 130,
          maxWidth: 200,
          valueFormatter: params => {
            const units = params.value || 0
            return minutesToHours(units * 60)
          },
          aggFunc: 'sum',
        },
      ]
    },
  },
  methods: {
    transformData(data) {
      const rows = []
      data.forEach(row => {
        row.attributes.special_sources.forEach(specialSource => {
          rows.push({
            ...row,
            id: crypto.randomUUID(),
            attributes: {
              ...row.attributes,
              special_source: {
                ...specialSource,
                special_source_id: specialSource.id,
                special_source_type: specialSource.type,
              },
            },
          })
        })
      })
      return rows
    }
  }
}
</script>
