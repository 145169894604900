<template>
  <base-data-table
      ref="table"
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      class="job-cost-detail"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader
        :header="data.header"
      />
    </template>

    <template #header="{row}">
      <JobLink
          :data="row.header"
          :show-preview="false"
      />
    </template>
    <template #total_type_summary="{row}">
      <div class="flex flex-col justify-end">
        <div>{{ $t('Grand Totals selected Jobs: Cost:') }}</div>
        <div class="my-3">{{ $t('Billed:') }}</div>
        <div>{{ $t('Billed Less Cost:') }}</div>
      </div>
    </template>

    <template v-for="column in amountColumns" #[getSummarySlotName(column)]>
      <div v-html="getTotals(column.prop)"/>
    </template>

  </base-data-table>
</template>
<script>

  import sumBy from 'lodash/sumBy'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import { jobCostDetailPrintOptions } from '@/modules/job-costing/components/reports/util'

  const amountTypes = {
    Cost: 'cost',
    Income: 'income',
    IncomeLessCost: 'income_less_cost',
  }

  export default {
    extends: ReportTableWrapper,
    props: {
      totalBy: {
        type: String,
        default: jobCostDetailPrintOptions.JobSummary,
      }
    },
    data() {
      return {
        amountTypes,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Job'),
            prop: 'job',
            hiddenValue: true,
          },
          {
            label: this.$t('Description'),
            prop: 'description',
            hiddenValue: true,
          },
          {
            label: '',
            prop: 'total_type',
            align: 'right',
          },
          {
            label: this.$t('Total <br> Budget <br> (1)'),
            prop: 'amount',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Prior to <br> Period <br> (2)'),
            prop: 'prior',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Period <br> Amount <br> (3)'),
            prop: 'period',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Total to end <br> of period <br> (2 + 3)'),
            prop: 'prior_plus_period',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Total job <br> to-date <br> (4)'),
            prop: 'amount_to_date',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
          },
          {
            label: this.$t('Remaining <br> budget <br> (1 - 4)'),
            prop: 'remaining',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedPrice',
            align: 'right',
          },
        ]
      },
      costEntries() {
        return this.getData.filter(row => row.type === amountTypes.Cost)
      },
      incomeEntries() {
        return this.getData.filter(row => row.type === amountTypes.Income)
      },
      incomeLessCostEntries() {
        return this.getData.filter(row => row.type === amountTypes.IncomeLessCost)
      },
    },
    methods: {
      composeRows(data) {
        data.forEach(item => {
          this.rows.push({
            header: {
              id: item.id,
              number: item.number,
              description: item.description,
            },
          })

          const row = [
            {
              total_type: this.$t('Cost:'),
              type: amountTypes.Cost,
              ...item[amountTypes.Cost],
            },
            {
              total_type: this.$t('Billed:'),
              type: amountTypes.Income,
              ...item[amountTypes.Income],
            },
            {
              total_type: this.$t('Billed Less Cost:'),
              type: amountTypes.IncomeLessCost,
              ...item[amountTypes.IncomeLessCost],
            },
          ]

          this.rows.push(...row)
        })

        return this.rows
      },
      getTotals(prop = 'amount') {
        const costTotal = sumBy(this.costEntries, prop)
        const incomeTotal = sumBy(this.incomeEntries, prop)
        const incomeLessCostTotal = sumBy(this.incomeLessCostEntries, prop)

        return `
          <dl>
            <dt>${ this.$formatPrice(costTotal) }</dt>
            <dt class="my-3">${ this.$formatPrice(incomeTotal) }</dt>
            <dt>${ this.$formatPrice(incomeLessCostTotal) }</dt>
          </dl>
        `
      },
    },
  }
</script>
