<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #thead-infos>
      <th colspan="3"/>
      <th colspan="2" align="center" class="thead-infos">
        <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
          {{ $t('Expense To') }}
        </span>
      </th>
      <th/>
      <th colspan="2" align="center" class="thead-infos">
        <span class="flex w-full justify-center mr-2 border-b-2 border-dashed pb-2">
          {{ $t('Payment') }}
        </span>
      </th>
      <th></th>
      <th></th>
      <th></th>
    </template>

    <template #header="{row, index}">
      <div class="flex"
           :class="{
              'top-border pt-4': index > 0 && row.header?.job,
           }"
      >
        <JobLink
            :data="row.header?.job"
            :show-preview="false"
            class="w-1/3"
        />
        <VendorLink
            :data="row.header?.vendor"
            :show-preview="false"
            class="w-2/3 pl-10"
            :class="{
              'top-border pt-4': index > 0 && !row.header?.job,
           }"
        />
      </div>
    </template>

    <template #invoice.journal.period="{row}">
      <span v-if="row.invoice?.journal?.period">
        {{ row.invoice.journal.period + ' ' + row.invoice.journal.fiscal_year }}
      </span>
      <span v-else></span>
    </template>

    <template #journal.name="{row}">
      <JournalLink
        v-if="row.journal?.id"
        :data="row.journal"
      />
    </template>

    <template #payment.journal.name="{row}">
      <JournalLink
        v-if="row.payment?.journal?.id"
        :data="row.payment?.journal"
      />
    </template>

    <template #journal.period="{row}">
      <span v-if="row.journal?.period">
        {{ row.journal.period + ' ' + row.journal.fiscal_year }}
      </span>
      <span v-else></span>
    </template>

    <template #payment.bnk_chk="{row}">
      <div class="flex items-center justify-between">
        <BankLink
            :id="get(row, 'bank.id')"
            displayOnlyInitials
        />
        <span>{{ row.payment?.number }}</span>
      </div>
    </template>

    <template #html-row="{htmlData}">
      <tr class="content-row">
        <td colspan="3" align="right">
          {{ $t('Vendor Totals:') }}
        </td>
        <td colspan="7"/>
        <td class="top-border" align="right">
          {{ $formatPrice(htmlData.payments_net_amount) }}
        </td>
      </tr>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="3" align="right">
        {{ $t('Job Totals:') }}
      </td>
      <td colspan="8" align="right">
        {{$formatPrice(subtotal.payments_net_amount)}}
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'

  export default {
    extends: ReportTableWrapper,
    props: {
      data: {
        required: true,
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Invoice <br> Number'),
            prop: 'invoice.invoice.number',
            component: 'EntityLink',
            redirectTo: '/accounts-payable/invoices/{ID}/view',
            entityKey: 'invoice.id',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            label: this.$t('Invoice <br> Date'),
            prop: 'invoice.invoice.date',
            component: 'FormattedDate',
            minWidth: 100,
            maxWidth: 110,
          },
          {
            label: this.$t('Description'),
            prop: 'invoice.invoice.description',
            minWidth: 120,
            maxWidth: 150,
            summary: () => this.$t('Grand Totals:'),
          },
          {
            label: this.$t('Period'),
            prop: 'invoice.journal.period',
            align: 'center',
            minWidth: 80,
            maxWidth: 100,
          },
          {
            label: this.$t('Journal'),
            prop: 'invoice.journal.name',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            label: this.$t('Gross <br> Amount'),
            prop: 'invoice.totals.gross_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            label: this.$t('Period'),
            prop: 'journal.period',
            align: 'center',
            minWidth: 100,
            maxWidth: 120,
          },
          {
            label: this.$t('Journal'),
            prop: 'journal.name',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            label: this.$t('Bnk/Chk'),
            prop: 'payment.bnk_chk',
            minWidth: 60,
            maxWidth: 70,
            summary: () => this.$t('(includes partial payments)'),
          },
          {
            label: this.$t('Pay Date'),
            prop: 'payment.date',
            component: 'FormattedDate',
            align: 'center',
            minWidth: 100,
            maxWidth: 110,
          },
          {
            label: this.$t('Payment <br> Amount'),
            prop: 'payment.net_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 100,
            maxWidth: 120,
            summary: () => this.$formatPrice(this.data.grand_totals?.payments_net_amount)
          },
        ]
      },
    },
    methods: {
      composeRows(data) {
        data.forEach(group => {

          group.vendors?.forEach((vendorObject, vendorIndex) => {

            if (vendorIndex === 0) {
              this.rows.push({
                header: {
                  job: group.job,
                  vendor: vendorObject.vendor,
                },
              })
            } else {
              this.rows.push({
                header: {
                  vendor: vendorObject.vendor,
                },
              })
            }

            vendorObject.invoices?.forEach(invoiceObject => {

              invoiceObject?.payments?.forEach(paymentObject => {

                this.rows.push({
                  ...paymentObject,
                  invoice: invoiceObject,
                })

              })

            })

            if (vendorObject.invoices?.length > 1) {
              this.rows.push({
                htmlData: { ...vendorObject.totals },
              })
            }
          })

          if (group.vendors?.length > 1) {
            this.rows.push({
              subtotal: group.job
            })
          }

        })
      },
    }
  }
</script>
