<template>
  <div>
    <BaseForm
      ref="baseForm"
      :loading="loading"
      :can-create-another-entity="!model.id"
      :save-text="$t('Create Repair Order')"
      :update-text="$t('Update Repair Order')"
      :layout="layout"
      :sticky-header="expandedSections.length === 0"
      :show-cancel="showCancel"
      @submit="onSubmit"
      @cancel="onCancel"
    >
      <template #header>
        <ElCollapse
          v-model="expandedSections"
          class="col-span-1"
        >
          <BaseCollapseSection
            name="form-header"
            class="-m-6 mb-6"
            has-background
          >
            <template #title>
              <div
                class="flex flex-1 items-center justify-between form-header-summary"
              >
                <div class="pl-2">
                  {{ $t('Repair Order') }}
                  <span v-if="model.number">
                    #{{ model.number }}
                  </span>
                </div>
              <div
                v-if="!expandedSections.includes('form-header')"
                class="flex space-x-2 items-center"
              >
                <div
                  v-if="selectedEquipment"
                  class="summary"
                >
                  {{ $t('Equipment') }}:
                  <span>
                    {{ `${selectedEquipment.code} (${selectedEquipment.description})` }}
                  </span>
                </div>
                <div
                  v-if="selectedEmployee"
                  class="summary"
                >
                  {{ $t('Assigned To') }}:
                  <span>
                    {{ `${selectedEmployee.code} (${selectedEmployee.name})` }}
                  </span>
                </div>
              </div>
              </div>
            </template>
            <div class="grid grid-cols-8 gap-x-4 p-4">
              <EquipmentSelect
                v-model="model.equipment_id"
                class="col-span-8 md:col-span-2 lg:col-span-2"
                rules="required"
                @entity-change="onEquipmentChanged"
              />
              <EmployeeSelectNew
                v-model="model.employee_id"
                :label="$t('Assigned To')"
                :placeholder="$t('Select Employee')"
                class="col-span-8 md:col-span-2 lg:col-span-1"
                @entity-change="onEmployeeChanged"
              />

              <BaseSelect
                v-model="model.status"
                :label="$t('Status')"
                :placeholder="$t('Status')"
                :options="statusOptions"
                class="col-span-6 md:col-span-2 lg:col-span-1"
                id="status"
              />
              <BaseDatePicker
                v-model="model.due_date"
                :label="$t('Due Date')"
                :placeholder="$t('Due Date')"
                class="col-span-8 md:col-span-2 lg:col-span-1"
                id="due_date"
              />
              <div class="col-span-full" />
              <BaseTextarea
                v-model="model.description"
                :label="$t('Description')"
                :placeholder="$t('Description')"
                class="col-span-8 md:col-span-4"
                id="description"
                rules="max:150"
              />
              <div class="col-span-full" />
              <BaseCollapseSection
                v-if="model.id"
                :title="$t('Costs To-Date')"
                class="col-span-8 md:col-span-4"
                name="actual-costs"
              >
                <div class="grid grid-cols-8 gap-x-4 -ml-2">
                  <p class="text-sm text-gray-500 col-span-8 mb-4">
                    {{ $t('Actual Costs To-Date for this Repair Order.') }}
                  </p>
                  <BaseInput
                    :value="model.quantity_to_date"
                    :label="$t('Cost Hours')"
                    readonly
                    type="number"
                    format="hours"
                    :placeholder="$t('Hours')"
                    class="col-span-8 md:col-span-4 lg:col-span-3"
                    id="cost_hours"
                  />
                  <BaseInput
                    :value="model.amount_to_date"
                    :label="$t('Cost Amount')"
                    readonly
                    type="number"
                    format="price"
                    :placeholder="$t('Miles')"
                    class="col-span-8 md:col-span-4 lg:col-span-3"
                    id="mileage"
                  />
                </div>
              </BaseCollapseSection>
            </div>
          </BaseCollapseSection>
        </ElCollapse>
      </template>

      <div class="col-span-full">
        <RepairOrderEntries
          ref="entriesTable"
          :repairOrder="model"
          @collapse-form-header="tryCollapseFormHeader"
        />
      </div>
    </BaseForm>
  </div>
</template>
<script>
import axios from 'axios'
import { resourceStatuses } from '@/enum/enums'
import { globalResources, RestifyResources } from "@/components/form/util";
import { repairOrderStatusOptions } from "@/modules/equipment/util/repairOrderUtils";

import RepairOrderEntries from '@/modules/equipment/components/repair-orders/RepairOrderEntries.vue'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'

export default {
  components: {
    RepairOrderEntries,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    redirectToListAfterStore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalResources,
      RestifyResources,
      loading: false,
      showCancel: true,
      showBack: true,
      expandedSections: [],
      model: {
        equipment_id: null,
        number: '',
        status: resourceStatuses.ToDo,
        employee_id: null,
        due_date: null,
        usage_hours: 0,
        mileage: 0,
        description: '',
        quantity_to_date: 0,
        amount_to_date: 0,
      },
      statusOptions: repairOrderStatusOptions,
      selectedEquipment: null,
      selectedEmployee: null,
      createdId: null,
    }
  },
  computed: {
    layout() {
      return this.redirectToListAfterStore ? 'vertical' : 'modal'
    },
    equipmentHours() {
      return this.selectedEquipment?.maintenance_hours_to_date || 0
    },
    equipmentMileage() {
      return this.selectedEquipment?.mileage || 0
    },
  },
  methods: {
    onEquipmentChanged(equipment) {
      this.selectedEquipment = equipment?.attributes
    },
    onEmployeeChanged(employee) {
      this.selectedEmployee = employee
    },
    onCancel() {
      if (this.redirectToListAfterStore) {
        return this.$router.push('/equipment/list')
      }
      this.$emit('cancel')
    },
    async tryCollapseFormHeader() {
      if (!this.expandedSections.length) {
        return
      }

      const isValidForm = await this.$refs.baseForm?.validate()

      if (!isValidForm) {
        return
      }

      this.expandedSections = []
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        this.loading = true

        const payload = {
          ...this.model,
        }

        if (this.model.id) {
          await axios.put(`/restify/repair-orders/${this.model.id}`, payload)
          await this.$refs.entriesTable.storeProgress(this.model.id)

          this.$success(this.$t('Repair Order updated successfully'))
        }
        else {
          const { data } = await axios.post('/restify/repair-orders', payload)
          this.createdId = data.id
          await this.$refs.entriesTable.storeProgress(data.id)

          this.$success(this.$t('Repair Order created successfully'))
          if (this.$createAnotherEntity) {
            return this.$emit('create-another')
          }

          this.$router.push('/equipment/repair-orders')
        }
      }
      catch (err) {
        console.warn(err)
        if (this.createdId) {
          await axios.delete(`/restify/repair-orders/${this.createdId}`)
          this.createdId = null
        }
        if (err?.handled) {
          return
        }
        this.$error(this.$t('Could not store repair order'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          this.expandedSections = ['form-header', 'track-usage']
          return
        }

        this.model = {
          ...this.model,
          ...value.attributes,
        }

        this.selectedEquipment = value.relationships?.equipment?.attributes || null
        this.selectedEmployee = value.relationships?.employee?.attributes || null
      },
    },
  },
}
</script>
