import i18n from '@/i18n'

export const BankAccountTypes = {
  Checking: 'checking',
  Savings: 'savings',
  CreditCard: 'credit_card',
}

export const BankUsedInTypes = {
  AccountsPayable: 'AP',
  AccountsReceivable: 'AR',
  Payroll: 'PR',
}

export const BankUsedInOptions = [
  {
    label: i18n.t('Accounts Payable'),
    value: BankUsedInTypes.AccountsPayable,
  },
  {
    label: i18n.t('Accounts Receivable'),
    value: BankUsedInTypes.AccountsReceivable,
  },
  {
    label: i18n.t('Payroll'),
    value: BankUsedInTypes.Payroll,
  }
]

export const bankAccountTypes = [
  {
    label: i18n.t('Checking'),
    value: BankAccountTypes.Checking,
  },
  {
    label: i18n.t('Savings'),
    value: BankAccountTypes.Savings,
  },
  {
    label: i18n.t('Credit Card'),
    value: BankAccountTypes.CreditCard,
  },
]

export const ratesEnum = {
  OvertimeMultiplier: 1.5,
  PremiumMultiplier: 2
}

export const reservedPayCodes = {
  RegularPay: 'REGPAY',
  OvertimePay: 'OVTPAY',
  PremiumPay: 'PRMPAY',
}

export const reservedPayCodeList = Object.values(reservedPayCodes)

export const jobTableOverrides = {
  OverrideAlways: 'always',
  OverrideIfGreater: 'if-greater',
  OverrideNever: 'never',
}

export const payFrequencies = {
  WEEKLY: 'weekly',
  BI_WEEKLY: 'bi-weekly',
  MONTHLY: 'monthly',
  SEMI_MONTHLY: 'semi-monthly',
  DAILY: 'daily',
}

export const employeeStatuses = {
  HOURLY: 'hourly',
  SALARY: 'salary',
  INACTIVE: 'inactive',
  DECEASED: 'deceased',
}

export const taxTypes = {
  STATE_WITHHOLDING: 'income-tax',
  STATE_DISABILITY: 'disability-insurance',
  STATE_UNEMPLOYMENT: 'unemployment-insurance',
  LOCAL_WITHHOLDING: 'income-tax',
  FICA: 'fica',
  FEDERAL_UNEMPLOYMENT: 'federal-unemployment',
}

export const federalFilingStatuses = {
  MARRIED_1_OR_LESS: 'married-1-or-less',
  MARRIED_2_OR_MORE: 'married-2-or-more',
  MARRIED: 'married',
  SINGLE: 'single',
  HEAD_OF_HOUSEHOLD: 'head-of-household',
  PERCENT_OF_STATE: 'percent-of-state',
  PERCENT_OF_FEDERAL: 'percent-of-federal',
  EXEMPT: 'exempt',
  EXEMPT_FICA: 'exempt-fica',
}

export const federalFilingStatusesDictionary = {
  [federalFilingStatuses.MARRIED_1_OR_LESS]: i18n.t('Married filing separately'),
  [federalFilingStatuses.MARRIED_2_OR_MORE]: i18n.t('Married filing jointly'),
  [federalFilingStatuses.MARRIED]: i18n.t('Married'),
  [federalFilingStatuses.SINGLE]: i18n.t('Single'),
  [federalFilingStatuses.HEAD_OF_HOUSEHOLD]: i18n.t('Head of Household'),
  [federalFilingStatuses.PERCENT_OF_STATE]: i18n.t('Percent of State'),
  [federalFilingStatuses.PERCENT_OF_FEDERAL]: i18n.t('Percent of Federal'),
  [federalFilingStatuses.EXEMPT]: i18n.t('Exempt'),
  [federalFilingStatuses.EXEMPT_FICA]: i18n.t('Exempt FICA'),
}

export const taxAdjustments = {
  NO_ADJUSTMENT: 'no-adjustment',
  ADDITIONAL_AMOUNT: 'additional-amount',
  FIXED_AMOUNT: 'fixed-amount',
  ADDITIONAL_GROSS_PERCENTAGE: 'additional-gross-percentage',
  ADDITIONAL_TAX_PERCENTAGE: 'additional-tax-percentage',
  FIXED_PERCENTAGE: 'fixed-percentage',
}

export const weekDays = [
  {
    label: i18n.t('Monday'),
    value: 1,
  },
  {
    label: i18n.t('Tuesday'),
    value: 2,
  },
  {
    label: i18n.t('Wednesday'),
    value: 3,
  },
  {
    label: i18n.t('Thursday'),
    value: 4,
  },
  {
    label: i18n.t('Friday'),
    value: 5,
  },
  {
    label: i18n.t('Saturday'),
    value: 6,
  },
  {
    label: i18n.t('Sunday'),
    value: 7,
  }
]

export const billingRateSources = {
  Material: 'material',
  Equipment: 'equipment',
  CraftCode: 'craft-code',
}

export const equipmentTypeWithMaintenance = 'REP'
export const equipmentTypeWithQuantity = 'FUL'

export const uniqueJobTypeAbbreviations = {
  // * cost types
  Subcontract: 'SUB',
  Equipment: 'EQP',
  Labor: 'LAB',
  Material: 'MTL',
  // * income types
  ProgressBilling: 'PRG',
  LumpSumBilling: 'LMP',
  UnitPriceBilling: 'UPB',
  CostPlusBilling: 'CPB',
}

export const jobLineItemTypes = {
  Cost: 'cost',
  Income: 'cost-retention',
}

export const costTypes = {
  Income: 'income',
  Cost: 'cost',
}

export const resourceStatuses = {
  Posted: 'posted',
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
  Review: 'review',
  ReviewAdjustments: 'review_adjustments',
  Open: 'open',
  Paid: 'paid',
  Invoiced: 'invoiced',
  NotInvoiced: 'not_invoiced',
  Computed: 'computed',
  Answered: 'answered',
  PartialPaid: 'partially_paid',
  PartiallyVoided: 'partially_voided',
  All: 'all',
  Voided: 'voided',
  VoidPending: 'void_pending',
  NoPost: 'no_post',
  NoCompute: 'no_compute',
  Closed: 'closed',
  Recurring: 'recurring',
  Normal: 'normal',
  Prepaid: 'prepaid',
  Hold: 'hold',
  Active: 'active',
  Inactive: 'inactive',
  Confirmed: 'confirmed',
  AwaitingMaterials: 'awaiting-materials',
  ToDo: 'todo',
  InProgress: 'in-progress',
  Completed: 'completed',
  Cancelled: 'cancelled',
}

export const journalActions = {
  Post: 'post',
  Void: 'void',
}

export const workOrderStatuses = {
  Open: 'open',
  Closed: 'closed',
  Recurring: 'recurring',
  Completed: 'completed',
  Inactive: 'inactive',
  HoldParts: 'hold_parts',
  HoldOther: 'hold_other',
}

export const workOrderStatusOptions = [
  {
    label: i18n.t('Open'),
    value: workOrderStatuses.Open,
  },
  {
    label: i18n.t('Closed'),
    value: workOrderStatuses.Closed,
  },
  {
    label: i18n.t('Inactive'),
    value: workOrderStatuses.Inactive,
  },
  {
    label: i18n.t('Completed'),
    value: workOrderStatuses.Completed,
  },
  {
    label: i18n.t('Recurring'),
    value: workOrderStatuses.Recurring,
  },
  {
    label: i18n.t('Hold Parts'),
    value: workOrderStatuses.HoldParts,
  },
  {
    label: i18n.t('Hold Other'),
    value: workOrderStatuses.HoldOther,
  },
]

export const purchaseOrderStatuses = {
  Open: 'open',
  Closed: 'closed',
  Pending: 'pending',
  NoPost: 'no_post',
}

export function getPurchaseOrderStatuses(purchaseOrder) {
  const status = purchaseOrder?.attributes?.status
  if (!status || [purchaseOrderStatuses.Pending, purchaseOrderStatuses.NoPost].includes(status)) {
    return [
      {
        label: i18n.t('Pending'),
        value: purchaseOrderStatuses.Pending,
      },
      {
        label: i18n.t('No Post'),
        value: purchaseOrderStatuses.NoPost,
      }
    ]
  }
  return [
    {
      label: i18n.t('Open'),
      value: purchaseOrderStatuses.Open,
    },
    {
      label: i18n.t('Closed'),
      value: purchaseOrderStatuses.Closed,
    }
  ]
}


export const appModule = {
  GeneralLedger: 'ledger',
  JobCosting: 'job-costing',
  AccountsPayable: 'accounts-payable',
  AccountsReceivable: 'accounts-receivable',
  Payroll: 'payroll',
  ServiceBilling: 'service-billing',
  InventoryManagement: 'purchasing-inventory',
  EquipmentManagement: 'equipment',
  Timesheets: 'timesheets',
  Estimating: 'estimates',
  Settings: 'settings',
  PurchasingInventory: 'inventory-management',
}

export const $modules = {
  GL: 'general-ledger',
  JC: appModule.JobCosting,
  AP: appModule.AccountsPayable,
  AR: appModule.AccountsReceivable,
  PR: appModule.Payroll,
  SB: appModule.ServiceBilling,
  IV: appModule.PurchasingInventory,
  INV: appModule.InventoryManagement,
  EST: appModule.Estimating,
  EQP: appModule.EquipmentManagement,
  TSHEET: appModule.Timesheets,
  GENERAL: 'general',
  SETTINGS: 'settings',
  LG: 'ledger', // * ledger chunk - used for match route vs current module
}

export const taggableInputStates = {
  Automatic: 'A',
  Manual: 'M',
}

export const addressTypes = {
  Billing: 'billing',
  Business: 'business',
  ConstructionLender: 'construction-lender',
  Home: 'home',
  Headquarters: 'hq',
  Shipping: 'shipping',
  Warehouse: 'warehouse',
  Job: 'jobsite',
}

export const masterJobKey = 'MASTER'

export const laborHourTypes = {
  Regular: 'regular-hours',
  Overtime: 'overtime-hours',
  Premium: 'premium-hours',
}

export const transactionTypes = {
  Cost: 'cost',
  CostRetention: 'cost-retention',
  Income: 'income',
  IncomeRetention: 'income-retention',
  GA: 'g&a',
  default: '-',
}

export const vendor1099FormTypes = {
  Attourney: 'attorney',
  FIT: 'fit',
  HealthCare: 'health_care',
  NonEmployeeCompensation: 'nec',
  OtherIncome: 'other_income',
  Rent: 'rent',
  Royalties: 'royalties',
  SIT: 'sit',
  Dividends: 'dividends',
  Interest: 'interest',
  DirectSales: 'direct_sales',
  CropInsurance: 'crop_insurance',
}

export const vendor1099FormTypeOptions = [
  {
    label: i18n.t('Payments to attorneys - Form 1099-NEC'),
    value: vendor1099FormTypes.Attourney,
  },
  {
    label: i18n.t('Federal Income Tax withholding'),
    value: vendor1099FormTypes.FIT,
  },
  {
    label: i18n.t('Medical and health care payments - Form 1099-MISC Box 6'),
    value: vendor1099FormTypes.HealthCare,
  },
  {
    label: i18n.t('Non-employee compensation - Form 1099-NEC Box 1'),
    value: vendor1099FormTypes.NonEmployeeCompensation,
  },
  {
    label: i18n.t('Other income - Form 1099-MISC Box 3'),
    value: vendor1099FormTypes.OtherIncome,
  },
  {
    label: i18n.t('Rental payments - Form 1099-MISC Box 1'),
    value: vendor1099FormTypes.Rent,
  },
  {
    label: i18n.t('Royalty payments - Form 1099-MISC Box 2'),
    value: vendor1099FormTypes.Royalties,
  },
  {
    label: i18n.t('State Income Tax withholding'),
    value: vendor1099FormTypes.SIT,
  },
  {
    label: i18n.t('Dividend payments - Form 1099-DIV'),
    value: vendor1099FormTypes.Dividends,
  },
  {
    label: i18n.t('Interest payments - Form 1099-INT'),
    value: vendor1099FormTypes.Interest,
  },
  {
    label: i18n.t('Direct sales over $5,000 - Form 1099-MISC Box 7'),
    value: vendor1099FormTypes.DirectSales,
  },
  {
    label: i18n.t('Crop insurance proceeds - Form 1099-MISC Box 9'),
    value: vendor1099FormTypes.CropInsurance,
  },
]

export const w9FileOptions = [
  {
    label: i18n.t('Not Sent'),
    value: 'not-sent',
  },
  {
    label: i18n.t('Not Required'),
    value: 'not-required',
  },
  {
    label: i18n.t('Not Received'),
    value: 'not-received',
  },
  {
    label: i18n.t('Yes'),
    value: 'yes',
  },
]

export const transactionStatuses = {
  Outstanding: 'outstanding',
  OutstandingWithVoid: 'outstanding_void',
  Reconciled: 'reconciled',
  Variance: 'variance',
  All: 'all',
  Unknown: 'n/a',
}

export const reconciliationTransactionTypes = {
  All: 'all',
  Credits: 'credits',
  Debits: 'debits',
  NotReconciled: 'unreconciled',
}


const transactionBusinessTypes = {
  Employee: 'employee',
  Vendor: 'vendor',
  Customer: 'customer',
}

export const businessType = {
  Employee: 'employee',
  Vendor: 'vendor',
  Customer: 'customer',
}


export const transactionBusinessLinkByTypes = {
  [transactionBusinessTypes.Employee]: 'EmployeeLink',
  [transactionBusinessTypes.Vendor]: 'VendorLink',
  [transactionBusinessTypes.Customer]: 'CustomerLink',
}

const transactionSpecialSourceTypes = {
  Equipment: 'equipment',
  Vendor: 'vendor',
  Material: 'material',
  WorkOrder: 'work-order',
}

export const transactionSpecialSourceLinkByTypes = {
  [transactionSpecialSourceTypes.Equipment]: 'EquipmentLink',
  [transactionSpecialSourceTypes.Vendor]: 'VendorLink',
  [transactionSpecialSourceTypes.Material]: 'MaterialLink',
  [transactionSpecialSourceTypes.Material]: 'WorkOrderLink',
}

export const vendorDaysTerms = {
  DaysFromInvoiceDate: 'days-from-invoice-date',
  DayOfFollowingMonth: 'day-of-following-month',
  DayOfCurrentMonth: 'day-of-current-month',
  DayOf2ndFollowingMonth: 'day-of-2nd-following-month',
}

const quarters = {
  FirstQuarter: 1,
  SecondQuarter: 2,
  ThirdQuarter: 3,
  FourthQuarter: 4,
}

export const quarterOptions = [
  {
    label: i18n.t('1st'),
    value: quarters.FirstQuarter,
  },
  {
    label: i18n.t('2nd'),
    value: quarters.SecondQuarter,
  },
  {
    label: i18n.t('3rd'),
    value: quarters.ThirdQuarter,
  },
  {
    label: i18n.t('4th'),
    value: quarters.FourthQuarter,
  },
]

export const sexOptions = {
  M: 'Male',
  m: 'Male',
  F: 'Female',
  f: 'Female',
  default: '',
}

export const minorityTypes = {
  None: 'none',
  Black: 'black',
  Hispanic: 'hispanic',
  Asian: 'asian',
  Indian: 'indian',
  Hawaiian: 'hawaiian',
  More: '2/more',
}

export const moduleBasePatches = {
  Equipment: 'equipment',
  JobCosting: 'job-costing',
  Payroll: 'payroll',
  AccountsPayable: 'accounts-payable',
  AccountsReceivable: 'accounts-receivable',
  PurchasingInventory: 'purchasing-inventory',
  ServiceBilling: 'service-billing',
  GeneralLedger: 'ledger',
}

export const billingTypesAbbr = {
  Progress: 'PRG',
  UnitPrice: 'UPB',
  CostPlus: 'CPB',
  LumpSum: 'LMP',
  Initialization: 'INIT',
  Service: 'SB',
}

export const billingTypeAbbreviations = Object.values(billingTypesAbbr)

export const actionPolicies = {
  Delete: 'authorizedToDelete',
  Pay: 'authorizedToPay',
  Post: 'authorizedToPost',
  QuickPay: 'authorizedToQuickPay',
  Show: 'authorizedToShow',
  Store: 'authorizedToStore',
  Update: 'authorizedToUpdate',
  Void: 'authorizedToVoid',
}

export const userRoles = {
  CompanyAdmin: 'company_admin',
  CompanyUser: 'company_user',
  Employee: 'employee',
}

export const addressableMorph = {
  Company: 'company',
  Job: 'job',
  Vendor: 'vendor',
  Customer: 'customer',
  Employee: 'employee',
  Bank: 'bank',
}

export function getAddressableType(entity) {
  const addressMap = {
    jobs: addressableMorph.Job,
    vendors: addressableMorph.Vendor,
    customers: addressableMorph.Customer,
    employees: addressableMorph.Employee,
    companies: addressableMorph.Company,
    banks: addressableMorph.Bank,
  }
  return addressMap[entity] || entity
}


export const morphContactable = {
  Job: 'job',
  Vendor: 'vendor',
  Customer: 'customer',
  Employee: 'employee',
}

export const ModulePaths = {
  [$modules.GL]: '/ledger',
  [$modules.JC]: '/job-costing',
  [$modules.AP]: '/accounts-payable',
  [$modules.AR]: '/accounts-receivable',
  [$modules.PR]: '/payroll',
  [$modules.SB]: '/service-billing',
  [$modules.EQP]: '/equipment',
  [$modules.INV]: '/purchasing-inventory',
}

export const EntityNameMap = {
  jobs: i18n.t('Job'),
  vendors: i18n.t('Vendor'),
  employees: i18n.t('Employee'),
  customers: i18n.t('Customer'),
  companies: i18n.t('Company'),
  banks: i18n.t('Bank'),
}

export const UsedInOptions = [
  {
    label: i18n.t('Accounts Payable'),
    value: $modules.AP,
  },
  {
    label: i18n.t('Job Costing'),
    value: $modules.JC,
  },
  {
    label: i18n.t('Accounts Receivable'),
    value: $modules.AR,
  },
  {
    label: i18n.t('Payroll'),
    value: $modules.PR,
  },
  {
    label: i18n.t('Service Billing'),
    value: $modules.SB,
  },
  {
    label: i18n.t('Purchasing & Inventory'),
    value: $modules.INV,
  },
  {
    label: i18n.t('Equipment'),
    value: $modules.EQP,
  },
  {
    label: i18n.t('Settings'),
    value: $modules.SETTINGS,
  }
]
