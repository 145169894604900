<template>
  <div>
    <AgDataTable
      url="/restify/timesheet-entries"
      :url-params="finalUrlParams"
      :columns="columns"
      :actions="hideAllActions ? '' : 'search,refresh'"
      :groupDefaultExpanded="-1"
      :groupIncludeFooter="true"
      :groupIncludeTotalFooter="true"
      :suppressAggFuncInHeader="true"
      :groupRowRendererParams="groupRowRendererParams"
      groupDisplayType="groupRows"
      domLayout="autoHeight"
    >
      <template #attributes.date="{row, params}">
        <div>
        <span v-if="row">
          {{ row.attributes?.notes }}
        </span>
          <span v-if="!row && params.node.level === 0">
            {{ $t('Totals') }}
          </span>
          <span v-if="!row && params.node.level === -1">
            {{ $t('Grand Totals') }}
          </span>
        </div>
      </template>
      <template #attributes.status="{row}">
        <TimesheetStatus :params="{data: row?.relationships?.timesheet}"/>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
import parse from "date-fns/parse";
import format from "date-fns/format";
import { LongDateFormat } from "@/plugins/dateFormatPlugin";
import TimesheetEntryGroupRow from "@/modules/payroll/components/timesheets/TimesheetEntryGroupRow.vue";
import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";
import TimesheetDialog from "@/modules/payroll/components/timesheets/TimesheetDialog.vue";
import TimesheetStatus from "@/components/table/cells/TimesheetStatus.vue";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { globalResources } from "@/components/form/util";

export default {
  components: {
    TimesheetStatus,
    TimesheetDialog,
    TimesheetEntryGroupRow
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({})
    },
    showSource: {
      type: Boolean,
      default: false,
    },
    showAddlSource: {
      type: Boolean,
      default: true,
    },
    hideAllActions: {
      type: Boolean,
      default: false,
    },
    forDailyReports: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LongDateFormat
    }
  },
  computed: {
    finalUrlParams() {
      return {
        related: 'timesheet',
        sort: '-date',
        ...this.urlParams,
      }
    },
    timesheetSettings() {
      return this.$store.state.company.settings.timesheet
    },
    showCraftCodeDetails() {
      return this.timesheetSettings?.show_craft_code_details
    },
    columns() {
      return [
        {
          field: 'attributes.timesheet_id',
          rowGroup: !this.forDailyReports,
          hide: true,
        },
        {
          headerName: this.$t('Employee'),
          field: 'attributes.employee_id',
          component: 'EmployeeLink',
          minWidth: 250,
          hide: !this.forDailyReports,
        },
        {
          headerName: this.$t('Notes'),
          field: 'attributes.notes',
          minWidth: 200,
          hide: this.forDailyReports,
        },
        {
          headerName: this.$t('Source'),
          field: 'attributes.source_id',
          component: 'SourceLink',
          minWidth: 250,
          maxWidth: 350,
          hide: !this.showSource,
        },
        {
          headerName: this.$t('Line Item'),
          field: 'attributes.addl_source_id',
          component: 'AddlSourceLink',
          minWidth: 130,
          maxWidth: 350,
          hide: !this.showAddlSource,
        },
        {
          headerName: this.$t('Status'),
          field: 'attributes.status',
          component: 'TimesheetStatus',
          minWidth: 110,
          maxWidth: 150,
        },
        {
          field: 'attributes.craft_code_id',
          headerName: 'Craft Code',
          minWidth: 100,
          maxWidth: 250,
          component: 'CraftCodeLink',
          hide: !this.showCraftCodeDetails || this.forDailyReports,
        },
        {
          field: 'attributes.sub_trade_id',
          headerName: 'Sub Trade',
          minWidth: 100,
          maxWidth: 250,
          component: 'SubTradeLink',
          hide: !this.showCraftCodeDetails || this.forDailyReports,
        },
        {
          headerName: this.$t('Duration'),
          field: 'attributes.duration',
          minWidth: 130,
          maxWidth: 200,
          valueFormatter: params => {
            const duration = params.value || 0
            return minutesToHours(duration)
          },
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Start Time'),
          field: 'attributes.clocked_in_at',
          minWidth: 100,
          maxWidth: 150,
          valueFormatter: params => {
            if (!params.data) {
              return
            }
            const start = params.data?.attributes?.start_time
            let startTime = parse(start, 'HH:mm:ss', new Date())
            return format(startTime, 'hh:mm aa')
          },
        },
        {
          headerName: this.$t('End Time'),
          field: 'attributes.end_time',
          minWidth: 100,
          maxWidth: 150,
          valueFormatter: params => {
            if (!params.data) {
              return
            }
            const end = params.data?.attributes?.end_time
            let startTime = parse(end, 'HH:mm:ss', new Date())
            return format(startTime, 'hh:mm aa')
          },
        },
      ]
    },
    groupRowRendererParams() {
      if (this.forDailyReports) {
        return {}
      }
      return {
        innerRenderer: 'TimesheetEntryGroupRow',
        suppressCount: true,
      }
    }
  }
}
</script>
