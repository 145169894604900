<template>
  <base-button
    variant="gray-light"
    title="Archive"
    :tabindex="skipFocus ? -1 : 0"
    :class="{
     'hidden md:flex': hideOnMobile,
     'flex': !hideOnMobile,
   }"
    size="icon"
    class="table-action-button hidden md:flex"
    data-test="button-row-archive"
    type="button"
    @click="$emit('click')">
    <ArchiveIcon class="w-4 h-4"/>
  </base-button>
</template>
<script>
import { ArchiveIcon } from 'vue-feather-icons'

export default {
  name: 'TableArchiveButton',
  components: {
    ArchiveIcon,
  },
  props: {
    skipFocus: {
      type: Boolean,
      default: false,
    },
    hideOnMobile: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
