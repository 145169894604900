<template>
  <div
    v-loading="loading"
    class="min-h-[200px] source-list overflow-scroll px-2 grid grid-cols-3 lg:flex lg:flex-col gap-1.5"
  >
    <DraggableTask
      v-for="task in recentTasks"
      :key="task.id"
      :cost-center="task.attributes.cost_center"
      :apply-for-all-crew-members="applyForAllCrewMembers"
      :job-id="getJobId(task)"
      :item="getItem(task)"
      :show-job-for-line-item="true"
      :task="task"
    />
    <NoDataRow
      v-if="recentTasks.length === 0 && !loading"
    >
      {{ $t('No recent tasks found') }}
    </NoDataRow>
  </div>
</template>
<script>
import DraggableTask from "@/modules/payroll/components/tasks/DraggableTask.vue";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import NoDataRow from "@/components/table/NoDataRow.vue";

export default {
  components: {
    DraggableTask,
    NoDataRow
  },
  props: {
    applyForAllCrewMembers: {
      type: Boolean,
    }
  },
  computed: {
    recentTasks() {
      return this.$store.state.timesheets.recentTasks || []
    },
    loading() {
      return this.$store.state.timesheets.recentTasksLoading
    }
  },
  methods: {
    getJobId(task) {
      const { source_id, cost_center } = task.attributes
      if (cost_center === costCenterTypes.Job) {
        return source_id
      }
      return null
    },
    getItem(task) {
      const { addl_source_id, source_id, is_lunch, is_break } = task.attributes
      const { addlSource, source } = task.relationships
      const itemData = addlSource?.id ? addlSource : source || {}

      return {
        id: addl_source_id || source_id,
        is_lunch,
        is_break,
        ...itemData,
      }
    },
  },
  mounted() {
    this.$store.dispatch('timesheets/getRecentTasks')
  }
}
</script>
