<template>
  <DetailLayout
      :loading="workOrderLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :select-component="WorkOrderSelect"
      :items="items"
  >
    <template #page-title>
      <div v-if="currentResource.id" class="flex">
        {{ $t('Work Order ') }}{{ currentResource.attributes.number }}
        ({{ get(currentResource, 'relationships.customer.attributes.name', '') }})
        <span class="mx-1 md:mx-2 flex items-center">
            <status-badge :status="currentResource.attributes.status"/>
          </span>
      </div>
    </template>
    <template #entity-name>
        <span>
          {{ pageTitle }}
        </span>
    </template>
    <template #extra-buttons>
      <StimulsoftPrintButton
          :url="`/restify/work-orders/${currentResource.id}/stimulsoft`"
          :entity="StimulsoftPrintEntities.WorkOrder"
          :name="pageTitle"
          class="mb-4"
      />
    </template>
  </DetailLayout>
</template>
<script>
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import WorkOrderSelect from "@/components/select/entities/WorkOrderSelect.vue";

export default {
  components: {
    DetailLayout,
  },
  data() {
    return {
      WorkOrderSelect,
      StimulsoftPrintEntities,
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/service-billing/work-orders/${this.$route.params.id}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/service-billing/work-orders/${this.$route.params.id}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Billings'),
          path: `/service-billing/work-orders/${this.$route.params.id}/billings`,
          authorizedTo: 'authorizedToShow',
          permission: 'billings_show',
        },
        {
          name: this.$t('Invoices'),
          path: `/service-billing/work-orders/${this.$route.params.id}/invoices`,
          authorizedTo: 'authorizedToShow',
          permission: 'invoices_show',
        },
        {
          name: this.$t('Purchase Orders'),
          path: `/service-billing/work-orders/${this.$route.params.id}/purchase-orders`,
          authorizedTo: 'authorizedToShow',
          permission: 'purchase_orders_show',
        },
        {
          name: this.$t('Timesheets'),
          path: `/service-billing/work-orders/${this.$route.params.id}/timesheets`,
          authorizedTo: 'authorizedToShow',
          permission: 'timesheets_approve',
        },
        {
          name: this.$t('Transactions'),
          path: `/service-billing/work-orders/${this.$route.params.id}/transactions`,
          authorizedTo: 'authorizedToShow',
          permission: 'transaction_show',
        },
      ]
    },
    currentResource() {
      return this.$store.state.serviceBilling.currentWorkOrder || {}
    },
    workOrderLoading() {
      return this.$store.state.serviceBilling.workOrderLoading
    },
    pageTitle() {
      let baseTitle = `${this.$t('Work Order ')} ${this.currentResource.attributes.number}`
      let customerName = this.get(this.currentResource, 'relationships.customer.attributes.name', '')
      if (customerName) {
        return `${baseTitle} (${customerName})`
      }
      return baseTitle
    },
    printData() {
      return {
        attributes: this.currentResource?.attributes,
        relationships: this.currentResource?.relationships,
        company: this.$currentCompany,
      }
    }
  },
  methods: {
    async getCurrentResource(id) {
      await this.$store.dispatch('serviceBilling/getWorkOrder', id)
    },
  },
}
</script>
