<template>
  <div>
    <h4 class="section-title">
      {{ $t('Daily Report Answers') }}
    </h4>
    <div class="flex flex-col space-y-4 max-w-4xl mt-4">
      <BaseInput
        v-for="(question, key) in model"
        :key="key"
        :label="question.question"
        :rules="question.required ? 'required': undefined"
        v-model="model[key].value"
      >
        <template #container>
          <div class="flex-1">
            <template v-if="[questionTypes.Boolean].includes(question.type)">
              <BaseSwitch v-model="question.value"/>
              <BaseTextarea
                v-if="question.value"
                v-model="question.details"
                :label="$t('Details')"
                rows="2"
                class="mt-2"
                :placeholder="$t('Explain in more details')"
              />
            </template>
            <template v-else-if="[questionTypes.Signature].includes(question.type)">
              <img v-if="question.value" class="max-w-[200px] h-auto" :src="question.value" :alt="question.question">
              <div class="text-sm text-gray-500">{{ $t('No signature provided') }}</div>
            </template>
            <template v-else>
              <BaseInput
                v-model="question.value"
                :name="question.question"
                :rules="question.required ? 'required': undefined"
              />
            </template>
          </div>
        </template>

      </BaseInput>
    </div>
  </div>
</template>
<script>
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import SignaturePad from "@/modules/settings/components/SignaturePad.vue";

export default {
  components: {
    SignaturePad
  },
  props: {
    value: {
      type: Object,
    }
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    questionTypes() {
      return questionTypes
    },
    dailyReportQuestions() {
      return this.$store.state.company.settings?.timesheet?.daily_reports || []
    },
    dailyReportQuestionsObject() {
      const questions = {}
      this.dailyReportQuestions.forEach(question => {
        questions[question.key] = {
          ...question,
          details: '',
          value: this.getDefaultValue(question),
        }
      })
      return questions
    }
  },
  methods: {
    getDefaultValue(question) {
      if (question.type === questionTypes.Boolean) {
        return false
      } else {
        return ''
      }
    },
    getValue() {
      return this.model
    },
    initModel() {
      if (!this.value || Array.isArray(this.value)) {
        this.model = {
          ...this.dailyReportQuestionsObject,
        }
        return
      }
      this.model = {
        ...this.dailyReportQuestionsObject,
        ...this.value
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.initModel()
      },
      immediate: true
    }
  }
}
</script>
