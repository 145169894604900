<template>
  <el-color-picker
      v-focus
      v-model="selectedValue"
      :hide-label="true"
      :size="size"
      class="flex-1"
      inline-errors
      @change="onChange"
      @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import { ColorPicker } from 'element-ui'
  import { selectEditorMixin } from '@/components/ag-grid/cellEditors/selectEditorMixin'

  export default {
    name: 'ColorPickerCell',
    mixins: [selectEditorMixin],
    components: {
      [ColorPicker.name]: ColorPicker,
    },
    computed: {
      size() {
        return this.params.size || 'medium'
      },
      clearable() {
        return this.params.clearable || false
      },
      defaultValue() {
        return this.params.defaultValue || '#FFFFFF'
      }
    },
    methods: {
      onChange(newValue) {
        if (!newValue && !this.clearable) {
          newValue = this.defaultValue
        }

        this.selectedValue = newValue
        this.params.api.tabToNextCell()
      },
      goToNextCell() {
        this.params.api.tabToNextCell()
      },
    },
  }
</script>
