import i18n from '@/i18n'

export const assetTypes = {
  owned: 'owned',
  financed: 'financed',
  leased: 'leased',
  rented: 'rented',
  disposed: 'disposed',
}

export const equipmentCostTypeFor = {
  Aquisition: 'ACQ',
  Depreciation: 'DEP',
  Repairs: 'REP',
  Fuel: 'FUL',
  Parts: 'PAR',
  Generic: 'GEN',
}

export const equipmentCostTypeForLabels = {
  [equipmentCostTypeFor.Aquisition]: i18n.t('Aquisition'),
  [equipmentCostTypeFor.Depreciation]: i18n.t('Depreciation'),
  [equipmentCostTypeFor.Repairs]: i18n.t('Repairs'),
  [equipmentCostTypeFor.Fuel]: i18n.t('Fuel'),
  [equipmentCostTypeFor.Parts]: i18n.t('Parts'),
  [equipmentCostTypeFor.Generic]: i18n.t('Generic'),
}

export const equipmentCostTypeForOptions = [
  {
    label: equipmentCostTypeForLabels[equipmentCostTypeFor.Aquisition],
    value: equipmentCostTypeFor.Aquisition,
  },
  {
    label: equipmentCostTypeForLabels[equipmentCostTypeFor.Depreciation],
    value: equipmentCostTypeFor.Depreciation,
  },
  {
    label: equipmentCostTypeForLabels[equipmentCostTypeFor.Repairs],
    value: equipmentCostTypeFor.Repairs,
  },
  {
    label: equipmentCostTypeForLabels[equipmentCostTypeFor.Fuel],
    value: equipmentCostTypeFor.Fuel,
  },
  {
    label: equipmentCostTypeForLabels[equipmentCostTypeFor.RepairOrder],
    value: equipmentCostTypeFor.RepairOrder,
  },
  {
    label: equipmentCostTypeForLabels[equipmentCostTypeFor.Parts],
    value: equipmentCostTypeFor.Parts,
  },
  {
    label: equipmentCostTypeForLabels[equipmentCostTypeFor.Generic],
    value: equipmentCostTypeFor.Generic
  },
]

export const equipmentTransactionTypes = {
  JobCosted: 'job-costed',
  CustomerBilled: 'customer-billed',
  ExpenseTransaction: 'expense',
}

export const equipmentTransactionTypeOptions = [
  {
    label: i18n.t('Utilization - Costed'),
    value: equipmentTransactionTypes.JobCosted,
  },
  {
    label: i18n.t('Utilization - Billed'),
    value: equipmentTransactionTypes.CustomerBilled,
  },
  {
    label: i18n.t('All Equipment Expense'),
    value: equipmentTransactionTypes.ExpenseTransaction,
  },
]

export const assetTypeOptions = [
  {
    label: i18n.t('Owned'),
    value: assetTypes.owned,
  },
  {
    label: i18n.t('Financed'),
    value: assetTypes.financed,
  },
  {
    label: i18n.t('Leased'),
    value: assetTypes.leased,
  },
  {
    label: i18n.t('Rented'),
    value: assetTypes.rented,
  },
  {
    label: i18n.t('Disposed'),
    value: assetTypes.disposed,
  },
]

export const dispositionTypes = {
  Sold: 'sold',
  Lost: 'lost',
  Junked: 'junked',
  Returned: 'returned',
  Active: 'active',
}

export const dispositionOptions = [
  {
    label: i18n.t('Sold'),
    value: dispositionTypes.Sold,
  },
  {
    label: i18n.t('Lost'),
    value: dispositionTypes.Lost,
  },
  {
    label: i18n.t('Junked'),
    value: dispositionTypes.Junked,
  },
  {
    label: i18n.t('Returned'),
    value: dispositionTypes.Returned,
  },
  {
    label: i18n.t('Active'),
    value: dispositionTypes.Active,
  },
]

export const depreciationMethod = {
  none: 'none',
  straightLine: 'straight-line',
  balance: 'balance',
}

export const intervalTypes = {
  Months: 'months',
  MonthsOrMiles: 'months/miles',
  MonthsOrHours: 'months/hours',
}

export const intervalTypeOptions = [
  {
    label: i18n.t('Months'),
    value: intervalTypes.Months
  },
  {
    label: i18n.t('Months/Miles'),
    value: intervalTypes.MonthsOrMiles
  },
  {
    label: i18n.t('Months/Hours'),
    value: intervalTypes.MonthsOrHours
  },
]
