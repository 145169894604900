<template>
  <BaseCard class="border">
    <h4 class="mb-2 form-section-title">
      {{ $t('Daily Report Answers') }}
    </h4>
    <div v-if="hasAnswers" class="divide-y divide-gray-200">
      <div v-for="question in data.answers" :key="question.key" class="py-2">
        <div class="font-semibold">
          {{ question.question }}
        </div>
        <div class="text-gray-600 text-sm">
          <template v-if="question.type === questionTypes.Signature">
            <img class="max-w-[200px] h-auto" :src="question.value" :alt="question.question">
          </template>
          <template v-else-if="question.type === questionTypes.Boolean">
            {{ question.value ? $t('Yes') : $t('No') }}
          </template>
          <span
            v-else
            :class="{
              'text-red-700 font-medium': question.type === questionTypes.UrgentText && question.value,
            }"
            >
            {{ question.value || '--' }}
          </span>
          <div v-if="question.details" class="mt-2">
            <div>{{ question.details }}</div>
          </div>
        </div>
      </div>
    </div>
    <NoDataRow v-else>
      {{ $t('No answers provided') }}
    </NoDataRow>
  </BaseCard>
</template>
<script setup>
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import parse from "date-fns/parse";
import { dateTypes, formatDate } from "@/plugins/dateFormatPlugin";
import { computed } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => ({}),
  }
})

const hasAnswers = computed(() => {
  const value = props.data.answers
  return value && !Array.isArray(value)
})
</script>
