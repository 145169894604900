<template>
  <AgencyForm
    :data="agency"
    @refresh="getAgency"
  />
</template>
<script>
import AgencyForm from '@/modules/payroll/components/AgencyForm'

export default {
  components: {
    AgencyForm,
  },
  computed: {
    agency() {
      const agency = this.$store.state.payroll.currentAgency
      return {
        ...agency?.attributes,
        ...agency?.relationships,
      }
    },
  },
  methods: {
    async getAgency() {
      await this.$store.dispatch('payroll/getAgency', this.$route.params.id)
    },
  },
}
</script>
