<template>
  <div>
    <AgDataTable
      ref="table"
      url="/restify/daily-job-reports"
      :url-params="urlParams"
      :columns="columns"
      actions="search,refresh,edit,view"
    >
      <template #header-info>
        <TimesheetsDateFilter
          v-model="startOfWeek"
        />
      </template>
      <template #dropdown-actions="{}">
        <TableActionItem>
          <StimulsoftPrintButton
            v-if="stimulsoftEntity"
            :url="`/restify/daily-job-reports/stimulsoft`"
            :url-params="printUrlParams"
            :entity="stimulsoftEntity"
            :extraData="printOptions"
            :alwaysOpenDialog="true"
          />
        </TableActionItem>
      </template>
      <template #attributes.date="{row, params}">
        <div>
          <router-link
            v-if="row"
            :to="`/payroll/timesheets/daily-reports/${row?.id}/view`"
          >
            {{ $formatDate(row.attributes?.date, LongDateFormat) }}
          </router-link>
        </div>
      </template>
    </AgDataTable>
  </div>
</template>
<script>

import TimesheetsDateFilter from "@/modules/payroll/components/timesheets/TimesheetsDateFilter.vue";
import TimesheetsSyncButton from "@/modules/payroll/components/timesheets/TimesheetsSyncButton.vue";
import { getEndOfPayWeek, getStartOfPayWeek } from "@/modules/payroll/components/timesheets/utils";
import format from "date-fns/format";
import { ApiDateFormat, dateTypes, LongDateFormat } from "@/plugins/dateFormatPlugin";
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
import TableActionItem from "@/components/table/actions/TableActionItem.vue";

export default {
  components: {
    TableActionItem,
    TimesheetsSyncButton,
    TimesheetsDateFilter
  },
  props: {
    jobId: {
      type: String,
    }
  },
  data() {
    return {
      renderKey: 1,
      rowToEdit: null,
      showDialog: false,
      LongDateFormat,
      stimulsoftEntity: StimulsoftPrintEntities.DailyReport,
      printOptions: {
        flags: {}
      }
    }
  },
  computed: {
    printUrlParams() {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        job_ids: this.jobId ? [this.jobId] : undefined,
      }
    },
    startOfWeek: {
      get() {
        return this.$store.state.timesheets.selectedStartOfWeek
      },
      set(value) {
        this.$store.commit('timesheets/SET_START_OF_WEEK', value)
      }
    },
    startDate() {
      const start = getStartOfPayWeek(this.startOfWeek)
      return format(start, 'yyyy-MM-dd')
    },
    endDate() {
      const end = getEndOfPayWeek(this.startOfWeek)
      return format(end, 'yyyy-MM-dd')
    },
    columns() {
      return [
        {
          headerName: this.$t('Date'),
          field: 'attributes.date',
          minWidth: 200,
        },
        {
          headerName: this.$t('Job'),
          field: 'attributes.job_id',
          minWidth: 250,
          component: 'JobLink',
        },
        {
          headerName: this.$t('Crew'),
          field: 'relationships.crew.attributes.name',
          minWidth: 150,
        },
        {
          headerName: this.$t('Status'),
          field: 'attributes.status',
          minWidth: 150,
          component: 'Status',
        },
        {
          headerName: this.$t('Created By'),
          field: 'attributes.created_by',
          minWidth: 150,
          component: 'UserLink',
        },
        {
          headerName: this.$t('Last Updated'),
          field: 'attributes.updated_at',
          minWidth: 200,
          component: 'FormattedDate',
          cellRendererParams: {
            format: dateTypes.DateTime,
          }
        },
      ]
    },
    urlParams() {
      return {
        job_id: this.jobId,
        sort: 'date',
        date: `${this.startDate},${this.endDate}`,
        related: 'crew',
      }
    },
    formTitle() {
      if (this.$isAuthorized(this.$actionPolicies.Update, this.rowToEdit)) {
        return this.$t('Edit Daily Report')
      }
      return this.$t('View Daily Report')
    }
  },
  methods: {
    refreshTable() {
      // @ts-ignore
      this.$refs.table.refresh()
    },
  }
}
</script>
