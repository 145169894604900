<template>
  <div class="flex items-center">
    <span
      v-for="column in tableColumns"
      :key="column.name"
      :style="{
        minWidth: `${column.minWidth}px`,
        maxWidth: `${column.maxWidth}px`
      }"
      class="pr-2"
    >
      <div class="flex items-center relative truncate">
        {{ get(option, column.prop) }}
      </div>
    </span>
  </div>
</template>
<script>
export default {
  name: 'MaintenanceSelectOption',
  inheritAttrs: false,
  props: {
    option: {
      type: Object,
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
