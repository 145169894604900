<template>
  <validation-provider :rules="$attrs.rules"
                       :name="$attrs.name || label"
                       v-slot="{ errors }">
    <div>
      <div class="flex space-x-2 ">
        <base-tooltip
          :content="label"
          v-if="label || $slots.label"
          :tabindex="-1"
          :key="$attrs.id"
          :open-delay="500"
          placement="top"
        >
          <label :for="$attrs.id"
                class="block text-sm font-medium leading-5 text-gray-700 truncate">
            <slot name="label">
              <span v-html="label"></span>
              <span v-if="$attrs.rules && $attrs.rules.includes('required')"
                    class="text-gray-500">
                            *
              </span>
            </slot>
          </label>
        </base-tooltip>

        <BaseTooltip
          v-if="tip"
          :tabindex="-1"
          class="inline"
        >
          <template #content>
            <div
              class="max-w-[300px]"
              v-html="tip"
            />
          </template>
          <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help"/>
        </BaseTooltip>
      </div>

      <slot name="container"
            :errors="errors">
        <div class="mt-1 relative rounded-md">
          <base-input-error :errors="errors"
                            :show-tooltip="inlineErrors">
            <textarea
              ref="input"
              v-bind="$attrs"
              :value="value"
              :name="$attrs.name || label"
              v-on="listeners"
              :rows="rows"
              :class="{
                'form-input-error': errors.length,
                'bg-gray-100 cursor-not-allowed': $attrs.disabled,
                'cursor-not-allowed bg-gray-100 focus:shadow-none focus:border-transparent': $attrs.readonly !== undefined,
              }"
              :spellcheck="true"
              class="form-input block w-full sm:text-sm sm:leading-5">
            </textarea>
          </base-input-error>
          <div v-if="error"
               class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
        </div>
      </slot>
    </div>
  </validation-provider>
</template>
<script>
  import { HelpCircleIcon } from 'vue-feather-icons'
  export default {
    components: {
      HelpCircleIcon,
    },
    inheritAttrs: false,
    props: {
      rows: {
        type: Number,
        default: 3,
      },
      value: {
        type: [String, Number, Date],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      inlineErrors: {
        type: Boolean,
        default: false,
      },
      tip: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        error: '',
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
        }
      },
    },
    methods: {
      onInput(evt) {
        this.$emit('input', evt.target.value)
      },
      focus() {
        if (!this.$refs.input) {
          return
        }
        this.$refs.input?.focus()
      }
    },
  }
</script>
<style lang="scss">
  .form-input-error {
    @apply border-red-600 bg-red-50;

    &:focus {
      @apply border-red-600 bg-white ring-red-500;
    }
  }
</style>
