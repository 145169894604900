<template>
  <RepairOrderForm
    :key="renderKey"
    @create-another="renderKey++"
  />
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import RepairOrderForm from '@/modules/equipment/components/repair-orders/RepairOrderForm.vue'

const renderKey = ref(1)
</script>
