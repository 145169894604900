<template>
  <div class="h-full">
    <AgDataTable
      :url="url"
      :urlParams="urlParams"
      :columns="columns"
      :add-text="$t('New maintenance code')"
      :per-page="$pagination.list"
      entity="maintenance-code"
      import-url="/equipment/maintenance-codes/import"
      ref="table"
      actions="search,add,refresh,import,export"
      permission="maintenance_codes"
      deleteCustom
      @add="onRowAdd"
      @delete="itemToDelete = $event; showDeleteDialog = true"
    >
      <template #attributes.interval="{ row }">
        <span class="capitalize">
          {{ row.attributes.interval }}
        </span>
      </template>
    </AgDataTable>
    <BaseFormDialog
      v-if="showDialog"
      :title="rowToEdit ? $t('Edit Maintenance Code') : $t('Add Maintenance Code')"
      :open.sync="showDialog"
      @close="onEventTrigger"
    >
      <MaintenanceCodeForm
        :data="rowToEdit"
        :key="renderKey"
        @cancel="onEventTrigger"
        @save="onEventTrigger"
        @create-another="renderKey++"
      />
    </BaseFormDialog>
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.EquipmentMaintenances"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script>
  import AgDataTable from '@/components/ag-grid/AgDataTable.vue'
import MaintenanceCodeForm from '@/modules/equipment/components/MaintenanceCodeForm'

  export default {
    components: {
      MaintenanceCodeForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        url: '/restify/maintenance-codes',
        showDeleteDialog: false,
        itemToDelete: null,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/equipment/maintenance-codes/{ID}/view',
            sortable: true,
            minWidth: 110,
            maxWidth: 120,
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            label: this.$t('Interval'),
            prop: 'attributes.interval',
            minWidth: 120,
            maxWidth: 150,
          },
          {
            label: this.$t('Months'),
            prop: 'attributes.interval_months',
            minWidth: 120,
            maxWidth: 150,
          },
          {
            label: this.$t('Value'),
            prop: 'attributes.interval_value',
            minWidth: 120,
            maxWidth: 150,
          },
          {
            label: this.$t('Updated At'),
            prop: 'attributes.updated_at',
            minWidth: 120,
            maxWidth: 150,
            component: 'FormattedDate',
          },
        ],
      }
    },
    computed: {
      urlParams() {
        return {
          sort: '-updated_at',
        }
      },
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
      onItemDeleted() {
        this.showDeleteDialog = false
        this.refreshTable()
      }
    },
  }
</script>
