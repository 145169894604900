<template>
  <div class="mt-10 sm:mt-0">
    <BaseForm
      v-bind="$attrs"
      :save-text="$t('Invite')"
      :loading="loading"
      @cancel="$emit('cancel')"
      @submit="inviteUser"
    >

      <div class="col-span-6 md:col-span-3">
        <BaseInput
          v-model="model.email"
          :label="$t('Email')"
          :placeholder="$t('Email to invite')"
          type="email"
          id="email"
          rules="required|email"
        />
      </div>
      <div class="col-span-6 md:col-span-3">
        <CompanyRoleSelect
          v-model="model.company_roles"
          :multiple="true"
          :filter-options="filterOptions"
          :label="$t('Company Roles')"
          rules="required"
        />
      </div>
      <EmployeeSelect
        v-if="includesEmployeeRole"
        v-model="employee_id"
        :urlParams="{
          related: 'user',
          perPage: 100,
        }"
        :display-email-column="true"
        :tip="$t(`When 'Employee' is selected as a Company Role, you must choose a specific employee. Only employees without existing user accounts and with matching email addresses will appear in the list.`)"
        :filterMethod="filterEmployeesMethod"
        rules="required"
        class="col-span-6 md:col-span-3"
        @entity-change="onEmployeeChanged"
      />
      <div class="col-span-6 md:col-span-3">
        <BaseInput
          v-model="model.name"
          :label="$t('Name')"
          :disabled="includesEmployeeRole"
          :placeholder="$t('Full Name')"
          id="name"
          rules="required">
        </BaseInput>
      </div>
    </BaseForm>
  </div>
</template>
<script>
import axios from 'axios'
import CompanySelect from "@/modules/settings/components/CompanySelect";
import EntitySelect from "@/components/select/EntitySelect";
import OrganizationRoleSelect from "@/components/select/entities/OrganizationRoleSelect.vue";
import { PlatformTypes } from "@/modules/settings/utils/userUtils";

export default {
  components: {
    OrganizationRoleSelect,
    CompanySelect,
    EntitySelect
  },
  data() {
    return {
      loading: false,
      employee_id: null,
      model: {
        name: '',
        email: '',
        company_roles: [],
        organization_roles: [],
      },
    }
  },
  computed: {
    userRoles() {
      return this.$store.getters['auth/companyRoles']
    },
    includesEmployeeRole() {
      return this.model.company_roles.includes('employee')
    },
    includesOnlyEmployeeRole() {
      return this.model.company_roles.length === 1 && this.includesEmployeeRole
    }
  },
  methods: {
    filterOptions(options) {
      return options.filter(o => {
        if (this.$can('company_roles_store') || this.$can('company_roles_store')) {
          return true
        }
        return this.userRoles.includes(o.attributes.name)
      })
    },
    filterEmployeesMethod(employee) {
      const doesntHaveEmailOrMatches = !employee.attributes.email || employee.attributes.email === this.model.email
      const doesntHaveUser = !employee.attributes?.user_id
      return doesntHaveUser
    },
    onEmployeeChanged(employee) {
      this.model.name = employee.attributes.name
      this.model.email = employee.attributes.email
    },
    async inviteEmployeeUser() {
      try {
        this.loading = true

        // Attach email to employee
        await axios.patch(`/restify/employees/${this.employee_id}`, {
          email: this.model.email,
        })

        const payload = {
          platform: this.includesOnlyEmployeeRole
            ? PlatformTypes.TimeCard
            : PlatformTypes.Cloud,
          repositories: [this.employee_id],
          company_roles: this.model.company_roles,
        }

        // Invite & attach user to employee
        await axios.post(`/restify/employees/actions?action=attach-users-to-employees`, payload)
        this.$success(this.$t('User invitation sent'))
        this.$emit('save')
      }
      catch (err) {
        this.$error(this.$t('Could not send the user invitation'))
      }
      finally {
        this.loading = false
      }
    },
    async inviteUser() {
      if (this.includesEmployeeRole) {
        return this.inviteEmployeeUser()
      }

      try {
        this.loading = true
        await axios.post(`/restify/invitations`, this.model)
        this.$success(this.$t('User invitation sent'))
        this.$emit('save')
      } catch (err) {
        this.$error(this.$t('Could not send the user invitation'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

