<template>
  <div class="col-span-6 flex flex-col">
    <label class="form-label">
      {{ $t('Recipients *') }}
    </label>
    <div v-for="(contact, index) in model"
         :key="contact._localId"
         class="flex justify-between group"
    >
      <BaseCheckbox
          v-model="model[index].checked"
          @input="onCheckboxChange(index)"
          :label="`${model[index]?.name} (${model[index]?.email})`"
          :id="model[index]._localId"
      />
      <div class="hidden group-hover:block">
        <TableDeleteButton @click="removeContact(index, contact)"/>
      </div>
    </div>
    <span v-if="model.length === 0" class="text-gray-700">
      <template v-if="contactEntity === ContactEntities.Customer">
        {{ $t('This Customer has no email or contacts. Please add one to be able to send emails.') }}
      </template>
      <template v-if="contactEntity === ContactEntities.Vendor">
        {{ $t('This Vendor has no email or contacts. Please add one to be able to send emails.') }}
      </template>
    </span>
    <div class="form-label ml-1">
      <span>{{$t('Sending to someone else ?')}}</span>
      <BaseButton
          variant="primary-link"
          class="mb-4 -ml-2"
          @click="showContactFormDialog = true"
      >
      {{ $t('Add Contact') }}
    </BaseButton>
    </div>

    <BaseFormDialog
        v-if="showContactFormDialog"
        :title="$t('Add Contact')"
        :open.sync="showContactFormDialog"
        :append-to-body="true"
        @close="onCloseContactFormDialog"
    >
      <ContactForm
          :loading="loading"
          :save-text="$t('Add Contact')"
          layout="modal"
          @cancel="onCloseContactFormDialog"
          @onSubmit="onSaveContact"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts" setup>
  import {computed, PropType, ref, watch} from "vue";
  import { ContactModel } from "@/modules/common/types/common";
  import ContactForm from "@/modules/common/components/contacts/ContactForm.vue";
  import axios from 'axios'
  import { success } from '@/components/common/NotificationPlugin'
  import i18n from "@/i18n";
  import Data = API.Data;
  import {Contact} from "@/modules/common/types/models";

  const props = defineProps({
    value: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array as PropType<Data<Contact>[]>,
      default: () => [],
    },
    data: {
      type: Object as PropType<Data<any>>,
      default: () => ({}),
    },
    contactEntity: {
      type: String,
      default: 'customer',
    }
  })

  const emit = defineEmits(['input'])

  const showContactFormDialog = ref(false)
  const loading = ref(false)

  const model = ref<ContactModel[]>([])

  function initModel(contacts: Data<Contact>[]) {
    model.value = contacts.map(c => {
      return {
        _localId: crypto.randomUUID(),
        name: c.attributes.name,
        email: c.attributes.email,
        checked: false,
      }
    })
    if (props.data?.attributes?.email) {
      model.value.unshift({
        _localId: crypto.randomUUID(),
        name: props.data.attributes.name as string,
        email: props.data.attributes.email,
        checked: false,
      })
    }
  }

  watch(props.contacts, initModel, { immediate: true })

  const ContactEntities = {
    Customer: 'customer',
    Vendor: 'vendor',
  }

  const apiPrefix = computed(() => {
    let prefix = 'customers'
    if (props.contactEntity === ContactEntities.Vendor) {
      prefix = 'vendors'
    }
    return prefix
  })
  async function onSaveContact(contact: any) {
    const { data } = await axios.post(`/restify/${apiPrefix.value}/${props.data.id}/contacts`, contact)
    success(i18n.t('Contact added successfully.'))
    props.contacts.push(data)
    initModel(props.contacts)
    showContactFormDialog.value = false
  }

  async function removeContact(index: number, contactData: any) {
    const contact = props.contacts.find(c => c.attributes.email === contactData.email)
    model.value.splice(index, 1)
    props.contacts.splice(index, 1)
    if (!contact?.id) {
      return
    }
    await axios.delete(`/restify/${apiPrefix.value}/${props.data.id}/contacts/${contact.id}`)
  }

  function onCheckboxChange() {
    const checkedContacts = model.value.filter(c => c.checked)
    emit('input', checkedContacts)
  }

  function onCloseContactFormDialog() {
    showContactFormDialog.value = false
  }
</script>
