<template>
  <div>
    <portal to="page-title">
      <div class="flex items-center">
        {{ $t('Journal ') }} #{{ currentResource.attributes.name }}
        <span v-if="currentResource.attributes.status"
              class="mx-1 md:mx-2 flex items-center">
        <status-badge :status="currentResource.attributes.status"/>
        <span class="ml-4 mr-2 font-semibold text-sm">{{$t('Action')}}</span>
        <status-badge :status="action"/>
        </span>
      </div>
      <div v-if="wasVoided" class="journal-second-title">
        {{ $t('Journal was VOIDED by ') }}
        <router-link :to="`/ledger/journal/${journalMeta?.void_journal_id || journalMeta?.journal_id}/view`"
                     class="!text-red-700"
        >
          {{ journalMeta?.void_journal_name || journalMeta?.journal_name || $t('Void Journal') }}
        </router-link>
      </div>
      <div v-else-if="isVoidAction" class="journal-second-title">
        {{ $t('This Journal is a VOID for') }}
        <router-link :to="`/ledger/journal/${journalMeta?.journal_id}/view`"
                     class="!text-primary-700"
        >
          {{ journalMeta?.journal_name || $t('Void Journal') }}
        </router-link>
      </div>
    </portal>
    <div class="flex justify-between items-center">
      <layout-tabs :items="filteredItems"/>
      <div class="flex space-x-2 items-center">
        <BaseTooltip
          v-if="$can('change_journals_posting') && !isVoidAction && !wasVoided && !isPending"
          :content="$t('You cannot change the journal posting for a closed fiscal year')"
          :disabled="!belongsToClosedYear"
        >
          <BaseButton
            variant="white"
            :disabled="belongsToClosedYear"
            class="print:hidden"
            @click="showChangeJournalPostingDialog = true"
          >
            {{ $t('Change Journal Posting') }}
          </BaseButton>
        </BaseTooltip>
        <VoidAction
          v-if="$isAuthorized('authorizedToVoid', currentResource)"
          :id="currentResource.id"
          :is-accounting="false"
          entity-name="Journal"
          resource-name="journals"
          :actionUrl="`/restify/journals/${currentResource.id}/actions?action=void-gl-journal`"
          @on-action-callback="id => getCurrentResource(id, true)"
        />
        <ProofListingButton
          v-if="$isAuthorized('authorizedToPost', currentResource)"
          :selected-rows="[currentResource]"
          path="/ledger/journal/proof-listing"
        />
        <StimulsoftPrintButton
          :data="printData"
          :entity="StimulsoftPrintEntities.GlJournalProofListing"
        />
      </div>
      <ChangeJournalPostingDialog
        v-if="showChangeJournalPostingDialog"
        :open.sync="showChangeJournalPostingDialog"
        :journal="journal"
        :appendToBody="true"
        :showAllPeriods="true"
        @close="showChangeJournalPostingDialog = false"
      />
    </div>
    <router-view/>
  </div>
</template>
<script>
  import layoutPermissions from '@/mixins/layoutPermissions'
  import PrintAction from "@/components/table/actions/PrintAction.vue";
  import ChangeJournalPostingDialog from '@/modules/common/components/journal/ChangeJournalPostingDialog'
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
  import { resourceStatuses } from "@/enum/enums";

  export default {
    components: {
      PrintAction,
      ChangeJournalPostingDialog,
    },
    mixins: [layoutPermissions],
    data() {
      return {
        StimulsoftPrintEntities,
        showChangeJournalPostingDialog: false,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Details'),
            path: `/ledger/journal/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Edit'),
            path: `/ledger/journal/${this.$route.params.id}/edit`,
            authorizedTo: 'authorizedToUpdate',
          },
        ]
      },
      currentResource() {
        return this.$store.state.generalLedger.currentJournal || {}
      },
      journalMeta() {
        return this.currentResource?.attributes?.meta || {}
      },
      allUsers() {
        return this.$store?.getters['globalLists/getResourceList'](this.$globalResources.Users) || []
      },
      journal() {
        const journal = this.currentResource
        const reference_date_formatted = journal?.attributes?.reference_date
            ? this.$formatDate(journal?.attributes?.reference_date)
            : ''
        const created_by_user = this.allUsers.find(user => user.id === journal?.attributes?.created_by)?.name || ''
        const period_name = this.getPeriodName(journal?.attributes?.period)

        return {
          ...journal?.attributes,
          ...journal?.relationships,
          reference_date_formatted,
          created_by_user,
          period_name,
        }
      },
      journalEntries() {
        return this.$store.state.generalLedger.currentJournalEntries || []
      },
      isPending() {
        return this.currentResource?.attributes?.status === resourceStatuses.Pending
      },
      wasVoided() {
        return this.currentResource?.attributes.meta?.was_voided
      },
      isVoidAction() {
        return this.action === 'void'
      },
      action() {
        return this.currentResource?.attributes?.action
      },
      allFiscalYears() {
        return this.$store.state.company.fiscalYears || []
      },
      fiscalYear() {
        return this.allFiscalYears.find((fy) => fy.attributes.fiscal_year == this.journal?.fiscal_year) || {}
      },
      belongsToClosedYear() {
        return this.fiscalYear?.attributes?.closed_at
      },
      printData() {
        const journals = this.journalEntries.map((entry) => {
          return {
            ...entry.attributes,
            journal: this.journal,
          }
        })

        return {
          flags: {
            single_journal: true
          },
          account_summary_data: [],
          journal: {
            name: this.journal.name,
            created_by_user: this.journal.created_by_user,
          },
          journals,
        }
      }
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      async getCurrentResource(id) {
        await this.$store.dispatch('generalLedger/getJournal', id)
      },
    },
  }
</script>
<style>
.breadcrumb-link .journal-second-title {
  @apply hidden;
}
</style>
