<template>
  <DailyReportForm
    :data="dailyReport"
    @refresh="getDailyReport"
  />
</template>
<script>
import DailyReportForm from "@/modules/payroll/components/daily-reports/DailyReportForm.vue";

export default {
  components: {
    DailyReportForm,
  },
  computed: {
    dailyReport() {
      const dailyReport = this.$store.state.timesheets.currentDailyReport
      return {
        ...dailyReport?.attributes,
        ...dailyReport?.relationships,
      }
    },
  },
  methods: {
    async getDailyReport() {
      await this.$store.dispatch('timesheets/getDailyReport', this.$route.params.id)
    },
  },
}
</script>
