<template>
  <nav class="flex space-x-4 nav-bar print:hidden my-2" aria-label="Tabs">
    <a
      v-for="item of filteredItems"
      :key="item.value"
      href="javascript:;"
      :class="{
        'active': value === item.value
      }"
      class="nav-item"
      @click="$emit('input', item.value)"
    >
      {{ item.label }}
    </a>
  </nav>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => this.$can(item.permission))
    },
  }
}
</script>
