<template>
  <BaseTooltip
    :content="$t('Application Version')"
    class="flex text-gray-400 text-xs pr-2"
  >
    <div class="space-x-1">
      <span>{{ versionFormatted }}</span>
      <span v-if="!isProd">({{ $t('Beta') }})</span>
    </div>
  </BaseTooltip>
</template>
<script>
import { isProduction } from "@/isProduction";
export default {
  data() {
    return {
      version: __APP_VERSION__,
      pre_version: __APP_PRE_VERSION__,
    }
  },
  computed: {
    versionFormatted() {
      return `${this.version}${parseInt(this.pre_version) ? `-dev.${this.pre_version}` : ''}`
    },
    isProd() {
      return isProduction()
    }
  },
}
</script>
