<template>
  <div class="space-y-4">
    <div class="flex flex-wrap gap-3">
      <BaseSelect
        v-model="localModel.frequency"
        :label="$t(`Repeat ${entity}`)"
        :placeholder="$t('Interval')"
        :options="frequencyOptions"
        id="frequency"
        :inline-errors="true"
        class="w-1/3"
        @change="onFrequencyChange"
      />
      <BaseSelect
        v-if="localModel.frequency === FrequencyType.Hourly"
        v-model="model.interval"
        :label="$t('Every')"
        :placeholder="$t('Hour Interval')"
        :options="hourlyOptions"
        :inline-errors="true"
        id="interval"
        @change="computeRule"
      />

      <BaseSelect
        v-if="localModel.frequency === FrequencyType.Monthly"
        v-model="model.interval"
        :label="$t('Every')"
        :placeholder="$t('Monthly Interval')"
        :options="monthRangeOptions"
        :inline-errors="true"
        id="month_interval"
        @change="computeRule"
      />

      <BaseSelect
        v-if="localModel.frequency === FrequencyType.Weekly"
        v-model="model.byweekday"
        :multiple="true"
        :label="$t('Every')"
        :placeholder="$t('Week Day')"
        :options="weekDayOptions"
        :inline-errors="true"
        id="byweekday"
        @change="computeRule"
      />
      <BaseSelect
        v-if="localModel.frequency === FrequencyType.Monthly"
        v-model="model.bymonthday"
        :multiple="true"
        :allowSelectAll="false"
        :allowClearAll="false"
        :label="$t('On the day(s) of every month')"
        :placeholder="$t('Day of the month')"
        :options="monthDayOptions"
        :inline-errors="true"
        id="bymonthday"
        @change="computeRule"
      />
    </div>
    <div class="flex flex-wrap gap-x-3">
      <BaseDatePicker
        v-model="model.dtstart"
        :label="$t('Start on')"
        :placeholder="$t('Start on')"
        :value-format="null"
        id="dtstart"
        :inline-errors="true"
        class="col-span-8 md:col-span-3 lg:col-span-1"
        @change="onStartDateChange"
      />
      <BaseSelect
        v-model="localModel.stop_type"
        :label="$t('and end')"
        :options="stopOptions"
        :inline-errors="true"
        id="stop_type"
        class="col-span-8 md:col-span-3 lg:col-span-1"
        @change="onChangeStopType"
      />
      <BaseInput
        v-if="localModel.stop_type === StopType.After"
        v-model="model.count"
        :label="$t('Occurrence count')"
        type="number"
        :min="1"
        :max="999"
        :step="1"
        rules="min_value:0|max_value:999"
        @change="computeRule"
      />
      <BaseDatePicker
        v-if="localModel.stop_type === StopType.On"
        v-model="model.until"
        :value-format="null"
        :label="$t('Until')"
        rules="required"
        @change="computeRule"
      />
      <slot
        name="additional-fields"
      />
    </div>
    <div class="flex items-center mt-4 mb-2">
      <BaseTooltip
        :content="$t(`First ${entity} will be created on ${$formatDate(firstOccurence)}`)"
      >
        <BaseBadge
          type="info"
        >
          <span class="mr-2 text-sm">
            {{ $t('Schedule Rule: ') }}
          </span>
          <div class="flex items-center">
            <RRuleText
              v-if="value"
              :value="value"
              :invoice="invoice"
              class="font-medium text-sm"
              @change="$emit('recurrence-rule-formatted', $event)"
            />
          </div>
        </BaseBadge>
      </BaseTooltip>
    </div>
  </div>
</template>
<script>
import { RRule, rrulestr } from 'rrule'
import i18n from '@/i18n';
import {
  FrequencyType,
  getFrequencyOptions,
  getRuleFrequency,
  getStopType,
  getStopTypeOptions, StopType
} from "@/modules/accounts-payable/components/recurring-invoice/recurringUtils";
import RRuleText from "@/modules/accounts-payable/components/recurring-invoice/RRuleText.vue";

function getOrdinalSuffix(day) {
  const suffixes = {
    1: `${day}st`,
    2: `${day}nd`,
    3: `${day}rd`,
  };

  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }

  const lastDigit = day % 10;
  return suffixes[lastDigit] || `${day}th`;
}

export default {
  components: {
    RRuleText,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    startDate: {
      type: [String, Date],
    },
    invoice: {
      type: Object,
      default: () => ({}),
    },
    entity: {
      type: String,
      default: i18n.t('Invoice'),
    }
  },
  data() {
    return {
      StopType,
      FrequencyType,
      model: {
        byweekday: [],
        bymonthday: [],
        dtstart: this.startDate || new Date(),
        interval: 1,
        count: null,
        until: null,
      },
      localModel: {
        frequency: FrequencyType.Monthly,
        stop_type: StopType.Never
      },
      rule: null
    }
  },
  computed: {
    frequencyOptions() {
      return getFrequencyOptions()
    },
    weekDayOptions() {
      return [
        {
          value: RRule.MO.weekday,
          fullValue: RRule.MO,
          label: this.$t('Monday'),
        },
        {
          value: RRule.TU.weekday,
          fullValue: RRule.TU,
          label: this.$t('Tuesday'),
        },
        {
          value: RRule.WE.weekday,
          fullValue: RRule.WE,
          label: this.$t('Wednesday'),
        },
        {
          value: RRule.TH.weekday,
          fullValue: RRule.TH,
          label: this.$t('Thursday'),
        },
        {
          value: RRule.FR.weekday,
          fullValue: RRule.FR,
          label: this.$t('Friday'),
        },
        {
          value: RRule.SA.weekday,
          fullValue: RRule.SA,
          label: this.$t('Saturday'),
        },
        {
          value: RRule.SU.weekday,
          fullValue: RRule.SU,
          label: this.$t('Sunday'),
        },
      ]
    },
    hourlyOptions() {
      return Array.from({ length: 24 }, (_, i) => i + 1).map(hour => {
        let hourLabel = hour === 1 ? this.$t('hour') : this.$t('hours')
        return {
          value: hour,
          label: `${hour} ${hourLabel}`
        }
      })
    },
    monthRangeOptions() {
      return Array.from({ length: 12 }, (_, i) => i + 1).map(month => {
        return {
          value: month,
          label: month > 1
            ? `${month} ${this.$t('months')}`
            : this.$t('month')
        }
      })
    },
    monthDayOptions() {
      return Array.from({ length: 31 }, (_, i) => i + 1).map(day => {
        return {
          value: day,
          label: getOrdinalSuffix(day)
        }
      })
    },
    stopOptions() {
      return getStopTypeOptions()
    },
    allOccurences() {
      return this.rule?.all() || []
    },
    firstOccurence() {
      return this.allOccurences[0]
    },
    lastOccurence() {
      if (this.localModel.stop_type === StopType.Never) {
        return null
      }

      if (this.localModel.stop_type === StopType.On) {
        return this.model.until
      }

      return this.allOccurences[this.allOccurences.length - 1]
    }
  },
  methods: {
    async onFrequencyChange(value) {
      if (value === FrequencyType.Hourly) {
        this.model.interval = 2
      } else if (value === FrequencyType.Quarterly) {
        this.model.interval = 3
      } else {
        this.model.interval = 1
      }
      if (value === FrequencyType.Weekly) {
        this.model.byweekday = [RRule.MO.weekday]
      } else {
        this.model.byweekday = []
      }
      if (value === FrequencyType.Monthly) {
        this.model.bymonthday = [1]
      } else {
        this.model.bymonthday = []
      }
      await this.computeRule()
    },
    async onStartDateChange(value) {
      this.updateStartDate(value)
      await this.computeRule()
    },
    updateStartDate(value) {
      this.$emit('update:startDate', value)
    },
    async onChangeStopType(value) {
      if (value === StopType.Never) {
        this.model.count = null
        this.model.until = null
      } else if (value === StopType.After) {
        this.model.until = null
        this.model.count = 12
      } else if (value === StopType.On) {
        this.model.count = null
      }
      await this.computeRule()
    },
    async computeRule() {
      await this.$nextTick()
      this.computeFrequency()
      const options = {
        ...this.model,
      }
      this.rule = new RRule(options)
      this.$emit('input', this.rule.toString())
    },
    computeFrequency() {
      const frequencyMap = {
        [FrequencyType.Hourly]: RRule.HOURLY,
        [FrequencyType.Daily]: RRule.DAILY,
        [FrequencyType.Weekly]: RRule.WEEKLY,
        [FrequencyType.Monthly]: RRule.MONTHLY,
      }
      this.model.freq = frequencyMap[this.localModel.frequency]
      if (this.localModel.frequency === FrequencyType.Quarterly) {
        this.model.freq = RRule.MONTHLY
        this.model.interval = 3
      }
    },
    parseRule(value) {
      if (!value) {
        return
      }

      const rRule = rrulestr(value)
      const options = rRule.options || {}
      this.model = {
        ...this.model,
        ...(options)
      }
      this.localModel.stop_type = getStopType(options)
      this.localModel.frequency = getRuleFrequency(options)
    }
  },
  async mounted() {
    await this.computeRule()
    this.updateStartDate(this.model.dtstart)
  },
  watch: {
    value: {
      handler(value) {
        this.parseRule(value)
      },
      immediate: true
    },
    ['localModel.stop_type']: {
      handler(value) {
        this.$emit('update:stopType', value)
      },
      immediate: true
    },
    firstOccurence: {
      handler(value) {
        this.$emit('first-occurence-changed', value)
      },
      immediate: true
    },
    lastOccurence: {
      handler(value) {
        this.$emit('last-occurence-changed', value)
      },
      immediate: true
    }
  }
}
</script>
