<template>
  <RatesStartDates
      :url-params.sync="startDateParams"
      :entity-repo="ratesWithStartDate.GenLiability"
      ref="intervalsWrapper"
  >
    <BaseDataTable
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :url-params="urlParams"
        :url="url"
        :add-text="$t(`New gen liability rate`)"
        ref="table"
        import-model="gen-liability-rates"
        entity="gen-liability-rates"
        view-entity-url-query="/payroll/rates/general-liability-rates"
        default-sort="code"
        actions="search,refresh,import,export"
        import-url="/payroll/rates/general-liability-rates/import"
        permission="employees"
        @add="onRowAdd"
        @edit="onRowEdit"
    />
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit General Liability Rate') : $t('Add General Liability Rate')"
        :open.sync="showDialog"
        @close="onEventTrigger"
    >
      <GeneralLiabilityRateForm
          :data="rowToEdit"
          :key="renderKey"
          :selected-date="startDateParams.start_date"
          @cancel="onEventTrigger"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </RatesStartDates>
</template>
<script>
import { formatCalculateByAmounts, ratesWithStartDate } from '@/modules/payroll/components/rates/util'
  import RatesStartDates from '@/modules/payroll/components/rates/RatesStartDates'
  import GeneralLiabilityRateForm from '@/modules/payroll/components/rates/GeneralLiabilityRateForm'

  export default {
    components: {
      RatesStartDates,
      GeneralLiabilityRateForm,
    },
    data() {
      return {
        renderKey: 1,
        ratesWithStartDate,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        startDateParams: {},
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/rates/general-liability-rates?id={ID}',
          },
          {
            label: this.$t('State'),
            prop: 'attributes.state',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            label: this.$t('Calculate By'),
            prop: 'attributes.calculate_by',
            component: 'FormattedText',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            label: this.$t('Multiply By'),
            prop: 'attributes.multiply_by',
            component: 'FormattedText',
            minWidth: 100,
            maxWidth: 200,
          },
          {
            label: this.$t('Amount'),
            prop: 'attributes.amount',
            formatter: formatCalculateByAmounts,
            align: 'right',
          },
        ]
      },
      urlParams() {
        const params = {
          ...this.startDateParams,
        }

        delete params.active
        delete params.start_date

        const selectedDate = this.startDateParams.start_date
        if (selectedDate === 'active') {
          params.active = true
        } else {
          params.start_date = selectedDate
        }

        return params
      },
      url() {
        return '/restify/gen-liability-rates'
      },
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      refreshDateIntervals() {
        this.$refs.intervalsWrapper.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshDateIntervals()
        this.refreshTable()
      },
    },
  }
</script>
