<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <p class="text-sm font-semibold flex justify-center -mt-2">{{ $t('Costs To-Date') }}</p>
      <div
        :title="$t('Cost Hours')"
        class="detail-row"
      >
        <ClockIcon class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm">{{ $t('Cost Hours') }}</span>
          {{ $formatHours(repairOrder.attributes?.quantity_to_date || 0) }}
        </div>
      </div>
      <div
        :title="$t('Cost Amount')"
        class="detail-row"
      >
        <DollarSignIcon class="detail-icon"/>
        <div class="flex w-full justify-between space-x-2">
          <span class="text-sm">{{ $t('Cost Amount') }}</span>
          {{ $formatPrice(repairOrder.attributes?.amount_to_date || 0) }}
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script>
import { ClockIcon, DollarSignIcon } from 'vue-feather-icons'

export default {
  name: 'RepairOrderCostsToDate',
  components: {
    ClockIcon,
    DollarSignIcon,
  },
  props: {
    repairOrder: {
      type: Object,
      required: true,
    },
  }
}
</script>
