<template>
  <div :class="['audioPulse', { active, hover }]">
    <div
      v-for="(_, i) in lineCount"
      :key="i"
      ref="lines"
      :style="{ animationDelay: `${i * 133}ms` }"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  active: Boolean,
  volume: Number,
  hover: Boolean
});

const lineCount = 3;
const lines = ref([]);
let timeout = null;

const update = () => {
  lines.value.forEach((line, i) => {
    if (line) {
      line.style.height = `${Math.min(24, 4 + props.volume * (i === 1 ? 400 : 60))}px`;
    }
  });
  timeout = setTimeout(update, 100);
};

onMounted(() => {
  update();
});

onUnmounted(() => {
  clearTimeout(timeout);
});
</script>

<style lang="scss">
.audioPulse {
  display: flex;
  width: 24px;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s;

  & > div {
    @apply rounded-xl bg-gray-500;
    border-radius: 1000px;
    width: 4px;
    min-height: 4px;
    border-radius: 1000px;
    transition: height 0.1s;
  }

  &.hover > div {
    animation: hover 1.4s infinite alternate ease-in-out;
  }

  height: 4px;
  transition: opacity 0.333s;

  &.active {
    opacity: 1;

    & > div {
      @apply bg-gray-500;
    }
  }
}

@keyframes hover {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-3.5px);
  }
}

@keyframes pulse {
  from {
    scale: 1 1;
  }

  to {
    scale: 1.2 1.2;
  }
}

</style>
