<template>
  <div class="truncate entity-preview">
    <preview-fixed-wrapper
        v-if="entity.id && showPreview && hoveredRowId === entity.id"
        :style="previewWrapperStyles"
        :title="valueToDisplay"
        class="entity-info fixed hidden left-auto entity-preview-card h-auto bg-white z-999999 rounded transition-all"
        @mouseleave="onMouseLeave(true)"
        @mouseover="mouseOverOnQuickPreview(true)"
    >
      <component
          v-bind="$attrs"
          :is="getComponentType"
          :entity="entity"
          :location-link="getLocationLink"
      />
    </preview-fixed-wrapper>
    <slot>
      <router-link v-if="entity.id && isLink"
                   :key="url"
                   :class="linkClass"
                   :to="url"
                   :tabindex="-1"
                   :target="target"
                   class="text-gray-900 hover:text-primary-700 cursor-pointer truncate"
      >
        <span
          :id="`row-${parentEntityId}`"
          class="truncate w-full"
          @mouseover="onMouseOver()"
          @mouseleave="onMouseLeave(false)"
        >
          {{ valueToDisplay }}
        </span>
      </router-link>
      <span v-else>
        {{ valueToDisplay }}
      </span>
    </slot>
  </div>
</template>
<script>
  import { locationLink } from '@/utils/utils'
  import JobPreview from '@/modules/common/components/entity-preview/JobPreview'
  import { getComponent } from '@/modules/common/components/entity-preview/entities'
  import EmptyPreview from '@/modules/common/components/entity-preview/EmptyPreview'
  import VendorPreview from '@/modules/common/components/entity-preview/VendorPreview'
  import AgencyPreview from '@/modules/common/components/entity-preview/AgencyPreview'
  import BillingPreview from '@/modules/common/components/entity-preview/BillingPreview'
  import CustomerPreview from '@/modules/common/components/entity-preview/CustomerPreview'
  import EmployeePreview from '@/modules/common/components/entity-preview/EmployeePreview'
  import WorksitePreview from '@/modules/common/components/entity-preview/WorksitePreview'
  import WorkOrderPreview from '@/modules/common/components/entity-preview/WorkOrderPreview'
  import EquipmentPreview from '@/modules/common/components/entity-preview/EquipmentPreview'
  import MaterialPreview from '@/modules/common/components/entity-preview/MaterialPreview'
  import PreviewFixedWrapper from "@/modules/common/components/entity-preview/PreviewFixedWrapper.vue";

  const PopoverPlacement = {
    Left: 'left',
    Right: 'right',
    Top: 'top',
    Bottom: 'bottom',
  }

  export default {
    components: {
      PreviewFixedWrapper,
      JobPreview,
      EmptyPreview,
      VendorPreview,
      AgencyPreview,
      BillingPreview,
      WorksitePreview,
      CustomerPreview,
      EmployeePreview,
      EquipmentPreview,
      WorkOrderPreview,
      MaterialPreview,
    },
    props: {
      entity: {
        type: Object,
        default: () => ({}),
      },
      entityName: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      valueToDisplay: {
        type: [String, Number],
        default: '',
      },
      parentEntityId: {
        type: [String, Number],
        default: '',
      },
      linkClass: {
        type: [String, Object, Array],
        default: '',
      },
      addresses: {
        type: Array,
        default: () => ([]),
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
      placement: {
        type: String,
        default: PopoverPlacement.Right,
      },
    },
    data() {
      return {
        previewWrapperStyles: {},
        quickPreviewFocused: false,
        hoveredRowId: false,
      }
    },
    computed: {
      getComponentType() {
        return getComponent(this.entityName)
      },
      getLocationLink() {
        if (!this.addresses.length) {
          return ''
        }
        return this.formattedLocationLink()
      },
    },
    methods: {
      formattedLocationLink() {
        const address = this.addresses.find(address => address.attributes.primary)
        return locationLink(address)
      },
      onMouseOver() {
        const rowID = `row-${this.parentEntityId}`
        this.hidePreview()
        this.hoveredRowId = this.entity.id
        const el = document.getElementById(rowID)
        if (!el) {
          return
        }
        const rect = el.getBoundingClientRect()
        this.setStyles(rect)
      },
      setStyles(rect) {
        const marginTop = rect.y - 60
        const marginLeft = this.placement === PopoverPlacement.Left ? (rect.x - 330) : (rect.x + rect.width + 5)

        this.previewWrapperStyles = {
          display: 'block',
          top: `${marginTop}px`,
          left: `${marginLeft}px`,
        }
      },
      onMouseLeave(forced) {
        if (forced) {
          return this.hidePreview()
        }
        setTimeout(() => {
          if (this.quickPreviewFocused) {
            return
          }
          this.hidePreview()
        }, 100)
      },
      hidePreview() {
        this.hoveredRowId = false
        this.quickPreviewFocused = false
        this.previewWrapperStyles = {}
      },
      mouseOverOnQuickPreview(state) {
        this.quickPreviewFocused = state
      },
    },
  }
</script>
<style lang="scss">
  .entity-preview-card {
    width: 350px;
  }

  .entity-info {
    box-shadow: 0 3px 40px 0 rgba(22, 28, 52, 0.08);
  }
</style>
