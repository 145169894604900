<template>
  <div class="mt-2 lg:flex space-x-4">
    <BaseForm
      :loading="saving"
      :saveText="$t('Update')"
      layout="vertical"
      gridClasses="grid grid-cols-8 gap-4"
      @submit="onSubmit"
    >
      <p class="text-sm text-gray-500 col-span-8">
        {{ $t('Update the current Usage Hours and/or Mileage for this Equipment.') }}
      </p>
      <BaseInput
        v-model="model.usage_hours"
        :label="$t('Usage Hours')"
        :placeholder="$t('Hours')"
        class="col-span-8 md:col-span-4 lg:col-span-2"
        id="usage_hours"
      />
      <BaseInput
        v-model="model.mileage"
        :label="$t('Mileage')"
        :placeholder="$t('Miles')"
        class="col-span-8 md:col-span-4 lg:col-span-2"
        id="mileage"
      />
    </BaseForm>
    <div class="flex-1 mt-4 lg:mt-0">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t('Update Activity')  }}
      </h3>
      <SettingsActivityLogTable
        :key="activityKey"
        :endpoint="`/restify/equipment-activities?equipment_id=${equipment.id}&only_usages=true`"
        :groupRendererParams="logGroupRendererParams"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import i18n from "@/i18n";
import store from '@/store'
import axios from 'axios'
import { computed, ref, watch } from 'vue'
import { error } from "@/components/common/NotificationPlugin";
import SettingsActivityLogTable from '@/modules/common/components/SettingsActivityLogTable.vue'
import { globalResources } from "@/components/form/util";

const model = ref({
  usage_hours: 0,
  mileage: 0,
})

const saving = ref(false)
const activityKey = ref(0)

async function onSubmit() {
  try {
    saving.value = true
    const url = `/restify/equipment/${equipment.value.id}/actions?action=track-usage`
    await axios.post(url, {
      ...model.value
    })

    equipment.value.maintenance_hours_to_date = model.value.usage_hours
    equipment.value.mileage = model.value.mileage

    activityKey.value++
  }
  catch (err: any) {
    if (err.handled) {
      return
    }
    
    error(i18n.t('Could not update equipment usage'))
  }
  finally {
    saving.value = false
  }
}

const equipment = computed(() => {
  return store.state.equipmentManagement.currentEquipment.attributes
})

const logGroupRendererParams = {
  hideName: true,
  throughEntity: {
    type: globalResources.RepairOrders,
    id: 'repair_order_id',
    props: [
      {
        entity_prop: 'number',
        value_key: 'repair_order_number',
      },
    ]
  }
}

watch(() => equipment.value, (value) => {
  model.value.usage_hours = value.maintenance_hours_to_date
  model.value.mileage = value.mileage
}, { immediate: true })
</script>
