<template>
  <div v-loading="loading"/>
</template>
<script>
  import echarts from './init'
  import { getMainColor } from "@/components/charts/chartColors";
  import { colors } from "@/components/charts/chartOptions";
  import { useWindowSize } from '@vueuse/core'
  import bus from '@/event-bus/EventBus'

  export default {
    props: {
      chartData: {
        type: Object,
      },
      loading: Boolean,
      noData: Boolean,
    },
    setup() {
      const { width } = useWindowSize()
      return {
        width,
      }
    },
    data() {
      return {
        loadingConfig: {
          text: 'Loading...',
          color: getMainColor(),
          textColor: colors.dark,
          maskColor: 'rgba(255, 255, 255, 0.8)',
          zlevel: 0,
          fontSize: 12,
          showSpinner: true,
          spinnerRadius: 20,
          lineWidth: 3,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontFamily: 'Inter var'
        }
      }
    },
    methods: {
      resize() {
        this.chart.resize()
      }
    },
    async mounted() {
      this.chart = echarts.init(this.$el);
      if (this.chartData) {
        this.chart.setOption(this.chartData);
        await this.$nextTick()
        this.resize()
      }
      bus.$on('resize-widget', this.resize)
    },
    watch: {
      width() {
        this.resize()
      },
      chartData(value) {
        if (!value) {
          return
        }

        this.chart.setOption(value, true);
      },
      loading(value) {
        if (value) {
          this.chart.showLoading('default', this.loadingConfig);
        } else {
          this.chart.hideLoading();
        }
      },
    }
  }

</script>
