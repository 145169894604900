<template>
  <RepairOrderForm
    :data="currentRepairOrder"
    @refresh="getRepairOrder"
  />
</template>
<script lang="ts" setup>
import store from '@/store'
import { computed } from 'vue'
import RepairOrderForm from '@/modules/equipment/components/repair-orders/RepairOrderForm.vue'

const currentRepairOrder = computed(() => {
  return store.state.equipmentManagement.currentRepairOrder
})

function getRepairOrder() {
  store.dispatch('equipmentManagement/getRepairOrder', currentRepairOrder.value.id)
}
</script>
