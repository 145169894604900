<template>
  <base-form
      v-bind="$attrs"
      :loading="loading"
      :showCancel="showCancel"
      :can-create-another-entity="!model.id"
      :save-text="$t('Create account')"
      :update-text="$t('Update account')"
      :layout="layout"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <BaseInput
        v-model="model.number"
        :disabled="!!model.id"
        :label="$t('Account Number')"
        :placeholder="$t('1-20 characters')"
        class="col-span-2"
        id="number"
        rules="required|min:1|max:20"
    />
    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        class="col-span-4"
        id="description"
        rules="required|max:150"
    />
    <base-select
        v-model="model.type"
        :label="$t('Type')"
        :placeholder="$t('Type')"
        :options="accountTypes"
        class="col-span-6 md:col-span-2"
        rules="required"
        id="type"
    />
    <base-select
        v-model="model.restrictions"
        :label="$t('Restrictions')"
        :placeholder="$t('Restrictions')"
        :options="restrictionOptions"
        class="col-span-6 md:col-span-3"
        id="restrictions"
        @change="onChangeRestriction"
    />
    <entity-select
        v-if="displayCostTypeSelector"
        v-model="model.restriction_type_id"
        :url="`/restify/job-types?type=${costTypeByRestriction}`"
        :placeholder="$t('Select Cost Type')"
        :label="$t('Cost Type')"
        :add-entity="false"
        :label-format="formatCostTypeName"
        class="col-span-6 md:col-span-2"
        label-key="abbr"
        clearable
        value-key="id"
        inline-errors
    />
    <base-switch
        v-model="model.active"
        :labelInfo="$t('Active')"
        class="col-span-6 md:col-span-1"
    />
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { accountRestrictions, accountTypes, restrictionOptions } from '@/enum/account'

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      layout: {
        type: String,
        default: 'modal',
      },
    },
    data() {
      return {
        loading: false,
        showCancel: true,
        model: {
          restriction_type_id: undefined,
          number: '',
          description: '',
          type: null,
          active: true,
          restrictions: accountRestrictions.RESTRICTION_NO,
          fiscal_year: this.activeFiscalYear,
        },
        accountTypes,
        restrictionOptions,
      }
    },
    computed: {
      displayCostTypeSelector() {
        return ['job_expense', 'job_income'].includes(this.model.restrictions)
      },
      costTypeByRestriction() {
        const types = {
          job_expense: 'cost',
          job_income: 'income',
        }
        return types[this.model.restrictions]
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            const result = await axios.put(`/restify/accounts/${this.model.id}`, this.model)
            this.$success(this.$t('Ledger account updated'))
            this.$store.commit('generalLedger/SET_CURRENT_ACCOUNT', result.data)
          } else {
            this.model.fiscal_year = this.activeFiscalYear
            await axios.post('/restify/accounts', this.model)
            this.$success(this.$t('Ledger account created'))
          }
          await this.$store.dispatch('globalLists/getGeneralConfiguration', true)

          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', this.model)
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the ledger account'))
        } finally {
          this.loading = false
        }
      },
      onChangeRestriction() {
        this.model.restriction_type_id = undefined
      },
      formatCostTypeName(costType) {
        const { abbr, name } = costType.attributes
        return `${abbr} (${name})`
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
  }
</script>
