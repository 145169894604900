<template>
  <AgDataTable
      v-bind="attrs"
      :copy-previous-row-enabled-by-default="hasSpecialUrlParams"
      :actions="readOnly ? '' : 'add'"
      :url="url"
      :url-params="urlParams"
      :columns="columns"
      :transform-data="mapData"
      :get-empty-row="getEmptyEntry"
      :groupIncludeTotalFooter="true"
      :data-loading="invoiceLoading"
      :show-cells-legend="showCellsLegend"
      :read-only="readOnly"
      :add-text="$t('New Entry')"
      hide-actions="filters"
      suppressColumnReordering
      @add="onRowAdd"
      @grid-ready="grid = $event"
      @cell-focused="onCellFocused"
      @cell-value-changed="onCellValueChanged"
  >
    <template #purchase_order_entry_id="{row}">
      <template v-if="row">
        <StatusBadge :status="row?.purchase_order_entry_id ? $t('Yes') : $t('No')"/>
      </template>
    </template>
    <template #header-info>
      <DistributionWarning
          :batch-amount="data.gross_amount"
          :entries-amount="entriesTotalAmount()"
          batch-name="invoice"
      />
    </template>
  </AgDataTable>
</template>
<script>
  import {
    accountCol,
    additionalSourceCol,
    costCenterCol,
    descriptionCol,
    quantityCol,
    sourceCol,
    subAccountCol,
    typeCol,
    updateCostCenterHeaderNames,
  } from '@/components/ag-grid/columns/costCenterColumns';
  import { costCenterDefaultFields, costCenterTypes, setTypeSources } from '@/components/grid-table/utils/cost-center';
  import {
    costCenterFields, getCostCenterFields,
    getDefaultAccounts,
    hasSpecialUrlParams,
    onChangeCostCenter
  } from '@/modules/common/util/costCenterUtils';
  import pick from 'lodash/pick';
  import { getEditablePriceCol } from '@/components/ag-grid/columns/editableColumns';
  import { getSpecialResource } from '@/modules/accounts-receivable/components/lump-sum-billings/lumpSumBillingUtils';
  import { editableTableProps, getTableData } from '@/components/ag-grid/tableUtils';
  import axios from 'axios';
  import { globalResources } from '@/components/form/util';
  import { cellClasses, requiredValueSetter, stopEditingOnTab } from '@/components/ag-grid/columnUtils'
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import { round } from "lodash";

  const ResourceEndpoint = '/restify/invoice-entries'
  const AmountFields = ['gross_amount', 'discount_amount', 'retention_amount']

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      vendor: {
        type: Object,
        default: () => ({}),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      showCellsLegend: {
        type: Boolean,
        default: true,
      },
      endpoint: {
        type: String,
        default: ResourceEndpoint,
      },
      parentEntityField: {
        type: String,
        default: 'invoice_id',
      }
    },
    data() {
      return {
        editableTableProps,
        grid: null,
      }
    },
    computed: {
      attrs() {
        return {
          ...editableTableProps,
          ...this.$attrs,
        }
      },
      invoiceLoading() {
        return this.$store.state.accountsPayable.invoiceLoading
      },
      url() {
        if (!this.data.id) {
          return
        }
        return this.endpoint
      },
      urlParams() {
        if (!this.data.id) {
          return
        }
        return {
          sort: 'order',
          perPage: 500,
          [this.parentEntityField]: this.data.id,
          related: 'source[id|number|description],addlSource[id|phase_code|cost_code|change_order|description]'
        }
      },
      getJobSubcontractTypeId() {
        return this.$store.getters['globalLists/getJobSubcontractTypeId']
      },
      getEquipmentTypeIdWithQuantity() {
        return this.$store.getters['globalLists/getEquipmentTypeIdWithQuantity']
      },
      hasSpecialUrlParams() {
        return hasSpecialUrlParams(this.$route.query)
      },
      columns() {
        return [
          {
            ...costCenterCol,
            cellEditorParams: {
              options: [
                {
                  label: this.$t('G&A'),
                  value: costCenterTypes.GeneralAndAdministrative,
                },
                {
                  label: this.$t('JOB'),
                  value: costCenterTypes.Job,
                },
                {
                  label: this.$t('S/B'),
                  value: costCenterTypes.WorkOrder,
                },
                {
                  label: this.$t('EQP'),
                  value: costCenterTypes.Equipment,
                },
                {
                  label: this.$t('INV'),
                  value: costCenterTypes.Inventory,
                },
              ],
            },
            valueSetter: params => {
              let value = params.newValue
              params.data.cost_center = value
              params.data = onChangeCostCenter(params.data)

              const costCenterDefaults = this.getVendorDefaults()

              if (costCenterDefaults.cost_center === value) {
                Object.keys(costCenterDefaults).forEach(key => {
                  params.data[key] = costCenterDefaults[key]
                })
              }
              params.node.setData(params.data)
              return true
            },
            editable: this.isNotLinkedToPurchaseOrder,
            cellClass: (params) => {
              if (this.readOnly) {
                return ''
              }

              return this.linkedPoCellClass(params)
            },
          },
          {
            ...sourceCol,
            editable: this.isSourceEditable,
            suppressNavigable: params => !this.isSourceEditable(params),
            cellClass: params => {
              if (params.node.footer || this.readOnly) {
                return ''
              }

              const isEditable = this.isSourceEditable(params)
              if (!isEditable) {
                return cellClasses.ReadOnly
              }

              const hasValue = params.data?.source_id
              return hasValue ? '' : cellClasses.Invalid
            },
            cellRendererParams: {
              showDescription: false,
            },
            minWidth: 60,
            maxWidth: 120,
          },
          {
            ...typeCol,
            editable: this.isTypeEditable,
            cellClass: params => {
              if (params.node.footer || this.readOnly) {
                return ''
              }

              const isEditable = this.isTypeEditable(params)
              if (!isEditable) {
                return cellClasses.ReadOnly
              }

              const hasValue = params.data?.type_id
              return hasValue ? '' : cellClasses.Invalid
            },
            valueSetter: params => {
              const entry = params.data
              if (entry.cost_center === costCenterTypes.Equipment) {
                entry.quantity = 0
              }

              entry.type_id = params.newValue

              if (entry.type_id === this.getJobSubcontractTypeId) {
                params.data = this.computeAmounts(entry, entry.gross_amount)
              }
              params.node.setData(params.data)
              return true
            },
          },
          {
            ...additionalSourceCol({
              readOnly: this.readOnly,
            }),
            cellRendererParams: {
              showDescription: false,
            },
            minWidth: 80,
            maxWidth: 120,
            valueSetter: params => {
              params.data.addl_source_id = params.newValue
              let resource = getSpecialResource(params.data.special_source_id, params.data.special_source_type)

              if (resource?.committed_po_amount) {
                this.$error(this.$t('Open Commitments found for line item but no P.O. referenced above.'))
              }
              return true
            },
            cellEditorParams: params => {
              const { cost_center } = params.data
              const resourceMapping = {
                [costCenterTypes.Equipment]: globalResources.EquipmentMaintenances,
              }
              const resourceName = resourceMapping[cost_center]

              return {
                resourceName,
                filterMethod: (option) => {
                  if (cost_center !== costCenterTypes.Job) {
                    return true
                  }
                  const isSubType = this.isSubContractType(params)
                  if (!isSubType) {
                    return true
                  }
                  // show only line items without a subcontractor vendor or with the same vendor id as the invoice
                  return !option.vendor_id || option.vendor_id === this.vendor?.id
                },
              }
            },
            editable: this.isNotLinkedToPurchaseOrder,
            cellClass: (params) => {
              if (this.readOnly) {
                return ''
              }
              return this.linkedPoCellClass(params)
            },
          },
          {
            ...accountCol(this.readOnly),
            cellRendererParams: {
              showDescription: false,
            },
            suppressNavigable: (params) => {
              return !!params.data.account
            },
            minWidth: 50,
            maxWidth: 80,
          },
          {
            ...subAccountCol,
          },
          {
            ...descriptionCol,
            minWidth: 200,
          },
          {
            ...quantityCol,
            minWidth: this.readOnly ? 60 : 80,
            valueSetter: params=> {
              const entry = params.data || {}
              if (params.newValue && entry.remaining_po_quantity && params.newValue > entry.remaining_po_quantity) {
                this.$error(this.$t(`Quantity exceeds remaining P.O. item quantity of ${entry.remaining_po_quantity}.`))
                return false
              }
              return requiredValueSetter(params, 0)
            },
            editable: params => {
              if (this.isNotLinkedToPurchaseOrder(params)) {
                return true
              }
              if (params?.data?.is_fixed_price) {
                return false
              }
              return !!params?.data?.quantity
            },
            cellClass: params => {
              if (this.isNotLinkedToPurchaseOrder(params)) {
                return
              }
              if (params?.data?.is_fixed_price || !params?.data?.quantity) {
                return cellClasses.ReadOnly
              }
            },
          },
          {
            headerName: this.$t('Unit Price'),
            field: 'unit_rate',
            component: 'FormattedPrice',
            cellEditor: this.$cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }

              this.onCellValueChanged(params)
              return true
            },
            minWidth: 100,
            maxWidth: 140,
            editable: this.isNotLinkedToPurchaseOrder,
            cellClass: (params) => {
              if (this.readOnly) {
                return ''
              }
              return this.linkedPoCellClass(params)
            },
          },
          {
            ...getEditablePriceCol({
              field: 'gross_amount',
              headerName: this.$t('Amount'),
              onChange: params => {
                params.data.net_amount = this.computedNetAmount(params.data)
              },
            }),
          },
          {
            ...getEditablePriceCol({
              field: 'discount_amount',
              headerName: this.$t('Discount'),
              onChange: params => {
                params.data.net_amount = this.computedNetAmount(params.data)
              },
              suppressKeyboardEvent: params => {
                if (this.isSubContractType(params)) {
                  return
                }
                stopEditingOnTab(params)
              },
            }),
          },
          {
            ...getEditablePriceCol({
              field: 'retention_amount',
              headerName: this.$t('Retention'),
              editable: this.isSubContractType,
              cellClass: params => {
                if (params.node.footer || this.readOnly) {
                  return 'flex justify-end'
                }
                return this.isSubContractType(params) ? 'flex justify-end' : 'readonly-ag-cell flex justify-end'
              },
              suppressNavigable: params => {
                return !this.isSubContractType(params)
              },
              suppressKeyboardEvent: params => {
                const isSubContract = this.isSubContractType(params)
                let isTabKey = params.event.key === 'Tab';
                if (isTabKey && isSubContract) {
                  params.api.stopEditing();
                }
              },
              onChange: params => {
                params.data.net_amount = this.computedNetAmount(params.data)
              },
            }),
          },
          {
            headerName: this.$t('Net'),
            field: 'net_amount',
            align: 'right',
            minWidth: 90,
            maxWidth: 120,
            component: 'FormattedPrice',
            cellClass: params => {
              return (params.node.footer || this.readOnly) ? 'flex justify-end' : 'readonly-ag-cell flex justify-end'
            },
            valueGetter: params => {
              return params.data.net_amount
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Linked P.O. Entry'),
            field: 'purchase_order_entry_id',
            minWidth: 70,
            hide: !this.data?.purchase_order_id,
            cellClass: params => {
              if (params?.data) {
                return cellClasses.ReadOnly
              }
            },
          },
          {
            ...getDeleteColumn({
              url: this.endpoint,
              hide: this.readOnly,
            }),
          },
        ]
      },
    },
    methods: {
      isNotLinkedToPurchaseOrder(params) {
        const purchaseOrderId = params?.data?.purchase_order_entry_id
        return purchaseOrderId === undefined || purchaseOrderId == null
      },
      linkedPoCellClass(params) {
        if (this.isNotLinkedToPurchaseOrder(params)) {
          return additionalSourceCol().cellClass(params)
        }
        return cellClasses.ReadOnly
      },
      isSourceEditable(params) {
        if (!this.isNotLinkedToPurchaseOrder(params)) {
          return false
        }
        return params.data?.cost_center !== costCenterTypes.GeneralAndAdministrative
      },
      isTypeEditable(params) {
        if (!this.isNotLinkedToPurchaseOrder(params)) {
          return false
        }
        const editableCostCenters = [costCenterTypes.Job, costCenterTypes.Equipment, costCenterTypes.WorkOrder]
        return editableCostCenters.includes(params.data?.cost_center)
      },
      isSubContractType(params) {
        return params.data?.type_id === this.getJobSubcontractTypeId
      },
      mapData(data) {
        return data.map(entry => {
          const { attributes } = entry
          const net_amount = this.computedNetAmount(attributes)
          return {
            ...attributes,
            net_amount,
            relationships: entry.relationships,
          }
        })
      },
      getVendorDefaults() {
        return pick(this.vendor, costCenterDefaultFields)
      },
      getEmptyEntry() {
        const costCenterDefaults = this.getVendorDefaults()
        const defaultAmounts = this.getDefaultComputedAmounts()
        const { source_id, cost_center } = getCostCenterFields(this.$route.query)
        if (cost_center !== costCenterTypes.GeneralAndAdministrative) {
          costCenterDefaults.cost_center = cost_center
        }
        if (source_id) {
          costCenterDefaults.source_id = source_id
        }

        let newEntry = {
          _localId: crypto.randomUUID(),
          dirty: true,
          [this.parentEntityField]: this.data[this.parentEntityField],
          ...costCenterDefaults,
          ...defaultAmounts,
          quantity: 0,
          description: '',
          order: 0,
        }

        this.trySetDefaultAccounts(newEntry)

        this.tryCollapseFormHeader()

        return newEntry
      },
      getVendorAccounts(entry) {
        entry.account = this.vendor?.account
        entry.subaccount = this.vendor?.subaccount

        return entry
      },
      tryCollapseFormHeader() {
        this.$emit('on-collapse-form-header')
      },
      async trySetDefaultAccounts(entry) {
        if (entry.cost_center === costCenterTypes.GeneralAndAdministrative) {
          return this.getVendorAccounts(entry)
        }

        return await getDefaultAccounts(entry)
      },
      onCellFocused(params) {
        updateCostCenterHeaderNames(params, true)
      },
      calculateGrossAmountBasedOnUnitPrice(params) {
        const quantity = +(params.data.quantity || 0)
        const unit_rate = +(params.data.unit_rate || 0)
        if (quantity === 0 && unit_rate === 0) {
          return
        }
        params.data.gross_amount = this.round(quantity * unit_rate)
        params.node.setDataValue('gross_amount', params.data.gross_amount)
      },
      async onCellValueChanged(params) {
        const field = params?.colDef?.field
        const entry = params.data
        const newValue = params.newValue

        if (AmountFields.includes(field)) {

          if (field === 'gross_amount') {
            params.data = this.computeAmounts(entry, newValue)
          } else {
            params.data.net_amount = this.computedNetAmount(entry)
          }

          params.node.setData(params.data)
          return true
        }

        if (['quantity', 'unit_rate'].includes(field)) {
          this.calculateGrossAmountBasedOnUnitPrice(params)
        }

        if (!costCenterFields.includes(field)) {
          return true
        }

        params.data = await this.trySetDefaultAccounts(entry)
        params.node.setData(params.data)
        // We need to explicitly set cell values here. Otherwise, cells won't update if in edit mode
        params.node.setDataValue('account', params.data.account)
        params.node.setDataValue('subaccount', params.data.subaccount)
      },
      entriesTotalAmount() {
        const entries = this.getData()

        if (!entries || !entries.length) {
          return 0
        }

        let result = 0

        entries.forEach(entry => {
          result += +entry.gross_amount
        })

        return round(result, 2)
      },
      remainingAmount() {
        return this.data.gross_amount - this.entriesTotalAmount()
      },
      getDefaultComputedAmounts() {
        let gross_amount = this.remainingAmount()
        if (gross_amount < 0 && this.data.gross_amount > 0) {
          gross_amount = 0
        }

        let discount_amount = 0.00
        if (this.data.discount_percent) {
          discount_amount = gross_amount * this.data.discount_percent / 100
          discount_amount = discount_amount.toFixed(2)
        }

        let retention_amount = 0.00
        if (this.data.retention_percent) {
          retention_amount = gross_amount * this.data.retention_percent / 100
          retention_amount = retention_amount.toFixed(2)
        }

        const net_amount = gross_amount - retention_amount

        return {
          gross_amount,
          discount_amount,
          retention_amount,
          net_amount,
        }
      },
      computeAmounts(entry, gross_amount) {
        if (parseFloat(entry.budget) < parseFloat(entry.gross_amount)) {
          this.$warning(this.$t('Warning...Job line item budget exceeded.'))
        }

        if (this.data.discount_percent) {
          let discount = gross_amount * this.data.discount_percent / 100
          entry.discount_amount = +discount.toFixed(2)
        }

        if (entry.cost_center !== costCenterTypes.GeneralAndAdministrative && this.data.retention_percent) {
          let retention = gross_amount * this.data.retention_percent / 100
          entry.retention_amount = +retention.toFixed(2)
        }

        const quantity = +(entry.quantity || 0)
        if (quantity) {
          entry.unit_rate = this.round(entry.gross_amount / entry.quantity)
        }

        entry.net_amount = this.computedNetAmount(entry)
        return entry
      },
      computedNetAmount(entry) {
        return +entry.gross_amount - +entry.retention_amount
      },
      getData() {
        const gridApi = this.grid?.api
        return getTableData(gridApi)
      },
      clearItems() {
        this.grid?.api?.setRowData([])
      },
      async pushLineItems(lineItems) {
        let entries = this.getData()
        entries = entries.filter(entry => !entry?.purchase_order_entry_id)

        const { account, subaccount } = this.vendor

        const entriesCount = entries.length
        const items = []

        for (let i = 0; i < lineItems.length; i++) {
          let entry = lineItems[i].attributes
          let entryId = lineItems[i].id
          let isFullyInvoiced = entry.invoiced_amount >= entry.amount

          if (entry.cost_center !== costCenterTypes.GeneralAndAdministrative) {
            entry = await getDefaultAccounts(entry)
          } else {
            entry.account = account
            entry.subaccount = subaccount
          }

          entry._localId = entryId
          entry.purchase_order_entry_id = entryId
          entry.order = i + 1 + entriesCount
          entry.gross_amount = entry.extended_amount
          entry.net_amount = entry.extended_amount
          entry.remaining_po_quantity = entry.quantity - entry.quantity_invoiced
          entry.discount_amount = 0
          entry.retention_amount = 0
          entry.dirty = true
          if (isFullyInvoiced) {
            delete entry.purchase_order_entry_id
          }

          delete entry.id
          items.push(entry)
        }

        entries.splice(entriesCount, 0, ...items)
        this.grid.api?.setRowData(entries)
      },
      async pushParsedLineItems(lineItems) {
        let entries = this.getData()
        entries = entries.filter(entry => !entry.purchase_order_entry_id)

        const { account, subaccount } = this.vendor

        const entriesCount = entries.length
        const items = []

        for (let i = 0; i < lineItems.length; i++) {
          let entry = this.getEmptyEntry()
          let parsedEntry = lineItems[i]

          if (entry.cost_center !== costCenterTypes.GeneralAndAdministrative) {
            entry = await getDefaultAccounts(entry)
          } else {
            entry.account = account
            entry.subaccount = subaccount
          }

          entry.order = i + 1 + entriesCount
          entry.gross_amount = parsedEntry.gross_amount
          entry.net_amount = parsedEntry.net_amount
          entry.discount_amount = parsedEntry.discount_amount
          entry.description = parsedEntry.description
          entry.quantity = parsedEntry.quantity
          entry.dirty = true

          items.push(entry)
        }

        entries.splice(entriesCount, 0, ...items)
        this.grid.api?.setRowData(entries)
      },
      mapEntry(entry, invoiceId) {
        entry = setTypeSources(entry)
        if (!entry[this.parentEntityField]) {
          entry[this.parentEntityField] = invoiceId
        }
        return entry
      },
      async onRowAdd() {
        await this.$nextTick()
        let entries = this.getData()
        if (entries.length > 1 && this.data.is_receipt) {
          this.$warning(this.$t('You can only add one line item to a receipt. When saved, the receipt will be automatically converted to an invoice and will no longer appear under the receipts tab.'))
        }
      },
      async storeProgress(invoiceId) {
        let entries = this.getData()
        entries = entries.filter(entry => entry.dirty || !entry.id).map(entry => this.mapEntry(entry, invoiceId))

        const entriesToSave = entries.filter(entry => !entry.id)
        const entriesToUpdate = entries.filter(entry => entry.id)
        const promises = []

        if (entriesToSave.length) {
          const savePromise = axios.post(`${this.endpoint}/bulk`, entriesToSave)
          promises.push(savePromise)
        }
        if (entriesToUpdate.length) {
          const updatePromise = axios.post(`${this.endpoint}/bulk/update`, entriesToUpdate)
          promises.push(updatePromise)
        }
        await Promise.all(promises)
      },
    },
  }
</script>
