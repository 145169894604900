<template>
  <AgDataTable
      :columns="columns"
      :masterDetail="true"
      :detailRowAutoHeight="true"
      :add-entity-in-new-tab="true"
      :detailCellRendererParams="detailCellRendererParams"
      :detailCellRenderer="detailCellRenderer"
      :actionsColumnWidth="showActions ? 300: 100"
      :hide-actions="showActions ? 'view' : 'filters,add,view'"
      url="/restify/accounts-payable-inits"
      :url-params="urlParams"
      :actions="showActions ? 'add,search,refresh,print,import': ''"
      import-url="/accounts-payable/settings/ap-initialize/import"
      permission="vendors"
      domLayout="autoHeight"
      ref="table"
      :perPage="9999"
      :showPagination="false"
      @grid-ready="grid = $event"
      @data-fetch="$emit('data-fetch', $event)"
  >

    <template
      v-if="showActions"
      #extra-actions="{row}">
      <ProofListingButton
        :path="`/accounts-payable/settings/ap-initialize/${row.id}/proof-listing`"
      />
      <AccountsPayableInitExport
        :id="row.id"
        size="xs"
        class="!mr-0"
      />
    </template>

  </AgDataTable>
</template>
<script>
  import AccountsPayableInitEntries from '@/modules/accounts-payable/components/settings/AccountsPayableInitEntries'
  import AccountsPayableInitExport from "@/modules/accounts-payable/components/settings/AccountsPayableInitExport.vue";

  export default {
    components: {
      AccountsPayableInitExport,
      AccountsPayableInitEntries,
    },
    props: {
      urlParams: {
        type: Object,
        default: () => ({})
      },
      showActions: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        grid: null,
        detailCellRenderer: null,
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Period'),
            prop: 'attributes.period',
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            label: this.$t('Fiscal Year'),
            prop: 'attributes.fiscal_year',
          },
          {
            label: this.$t('Entries'),
            prop: 'attributes.entries_count',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'StatusLink',
            align: 'center',
          },
        ]
      },
      detailCellRendererParams() {
        return {
          parentGrid: this.grid,
          readOnly: true,
        }
      }
    },
    created() {
      this.detailCellRenderer = 'AccountsPayableInitEntries'
    },
  }
</script>
