import { employeeStatuses, federalFilingStatuses, minorityTypes, payFrequencies, taxAdjustments } from "@/enum/enums";
import i18n from "@/i18n";
import { Employee } from "@/modules/common/types/models";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";

export const taxAdjustmentOptions = [
  {
    label: i18n.t('No Adjustment'),
    value: taxAdjustments.NO_ADJUSTMENT,
  },
  {
    label: i18n.t('Additional Dollar Amount'),
    value: taxAdjustments.ADDITIONAL_AMOUNT,
  },
  {
    label: i18n.t('Additional Percent of Gross'),
    value: taxAdjustments.ADDITIONAL_GROSS_PERCENTAGE,
  },
  {
    label: i18n.t('Fixed Dollar Amount'),
    value: taxAdjustments.FIXED_AMOUNT,
  },
  {
    label: i18n.t('Fixed Percent'),
    value: taxAdjustments.FIXED_PERCENTAGE,
  },
  {
    label: i18n.t('Additional Percent of Tax'),
    value: taxAdjustments.ADDITIONAL_TAX_PERCENTAGE,
  },
]

export const statusOptions = [
  {
    label: i18n.t('Hourly'),
    value: employeeStatuses.HOURLY,
  },
  {
    label: i18n.t('Salary'),
    value: employeeStatuses.SALARY,
  },
  {
    label: i18n.t('Inactive'),
    value: employeeStatuses.INACTIVE,
  },
  {
    label: i18n.t('Deceased'),
    value: employeeStatuses.DECEASED,
  },
]

export const payFrequencyOptions = [
  {
    label: i18n.t('Weekly'),
    value: payFrequencies.WEEKLY,
  },
  {
    label: i18n.t('Bi-Weekly'),
    value: payFrequencies.BI_WEEKLY,
  },
  {
    label: i18n.t('Monthly'),
    value: payFrequencies.MONTHLY,
  },
  {
    label: i18n.t('Semi Monthly'),
    value: payFrequencies.SEMI_MONTHLY,
  },
  {
    label: i18n.t('Daily'),
    value: payFrequencies.DAILY,
  },
]

export const minorityOptions = [
  {
    label: i18n.t('None'),
    value: minorityTypes.None,
  },
  {
    label: i18n.t('Black'),
    value: minorityTypes.Black,
  },
  {
    label: i18n.t('Hispanic'),
    value: minorityTypes.Hispanic,
  },
  {
    label: i18n.t('Asian'),
    value: minorityTypes.Asian,
  },
  {
    label: i18n.t('Indian'),
    value: minorityTypes.Indian,
  },
  {
    label: i18n.t('Hawaiian'),
    value: minorityTypes.Hawaiian,
  },
  {
    label: i18n.t('2/more'),
    value: minorityTypes.More,
  },
]

export const federalStatusOptions = [
  {
    label: i18n.t('Single'),
    value: federalFilingStatuses.SINGLE,
  },
  {
    label: i18n.t('Married filing separately'),
    value: federalFilingStatuses.MARRIED_1_OR_LESS,
  },
  {
    label: i18n.t('Married filing jointly'),
    value: federalFilingStatuses.MARRIED_2_OR_MORE,
  },
  {
    label: i18n.t('Head of Household'),
    value: federalFilingStatuses.HEAD_OF_HOUSEHOLD,
  },
  {
    label: i18n.t('Exempt'),
    value: federalFilingStatuses.EXEMPT,
  },
]

export const sexOptions = [
  {
    label: i18n.t('Male'),
    value: 'M',
  },
  {
    label: i18n.t('Female'),
    value: 'F',
  },
]

export const employeeCostCenters = [
  {
    label: i18n.t('Job'),
    value: costCenterTypes.Job,
  },
  {
    label: i18n.t('General & Administrative'),
    value: costCenterTypes.GeneralAndAdministrative,
  },
  {
    label: i18n.t('Equipment Management'),
    value: costCenterTypes.Equipment,
  },
]

export function getOptionLabel(options: any[], value: string | number) {
  const option = options.find((option) => option.value === value);
  return option ? option.label : '';
}

export function getEmployeeFullName(employee: Employee) {
  if (!employee) {
    return ''
  }
  const {first_name, middle_name, suffix, last_name} = employee || {};
  const parts = [first_name, last_name, middle_name, suffix]
  return parts.filter(p => p).join(' ')
}
