<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>
      <base-filter-row :title="$t('View')">
        <base-select
            v-model="model.view_type"
            :options="viewTypeOptions"
            :name="$t('View Type')"
            class="col-span-2"
            rules="required"
            @change="onChangeViewType"
        />

        <div v-if="model.view_type  === viewTypes.AccountAndSubaccountTotals"
             class="flex col-span-3 2xl:col-span-2 items-baseline">
          <span class="text-label">{{ $t('Sort By') }}</span>
          <base-select
              v-model="model.group_by"
              :options="sortByOptions"
              :name="$t('Sort By')"
              class="flex-1"
          />
        </div>
      </base-filter-row>

      <base-filter-row :title="$t('Compare For')">
        <period-year-filter
            v-model="model"
            class="col-span-3"
        />

        <div class="col-span-3 flex items-baseline">
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">{{ $t('Subtotal On') }}</span>
          <base-select
              v-model="subtotalPosition"
              :key="model.view_type"
              :options="filteredSubtotalPositionOptions"
              class="flex-1"
              @change="onChangeSubtotalPosition"
          />
        </div>
      </base-filter-row>
      <AccountFilterRow
        v-model="model"
        @reset="resetAccountFilters"
      >
        <template #extra-filters>
          <SubAccountRangeFilter
            v-model="model"
            grid-class="col-span-3"
          />
        </template>
      </AccountFilterRow>

      <base-filter-row>

        <base-checkbox
            v-if="model.view_type  === viewTypes.AccountAndSubaccountTotals"
            v-model="model.page_before_subaccount"
            :label="$t('Page Before Subaccount')"
            class="col-span-6"
            id="page_before_subaccount"
        />

      </base-filter-row>

    </template>

    <template #table="{data, loading}">
      <account-balance-comparison-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :subtotal-on="+model.subtotal_on"
      />
    </template>

  </base-report-form>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { PeriodYearFilter, SubAccountRangeFilter } from '@/components/range-filters'
  import AccountBalanceComparisonReportTable
    from '@/modules/ledger/components/reports/AccountBalanceComparisonReportTable.vue'
  import AccountFilterRow from '@/components/entity-filters/AccountFilterRow.vue'

  const sortBy = {
    Account: 'account',
    Subaccount: 'subaccount',
  }

  const viewTypes = {
    AccountTotals: 'account',
    AccountAndSubaccountTotals: 'account_subaccount',
  }

  const subtotalPositions = {
    NoSubtotals: '0',
    FirstPositionOfAcct: '1',
    First2PositionOfAcct: '2',
    First3PositionOfAcct: '3',
    AccountType: 'type',
  }

  export default defineComponent({
    components: {
      PeriodYearFilter,
      AccountFilterRow,
      SubAccountRangeFilter,
      AccountBalanceComparisonReportTable,
    },
    data() {
      return {
        viewTypes,
        subtotalPosition: subtotalPositions.NoSubtotals as string,
        model: <GL_REPORTS.AccountBalanceComparison>{
          view_type: viewTypes.AccountTotals,
          group_by: sortBy.Account,
          period: 0,
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          subaccount_number_from: null,
          subaccount_number_to: null,
          subtotal_on: subtotalPositions.NoSubtotals,
          subtotal_by_type: false,
          page_before_subaccount: false,
        },
        viewTypeOptions: [
          {
            label: this.$t('Account Totals'),
            value: viewTypes.AccountTotals,
          },
          {
            label: this.$t('Account and Sub-Account Totals'),
            value: viewTypes.AccountAndSubaccountTotals,
          },
        ],
        sortByOptions: [
          {
            label: this.$t('Account'),
            value: sortBy.Account,
          },
          {
            label: this.$t('Sub-Account'),
            value: sortBy.Subaccount,
          },
        ],
        subtotalPositionOptions: [
          {
            label: this.$t('1st Position of Acct'),
            value: subtotalPositions.FirstPositionOfAcct,
          },
          {
            label: this.$t('1st 2 Position of Acct'),
            value: subtotalPositions.First2PositionOfAcct,
          },
          {
            label: this.$t('1st 3 Position of Acct'),
            value: subtotalPositions.First3PositionOfAcct,
          },
          {
           label: this.$t('Account Type'),
           value: subtotalPositions.AccountType,
          },
          {
            label: this.$t('No Subtotals'),
            value: subtotalPositions.NoSubtotals,
          },
        ],
      }
    },
    computed: {
      reportNumber() {
        return this.model.view_type === viewTypes.AccountTotals
          ? 1885
          : '1885a'
      },
      filteredSubtotalPositionOptions() {
        if (this.model.view_type === viewTypes.AccountAndSubaccountTotals) {
          return this.subtotalPositionOptions.filter((option) => option.value !== subtotalPositions.AccountType)
        }
        return this.subtotalPositionOptions
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.subaccount_number_from = this.model.subaccount_number_to = null
      },
      onChangeViewType() {
        this.model.subtotal_by_type = false
        this.model.subtotal_on = this.subtotalPosition = subtotalPositions.NoSubtotals
      },
      onChangeSubtotalPosition(position: string) {
        this.model.subtotal_on = position ===  subtotalPositions.AccountType ? subtotalPositions.FirstPositionOfAcct : position
        this.model.subtotal_by_type = position === subtotalPositions.AccountType
      },
      setSubtotalPosition() {
        if (this.model.subtotal_by_type) {
          this.subtotalPosition = subtotalPositions.AccountType
        } else {
          this.subtotalPosition = this.model.subtotal_on
        }
      },
    },
    mounted() {
      this.setSubtotalPosition()
    },
  })
</script>
