<template>
  <MaintenanceCodeForm
    :key="currentResource?.id"
    :data="currentResource"
    @refresh="getMaintenanceCode"
  />
</template>
<script>
import MaintenanceCodeForm from '@/modules/equipment/components/MaintenanceCodeForm'

export default {
  components: {
    MaintenanceCodeForm
  },
  computed: {
    maintenanceCodes() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.EquipmentMaintenances)
    },
    currentResource() {
      return this.$store.state.equipmentManagement.currentMaintenanceCode
    },
  },
  methods: {
    getMaintenanceCode() {
      this.$store.dispatch('equipmentManagement/getMaintenanceCode', this.currentResource.id)
    },
  }
}
</script>
