<template>
  <div>
    <div class="flex justify-between items-center w-full leading-4">
      <EmployeeLink :id="employeeId"/>
      <div class="flex space-x-2 items-center">
        <BaseButton
          v-if="canReset"
          :loading="resetLoading"
          variant="gray-light"
          size="xs"
          @click="onReset"
        >
          <RotateCcwIcon class="w-4 h-4 mr-2"/>
          <span>{{ $t('Reset all') }}</span>
        </BaseButton>
        <BaseButton
          v-if="canReject"
          :loading="rejectLoading"
          variant="gray-light"
          size="xs"
          @click="showRejectDialog = true"
        >
          <XIcon class="w-4 h-4 mr-2"/>
          <span>{{ $t('Reject all') }}</span>
        </BaseButton>
        <BaseButton
          v-if="canApprove"
          :loading="approveLoading"
          variant="primary-light"
          size="xs"
          @click="onApprove">
          <CheckIcon class="w-4 h-4 mr-2"/>
          <span>{{ $t('Approve all') }}</span>
        </BaseButton>
        <TableEditButton @click="editTimesheet"/>
      </div>
      <BaseFormDialog
        v-if="showRejectDialog"
        :open.sync="showRejectDialog"
        :title="$t('Reject Timesheet')"
        :append-to-body="true"
        :loading="rejectLoading"
      >
        <BaseForm
          :save-text="$t('Reject')"
          layout="modal"
          @submit="onReject">
          <base-textarea
            v-model="rejectReason"
            :label="$t('Reject Reason')"
            rules="required"
            class="col-span-6"
          />
        </BaseForm>
      </BaseFormDialog>
    </div>
  </div>
</template>
<script>

import { CheckIcon, RotateCcwIcon, XIcon } from "vue-feather-icons";
import {
  approveTimesheet, notApprovedFilter, notRejectedFilter,
  notResetFilter,
  rejectTimesheet,
  resetTimesheet
} from "@/modules/payroll/utils/timesheetUtils";

export default {
  components: { RotateCcwIcon, XIcon, CheckIcon },
  data() {
    return {
      approveLoading: false,
      rejectLoading: false,
      resetLoading: false,
      showRejectDialog: false,
      rejectReason: '',
    }
  },
  computed: {
    children() {
      return this.params.node?.allLeafChildren
    },
    data() {
      return this.children[0]?.data || {}
    },
    employeeId() {
      return this.data?.attributes?.employee_id || {}
    },
    canReset() {
      return this.children.some(notResetFilter)
    },
    canReject() {
      return this.children.some(notRejectedFilter)
    },
    canApprove() {
      return this.children.some(notApprovedFilter)
    }
  },
  methods: {
    async onReset() {
      try {
        this.resetLoading = true
        await resetTimesheet(this.children)
      } finally {
        this.resetLoading = false
      }
    },
    async onReject() {
      try {
        this.rejectLoading = true
        await rejectTimesheet(this.children, this.rejectReason)
        this.rejectReason = ''
        this.showRejectDialog = false
      } finally {
        this.rejectLoading = false
      }
    },
    async onApprove() {
      try {
        this.approveLoading = true
        await approveTimesheet(this.children)
      } finally {
        this.approveLoading = false
      }
    },
    editTimesheet() {
      this.$router.push({
        path: '/payroll/timesheets/review',
        query: {
          employee_id: this.employeeId,
          from: this.params.from,
          to: this.params.to,
        }
      })
    }
  }
}
</script>
