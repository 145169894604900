<template>
  <RecurringBillingsTable
    v-bind="$attrs"
    :billing-status="status"
  />
</template>
<script>
import { resourceStatuses } from '@/enum/enums'
import RecurringBillingsTable from '@/modules/accounts-receivable/components/recurring-billings/RecurringBillingsTable.vue'

export default {
  components: {
    RecurringBillingsTable,
  },
  data() {
    return {
      status: resourceStatuses.Pending,
    }
  },
  watch: {
    '$route.meta': {
      immediate: true,
      handler(meta) {
        this.status = meta?.status
      },
    },
  },
}
</script>
