<template>
  <div>
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Payroll Settings'),
            path: `/payroll/settings/payroll-settings`,
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Timesheet Settings'),
            path: `/payroll/settings/timesheet-settings`,
            permission: 'timesheets_approve',
          },
          {
            name: this.$t('Labor Burden Values'),
            path: `/payroll/settings/labor-burden-values`,
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Settings Update Activity'),
            path: '/payroll/settings/activity-log',
            permission: 'job_costing_settings',
          },
          {
            name: this.$t('State Tax Jurisdictions'),
            path: '/payroll/settings/state-taxes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Local Tax Jurisdictions'),
            path: '/payroll/settings/local-taxes',
            permission: 'payroll_settings',
          },
          {
            name: this.$t('Open Payroll Calendar Year'),
            path: `/payroll/settings/open-calendar-year`,
            permission: 'payroll_settings',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => {
          const hasPermission = this.$can(item.permission)
          if (item.renderIf) {
            return item.renderIf() && hasPermission
          }
          return hasPermission
        })
      },
    },
  }
</script>
