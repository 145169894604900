<template>
  <div>
    <BaseDialog
      v-if="showChart"
      :visible.sync="showChart"
      :append-to-body="true"
      size="lg"
      @close="showChart = false"
    >
      <div v-loading="chartLoading" id="chart-container" class="vega-embed min-h-[300px] w-full" ref="embedRef" />
    </BaseDialog>
    <LiveChatButtons :supports-video="true"/>
  </div>
</template>

<script setup lang="ts">
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import useLiveApi from "@/modules/support/composables/useLiveApi";
import LiveChatButtons from "@/modules/support/components/LiveChatButtons.vue";
import vegaEmbed from "vega-embed";
import store from "@/store";
import {useAllRoutes} from "@/modules/dashboard/composables/useAllRoutes";
import {LiveConfig} from "@/modules/support/types/multimodal-live-types";
import {
  chartDataJson,
  renderChartsTool,
  searchByFieldFunctionTool,
  searchFunctionTool,
  sortByFieldFunctionTool
} from "@/modules/support/lib/tools";
import {delay} from "@/utils/utils";

const {client, setConfig, connected} = useLiveApi();

const user = computed(() => {
  return store.state.auth?.user || {}
})


const {cleanRoutes} = useAllRoutes()
const companyContext = computed(() => {
  const globalLists = store.state.globalLists
  const contextMap = {
    routes: {
      description: 'All the application pages the user has access to',
      data: cleanRoutes.value
    },
    // accounts: {
    //   description: 'General Ledger Accounts',
    //   show: can('accounts_show'),
    //   data: store.state.company.accounts,
    // },
    // vendors: {
    //   description: 'Accounts Payable Vendors',
    //   show: can('vendors_show'),
    //   data: orderBy(globalLists[globalResources.Vendors], 'current_ap_amount', 'desc').slice(0, 50)
    // },
    // customers: {
    //   description: 'Accounts Receivable Customers',
    //   show: can('customers_show'),
    //   data: globalLists[globalResources.Customers]
    // }
  }
  return contextMap
})

const embedRef = ref()
const showChart = ref(false)
const chartLoading = ref(false)

const systemInstructions = computed(() => {
  return `You are a helpful accounting assistant for a software product called Construction Partner. You are helping users navigate and understand the Construction Partner application.
          ------------------------------------------------
          General suggestions
          - If you don't have enough context, you can always ask the user to share his screen or share the current page content.
          - Try not interrupt the user while they are typing and don't act overly helpful or intrusive.
          - Don't assume things you don't know for sure or things that cannot be found on the internet.
          - When the user asks for guidance about what they see on the screen, ensure they have their screen shared for optimal results.
          - Any time the user is asking you for a graph call the "render_charts" function (using vega embed) provided you. Dont ask for additional information just make your best judgement.
          - Don't be very technical or developer oriented and try to keep the conversation easy to understand.
          ------------------------------------------------
          This is the current user information. You can use this information to help the current user you are speaking to.
          ${JSON.stringify(user.value)}
          ------------------------------------------------
          This is the general product information. You can use this information to help the current user you are speaking to.
          ${JSON.stringify(companyContext.value)}
          ------------------------------------------------
          Commonly referenced fields
          Open amount, Open AR amount, Open Receivable amount, Customer amount, Current AR amount, Current customer amount -> current_ar_amount
          Open amount, Open AP amount, Open Payable amount, Vendor amount, Current AP amount, Current vendor amount -> current_ap_amount
          Created At, Created, Date Created -> created_at
          `
})

const apiConfig = computed(() => {
  return {
    model: 'models/gemini-2.0-flash-exp',
    generationConfig: {
      responseModalities: 'audio',
      speechConfig: {
        voiceConfig: {
          prebuiltVoiceConfig: {voiceName: 'Puck'}
        },
      },
    },
    systemInstruction: {
      parts: [
        {
          text: systemInstructions.value,
        },
      ],
    },
    tools: [
      {googleSearch: {}},
      {
        functionDeclarations: [
          sortByFieldFunctionTool,
          searchFunctionTool,
          searchByFieldFunctionTool,
          renderChartsTool,
        ]
      },
    ],
  }
})

watch(() => chartDataJson.value, async () => {
  try {
    chartLoading.value = true
    showChart.value = true
    await nextTick()
    const chartData = JSON.parse(chartDataJson.value)
    console.log(chartData)
    const result = await vegaEmbed('#chart-container', chartData);
    result.view?.resize()?.run();
  } catch (err) {
    console.error(err)
  } finally {
    chartLoading.value = false
  }
})

watch(() => apiConfig.value, (newVal) => {
  setConfig(newVal as LiveConfig);
})

onMounted(() => {
  setConfig(apiConfig.value as LiveConfig);
});

</script>

