<template>
  <base-filter-range class="date-range_filter">
    <base-date-picker
        v-model="dateInterval"
        :picker-options="datePickerOptions"
        :range-separator="$t('To')"
        :start-placeholder="$t('Start Date')"
        :end-placeholder="$t('End Date')"
        clearable
        align="left"
        type="daterange"
        :name="$attrs.name"
        :rules="$attrs.rules"
        @change="onChangeInterval"
    />
  </base-filter-range>
</template>
<script>
  import startOfQuarter from 'date-fns/startOfQuarter'
  import startOfMonth from 'date-fns/startOfMonth'
  import startOfYear from 'date-fns/startOfYear'
  import endOfYear from 'date-fns/endOfYear'
  import format from "date-fns/format";
  import parseISO from "date-fns/parseISO";
  import subYears from "date-fns/subYears";
  import { endOfQuarter, subMonths } from "date-fns";

  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          start_date: null,
          end_date: null,
        }),
      },
      startDateKey: {
        type: String,
        default: 'start_date',
      },
      endDateKey: {
        type: String,
        default: 'end_date',
      },
      startDateRequired: Boolean,
      endDateRequired: Boolean,
    },
    data() {
      return {
        dateInterval: [],
        datePickerOptions: {
          shortcuts: [
            {
              text: this.$t('This Month'),
              onClick(picker) {
                const start = startOfMonth(new Date())
                const end = new Date()
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: this.$t('This Quarter'),
              onClick(picker) {
                const start = startOfQuarter(new Date())
                const end = new Date()
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: this.$t('This Year'),
              onClick(picker) {
                const start = startOfYear(new Date())
                const end = new Date()
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: this.$t('Last Month'),
              onClick(picker) {
                const year = new Date().getFullYear()
                const month = new Date().getMonth()

                const start = new Date(year, (month-1), 1)
                const end = new Date(year, month, 0)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: this.$t('Last Quarter'),
              onClick(picker) {
                const currentQuarterStart = startOfQuarter(new Date())
                const start = subMonths(currentQuarterStart, 3)
                const end = endOfQuarter(start)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: this.$t('Last Year'),
              onClick(picker) {
                const start = subYears(startOfYear(new Date()), 1)
                const end = subYears(endOfYear(new Date()), 1)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
      }
    },
    methods: {
      clearRange() {
        this.dateInterval = null
        this.onChangeInterval(this.dateInterval)
      },
      onChangeInterval(interval) {
        if (!interval) {
          this.$set(this.value, this.startDateKey, null)
          this.$set(this.value, this.endDateKey, null)
          return
        }
        let start = interval[0]
        let end = interval[1]
        if (typeof start === 'string') {
          start = parseISO(start)
        }
        if (typeof end === 'string') {
          end = parseISO(end)
        }
        let startData = format(start, 'yyyy-MM-dd')
        let endData = format(end, 'yyyy-MM-dd')

        this.$set(this.value, this.startDateKey, startData)
        this.$set(this.value, this.endDateKey, endData)
      }
    },
    mounted() {
      // * Default interval - this month ( 1 - current day)
      const start = startOfMonth(new Date())
      const end = new Date()

      this.dateInterval = [start, end]
      this.onChangeInterval(this.dateInterval)
    },
    beforeDestroy() {
      this.$set(this.value, this.startDateKey, null)
      this.$set(this.value, this.endDateKey, null)
    }
  }
</script>
<style lang="scss">
  .date-range_filter {
    .el-date-editor {
      width: 100% !important;
    }
  }
</style>
