<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <div
        :title="$t('Number')"
        class="detail-row"
      >
        <HashIcon class="detail-icon"/>
        <div>{{ repairOrderData.number }}</div>
        <StatusBadge :status="repairOrderData.status"/>
      </div>
      <div
        :title="$t('Equipment')"
        class="detail-row"
      >
        <EquipmentIcon class="detail-icon"/>
        <div class="truncate">
          <span class="text-xs text-gray-500">{{$t('Equipment')}}</span>
          <EquipmentLink
            :data="repairOrder?.relationships?.equipment"
          />
        </div>
      </div>
      <div
        :title="$t('Assigned To')"
        class="detail-row"
      >
        <UserCheckIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Assigned To') }}</span>
          <EmployeeLink
            :data="repairOrder?.relationships?.employee"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { computed } from 'vue'
import { HashIcon, UserCheckIcon } from 'vue-feather-icons'
import EquipmentIcon from "@/modules/payroll/components/timesheets/EquipmentIcon.vue";

const props = defineProps({
  repairOrder: {
    type: Object,
    required: true,
  },
})

const repairOrderData = computed(() => {
  return props.repairOrder?.attributes || {}
})
</script>
