<template>
  <div class="flex items-center">
    <span v-if="headerName"
          class="flex-1"
    >
      {{ headerName }}
    </span>
    <ElDropdown trigger="click">
      <div
          :data-tip="$t('Toggle columns')"
          tabindex="0"
          class="tooltip"
      >
        <ColumnsIcon class="w-5 h-5"/>
      </div>
      <template #dropdown>
        <ElDropdownMenu>
          <li
              v-for="column in getToggleColumns()"
              :key="column.getColId()"
              class="px-2"
          >
            <BaseCheckbox
                :id="column.getColId()"
                :value="column.visible"
                @input="setColumnVisible(column, $event)"
            >
              <template #label>
                <div class="w-32 inline-flex text-sm text-gray-900 font-medium capitalize">
                  {{ column.getColDef()?.headerName }}
                </div>
              </template>
            </BaseCheckbox>
          </li>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script>
  import { Dropdown as ElDropdown, DropdownMenu as ElDropdownMenu } from 'element-ui'
  import { ColumnsIcon } from 'vue-feather-icons'
  import { useStorage } from '@vueuse/core'
  import { gridContext } from './gridContext'

  export default {
    components: {
      ElDropdown,
      ElDropdownMenu,
      ColumnsIcon,
    },
    setup() {
      const storageColumns = useStorage(gridContext.storageKey, [])
      return {
        storageColumns
      }
    },
    computed: {
      headerName() {
        return this.params?.column?.colDef?.headerName
      }
    },
    methods: {
      getToggleColumns() {
        const allColumns = this.params?.columnApi?.getColumns() || []
        return allColumns.filter((col) => {
          const colDef = col.getColDef()
          const hasHeader = colDef.headerName?.trim()?.length > 0
          return !colDef.lockVisible && hasHeader && !colDef?.rowGroup
        })
      },
      setColumnVisible(col, value) {
        this.params.columnApi.setColumnVisible(col, value)
        const colDefs = this.params?.api?.getColumnDefs() || []
        this.storageColumns = colDefs
      },
    }
  }
</script>
