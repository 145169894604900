<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
      :transform-filters="transformFilters"
      :can-export-to-csv="true"
  >

    <template #filters>

      <base-filter-row :title="$t('Sort By')">
        <base-select
            v-model="model.group_by"
            :add-entity="false"
            :options="groupByOptions"
            class="col-span-2"
        />
        <base-checkbox
            v-model="model.totals_only"
            :label="$t('Totals Only')"
            class="col-span-3"
            id="totals_only"
        />
      </base-filter-row>

      <base-filter-row :title="$t('Journal')">

        <base-select
            v-model="model.journal_code"
            :options="journalCodeOptions"
            class="col-span-3"
        />

        <div class="col-span-3 flex items-baseline">
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">
            {{ $t('Journal No') }}
          </span>
          <base-input
              v-model.number="model.journal_number"
              :placeholder="$t('Enter Journal Number')"
              class="col-span-1"
              type="number"
              inline-errors
          />
        </div>

      </base-filter-row>

      <period-year-range-filter v-model="model">
        <base-checkbox
          v-model="model.include_period_0"
          :label="$t('Include Period 0')"
          class="col-span-2"
          id="include_period_0"
        />
      </period-year-range-filter>

      <base-filter-row :title="$t('Date Range')">

        <base-select
            v-model="model.date_to_parse"
            :options="dateToParseOptions"
            class="col-span-2"
        />

        <date-range-filter
            v-if="model.date_to_parse !== dateToParse.All"
            v-model="model"
            class="col-span-4"
        />

      </base-filter-row>

      <base-filter-row :title="$t('Reference Range')">

        <number-range-filter
            :from="model.reference_from"
            :to="model.reference_to"
            :from.sync="model.reference_from"
            :to.sync="model.reference_to"
            grid-class="col-span-3"
        />

      </base-filter-row>

      <base-filter-row :title="$t('Vendor / Customer / Employee')">

        <base-input
            v-model="model.business_code"
            :placeholder="$t('Enter Code')"
            class="col-span-2"
            inline-errors
        />

        <base-select
            v-model="model.source_type"
            :options="sourceTypeOptions"
            :placeholder="$t('All')"
            class="col-span-1"
            clearable
            @change="onChangeSourceType"
        />

        <component
            v-model="model.source_id"
            :is="getSourceComponent"
            :name="$t('Source')"
            :add-entity="false"
            :hide-label="true"
            rules="required"
            class="col-span-3"
            @entity-change="onChangeSource"
        />

      </base-filter-row>

      <AccountFilterRow
        v-model="model"
        @reset="resetAccountFilters"
      >
        <template #extra-filters>
          <SubAccountRangeFilter
            v-model="model"
            grid-class="col-span-3"
          />
        </template>
      </AccountFilterRow>

    </template>

    <template #table="{data, loading}">
      <HistorySelectionReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        :group-by="model.group_by"
        :totals-only="model.totals_only"
        :transform-filters="transformFilters"
        ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { codes } from '@/modules/ledger/enum/journal'
  import { historySelectionGroupBy } from '@/modules/ledger/components/reports/util'
  import HistorySelectionReportTable from '@/modules/ledger/components/reports/HistorySelectionReportTable.vue'
  import {
    PeriodYearRangeFilter,
    DateRangeFilter,
    NumberRangeFilter,
    SubAccountRangeFilter, periodYearFilterKeys,
  } from '@/components/range-filters'
  import AccountFilterRow from '@/components/entity-filters/AccountFilterRow.vue'
  import { now } from '@/plugins/dateFormatPlugin'

  const dateToParse = {
    All: 'all',
    ReferenceDate: 'reference_date',
    PostedAt: 'posted_at',
  }

  export const sourceTypes = {
    Job: 'job',
    WorkOrder: 'work-order',
    Equipment: 'equipment',
    Material: 'material',
  }

  export default defineComponent({
    components: {
      DateRangeFilter,
      NumberRangeFilter,
      PeriodYearRangeFilter,
      SubAccountRangeFilter,
      HistorySelectionReportTable,
      AccountFilterRow,
    },
    data() {
      return {
        dateToParse,
        reportNumber: 1871,
        historySelectionGroupBy,
        model: <GL_REPORTS.HistorySelection> {
          group_by: historySelectionGroupBy.Account,
          totals_only: false,
          journal_code: null,
          journal_number: null,

          ...periodYearFilterKeys,

          date_to_parse: dateToParse.PostedAt,
          start_date: null,
          end_date: now,
          reference_from: null,
          reference_to: null,
          business_code: null,
          source_id: null,
          source_type: null,
          source_code: null,
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          subaccount_number_from: null,
          subaccount_number_to: null,
          include_period_0: false,
        },
        dateToParseOptions: [
          {
            label: this.$t('All Dates'),
            value: dateToParse.All,
          },
          {
            label: this.$t('Reference'),
            value: dateToParse.ReferenceDate,
          },
          {
            label: this.$t('Posted'),
            value: dateToParse.PostedAt,
          },
        ],
        groupByOptions: [
          {
            label: this.$t('Account'),
            value: historySelectionGroupBy.Account,
          },
          {
            label: this.$t('Journal'),
            value: historySelectionGroupBy.Journal,
          },
          {
            label: this.$t('Posted Date'),
            value: historySelectionGroupBy.PostedDate,
          },
          {
            label: this.$t('Sub Account'),
            value: historySelectionGroupBy.SubAccount,
          },
          {
            label: this.$t('Fiscal Period'),
            value: historySelectionGroupBy.FiscalPeriod,
          },
        ],
        journalCodeOptions: [
          {
            label: this.$t('All Journals'),
            value: null,
          },
          ...codes,
        ],
        sourceTypeOptions: [
          {
            label: this.$t('Job'),
            value: sourceTypes.Job,
          },
          {
            label: this.$t('Work order'),
            value: sourceTypes.WorkOrder,
          },
          {
            label: this.$t('Material'),
            value: sourceTypes.Material,
          },
          {
            label: this.$t('Equipment'),
            value: sourceTypes.Equipment,
          },
        ],
      }
    },
    computed: {
      getSourceComponent() {
        if (!this.model.source_type) {
          return ''
        }
        const sourceComponentMap = {
          [sourceTypes.Job]: 'JobSelect',
          [sourceTypes.WorkOrder]: 'WorkOrderSelect',
          [sourceTypes.Material]: 'MaterialSelect',
          [sourceTypes.Equipment]: 'EquipmentSelect',
          default: '',
        }

        return sourceComponentMap[this.model.source_type]
      },
    },
    methods: {
      onChangeSource(source: API.Data<any>) {
        const {code , number} = source?.attributes

        this.model.source_code = code || number
      },
      onChangeSourceType() {
        this.model.source_id = this.model.source_code = null
      },
      resetAccountFilters() {
        this.model.include_period_0 = false
        this.model.subaccount_number_from = this.model.subaccount_number_to = null
      },
      transformFilters(params: any) {
        if (!params.start_date && !params.end_date) {
          params.date_to_parse = undefined
        }
        return params
      }
    }
  })
</script>
