<template>
   <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Reject')"
    :append-to-body="true"
  >
    <BaseForm
      :save-text="$t('Reject')"
      :loading="loading"
      layout="modal"
      @submit="reject"
    >
      <BaseTextarea
        v-model="rejectReason"
        :label="$t('Reject Reason')"
        rules="required"
        class="col-span-6"
      />
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
export default {
  props: {
    onReject: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      rejectReason: '',
    }
  },
  methods: {
    async reject() {
      this.loading = true;
      try {
        await this.onReject(this.rejectReason);
        this.$emit('close');
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
