<template>
  <RecurringServiceInvoiceForm
    v-if="recurringBilling?.id"
    :data="recurringBilling"
    list-path="/accounts-receivable/billings/service/pending"
  />
</template>
<script>
import RecurringServiceInvoiceForm from '@/modules/service-billing/components/RecurringServiceInvoiceForm.vue'

export default {
  components: {
    RecurringServiceInvoiceForm,
  },
  computed: {
    recurringBilling() {
      return this.$store.state.accountsReceivable.currentRecurringBilling
    },
  },
  methods: {
    getRecurringBilling() {
      this.$store.dispatch('accountsReceivable/getRecurringBilling', this.$route.params.id)
    },
  },
  watch: {
    recurringBilling: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value.id) {
          return
        }
        const { meta } = value || {}
        if (meta.hasOwnProperty('authorizedToUpdate') && !meta.authorizedToUpdate) {
          this.$router.push(`/accounts-receivable/recurring-billings/${value.id}/view`)
        }
      },
    },
  },
}
</script>
