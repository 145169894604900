<template>
  <AgDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :compact="true"
      :no-borders="true"
      :enableRangeSelection="true"
      :detailRowAutoHeight="true"
      :groupDefaultExpanded="1"
      :groupRowRendererParams="groupRowRendererParams"
      :transform-data="transformData"
      ref="table"
      actions="search,refresh"
      domLayout="autoHeight"
      groupDisplayType="groupRows"
      suppressColumnReordering
      @grid-ready="onGridReady"
  >
    <template #header-info>
      <SummaryPill
        :label="$t('Selected Retention To Release')"
        :value="totalRetentionToRelease"
      />
      <SummaryPill
          :label="$t('Total Retention To Release (current page)')"
          :value="totalRetentionOnCurrentPage"
      />
    </template>
    <template #additional-actions>
      <base-button variant="danger-link"
                   :disabled="validRetentions.length === 0"
                   @click="resetSelections"
                   class="mr-2">
        {{ $t('Reset Selections') }}
      </base-button>
      <ProofListingButton
          :disabled="validRetentions.length === 0"
          :selected-rows="validRetentions"
          :query-params="proofListingQueryParams"
          :path="proofListingPath"
      />
    </template>
  </AgDataTable>
</template>
<script lang="ts">
  import Data = API.Data
  import get from 'lodash/get'
  import sumBy from 'lodash/sumBy'
  import { defineComponent } from 'vue'
  import { useStorage } from '@vueuse/core'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
  import { GridReadyEvent, ValueFormatterParams, ValueSetterParams } from '@ag-grid-community/core'
  import ReleaseRetentionRowGroup from '@/modules/common/components/release-retention/ReleaseRetentionRowGroup.vue'
  import {modules} from "@/modules/common/components/settings/util";
  import {naturalSortEntries} from "@/modules/accounts-receivable/utils/billingUtils";

  const ReleaseMethods = {
    None: null,
    Partial: 'partial',
    Full: 'full',
  }

  const defaultSelection = {
    release_method: null,
    release_retention: 0,
    overrides: {},
  }

  export default defineComponent({
    components: {
      ReleaseRetentionRowGroup,
    },
    props: {
      filters: {
        type: Object,
        default: () => ({}),
      },
      module: {
        type: String,
        default: modules.AccountsPayable,
      }
    },
    setup(props) {
      const storageKey = props.module === modules.AccountsPayable ? `selected-ap-retentions` : `selected-ar-retentions`
      const selectedRetentions = useStorage(storageKey, {})
      return {
        selectedRetentions
      }
    },
    data() {
      return {
        grid: null as GridReadyEvent | null,
        loading: false as boolean,
        detailCellRenderer: null as string | null,
        entries: [] as Data<any>[],
        releaseMethods: [
          {
            label: this.$t('?'),
            value: ReleaseMethods.None,
          },
          {
            label: this.$t('Partial Withheld Amount'),
            value: ReleaseMethods.Partial,
          },
          {
            label: this.$t('Full Withheld Amount'),
            value: ReleaseMethods.Full,
          },
        ],
      }
    },
    computed: {
      isAR() {
        return this.module === modules.AccountsReceivable
      },
      proofListingPath(): string {
        if (this.isAR) {
          return '/accounts-receivable/release-retention/proof-listing'
        }
        return '/accounts-payable/release-retention/proof-listing'
      },
      url(): string {
        if (this.isAR) {
          return '/restify/customer-retentions/release'
        }
        return `/restify/vendor-retentions/release`
      },
      urlParams() {
        return this.filters || {}
      },
      columns() {
        return [
          {
            headerName: ' ',
            field: 'empty_column',
            minWidth: 30,
            maxWidth: 30,
          },
          {
            headerName: this.$t('Job'),
            field: 'job_id',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: ' ',
            field: 'release_method',
            minWidth: 200,
            maxWidth: 250,
            editable: true,
            cellEditor: cellEditors.BaseSelect,
            cellEditorParams: {
              options: this.releaseMethods,
              onValueChanged: this.updateReleaseMethod,
            },
            valueFormatter: (params: ValueFormatterParams) => {
              const options = get(params, 'colDef.cellEditorParams.options', [])
              const op = options.find((option: {
                value: any;
              }) => option.value === params.value)
              return op?.label || ''
            },
          },
          {
            headerName: this.$t('Phase Code'),
            field: 'line_item.phase_code',
            minWidth: 80,
            maxWidth: 80,
            align: 'center',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Cost Code'),
            field: 'line_item.cost_code',
            minWidth: 80,
            maxWidth: 80,
            align: 'center',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Change Order'),
            field: 'line_item.change_order',
            minWidth: 80,
            maxWidth: 150,
            align: 'center',
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Vendor'),
            field: 'vendor.id',
            maxWidth: 160,
            cellClass: cellClasses.ReadOnlyLight,
            component: 'VendorLink',
            cellRendererParams: {
              target: '_blank',
              showName: false,
            },
            initialHide: this.isAR,
          },
          {
            headerName: this.$t('Customer'),
            field: 'customer.id',
            maxWidth: 160,
            cellClass: cellClasses.ReadOnlyLight,
            component: 'CustomerLink',
            cellRendererParams: {
              target: '_blank',
              showName: false,
            },
            initialHide: !this.isAR,
          },
          {
            headerName: this.$t('Amount To-Date'),
            field: 'job_budget.amount_to_date',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 160,
            maxWidth: 300,
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            headerName: this.$t('Retention Withheld'),
            field: 'retention_amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 160,
            maxWidth: 300,
            cellClass: cellClasses.ReadOnlyLight,
          },
          {
            label: this.$t('Release Retention'),
            prop: 'release_retention',
            component: 'FormattedPrice',
            editable: params => params.data.release_method === ReleaseMethods.Partial,
            cellClass: params => {
              let classes = 'flex w-full justify-end'
              if (params.data.release_method !== ReleaseMethods.Partial) {
                classes = classes + ' ' + cellClasses.ReadOnlyLight
              }
              if (this.invalidReleaseRetentionAmount(params)) {
                classes = classes + ' ' + cellClasses.InvalidLight
                this.$warning(this.$t('Release Retention Amount cannot be greater than Retention Withheld Amount.'))
              }

              return classes
            },
            valueSetter: (params: ValueSetterParams) => {
              params.data.release_retention = +params.newValue
              if (this.invalidReleaseRetentionAmount(params)) {
                return false
              }

              this.setSelectionData(params.data)
              return true
            },
            align: 'right',
            minWidth: 160,
            maxWidth: 300,
          },
        ] as Column[]
      },
      validRetentions(): any[] {
        if (!this.selectedRetentions) {
          return []
        }
        return Object.values(this.selectedRetentions)
      },
      proofListingQueryParams() {
        // @ts-ignore
        const validRetentions = this.validRetentions.filter(r => r.release_retention > 0)
        const retentionLineItems = validRetentions.map(r => {
          return {
            id: r.line_item_id,
            source_id: r.source_id,
            job_type_id: r.job_type_id,
            job_id: r.job_id,
            retention_amount: r.release_retention,
          }
        })
        return {
          line_items: JSON.stringify(retentionLineItems),
        }
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'ReleaseRetentionRowGroup',
          suppressCount: true,
          wrapText: true,
          autoHeight: true,
          allowOverrides: this.isAR,
          updateGroupRetentionsMethod: (retentions: Data<any>[], method: ReleaseMethods | null) => this.updateGroupRetentionsMethod(retentions, method),
          setOverrides: (retentions: Data<any>[], overrides: any) => this.updateGroupRetentionsOverrides(retentions, overrides),
          module: this.module,
          releaseFullMethod: ReleaseMethods.Full,
        }
      },
      totalRetentionToRelease() {
        if (!this.validRetentions?.length) {
          return 0
        }
        return sumBy(this.validRetentions, 'release_retention')
      },
      totalRetentionOnCurrentPage() {
        return sumBy(this.entries, 'retention_amount')
      },
    },
    methods: {
      invalidReleaseRetentionAmount(params: any) {
        return params.data.release_retention > params.data.retention_amount && params.data.retention_amount > 0
      },
      transformData(retentions: Data<any>[]) {
        const sortedRetentions = naturalSortEntries(retentions, 'line_item')
        this.entries = sortedRetentions
        return sortedRetentions.map((retention: any) => {
          const id = this.getSelectionId(retention)
          // @ts-ignore
          const selectionFromStorage = this.selectedRetentions[id] || {}
          const defaultSelections = {
            ...defaultSelection,
            _localId: crypto.randomUUID(),
            release_retention: selectionFromStorage.release_retention || defaultSelection.release_retention,
            release_method: selectionFromStorage.release_method || defaultSelection.release_method,
            overrides: selectionFromStorage.overrides || {},
          }
          return {
            ...retention,
            ...defaultSelections,
          }
        })
      },
      onGridReady(params: GridReadyEvent) {
        this.grid = params
      },
      async resetSelections() {
        const result = await this.$deleteConfirm({
          title: this.$t('Do you really want to cancel the selections ?'),
          description: this.$t('This action will reset all release methods to the default state. You will have to process release methods again.'),
          buttonText: this.$t('Reset Selections'),
        })

        if (!result) {
          return
        }

        this.clearSelections()
      },
      clearSelections() {
        this.selectedRetentions = {}
        this.resetTable()
      },
      async resetTable() {
        // @ts-ignore
        await this.$refs.table.refresh()
        // @ts-ignore
        this.grid.api.redrawRows()
      },
      getSelectionId(row: any) {
        return row.line_item_id + row.retention_amount
      },
      getRowSelection(row: any) {
        const id = this.getSelectionId(row)
        // @ts-ignore
        let selection = this.selectedRetentions[id] || {...defaultSelection}
        selection.id = id
        selection.job_id = row.job_id
        selection.line_item_id = row.line_item_id
        selection.source_id = this.isAR ? row.customer_id : row.vendor_id
        selection.job_type_id = row.job_type_id
        selection.job_id = row.job_id
        selection.overrides = row.overrides || {}
        return selection
      },
      saveSelectionToStorage(selection: any) {
        this.$set(this.selectedRetentions, selection.id, selection)
      },
      updateGroupRetentionsMethod(retentions: Data<any>[], method: string) {
        if (method === ReleaseMethods.Full) {
          retentions.forEach(retention => {
            // @ts-ignore
            this.$set(retention, 'release_retention', retention.retention_amount)
            this.$set(retention, 'release_method', method)
            this.setSelectionData(retention)
          })
        } else {
          retentions.forEach(retention => {
            // @ts-ignore
            this.$set(retention, 'release_retention', 0)
            this.$set(retention, 'release_method', method)
            this.setSelectionData(retention)
          })
        }
      },
      updateGroupRetentionsOverrides(retentions: Data<any>[], overrides: any) {
        retentions.forEach(retention => {
          // @ts-ignore
          this.$set(retention, 'overrides', overrides)
          this.setSelectionData(retention)
        })
      },
      updateReleaseMethod(params: any, method: ReleaseMethods) {
        params.data.loading = true
        let retention = params.data
        retention.release_method = method
        const releaseRetention = method === ReleaseMethods.Full ? retention.retention_amount : 0
        this.$set(retention, 'release_retention', releaseRetention)
        params.node.setData(retention)
        this.setSelectionData(retention)
        params.data.loading = false
      },
      setSelectionData(retention: any) {
        let selection = this.getRowSelection(retention)
        selection.release_method = retention.release_method
        selection.release_retention = retention.release_retention
        selection.overrides = retention.overrides
        this.saveSelectionToStorage(selection)
      },
    },
  })
</script>
