<template>
  <div>
    <InvoiceDetailsPage
      :loading="invoiceLoading"
      :data="originalInvoice"
      :custom-title="$t('Recurring Payable')"
      entity="recurring-invoices"
    >
      <template #title>
        <invoice-status-popover
          :invoice="originalInvoice"
          placement="left"
          class="print:hidden"
        />
        <ReviewStatus
          v-if="showApproveStatus"
          :row="originalInvoice"
          class="ml-2"
        />
      </template>
      <template #header-left>
        <div class="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <VendorContactInfo :title="$t('From')" :vendor="vendor"/>
          <CompanyContactInfo :title="$t('Recurring Payable For')" :company="$currentCompany"/>
        </div>
      </template>
      <template #content>
        <InvoiceDescription
          v-if="invoice.id"
          :invoice="invoice"
        />
        <div class="flex items-center text-sm mb-4 -mt-4 px-6">
          <span class="font-medium leading-normal text-gray-400 mr-2 w-[95px]">{{ $t('Schedule:') }}</span>
          <div class="flex items-center space-x-1">
            <RRuleText
              :value="invoice?.recurring_rule"
              class="font-semibold"
            />
          </div>
        </div>
        <InvoiceEntries
          v-if="!originalInvoice.meta?.is_initialization"
          :data="invoice"
          :read-only="true"
          :showCellsLegend="false"
          endpoint="/restify/recurring-invoice-entries"
          parentEntityField="recurring_invoice_id"
        />

        <div
          v-if="isActiveOrInactive"
          class="px-4 py-4">
          <h5 class="-mb-4 font-semibold print:text-print">{{ $t('Issued Invoices') }}</h5>
          <InvoiceTable
            :hideAllActions="true"
            :invoiceStatus="resourceStatuses.All"
            :urlParams="{
              recurring_invoice_id: invoice.id,
              vendor_id: invoice?.vendor_id,
            }"
          />
        </div>

      </template>
    </InvoiceDetailsPage>

    <base-back-link
      v-if="showBack"
      to="/accounts-payable/recurring-invoices/all"
    />
  </div>
</template>

<script>
import InvoiceDetails from '@/modules/accounts-payable/pages/invoices/InvoiceDetails'
import VendorContactInfo from "@/modules/accounts-payable/components/invoice/VendorContactInfo";
import CompanyContactInfo from "@/modules/accounts-payable/components/invoice/CompanyContactInfo";
import InvoiceDetailsPage from "@/components/invoice/InvoiceDetailsPage";
import InvoiceDescription from "@/modules/accounts-payable/components/invoice/InvoiceDescription.vue";
import ReviewStatus from "@/components/links/ReviewStatus.vue";
import { resourceStatuses } from "@/enum/enums";
import InvoiceEntries from "@/modules/accounts-payable/components/invoice/InvoiceEntries.vue";
import RRuleText from "@/modules/accounts-payable/components/recurring-invoice/RRuleText.vue";
import RecurringScheduleTable from "@/modules/accounts-payable/components/recurring-invoice/RecurringScheduleTable.vue";
import InvoiceTable from "@/modules/accounts-payable/pages/invoices/InvoiceTable.vue";

export default {
  props: {
    id: [String, Number],
    showBack: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    InvoiceTable,
    RecurringScheduleTable,
    RRuleText,
    InvoiceEntries,
    ReviewStatus,
    InvoiceDescription,
    InvoiceDetailsPage,
    CompanyContactInfo,
    InvoiceDetails,
    VendorContactInfo,
  },
  data() {
    return {
      resourceStatuses,
      showScheduleTable: false,
    }
  },
  computed: {
    originalInvoice() {
      return this.$store.state.accountsPayable.currentRecurringInvoice || {
        attributes: {},
        relationships: {},
        meta: {},
      }
    },
    invoiceLoading() {
      return this.$store.state.accountsPayable.recurringInvoiceLoading
    },
    isActiveOrInactive() {
      const status = this.originalInvoice?.attributes?.status
      return [resourceStatuses.Active, resourceStatuses.Inactive].includes(status)
    },
    showApproveStatus() {
      const status = this.originalInvoice?.attributes?.status
      return [resourceStatuses.Pending].includes(status)
    },
    invoice() {
      return {
        ...this.originalInvoice?.attributes || {},
        ...this.originalInvoice?.relationships || {},
      }
    },
    vendor() {
      const vendorData = this.originalInvoice?.relationships?.vendor || { attributes: {}, relationships: {} }
      return {
        ...vendorData?.attributes,
        ...vendorData?.relationships,
      }
    },
  },
}
</script>
