<template>
  <DailyReportsTable/>
</template>
<script>

import DailyReportsTable from "@/modules/payroll/components/daily-reports/DailyReportsTable.vue";

export default {
  components: {
    DailyReportsTable,
  }
}
</script>
