import { AccrualMethodLabels, AccrualMethods } from "@/modules/payroll/utils/accrualMethods";
import pluralize from "pluralize";
import { codeTypes } from "@/modules/payroll/components/rates/util";
import i18n from "@/i18n";

export function getRuleText(rule, accrualMethod) {
  const { rate_unit, rate, milestone } = rule
  const isGivenMethod = [AccrualMethods.PerAnniversaryYear, AccrualMethods.PerCalendarYear].includes(accrualMethod)
  let accrualMethodLabel = AccrualMethodLabels[accrualMethod] || ''
  accrualMethodLabel = accrualMethodLabel.replace('Given Per ', '')
  accrualMethodLabel = accrualMethodLabel.replace('Accrued Per ', '')
  let hoursLabel = pluralize('hour', +rate)
  if (isGivenMethod) {
    return i18n.tc('Time off rule given label', milestone, { rate, hoursLabel, accrualMethodLabel }).toLowerCase()
  }
  return i18n.tc('Time off rule accrued label', milestone, { rate, hoursLabel, accrualMethodLabel, rate_unit }).toLowerCase()
}

export function getSpecialSourceDurationValidation({ duration, type, timeOffData }) {
  const isTimeOff = type === codeTypes.TIME_OFF
  const isPayCode = type === codeTypes.PAY

  if (isPayCode) {
    return `min_value:0`
  }
  if (!isTimeOff) {
    const maxUnits = duration / 60
    return `max_value:${maxUnits}`
  }
  const balance = timeOffData?.status?.balance || 0
  const allow_time_borrowing = timeOffData?.status?.allow_time_borrowing
  const isUnlimited = timeOffData?.status?.accrual_method === AccrualMethods.Unlimited

  if (allow_time_borrowing || isUnlimited) {
    return `min_value:0`
  }
  return `min_value:0|max_value:${balance}`
}
