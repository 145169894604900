<template>
  <div class="h-full">
    <AgDataTable
        :columns="columns"
        :url="url"
        :url-params="urlParams"
        :actions="actions"
        :default-match-filters="defaultMatchFilters"
        :edit-entity-url-query="editEntityUrlQuery"
        :isRowSelectable="isRowSelectable"
        :per-page="$pagination.list"
        :add-text="$t('New account')"
        :disable-col-flex="true"
        import-url="/ledger/accounts/import"
        entity="accounts"
        default-sort="number"
        permission="accounts"
        ref="table"
        :deleteCustom="true"
        @delete="itemToDelete = $event; showDeleteDialog = true"
        @add="onRowAdd"
        @edit="onRowEdit"
    >
      <template #dropdown-actions v-if="$route.query.debug">
        <ValidateAccountsAction/>
        <ValidateJournalsAction/>
      </template>
      <template v-slot:attributes.restrictions="{row}">
        <div class="truncate">
          {{ $t(restrictionDictionary[row.attributes.restrictions]) }}
        </div>
      </template>
      <template #attributes.active="{row}">
        <StatusBadge :status="row.attributes.active ? resourceStatuses.Active: resourceStatuses.Inactive"/>
      </template>
    </AgDataTable>
    <BaseFormDialog v-if="showDialog"
                    :title="rowToEdit ? $t('Edit General Ledger Account') : $t('Add General Ledger Account')"
                    :open.sync="showDialog"
                    @close="onEventTrigger(false)">
      <AccountForm :data="rowToEdit"
                   :key="renderKey"
                   @cancel="onEventTrigger(false)"
                   @save="onEventTrigger"
                   @create-another="renderKey++"
      />
    </BaseFormDialog>
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.Accounts"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script>
  import AccountForm from '@/modules/ledger/components/AccountForm'
  import { accountStatusOptions, restrictionDictionary } from '@/modules/ledger/enum/account'
  import { getDefaultMatchFilter } from "@/components/ag-grid/filterUtils";
  import ValidateAccountsAction from "@/modules/ledger/components/accounts/ValidateAccountsAction.vue";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { cellClasses } from "@/components/ag-grid/columnUtils";
  import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
  import { accountTypes } from "@/enum/account";
  import { resourceStatuses } from "@/enum/enums";
  import ValidateJournalsAction from "@/modules/ledger/components/accounts/ValidateJournalsAction.vue";

  export default {
    components: {
      ValidateJournalsAction,
      ValidateAccountsAction,
      AccountForm,
    },
    data() {
      return {
        renderKey: 1,
        restrictionDictionary,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('# Number'),
            prop: 'attributes.number',
            minWidth: 90,
            maxWidth: 120,
            component: 'EntityLink',
            redirectTo: '/ledger/accounts/{ID}/view',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
            minWidth: 150,
            maxWidth: 350,
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.type',
            minWidth: 100,
            maxWidth: 160,
            component: 'Status',
            editable: true,
            cellEditor: cellEditors.Status,
            cellEditorParams: {
              options: accountTypes,
            },
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params) => {
              return valueSetterWithUpdate({
                storeAction: 'generalLedger/patchAccount',
              })(params)
            },
          },
          {
            label: this.$t('Active'),
            prop: 'attributes.active',
            minWidth: 100,
            maxWidth: 120,
            component: 'Status',
            editable: true,
            cellEditor: cellEditors.Status,
            cellEditorParams: {
              options: accountStatusOptions,
            },
            headerClass: cellClasses.HeaderEditable,
            valueSetter: (params) => {
              return valueSetterWithUpdate({
                storeAction: 'generalLedger/patchAccount',
              })(params)
            },
          },
          {
            label: this.$t('Restrictions'),
            prop: 'attributes.restrictions',
            minWidth: 150,
          },
        ],
        resourceStatuses,
        showDeleteDialog: false,
        itemToDelete: null,
      }
    },
    computed: {
      url() {
        return `/restify/accounts`
      },
      urlParams() {
        return {
          fiscal_year: this.activeFiscalYear
        }
      },
      actions() {
        let actions = `search,refresh,view,export,print,bulk-delete`
        if (this.$can('accounts_update')) {
          actions += ',import'
        }
        return actions
      },
      defaultMatchFilters() {
        return getDefaultMatchFilter('show_inactive_accounts', {
          active: true,
        })
      },
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      onItemDeleted() {
        this.showDeleteDialog = false
        this.refreshTable()
        this.refreshGlobalList()
      },
      isRowSelectable(params) {
        return !params.data.attributes?.is_used
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      refreshGlobalList() {
        this.$store.dispatch('company/getCompanyAccounts')
      },
      onEventTrigger(refresh = true) {
        this.rowToEdit = null
        this.showDialog = false
        if (!refresh) {
          return
        }
        this.refreshTable()
        this.refreshGlobalList()
      },
    },
  }
</script>
