<template>
  <div class="report-page">
    <layout-tabs :items="filteredItems"/>
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Daily Job Cost Detail'),
            path: '/job-costing/reports/daily-job-cost-detail',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Billing / Cost Comparison'),
            path: '/job-costing/reports/billing-cost-comparison',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Period Cost / Income Summary'),
            path: '/job-costing/reports/period-cost-income-summary',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Job Cost Detail'),
            path: '/job-costing/reports/job-cost-detail',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Labor Status Report'),
            path: '/job-costing/reports/labor-status-report',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Job Profit Summary'),
            path: '/job-costing/reports/profit-summary',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Job Cost Variance'),
            path: '/job-costing/reports/job-cost-variance',
            permission: 'job_costing_reports',
          },
          {
            name: this.$t('Job Cost - Overview'),
            path: '/job-costing/reports/job-cost-overview?debug=true',
            permission: 'job_costing_reports',
            showIf: () => this.isDebug
          },
        ],
      }
    },
    computed: {
      isDebug() {
        return this.$route.query.debug === 'true'
      },
      filteredItems() {
        return this.items.filter(item => {
          if (item.showIf && !item.showIf()) {
            return false

          }
          return this.$can(item.permission)
        })
      },
    },
  }
</script>
