<template>
  <div class="h-screen flex overflow-hidden bg-gray-50 print:bg-transparent dashboard-layout">
    <Sidebar ref="sidebar"
             :theme="theme"
             class="print:hidden"
    />
    <div class="flex flex-col w-0 flex-1 overflow-hidden dashboard-layout-container">
      <div class="md:hidden print:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 flex items-center justify-between bg-white pb-1 shadow">
        <button
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
          @click="toggleSidebar(true)"
          aria-label="Open sidebar">
          <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </button>
      </div>
      <main class="flex-1 flex flex-col relative z-0 overflow-y-auto focus:outline-none"
            :class="{'report-page': $route.path.includes('/reports')}"
            ref="dashboardLayout"
            tabindex="0">
        <DashboardPageHeader
          @note-params-changed="fetchNotes"
          @toggle-note-panel="onToggleNotesPanel"/>
        <div class="px-2 md:px-4 lg:px-8 mx-auto lg:print:px-0 print:px-0 flex-1 w-full">
          <router-view
            v-if="canRenderPage"
            :key="user.current_company_id"/>
          <PageLoading v-else key="content-not-ready"/>
        </div>
      </main>
      <base-copyright class="hidden" />
      <NotesPanel ref="noteWrapper" class="print:hidden"/>
      <EmployeeConsentDialog
        v-if="employeeConsented"
        :open="employeeConsented"
      />
    </div>
    <UpdateAvailableNotification/>
    <transition name="fade">
      <ScrollToTopButton v-show="showScrollToTopButton"/>
    </transition>
  </div>
</template>
<script>
import { ref } from 'vue'
import { useScroll } from '@vueuse/core'
import { isProduction } from '@/isProduction'
import { setOrganizationTranslations } from '@/utils/utils'
import Sidebar from '@/modules/dashboard/components/Sidebar'
import NotesPanel from '@/modules/dashboard/components/NotesPanel'
import DashboardPageHeader from '@/modules/dashboard/components/DashboardPageHeader'
import EmployeeConsentDialog from '@/modules/employee-portal/components/EmployeeConsentDialog'
import PageLoading from "@/modules/dashboard/components/PageLoading";
import ScrollToTopButton from '@/modules/common/components/ScrollToTopButton'
import LiveChat from "@/modules/support/components/LiveChat.vue";
import UpdateAvailableNotification from '../components/UpdateAvailableNotification.vue'

export default {
  components: {
    LiveChat,
    Sidebar,
    NotesPanel,
    PageLoading,
    ScrollToTopButton,
    DashboardPageHeader,
    EmployeeConsentDialog,
    UpdateAvailableNotification
  },
  setup() {
    const dashboardLayout = ref(null)
    const { y: scrollOffset } = useScroll(dashboardLayout)
    return { dashboardLayout, scrollOffset }
  },
  data() {
    return {
      canRenderPage: this.$store.state.auth?.organization?.active,
    }
  },
  provide() {
    return {
      triggerMenuClick: (route) => this.$refs.sidebar.triggerMenuClick(route)
    }
  },
  computed: {
    showScrollToTopButton() {
      return window.innerHeight < (this.scrollOffset + 700)
    },
    user() {
      return this.$store.getters['auth/user'] || { current_company_id: 0 }
    },
    theme() {
      return this.$store.state.theme.theme
    },
    employee() {
      return this.$user?.employee
    },
    hasEmployee() {
      return this.employee !== null
    },
    employeeConsented() {
      const isEmployeeConsentRequired = this.$store.getters['company/isEmployeeConsentRequired']
      if (isEmployeeConsentRequired === false) {
        return
      }
      return this.hasEmployee && this.employee?.payroll_consent_confirmed_at === null && this.employee?.payroll_consent_token !== null
    },
    notesPanelData() {
      return this.$store.state.notes.notesPanelData
    },
    notesPanelKey() {
      return this.$store.state.notes.notesPanelKey
    },
  },
  methods: {
    onToggleNotesPanel(data) {
      this.$refs.noteWrapper.togglePanel(data)
    },
    fetchNotes(data) {
      const noteRef = this.$refs.noteWrapper
      if (!noteRef) {
        setTimeout(() => {
          this.fetchNotes(data)
        }, 200)
      } else {
        noteRef?.getNotes(data)
      }
    },
    toggleSidebar(value) {
      this.$refs.sidebar.toggleSidebar(value)
    },
    async loadGlobalEntities() {
      if (isProduction()) {
        return
      }
      await this.$store.dispatch('globalLists/getGeneralConfiguration')
      this.canRenderPage = true
      await Promise.all([
        setOrganizationTranslations(),
      ])
    },
    addFlareContext() {
      flare.addContextGroup("user", {
        name: this.$user.name,
        email: this.$user.email,
        roles: this.$user.company_roles.map(r => r.name).join(', '),
        organization: this.$user.current_organization,
      });
      flare.addContextGroup("company", {
        name: this.$currentCompany.name,
        abbr: this.$currentCompany.abbr,
        number: this.$currentCompany.number,
      })
    }
  },
  async mounted() {
    await this.loadGlobalEntities()
    this.$store.dispatch('auth/refreshProfile')
    this.addFlareContext()
    // initOpenRelayTracking()
    // trackUser()
  },
  watch: {
    notesPanelKey() {
      this.onToggleNotesPanel(this.notesPanelData)
    }
  }
}
</script>
