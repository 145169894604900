<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :data-loading="dataLoading"
    :has-summary="false"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{ row }">
      <div class="flex">
        <template v-if="row.header?.job">
          <div class="w-1/3 flex">
            {{ $t('Job:') }} &nbsp;
            <JobLink
              :data="row.header.job"
              :show-preview="false"
              :show-name="true"
            />
          </div>
          <template v-if="get(row, 'header.customer.id')">
            {{ $t('Customer:') }} &nbsp;
            <CustomerLink
              :data="row.header.customer"
              :show-preview="false"
              :show-name="true"
            />
          </template>
        </template>
        <template v-else-if="row.header?.line_item">
          <LineItemLink
            :data="row.header.line_item"
            :show-preview="false"
            :show-name="true"
          />
        </template>
      </div>
    </template>
    
    <template #business="{ row }">
      <EmployeeLink
        v-if="row.business?.type === 'employee'"
        :data="row.business"
        :show-preview="false"
      />
      <VendorLink
        v-else-if="row.business?.type === 'vendor'"
        :data="row.business"
        :show-preview="false"
      />
      <CustomerLink
        v-else-if="row.business?.type === 'customer'"
        :data="row.business"
        :show-preview="false"
      />
      <span v-else> - </span>
    </template>

    <template #subtotal="{subtotal}">
      <td colspan="3" />

      <td align="right" style="vertical-align: top;">
        {{ $t(subtotal.name) }}
      </td>
      <td />

      <td v-for="(costKey, index) of costKeys" align="right" style="vertical-align: top;">
        <div>{{ $formatPrice(subtotal.totals[costKey]) }}</div>
        <template v-if="index === costKeys.length - 1">
          <template v-if="subtotal.job_totals || subtotal.grand_totals">
            <div>{{ $t('Total Costs:') }}</div>
            <div class="my-2 border-t border-gray-700"></div>
            <div>{{ $t('Billed Less Cost:') }}</div>
          </template>
          <div>{{ $t('Total Labor Hours:') }}</div>
        </template>
      </td>
      <td align="right" style="vertical-align: top;">
        <div>{{ $formatPrice(subtotal.totals.billed) }}</div>
        <template v-if="subtotal.job_totals || subtotal.grand_totals">
          <div>{{ $formatPrice(subtotal.totals.costs)  }}</div>
          <div class="my-2 border-gray-700 border-t -ml-2"></div>
          <div>{{ $formatPrice(subtotal.totals.billed - subtotal.totals.costs)  }}</div>
        </template>
        <div>{{ $formatHours(subtotal.totals.quantity) }}</div>
      </td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import {
  preBillingViewFormats,
  PreBillingReportOptions,
  QuantityLabels,
} from '@/modules/accounts-receivable/components/reports/util'

export default {
  extends: ReportTableWrapper,
  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    jobCostTypes() {
      return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobCostTypes) || []
    },
    costKeys() {
      return this.jobCostTypes.map(costType => costType.id)
    },
    jobCostTypeColumns() {
      return this.jobCostTypes.map(costType => {
        return {
          label: costType.name,
          prop: costType.id,
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
        }
      })
    },
    columns() {
      return [
        {
          label: this.$t('Date'),
          prop: 'date',
          component: 'FormattedDate',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Vendor/<br>Empl/Cust'),
          prop: 'business',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Ref No.'),
          prop: 'reference_no',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Description'),
          prop: 'description',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Units <br> LAB/MTL/EQP'),
          prop: 'units',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
        },
        ...this.jobCostTypeColumns,
        {
          label: this.$t('Billed'),
          prop: 'billed',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
        },
      ]
    },
    format() {
      return this.filters.format
    },
    sort_by() {
      return this.filters.sort_by
    }
  },
  methods: {
    addJobGroup(jobGroup) {
      this.rows.push({
        header: {
          job: jobGroup.job,
          customer: jobGroup.customer,
        }
      })

      this.addJobDetailLines(jobGroup)

      this.addJobTotals(jobGroup)
    },
    addJobDetailLines(jobGroup) {
      // When sorted by phase we still need to add at least phase totals
      if (this.sort_by === PreBillingReportOptions.SortBy.PhaseCostCodeDate) {
        this.addItemsSortedByPhase(jobGroup)
        return
      }

      // Nothing else to add when sorted by date & format = summary
      if (this.format !== preBillingViewFormats.JCAndBillWorksheetDetails) {
        return
      }

      this.addItemsSortedByDate(jobGroup)
    },
    addItemsSortedByDate(jobGroup) {
      for (const dateGroup of jobGroup.items) {
        for (const item of dateGroup) {
          this.addDetailLine(item)
        }
      }
    },
    addItemsSortedByPhase(jobGroup) {
      jobGroup.items.forEach(phaseGroup => {

        const line_item = phaseGroup.items?.[0]?.line_item

        this.rows.push({
          header: {
            line_item,
          }
        })

        if (this.format === preBillingViewFormats.JCAndBillWorksheetDetails) {
          phaseGroup.items.forEach(this.addDetailLine)
        }

        this.rows.push({
          subtotal: {
            name: this.$t('Phase / Cost Code Totals') +  ` (${line_item.phase_code || ' - '})`,
            totals: {
              ...phaseGroup.totals,
            },
          }
        })
      })
    },

    addDetailLine(item) {
      const { type } = item
      const qtyUnit = QuantityLabels[item?.quantity_from] || item?.quantity_from || ''
      const units = item?.quantity
        ? `${type?.abbr || ''} ${this.$formatQuantity(item.quantity)} ${qtyUnit}`
        : ''

      const originalDate = item?.date

      const prevDate = this.rows[this.rows.length - 1]?.originalDate
      const lineDate = originalDate === prevDate ? '' : originalDate

      const lineResult = {
        ...item,
        date: lineDate,
        originalDate,
        units,
      }

      this.rows.push(lineResult)
    },
    addJobTotals(jobGroup) {
      this.rows.push({
        subtotal: {
          name: this.$t('Job Totals') +  ` (${jobGroup.job.number})`,
          job_totals: true,
          totals: {
            ...jobGroup.totals,
          },
        }
      })
    },
    addGrandTotals(data) {
      const grand_total_keys = [
        ...this.costKeys,
        'costs',
        'billed',
        'quantity'
      ]

      const grand_totals = grand_total_keys.reduce((acc, key) => {

        acc[key] = data.reduce((acc, jobGroup) => {
          acc += jobGroup.totals[key] || 0
          return acc
        }, 0)

        return acc
      }, {})

      this.rows.push({
        subtotal: {
          name: this.$t('Grand Total All Jobs'),
          grand_totals: true,
          totals: {
            ...grand_totals,
          },
        }
      })
    },
    composeRows(data) {
      data.forEach(jobGroup => {
        this.addJobGroup(jobGroup)
      })

      this.addGrandTotals(data)
      return this.rows
    },
  },
}
</script>
