<template>
  <div class="ag-custom-tooltip rounded-lg p-2 bg-white shadow-md space-y-2">
    <div
      v-for="(message, index) in messages"
      :key="index"
    >
      <p class="text-sm text-gray-800">{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    value() {
      return this.params?.value
    },
    messages() {
      if (Array.isArray(this.value)) {
        return this.value
      }

      return [this.value]
    }
  },
}
</script>
<style lang="scss">
.ag-root-wrapper:has(.ag-custom-tooltip) {
  overflow: visible;
}
</style>
