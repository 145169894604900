<template>
  <RecurringServiceInvoiceForm
    :key="renderKey"
    list-path="/service-billing/recurring-service-invoices/pending"
    @create-another="renderKey++"
  />
</template>
<script>
import RecurringServiceInvoiceForm from '@/modules/service-billing/components/RecurringServiceInvoiceForm.vue'
export default {
  components: {
    RecurringServiceInvoiceForm,
  },
  data() {
    return {
      renderKey: 1,
    }
  }
}
</script>
