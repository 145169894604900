<template>
  <EntityApproveDialog
    v-bind="$attrs"
    v-on="$listeners"
    :entity-name="$t('repair order')"
    :data="repairOrder"
    api-base-path="/restify/repair-orders"
    base-path="/equipment/repair-orders"
  >
    <RepairOrderDetails
      :show-back="false"
      class="col-span-6"
    />
  </EntityApproveDialog>
</template>
<script>
import EntityApproveDialog from "@/modules/common/components/review/EntityApproveDialog.vue";
import RepairOrderDetails from '@/modules/equipment/pages/repair-orders/repair-order-details.vue';

export default {
  components: {
    EntityApproveDialog,
    RepairOrderDetails,
  },
  props: {
    repairOrder: {
      type: Object,
    },
  },
  watch: {
    repairOrder: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.$store.commit('equipmentManagement/SET_CURRENT_REPAIR_ORDER', value)
      },
    }
  }
}
</script>
