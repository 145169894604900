<template>
  <base-form :layout="layout"
             :loading="loading"
             :show-cancel="showCancel"
             :can-create-another-entity="!model.id && redirectToListAfterStore"
             :show-back="showBack"
             :show-reset="!model.id"
             :save-text="$t('Create job')"
             :update-text="$t('Update job')"
             grid-classes="grid grid-cols-8 gap-x-3"
             @cancel="onCancel"
             @submit="onSubmit"
  >
    <div class="col-span-8 md:col-span-2 xl:col-span-2">
      <code-input
        v-model="model.number"
        :model="model"
        :label="$t('Number')"
        :placeholder="$t('Number')"
        :max-length="20"
        :resource-name="globalResources.Jobs"
        value-key="number"
      />
      <div class="text-sm text-gray-700 font-medium -mt-2 mb-2">{{ $t('Last no:') }} {{ lastJobNumber }}</div>
    </div>
    <div class="col-span-8 md:col-span-2">
      <customer-select v-model="model.customer_id"
                       :initial-value="customer"
                       :key="customerSelectRenderKey"
                       clearable
      />
    </div>
    <div class="col-span-8 md:col-span-2 xl:col-span-2">
      <job-category-select v-model="model.category_id"
                           :label="$t('Category')"
                           url="/restify/job-categories"
                           label-key="code"
                           value-key="id"
                           clearable
                           id="category"
      />
    </div>
    <div class="col-span-8 md:col-span-2 xl:col-span-1">
      <base-select v-model="model.status"
                   :label="$t('Status')"
                   id="status"
                   :options="statusTypes"
      />
    </div>
    <div v-if="preNoticeId"
         class="col-span-8 md:col-span-2 xl:col-span-1">
      <router-link
          :to="`/job-costing/settings/pre-notice/${preNoticeId}/edit`"
          target="_blank"
          class="btn-link"
      >
        <div class="flex items-center mt-8">
          <span class="mr-2">{{ $t('View Pre-Notice') }}</span>
          <ExternalLinkIcon class="w-4 h-4"/>
        </div>
      </router-link>
    </div>
    <div class="col-span-8 md:col-span-4">
      <div class="grid grid-cols-4 gap-x-4">
        <div class="col-span-8 md:col-span-6 xl:col-span-4">
          <base-textarea
              v-model="model.description"
              :label="$t('Description')"
              :placeholder="$t('Description')"
              :rows="2"
              id="description"
              rules="max:150"
          />
        </div>
        <div class="col-span-8 md:col-span-2">
          <base-input v-model="model.owner_name"
                      :label="$t('Owner Name')"
                      :placeholder="$t('Owner Name')"
                      id="owner_name"
                      rules="max:40"
          />
        </div>
        <employee-select-new
            v-model="model.sales_person_id"
            :label="$t('Salesperson')"
            :placeholder="$t('Select Salesperson')"
            :add-entity="true"
            :add-label="$t('Add new Salesperson')"
            class="col-span-8 md:col-span-4 xl:col-span-2"
            clearable
        />
      </div>
    </div>
    <div class="col-span-8 md:col-span-4">
      <JobNotes v-model="model.user_notes"/>
    </div>
    <div class="col-span-8">
      <el-collapse v-model="activeGroups">
        <base-collapse-section :title="$t('Addresses & Contacts')"
                               key="addresses_and_contacts"
                               name="addresses_and_contacts"
        >
          <div class="grid grid-cols-2 gap-x-4">
            <AddressList ref="addressList"
                         :entityId="model.id"
                         :data="model.addresses"
                         :resource="model"
                         class="col-span-2 md:col-span-2 xl:col-span-1"
                         @refresh="$emit('refresh')"
                         entity="jobs"
            />
            <ContactList title="Contacts"
                         :contacts="model.contacts"
                         ref="contactList"
                         entity="jobs"
                         :entity-id="model.id"
                         class="col-span-2 md:col-span-2 xl:col-span-1"
                         @refresh="$emit('refresh')"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section key="job_specifications"
                               :title="$t('Job Specifications')"
                               name="job_specifications">
          <div class="grid grid-cols-8 gap-x-4">
            <base-input v-model="model.subcontract_retention_percent"
                        :name="$t('Retention  – Subcontracts')"
                        :label="$t('Retention % – Subcontracts')"
                        id="subcontract_retention_percent"
                        :min="0"
                        :step="0.01"
                        type="number"
                        format="percent"
                        rules="max_value:100|min_value:0"
                        class="col-span-8 md:col-span-2"
            />
            <base-input v-model="model.billing_retention_percent"
                        :name="$t('Retention – Billing')"
                        :label="$t('Retention % – Billing')"
                        :min="0"
                        :step="0.01"
                        id="billing_retention_percent"
                        type="number"
                        format="percent"
                        rules="max_value:100|min_value:0"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-input v-model="model.units"
                        :name="$t('Job Units')"
                        :label="$t('Job Units')"
                        :min="0"
                        :step="0.01"
                        id="units"
                        type="number"
                        rules="max_value:9999999999.999999|min_value:0"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-input v-model="model.um"
                        :label="$t('U/M')"
                        :placeholder="$t('Enter the unit of measure for the Job Units value')"
                        id="um"
                        rules="max:4"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <div class="col-span-8"></div>
            <entity-select v-model="model.billing_rate_type_id"
                           :label="$t('Pre-Bill Worksheet')"
                           :placeholder="$t('Select Rate')"
                           :add-entity="false"
                           url="/restify/billing-rate-types"
                           label-key="title"
                           id="billing_rate_type_id"
                           class="col-span-8 md:col-span-2 xl:col-span-1"
            />

            <sales-tax-district-select
                v-model="model.district_id"
                :add-entity="true"
                :initial-value="get(data, 'relationships.district')"
                class="col-span-8 md:col-span-2"
                id="district_id"
                clearable
            />
            <div class="col-span-8 md:col-span-2">
              <base-switch
                v-model="model.exempt_from_sales_tax"
                :label-info="$t('Exempt From Sales Tax')"/>
            </div>
            <base-input v-model="model.project_number"
                        :label="$t('Arch/Engr Project #')"
                        :placeholder="$t('Arch/Engr Project')"
                        id="project_number"
                        rules="max:12"
                        class="col-span-8 md:col-span-2"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section key="payroll_settings"
                               :title="$t('Payroll Settings')"
                               name="payroll_settings">
          <TimecardOverrides
              v-model="model"
              scope="Job"
              :collapsible="false"
          />
          <div class="grid grid-cols-6 gap-x-4">
            <entity-select v-model="model.union_id"
                           :label="$t('Union Code')"
                           :placeholder="$t('Select Union')"
                           :add-entity="false"
                           url="restify/unions"
                           label-key="code"
                           id="union_id"
                           clearable
                           class="col-span-6 md:col-span-2 xl:col-span-1"
            />
            <entity-select v-model="model.union_rate_type_id"
                           :label="$t('Union Rate')"
                           :placeholder="$t('Select Rate')"
                           :add-entity="false"
                           clearable
                           url="/restify/billing-rate-types"
                           label-key="title"
                           id="union_rate_type_id"
                           class="col-span-6 md:col-span-2 xl:col-span-1"
            />


            <base-select
                v-model="model.certified_payroll"
                id="certified_payroll"
                inline-errors
                :options="certifiedPayrollTypes"
                :label="$t('Certified Payroll')"
                class="col-span-3 lg:col-span-1"
            />
            <div class="col-span-3 lg:col-span-1">
              <base-select v-model="model.compliance_format"
                           id="compliance_format"
                           :label="$t('Compliance Format')"
                           inline-errors
                           :options="complianceFormatTypes"
              />
            </div>
            <div class="col-span-3 lg:col-span-1">
              <base-select v-model="model.print_ss"
                           id="print_ss"
                           :label="$t('Print SS')"
                           inline-errors
                           :options="printSsTypes"
              />
            </div>
            <template v-if="model.certified_payroll !== 'no'">
              <div class="col-span-6 md:col-span-2 xl:col-span-1">
                <base-select v-model="model.print"
                             id="print"
                             :label="$t('Print')"
                             inline-errors
                             :options="printTypes"
                />
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-1">
                <base-input v-model="model.last_payroll_number"
                            id="last_payroll_number"
                            :name="$t('Last Payroll Number')"
                            :label="$t('Last Payroll Number')"
                            :min="0"
                            :step="1"
                            readonly
                            type="number"
                            rules="max_value:999999999|min_value:0"
                />
              </div>
              <div class="col-span-6 md:col-span-2 xl:col-span-1">
                <base-date-picker v-model="model.last_pay_period"
                                  :label="$t('Last Pay Period')"
                                  id="last_pay_period"
                />
              </div>

            </template>

            <div class="col-span-6"></div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-switch
                v-model="model.owner_insured_workers_comp"
                :label-info="$t(`Owner Insured W/Comp`)"
              />
            </div>
            <div
              v-if="model.owner_insured_workers_comp"
              class="col-span-6 md:col-span-2 xl:col-span-1">
              <ocip-select
                v-model="model.workers_comp_ocip_id"
                :label="$t(`Worker's Comp OCIP`)"
                :placeholder="$t(`Worker's Comp OCIP`)"
                clearable
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <worker-comp-rate-select v-model="model.workers_comp_rate_code"
                                       clearable
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-switch
                v-model="model.owner_insured_gen_liability"
                :label-info="$t(`Owner Insured Gen/Liab`)"
              />
            </div>
            <div
              v-if="model.owner_insured_gen_liability"
              class="col-span-6 md:col-span-2 xl:col-span-1">
              <ocip-select v-model="model.gen_liability_ocip_id"
                           :label="$t('General Liability OCIP')"
                           :placeholder="$t('General Liability OCIP')"
                           clearable
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <gen-liability-rate-select v-model="model.gen_liability_rate_code"
                                         clearable
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <BaseSwitch
                v-model="model.available_in_timesheets"
                :label-info="$t(`Show in Timesheets`)"
              />
            </div>
            <div class="col-span-6"></div>
            <EntityStartingPayPeriod
              v-if="model.id"
              :id="model.id"
              :value="model.starting_pay_period"
              entity="jobs"
              payload-key="jobs"
              @change="model.starting_pay_period = $event"
            />
            <h5 class="form-section-title col-span-6 mb-4">
              {{ $t('Certified Payroll Settings') }}
            </h5>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-input v-model="model.contract_agency"
                          :tip="$t('Contract Agency is required for CA State jobs when using Certified Payroll')"
                          :label="$t(`Contract Agency`)"
                          :placeholder="$t(`Contract Agency`)"
                          rules="max:50"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-input v-model="model.DLSE_project_id"
                          :tip="$t('DLSE Project ID is required for CA State jobs when using Certified Payroll')"
                          :label="$t(`DLSE Project ID`)"
                          :placeholder="$t(`DLSE Project ID`)"
                          type="number"
                          :min="0"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-input v-model="model.wa_intent_id"
                          :tip="$t('Intent ID is required for WA State jobs')"
                          :label="$t(`Intent ID`)"
                          :placeholder="$t(`Intent ID`)"
                          rules="max:10"
                          clearable
              />
            </div>
          </div>
        </base-collapse-section>

        <BaseCollapseSection
          key="report_settings"
          :title="$t('Report Settings')"
          name="report_settings"
        >
          <div class="col-span-6 md:col-span-2 xl:col-span-1">
            <BaseSwitch
              v-model="model.show_in_reports"
              :label-info="$t(`Show in Reports`)"
            />
          </div>
        </BaseCollapseSection>
        <!--They don't serve a purpose now. We can enable them later on-->
        <base-collapse-section
            v-show="false"
            key="job-dates"
            :title="$t('Job Dates')"
            name="job_dates">
          <div class="grid grid-cols-6 gap-x-4">
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.contract_start_date"
                                :label="$t('Contract start')"
                                id="contract_start_date"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.schedule_start_date"
                                :label="$t('Schedule start')"
                                id="schedule_start_date"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.actual_start_date"
                                :label="$t('Actual start')"
                                id="actual_start_date"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.original_completion_date"
                                :label="$t('Original completion')"
                                id="original_completion_date"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.current_completion_date"
                                :label="$t('Current completion')"
                                id="current_completion_date"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.schedule_completion_date"
                                :label="$t('Schedule completion')"
                                id="schedule_completion_date"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.actual_completion_date"
                                :label="$t('Actual completion')"
                                id="actual_completion_date"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.BOD_date"
                                :label="$t('B.O.D')"
                                id="BOD"
              />
            </div>
            <div class="col-span-6 md:col-span-2 xl:col-span-1">
              <base-date-picker v-model="model.NOC_date"
                                :label="$t('N.O.C Filed')"
                                id="NOC_filed"
              />
            </div>
          </div>
        </base-collapse-section>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import OcipSelect from '@/components/select/entities/OcipSelect'
  import ContactList from '@/modules/common/components/contacts/ContactList.vue'
  import AddressList from '@/modules/common/components/AddressList'
  import { JobStatus, jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import JobNotes from '@/modules/job-costing/components/job/JobNotes'
  import TimecardOverrides from '@/components/common/TimecardOverrides'
  import { ExternalLinkIcon } from 'vue-feather-icons'
  import { globalResources, RestifyResources } from "@/components/form/util";
  import EntityStartingPayPeriod from "@/modules/job-costing/components/EntityStartingPayPeriod.vue";

  export default {
    components: {
      EntityStartingPayPeriod,
      JobNotes,
      AddressList,
      ContactList,
      OcipSelect,
      TimecardOverrides,
      ExternalLinkIcon,
    },
    props: {
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        globalResources,
        activeGroups: [],
        loading: false,
        showCancel: true,
        customer: {},
        model: {
          number: '',
          description: '',
          owner_name: '',
          sales_person_id: undefined,
          customer_id: this.$route.query.customer_id || undefined,
          district_id: undefined,
          status: JobStatus.Open,
          user_notes: ['', '', '', ''],
          category_id: undefined,
          project_number: '',
          subcontract_retention_percent: 0,
          billing_retention_percent: 0,
          units: 0,
          um: '',
          union_id: undefined,
          union_rate_type_id: undefined,
          billing_rate_type_id: undefined,
          certified_payroll: 'no',
          print: 'minority',
          last_payroll_number: 0,
          last_pay_period: '',
          compliance_format: 'general',
          print_ss: 'yes',
          owner_insured_workers_comp: false,
          workers_comp_ocip_id: undefined,
          wa_intent_id: undefined,
          workers_comp_rate_code: '',
          owner_insured_gen_liability: false,
          gen_liability_ocip_id: undefined,
          gen_liability_rate_code: '',
          contract_start_date: '',
          schedule_start_date: '',
          actual_start_date: '',
          original_completion_date: '',
          current_completion_date: '',
          schedule_completion_date: '',
          actual_completion_date: '',
          BOD_date: '',
          NOC_date: '',
          timecard_overrides: false,
          gen_liability_state_id: null,
          workers_comp_state_id: null,
          withholding_state_id: null,
          withholding_local_id: null,
          withholding_local_2_id: null,
          sui_sdi_state_id: null,
          contract_agency: null,
          DLSE_project_id: null,
          exempt_from_sales_tax: false,
          show_in_reports: true,
          starting_pay_period: null,
          available_in_timesheets: true,
        },
        customerSelectRenderKey: 1,
        statusTypes: jobStatusOptions,
        printTypes: [
          {
            label: this.$t('Minority'),
            value: 'minority',
          },
          {
            label: this.$t('Race'),
            value: 'race',
          },
          {
            label: this.$t('None'),
            value: 'none',
          },
        ],
        complianceFormatTypes: [
          {
            label: this.$t('General'),
            value: 'general',
          },
          {
            label: this.$t('U.S DOL'),
            value: 'us-dol',
          },
          {
            label: this.$t('Caltrans'),
            value: 'caltrans',
          },
          {
            label: this.$t('Template'),
            value: 'template',
          },
        ],
        certifiedPayrollTypes: [
          {
            label: this.$t('No'),
            value: 'no',
            description: '',
          },
          {
            label: this.$t('Y-w/Non-Perf'),
            value: 'y-w/-non-perf',
            description: this.$t('if you will normally be printing certified payroll reports for this job, and Non-Performance Statements are used for periods with no work performed.'),
          },
          {
            label: this.$t('Y-no Non-Perf'),
            value: 'y-w/o-non-perf',
            description: this.$t('if you will normally be printing certified payroll reports for this job, and need blank Certified Payroll and Statement of Compliance reports for periods with no work performed.'),
          },
        ],
        printSsTypes: [
          {
            label: this.$t('Yes'),
            value: 'yes',
          },
          {
            label: this.$t('No'),
            value: 'no',
          },
          {
            label: this.$t('4 Digits Only'),
            value: '4-digits-only',
          },
        ],
      }
    },
    computed: {
      layout() {
        return this.redirectToListAfterStore ? 'vertical' : 'modal'
      },
      getSiblingData() {
        return this.$store.getters['sibling/getData']
      },
      lastJobNumber() {
        return this.$store.getters['globalLists/getLastJobNumber']
      },
      preNoticeId() {
        return this.get(this.data, 'preNotice.id')
      },
    },
    methods: {
      onCancel() {
        if (this.redirectToListAfterStore) {
          return this.$router.push('/job-costing/jobs')
        }
        this.$emit('cancel')
      },
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            const {data} = await axios.put(`/restify/jobs/${this.model.id}`, this.model)
            await this.$store.dispatch('jobCosting/getJob', this.model.id)
            this.$success(this.$t('Job updated'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Jobs,
              resourceId: data.id,
              isEdit: true
            })
            this.$emit('save', data)
          } else {
            const { data } = await axios.post('/restify/jobs', this.model)
            this.model.id = data.id
            await this.attachAddressesAndContacts(data.id)
            this.$success(this.$t('Job created'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Jobs,
              resourceId: data.id,
            })
            // * check also for redirectToListAfterStore state - because of create jobs on the fly
            if (this.$createAnotherEntity && this.redirectToListAfterStore) {
              return this.$emit('create-another')
            }
            if (this.redirectToListAfterStore) {
              await this.$router.push(`/job-costing/jobs/${data.id}/line-items`)
            }
            this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async attachAddressesAndContacts(jobID) {
        await this.$refs.addressList.attachTemporaryAddresses(jobID)
        await this.$refs.contactList.attachTemporaryContacts(jobID)
      },
      async getCustomer(id) {
        const { data } = await axios.get(`/restify/customers/${id}`)
        this.customer = data
        this.model.customer_id = id
        this.customerSelectRenderKey++
      },
      fillForm(data) {
        this.model = {
          ...this.model,
          ...data,
          id: '',
          number: '',
        }

        if (!data.customer_id) {
          return
        }
        this.getCustomer(data.customer_id)
      },
    },
    watch: {
      getSiblingData: {
        handler(value) {
          this.fillForm(value)
        },
      },
      data: {
        immediate: true,
        handler(value) {
          if (this.$route.query.customer_id) {
            this.getCustomer(this.$route.query.customer_id)
          }

          if (!value?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value,
          }

          this.customer = this.get(value, 'customer', {})
        },
      },
    },
  }
</script>
<style lang="scss">
  .el-collapse-item .el-collapse-item__header {
    @apply text-base leading-6 font-medium text-gray-900;
  }
</style>
