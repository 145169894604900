<template>
  <component
    :is="asRow ? 'BaseFilterRow' : 'div'"
    :title="$t('Jobs')"
  >
    <base-filter-range :class="gridClass">
      <template #start>
        <job-select
            v-model="value[fromKey]"
            v-bind="$attrs"
            :add-entity="false"
            :placeholder="$t('Job From')"
            :name="$t('Job From')"
            :hide-label="true"
            :allow-create-no-options-only="true"
            value-key="number"
            clearable
            allow-create
            @visible-change="onChangeStartRangeFilterVisibility"
        />
      </template>
      <template #end>
        <job-select
            v-model="value[toKey]"
            v-bind="$attrs"
            :add-entity="false"
            :placeholder="$t('Job To')"
            :name="$t('Job To')"
            :hide-label="true"
            :rules="value[fromKey] ? 'required' : ''"
            :allow-create-no-options-only="true"
            value-key="number"
            clearable
            @blur="tryFillEndRangeValue"
        />
      </template>
    </base-filter-range>

    <slot />
  </component>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          job_number_from: null,
          job_number_to: null,
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
      fromKey: {
        type: String,
        default: 'job_number_from',
      },
      toKey: {
        type: String,
        default: 'job_number_to',
      },
      asRow: {
        type: Boolean,
        default: true,
      }
    },
    methods: {
      onChangeStartRangeFilterVisibility(opened) {
        if (opened) {
          return
        }
        this.tryFillEndRangeValue()
      },
      tryFillEndRangeValue() {
        this.value[this.toKey] = this.value[this.toKey] || this.value[this.fromKey]
      },
    }
  }
</script>
