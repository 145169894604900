<template>
  <div>
    <AgDataTable
      v-bind="editableTableProps"
      :add-text="$t('New Entry')"
      :url="url"
      :url-params="urlParams"
      :columns="columns"
      :transform-data="mapData"
      :get-empty-row="getEmptyEntry"
      :readOnly="readOnly"
      :show-cells-legend="!readOnly"
      :no-borders="readOnly"
      :groupIncludeFooter="true"
      :groupIncludeTotalFooter="true"
      ref="gridTable"
      domLayout="autoHeight"
      :actions="readOnly ? '' : 'add,delete'"
      hide-actions="filters"
      suppressColumnReordering
      :deleteCustom="true"
      @delete="onDelete"
      @cell-focused="onCellFocused"
      @grid-ready="onGridReady"
      @data-updated="entries = $event"
    />
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.RepairOrderEntries"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script>
import axios from 'axios'
import {
  additionalSourceCol,
  descriptionCol,
  quantityCol,
  updateColumnHeader,
} from "@/components/ag-grid/columns/costCenterColumns";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import {
  cellClasses,
  getCellClasses,
  requiredValueSetter,
} from "@/components/ag-grid/columnUtils";
import { editableTableProps, getTableData, getRowData } from "@/components/ag-grid/tableUtils";
import {
  sourceTypes,
  abbrToSourceType,
  additionalSourceMap,
  sourceCellTitles
} from "@/modules/equipment/util/repairOrderUtils";
import { equipmentCostTypeFor } from '@/enum/equipment'

export default {
  name: 'RepairOrderMaterialsList',
  props: {
    computedGrossBilling: Boolean,
    repairOrder: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    resourceEndpoint: {
      type: String,
      default: '/restify/repair-order-entries',
    },
  },
  data() {
    return {
      entries: [],
      grid: null,
      editableTableProps,
      itemToDelete: null,
      showDeleteDialog: false,
    }
  },
  computed: {
    url() {
      if (!this.repairOrder.id) {
        return ''
      }
      return this.resourceEndpoint
    },
    urlParams() {
      if (!this.repairOrder.id) {
        return {}
      }
      return {
        repair_order_id: this.repairOrder.id,
        perPage: 500,
        sort: 'created_at',
      }
    },
    equipmentCostTypes() {
      return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.EquipmentTypes)
    },
    allMaterials() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.Materials)
    },
    maintenanceCodes() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.EquipmentMaintenances)
    },
    columns() {
      return [
        {
          headerName: this.$t('Type'),
          field: 'type_id',
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: this.$globalResources.EquipmentTypes,
            filterMethod: (eqpType) => {
              return [
                equipmentCostTypeFor.Repairs,
                equipmentCostTypeFor.Parts,
              ].includes(eqpType.for)
            }
          },
          valueFormatter: (params) => {
            const op = this.equipmentCostTypes.find(o => o.id === params.value)
            return op ? (op.name || op.abbr) : ''
          },
          valueSetter: params => {
            if (!params.newValue) {
              return false
            }

            this.onChangeType(params)
            return true
          },
          cellClass: getCellClasses,
          editable: true,
          minWidth: 80,
          maxWidth: 120,
        },
        {
          ...additionalSourceCol(),
          headerName: ' ',
          component: undefined,
          cellClass: params => {
            if (this.readOnly || params.node.footer) {
              return ''
            }

            if (!params.data?.addl_source_type) {
              return cellClasses.ReadOnly
            }

            if (params.data.addl_source_type === sourceTypes.Material && !params.data.addl_source_id) {
              return cellClasses.Invalid
            }
          },
          editable: true,
          suppressNavigable: false,
          cellEditorParams: params => {
            const { addl_source_type } = params.data
            const resourceName = additionalSourceMap[addl_source_type]

            return {
              resourceName,
              unauthorizedToAdd: false,
            }
          },
          valueFormatter: params => {
            if (!params?.data) {
              return
            }
            const resource = this.findResource(params.data)

            const { code, description } = resource
            if (!code) {
              return ''
            }
            let text = code
            if (description) {
              text = `${code} (${description})`
            }
            return text
          },
          valueSetter: params => {
            if (!params.newValue) {
              return false
            }

            params.data.addl_source_id = params.newValue
            this.onChangeAdditionalSource(params)
            return true
          },
          minWidth: 200,
          maxWidth: 250,
        },
        {
          headerName: this.$t('Maintenance Code'),
          field: 'maintenance_code_id',
          minWidth: 200,
          maxWidth: 230,
          editable: true,
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: this.$globalResources.EquipmentMaintenances,
          },
          valueFormatter: (params) => {
            const mc = this.maintenanceCodes.find(o => o.id === params.value)
            return mc
              ? `${mc.code} (${mc.description})`
              : ''
          },
          valueSetter: params => {
            if (!params.newValue) {
              return false
            }

            params.data.maintenance_code_id = params.newValue
            const mc = this.maintenanceCodes.find(o => o.id === params.newValue)
            params.data.description = mc.description
            return true
          },
        },
        {
          ...descriptionCol,
          cellClass: (params) => {
            if (this.readOnly || params.node.footer) {
              return ''
            }

            if (params.data.addl_source_type === sourceTypes.Labor && !params.data.description) {
              return cellClasses.Invalid
            }
          }
        },
        {
          headerName: this.$t('Unit'),
          field: 'um',
          minWidth: 60,
          maxWidth: 120,
          editable: true,
          sortable: false,
        },
        {
          headerName: this.$t('Estimated'),
          children: [
            {
              ...quantityCol,
              editable: !this.readOnly,
              valueSetter: params => {
                const isValid = requiredValueSetter(params, 0)
                if (!isValid) {
                  return false
                }

                this.calculateAmount(params)

                return true
            },
            },
            {
              headerName: this.$t('Unit Price'),
              field: 'unit_rate',
              align: 'right',
              minWidth: 90,
              maxWidth: 150,
              component: 'FormattedPrice',
              editable: !this.readOnly,
              cellEditor: cellEditors.Numeric,
              valueSetter: params => {
                const isValid = requiredValueSetter(params, 0)
                if (!isValid) {
                  return false
                }
                
                this.calculateAmount(params)

                return true
              },
            },
            {
              headerName: this.$t('Amount'),
              field: 'amount',
              align: 'right',
              minWidth: 100,
              maxWidth: 150,
              component: 'FormattedPrice',
              editable: !this.readOnly,
              sortable: false,
              cellEditor: cellEditors.Numeric,
              valueSetter: params => {
                const isValid = requiredValueSetter(params, 0)
                if (!isValid) {
                  return false
                }

                this.calculateUnitPrice(params)

                return true
              },
              aggFunc: 'sum'
            },
          ]
        },
        {
          headerName: this.$t('To-Date'),
          children: [
            {
              headerName: this.$t('Qty'),
              field: 'quantity_to_date',
              align: 'right',
              minWidth: 100,
              maxWidth: 150,
              component: 'FormattedHours',
              initialHide: !this.readOnly,
              cellClass: () => {
                if (this.readOnly) {
                  return ''
                }

                return cellClasses.ReadOnly
              }
            },
            {
              headerName: this.$t('Amount'),
              field: 'amount_to_date',
              align: 'right',
              minWidth: 100,
              maxWidth: 150,
              component: 'FormattedPrice',
              initialHide: !this.readOnly,
              cellClass: () => {
                if (this.readOnly) {
                  return ''
                }

                return cellClasses.ReadOnly
              },
              aggFunc: 'sum'
            },
          ]
        },
      ]
    },
  },
  methods: {
    mapData(data) {
      return data.map(entry => {
        return {
          ...entry.attributes,
        }
      })
    },
    onCellFocused(params) {
      const row = getRowData(params)
      const sourceCol = params.columnApi.getColumn('addl_source_id')?.colDef
      const addl_source_type = row?.addl_source_type

      const title = sourceCellTitles[addl_source_type] || sourceCellTitles.default

      updateColumnHeader(sourceCol, title)
      params.api.refreshHeader()

      this.selectedRow = row
    },
    tryCollapseFormHeader() {
      this.$emit('collapse-form-header')
    },
    onGridReady(grid) {
      this.grid = grid
    },
    calculateAmount(params) {
      const {
        unit_price,
        quantity,
      } = params.data

      params.data.amount = this.round(unit_price * quantity, 2)
    },
    calculateUnitPrice(params) {
      const {
        amount,
        quantity,
      } = params.data

      params.data.unit_price = this.round(amount / quantity, 2)
    },
    getEmptyEntry() {
      this.tryCollapseFormHeader()

      return {
        _localId: crypto.randomUUID(),
        addl_source_type: null,
        addl_source_id: null,
        quantity: 0,
        um: '',
        unit_price: 0,
        amount: 0,
        description: '',
      }
    },
    findResource({ addl_source_id, addl_source_type }) {
      const resourceName = additionalSourceMap[addl_source_type]
      if (!resourceName) {
        return {}
      }
      const resourceList = this.$store.getters['globalLists/getResourceList'](resourceName)
      return resourceList.find(resource => resource.id === addl_source_id) || {}
    },
    onChangeType(params) {
      let currentEntry = params.data

      currentEntry.type_id = params.newValue
      currentEntry.type_type = 'equipment-type'
      currentEntry.addl_source_id = null

      const used_for = this.equipmentCostTypes.find(o => o.id === params.newValue)?.for
      currentEntry.addl_source_type = abbrToSourceType[used_for]

      params.node.setData(currentEntry)
    },
    onChangeAdditionalSource(params) {
      let currentEntry = params.data
      const newData = {
        quantity: 1,
      }

      if (currentEntry.addl_source_type == sourceTypes.Material) {
        const material = this.allMaterials.find(m => m.id === params.newValue)
        newData.um = material?.um || ''
        newData.unit_price = material?.last_invoice_unit_rate || material?.standard_unit_rate || 0
        newData.amount = newData?.unit_price || 0
      }
      else {
        newData.um = 'HOUR'
      }

      params.node.setData({
        ...currentEntry,
        ...newData,
      })
    },
    getTableData() {
      return getTableData(this.grid?.api)
    },
    async storeProgress(repair_order_id) {
      const entries = this.getTableData()
        .map((entry, index) => {
          const dirty = entry.dirty ? entry.dirty : entry.order !== index
          return {
            ...entry,
            dirty,
            repair_order_id,
            addl_source_type: entry.addl_source_id
              ? entry.addl_source_type
              : null,
            order: index,
          }
        })
        .filter(entry => entry.dirty)

      const entriesToSave = entries.filter(entry => !entry.id)
      const entriesToUpdate = entries.filter(entry => entry.id)

      if (entriesToSave.length) {
        await axios.post(`${this.resourceEndpoint}/bulk`, entriesToSave)
      }
      if (entriesToUpdate.length) {
        await axios.post(`${this.resourceEndpoint}/bulk/update`, entriesToUpdate)
      }

      await this.refreshEntriesTable()
    },
    async refreshEntriesTable() {
      if (!this.repairOrder.id) {
        return
      }
      await this.$refs.gridTable.refresh()
    },
    onDelete(row) {
      if (!row?.id) {
        this.grid.api.applyTransaction({ remove: [row] })
        return
      }

      this.itemToDelete = row
      this.showDeleteDialog = true
    },
    async onItemDeleted() {
      this.showDeleteDialog = false
      await this.refreshEntriesTable()
    },
  },
}
</script>
