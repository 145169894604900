<template>
  <div>
    <AgDataTable
      ref="table"
      url="/restify/crews"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('New Crew')"
      :edit-entity-url-query="editEntityUrlQuery"
      actions="search,refresh,add,edit,view"
      view-entity-url-query="/payroll/timesheets/crews"
      @add="onRowAdd"
      @edit="onRowEdit"
    >
      <template #crew_leaders="{row}">
        <div>
          <EmployeeLink
            v-for="employee in getCrewLeaders(row)"
            :key="employee.id"
            :id="employee.id"
            :data="employee"/>
        </div>
      </template>
      <template #crew_member_count="{row}">
        <StatusBadge :status="getCrewEmployees(row)?.length"/>
      </template>
    </AgDataTable>
    <BaseFormDialog
      v-if="showDialog"
      :title="formTitle"
      :open.sync="showDialog"
      size="2xl"
      @cancel="onEventTrigger(false)"
    >
      <CrewForm
        :data="rowToEdit"
        :key="renderKey"
        @cancel="onEventTrigger(false)"
        @save="onEventTrigger"
        @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
import CrewForm from "@/modules/payroll/components/crews/CrewForm.vue";

export default {
  components: {
    CrewForm
  },
  data() {
    return {
      renderKey: 1,
      rowToEdit: null,
      showDialog: false,
      editEntityUrlQuery: '?id={ID}'
    }
  },
  computed: {
    columns() {
      return [
        {
          headerName: this.$t('Name'),
          field: 'attributes.name',
          minWidth: 150,
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 150,
        },
        {
          headerName: this.$t('Crew Leaders'),
          field: 'crew_leaders',
          minWidth: 150,
          autoHeight: true,
        },
        {
          headerName: this.$t('Members'),
          field: 'crew_member_count',
          minWidth: 100,
        }
      ]
    },
    urlParams() {
      return {
        related: 'employees[id|name|code]'
      }
    },
    formTitle() {
      if (!this.rowToEdit) {
        return this.$t('Add Crew')
      }
      if (this.$isAuthorized(this.$actionPolicies.Update, this.rowToEdit)) {
        return this.$t('Edit Crew')
      }
      return this.$t('View Crew')
    }
  },
  methods: {
    getCrewEmployees(row) {
      return row.relationships?.employees || []
    },
    getCrewLeaders(row) {
      return this.getCrewEmployees(row).filter(e => e.pivots?.is_leader)
    },
    getCrewMembers(row) {
      return this.getCrewEmployees(row).filter(e => !e.pivots?.is_leader)
    },
    onRowAdd() {
      this.showDialog = true
    },
    onRowEdit(row) {
      this.showDialog = true
      this.rowToEdit = row
    },
    refreshTable() {
      // @ts-ignore
      this.$refs.table.refresh()
    },
    onEventTrigger(refresh = true) {
      this.rowToEdit = null
      this.showDialog = false
      if (!refresh) {
        return
      }
      this.refreshTable()
    },
  }
}
</script>
