<template>
  <div>
    <base-go-to-edit-alert
        v-if="reconciliationAlreadyExists"
        :path="`/ledger/account-reconciliation/${pendingReconciliation.id}/edit`"
        message="Pending reconciliation for current selections already exists."
    />
    <base-form
        :loading="loading"
        :show-back="true"
        :save-text="$t('Create reconciliation')"
        :update-text="$t('Update reconciliation')"
        :submit-disabled="formSubmitDisabled"
        layout="vertical"
        grid-classes="grid grid-cols-1"
        @submit="onSubmit"
    >
      <el-collapse
          v-model="activeGroup"
          class="col-span-1"
      >
        <base-collapse-section
            key="summary"
            class="-m-6 mb-6"
            name="summary"
            has-background
        >
          <template #title>
            <div class="flex flex-1 items-center justify-between form-header-summary">
              <span class="mr-2">
                {{ $t('Summary') }}
              </span>
              <div class="summary">
                {{ $t('G/L Balance: ') }}
                <span>
                  {{ $formatPrice(accountBalance) }}
                </span>
              </div>
            </div>
          </template>
          <div class="grid grid-cols-3 gap-4 mx-2">
            <div class="col-span-2 grid grid-cols-12 gap-x-4">
              <account-select
                  v-model="model.account"
                  :name="$t('Account')"
                  :label="$t('Account')"
                  :filter-function="filterAccounts"
                  :disabled="!!$route.params.id"
                  value-key="number"
                  :step="0.01"
                  id="account_id"
                  rules="required"
                  class="col-span-12 md:col-span-4"
                  @change="onAccountChange"
              />
              <period-select
                  v-model="model.period"
                  :reference-date="model.date"
                  :label="$t('Period')"
                  class="col-span-12 md:col-span-3"
                  rules="required"
                  @change="validateSelection"
              />
              <fiscal-year-select
                  v-model="model.fiscal_year"
                  :label="$t('Year')"
                  :only-opened-years="false"
                  class="col-span-12 md:col-span-3"
                  @change="validateSelection"
              />
              <base-date-picker
                  v-model="model.date"
                  :label="$t('Date')"
                  class="col-span-12 md:col-span-3"
                  rules="required"
                  @change="validateSelection"
              />
              <base-input
                  v-model.number="model.bank_balance_amount"
                  :label="$t('Bank Statement Balance')"
                  id="bank-statement-balance"
                  :step="0.01"
                  type="number"
                  format="price"
                  class="col-span-12 md:col-span-3"
              />
              <ReconciliationProcessingInput
                :data="model"
                :account="model.account"
                class="print:hidden col-span-12 mb-4"
                @parsed="onStatementProcessed"
                @reprocess="onStatementReProcess"
              />
              <!--to do: implement on be side-->
              <template v-if="false">
                <base-select
                    v-model="filters.additionalFilter"
                    :label="$t('Display')"
                    :options="additionalFilterOptions"
                    class="col-span-12 md:col-span-2"
                    rules="required"
                />
                <base-input v-if="filters.additionalFilter === 'exact_amount'"
                            v-model="filters.amount"
                            :label="$t('Exact Amount')"
                            :step="1"
                            id="exact_amount"
                            key="exact_amount"
                            format="price"
                            type="number"
                            class="number-input"
                            rules="required|excluded:0"
                />
                <template v-if="filters.additionalFilter === 'partial_amount'">
                  <base-input
                      v-model="filters.amount_from"
                      :label="$t('Min Amount')"
                      :step="1"
                      id="partial_amount_min"
                      type="number"
                      format="price"
                      rules="required|excluded:0"
                      class="col-span-12 md:col-span-2"
                  />
                  <base-input
                      v-model="filters.amount_to"
                      :label="$t('Max Amount')"
                      :rules="amountRules"
                      :step="1"
                      id="partial_amount_max"
                      type="number"
                      format="price"
                      class="col-span-12 md:col-span-2"
                  />
                </template>
              </template>
            </div>
            <div class="col-span-1 flex-1">
              <portal-target name="reconciliation-summary"/>
            </div>
          </div>
        </base-collapse-section>
      </el-collapse>
      <ReconciliationEntries
          v-if="showReconcileEntriesTable && !localLoading"
          :data="model"
          :url-params="getUrlParams"
          :account-balance="accountBalance"
          :details-page="false"
          class="mt-6"
          ref="entriesTable"
      />
      <FileAttachments
        ref="attachments"
        :entity-id="entityId"
        class="col-span-1 mt-8"
        entity="reconciliations"/>

      <template #extra-buttons-right>
        <portal-target name="reconciliation-difference"/>
      </template>
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses, transactionStatuses } from '@/enum/enums'
  import { getAccountByNumber, computedAccountBalance } from '@/utils/account'
  import ReconciliationEntries from '@/modules/ledger/components/ReconciliationEntries'
  import FileAttachments from '@/modules/accounts-payable/components/attachements/FileAttachments'
  import ReconciliationProcessingInput from "@/modules/ledger/components/ReconciliationProcessingInput.vue";
  import InvoiceProcessingInput from "@/modules/accounts-payable/components/invoice/InvoiceProcessingInput.vue";
  import { convertJsonToCSVFile } from "@/modules/common/util/csvUtils";

  const meta = {
    prior_and_current_outstanding: 0,
    future_items_reconciled: 0,
    adjusted_balance: 0,
    difference: 0,
  }

  export default {
    components: {
      InvoiceProcessingInput,
      ReconciliationProcessingInput,
      FileAttachments,
      ReconciliationEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      const firstPeriodValue = this.$store.getters['company/firstPeriodValue']

      return {
        localLoading: false,
        entityId: null,
        model: {
          account: '',
          bank_balance_amount: 0,
          date: this.$now,
          fiscal_year: this.$currentYear,
          period: firstPeriodValue,
          status: resourceStatuses.Pending,
          meta,
        },
        activeGroup: ['summary'],
        reconciliationAlreadyExists: false,
        isValidSelections: false,
        loading: false,
        urlQuery: null,
        filters: {
          additionalFilter: 'all_items',
          amount: 0,
          amount_from: 0,
          amount_to: 0,
        },
        additionalFilterOptions: [
          {
            label: this.$t('All Items'),
            value: 'all_items',
          },
          {
            label: this.$t('Exact Amount'),
            value: 'exact_amount',
          },
          {
            label: this.$t('Amount range'),
            value: 'partial_amount',
          },
          {
            label: this.$t('Outstanding only'),
            value: 'outstanding',
          },
          {
            label: this.$t('Reconciled only'),
            value: 'reconciled',
          },
          {
            label: this.$t('Variance only'),
            value: 'variance',
          },
        ],
        selectedAccount: {},
        pendingReconciliation: {},
        transactionsCsvFileName: null,
      }
    },
    computed: {
      formSubmitDisabled() {
        if (this.model.id) {
          return false
        }

        return this.reconciliationAlreadyExists || !this.isValidSelections
      },
      showReconcileEntriesTable() {
        return this.isValidSelections || this.model.id
      },
      amountRules() {
        const amount_from = this.filters.amount_from
        return `required|min_value:${amount_from}`
      },
      periods() {
        return this.$store.getters['company/getAuthorizedToUsePeriods']
      },
      accountBalance() {
        return computedAccountBalance(this.selectedAccount, this.model.period)
      },
      getUrlParams() {
        return {
          account_number: this.selectedAccount.number || this.data?.attributes?.account,
          period: this.model.period,
          before_period: this.model.period,
          fiscal_year: this.model.fiscal_year,
          before_fiscal_year: this.model.fiscal_year,
        }
      },
      requiredFieldsFilled() {
        return this.model.account && this.model.period && this.model.fiscal_year && this.model.date
      },
    },
    methods: {
      async onAccountChange(accountNumber) {
        try {
          this.localLoading = true
          this.selectedAccount = await getAccountByNumber(accountNumber, this.model.fiscal_year)
          await this.validateSelection()
        } catch (e) {
          console.warn(e)
        } finally {
          this.localLoading = false
        }
      },
      filterAccounts(account) {
        const bankAccounts = this.$store.state.company.activeCompany?.banks?.map(b => b?.attributes?.account) || []
        return bankAccounts.includes(account.number)
      },
      async onStatementProcessed(model, transactions, file) {
        if (!transactions.length || !model) {
          return
        }
        this.model = {
          ...this.model,
          ...model
        }
        const mappedTransactions = await this.$refs.entriesTable?.applyMatchingTransactions(transactions)
        if (file) {
          file.id = new Date().getTime() + file.name
          const csvFileName = `${file.name}.csv`
          const transactionsCsv = await convertJsonToCSVFile({
            fileName: csvFileName,
            data: mappedTransactions,
            addHeader: false,
          })
          this.transactionsCsvFileName = csvFileName
          transactionsCsv.id = new Date().getTime() + csvFileName
          const event = {
            target: {
              files: [transactionsCsv]
            }
          }
          await this.$refs.attachments?.onChange(event)
        }
      },
      async onStatementReProcess(transactions) {
        if (!transactions.length) {
          return
        }
        await this.$refs.entriesTable?.applyMatchingTransactions(transactions)
        this.$success(this.$t('Transactions were reprocessed and mapped again'))
      },
      async pushDummyCsv() {
        const data = [
          {
            header: 'Value'
          }
        ]
        const csvFileName = 'dummy.csv'
        const transactionsCsv = await convertJsonToCSVFile({
          fileName: csvFileName,
          data,
          addHeader: false,
        })
        transactionsCsv.id = new Date().getTime() + csvFileName
        this.$refs.attachments.files?.push(transactionsCsv)
      },
      async validateSelection() {
        await this.$nextTick()

        if (this.model.id || !this.requiredFieldsFilled) {
          return
        }

        try {
          this.loading = true

          const { data } = await axios.get('/restify/reconciliations', {
            params: {
              account: this.model.account,
              period: this.model.period,
              fiscal_year: this.model.fiscal_year,
              date: this.model.date,
              status: resourceStatuses.Pending,
            },
          })

          this.reconciliationAlreadyExists = !!data.length
          this.pendingReconciliation = data[0] || {}
          this.isValidSelections = !data.length

          if (data.length) {
            return
          }

          await this.tryGetLastPostedReconciliation()

        } catch (e) {
          console.warn(e)
        } finally {
          this.loading = false
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          const meta = this.$refs.entriesTable.getSummary() || {}
          this.model.prior_and_current_amount = meta.prior_and_current_outstanding
          this.model.future_amount = meta.future_items_reconciled
          this.model.account_balance_amount = meta.adjusted_balance

          if (this.model.id) {
            await axios.put(`/restify/reconciliations/${this.model.id}`, this.model)
            await this.$refs.entriesTable.storeProgress(this.model.id)
          } else {
            const { data } = await axios.post('/restify/reconciliations', this.model)
            this.entityId = data.id
            await this.$refs.entriesTable.storeProgress(data.id, true)
            await this.$refs.attachments.triggerUpload()
            const parsedCsvFile = this.$refs.attachments.getAttachmentByName(this.transactionsCsvFileName)
            if (parsedCsvFile) {
              this.model.meta.parsed_csv_file_id = parsedCsvFile?.uuid
              await axios.put(`/restify/reconciliations/${data.id}`, this.model)
            }
            await this.$router.push('/ledger/account-reconciliation/pending')
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }

          this.$error(this.$t('Could not save account reconciliation'))
        } finally {
          this.loading = false
        }
      },
      async tryGetLastPostedReconciliation() {
        try {
          this.loading = true
          const { data } = await axios.get('/restify/reconciliations', {
            params: {
              account: this.model.account,
              fiscal_year: this.$currentYear,
              period: this.model.period,
              status: resourceStatuses.Posted,
              perPage: 1,
              sort: '-created_at',
            },
          })

          if (!data.length) {
            return
          }

          this.model.period = this.get(data, '0.attributes.period')

        } catch (e) {
          console.warn(e)
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      window.pushDummyCsv = this.pushDummyCsv
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }

          this.model = {
            ...this.model,
            ...value.attributes,
            meta: this.get(value, 'attributes.meta', meta),
          }

          this.onAccountChange(this.model.account)
        },
      },
    },
  }
</script>
