export interface AccountsPayableInit {
  // columns
  id: string
  organization_id: string
  company_id: string
  journal_id: string|null
  void_journal_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  status: string
  fiscal_year: number
  period: number
  posted_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  entries: AccountsPayableInitEntries
  journal: Journal
  poster: User
  void_journal: Journal
  creator: User
  editor: User
}
export type AccountsPayableInits = AccountsPayableInit[]
export interface AccountsPayableInitResults extends api.MetApiResults { data: AccountsPayableInits }
export interface AccountsPayableInitResult extends api.MetApiResults { data: AccountsPayableInit }
export interface AccountsPayableInitMetApiData extends api.MetApiData { data: AccountsPayableInit }
export interface AccountsPayableInitResponse extends api.MetApiResponse { data: AccountsPayableInitMetApiData }

export interface AccountsPayableInitEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  accounts_payable_init_id: string
  vendor_id: string
  number: string
  description: string|null
  date: Date
  due_date: Date
  gross_amount: number
  discount_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  accounts_payable_init: AccountsPayableInit
  vendor: Vendor
}
export type AccountsPayableInitEntries = AccountsPayableInitEntry[]
export interface AccountsPayableInitEntryResults extends api.MetApiResults { data: AccountsPayableInitEntries }
export interface AccountsPayableInitEntryResult extends api.MetApiResults { data: AccountsPayableInitEntry }
export interface AccountsPayableInitEntryMetApiData extends api.MetApiData { data: AccountsPayableInitEntry }
export interface AccountsPayableInitEntryResponse extends api.MetApiResponse { data: AccountsPayableInitEntryMetApiData }

export interface Invoice {
  // columns
  id: string
  organization_id: string
  company_id: string
  vendor_id: string
  journal_id: string|null
  void_journal_id: string|null
  bank_id: string|null
  purchase_order_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  voided_by: string|null
  date: Date
  number: string
  close_po_after: boolean
  description: string|null
  gross_amount: number
  distribution_amount: number
  discount_percent: number
  discount_amount: number
  discount_taken_amount: number
  retention_percent: number
  retention_amount: number
  paid_amount: number
  open_amount: number
  is_in_balance: boolean
  for_retention_release: boolean
  status: string
  type: string
  next_recurrence_fiscal_year: number|null
  next_recurrence_period: number|null
  payment_number: string|null
  payment_date: Date|null
  due_date: Date
  discount_date: Date
  voided_at: Date|null
  posted_at: Date|null
  paid_at: Date|null
  telemetry: Record<string, unknown>
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  entries: InvoiceEntries
  payments: InvoicePayments
  purchase_order: PurchaseOrder
  bank: Bank
  journal: Journal
  poster: User
  vendor: Vendor
  voider: User
  void_journal: Journal
  notes: Notes
  media: Media
  creator: User
  editor: User
}
export type Invoices = Invoice[]
export interface InvoiceResults extends api.MetApiResults { data: Invoices }
export interface InvoiceResult extends api.MetApiResults { data: Invoice }
export interface InvoiceMetApiData extends api.MetApiData { data: Invoice }
export interface InvoiceResponse extends api.MetApiResponse { data: InvoiceMetApiData }

export interface InvoiceEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  invoice_id: string
  purchase_order_entry_id: string|null
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  order: number
  cost_center: CostCenter
  description: string|null
  account: string
  subaccount: string|null
  quantity: number
  gross_amount: number
  retention_amount: number
  discount_amount: number
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // mutators
  net_amount: number
  // relations
  gl_account: Account
  gl_subaccount: Subaccount
  invoice: Invoice
  purchase_order_entry: PurchaseOrderEntry
  addl_source: InvoiceEntry
  source: InvoiceEntry
  type: InvoiceEntry
}
export type InvoiceEntries = InvoiceEntry[]
export interface InvoiceEntryResults extends api.MetApiResults { data: InvoiceEntries }
export interface InvoiceEntryResult extends api.MetApiResults { data: InvoiceEntry }
export interface InvoiceEntryMetApiData extends api.MetApiData { data: InvoiceEntry }
export interface InvoiceEntryResponse extends api.MetApiResponse { data: InvoiceEntryMetApiData }

export interface InvoicePayment {
  // columns
  id: string
  organization_id: string
  company_id: string
  vendor_id: string
  bank_id: string
  journal_id: string
  void_journal_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  voided_by: string|null
  date: Date
  number: string
  type: string
  status: string
  net_amount: number
  gross_amount: number
  discount_amount: number
  sent_count: number
  sent_dd_count: number
  printed_count: number
  telemetry: Record<string, unknown>
  pdf_paths: Record<string, unknown>
  attachments: string[]
  printed_at: Date|null
  sent_at: Date|null
  downloaded_at: Date|null
  posted_at: Date|null
  voided_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  invoices: Invoices
  bank: Bank
  journal: Journal
  vendor: Vendor
  voider: User
  void_journal: Journal
  creator: User
  editor: User
}
export type InvoicePayments = InvoicePayment[]
export interface InvoicePaymentResults extends api.MetApiResults { data: InvoicePayments }
export interface InvoicePaymentResult extends api.MetApiResults { data: InvoicePayment }
export interface InvoicePaymentMetApiData extends api.MetApiData { data: InvoicePayment }
export interface InvoicePaymentResponse extends api.MetApiResponse { data: InvoicePaymentMetApiData }

export interface Vendor {
  // columns
  id: string
  organization_id: string
  company_id: string
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  code: string
  name: string|null
  cost_center: string
  account: string|null
  subaccount: string|null
  email: string|null
  phone: string|null
  fax: string|null
  contact: string|null
  federal_tax_id: string|null
  state_tax_id: string|null
  vendor_personal_name: string|null
  dir: string|null
  check_memo: string|null
  website_url: string|null
  w9_on_file: string
  due_date: string
  due_date_days: unknown
  discount_date: string
  discount_date_days: number
  status: string
  payment_to_date: number
  current_ap_amount: number
  payments_on_hold_amount: number
  retention_amount: number
  discount_percent: number
  dir_expiration_date: Date|null
  last_payment_date: Date|null
  contractor_license: string[]
  expiration_dates: Record<string, unknown>
  print_1099: boolean
  csp: boolean
  track_purchases: boolean
  dd_status: string
  dd_account_type: string
  dd_routing_number: string|null
  dd_account_number: unknown|null
  default_all_pay_to_dd: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  activities: VendorActivities
  documents: VendorDocuments
  invoices: Invoices
  retentions: VendorRetentions
  addresses: Addresses
  address: Address
  contacts: Contacts
  transactions: Transactions
  notes: Notes
  addl_source: Vendor
  source: Vendor
  type: Vendor
}
export type Vendors = Vendor[]
export interface VendorResults extends api.MetApiResults { data: Vendors }
export interface VendorResult extends api.MetApiResults { data: Vendor }
export interface VendorMetApiData extends api.MetApiData { data: Vendor }
export interface VendorResponse extends api.MetApiResponse { data: VendorMetApiData }

export interface VendorActivity {
  // columns
  id: string
  organization_id: string
  company_id: string
  vendor_id: string
  year: number
  amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  vendor: Vendor
}
export type VendorActivities = VendorActivity[]
export interface VendorActivityResults extends api.MetApiResults { data: VendorActivities }
export interface VendorActivityResult extends api.MetApiResults { data: VendorActivity }
export interface VendorActivityMetApiData extends api.MetApiData { data: VendorActivity }
export interface VendorActivityResponse extends api.MetApiResponse { data: VendorActivityMetApiData }

export interface VendorDocument {
  // columns
  id: string
  organization_id: string
  company_id: string
  vendor_id: string
  job_id: string
  lienor_vendor_id: string|null
  joint_vendor_id: string|null
  document_type: string
  type: string|null
  notice_date: Date|null
  entry_date: Date|null
  date_sent: Date|null
  throw_date: Date|null
  receive_date: Date|null
  estimated_price: number
  amount: number
  certified_mail_no: string|null
  check_no: string|null
  comment: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  joint_vendor: Vendor
  lienor_vendor: Vendor
  job: Job
  vendor: Vendor
}
export type VendorDocuments = VendorDocument[]
export interface VendorDocumentResults extends api.MetApiResults { data: VendorDocuments }
export interface VendorDocumentResult extends api.MetApiResults { data: VendorDocument }
export interface VendorDocumentMetApiData extends api.MetApiData { data: VendorDocument }
export interface VendorDocumentResponse extends api.MetApiResponse { data: VendorDocumentMetApiData }

export interface VendorRetention {
  // columns
  id: string
  vendor_id: string
  job_budget_id: string
  line_item_id: string
  job_id: string
  job_type_id: string
  retention_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  job: Job
  job_budget: JobBudget
  job_type: JobType
  line_item: LineItem
  vendor: Vendor
}
export type VendorRetentions = VendorRetention[]
export interface VendorRetentionResults extends api.MetApiResults { data: VendorRetentions }
export interface VendorRetentionResult extends api.MetApiResults { data: VendorRetention }
export interface VendorRetentionMetApiData extends api.MetApiData { data: VendorRetention }
export interface VendorRetentionResponse extends api.MetApiResponse { data: VendorRetentionMetApiData }

export interface Agency {
  id: string
  organization_id: string
  company_id: string
  account_number: string
  name: string
  code: string
  description: string|null
  updated_at: Date|null
  status: string
  website_url: string|null
}

export interface AccountsReceivableInit {
  // columns
  id: string
  organization_id: string
  company_id: string
  journal_id: string|null
  void_journal_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  status: string
  fiscal_year: number
  period: number
  posted_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  entries: AccountsReceivableInitEntries
  journal: Journal
  poster: User
  void_journal: Journal
  creator: User
  editor: User
}
export type AccountsReceivableInits = AccountsReceivableInit[]
export interface AccountsReceivableInitResults extends api.MetApiResults { data: AccountsReceivableInits }
export interface AccountsReceivableInitResult extends api.MetApiResults { data: AccountsReceivableInit }
export interface AccountsReceivableInitMetApiData extends api.MetApiData { data: AccountsReceivableInit }
export interface AccountsReceivableInitResponse extends api.MetApiResponse { data: AccountsReceivableInitMetApiData }

export interface AccountsReceivableInitEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  accounts_receivable_init_id: string
  customer_id: string
  number: string
  description: string|null
  date: Date
  gross_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  accounts_receivable_init: AccountsReceivableInit
  customer: Customer
}
export type AccountsReceivableInitEntries = AccountsReceivableInitEntry[]
export interface AccountsReceivableInitEntryResults extends api.MetApiResults { data: AccountsReceivableInitEntries }
export interface AccountsReceivableInitEntryResult extends api.MetApiResults { data: AccountsReceivableInitEntry }
export interface AccountsReceivableInitEntryMetApiData extends api.MetApiData { data: AccountsReceivableInitEntry }
export interface AccountsReceivableInitEntryResponse extends api.MetApiResponse { data: AccountsReceivableInitEntryMetApiData }

export interface Billing {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  journal_id: string|null
  void_journal_id: string|null
  job_id: string|null
  work_order_id: string|null
  bank_id: string|null
  created_by: string|null
  updated_by: string|null
  voided_by: string|null
  posted_by: string|null
  type: BillingType
  date: Date
  number: string
  description: string|null
  gross_amount: number
  distribution_amount: number
  discount_percent: number
  discount_amount: number
  discount_given_amount: number
  paid_amount: number
  retention_percent: number
  retention_amount: number
  sales_tax_percent: number
  sales_tax_amount: number
  open_amount: number
  net_amount: number
  is_in_balance: boolean
  for_retention_release: boolean
  status: string
  calc_retention: string
  discount_date: Date|null
  payment_number: string|null
  payment_date: Date|null
  telemetry: Record<string, unknown>
  meta: Record<string, unknown>
  voided_at: Date|null
  posted_at: Date|null
  paid_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  entries: BillingEntries
  payments: BillingPayments
  work_order: WorkOrder
  customer: Customer
  job: Job
  journal: Journal
  poster: User
  void_journal: Journal
  notes: Notes
  media: Media
  creator: User
  editor: User
}
export type Billings = Billing[]
export interface BillingResults extends api.MetApiResults { data: Billings }
export interface BillingResult extends api.MetApiResults { data: Billing }
export interface BillingMetApiData extends api.MetApiData { data: Billing }
export interface BillingResponse extends api.MetApiResponse { data: BillingMetApiData }

export interface BillingEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  billing_id: string
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  special_source_type: string|null
  special_source_id: string|null
  transaction_id: string|null
  order: number
  cost_center: CostCenter
  account: string
  subaccount: string|null
  description: string|null
  gross_amount: number
  retention_amount: number
  discount_amount: number
  material_stored_amount: number
  sales_tax_amount: number
  quantity: number
  um: string|null
  unit_rate: number
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  billing: Billing
  transaction: Transaction
  addl_source: BillingEntry
  source: BillingEntry
  special_source: BillingEntry
  type: BillingEntry
}
export type BillingEntries = BillingEntry[]
export interface BillingEntryResults extends api.MetApiResults { data: BillingEntries }
export interface BillingEntryResult extends api.MetApiResults { data: BillingEntry }
export interface BillingEntryMetApiData extends api.MetApiData { data: BillingEntry }
export interface BillingEntryResponse extends api.MetApiResponse { data: BillingEntryMetApiData }

export interface BillingPayment {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  vendor_id: string|null
  bank_id: string|null
  journal_id: string
  void_journal_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  voided_by: string|null
  date: Date
  number: string
  reference_no: string|null
  type: string
  status: string
  net_amount: number
  gross_amount: number
  discount_amount: number
  from_cash_on_deposit_amount: number
  sent_count: number
  sent_dd_count: number
  printed_count: number
  telemetry: Record<string, unknown>
  pdf_paths: Record<string, unknown>
  attachments: string[]
  printed_at: Date|null
  sent_at: Date|null
  downloaded_at: Date|null
  posted_at: Date|null
  voided_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  billings: Billings
  bank: Bank
  customer: Customer
  journal: Journal
  voider: User
  void_journal: Journal
  creator: User
  editor: User
}
export type BillingPayments = BillingPayment[]
export interface BillingPaymentResults extends api.MetApiResults { data: BillingPayments }
export interface BillingPaymentResult extends api.MetApiResults { data: BillingPayment }
export interface BillingPaymentMetApiData extends api.MetApiData { data: BillingPayment }
export interface BillingPaymentResponse extends api.MetApiResponse { data: BillingPaymentMetApiData }

export interface Customer {
  // columns
  id: string
  organization_id: string
  company_id: string
  billing_rate_type_id: string|null
  district_id: string|null
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  code: string
  name: string|null
  cost_center: string
  account: string|null
  subaccount: string|null
  email: string|null
  phone: string|null
  fax: string|null
  contact: string|null
  terms: string|null
  sales_tax_percent: number
  sales_activity_to_date: number
  retention_amount: number
  current_ar_amount: number
  current_unapplied_cash_on_deposit_amount: number
  status: string
  exempt_from_late_charges: boolean
  last_payment_date: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  activities: CustomerActivities
  billings: Billings
  billing_rates: CustomerBillingRates
  billing_rate_type: BillingRateType
  lump_sum_billgins: LumpSumBillings
  open_billings: Billings
  retentions: CustomerRetentions
  statements: CustomerStatements
  service_billing_invoices: ServiceBillingInvoices
  addresses: Addresses
  address: Address
  contacts: Contacts
  district: District
  notes: Notes
}
export type Customers = Customer[]
export interface CustomerResults extends api.MetApiResults { data: Customers }
export interface CustomerResult extends api.MetApiResults { data: Customer }
export interface CustomerMetApiData extends api.MetApiData { data: Customer }
export interface CustomerResponse extends api.MetApiResponse { data: CustomerMetApiData }

export interface CustomerActivity {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  year: number
  amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  customer: Customer
}
export type CustomerActivities = CustomerActivity[]
export interface CustomerActivityResults extends api.MetApiResults { data: CustomerActivities }
export interface CustomerActivityResult extends api.MetApiResults { data: CustomerActivity }
export interface CustomerActivityMetApiData extends api.MetApiData { data: CustomerActivity }
export interface CustomerActivityResponse extends api.MetApiResponse { data: CustomerActivityMetApiData }

export interface CustomerBillingRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  billing_rate_type_id: string
  source_type: string
  source_id: string
  regular_rate: number
  overtime_rate: number
  premium_rate: number
  apply_to: string
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  billing_rate_type: BillingRateType
  customer: Customer
  source: CustomerBillingRate
}
export type CustomerBillingRates = CustomerBillingRate[]
export interface CustomerBillingRateResults extends api.MetApiResults { data: CustomerBillingRates }
export interface CustomerBillingRateResult extends api.MetApiResults { data: CustomerBillingRate }
export interface CustomerBillingRateMetApiData extends api.MetApiData { data: CustomerBillingRate }
export interface CustomerBillingRateResponse extends api.MetApiResponse { data: CustomerBillingRateMetApiData }

export interface CustomerRetention {
  // columns
  id: string
  customer_id: string
  job_id: string
  job_type_id: string
  line_item_id: string
  job_budget_id: string
  retention_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  customer: Customer
  job: Job
  job_budget: JobBudget
  job_type: JobType
  line_item: LineItem
}
export type CustomerRetentions = CustomerRetention[]
export interface CustomerRetentionResults extends api.MetApiResults { data: CustomerRetentions }
export interface CustomerRetentionResult extends api.MetApiResults { data: CustomerRetention }
export interface CustomerRetentionMetApiData extends api.MetApiData { data: CustomerRetention }
export interface CustomerRetentionResponse extends api.MetApiResponse { data: CustomerRetentionMetApiData }

export interface CustomerStatement {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  template: string
  date: Date
  customer_data: string[]|null
  total: string[]|null
  invoices: string[]|null
  sent_count: number
  sent_at: Date|null
  downloaded_at: Date|null
  downloaded_template: string|null
  pdf_paths: Record<string, unknown>|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  customer: Customer
  media: Media
}
export type CustomerStatements = CustomerStatement[]
export interface CustomerStatementResults extends api.MetApiResults { data: CustomerStatements }
export interface CustomerStatementResult extends api.MetApiResults { data: CustomerStatement }
export interface CustomerStatementMetApiData extends api.MetApiData { data: CustomerStatement }
export interface CustomerStatementResponse extends api.MetApiResponse { data: CustomerStatementMetApiData }

export interface District {
  // columns
  id: string
  organization_id: string
  company_id: string
  state: string
  code: string|null
  account: string|null
  subaccount: string|null
  description: string|null
  county: string|null
  sales_tax_percent: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
}
export type Districts = District[]
export interface DistrictResults extends api.MetApiResults { data: Districts }
export interface DistrictResult extends api.MetApiResults { data: District }
export interface DistrictMetApiData extends api.MetApiData { data: District }
export interface DistrictResponse extends api.MetApiResponse { data: DistrictMetApiData }

export interface LumpSumBilling {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  template: string
  number: string
  date: Date
  customer_data: string[]
  totals: string[]
  items: string[]
  sent_count: number
  sent_at: Date|null
  downloaded_at: Date|null
  pdf_paths: Record<string, unknown>|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  customer: Customer
  media: Media
}
export type LumpSumBillings = LumpSumBilling[]
export interface LumpSumBillingResults extends api.MetApiResults { data: LumpSumBillings }
export interface LumpSumBillingResult extends api.MetApiResults { data: LumpSumBilling }
export interface LumpSumBillingMetApiData extends api.MetApiData { data: LumpSumBilling }
export interface LumpSumBillingResponse extends api.MetApiResponse { data: LumpSumBillingMetApiData }

export interface AppTemplate {
  // columns
  id: string
  organization_id: string
  company_id: string
  key: string
  title: string|null
  html: string|null
  created_at: Date|null
  updated_at: Date|null
}
export type AppTemplates = AppTemplate[]
export interface AppTemplateResults extends api.MetApiResults { data: AppTemplates }
export interface AppTemplateResult extends api.MetApiResults { data: AppTemplate }
export interface AppTemplateMetApiData extends api.MetApiData { data: AppTemplate }
export interface AppTemplateResponse extends api.MetApiResponse { data: AppTemplateMetApiData }
export enum BankPositivePayAdditionalInfoField {
  Address1 = 'address_1',
  CheckMemo = 'check_memo',
  Contact = 'contact',
}
export enum BankPositivePayFormats {
  ARP = 'arp',
  BankOfAmerica = 'bofa',
  CIT_SIF = 'citsif',
  CRS = 'crs',
  CSV = 'csv',
  CSVAdditionalInfo = 'csvadd',
  FFB = 'ffb',
  JPMorganChase = 'jpm',
  MICASH = 'micash',
  NACHA = 'nacha',
  NACHA2 = 'nacha2',
  SiliconValleyBank = 'svb',
  WesternAllianceBank = 'wa',
  WellsFargoCSV = 'wf',
}

export interface Bank {
  // columns
  id: string
  organization_id: string
  company_id: string
  name: string
  account: string
  subaccount: string|null
  phone: string|null
  fax: string|null
  email: string|null
  last_ap_check_no: number
  last_ar_check_no: number
  last_pr_check_no: number
  last_ap_dd_no: number
  last_pr_dd_no: number
  top_margin: number
  left_margin: number
  used_in: string[]
  account_type: BankAccountType
  routing_number: number|null
  account_number: unknown|null
  is_for_dd: boolean
  is_for_micr: boolean
  is_for_positive_pay: boolean
  print_bank_info: boolean
  print_check_number: boolean
  print_company_info: boolean
  positive_pay_format: BankPositivePayFormats
  print_company_logo: boolean
  memo: string|null
  meta: {
    include_totals: boolean
    include_voided_checks: boolean
    additional_info_field: BankPositivePayAdditionalInfoField | null
  },
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  initials: string
  positive_pay_file_name: string
  // relations
  direct_deposit: BankDirectDeposit
  addresses: Addresses
  address: Address
}
export type Banks = Bank[]
export interface BankResults extends api.MetApiResults { data: Banks }
export interface BankResult extends api.MetApiResults { data: Bank }
export interface BankMetApiData extends api.MetApiData { data: Bank }
export interface BankResponse extends api.MetApiResponse { data: BankMetApiData }

export interface BankDirectDeposit {
  // columns
  id: string
  organization_id: string
  company_id: string
  bank_id: string
  scope: BankDirectDepositScope
  company_assigned_id: string
  company_discretionary_data: string|null
  origin_routing_number: string|null
  origin_format: string|null
  origin_char_option: string|null
  company_date_format: string|null
  company_abbr: string|null
  company_name: string|null
  bank_name: string|null
  destination_routing_number: string|null
  originating_dfi_id: string|null
  reference_code: string|null
  service_class_code: BankDirectDepositServiceClassCode
  standard_entry_class_code: BankDirectDepositStandardEntryCode
  transactions_description: string
  debit_bank_name: string|null
  debit_routing_number: string|null
  debit_account_type: BankAccountType|null
  debit_account_number: string|null
  use_crlf: boolean
  balanced_batch_file_required: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  bank: Bank
}
export type BankDirectDeposits = BankDirectDeposit[]
export interface BankDirectDepositResults extends api.MetApiResults { data: BankDirectDeposits }
export interface BankDirectDepositResult extends api.MetApiResults { data: BankDirectDeposit }
export interface BankDirectDepositMetApiData extends api.MetApiData { data: BankDirectDeposit }
export interface BankDirectDepositResponse extends api.MetApiResponse { data: BankDirectDepositMetApiData }

export interface Company {
  // columns
  id: string
  organization_id?: string
  gen_liability_state_id: string|null
  sui_sdi_state_id: string|null
  withholding_local_2_id: string|null
  withholding_local_id: string|null
  withholding_state_id: string|null
  workers_comp_state_id: string|null
  number: unknown
  name: string
  email: string|null
  phone: string|null
  fax: string|null
  abbr: string|null
  contractor_license: string|null
  naics_code: string|null
  active: boolean
  multiple_local_taxes: boolean
  multiple_state_taxes: boolean
  features: Record<string, unknown>
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  address: Address
  accounts: Accounts
  app_templates: AppTemplates
  banks: Banks
  benefit_codes: BenefitCodes
  roles: CompanyRoles
  contacts: Contacts
  customers: Customers
  deduction_codes: DeductionCodes
  invitations: Invitations
  employees: Employees
  fiscal_years: FiscalYears
  locations: Locations
  logins: CompanyLogins
  mail_templates: MailTemplates
  maintenance_codes: MaintenanceCodes
  organization_roles: OrganizationRoles
  pay_codes: PayCodes
  report_presets: ReportPresets
  settings: CompanySettings
  tax_codes: TaxCodes
  time_off_types: TimeOffTypes
  users: Users
  waiver_release_templates: WaiverReleaseTemplates
  addresses: Addresses
  equipment_types: EquipmentTypes
  jobs: Jobs
  job_types: JobTypes
  service_billing_types: ServiceBillingTypes
}
export type Companies = Company[]
export interface CompanyResults extends api.MetApiResults { data: Companies }
export interface CompanyResult extends api.MetApiResults { data: Company }
export interface CompanyMetApiData extends api.MetApiData { data: Company }
export interface CompanyResponse extends api.MetApiResponse { data: CompanyMetApiData }

export interface CompanyLogin {
  // columns
  id: string
  company_id: string
  user_id: string
  ip_address: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  user: User
  company: Company
}
export type CompanyLogins = CompanyLogin[]
export interface CompanyLoginResults extends api.MetApiResults { data: CompanyLogins }
export interface CompanyLoginResult extends api.MetApiResults { data: CompanyLogin }
export interface CompanyLoginMetApiData extends api.MetApiData { data: CompanyLogin }
export interface CompanyLoginResponse extends api.MetApiResponse { data: CompanyLoginMetApiData }

export interface CompanyModel {
  // relations
  company: Company
}
export type CompanyModels = CompanyModel[]
export interface CompanyModelResults extends api.MetApiResults { data: CompanyModels }
export interface CompanyModelResult extends api.MetApiResults { data: CompanyModel }
export interface CompanyModelMetApiData extends api.MetApiData { data: CompanyModel }
export interface CompanyModelResponse extends api.MetApiResponse { data: CompanyModelMetApiData }

export interface CompanyUser {
  // columns
  organization_id: string
  company_id: string
  user_id: string
  created_at: Date|null
  updated_at: Date|null
}
export type CompanyUsers = CompanyUser[]
export interface CompanyUserResults extends api.MetApiResults { data: CompanyUsers }
export interface CompanyUserResult extends api.MetApiResults { data: CompanyUser }
export interface CompanyUserMetApiData extends api.MetApiData { data: CompanyUser }
export interface CompanyUserResponse extends api.MetApiResponse { data: CompanyUserMetApiData }

export interface Contact {
  // columns
  id: string
  organization_id: string
  company_id: string
  contactable_type: string
  contactable_id: string
  name: string
  email: string|null
  title: string|null
  phone: string|null
  address: string|null
  notes: string|null
  dob: Date|null
  type: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  contactable: Contact
}
export type Contacts = Contact[]
export interface ContactResults extends api.MetApiResults { data: Contacts }
export interface ContactResult extends api.MetApiResults { data: Contact }
export interface ContactMetApiData extends api.MetApiData { data: Contact }
export interface ContactResponse extends api.MetApiResponse { data: ContactMetApiData }

export interface MailTemplate {
  // columns
  id: string
  organization_id: string
  company_id: string
  key: string
  from_address: string|null
  from_name: string|null
  subject: string|null
  html: string|null
  created_at: Date|null
  updated_at: Date|null
}
export type MailTemplates = MailTemplate[]
export interface MailTemplateResults extends api.MetApiResults { data: MailTemplates }
export interface MailTemplateResult extends api.MetApiResults { data: MailTemplate }
export interface MailTemplateMetApiData extends api.MetApiData { data: MailTemplate }
export interface MailTemplateResponse extends api.MetApiResponse { data: MailTemplateMetApiData }

export interface Media {
  // columns
  id: string
  organization_id: string
  company_id: string
  model_type: string
  model_id: string
  uuid: string|null
  collection_name: string
  name: string
  file_name: string
  mime_type: string|null
  disk: string
  conversions_disk: string|null
  size: number
  order_column: number|null
  manipulations: string[]
  custom_properties: string[]
  generated_conversions: string[]
  responsive_images: string[]
  created_at: Date|null
  updated_at: Date|null
  // relations
  company: Company
  tenant: Organization
}
export type Media = Media[]
export interface MediaResults extends api.MetApiResults { data: Media }
export interface MediaResult extends api.MetApiResults { data: Media }
export interface MediaMetApiData extends api.MetApiData { data: Media }
export interface MediaResponse extends api.MetApiResponse { data: MediaMetApiData }

export interface Note {
  // columns
  id: string
  notable_type: string
  notable_id: string
  title: string
  content: string|null
  created_by: string|null
  updated_by: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  notable: Note
  creator: User
  editor: User
}
export type Notes = Note[]
export interface NoteResults extends api.MetApiResults { data: Notes }
export interface NoteResult extends api.MetApiResults { data: Note }
export interface NoteMetApiData extends api.MetApiData { data: Note }
export interface NoteResponse extends api.MetApiResponse { data: NoteMetApiData }

export interface ReportPreset {
  // columns
  id: string
  organization_id: string
  company_id: string
  user_id: string
  name: string
  filters: string[]
  report_type: string
  public: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  user: User
}
export type ReportPresets = ReportPreset[]
export interface ReportPresetResults extends api.MetApiResults { data: ReportPresets }
export interface ReportPresetResult extends api.MetApiResults { data: ReportPreset }
export interface ReportPresetMetApiData extends api.MetApiData { data: ReportPreset }
export interface ReportPresetResponse extends api.MetApiResponse { data: ReportPresetMetApiData }

export interface WaiverReleaseTemplate {
  // columns
  id: string
  organization_id: string
  company_id: string
  key: string
  module: WaiverReleaseModule
  state: string|null
  title: string|null
  html: string|null
  created_at: Date|null
  updated_at: Date|null
}
export type WaiverReleaseTemplates = WaiverReleaseTemplate[]
export interface WaiverReleaseTemplateResults extends api.MetApiResults { data: WaiverReleaseTemplates }
export interface WaiverReleaseTemplateResult extends api.MetApiResults { data: WaiverReleaseTemplate }
export interface WaiverReleaseTemplateMetApiData extends api.MetApiData { data: WaiverReleaseTemplate }
export interface WaiverReleaseTemplateResponse extends api.MetApiResponse { data: WaiverReleaseTemplateMetApiData }

export interface Equipment {
  // columns
  id: string
  organization_id: string
  company_id: string
  location_id: string
  class_id: string|null
  specialty_id: string|null
  last_job_id: string|null
  status: EquipmentStatus
  code: string
  description: string|null
  year_and_model: string|null
  serial_no: string|null
  license_no: string|null
  life: number
  actual_disposal_proceeds_amount: number
  standard_job_cost_hourly_rate: number
  standard_estimating_hourly_rate: number
  current_market_value: number
  gallons_to_date: number
  acquisition_cost: number
  depreciation_amount: number
  repairs_and_maintenance_to_date: number
  fuel_used_to_date: number
  utilization_hours_job_costed: number
  utilization_hours_customer_billed: number
  utilization_amount_job_costed: number
  utilization_amount_customer_billed: number
  mileage: number
  maintenance_hours_to_date: number
  user_defined_cost_types: string[]
  on_balance_sheet: boolean
  is_depreciation_posted: boolean
  is_equipment_cost_transferred: boolean
  asset_type: EquipmentAssetType|null
  disposition: EquipmentDisposition|null
  maintenance_interval: string|null
  depreciation_method: string
  date_acquired: Date|null
  disposal_date: Date|null
  last_depreciation_date: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  billing_rates: BillingRates
  class: EquipmentClass
  depreciations: EquipmentDepreciations
  last_job: Job
  specialty: EquipmentSpecialty
  location: Location
}
export type Equipment = Equipment[]
export interface EquipmentResults extends api.MetApiResults { data: Equipment }
export interface EquipmentResult extends api.MetApiResults { data: Equipment }
export interface EquipmentMetApiData extends api.MetApiData { data: Equipment }
export interface EquipmentResponse extends api.MetApiResponse { data: EquipmentMetApiData }

export interface EquipmentClass {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  disposal_account: string|null
  disposal_subaccount: string|null
  calculated_depreciation_account: string|null
  calculated_depreciation_subaccount: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  types: EquipmentTypes
}
export type EquipmentClasses = EquipmentClass[]
export interface EquipmentClassResults extends api.MetApiResults { data: EquipmentClasses }
export interface EquipmentClassResult extends api.MetApiResults { data: EquipmentClass }
export interface EquipmentClassMetApiData extends api.MetApiData { data: EquipmentClass }
export interface EquipmentClassResponse extends api.MetApiResponse { data: EquipmentClassMetApiData }

export interface EquipmentClassType {
  // columns
  id: string
  type_id: string
  class_id: string
  credit_account: string|null
  credit_subaccount: string|null
  debit_account: string|null
  debit_subaccount: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  class: EquipmentClass
  type: EquipmentType
}
export type EquipmentClassTypes = EquipmentClassType[]
export interface EquipmentClassTypeResults extends api.MetApiResults { data: EquipmentClassTypes }
export interface EquipmentClassTypeResult extends api.MetApiResults { data: EquipmentClassType }
export interface EquipmentClassTypeMetApiData extends api.MetApiData { data: EquipmentClassType }
export interface EquipmentClassTypeResponse extends api.MetApiResponse { data: EquipmentClassTypeMetApiData }

export interface EquipmentDepreciation {
  // columns
  id: string
  organization_id: string
  company_id: string
  equipment_id: string
  created_by: string|null
  posted_by: string|null
  status: EquipmentDepreciationStatus
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // mutators
  accounts: unknown
  // relations
  equipment: Equipment
}
export type EquipmentDepreciations = EquipmentDepreciation[]
export interface EquipmentDepreciationResults extends api.MetApiResults { data: EquipmentDepreciations }
export interface EquipmentDepreciationResult extends api.MetApiResults { data: EquipmentDepreciation }
export interface EquipmentDepreciationMetApiData extends api.MetApiData { data: EquipmentDepreciation }
export interface EquipmentDepreciationResponse extends api.MetApiResponse { data: EquipmentDepreciationMetApiData }

export interface EquipmentMaintenance {
  // columns
  id: string
  organization_id: string
  company_id: string
  equipment_id: string
  maintenance_code_id: string
  class_id: string|null
  description: string|null
  date: Date|null
  amount: number
  due_date: Date|null
  due_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  class: EquipmentClass
  maintenance_code: MaintenanceCode
  equipment: Equipment
}
export type EquipmentMaintenances = EquipmentMaintenance[]
export interface EquipmentMaintenanceResults extends api.MetApiResults { data: EquipmentMaintenances }
export interface EquipmentMaintenanceResult extends api.MetApiResults { data: EquipmentMaintenance }
export interface EquipmentMaintenanceMetApiData extends api.MetApiData { data: EquipmentMaintenance }
export interface EquipmentMaintenanceResponse extends api.MetApiResponse { data: EquipmentMaintenanceMetApiData }

export interface EquipmentSpecialty {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
}
export type EquipmentSpecialties = EquipmentSpecialty[]
export interface EquipmentSpecialtyResults extends api.MetApiResults { data: EquipmentSpecialties }
export interface EquipmentSpecialtyResult extends api.MetApiResults { data: EquipmentSpecialty }
export interface EquipmentSpecialtyMetApiData extends api.MetApiData { data: EquipmentSpecialty }
export interface EquipmentSpecialtyResponse extends api.MetApiResponse { data: EquipmentSpecialtyMetApiData }

export interface EquipmentType {
  // columns
  id: string
  organization_id: string
  company_id: string
  type: string
  abbr: string
  name: string|null
  credit_account: string|null
  credit_subaccount: string|null
  debit_account: string|null
  debit_subaccount: string|null
  index: number
  readonly: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
}
export type EquipmentTypes = EquipmentType[]
export interface EquipmentTypeResults extends api.MetApiResults { data: EquipmentTypes }
export interface EquipmentTypeResult extends api.MetApiResults { data: EquipmentType }
export interface EquipmentTypeMetApiData extends api.MetApiData { data: EquipmentType }
export interface EquipmentTypeResponse extends api.MetApiResponse { data: EquipmentTypeMetApiData }

export interface EquipmentUtilizationHistory {
  // columns
  id: string
  organization_id: string
  company_id: string
  journal_id: string
  equipment_id: string
  job_id: string|null
  service_billing_code_id: string|null
  type_id: string|null
  class_id: string|null
  source_type: string|null
  source_id: string|null
  utilization_type: string|null
  utilization_id: string|null
  type: string
  fiscal_year: Date
  payroll_period_end_date: Date
  period: boolean
  billing_type: boolean|null
  hours: number
  amount: number
  account: string|null
  subaccount: string|null
  description: string|null
  invoice_or_reference: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  equipment: Equipment
}
export type EquipmentUtilizationHistories = EquipmentUtilizationHistory[]
export interface EquipmentUtilizationHistoryResults extends api.MetApiResults { data: EquipmentUtilizationHistories }
export interface EquipmentUtilizationHistoryResult extends api.MetApiResults { data: EquipmentUtilizationHistory }
export interface EquipmentUtilizationHistoryMetApiData extends api.MetApiData { data: EquipmentUtilizationHistory }
export interface EquipmentUtilizationHistoryResponse extends api.MetApiResponse { data: EquipmentUtilizationHistoryMetApiData }

export interface MaintenanceCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  interval: string
  interval_months: number
  interval_value: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
}
export type MaintenanceCodes = MaintenanceCode[]
export interface MaintenanceCodeResults extends api.MetApiResults { data: MaintenanceCodes }
export interface MaintenanceCodeResult extends api.MetApiResults { data: MaintenanceCode }
export interface MaintenanceCodeMetApiData extends api.MetApiData { data: MaintenanceCode }
export interface MaintenanceCodeResponse extends api.MetApiResponse { data: MaintenanceCodeMetApiData }

export interface Bid {
  // columns
  id: string
  organization_id: string
  company_id: string
  crew_labor_rate_id: string
  number: string
  description: string|null
  estimator: string|null
  notes: string[]|null
  labor_area: unknown
  compute_bond: boolean
  date: Date|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  crew_labor: CrewLaborRate
  job_types: JobTypes
  work_items: WorkItems
}
export type Bids = Bid[]
export interface BidResults extends api.MetApiResults { data: Bids }
export interface BidResult extends api.MetApiResults { data: Bid }
export interface BidMetApiData extends api.MetApiData { data: Bid }
export interface BidResponse extends api.MetApiResponse { data: BidMetApiData }

export interface BidItem {
  // columns
  id: string
  organization_id: string
  company_id: string
  bid_id: string
  code: string
  description: string|null
  status: string
  type: string
  created_at: Date|null
  updated_at: Date|null
  // relations
  bid: Bid
  job_types: JobTypes
}
export type BidItems = BidItem[]
export interface BidItemResults extends api.MetApiResults { data: BidItems }
export interface BidItemResult extends api.MetApiResults { data: BidItem }
export interface BidItemMetApiData extends api.MetApiData { data: BidItem }
export interface BidItemResponse extends api.MetApiResponse { data: BidItemMetApiData }

export interface BidJobType {
  // columns
  job_type_id: string
  bid_id: string
  created_at: Date|null
  updated_at: Date|null
  // relations
  bid: Bid
  job_type: JobType
}
export type BidJobTypes = BidJobType[]
export interface BidJobTypeResults extends api.MetApiResults { data: BidJobTypes }
export interface BidJobTypeResult extends api.MetApiResults { data: BidJobType }
export interface BidJobTypeMetApiData extends api.MetApiData { data: BidJobType }
export interface BidJobTypeResponse extends api.MetApiResponse { data: BidJobTypeMetApiData }

export interface CrewLaborRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  descriptions: string[]|null
  direct_1: number
  direct_2: number
  direct_3: number
  direct_4: number
  direct_5: number
  direct_6: number
  direct_7: number
  direct_8: number
  direct_9: number
  direct_10: number
  burden_1: number
  burden_2: number
  burden_3: number
  burden_4: number
  burden_5: number
  burden_6: number
  burden_7: number
  burden_8: number
  burden_9: number
  burden_10: number
  created_at: Date|null
  updated_at: Date|null
}
export type CrewLaborRates = CrewLaborRate[]
export interface CrewLaborRateResults extends api.MetApiResults { data: CrewLaborRates }
export interface CrewLaborRateResult extends api.MetApiResults { data: CrewLaborRate }
export interface CrewLaborRateMetApiData extends api.MetApiData { data: CrewLaborRate }
export interface CrewLaborRateResponse extends api.MetApiResponse { data: CrewLaborRateMetApiData }

export interface EstimateFormula {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  formula: string
  um_x: string|null
  um_y: string|null
  um_z: string|null
  created_at: Date|null
  updated_at: Date|null
}
export type EstimateFormulas = EstimateFormula[]
export interface EstimateFormulaResults extends api.MetApiResults { data: EstimateFormulas }
export interface EstimateFormulaResult extends api.MetApiResults { data: EstimateFormula }
export interface EstimateFormulaMetApiData extends api.MetApiData { data: EstimateFormula }
export interface EstimateFormulaResponse extends api.MetApiResponse { data: EstimateFormulaMetApiData }

export interface WorkItem {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_type_id: string|null
  formula_id: string|null
  material_id: string|null
  equipment_id: string|null
  crew_labor_rate_id: string|null
  bid_id: string|null
  code: string
  descriptions: string[]|null
  um: string|null
  sort_group: string|null
  um_conversion_factor: number
  unit_value: number
  equipment_rate: number
  crew_labor_rate: number
  equipment_priced_by: string
  crew_labor_priced_by: string
  created_at: Date|null
  updated_at: Date|null
  // relations
  bid: Bid
  formula: EstimateFormula
  material: Material
  crew_labor_rate: CrewLaborRate
  job_type: JobType
}
export type WorkItems = WorkItem[]
export interface WorkItemResults extends api.MetApiResults { data: WorkItems }
export interface WorkItemResult extends api.MetApiResults { data: WorkItem }
export interface WorkItemMetApiData extends api.MetApiData { data: WorkItem }
export interface WorkItemResponse extends api.MetApiResponse { data: WorkItemMetApiData }

export interface Account {
  // columns
  id: string
  organization_id: string
  company_id: string
  restriction_type_id: string|null
  fiscal_year: number
  number: string
  description: string|null
  p0_amount: number
  p1_amount: number
  p2_amount: number
  p3_amount: number
  p4_amount: number
  p5_amount: number
  p6_amount: number
  p7_amount: number
  p8_amount: number
  p9_amount: number
  p10_amount: number
  p11_amount: number
  p12_amount: number
  p13_amount: number
  type: string
  restrictions: string
  active: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  total_amount: number
  // relations
  budgets: Budgets
  subaccount_data: SubaccountDatas
  transactions: Transactions
}
export type Accounts = Account[]
export interface AccountResults extends api.MetApiResults { data: Accounts }
export interface AccountResult extends api.MetApiResults { data: Account }
export interface AccountMetApiData extends api.MetApiData { data: Account }
export interface AccountResponse extends api.MetApiResponse { data: AccountMetApiData }

export interface Budget {
  // columns
  id: string
  organization_id: string
  company_id: string
  account_id: string
  subaccount_id: string|null
  fiscal_year: Date
  amount: number
  spread_uniformly: boolean
  p1_amount: number
  p2_amount: number
  p3_amount: number
  p4_amount: number
  p5_amount: number
  p6_amount: number
  p7_amount: number
  p8_amount: number
  p9_amount: number
  p10_amount: number
  p11_amount: number
  p12_amount: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  subaccount: Subaccount
  account: Account
}
export type Budgets = Budget[]
export interface BudgetResults extends api.MetApiResults { data: Budgets }
export interface BudgetResult extends api.MetApiResults { data: Budget }
export interface BudgetMetApiData extends api.MetApiData { data: Budget }
export interface BudgetResponse extends api.MetApiResponse { data: BudgetMetApiData }

export interface FinancialReport {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  type: FinancialReportType
  description: string|null
  skip_rows_with_zero: boolean
  print_date_time: boolean
  accounts: unknown[]
  cols: unknown[]
  rows: unknown[]
  created_at: Date|null
  updated_at: Date|null
}
export type FinancialReports = FinancialReport[]
export interface FinancialReportResults extends api.MetApiResults { data: FinancialReports }
export interface FinancialReportResult extends api.MetApiResults { data: FinancialReport }
export interface FinancialReportMetApiData extends api.MetApiData { data: FinancialReport }
export interface FinancialReportResponse extends api.MetApiResponse { data: FinancialReportMetApiData }

export interface FiscalYear {
  // columns
  id: string
  organization_id: string
  company_id: string
  closed_by: string|null
  fiscal_year: number
  first_month: string
  balances_transferred: boolean
  periods: Record<string, unknown>
  is_used: boolean
  closed_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  closed_by: User
}
export type FiscalYears = FiscalYear[]
export interface FiscalYearResults extends api.MetApiResults { data: FiscalYears }
export interface FiscalYearResult extends api.MetApiResults { data: FiscalYear }
export interface FiscalYearMetApiData extends api.MetApiData { data: FiscalYear }
export interface FiscalYearResponse extends api.MetApiResponse { data: FiscalYearMetApiData }

export interface Journal {
  // columns
  id: string
  organization_id: string
  company_id: string
  parent_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  name: string
  description: string|null
  code: string
  transaction_type: TransactionType
  number: number|null
  fiscal_year: number
  period: number
  fiscal_year_period: string|null
  reference_date: Date
  type: string
  status: string
  action: JournalAction
  posted_at: Date|null
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // mutators
  total_credit: number
  total_debit: number
  // relations
  entries: JournalEntries
  records: JournalRecords
  transactions: Transactions
  poster: User
  creator: User
  editor: User
}
export type Journals = Journal[]
export interface JournalResults extends api.MetApiResults { data: Journals }
export interface JournalResult extends api.MetApiResults { data: Journal }
export interface JournalMetApiData extends api.MetApiData { data: Journal }
export interface JournalResponse extends api.MetApiResponse { data: JournalMetApiData }

export interface JournalEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  journal_id: string
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  special_source_type: string|null
  special_source_id: string|null
  cost_center: CostCenter
  transaction_type: TransactionType
  account: string|null
  subaccount: string|null
  description: string|null
  reference_no: string|null
  reference_date: Date|null
  meta: Record<string, unknown>
  order: number
  debit_amount: number
  credit_amount: number
  post_to_gl: boolean
  reverse_next_period: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  subaccount: Subaccount
  account: Account
  journal: Journal
  addl_source: JournalEntry
  source: JournalEntry
  special_source: JournalEntry
  type: JournalEntry
}
export type JournalEntries = JournalEntry[]
export interface JournalEntryResults extends api.MetApiResults { data: JournalEntries }
export interface JournalEntryResult extends api.MetApiResults { data: JournalEntry }
export interface JournalEntryMetApiData extends api.MetApiData { data: JournalEntry }
export interface JournalEntryResponse extends api.MetApiResponse { data: JournalEntryMetApiData }

export interface JournalRecord {
  // columns
  id: string
  organization_id: string
  company_id: string
  journal_id: string
  source_type: string
  source_id: string
  created_at: Date
  // relations
  journal: Journal
  source: JournalRecord
}
export type JournalRecords = JournalRecord[]
export interface JournalRecordResults extends api.MetApiResults { data: JournalRecords }
export interface JournalRecordResult extends api.MetApiResults { data: JournalRecord }
export interface JournalRecordMetApiData extends api.MetApiData { data: JournalRecord }
export interface JournalRecordResponse extends api.MetApiResponse { data: JournalRecordMetApiData }

export interface Reconciliation {
  // columns
  id: string
  organization_id: string
  company_id: string
  account: string
  fiscal_year: number
  period: number
  bank_balance_amount: number
  date: Date
  status: ReconciliationStatus
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  entries: ReconciliationEntries
  media: Media
}
export type Reconciliations = Reconciliation[]
export interface ReconciliationResults extends api.MetApiResults { data: Reconciliations }
export interface ReconciliationResult extends api.MetApiResults { data: Reconciliation }
export interface ReconciliationMetApiData extends api.MetApiData { data: Reconciliation }
export interface ReconciliationResponse extends api.MetApiResponse { data: ReconciliationMetApiData }

export interface ReconciliationEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  reconciliation_id: string
  transaction_id: string
  per_bank_amount: number
  outstanding_amount: number
  status: TransactionStatus
  created_at: Date|null
  updated_at: Date|null
  // relations
  reconciliation: Reconciliation
  transaction: Transaction
}
export type ReconciliationEntries = ReconciliationEntry[]
export interface ReconciliationEntryResults extends api.MetApiResults { data: ReconciliationEntries }
export interface ReconciliationEntryResult extends api.MetApiResults { data: ReconciliationEntry }
export interface ReconciliationEntryMetApiData extends api.MetApiData { data: ReconciliationEntry }
export interface ReconciliationEntryResponse extends api.MetApiResponse { data: ReconciliationEntryMetApiData }

export interface Subaccount {
  // columns
  id: string
  organization_id: string
  company_id: string
  fiscal_year: number
  number: string
  description: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  budgets: Budgets
  subaccount_data: SubaccountDatas
  transactions: Transactions
}
export type Subaccounts = Subaccount[]
export interface SubaccountResults extends api.MetApiResults { data: Subaccounts }
export interface SubaccountResult extends api.MetApiResults { data: Subaccount }
export interface SubaccountMetApiData extends api.MetApiData { data: Subaccount }
export interface SubaccountResponse extends api.MetApiResponse { data: SubaccountMetApiData }

export interface SubaccountData {
  // columns
  id: string
  organization_id: string
  company_id: string
  account_id: string
  subaccount_id: string
  p0_amount: number
  p1_amount: number
  p2_amount: number
  p3_amount: number
  p4_amount: number
  p5_amount: number
  p6_amount: number
  p7_amount: number
  p8_amount: number
  p9_amount: number
  p10_amount: number
  p11_amount: number
  p12_amount: number
  p13_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  account: Account
  subaccount: Subaccount
}
export type SubaccountDatas = SubaccountData[]
export interface SubaccountDataResults extends api.MetApiResults { data: SubaccountDatas }
export interface SubaccountDataResult extends api.MetApiResults { data: SubaccountData }
export interface SubaccountDataMetApiData extends api.MetApiData { data: SubaccountData }
export interface SubaccountDataResponse extends api.MetApiResponse { data: SubaccountDataMetApiData }

export interface Transaction {
  // columns
  id: string
  organization_id: string
  company_id: string
  account_id: string|null
  subaccount_id: string|null
  journal_id: string|null
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  business_type: string|null
  business_id: string|null
  category: TransactionCategory
  cost_center: CostCenter
  transaction_type: TransactionType
  fiscal_year: number
  period: number
  fiscal_year_period: string|null
  amount: number
  per_bank_amount: number
  reference_date: Date|null
  reference_no: string|null
  description: string|null
  status: TransactionStatus
  cost_plus_status: string
  meta: Record<string, unknown>
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  posted_at: Date|null
  cost_plus_billed_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // mutators
  credit_amount: number
  debit_amount: number
  outstanding_amount: number
  // relations
  reconciliation_entry: ReconciliationEntry
  subaccount: Subaccount
  account: Account
  journal: Journal
  poster: User
  business: Transaction
  addl_source: Transaction
  source: Transaction
  type: Transaction
  creator: User
  editor: User
}
export type Transactions = Transaction[]
export interface TransactionResults extends api.MetApiResults { data: Transactions }
export interface TransactionResult extends api.MetApiResults { data: Transaction }
export interface TransactionMetApiData extends api.MetApiData { data: Transaction }
export interface TransactionResponse extends api.MetApiResponse { data: TransactionMetApiData }

export interface TransferCount {
  // columns
  id: string
  organization_id: string
  company_id: string
  name: string
  count: number
  created_at: Date|null
  updated_at: Date|null
}
export type TransferCounts = TransferCount[]
export interface TransferCountResults extends api.MetApiResults { data: TransferCounts }
export interface TransferCountResult extends api.MetApiResults { data: TransferCount }
export interface TransferCountMetApiData extends api.MetApiData { data: TransferCount }
export interface TransferCountResponse extends api.MetApiResponse { data: TransferCountMetApiData }

export interface Address {
  // columns
  id: string
  organization_id: string
  company_id: string|null
  addressable_type: string
  addressable_id: string
  type: string
  primary: boolean
  name: string
  description: string|null
  contact: string|null
  email: string|null
  phone: string|null
  fax: string|null
  address_1: string
  address_2: string|null
  city: string
  state: string
  postal_code: string
  country: string
  meta: Record<string, unknown>
  active: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  addressable: Address
  actions: ActionEvents
}
export type Addresses = Address[]
export interface AddressResults extends api.MetApiResults { data: Addresses }
export interface AddressResult extends api.MetApiResults { data: Address }
export interface AddressMetApiData extends api.MetApiData { data: Address }
export interface AddressResponse extends api.MetApiResponse { data: AddressMetApiData }

export interface AppModel {
}
export type AppModels = AppModel[]
export interface AppModelResults extends api.MetApiResults { data: AppModels }
export interface AppModelResult extends api.MetApiResults { data: AppModel }
export interface AppModelMetApiData extends api.MetApiData { data: AppModel }
export interface AppModelResponse extends api.MetApiResponse { data: AppModelMetApiData }

export interface BillingRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  billing_rate_type_id: string
  source_type: string
  source_id: string
  regular_rate: number
  overtime_rate: number
  premium_rate: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  billing_rate_type: BillingRateType
  source: BillingRate
}
export type BillingRates = BillingRate[]
export interface BillingRateResults extends api.MetApiResults { data: BillingRates }
export interface BillingRateResult extends api.MetApiResults { data: BillingRate }
export interface BillingRateMetApiData extends api.MetApiData { data: BillingRate }
export interface BillingRateResponse extends api.MetApiResponse { data: BillingRateMetApiData }

export interface BillingRateType {
  // columns
  id: string
  organization_id: string
  company_id: string
  title: string
  created_at: Date|null
  updated_at: Date|null
  // relations
  billing_rates: BillingRates
}
export type BillingRateTypes = BillingRateType[]
export interface BillingRateTypeResults extends api.MetApiResults { data: BillingRateTypes }
export interface BillingRateTypeResult extends api.MetApiResults { data: BillingRateType }
export interface BillingRateTypeMetApiData extends api.MetApiData { data: BillingRateType }
export interface BillingRateTypeResponse extends api.MetApiResponse { data: BillingRateTypeMetApiData }

export interface CompanyRole {
  // columns
  id: string
  organization_id: string
  company_id: string
  name: string
  permissions: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  users: Users
}
export type CompanyRoles = CompanyRole[]
export interface CompanyRoleResults extends api.MetApiResults { data: CompanyRoles }
export interface CompanyRoleResult extends api.MetApiResults { data: CompanyRole }
export interface CompanyRoleMetApiData extends api.MetApiData { data: CompanyRole }
export interface CompanyRoleResponse extends api.MetApiResponse { data: CompanyRoleMetApiData }

export interface Dashboard {
  // columns
  id: string
  organization_id: string
  company_id: string
  name: string
  description: string|null
  icon: string|null
  main: boolean
  cost_center: string
  widgets: Record<string, unknown>
  created_by: string|null
  updated_by: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  creator: User
  editor: User
}
export type Dashboards = Dashboard[]
export interface DashboardResults extends api.MetApiResults { data: Dashboards }
export interface DashboardResult extends api.MetApiResults { data: Dashboard }
export interface DashboardMetApiData extends api.MetApiData { data: Dashboard }
export interface DashboardResponse extends api.MetApiResponse { data: DashboardMetApiData }

export interface FederalTax {
  // columns
  id: string
  code: string
  type: string
  status: string
  year: number
  low_income_exemption_limit_amount: number
  standard_deduction_amount: number
  tax_credit_allowance_amount: number
  earning_deduction_amount: number
  max_fit_deduction_amount: number
  max_fica_deduction_amount: number
  brackets: Record<string, unknown>
  start_date: Date
  end_date: Date|null
  active: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  is_active: boolean
}
export type FederalTaxes = FederalTax[]
export interface FederalTaxResults extends api.MetApiResults { data: FederalTaxes }
export interface FederalTaxResult extends api.MetApiResults { data: FederalTax }
export interface FederalTaxMetApiData extends api.MetApiData { data: FederalTax }
export interface FederalTaxResponse extends api.MetApiResponse { data: FederalTaxMetApiData }

export interface HasStatesAndStatuses {
}
export type HasStatesAndStatuses = HasStatesAndStatuses[]
export interface HasStatesAndStatusesResults extends api.MetApiResults { data: HasStatesAndStatuses }
export interface HasStatesAndStatusesResult extends api.MetApiResults { data: HasStatesAndStatuses }
export interface HasStatesAndStatusesMetApiData extends api.MetApiData { data: HasStatesAndStatuses }
export interface HasStatesAndStatusesResponse extends api.MetApiResponse { data: HasStatesAndStatusesMetApiData }

export interface ImpersonateLog {
  // columns
  id: number
  organization_id: string
  nova_user_id: string
  user_id: string
  created_at: Date
  // relations
  nova_user: NovaUser
  organization: Organization
  user: User
}
export type ImpersonateLogs = ImpersonateLog[]
export interface ImpersonateLogResults extends api.MetApiResults { data: ImpersonateLogs }
export interface ImpersonateLogResult extends api.MetApiResults { data: ImpersonateLog }
export interface ImpersonateLogMetApiData extends api.MetApiData { data: ImpersonateLog }
export interface ImpersonateLogResponse extends api.MetApiResponse { data: ImpersonateLogMetApiData }

export interface Invitation {
  // columns
  id: string
  organization_id: string
  company_id: string
  email: string
  name: string|null
  organization_roles: string[]
  company_roles: string[]
  token: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  notifications: DatabaseNotifications
}
export type Invitations = Invitation[]
export interface InvitationResults extends api.MetApiResults { data: Invitations }
export interface InvitationResult extends api.MetApiResults { data: Invitation }
export interface InvitationMetApiData extends api.MetApiData { data: Invitation }
export interface InvitationResponse extends api.MetApiResponse { data: InvitationMetApiData }

export interface Location {
  // columns
  id: string
  organization_id: string
  company_id: string
  name: string
  description: string|null
  contact: string|null
  email: string|null
  phone: string|null
  fax: string|null
  address_1: string
  address_2: string|null
  city: string
  state: string
  postal_code: string
  country: string
  is_default_for_eq: boolean
  is_default_for_iv: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  equipment: Equipment
  materials: Materials
}
export type Locations = Location[]
export interface LocationResults extends api.MetApiResults { data: Locations }
export interface LocationResult extends api.MetApiResults { data: Location }
export interface LocationMetApiData extends api.MetApiData { data: Location }
export interface LocationResponse extends api.MetApiResponse { data: LocationMetApiData }

export interface Login {
  // columns
  id: string
  organization_id: string
  user_id: string
  ip_address: string|null
  created_at: Date|null
  updated_at: Date|null
}
export type Logins = Login[]
export interface LoginResults extends api.MetApiResults { data: Logins }
export interface LoginResult extends api.MetApiResults { data: Login }
export interface LoginMetApiData extends api.MetApiData { data: Login }
export interface LoginResponse extends api.MetApiResponse { data: LoginMetApiData }

export interface OrganizationRole {
  // columns
  id: string
  organization_id: string
  name: string
  permissions: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  users: Users
}
export type OrganizationRoles = OrganizationRole[]
export interface OrganizationRoleResults extends api.MetApiResults { data: OrganizationRoles }
export interface OrganizationRoleResult extends api.MetApiResults { data: OrganizationRole }
export interface OrganizationRoleMetApiData extends api.MetApiData { data: OrganizationRole }
export interface OrganizationRoleResponse extends api.MetApiResponse { data: OrganizationRoleMetApiData }

export interface StateTax {
  // columns
  id: string
  code: string
  state: string
  year: number
  type: StateTaxType
  status: string
  wage_limit: boolean
  low_income_exemption_limit_amount: number
  standard_deduction_amount: number
  tax_credit_allowance_amount: number
  earning_deduction_amount: number
  brackets: Record<string, unknown>
  start_date: Date
  end_date: Date|null
  active: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  is_active: boolean
  // relations
  jurisdiction: StateTaxJurisdiction
}
export type StateTaxes = StateTax[]
export interface StateTaxResults extends api.MetApiResults { data: StateTaxes }
export interface StateTaxResult extends api.MetApiResults { data: StateTax }
export interface StateTaxMetApiData extends api.MetApiData { data: StateTax }
export interface StateTaxResponse extends api.MetApiResponse { data: StateTaxMetApiData }

export interface User {
  // columns
  id: string
  current_organization_id: string|null
  current_company_id: string|null
  email: string
  password?: string|null
  name: string|null
  avatar: string|null
  phone: string|null
  email_verified_at: Date|null
  remember_token?: string|null
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  employee: Employee
  last_login: Login
  report_presets: ReportPresets
  companies: Companies
  current_organization_companies: Companies
  current_company: Company
  current_organization: Organization
  organizations: Organizations
  tokens: PersonalAccessTokens
  roles: CompanyRoles
  company_roles: CompanyRoles
  organization_roles: OrganizationRoles
  notifications: DatabaseNotifications
}
export type Users = User[]
export interface UserResults extends api.MetApiResults { data: Users }
export interface UserResult extends api.MetApiResults { data: User }
export interface UserMetApiData extends api.MetApiData { data: User }
export interface UserResponse extends api.MetApiResponse { data: UserMetApiData }

export interface WikiArticle {
  // columns
  id: string
  created_by: string|null
  updated_by: string|null
  category: string|null
  title: string|null
  content: string
  draft: boolean
  published_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  creator: User
  editor: User
}
export type WikiArticles = WikiArticle[]
export interface WikiArticleResults extends api.MetApiResults { data: WikiArticles }
export interface WikiArticleResult extends api.MetApiResults { data: WikiArticle }
export interface WikiArticleMetApiData extends api.MetApiData { data: WikiArticle }
export interface WikiArticleResponse extends api.MetApiResponse { data: WikiArticleMetApiData }

export interface COGSTransfer {
  // columns
  id: string
  organization_id: string
  company_id: string
  inventory_batch_id: string
  material_id: string
  customer_id: string|null
  district_id: string|null
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  cost_center: CostCenter
  um: string|null
  unit_rate: number
  quantity: number
  amount: number
  sales_tax_amount: number
  sales_tax_percent: number
  sales_tax_only: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  inventory_batch: InventoryBatch
  material: Material
  customer: Customer
  district: District
  addl_source: COGSTransfer
  source: COGSTransfer
  type: COGSTransfer
}
export type COGSTransfers = COGSTransfer[]
export interface COGSTransferResults extends api.MetApiResults { data: COGSTransfers }
export interface COGSTransferResult extends api.MetApiResults { data: COGSTransfer }
export interface COGSTransferMetApiData extends api.MetApiData { data: COGSTransfer }
export interface COGSTransferResponse extends api.MetApiResponse { data: COGSTransferMetApiData }

export interface InventoryAdjustment {
  // columns
  id: string
  organization_id: string
  company_id: string
  inventory_batch_id: string
  material_id: string|null
  account: string|null
  subaccount: string|null
  description: string|null
  um: string|null
  quantity: number
  amount: number
  post_to_gl: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  inventory_batch: InventoryBatch
  material: Material
}
export type InventoryAdjustments = InventoryAdjustment[]
export interface InventoryAdjustmentResults extends api.MetApiResults { data: InventoryAdjustments }
export interface InventoryAdjustmentResult extends api.MetApiResults { data: InventoryAdjustment }
export interface InventoryAdjustmentMetApiData extends api.MetApiData { data: InventoryAdjustment }
export interface InventoryAdjustmentResponse extends api.MetApiResponse { data: InventoryAdjustmentMetApiData }

export interface InventoryBatch {
  // columns
  id: string
  organization_id: string
  company_id: string
  status: string
  type: InventoryBatchType
  fiscal_year: number
  period: number
  transaction_date: Date
  created_at: Date|null
  updated_at: Date|null
  // relations
  cogs_transfers: COGSTransfers
  inventory_adjustments: InventoryAdjustments
  receive_materials: ReceiveMaterials
}
export type InventoryBatches = InventoryBatch[]
export interface InventoryBatchResults extends api.MetApiResults { data: InventoryBatches }
export interface InventoryBatchResult extends api.MetApiResults { data: InventoryBatch }
export interface InventoryBatchMetApiData extends api.MetApiData { data: InventoryBatch }
export interface InventoryBatchResponse extends api.MetApiResponse { data: InventoryBatchMetApiData }

export interface Material {
  // columns
  id: string
  organization_id: string
  company_id: string
  class_id: string|null
  primary_vendor_id: string|null
  alternate_vendor_id: string|null
  last_order_vendor_id: string|null
  last_invoiced_vendor_id: string|null
  location_id: string
  purchase_order_id: string|null
  status: MaterialStatus
  code: string
  last_order_um: string|null
  description: string|null
  um: string|null
  current_value: number
  last_po_unit_rate: number
  last_invoice_unit_rate: number
  standard_unit_rate: number
  estimated_unit_rate: number
  um_conversion_factor: number
  quantity_on_order: number
  last_order_quantity: number
  quantity_not_invoiced: number
  quantity_on_hand: number
  last_invoice_quantity: number
  last_order_date: Date|null
  last_invoice_date: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  alternate_vendor: Vendor
  billing_rates: BillingRates
  class: MaterialClass
  last_invoiced_vendor: Vendor
  last_order_vendor: Vendor
  primary_vendor: Vendor
  purchase_order: PurchaseOrder
  location: Location
  transactions: Transactions
}
export type Materials = Material[]
export interface MaterialResults extends api.MetApiResults { data: Materials }
export interface MaterialResult extends api.MetApiResults { data: Material }
export interface MaterialMetApiData extends api.MetApiData { data: Material }
export interface MaterialResponse extends api.MetApiResponse { data: MaterialMetApiData }

export interface MaterialClass {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  inventory_account: string|null
  inventory_subaccount: string|null
  cogs_account: string|null
  cogs_subaccount: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
}
export type MaterialClasses = MaterialClass[]
export interface MaterialClassResults extends api.MetApiResults { data: MaterialClasses }
export interface MaterialClassResult extends api.MetApiResults { data: MaterialClass }
export interface MaterialClassMetApiData extends api.MetApiData { data: MaterialClass }
export interface MaterialClassResponse extends api.MetApiResponse { data: MaterialClassMetApiData }

export interface MaterialHistory {
  // columns
  id: string
  organization_id: string
  company_id: string
  material_id: string
  journal_id: string
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  business_type: string|null
  business_id: string|null
  description: string|null
  transfer_type: string|null
  amount: number
  reference_date: Date|null
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  material: MaterialClass
  journal: Journal
  addl_source: MaterialHistory
  business: MaterialHistory
  source: MaterialHistory
  type: MaterialHistory
}
export type MaterialHistories = MaterialHistory[]
export interface MaterialHistoryResults extends api.MetApiResults { data: MaterialHistories }
export interface MaterialHistoryResult extends api.MetApiResults { data: MaterialHistory }
export interface MaterialHistoryMetApiData extends api.MetApiData { data: MaterialHistory }
export interface MaterialHistoryResponse extends api.MetApiResponse { data: MaterialHistoryMetApiData }

export interface PurchaseOrder {
  // columns
  id: string
  organization_id: string
  company_id: string
  vendor_id: string
  employee_id: string|null
  journal_id: string|null
  void_journal_id: string|null
  template_id: string|null
  number: string
  status: string
  contact: string|null
  terms: string|null
  fob_point: string|null
  instructions: string|null
  description: string|null
  ship_to: string
  are_shop_drawing_submitted: boolean
  is_manual: boolean
  is_invoiced: boolean
  amount: number
  extended_amount: number
  freight_amount: number
  invoiced_amount: number
  sales_tax_amount: number
  sales_tax_percent: number
  discount_percent: number
  delivery_date: Date|null
  date: Date
  posted_at: Date|null
  voided_at: Date|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  voided_by: string|null
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  entries: PurchaseOrderEntries
  vendor_contact: Contact
  employee: Employee
  vendor: Vendor
  media: Media
}
export type PurchaseOrders = PurchaseOrder[]
export interface PurchaseOrderResults extends api.MetApiResults { data: PurchaseOrders }
export interface PurchaseOrderResult extends api.MetApiResults { data: PurchaseOrder }
export interface PurchaseOrderMetApiData extends api.MetApiData { data: PurchaseOrder }
export interface PurchaseOrderResponse extends api.MetApiResponse { data: PurchaseOrderMetApiData }

export interface PurchaseOrderEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  purchase_order_id: string
  address_id: string
  material_id: string|null
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  order: number
  cost_center: string
  um: string|null
  description: string|null
  unit_rate: number
  quantity: number
  quantity_received: number
  quantity_invoiced: number
  amount: number
  extended_amount: number
  freight_amount: number
  invoiced_amount: number
  sales_tax_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  address: Address
  material: Material
  purchase_order: PurchaseOrder
  addl_source: PurchaseOrderEntry
  source: PurchaseOrderEntry
  type: PurchaseOrderEntry
}
export type PurchaseOrderEntries = PurchaseOrderEntry[]
export interface PurchaseOrderEntryResults extends api.MetApiResults { data: PurchaseOrderEntries }
export interface PurchaseOrderEntryResult extends api.MetApiResults { data: PurchaseOrderEntry }
export interface PurchaseOrderEntryMetApiData extends api.MetApiData { data: PurchaseOrderEntry }
export interface PurchaseOrderEntryResponse extends api.MetApiResponse { data: PurchaseOrderEntryMetApiData }

export interface ReceiveMaterial {
  // columns
  id: string
  organization_id: string
  company_id: string
  inventory_batch_id: string
  material_id: string
  vendor_id: string|null
  purchase_order_id: string|null
  purchase_order_entry_id: string|null
  description: string|null
  invoice_packing_slip_no: string|null
  um: string|null
  quantity: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  inventory_batch: InventoryBatch
  material: Material
  purchase_order: PurchaseOrder
  purchase_order_entry: PurchaseOrderEntry
  vendor: Vendor
}
export type ReceiveMaterials = ReceiveMaterial[]
export interface ReceiveMaterialResults extends api.MetApiResults { data: ReceiveMaterials }
export interface ReceiveMaterialResult extends api.MetApiResults { data: ReceiveMaterial }
export interface ReceiveMaterialMetApiData extends api.MetApiData { data: ReceiveMaterial }
export interface ReceiveMaterialResponse extends api.MetApiResponse { data: ReceiveMaterialMetApiData }

export interface Job {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string|null
  category_id: string|null
  district_id: string|null
  workers_comp_ocip_id: string|null
  gen_liability_ocip_id: string|null
  sales_person_id: string|null
  union_id: string|null
  union_rate_type_id: string|null
  billing_rate_type_id: string|null
  gen_liability_state_id: string|null
  sui_sdi_state_id: string|null
  withholding_local_2_id: string|null
  withholding_local_id: string|null
  withholding_state_id: string|null
  workers_comp_state_id: string|null
  number: string
  description: string|null
  owner_name: string|null
  gen_liability_rate_code: string|null
  workers_comp_rate_code: string|null
  phone: string|null
  fax: string|null
  project_number: string|null
  um: string|null
  next_billing_suffix: string
  next_invoice_suffix: string
  user_notes: string[]
  last_payroll_number: number
  timecard_overrides: boolean
  subcontract_retention_percent: number
  billing_retention_percent: number
  units: number
  status: JobStatus
  print: string
  compliance_format: string
  certified_payroll: JobCertifiedPayroll
  print_ss: string
  actual_completion_date: Date|null
  actual_start_date: Date|null
  b_o_d_date: Date|null
  contract_start_date: Date|null
  current_completion_date: Date|null
  last_pay_period: Date|null
  n_o_c_date: Date|null
  original_completion_date: Date|null
  schedule_completion_date: Date|null
  schedule_start_date: Date|null
  meta: Record<string, unknown>
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  line_items: LineItems
  proposals: Proposals
  category: JobCategory
  gen_liability_ocip: OCIP
  pre_notice: JobPreNotice
  billing_rate_type: BillingRateType
  rates: JobRates
  sales_person: Employee
  transactions: Transactions
  union: Union
  union_rate_type: BillingRateType
  workers_comp_ocip: OCIP
  customer: Customer
  district: District
  gen_liability_rate: GenLiabilityRate
  workers_comp_rate: WorkersCompRate
  addresses: Addresses
  address: Address
  contacts: Contacts
  notes: Notes
}
export type Jobs = Job[]
export interface JobResults extends api.MetApiResults { data: Jobs }
export interface JobResult extends api.MetApiResults { data: Job }
export interface JobMetApiData extends api.MetApiData { data: Job }
export interface JobResponse extends api.MetApiResponse { data: JobMetApiData }

export interface JobBudget {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_type_id: string
  line_item_id: string
  amount: number
  amount_to_date: number
  completion: number
  est_final_cost: number
  material_stored_amount: number
  retention_amount: number
  variance_amount: number
  quantity: number
  quantity_to_date: number
  unit_rate: number
  um: string|null
  committed_po_amount: number
  invoiced_po_amount: number
  last_updated_by: string|null
  last_updated_at: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  updater: User
  job_type: JobType
  line_item: LineItem
}
export type JobBudgets = JobBudget[]
export interface JobBudgetResults extends api.MetApiResults { data: JobBudgets }
export interface JobBudgetResult extends api.MetApiResults { data: JobBudget }
export interface JobBudgetMetApiData extends api.MetApiData { data: JobBudget }
export interface JobBudgetResponse extends api.MetApiResponse { data: JobBudgetMetApiData }

export interface JobCategory {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  code_edit_option: LineItemEditOption
  burden_calc_override: boolean
  burden_calc: string|null
  burden_rate_type: string|null
  burden_rate: number
  estimated_burden_job_expense_account: string|null
  estimated_burden_job_expense_subaccount: string|null
  estimated_burden_job_liability_account: string|null
  estimated_burden_job_liability_subaccount: string|null
  employer_benefits_job_expense_account: string|null
  employer_benefits_job_expense_subaccount: string|null
  fica_job_expense_account: string|null
  fica_job_expense_subaccount: string|null
  medicare_job_expense_account: string|null
  medicare_job_expense_subaccount: string|null
  fui_job_expense_account: string|null
  fui_job_expense_subaccount: string|null
  sui_job_expense_account: string|null
  sui_job_expense_subaccount: string|null
  lit_job_expense_account: string|null
  lit_job_expense_subaccount: string|null
  workers_comp_job_expense_account: string|null
  workers_comp_job_expense_subaccount: string|null
  gen_liability_job_expense_account: string|null
  gen_liability_job_expense_subaccount: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  job_c_p_b: JobCategoryType
  job_types: JobTypes
}
export type JobCategories = JobCategory[]
export interface JobCategoryResults extends api.MetApiResults { data: JobCategories }
export interface JobCategoryResult extends api.MetApiResults { data: JobCategory }
export interface JobCategoryMetApiData extends api.MetApiData { data: JobCategory }
export interface JobCategoryResponse extends api.MetApiResponse { data: JobCategoryMetApiData }

export interface JobCategoryType {
  // columns
  id: string
  type_id: string
  category_id: string
  credit_account: string|null
  credit_subaccount: string|null
  debit_account: string|null
  debit_subaccount: string|null
  markup_percent: number|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  category: JobCategory
  type: JobType
}
export type JobCategoryTypes = JobCategoryType[]
export interface JobCategoryTypeResults extends api.MetApiResults { data: JobCategoryTypes }
export interface JobCategoryTypeResult extends api.MetApiResults { data: JobCategoryType }
export interface JobCategoryTypeMetApiData extends api.MetApiData { data: JobCategoryType }
export interface JobCategoryTypeResponse extends api.MetApiResponse { data: JobCategoryTypeMetApiData }

export interface JobCostingInit {
  // columns
  id: string
  organization_id: string
  company_id: string
  journal_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  status: string
  type: string
  fiscal_year: number
  period: number
  posted_at: Date|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  entries: JobCostingInitEntries
  journal: Journal
  creator: User
  editor: User
}
export type JobCostingInits = JobCostingInit[]
export interface JobCostingInitResults extends api.MetApiResults { data: JobCostingInits }
export interface JobCostingInitResult extends api.MetApiResults { data: JobCostingInit }
export interface JobCostingInitMetApiData extends api.MetApiData { data: JobCostingInit }
export interface JobCostingInitResponse extends api.MetApiResponse { data: JobCostingInitMetApiData }

export interface JobCostingInitEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_costing_init_id: string
  job_id: string
  job_type_id: string
  line_item_id: string
  business_type: string|null
  business_id: string|null
  quantity: number
  amount: number
  retention_amount: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  job_costing_init: JobCostingInit
  business: JobCostingInitEntry
  job: Job
  job_type: JobType
  line_item: LineItem
}
export type JobCostingInitEntries = JobCostingInitEntry[]
export interface JobCostingInitEntryResults extends api.MetApiResults { data: JobCostingInitEntries }
export interface JobCostingInitEntryResult extends api.MetApiResults { data: JobCostingInitEntry }
export interface JobCostingInitEntryMetApiData extends api.MetApiData { data: JobCostingInitEntry }
export interface JobCostingInitEntryResponse extends api.MetApiResponse { data: JobCostingInitEntryMetApiData }

export interface JobHistory {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_id: string
  line_item_id: string
  job_type_id: string
  journal_id: string
  source_type: string|null
  source_id: string|null
  business_type: string|null
  business_id: string|null
  description: string|null
  amount: number
  retention_amount: number
  reference_date: Date|null
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  job: Job
  job_type: JobType
  journal: Journal
  line_item: LineItem
  business: JobHistory
  source: JobHistory
}
export type JobHistories = JobHistory[]
export interface JobHistoryResults extends api.MetApiResults { data: JobHistories }
export interface JobHistoryResult extends api.MetApiResults { data: JobHistory }
export interface JobHistoryMetApiData extends api.MetApiData { data: JobHistory }
export interface JobHistoryResponse extends api.MetApiResponse { data: JobHistoryMetApiData }

export interface JobPreNotice {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_id: string
  est_price: number
  declarer_name: string|null
  declarer_relation: string|null
  declarer_title: string|null
  description: string|null
  signed_location: string|null
  services_finished_date_from: Date|null
  services_finished_date_to: Date|null
  notice_date: Date|null
  date_mailed: Date|null
  parties: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  job: Job
  addresses: Addresses
  address: Address
}
export type JobPreNotices = JobPreNotice[]
export interface JobPreNoticeResults extends api.MetApiResults { data: JobPreNotices }
export interface JobPreNoticeResult extends api.MetApiResults { data: JobPreNotice }
export interface JobPreNoticeMetApiData extends api.MetApiData { data: JobPreNotice }
export interface JobPreNoticeResponse extends api.MetApiResponse { data: JobPreNoticeMetApiData }

export interface JobProgressHistory {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_id: string
  line_item_id: string
  job_budget_id: string
  job_type_id: string
  batch: string
  prior: Record<string, unknown>
  current: Record<string, unknown>
  created_by: string
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  creator: User
  job_budget: JobBudget
  job: Job
  job_type: JobType
  line_item: LineItem
}
export type JobProgressHistories = JobProgressHistory[]
export interface JobProgressHistoryResults extends api.MetApiResults { data: JobProgressHistories }
export interface JobProgressHistoryResult extends api.MetApiResults { data: JobProgressHistory }
export interface JobProgressHistoryMetApiData extends api.MetApiData { data: JobProgressHistory }
export interface JobProgressHistoryResponse extends api.MetApiResponse { data: JobProgressHistoryMetApiData }

export interface JobType {
  // columns
  id: string
  organization_id: string
  company_id: string
  type: string
  abbr: string
  name: string|null
  markup_percent: number
  credit_account: string|null
  credit_subaccount: string|null
  debit_account: string|null
  debit_subaccount: string|null
  index: number
  readonly: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  budgets: JobBudgets
}
export type JobTypes = JobType[]
export interface JobTypeResults extends api.MetApiResults { data: JobTypes }
export interface JobTypeResult extends api.MetApiResults { data: JobType }
export interface JobTypeMetApiData extends api.MetApiData { data: JobType }
export interface JobTypeResponse extends api.MetApiResponse { data: JobTypeMetApiData }

export interface LineItem {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_id: string
  vendor_id: string|null
  material_id: string|null
  equipment_id: string|null
  sibling_id: string|null
  gen_liability_rate_code: string|null
  workers_comp_rate_code: string|null
  type: string
  phase_code: string|null
  cost_code: string|null
  change_order: number
  account: string|null
  subaccount: string|null
  description: string|null
  additional_description: string[]|null
  piece_pay_rate: number
  exempt_from_sales_tax: boolean
  status: LineItemStatus
  certified_payroll: LineItemCertifiedPayroll
  pay_rate_adjustment: PayRateAdjustment
  pay_rate_value: number
  amount: number
  amount_to_date: number
  completion: number
  est_final_cost: number
  retention_amount: number
  material_stored_amount: number
  committed_po_amount: number
  invoiced_po_amount: number
  last_updated_by: string|null
  last_updated_at: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  proposal_entries: ProposalEntries
  budgets: JobBudgets
  material: Material
  sibling: LineItem
  transactions: Transactions
  updater: User
  equipment: Equipment
  gen_liability_rate: GenLiabilityRate
  job: Job
  vendor: Vendor
  workers_comp_rate: WorkersCompRate
}
export type LineItems = LineItem[]
export interface LineItemResults extends api.MetApiResults { data: LineItems }
export interface LineItemResult extends api.MetApiResults { data: LineItem }
export interface LineItemMetApiData extends api.MetApiData { data: LineItem }
export interface LineItemResponse extends api.MetApiResponse { data: LineItemMetApiData }

export interface Proposal {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_id: string
  number: string
  description: string|null
  date: Date|null
  status: ProposalStatus
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  entries: ProposalEntries
  job: Job
}
export type Proposals = Proposal[]
export interface ProposalResults extends api.MetApiResults { data: Proposals }
export interface ProposalResult extends api.MetApiResults { data: Proposal }
export interface ProposalMetApiData extends api.MetApiData { data: Proposal }
export interface ProposalResponse extends api.MetApiResponse { data: ProposalMetApiData }

export interface ProposalEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  proposal_id: string
  job_type_id: string
  line_item_id: string|null
  order: number
  type: ProposalEntryType
  phase_code: string|null
  cost_code: string|null
  change_order: unknown
  amount: number
  description: string|null
  status: ProposalEntryStatus
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  proposal: Proposal
  job_type: JobType
  line_item: LineItem
}
export type ProposalEntries = ProposalEntry[]
export interface ProposalEntryResults extends api.MetApiResults { data: ProposalEntries }
export interface ProposalEntryResult extends api.MetApiResults { data: ProposalEntry }
export interface ProposalEntryMetApiData extends api.MetApiData { data: ProposalEntry }
export interface ProposalEntryResponse extends api.MetApiResponse { data: ProposalEntryMetApiData }

export interface NovaRole {
  // columns
  id: string
  name: string
  permissions: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  users: Users
}
export type NovaRoles = NovaRole[]
export interface NovaRoleResults extends api.MetApiResults { data: NovaRoles }
export interface NovaRoleResult extends api.MetApiResults { data: NovaRole }
export interface NovaRoleMetApiData extends api.MetApiData { data: NovaRole }
export interface NovaRoleResponse extends api.MetApiResponse { data: NovaRoleMetApiData }

export interface NovaUser {
  // columns
  id: string
  email: string
  password?: string
  name: string|null
  email_verified_at: Date|null
  remember_token?: string|null
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  roles: NovaRoles
  notifications: DatabaseNotifications
}
export type NovaUsers = NovaUser[]
export interface NovaUserResults extends api.MetApiResults { data: NovaUsers }
export interface NovaUserResult extends api.MetApiResults { data: NovaUser }
export interface NovaUserMetApiData extends api.MetApiData { data: NovaUser }
export interface NovaUserResponse extends api.MetApiResponse { data: NovaUserMetApiData }

export interface Organization {
  // columns
  id?: string
  name: string
  subdomain: string
  host?: Record<string, unknown>
  database?: Record<string, unknown>
  contact_name: string|null
  contact_email: string|null
  fax: string|null
  phone: string|null
  active: boolean
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  deleted_at: Date|null
  // relations
  addresses: Addresses
  companies: Companies
  mail_templates: MailTemplates
  roles: OrganizationRoles
  settings: OrganizationSettings
  users: Users
  actions: ActionEvents
  contacts: Contacts
}
export type Organizations = Organization[]
export interface OrganizationResults extends api.MetApiResults { data: Organizations }
export interface OrganizationResult extends api.MetApiResults { data: Organization }
export interface OrganizationMetApiData extends api.MetApiData { data: Organization }
export interface OrganizationResponse extends api.MetApiResponse { data: OrganizationMetApiData }

export interface OrganizationModel {
  // relations
  organization: Organization
  tenant: Organization
}
export type OrganizationModels = OrganizationModel[]
export interface OrganizationModelResults extends api.MetApiResults { data: OrganizationModels }
export interface OrganizationModelResult extends api.MetApiResults { data: OrganizationModel }
export interface OrganizationModelMetApiData extends api.MetApiData { data: OrganizationModel }
export interface OrganizationModelResponse extends api.MetApiResponse { data: OrganizationModelMetApiData }

export interface OrganizationSetting {
  // columns
  id: string
  organization_id: string
  key: string
  value: string
  created_at: Date|null
  updated_at: Date|null
}
export type OrganizationSettings = OrganizationSetting[]
export interface OrganizationSettingResults extends api.MetApiResults { data: OrganizationSettings }
export interface OrganizationSettingResult extends api.MetApiResults { data: OrganizationSetting }
export interface OrganizationSettingMetApiData extends api.MetApiData { data: OrganizationSetting }
export interface OrganizationSettingResponse extends api.MetApiResponse { data: OrganizationSettingMetApiData }

export interface OrganizationUser {
  // columns
  organization_id: string
  user_id: string
  created_at: Date|null
  updated_at: Date|null
}
export type OrganizationUsers = OrganizationUser[]
export interface OrganizationUserResults extends api.MetApiResults { data: OrganizationUsers }
export interface OrganizationUserResult extends api.MetApiResults { data: OrganizationUser }
export interface OrganizationUserMetApiData extends api.MetApiData { data: OrganizationUser }
export interface OrganizationUserResponse extends api.MetApiResponse { data: OrganizationUserMetApiData }

export interface BenefitCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_type_id?: string|null
  code: string
  description: string|null
  liability_account: string|null
  liability_subaccount: string|null
  expense_account: string|null
  account: string|null
  subaccount: string|null
  expense_subaccount: string|null
  scope: CodeScope
  calculate_by: string
  multiply_by: string
  use_employee_regular_rate: boolean
  subject_to_workers_comp: boolean
  subject_to_liability_insurance: boolean
  subject_to_fit: boolean|string
  subject_to_sit : boolean|string
  subject_to_lit: boolean|string
  subject_to_fica: boolean|string
  subject_to_fui: boolean|string
  subject_to_sdi: boolean|string
  subject_to_sui: boolean|string
  available_in_timecards: boolean|string
  include_on_certified_payroll_report: boolean
  reduce_disposible_earnings: boolean
  is_401k_match
  classify_as: string
  include_on_union_reports: boolean
  include_non_tax_benefits_on_401: boolean
  show_on_paystub: boolean
  show_in_ytds: boolean
  should_distribute: boolean
  certified_payroll_category_type: string|null
  active: boolean
  is_used: boolean
  readonly: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  job_type: JobType
  w2_code: string|null
}
export type BenefitCodes = BenefitCode[]
export interface BenefitCodeResults extends api.MetApiResults { data: BenefitCodes }
export interface BenefitCodeResult extends api.MetApiResults { data: BenefitCode }
export interface BenefitCodeMetApiData extends api.MetApiData { data: BenefitCode }
export interface BenefitCodeResponse extends api.MetApiResponse { data: BenefitCodeMetApiData }

export interface CraftCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  level: string
  description: string|null
  billing_description: string|null
  trade: string|null
  classification: string|null
  active: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  billing_rates: BillingRates
  employees: Employees
}
export type CraftCodes = CraftCode[]
export interface CraftCodeResults extends api.MetApiResults { data: CraftCodes }
export interface CraftCodeResult extends api.MetApiResults { data: CraftCode }
export interface CraftCodeMetApiData extends api.MetApiData { data: CraftCode }
export interface CraftCodeResponse extends api.MetApiResponse { data: CraftCodeMetApiData }

export interface DeductionCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  vendor_id: string|null
  w2_code: string|null
  code: string
  state: string|null
  account: string|null
  subaccount: string|null
  description: string|null
  scope: CodeScope
  calculate_by: string
  multiply_by: string
  auto_refill: boolean
  separate_invoice_by_employee: boolean
  show_in_ytds: boolean
  active: boolean
  is_used: boolean
  readonly: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  union_rate: UnionRates
  w_two_code: WTwoCode
  vendor: Vendor
  include_on_union_reports: boolean
  show_in_ytds: boolean
  reduce_gross_pay_by_125s: boolean
  separate_invoice_by_employee: boolean
  is_loan: boolean
  is_roth: boolean
}
export type DeductionCodes = DeductionCode[]
export interface DeductionCodeResults extends api.MetApiResults { data: DeductionCodes }
export interface DeductionCodeResult extends api.MetApiResults { data: DeductionCode }
export interface DeductionCodeMetApiData extends api.MetApiData { data: DeductionCode }
export interface DeductionCodeResponse extends api.MetApiResponse { data: DeductionCodeMetApiData }

export interface Department {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  eeoc_job_category: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
}
export type Departments = Department[]
export interface DepartmentResults extends api.MetApiResults { data: Departments }
export interface DepartmentResult extends api.MetApiResults { data: Department }
export interface DepartmentMetApiData extends api.MetApiData { data: Department }
export interface DepartmentResponse extends api.MetApiResponse { data: DepartmentMetApiData }

export interface Employee {
  // columns
  id: string
  organization_id: string
  company_id: string
  user_id: string|null
  union_id: string|null
  craft_code_id: string|null
  department_id: string|null
  gen_liability_state_id: string|null
  sui_sdi_state_id: string|null
  withholding_local_2_id: string|null
  withholding_local_id: string|null
  withholding_state_id: string|null
  workers_comp_state_id: string|null
  paid_holiday_policy_id: string|null
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  cost_center: CostCenter
  code: string
  first_name: string
  middle_name: string|null
  last_name: string
  suffix: string|null
  name: string
  status: string
  pay_frequency: string
  email: string|null
  phone_1: string|null
  phone_2: string|null
  ssn: unknown|null
  ssn_bidx: string|null
  masked_ssn: string|null
  identification_code: string|null
  minority: string|null
  sex: string|null
  dept_code: string|null
  gen_liability_rate_code: string|null
  workers_comp_rate_code: string|null
  dob: Date|null
  hire_date: Date|null
  initial_hire_date: Date|null
  term_date: Date|null
  other_date: Date|null
  certifications: Record<string, unknown>
  work_permits: Record<string, unknown>
  driver_licenses: Record<string, unknown>
  account: string|null
  subaccount: string|null
  has_pension_plan: boolean
  has_officer_position: boolean
  job_can_override_workers_comp: boolean
  job_can_override_gen_liability: boolean
  job_can_override_union: boolean
  on_certified_payroll: boolean
  is_detail_stub: boolean
  hourly_rate: number
  salary_amount: number
  receive_checks_via_email: boolean
  payroll_consent_token: string|null
  payroll_consent_confirmed_at: Date|null
  payroll_consent_optout_at: Date|null
  payroll_consent_sent_at: Date|null
  subject_to_federal_unemployment: boolean
  subject_to_medicare_withholding: boolean
  subject_to_social_security_withholding: boolean
  federal_status: string
  is_old_w4: boolean
  allowances: boolean
  is_step_2: boolean
  dependents_amount: number
  other_income_amount: number
  deductions_amount: number
  withholding_amount: number
  fit_adjustment_type: string
  fit_adjustment_rate: number
  paper_check_min_pay_amount: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  federal_status_abbr: string
  formatted_pay_frequency: string
  formatted_ssn: string
  // relations
  benefit_rates: EmployeeBenefitRates
  checks: EmployeeChecks
  computable_timecards: Timecards
  craft_code: CraftCode
  department: Department
  current_ytds: YTDS
  deduction_rates: EmployeeDeductionRates
  direct_deposits: EmployeeDirectDeposits
  pay_rates: EmployeePayRates
  payrolls: Payrolls
  selectable_deduction_rates: EmployeeDeductionRates
  sui_sdi_state: StateTaxJurisdiction
  timecards: Timecards
  time_off_policies: TimeOffPolicies
  time_off_statuses: TimeOffStatuses
  union: Union
  union_rates: UnionRate
  user: User
  withholding_local: LocalTaxJurisdiction
  withholding_state: StateTaxJurisdiction
  ytds: YTDS
  gen_liability_rate: GenLiabilityRate
  workers_comp_rate: WorkersCompRate
  addresses: Addresses
  address: Address
  notes: Notes
  addl_source: Employee
  source: Employee
  type: Employee
}
export type Employees = Employee[]
export interface EmployeeResults extends api.MetApiResults { data: Employees }
export interface EmployeeResult extends api.MetApiResults { data: Employee }
export interface EmployeeMetApiData extends api.MetApiData { data: Employee }
export interface EmployeeResponse extends api.MetApiResponse { data: EmployeeMetApiData }

export interface EmployeeBenefitRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  source_id: string
  order: number
  frequency: string
  rate: number
  accumulated_amount: number
  limit_on_accumulated_amount: number
  period_limit_amount: number
  carry_over: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  benefit_code: BenefitCode
  employee: Employee
}
export type EmployeeBenefitRates = EmployeeBenefitRate[]
export interface EmployeeBenefitRateResults extends api.MetApiResults { data: EmployeeBenefitRates }
export interface EmployeeBenefitRateResult extends api.MetApiResults { data: EmployeeBenefitRate }
export interface EmployeeBenefitRateMetApiData extends api.MetApiData { data: EmployeeBenefitRate }
export interface EmployeeBenefitRateResponse extends api.MetApiResponse { data: EmployeeBenefitRateMetApiData }

export interface EmployeeCheck {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  journal: string|null
  amount: number
  no: string
  date: Date
  start_date: Date
  end_date: Date
  year: Date|null
  period: boolean|null
  quarter: boolean|null
  labor: Record<string, unknown>|null
  special: Record<string, unknown>|null
  witholding: string[]|null
  earnings: Record<string, unknown>|null
  benefits: Record<string, unknown>|null
  tax_deductions: Record<string, unknown>|null
  other: Record<string, unknown>|null
  deposit: Record<string, unknown>|null
  pto: Record<string, unknown>|null
  sent_count: boolean
  sent_at: Date|null
  downloaded_at: Date|null
  downloaded_template: string|null
  pdf_paths: Record<string, unknown>|null
  voided: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  number: unknown
  // relations
  employee: Employee
  media: Media
}
export type EmployeeChecks = EmployeeCheck[]
export interface EmployeeCheckResults extends api.MetApiResults { data: EmployeeChecks }
export interface EmployeeCheckResult extends api.MetApiResults { data: EmployeeCheck }
export interface EmployeeCheckMetApiData extends api.MetApiData { data: EmployeeCheck }
export interface EmployeeCheckResponse extends api.MetApiResponse { data: EmployeeCheckMetApiData }

export interface EmployeeDeductionRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  source_id: string
  vendor_id: string|null
  order: number
  case_number: string|null
  frequency: string
  rate: number
  accumulation_interval: string
  invoice_option: string|null
  accumulated_amount: number
  limit_on_accumulated_amount: number
  period_limit_amount: number
  carry_over: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  deduction_code: DeductionCode
  vendor: Vendor
  employee: Employee
}
export type EmployeeDeductionRates = EmployeeDeductionRate[]
export interface EmployeeDeductionRateResults extends api.MetApiResults { data: EmployeeDeductionRates }
export interface EmployeeDeductionRateResult extends api.MetApiResults { data: EmployeeDeductionRate }
export interface EmployeeDeductionRateMetApiData extends api.MetApiData { data: EmployeeDeductionRate }
export interface EmployeeDeductionRateResponse extends api.MetApiResponse { data: EmployeeDeductionRateMetApiData }

export interface EmployeeDirectDeposit {
  // columns
  id?: string
  _localId?: string
  organization_id?: string
  company_id?: string
  employee_id: string
  direct_deposit_code_id?: string
  status: string
  order: number
  account_type: string
  routing_number: string
  account_number: unknown
  calculate_by: string
  amount: number
  created_at?: Date|null
  updated_at?: Date|null
  // relations
  deduction_code?: DeductionCode
  employee?: Employee
}
export type EmployeeDirectDeposits = EmployeeDirectDeposit[]
export interface EmployeeDirectDepositResults extends api.MetApiResults { data: EmployeeDirectDeposits }
export interface EmployeeDirectDepositResult extends api.MetApiResults { data: EmployeeDirectDeposit }
export interface EmployeeDirectDepositMetApiData extends api.MetApiData { data: EmployeeDirectDeposit }
export interface EmployeeDirectDepositResponse extends api.MetApiResponse { data: EmployeeDirectDepositMetApiData }

export interface EmployeeLeaveDeduction {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_deduction_rate_id: string
  bank_id: string|null
  type: string
  state: string|null
  local: string|null
  reference_date: Date|null
  actual_date: Date|null
  payment_date: Date|null
  ppe_date: Date|null
  day_of_week: boolean
  payment_number: number
  quantity: number
  amount: number
  accumulated_amount: number
  limit_on_accumulated_amount: number
  period_limit_amount: number
  annual_amount: number
  last_year_accumulated_amount: number
  created_at: Date|null
  updated_at: Date|null
}
export type EmployeeLeaveDeductions = EmployeeLeaveDeduction[]
export interface EmployeeLeaveDeductionResults extends api.MetApiResults { data: EmployeeLeaveDeductions }
export interface EmployeeLeaveDeductionResult extends api.MetApiResults { data: EmployeeLeaveDeduction }
export interface EmployeeLeaveDeductionMetApiData extends api.MetApiData { data: EmployeeLeaveDeduction }
export interface EmployeeLeaveDeductionResponse extends api.MetApiResponse { data: EmployeeLeaveDeductionMetApiData }

export interface EmployeeLeavePay {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_pay_rate_id: string
  bank_id: string|null
  type: string
  state: string|null
  local: string|null
  reference_date: Date|null
  actual_date: Date|null
  payment_date: Date|null
  ppe_date: Date|null
  day_of_week: boolean
  payment_number: number
  quantity: number
  amount: number
  accumulated_amount: number
  annual_amount: number
  limit_on_accumulated_amount: number
  period_limit_amount: number
  created_at: Date|null
  updated_at: Date|null
}
export type EmployeeLeavePays = EmployeeLeavePay[]
export interface EmployeeLeavePayResults extends api.MetApiResults { data: EmployeeLeavePays }
export interface EmployeeLeavePayResult extends api.MetApiResults { data: EmployeeLeavePay }
export interface EmployeeLeavePayMetApiData extends api.MetApiData { data: EmployeeLeavePay }
export interface EmployeeLeavePayResponse extends api.MetApiResponse { data: EmployeeLeavePayMetApiData }

export interface EmployeeLocalTax {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  jurisdiction_id: string
  type: string
  status: string
  adjustment: string
  percent_of_federal_or_state: number
  sit_adjustment_rate: number
  start_quarter: boolean
  personal_tax_credit_allowance_count: number
  estimated_deduction_allowance_count: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  jurisdiction: LocalTaxJurisdiction
  employee: Employee
}
export type EmployeeLocalTaxes = EmployeeLocalTax[]
export interface EmployeeLocalTaxResults extends api.MetApiResults { data: EmployeeLocalTaxes }
export interface EmployeeLocalTaxResult extends api.MetApiResults { data: EmployeeLocalTax }
export interface EmployeeLocalTaxMetApiData extends api.MetApiData { data: EmployeeLocalTax }
export interface EmployeeLocalTaxResponse extends api.MetApiResponse { data: EmployeeLocalTaxMetApiData }

export interface EmployeePayRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  source_id: string
  order: number
  frequency: string
  job_can_override_rate: string
  rate: number
  accumulated_amount: number
  limit_on_accumulated_amount: number
  period_limit_amount: number
  carry_over: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  pay_code: PayCode
  employee: Employee
}
export type EmployeePayRates = EmployeePayRate[]
export interface EmployeePayRateResults extends api.MetApiResults { data: EmployeePayRates }
export interface EmployeePayRateResult extends api.MetApiResults { data: EmployeePayRate }
export interface EmployeePayRateMetApiData extends api.MetApiData { data: EmployeePayRate }
export interface EmployeePayRateResponse extends api.MetApiResponse { data: EmployeePayRateMetApiData }

export interface EmployeeStateTax {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  jurisdiction_id: string
  type: string
  status: string
  sit_adjustment_type: string
  percent_of_federal: number
  sit_adjustment_rate: number
  ct_adjustment_amount: number
  start_quarter: boolean
  personal_tax_credit_allowance_count: number
  estimated_deduction_allowance_count: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  jurisdiction: StateTaxJurisdiction
  employee: Employee
}
export type EmployeeStateTaxes = EmployeeStateTax[]
export interface EmployeeStateTaxResults extends api.MetApiResults { data: EmployeeStateTaxes }
export interface EmployeeStateTaxResult extends api.MetApiResults { data: EmployeeStateTax }
export interface EmployeeStateTaxMetApiData extends api.MetApiData { data: EmployeeStateTax }
export interface EmployeeStateTaxResponse extends api.MetApiResponse { data: EmployeeStateTaxMetApiData }

export interface EmployeeWTwoForm {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  template: string
  year: number
  company_data: string[]
  employee_data: string[]
  form_data: string[]
  sent_count: number
  sent_at: Date|null
  downloaded_at: Date|null
  pdf_paths: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  employee: Employee
  media: Media
}
export type EmployeeWTwoForms = EmployeeWTwoForm[]
export interface EmployeeWTwoFormResults extends api.MetApiResults { data: EmployeeWTwoForms }
export interface EmployeeWTwoFormResult extends api.MetApiResults { data: EmployeeWTwoForm }
export interface EmployeeWTwoFormMetApiData extends api.MetApiData { data: EmployeeWTwoForm }
export interface EmployeeWTwoFormResponse extends api.MetApiResponse { data: EmployeeWTwoFormMetApiData }

export interface GenLiabilityRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  state: string|null
  calculate_by: string
  multiply_by: string
  amount: number
  start_date: Date
  end_date: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  is_active: boolean
}
export type GenLiabilityRates = GenLiabilityRate[]
export interface GenLiabilityRateResults extends api.MetApiResults { data: GenLiabilityRates }
export interface GenLiabilityRateResult extends api.MetApiResults { data: GenLiabilityRate }
export interface GenLiabilityRateMetApiData extends api.MetApiData { data: GenLiabilityRate }
export interface GenLiabilityRateResponse extends api.MetApiResponse { data: GenLiabilityRateMetApiData }

export interface JobRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_id: string
  source_type: string
  source_id: string
  order: number
  craft_code: string
  craft_level: string
  rate: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  craft_code: CraftCode
  job: Job
  source: JobRate
}
export type JobRates = JobRate[]
export interface JobRateResults extends api.MetApiResults { data: JobRates }
export interface JobRateResult extends api.MetApiResults { data: JobRate }
export interface JobRateMetApiData extends api.MetApiData { data: JobRate }
export interface JobRateResponse extends api.MetApiResponse { data: JobRateMetApiData }

export interface LocalTax {
  // columns
  id: string
  organization_id: string
  company_id: string
  jurisdiction_id: string
  status: string
  responsible: string
  percent: number
  is_resident: boolean
  start_date: Date
  end_date: Date|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  jurisdiction: LocalTaxJurisdiction
}
export type LocalTaxes = LocalTax[]
export interface LocalTaxResults extends api.MetApiResults { data: LocalTaxes }
export interface LocalTaxResult extends api.MetApiResults { data: LocalTax }
export interface LocalTaxMetApiData extends api.MetApiData { data: LocalTax }
export interface LocalTaxResponse extends api.MetApiResponse { data: LocalTaxMetApiData }

export interface LocalTaxJurisdiction {
  // columns
  id: string
  organization_id: string
  company_id: string
  w2_code: string|null
  withholding_payee_vendor_id: string|null
  code: string
  description: string|null
  type: string
  is_125s_exempt: boolean
  is_401k_exempt: boolean
  employer_number: string|null
  w2_description: string|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  wh_vendor: Vendor
  w_two_code: WTwoCode
}
export type LocalTaxJurisdictions = LocalTaxJurisdiction[]
export interface LocalTaxJurisdictionResults extends api.MetApiResults { data: LocalTaxJurisdictions }
export interface LocalTaxJurisdictionResult extends api.MetApiResults { data: LocalTaxJurisdiction }
export interface LocalTaxJurisdictionMetApiData extends api.MetApiData { data: LocalTaxJurisdiction }
export interface LocalTaxJurisdictionResponse extends api.MetApiResponse { data: LocalTaxJurisdictionMetApiData }

export interface OCIP {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
}
export type OCIPS = OCIP[]
export interface OCIPResults extends api.MetApiResults { data: OCIPS }
export interface OCIPResult extends api.MetApiResults { data: OCIP }
export interface OCIPMetApiData extends api.MetApiData { data: OCIP }
export interface OCIPResponse extends api.MetApiResponse { data: OCIPMetApiData }

export interface PayCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  job_type_id: string|null
  code: string
  description: string|null
  liability_account: string|null
  liability_subaccount: string|null
  expense_account: string|null
  expense_subaccount: string|null
  scope: CodeScope
  calculate_by: string
  multiply_by: string
  use_employee_regular_rate: boolean
  is_disposable_earning: boolean
  subject_to_workers_comp: boolean
  subject_to_liability_insurance: boolean
  subject_to_fit: string
  subject_to_sit: string
  subject_to_lit: string
  subject_to_fica: boolean
  subject_to_fui: boolean
  subject_to_sdi: boolean
  subject_to_sui: boolean
  treat_units_as_regular_hours: boolean
  include_on_certified_payroll_report: boolean
  classify_as: string
  include_on_union_reports: boolean
  include_non_tax_benefits_on_401: boolean
  show_in_ytds: boolean
  certified_payroll_category_type: string|null
  piece_pay_behavior: string|null
  piece_rest_and_recovery_rate: number
  piece_rest_and_recovery_min_pay_rate: number
  active: boolean
  is_used: boolean
  readonly: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  job_type: JobType
}
export type PayCodes = PayCode[]
export interface PayCodeResults extends api.MetApiResults { data: PayCodes }
export interface PayCodeResult extends api.MetApiResults { data: PayCode }
export interface PayCodeMetApiData extends api.MetApiData { data: PayCode }
export interface PayCodeResponse extends api.MetApiResponse { data: PayCodeMetApiData }

export interface Payroll {
  // columns
  id: string
  organization_id: string
  company_id: string
  payroll_batch_id: string
  timecard_id: string|null
  employee_id: string
  journal_id: string|null
  void_journal_id: string|null
  bank_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  voided_by: string|null
  year: number
  quarter: number|null
  year_quarter: string|null
  period_start_date: Date|null
  period_end_date: Date
  type: string
  payment_number: string|null
  payment_date: Date|null
  employee_regular_rate: number|null
  status: string
  totals: Record<string, unknown>
  ytd_totals: Record<string, unknown>
  sent_count: number
  sent_at: Date|null
  downloaded_at: Date|null
  telemetry: Record<string, unknown>
  meta: Record<string, unknown>
  pdf_paths: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // mutators
  amount: number
  // relations
  batch: PayrollBatch
  child_entries: PayrollEntries
  entries: PayrollEntries
  timecard: Timecard
  bank: Bank
  employee: Employee
  journal: Journal
  void_journal: Journal
  media: Media
}
export type Payrolls = Payroll[]
export interface PayrollResults extends api.MetApiResults { data: Payrolls }
export interface PayrollResult extends api.MetApiResults { data: Payroll }
export interface PayrollMetApiData extends api.MetApiData { data: Payroll }
export interface PayrollResponse extends api.MetApiResponse { data: PayrollMetApiData }

export interface PayrollBatch {
  // columns
  id: string
  organization_id: string
  company_id: string
  timecard_batch_id: string|null
  journal_id: string|null
  void_journal_id: string|null
  created_by: string|null
  updated_by: string|null
  posted_by: string|null
  voided_by: string|null
  year: number
  period_end_date: Date
  has_monthly_employees: boolean
  has_semi_monthly_employees: boolean
  status: string
  counts: Record<string, unknown>
  telemetry: Record<string, unknown>
  totals: Record<string, unknown>
  pdf_paths: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  payrolls: Payrolls
  timecard_batch: TimecardBatch
}
export type PayrollBatches = PayrollBatch[]
export interface PayrollBatchResults extends api.MetApiResults { data: PayrollBatches }
export interface PayrollBatchResult extends api.MetApiResults { data: PayrollBatch }
export interface PayrollBatchMetApiData extends api.MetApiData { data: PayrollBatch }
export interface PayrollBatchResponse extends api.MetApiResponse { data: PayrollBatchMetApiData }

export interface PayrollEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  payroll_id: string
  parent_id: string|null
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  special_source_type: string|null
  special_source_id: string|null
  craft_code_id: string|null
  gen_liability_rate_id: string|null
  union_id: string|null
  workers_comp_rate_id: string|null
  gen_liability_state_id: string|null
  sui_sdi_state_id: string|null
  withholding_local_2_id: string|null
  withholding_local_id: string|null
  withholding_state_id: string|null
  workers_comp_state_id: string|null
  special_source_code: string
  cost_center: string
  day: number|null
  date: Date|null
  rate: number
  rate_from: RateFrom|null
  quantity: number
  amount: number
  current_ytd_amount: number
  batch_previous_ytd_amount: number
  is_certified_payroll_exempt: boolean
  scope: PayrollEntryScope
  category: PayrollEntryCategory
  credit_account: string|null
  credit_subaccount: string|null
  debit_account: string|null
  debit_subaccount: string|null
  is_removable: boolean
  meta: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  children: PayrollEntries
  craft_code: CraftCode
  gen_liability_rate: GenLiabilityRate
  gen_liability_state: StateTaxJurisdiction
  parent: PayrollEntry
  payroll: Payroll
  sui_sdi_state: StateTaxJurisdiction
  union: Union
  withholding_local: LocalTaxJurisdiction
  withholding_local2: LocalTaxJurisdiction
  withholding_state: StateTaxJurisdiction
  workers_comp_rate: WorkersCompRate
  workers_comp_state: StateTaxJurisdiction
  addl_source: PayrollEntry
  source: PayrollEntry
  special_source: PayrollEntry
  type: PayrollEntry
}
export type PayrollEntries = PayrollEntry[]
export interface PayrollEntryResults extends api.MetApiResults { data: PayrollEntries }
export interface PayrollEntryResult extends api.MetApiResults { data: PayrollEntry }
export interface PayrollEntryMetApiData extends api.MetApiData { data: PayrollEntry }
export interface PayrollEntryResponse extends api.MetApiResponse { data: PayrollEntryMetApiData }

export interface StateTaxJurisdiction {
  // columns
  id: string
  organization_id: string
  company_id: string
  ui_payee_vendor_id: string|null
  withholding_payee_vendor_id: string|null
  code: string
  description: string|null
  is_125s_exempt: boolean
  is_401k_exempt: boolean
  employer_number: string|null
  sui_code: string|null
  sui_rate: number
  sui_rate_last_year: number
  sui_limit_amount: number
  sui_limit_amount_last_year: number
  training_percent: number
  training_percent_last_year: number
  workers_comp_mod_percent: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  ui_vendor: Vendor
  wh_vendor: Vendor
}
export type StateTaxJurisdictions = StateTaxJurisdiction[]
export interface StateTaxJurisdictionResults extends api.MetApiResults { data: StateTaxJurisdictions }
export interface StateTaxJurisdictionResult extends api.MetApiResults { data: StateTaxJurisdiction }
export interface StateTaxJurisdictionMetApiData extends api.MetApiData { data: StateTaxJurisdiction }
export interface StateTaxJurisdictionResponse extends api.MetApiResponse { data: StateTaxJurisdictionMetApiData }

export interface TaxCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  vendor_id: string|null
  w2_code: string|null
  code: string
  state: string|null
  account: string|null
  subaccount: string|null
  description: string|null
  scope: CodeScope
  calculate_by: string
  multiply_by: string
  include_on_union_reports: boolean
  auto_refill: boolean
  separate_invoice_by_employee: boolean
  show_in_ytds: boolean
  active: boolean
  is_used: boolean
  readonly: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  union_rate: UnionRates
  w_two_code: WTwoCode
  vendor: Vendor
}
export type TaxCodes = TaxCode[]
export interface TaxCodeResults extends api.MetApiResults { data: TaxCodes }
export interface TaxCodeResult extends api.MetApiResults { data: TaxCode }
export interface TaxCodeMetApiData extends api.MetApiData { data: TaxCode }
export interface TaxCodeResponse extends api.MetApiResponse { data: TaxCodeMetApiData }

export interface TenureAccrualRule {
  // columns
  id: string
  organization_id: string
  company_id: string
  time_off_policy_id: string
  rate: number
  rate_unit: number
  milestone: number
  max_accrual_hours_per_pay_period: number
  max_accrual_hours_per_year: number
  carryover_limit_hours: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  time_off_policy: TimeOffPolicy
}
export type TenureAccrualRules = TenureAccrualRule[]
export interface TenureAccrualRuleResults extends api.MetApiResults { data: TenureAccrualRules }
export interface TenureAccrualRuleResult extends api.MetApiResults { data: TenureAccrualRule }
export interface TenureAccrualRuleMetApiData extends api.MetApiData { data: TenureAccrualRule }
export interface TenureAccrualRuleResponse extends api.MetApiResponse { data: TenureAccrualRuleMetApiData }

export interface TimeOffActivity {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  time_off_type_id: string
  created_at: Date|null
  updated_at: Date|null
}
export type TimeOffActivities = TimeOffActivity[]
export interface TimeOffActivityResults extends api.MetApiResults { data: TimeOffActivities }
export interface TimeOffActivityResult extends api.MetApiResults { data: TimeOffActivity }
export interface TimeOffActivityMetApiData extends api.MetApiData { data: TimeOffActivity }
export interface TimeOffActivityResponse extends api.MetApiResponse { data: TimeOffActivityMetApiData }

export interface TimeOffPolicy {
  // columns
  id: string
  organization_id: string
  company_id: string
  time_off_type_id: string
  name: string
  description: string|null
  state: string|null
  follow_state_requirements: boolean
  account: string|null
  subaccount: string|null
  accrual_method: AccrualMethod
  max_hours: number
  waiting_period_days: number
  usage_waiting_period_days: number
  paid_out_on_termination: boolean
  allow_time_borrowing: boolean
  include_on_union_reports: boolean
  active: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  code: string
  // relations
  employees: Employees
  time_off_type: TimeOffType
  tenures: TenureAccrualRules
  vendor: Vendor
}
export type TimeOffPolicies = TimeOffPolicy[]
export interface TimeOffPolicyResults extends api.MetApiResults { data: TimeOffPolicies }
export interface TimeOffPolicyResult extends api.MetApiResults { data: TimeOffPolicy }
export interface TimeOffPolicyMetApiData extends api.MetApiData { data: TimeOffPolicy }
export interface TimeOffPolicyResponse extends api.MetApiResponse { data: TimeOffPolicyMetApiData }

export interface TimeOffStatus {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  time_off_type_id: string
  state: string
  balance: number
  current_year_accrued_hours: number
  current_year_used_hours: number
  previous_year_carry_over_hours: number
  created_at: Date|null
  updated_at: Date|null
  // relations
  time_off_type: TimeOffType
  employee: Employee
}
export type TimeOffStatuses = TimeOffStatus[]
export interface TimeOffStatusResults extends api.MetApiResults { data: TimeOffStatuses }
export interface TimeOffStatusResult extends api.MetApiResults { data: TimeOffStatus }
export interface TimeOffStatusMetApiData extends api.MetApiData { data: TimeOffStatus }
export interface TimeOffStatusResponse extends api.MetApiResponse { data: TimeOffStatusMetApiData }

export interface TimeOffType {
  // columns
  id: string
  organization_id: string
  company_id: string
  name: string
  kind: TimeOffTypeKind
  payment_strategy: TimeOffTypeStrategy
  is_custom: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  time_off_statuses: TimeOffStatuses
}
export type TimeOffTypes = TimeOffType[]
export interface TimeOffTypeResults extends api.MetApiResults { data: TimeOffTypes }
export interface TimeOffTypeResult extends api.MetApiResults { data: TimeOffType }
export interface TimeOffTypeMetApiData extends api.MetApiData { data: TimeOffType }
export interface TimeOffTypeResponse extends api.MetApiResponse { data: TimeOffTypeMetApiData }

export interface Timecard {
  // columns
  id: string
  organization_id: string
  company_id: string
  timecard_batch_id: string
  employee_id: string
  bank_id: string|null
  created_by: string|null
  updated_by: string|null
  computed_by: string|null
  year: number
  period_end_date: Date
  number: number
  type: string
  payment_number: string|null
  payment_date: Date|null
  status: string
  selectable_deductions: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  batch: TimecardBatch
  entries: TimecardEntries
  payroll: Payroll
  bank: Bank
  employee: Employee
}
export type Timecards = Timecard[]
export interface TimecardResults extends api.MetApiResults { data: Timecards }
export interface TimecardResult extends api.MetApiResults { data: Timecard }
export interface TimecardMetApiData extends api.MetApiData { data: Timecard }
export interface TimecardResponse extends api.MetApiResponse { data: TimecardMetApiData }

export interface TimecardBatch {
  // columns
  id: string
  organization_id: string
  company_id: string
  payroll_batch_id: string|null
  created_by: string|null
  updated_by: string|null
  computed_by: string|null
  year: number
  period_end_date: Date
  pay_frequency: Record<string, unknown>|null
  include_salaried: boolean
  status: string
  counts: Record<string, unknown>
  selectable_deductions: Record<string, unknown>
  created_at: Date|null
  updated_at: Date|null
  // relations
  payroll_batch: PayrollBatch
  timecards: Timecards
  creator: User
  editor: User
}
export type TimecardBatches = TimecardBatch[]
export interface TimecardBatchResults extends api.MetApiResults { data: TimecardBatches }
export interface TimecardBatchResult extends api.MetApiResults { data: TimecardBatch }
export interface TimecardBatchMetApiData extends api.MetApiData { data: TimecardBatch }
export interface TimecardBatchResponse extends api.MetApiResponse { data: TimecardBatchMetApiData }

export interface TimecardEntry {
  // columns
  id: string
  organization_id: string
  company_id: string
  timecard_id: string
  source_type: string|null
  source_id: string|null
  addl_source_type: string|null
  addl_source_id: string|null
  type_type: string|null
  type_id: string|null
  special_source_type: string|null
  special_source_id: string|null
  union_id: string|null
  craft_code_id: string|null
  gen_liability_state_id: string|null
  sui_sdi_state_id: string|null
  withholding_local_2_id: string|null
  withholding_local_id: string|null
  withholding_state_id: string|null
  workers_comp_state_id: string|null
  workers_comp_rate_code: string|null
  gen_liability_rate_code: string|null
  order: number
  cost_center: CostCenter
  day: number
  date: Date
  regular_hours: number
  overtime_hours: number
  premium_hours: number
  units: number
  has_override: boolean
  is_certified_payroll_exempt: boolean
  regular_rate: number
  overtime_rate: number
  premium_rate: number
  special_rate: number
  regular_rate_from: RateFrom|null
  overtime_rate_from: RateFrom|null
  premium_rate_from: RateFrom|null
  special_rate_from: RateFrom|null
  account: string|null
  subaccount: string|null
  special_account: string|null
  special_subaccount: string|null
  is_removable: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  craft_code: CraftCode
  gen_liability_state: StateTaxJurisdiction
  sui_sdi_state: StateTaxJurisdiction
  timecard: Timecard
  union: Union
  withholding_local: LocalTaxJurisdiction
  withholding_local2: LocalTaxJurisdiction
  withholding_state: StateTaxJurisdiction
  workers_comp_state: StateTaxJurisdiction
  gen_liability_rate: GenLiabilityRate
  workers_comp_rate: WorkersCompRate
  addl_source: TimecardEntry
  source: TimecardEntry
  special_source: TimecardEntry
  type: TimecardEntry
}
export type TimecardEntries = TimecardEntry[]
export interface TimecardEntryResults extends api.MetApiResults { data: TimecardEntries }
export interface TimecardEntryResult extends api.MetApiResults { data: TimecardEntry }
export interface TimecardEntryMetApiData extends api.MetApiData { data: TimecardEntry }
export interface TimecardEntryResponse extends api.MetApiResponse { data: TimecardEntryMetApiData }

export interface Union {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  employees: Employees
  rates: UnionRates
}
export type Unions = Union[]
export interface UnionResults extends api.MetApiResults { data: Unions }
export interface UnionResult extends api.MetApiResults { data: Union }
export interface UnionMetApiData extends api.MetApiData { data: Union }
export interface UnionResponse extends api.MetApiResponse { data: UnionMetApiData }

export interface UnionRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  union_id: string
  source_type: string
  source_id: string
  order: unknown
  craft_code: string
  craft_level: string
  rate: number
  start_date: Date
  end_date: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  is_active: boolean
  // relations
  craft_code: CraftCode
  union: Union
  source: UnionRate
}
export type UnionRates = UnionRate[]
export interface UnionRateResults extends api.MetApiResults { data: UnionRates }
export interface UnionRateResult extends api.MetApiResults { data: UnionRate }
export interface UnionRateMetApiData extends api.MetApiData { data: UnionRate }
export interface UnionRateResponse extends api.MetApiResponse { data: UnionRateMetApiData }

export interface WTwoCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  box_code: string
  box_number: number|null
  is_401k_deferral: boolean
  is_hire_exempt: boolean
  active: boolean
  start_year: number
  created_at: Date|null
  updated_at: Date|null
}
export type WTwoCodes = WTwoCode[]
export interface WTwoCodeResults extends api.MetApiResults { data: WTwoCodes }
export interface WTwoCodeResult extends api.MetApiResults { data: WTwoCode }
export interface WTwoCodeMetApiData extends api.MetApiData { data: WTwoCode }
export interface WTwoCodeResponse extends api.MetApiResponse { data: WTwoCodeMetApiData }

export interface WorkersCompRate {
  // columns
  id: string
  organization_id: string
  company_id: string
  alternate_code: string|null
  code: string
  description: string|null
  state: string|null
  rate_limit_type: string
  calculate_by: string
  multiply_by: string
  rate_limit: number
  employer_rate: number
  employee_deduction_rate: number
  start_date: Date
  end_date: Date|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // mutators
  is_active: boolean
  // relations
  alt_rate: WorkersCompRate
}
export type WorkersCompRates = WorkersCompRate[]
export interface WorkersCompRateResults extends api.MetApiResults { data: WorkersCompRates }
export interface WorkersCompRateResult extends api.MetApiResults { data: WorkersCompRate }
export interface WorkersCompRateMetApiData extends api.MetApiData { data: WorkersCompRate }
export interface WorkersCompRateResponse extends api.MetApiResponse { data: WorkersCompRateMetApiData }

export interface YTD {
  // columns
  id: string
  organization_id: string
  company_id: string
  employee_id: string
  state_id: string|null
  local_id: string|null
  source_type: string
  source_id: string
  order: number
  year: number
  type: YTDType
  q1_amount: number
  q2_amount: number
  q3_amount: number
  q4_amount: number
  created_at: Date|null
  updated_at: Date|null
  source_name: string|null,
  // relations
  local: LocalTaxJurisdiction
  state: StateTaxJurisdiction
  employee: Employee
  source: YTD
}
export type YTDS = YTD[]
export interface YTDResults extends api.MetApiResults { data: YTDS }
export interface YTDResult extends api.MetApiResults { data: YTD }
export interface YTDMetApiData extends api.MetApiData { data: YTD }
export interface YTDResponse extends api.MetApiResponse { data: YTDMetApiData }

export interface ServiceBillingCategory {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  equipment_utilization_credit_account: string|null
  equipment_utilization_credit_subaccount: string|null
  estimated_burden_sb_expense_account: string|null
  estimated_burden_sb_expense_subaccount: string|null
  employer_benefits_sb_expense_account: string|null
  employer_benefits_sb_expense_subaccount: string|null
  fica_sb_expense_account: string|null
  fica_sb_expense_subaccount: string|null
  medicare_sb_expense_account: string|null
  medicare_sb_expense_subaccount: string|null
  fui_sb_expense_account: string|null
  fui_sb_expense_subaccount: string|null
  sui_sb_expense_account: string|null
  sui_sb_expense_subaccount: string|null
  lit_sb_expense_account: string|null
  lit_sb_expense_subaccount: string|null
  workers_comp_sb_expense_account: string|null
  workers_comp_sb_expense_subaccount: string|null
  gen_liability_sb_expense_account: string|null
  gen_liability_sb_expense_subaccount: string|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  service_billing_types: ServiceBillingTypes
}
export type ServiceBillingCategories = ServiceBillingCategory[]
export interface ServiceBillingCategoryResults extends api.MetApiResults { data: ServiceBillingCategories }
export interface ServiceBillingCategoryResult extends api.MetApiResults { data: ServiceBillingCategory }
export interface ServiceBillingCategoryMetApiData extends api.MetApiData { data: ServiceBillingCategory }
export interface ServiceBillingCategoryResponse extends api.MetApiResponse { data: ServiceBillingCategoryMetApiData }

export interface ServiceBillingCategoryType {
  // columns
  id: string
  type_id: string
  category_id: string
  account: string|null
  subaccount: string|null
  non_tax_account: string|null
  non_tax_subaccount: string|null
  markup_percent: number|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  category: ServiceBillingCategory
  type: ServiceBillingType
}
export type ServiceBillingCategoryTypes = ServiceBillingCategoryType[]
export interface ServiceBillingCategoryTypeResults extends api.MetApiResults { data: ServiceBillingCategoryTypes }
export interface ServiceBillingCategoryTypeResult extends api.MetApiResults { data: ServiceBillingCategoryType }
export interface ServiceBillingCategoryTypeMetApiData extends api.MetApiData { data: ServiceBillingCategoryType }
export interface ServiceBillingCategoryTypeResponse extends api.MetApiResponse { data: ServiceBillingCategoryTypeMetApiData }

export interface ServiceBillingCode {
  // columns
  id: string
  organization_id: string
  company_id: string
  code: string
  description: string|null
  hours: number
  minutes: number
  technicians: number
  technical_level: number
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  work_orders: WorkOrders
}
export type ServiceBillingCodes = ServiceBillingCode[]
export interface ServiceBillingCodeResults extends api.MetApiResults { data: ServiceBillingCodes }
export interface ServiceBillingCodeResult extends api.MetApiResults { data: ServiceBillingCode }
export interface ServiceBillingCodeMetApiData extends api.MetApiData { data: ServiceBillingCode }
export interface ServiceBillingCodeResponse extends api.MetApiResponse { data: ServiceBillingCodeMetApiData }

export interface ServiceBillingInvoice {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  workorder_number: string
  workorder_date: Date|null
  workorder_description: string|null
  invoice_number: string
  invoice_date: Date
  start_date: Date|null
  end_date: Date|null
  call_placed_by: string|null
  customer_po_number: string|null
  categorycode: string|null
  customer_data: string[]|null
  worksite: string[]|null
  total: string[]|null
  items: string[]|null
  sent_count: number
  sent_at: Date|null
  downloaded_at: Date|null
  template: string
  pdf_paths: Record<string, unknown>|null
  created_at: Date|null
  updated_at: Date|null
  // relations
  customer: Customer
  notes: Notes
  media: Media
}
export type ServiceBillingInvoices = ServiceBillingInvoice[]
export interface ServiceBillingInvoiceResults extends api.MetApiResults { data: ServiceBillingInvoices }
export interface ServiceBillingInvoiceResult extends api.MetApiResults { data: ServiceBillingInvoice }
export interface ServiceBillingInvoiceMetApiData extends api.MetApiData { data: ServiceBillingInvoice }
export interface ServiceBillingInvoiceResponse extends api.MetApiResponse { data: ServiceBillingInvoiceMetApiData }

export interface ServiceBillingType {
  // columns
  id: string
  organization_id: string
  company_id: string
  type: string
  abbr: string|null
  name: string|null
  line_type: string|null
  markup_percent: number
  account: string|null
  subaccount: string|null
  non_tax_account: string|null
  non_tax_subaccount: string|null
  index: number
  is_taxable: boolean
  readonly: boolean
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  work_orders: WorkOrders
}
export type ServiceBillingTypes = ServiceBillingType[]
export interface ServiceBillingTypeResults extends api.MetApiResults { data: ServiceBillingTypes }
export interface ServiceBillingTypeResult extends api.MetApiResults { data: ServiceBillingType }
export interface ServiceBillingTypeMetApiData extends api.MetApiData { data: ServiceBillingType }
export interface ServiceBillingTypeResponse extends api.MetApiResponse { data: ServiceBillingTypeMetApiData }

export interface WorkOrder {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  work_site_id: string
  call_employee_id: string|null
  salesperson_employee_id: string|null
  assigned_employee_id: string|null
  union_id: string|null
  category_id: string|null
  billing_rate_type_id: string|null
  customer_purchase_order: string|null
  purchase_order_id: string|null
  gen_liability_state_id: string|null
  sui_sdi_state_id: string|null
  withholding_local_2_id: string|null
  withholding_local_id: string|null
  withholding_state_id: string|null
  workers_comp_state_id: string|null
  series: string
  number: string
  status: string
  compliance_format: string
  description: string|null
  call_placed_by: string|null
  last_invoice_number: string|null
  last_printed_invoice_number: string|null
  recurring_method: string|null
  gen_liability_rate_code: string|null
  workers_comp_rate_code: string|null
  date: Date
  last_invoice_date: Date|null
  actual_start_date: Date|null
  completion_date: Date|null
  last_printed_invoice_date: Date|null
  next_recurring_date: Date|null
  is_certified_payroll: boolean
  is_history_posted: boolean
  was_printed: boolean
  recurring_count: number|null
  minimum_cost_quoted_amount: number
  estimated_cost_quoted_amount: number
  commission_paid_amount: number
  commission_earned_amount: number
  method_interval: number
  timetables: Record<string, unknown>
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  assigned_employee: Employee
  billing_rate_type: BillingRateType
  call_employee: Employee
  category: ServiceBillingCategory
  purchase_order: PurchaseOrder
  salesperson_employee: Employee
  service_billing_codes: ServiceBillingCodes
  service_billing_types: ServiceBillingTypes
  sui_sdi_state: StateTaxJurisdiction
  union: Union
  withholding_local: LocalTaxJurisdiction
  withholding_local2: LocalTaxJurisdiction
  withholding_state: StateTaxJurisdiction
  work_site: WorkSite
  customer: Customer
  gen_liability_rate: GenLiabilityRate
  workers_comp_rate: WorkersCompRate
  cost_amount: number | null
  income_amount: number | null
  notes: Notes
}
export type WorkOrders = WorkOrder[]
export interface WorkOrderResults extends api.MetApiResults { data: WorkOrders }
export interface WorkOrderResult extends api.MetApiResults { data: WorkOrder }
export interface WorkOrderMetApiData extends api.MetApiData { data: WorkOrder }
export interface WorkOrderResponse extends api.MetApiResponse { data: WorkOrderMetApiData }

export interface WorkSite {
  // columns
  id: string
  organization_id: string
  company_id: string
  customer_id: string
  category_id: string|null
  district_id: string|null
  sales_person_id: string|null
  withholding_state_id: string|null
  sui_sdi_state_id: string|null
  withholding_local_id: string|null
  withholding_local_2_id: string|null
  number: number
  street_name: string
  street_number: string
  unit_type: string|null
  unit_number: string|null
  city: string
  state: string
  postal_code: string
  worksite_type: string|null
  street_direction_code: string|null
  cross_streets: string|null
  worksite_phone: string|null
  other_phone: string|null
  fax: string|null
  name: string|null
  contact_name: string|null
  authorizing_person_name: string|null
  user_notes: Record<string, unknown>|null
  is_used: boolean
  created_at: Date|null
  updated_at: Date|null
  // relations
  category: ServiceBillingCategory
  sales_person: Employee
  sui_sdi_state: StateTaxJurisdiction
  withholding_local: LocalTaxJurisdiction
  withholding_local2: LocalTaxJurisdiction
  withholding_state: StateTaxJurisdiction
  work_orders: WorkOrders
  customer: Customer
  district: District
  notes: Notes
}
export type WorkSites = WorkSite[]
export interface WorkSiteResults extends api.MetApiResults { data: WorkSites }
export interface WorkSiteResult extends api.MetApiResults { data: WorkSite }
export interface WorkSiteMetApiData extends api.MetApiData { data: WorkSite }
export interface WorkSiteResponse extends api.MetApiResponse { data: WorkSiteMetApiData }

const CostCenter = {
  GA: 'G&A',
  JOB: 'JOB',
  LAB: 'LAB',
  EQP: 'EQP',
  INV: 'INV',
  SB: 'S/B',
} as const;

export type CostCenter = typeof CostCenter[keyof typeof CostCenter]

const BillingType = {
  Progress: 'PRG',
  LumpSum: 'LMP',
  UnitPrice: 'UPB',
  CostPlus: 'CPB',
  Service: 'SB',
  Initialization: 'INIT',
} as const;

export type BillingType = typeof BillingType[keyof typeof BillingType]

const BankAccountType = {
  Checking: 'checking',
  Savings: 'savings',
} as const;

export type BankAccountType = typeof BankAccountType[keyof typeof BankAccountType]

const BankDirectDepositScope = {
  AP: 'accounts-payable',
  PR: 'payroll',
} as const;

export type BankDirectDepositScope = typeof BankDirectDepositScope[keyof typeof BankDirectDepositScope]

const BankDirectDepositServiceClassCode = {
  DebitsCredits: '200',
  Credits: '220',
  Debits: '225',
} as const;

export type BankDirectDepositServiceClassCode = typeof BankDirectDepositServiceClassCode[keyof typeof BankDirectDepositServiceClassCode]

const BankDirectDepositStandardEntryCode = {
  PPD: 'ppd',
  CCD: 'ccd',
} as const;

export type BankDirectDepositStandardEntryCode = typeof BankDirectDepositStandardEntryCode[keyof typeof BankDirectDepositStandardEntryCode]

const WaiverReleaseModule = {
  AP: 'accounts-payable',
  AR: 'accounts-receivable',
} as const;

export type WaiverReleaseModule = typeof WaiverReleaseModule[keyof typeof WaiverReleaseModule]

const EquipmentStatus = {
  Active: 'active',
  Inactive: 'inactive',
} as const;

export type EquipmentStatus = typeof EquipmentStatus[keyof typeof EquipmentStatus]

const EquipmentAssetType = {
  Owned: 'owned',
  Financed: 'financed',
  Leased: 'leased',
  Rented: 'rented',
  Disposed: 'disposed',
} as const;

export type EquipmentAssetType = typeof EquipmentAssetType[keyof typeof EquipmentAssetType]

const EquipmentDisposition = {
  Sold: 'sold',
  Lost: 'lost',
  Junked: 'junked',
  Returned: 'returned',
  Active: 'active',
} as const;

export type EquipmentDisposition = typeof EquipmentDisposition[keyof typeof EquipmentDisposition]

const EquipmentDepreciationStatus = {
  Pending: 'pending',
  Posted: 'posted',
} as const;

export type EquipmentDepreciationStatus = typeof EquipmentDepreciationStatus[keyof typeof EquipmentDepreciationStatus]

const FinancialReportType = {
  BalanceSheet: 'balance-sheet',
  IncomeStatement: 'income-statement',
  Other: 'other',
} as const;

export type FinancialReportType = typeof FinancialReportType[keyof typeof FinancialReportType]

const TransactionType = {
  Cost: 'cost',
  CostRetention: 'cost-retention',
  Income: 'income',
  IncomeRetention: 'income-retention',
  GA: 'g&a',
} as const;

export type TransactionType = typeof TransactionType[keyof typeof TransactionType]

const JournalAction = {
  None: 'none',
  Post: 'post',
  Void: 'void',
} as const;

export type JournalAction = typeof JournalAction[keyof typeof JournalAction]

const ReconciliationStatus = {
  Pending: 'pending',
  Posted: 'posted',
} as const;

export type ReconciliationStatus = typeof ReconciliationStatus[keyof typeof ReconciliationStatus]

const TransactionStatus = {
  Outstanding: 'outstanding',
  Reconciled: 'reconciled',
  Variance: 'variance',
} as const;

export type TransactionStatus = typeof TransactionStatus[keyof typeof TransactionStatus]

const TransactionCategory = {
  Credit: 'credit',
  Debit: 'debit',
} as const;

export type TransactionCategory = typeof TransactionCategory[keyof typeof TransactionCategory]

const StateTaxType = {
  SIT: 'income-tax',
  SDI: 'disability-insurance',
} as const;

export type StateTaxType = typeof StateTaxType[keyof typeof StateTaxType]

const InventoryBatchType = {
  COGSTransfer: 'cogs-transfer',
  InventoryAdjustments: 'inventory-adjustments',
  ReceiveMaterials: 'receive-materials',
} as const;

export type InventoryBatchType = typeof InventoryBatchType[keyof typeof InventoryBatchType]

const MaterialStatus = {
  Active: 'active',
  Inactive: 'inactive',
} as const;

export type MaterialStatus = typeof MaterialStatus[keyof typeof MaterialStatus]

const JobStatus = {
  Open: 'open',
  Closed: 'closed',
  Inactive: 'inactive',
} as const;

export type JobStatus = typeof JobStatus[keyof typeof JobStatus]

const JobCertifiedPayroll = {
  YesWithNonPerformingWeek: 'y-w/-non-perf',
  YesWithoutNonPerformingWeek: 'y-w/o-non-perf',
  No: 'no',
} as const;

export type JobCertifiedPayroll = typeof JobCertifiedPayroll[keyof typeof JobCertifiedPayroll]

const LineItemEditOption = {
  InJob: 'code-edit-in-job',
  InJobOrMaster: 'code-edit-in-job-or-master',
  OnTheFly: 'code-edit-in-transaction',
} as const;

export type LineItemEditOption = typeof LineItemEditOption[keyof typeof LineItemEditOption]

const LineItemStatus = {
  Open: 'open',
  Closed: 'closed',
  Inactive: 'inactive',
} as const;

export type LineItemStatus = typeof LineItemStatus[keyof typeof LineItemStatus]

const LineItemCertifiedPayroll = {
  YesWithoutUnion: 'yes-w/o-union',
  YesWithoutUnionAndCraft: 'yes-w/o-union-and-craft',
  YesWithUnion: 'yes-with-union',
  No: 'no',
} as const;

export type LineItemCertifiedPayroll = typeof LineItemCertifiedPayroll[keyof typeof LineItemCertifiedPayroll]

const PayRateAdjustment = {
  NoAdjustment: 'no-adjustment',
  Dollar: 'dollar',
  Percentage: 'percentage',
} as const;

export type PayRateAdjustment = typeof PayRateAdjustment[keyof typeof PayRateAdjustment]

const ProposalStatus = {
  Open: 'open',
  Inactive: 'inactive',
  Closed: 'closed',
} as const;

export type ProposalStatus = typeof ProposalStatus[keyof typeof ProposalStatus]

const ProposalEntryType = {
  Cost: 'cost',
  Income: 'income',
} as const;

export type ProposalEntryType = typeof ProposalEntryType[keyof typeof ProposalEntryType]

const ProposalEntryStatus = {
  Pending: 'pending',
  Approved: 'approved',
  CreateBudget: 'create-budget',
  BudgetCreated: 'budget-created',
  Rejected: 'rejected',
} as const;

export type ProposalEntryStatus = typeof ProposalEntryStatus[keyof typeof ProposalEntryStatus]

const CodeScope = {
  Federal: 'federal',
  State: 'state',
  Local: 'local',
} as const;

export type CodeScope = typeof CodeScope[keyof typeof CodeScope]

const RateFrom = {
  Employee: 'employee',
  EmployeeRate: 'employee-rate',
  Equipment: 'equipment',
  FederalTaxCode: 'federal-tax-code',
  GenLiabilityRate: 'gen-liability-rate',
  Import: 'import',
  JobRate: 'job-rate',
  Material: 'material',
  NotUsed: 'not-used',
  RegularTimes15: 'regular-times-1.5',
  RegularTimes20: 'regular-times-2',
  Somewhere: 'somewhere',
  StateTaxCode: 'state-tax-code',
  Transfer: 'transfer',
  UnionRate: 'union-rate',
  Unknown: 'unknown',
  Undefined: 'undefined',
  UserDefined: 'user-defined',
  WorkersCompRate: 'workers-comp-rate',
} as const;

export type RateFrom = typeof RateFrom[keyof typeof RateFrom]

const PayrollEntryScope = {
  Employee: 'employee',
  Employer: 'employer',
} as const;

export type PayrollEntryScope = typeof PayrollEntryScope[keyof typeof PayrollEntryScope]

const PayrollEntryCategory = {
  Benefits: 'benefits',
  Deductions: 'deductions',
  EmployeeTaxes: 'employee-taxes',
  EmployerTaxes: 'employer-taxes',
  GrossPay: 'gross-pay',
  Insurance: 'insurance',
  NonPayroll: 'non-payroll',
} as const;

export type PayrollEntryCategory = typeof PayrollEntryCategory[keyof typeof PayrollEntryCategory]

const AccrualMethod = {
  PerAnniversaryYear: 'per_anniversary_year',
  PerCalendarYear: 'per_calendar_year',
  PerPayPeriod: 'per_pay_period',
  PerHourPaid: 'per_hour_paid',
  PerHourPaidNoOvertime: 'per_hour_paid_no_overtime',
  PerHourWorked: 'per_hour_worked',
  Unlimited: 'unlimited',
} as const;

export type AccrualMethod = typeof AccrualMethod[keyof typeof AccrualMethod]

const TimeOffTypeKind = {
  Bereavement: 'bereavement',
  Custom: 'custom',
  FloatingHoliday: 'floating-holiday',
  HolidayPay: 'holiday-pay',
  JuryDuty: 'jury-duty',
  Learning: 'learning',
  ParentalLeave: 'parental-leave',
  PersonalDay: 'personal-day',
  Sick: 'sick',
  Unpaid: 'unpaid',
  Vacation: 'vacation',
  Volunteer: 'volunteer',
  Weather: 'weather',
} as const;

export type TimeOffTypeKind = typeof TimeOffTypeKind[keyof typeof TimeOffTypeKind]

const TimeOffTypeStrategy = {
  PaidInFull: 'paid-in-full',
} as const;

export type TimeOffTypeStrategy = typeof TimeOffTypeStrategy[keyof typeof TimeOffTypeStrategy]

const YTDType = {
  Benefits: 'benefits',
  Deductions: 'deductions',
  Earnings: 'earnings',
  Taxes: 'taxes',
  W2Memos: 'w2-memos',
} as const;

export type YTDType = typeof YTDType[keyof typeof YTDType]

export interface DirectDepositCode {
  id: string
  code: string
  description: string
  active: boolean
  show_in_ytds: boolean
}
