<template>
  <BaseDropdown
    v-bind="$attrs"
    :tooltip-content="$t('Reports')"
    class="relative"
    :class="{
      'is-dropdown-open': showDropdown,
    }"
    ref="dropdown"
    @change="onDropdownChange"
  >
    <template v-slot:trigger>
      <BaseButton
        variant="white"
        class="ml-2 report-actions-dropdown-button"
        size="sm"
      >
        <PieChartIcon class="w-4 h-4 md:mr-2" />
        <span class="hidden md:flex">{{ $t(buttonText) }}</span>
        <IconArrowDown
          class="hidden md:flex ml-1 transition duration-75"
          :class="{'rotate-180': showDropdown}"
        />
      </BaseButton>
    </template>
    <div class="report-actions-dropdown">
      <slot />
    </div>
  </BaseDropdown>
</template>
<script>
import { MenuIcon, PieChartIcon } from 'vue-feather-icons'

export default {
  name: 'TableActionsDropdown',
  components: {
    MenuIcon,
    PieChartIcon,
  },
  props: {
    buttonText: {
      type: String,
      default: 'Reports'
    }
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  methods: {
    onDropdownChange(value) {
      this.showDropdown = value
    }
  },
}
</script>
<style scoped>
.report-actions-dropdown {
  min-width: 250px;
}

.report-actions-dropdown-button {
  min-height: 38px;
}
</style>
