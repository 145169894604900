<template>
  <div class="-mt-6">
    <layout-tabs :items="items"/>
    <slot/>
  </div>
</template>
<script>

export default {
  computed: {
    currentJobId() {
      return this.$route.params.id
    },
    items() {
      return [
        {
          name: this.$t('Timesheets'),
          path: `/job-costing/jobs/${this.currentJobId}/timesheets`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Daily Reports'),
          path: `/job-costing/jobs/${this.currentJobId}/daily-reports`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]
    }
  }
}
</script>
