import axios from 'axios'
import { getStartTimeForDate } from "@/modules/payroll/utils/timeCardUtils";
import uniqBy from "lodash/uniqBy";
import { resizeImageFile } from "@/modules/common/util/fileUtils";
import { startOfWeek } from "date-fns";

let getTimeEntriesController = new AbortController();

const types = {
  SET_TIMESHEET_ENTRIES: 'SET_TIMESHEET_ENTRIES',
  SET_TIMESHEETS: 'SET_TIMESHEETS',
  SET_TIMESHEET_ENTRIES_LOADING: 'SET_TIMESHEET_ENTRIES_LOADING',
  SET_RECENT_TASKS: 'SET_RECENT_TASKS',
  SET_RECENT_TASKS_LOADING: 'SET_RECENT_TASKS_LOADING',
  ADD_RECENT_TASK: 'ADD_RECENT_TASK',
  UPDATE_ENTRY: 'UPDATE_ENTRY',
  DELETE_ENTRY: 'DELETE_ENTRY',
  ADD_ENTRY: 'ADD_ENTRY',
  SET_START_OF_WEEK: 'SET_START_OF_WEEK',
  SET_DAILY_REPORT: 'SET_DAILY_REPORT',
  SET_DAILY_REPORT_LOADING: 'SET_DAILY_REPORT_LOADING',
}

const state = {
  timesheets: [],
  timesheetEntries: [],
  timesheetEntriesLoading: false,
  recentTasks: [],
  recentTasksLoading: false,
  selectedStartOfWeek: startOfWeek(new Date()),
  currentDailyReport: null,
  currentDailyReportLoading: false,
}

const mutations = {
  [types.SET_TIMESHEET_ENTRIES]: (state, value) => {
    state.timesheetEntries = value
  },
  [types.SET_TIMESHEET_ENTRIES_LOADING]: (state, value) => {
    state.timesheetEntriesLoading = value
  },
  [types.UPDATE_ENTRY]: (state, { index, value }) => {
    state.timesheetEntries.splice(index, 1, value)
  },
  [types.DELETE_ENTRY]: (state, entry) => {
    const index = getEntryIndex(state.timesheetEntries, entry)
    state.timesheetEntries.splice(index, 1)
  },
  [types.ADD_ENTRY]: (state, value) => {
    state.timesheetEntries.push(value)
  },
  [types.SET_TIMESHEETS]: (state, value) => {
    state.timesheets = value
  },
  [types.SET_RECENT_TASKS]: (state, value) => {
    state.recentTasks = uniqBy(value, task => {
      return [task.attributes.source_id, task.attributes.addl_source_id].join('-')
    })
  },
  [types.SET_RECENT_TASKS_LOADING]: (state, value) => {
    state.recentTasksLoading = value
  },
  [types.ADD_RECENT_TASK]: (state, value) => {
    state.recentTasks.push(value)
    state.recentTasks = uniqBy(state.recentTasks, 'attributes.source_id')
  },
  [types.SET_START_OF_WEEK]: (state, value) => {
    state.selectedStartOfWeek = value
  },
  [types.SET_DAILY_REPORT]: (state, value) => {
    state.currentDailyReport = value
  },
  [types.SET_DAILY_REPORT_LOADING]: (state, value) => {
    state.currentDailyReportLoading = value
  },
}

function getEntryIndex(entries, entry) {
  return entries.findIndex(e => e.id === entry.id)
}

function getTimesheet(timesheets, id) {
  return timesheets.find(t => t.id === id)
}

const actions = {
  async getTimesheetEntries({ commit, rootState }, params = {}) {
    try {
      commit(types.SET_TIMESHEET_ENTRIES_LOADING, true)
      if (getTimeEntriesController) {
        getTimeEntriesController.abort()
        getTimeEntriesController = new AbortController()
      }

      const [timesheetEntries, timesheets] = await Promise.all([
        axios.get('/restify/timesheet-entries', {
          signal: getTimeEntriesController.signal,
          params: {
            ...params,
            related: 'addlSource'
          },
        }),
        axios.get('/restify/timesheets', {
          params,
        })
      ])
      const entries = timesheetEntries.data.map((entry) => {
        entry.attributes.initial_duration = entry.attributes.duration
        entry.attributes.timesheet = timesheets.data.find(t => t.id === entry.attributes.timesheet_id)
        return entry
      })
      commit(types.SET_TIMESHEET_ENTRIES, entries)
      commit(types.SET_TIMESHEETS, timesheets.data)
      return entries
    } finally {
      commit(types.SET_TIMESHEET_ENTRIES_LOADING, false)
    }
  },
  async addEntry({ commit, dispatch }, entry) {
    const { data } = await axios.post('/restify/timesheet-entries', {
      ...entry,
      files: [],
      end_time: undefined,
    }, {
      params: {
        related: 'timesheet'
      }
    })
    const newEntry = data.attributes
    newEntry.files = await dispatch('attachFilesToEntry', { entry: newEntry, files: entry.files })
    if (newEntry.files?.length) {
      await axios.patch(`/restify/timesheet-entries/${newEntry.id}`, {
        files: newEntry.files,
      })
    }
    data.attributes.files = newEntry.files
    data.attributes.timesheet = data?.relationships?.timesheet
    commit(types.ADD_ENTRY, data)
    return data
  },
  async updateEntry({ commit, dispatch }, entry) {
    const index = getEntryIndex(state.timesheetEntries, entry)
    const oldEntry = state.timesheetEntries[index]
    try {
      entry.files = await dispatch('attachFilesToEntry', { entry, files: entry.files })
      const { data } = await axios.put(`/restify/timesheet-entries/${entry.id}`, entry)
      data.attributes.files = entry.files
      data.attributes.timesheet = getTimesheet(state.timesheets, data.attributes?.timesheet_id)
      if (index !== -1) {
        commit(types.UPDATE_ENTRY, { index, value: data })
      }
      return data
    } catch (err) {
      if (oldEntry && index !== -1) {
        commit(types.UPDATE_ENTRY, { index, value: oldEntry })
      }
      throw err
    }
  },
  async deleteEntry({ commit, dispatch }, entry) {
    const index = getEntryIndex(state.timesheetEntries, entry)
    const oldEntry = state.timesheetEntries[index]
    try {
      await axios.delete(`/restify/timesheet-entries/${entry.id}`)
      commit(types.DELETE_ENTRY, entry)
    } catch (err) {
      if (oldEntry && index !== -1) {
        commit(types.UPDATE_ENTRY, { index, value: oldEntry })
      }
      throw err
    }
  },
  async attachFilesToEntry({ commit }, { entry, files }) {
    if (!files?.length) {
      files = []
    }
    const formData = new FormData()
    const validFiles = files.filter((file) => file.raw)
    const oldFiles = entry.files?.filter(file => !file.raw) || []
    if (!validFiles.length) {
      return oldFiles
    }
    for (const file of validFiles) {
      const resizedImage = await resizeImageFile(file.raw, 1000)
      formData.append('files[]', resizedImage)
    }

    const { data } = await axios.post(`/restify/timesheet-entries/${entry.id}/actions?action=create-attachments`, formData)
    const previousFiles = entry.files.filter(file => !file.raw)
    return [...previousFiles, ...data.files]
  },
  async getRecentTasks({ commit}) {
    try {
      commit(types.SET_RECENT_TASKS_LOADING, true)
      const { data } = await axios.get(`/restify/tasks`, {
        params: {
          sort: '-created_at',
          perPage: 20,
          related: 'source,addlSource',
        }
      })
      commit(types.SET_RECENT_TASKS, data)
    } finally {
      commit(types.SET_RECENT_TASKS_LOADING, false)
    }
  },
  addRecentTask({ commit }, task) {
    commit(types.ADD_RECENT_TASK, task)
  },
  async getDailyReport({ commit }, agencyId) {
    try {
      commit(types.SET_DAILY_REPORT_LOADING, true)
      const params = {
        related: 'crew,foreman'
      }
      const { data } = await axios.get(`/restify/daily-job-reports/${agencyId}`, {
        params,
      })
      commit(types.SET_DAILY_REPORT, data)
    } finally {
      commit(types.SET_DAILY_REPORT_LOADING, false)
    }
  }
}

const getters = {
  startTime: (state) =>{
    return (date) => {
      return getStartTimeForDate(state.timesheetEntries, date)
    }
  }
}

export default {
  namespaced: true,
  types,
  state,
  mutations,
  actions,
  getters,
}
