<template>
    <entity-preview
        v-bind="$attrs"
        :entity="vendorData"
        :key="`preview-${vendorData?.id}`"
        :url="`/accounts-payable/vendors/${vendorData?.id}/view`"
        :value-to-display="valueToDisplay"
        :parent-entity-id="vendorData?.id"
        :show-preview="showPreview"
        :target="target"
        :link-class="linkClass"
        :is-link="!editableCell"
        entity-name="vendor"
    />
</template>
<script>
  export default {
    name: 'VendorLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      showPreview: {
        type: Boolean,
        default: true,
      },
      target: String,
      linkClass: String,
      editableCell: Boolean,
    },
    computed: {
      allVendors() {
        return this.$store.state.globalLists.vendors
      },
      vendorData() {
        if (this.id) {
          return this.allVendors.find(vendor => vendor.id === this.id)
        }

        return this.data?.attributes || this.data || {}
      },
      valueToDisplay() {
        if (!this.vendorData?.id) {
          return
        }

        if (!this.showDescription || !this.vendorData.name) {
          return this.vendorData.code
        }
        return `${this.vendorData.code} (${this.vendorData.name})`
      },
    },
  }
</script>
