<template>
  <settings-form
      :fields="fields"
      :module-name="moduleName"
      @cancel="$router.push('/accounts-payable/vendors')"
  >
    <template v-slot="{model}">
      <div class="col-span-6">
        <h5 class="form-section-title">
          {{ $t('General') }}
        </h5>
      </div>
      <div class="col-span-3 lg:col-span-1">
        <account-select v-model="model.account"
                        :label="$t('Account')"
                        value-key="number"
                        rules="required"
                        warningOnChange
        />
      </div>
      <div class="col-span-3 lg:col-span-1">
        <sub-account-select v-model="model.subaccount"
                            :label="$t('Sub Account')"
                            value-key="number"
        />
      </div>
      <div class="col-span-3 lg:col-span-1">
        <bank-select v-model="model.default_bank_id"
                     :used-for="BankUsedInTypes.AccountsPayable"
                     :label="$t('Default Bank')"
                     rules="required"
        />
      </div>
      <div class="col-span-3 lg:col-span-1">
        <base-select v-model="model.default_invoice_status"
                     :label="$t('Default Invoice Status')"
                     :placeholder="$t('Default Invoice Status')"
                     :options="invoiceStatusOptions"
        />
      </div>
      <div class="col-span-6 lg:col-span-1">
        <base-switch v-model="model.close_po_after_posting_invoice"
                     :label-info="$t('Close PO After Posting Invoice')"
        />
      </div>
      <div class="col-span-6">
        <h5 class="form-section-title">
          {{ $t('Discount Settings') }}
        </h5>
      </div>
      <div class="col-span-3 lg:col-span-1">
        <account-select v-model="model.discounts_taken_account"
                        :label="$t('Discounts Taken Account')"
                        value-key="number"
                        rules="required"
                        warningOnChange
        />
      </div>
      <div class="col-span-3 lg:col-span-1">
        <sub-account-select v-model="model.discounts_taken_subaccount"
                            :label="$t('Discounts Taken Sub Account')"
                            value-key="number"
        />
      </div>
      <div class="col-span-6 lg:col-span-1">
        <base-switch v-model="model.force_discounts"
                     :label-info="$t('Force Discounts')"
        />
      </div>
      <div class="col-span-6 lg:col-span-2">
        <base-switch v-model="model.post_discounts_to_expense_accounts"
                     :label-info="$t('Post Discounts to Job/Expense Accounts')"
        />
      </div>
    </template>
  </settings-form>
</template>
<script>
  import SettingsForm from "@/modules/common/components/SettingsForm";
  import { invoiceStatusOptions } from "@/modules/accounts-payable/enum/invoice";
  import { BankUsedInTypes } from "@/enum/enums";

  export default {
    components: {
      SettingsForm,
    },
    data() {
      return {
        moduleName: 'accounts-payable',
        invoiceStatusOptions,
        fields: [
          'account',
          'subaccount',
          'calendar_year',
          'close_po_after_posting_invoice',
          'default_bank_id',
          'default_invoice_status',
          'discounts_taken_account',
          'discounts_taken_subaccount',
          'force_discounts',
          'last_ap_journal_number_posted',
          'last_cd_journal_number_posted',
          'last_direct_deposit_check_no',
          'last_fiscal_period_posted',
          'last_fiscal_year_posted',
          'post_discounts_to_expense_accounts',
        ],
      }
    },
    computed: {
      BankUsedInTypes() {
        return BankUsedInTypes
      },
      periods() {
        return this.$store.getters['company/getAuthorizedToUsePeriods']
      },
    },
    methods: {
      periodLabel(periodNumber) {
        return this.$store.getters['company/getPeriodName'](periodNumber)
      }
    }
  }
</script>
