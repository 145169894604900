<template>

  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >
    <template #filters>
      <AccountFilterRow
        v-model="model"
        @reset="resetAccountFilters"
      >
        <template #extra-filters>
          <BaseCheckbox
            v-model="model.exclude_inactive"
            :label="$t('Exclude Inactive Accounts')"
            class="col-span-2"
            id="exclude_inactive"
          />
          <SubAccountRangeFilter
            v-model="model"
            grid-class="col-span-3"
          />
        </template>
      </AccountFilterRow>
      <BaseFilterRow>
        <BaseCheckbox
          v-model="model.include_budgets"
          :label="$t('Include Budget Amounts on Listing')"
          class="col-span-3 mb-3"
          id="include_budgets"
        />
      </BaseFilterRow>
      <base-filter-row :title="$t('Fiscal Year')">
        <fiscal-year-select
          v-model="model.fiscal_year"
          :placeholder="$t('Fiscal Year')"
          class="col-span-6 md:col-span-2"
          :show-closed-years="true"
        />
      </base-filter-row>
    </template>

    <template #table="{data, loading}">

      <sub-accounts-budgets-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :include-budgets="model.include_budgets"
          ref="reportTable"
      />

    </template>

  </base-report-form>

</template>
<script lang="ts">
  import { defineComponent  } from 'vue'
  import { SubAccountRangeFilter } from '@/components/range-filters'
  import SubAccountsBudgetsReportTable from '@/modules/ledger/components/reports/SubAccountsBudgetsReportTable.vue'
  import AccountFilterRow from '@/components/entity-filters/AccountFilterRow.vue'

  export default defineComponent({
    components: {
      AccountFilterRow,
      SubAccountRangeFilter,
      SubAccountsBudgetsReportTable,
    },
    data() {
      return {
        reportNumber: 1883,
        model: <GL_REPORTS.SubAccountBudget> {
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          subaccount_number_from: null,
          subaccount_number_to: null,
          exclude_inactive: true,
          include_budgets: false,
          fiscal_year: this.$currentFiscalYear,
        },
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.subaccount_number_from = this.model.subaccount_number_to = null
      },
    },
  })
</script>
