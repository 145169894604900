<template>
  <RecurringLumpSumBillingForm
    :key="renderKey"
    @create-another="renderKey++"
  />
</template>
<script>
import RecurringLumpSumBillingForm from '@/modules/accounts-receivable/components/recurring-billings/lump-sum/RecurringLumpSumBillingForm.vue'

export default {
  components: {
    RecurringLumpSumBillingForm,
  },
  data() {
    return {
      renderKey: 1,
    }
  },
}
</script>
