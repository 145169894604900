<template>
  <div class="truncate">
    <RouterLink
      v-if="crewId && isLink"
      :to="`/payroll/timesheets/crews?id=${crewId}`"
      :target="target"
      :class="$attrs.class"
    >
      <span>
        {{ valueToDisplay }}
      </span>
    </RouterLink>
    <template v-else>
      <span>
        {{ valueToDisplay }}
      </span>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'CrewLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      crewId() {
        return this.data?.id
      },
      valueToDisplay() {
        return this.data?.attributes?.name
      },
    },
  }
</script>
