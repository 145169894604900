<template>
  <BaseForm
    :layout="layout"
    :loading="loading"
    :show-cancel="showCancel"
    :show-back="showBack"
    :can-create-another-entity="!model.id"
    :save-text="$t('Create agency')"
    :update-text="$t('Update agency')"
    grid-classes="grid grid-cols-8 gap-x-4"
    @cancel="onCancel"
    @submit="onSubmit"
  >
    <BaseInput
      v-model="model.name"
      :label="$t('Name')"
      :placeholder="$t('Name')"
      id="name"
      class="col-span-8 md:col-span-4 lg:col-span-2"
      rules="required|max:100"
      @blur="onNameBlur"
    />
    <CodeInput
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.Agencies"
      class="col-span-8 md:col-span-4 lg:col-span-2"
    />
    <BaseInput
      v-model="model.account_number"
      :label="$t('Account Number')"
      :placeholder="$t('Account Number')"
      id="account_number"
      class="col-span-8 md:col-span-4 lg:col-span-2"
      rules="required|max:255"
    />
    <VendorStatusSelect
      v-model="model.status"
      class="col-span-8 md:col-span-2 xl:col-span-1"
    />
    <BaseInput
      v-model="model.website_url"
      :name="$t('Website URL')"
      :label="$t('Website URL')"
      :placeholder="$t('Enter website url...')"
      id="website_url"
      class="col-span-8 md:col-span-4 lg:col-span-2"
      rules="url"
    />
    <div class="col-span-8"/>
    <BaseTextarea
      v-model="model.description"
      :label="$t('Description')"
      :placeholder="$t('Description')"
      id="description"
      class="col-span-8 md:col-span-4"
      rules="max:65535"
    />
    <div class="col-span-8">
      <ElCollapse v-model="activeGroups">
        <BaseCollapseSection
          :title="$t('Addresses & Contacts')"
          key="addresses_and_contacts"
          name="addresses_and_contacts"
        >
          <div class="grid grid-cols-2 gap-x-4">
            <AddressList
              :data="model.addresses"
              :entity-id="model.id"
              :resource="model"
              ref="addressList"
              entity="agencies"
              class="col-span-2 md:col-span-1"
              @refresh="$emit('refresh')"
            />
            <ContactList
              :contacts="model.contacts"
              :entity-id="model.id"
              ref="contactList"
              entity="agencies"
              class="col-span-2 md:col-span-1"
              @refresh="$emit('refresh')"
            />
          </div>
        </BaseCollapseSection>
      </ElCollapse>
    </div>
  </BaseForm>
</template>
<script>
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import ContactList from '@/modules/common/components/contacts/ContactList.vue'
import AddressList from '@/modules/common/components/AddressList'
import { generateCodeFromName } from '@/utils/generateCodeFromName'
import {
  resourceStatuses,
} from '@/enum/enums'
import ExpirationDateList from '@/modules/accounts-payable/components/defined-fields/ExpirationDateList'
import { globalResources, RestifyResources } from "@/components/form/util";
import VendorStatusSelect from "@/modules/accounts-payable/components/VendorStatusSelect.vue";

const COLLAPSE_ITEMS = [
  'addresses_and_contacts',
]
export default {
  components: {
    ContactList,
    AddressList,
    ExpirationDateList,
    VendorStatusSelect,
  },
  props: {
    redirectToListAfterStore: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalResources,
      localLoading: false,
      activeGroups: [],
      loading: false,
      showCancel: true,
      model: {
        code: '',
        name: '',
        account_number: '',
        website_url: '',
        status: resourceStatuses.Active,
        description: '',
        addresses: [],
        contacts: [],
      },
    }
  },
  computed: {
    layout() {
      return this.redirectToListAfterStore ? 'vertical': 'modal'
    },
  },
  methods: {
    onNameBlur() {
      if (this.data.id) {
        return
      }
      this.model.code = generateCodeFromName(this.model.name)
    },
    onCancel() {
      if (this.redirectToListAfterStore) {
        return this.$router.push('/accounts-payable/agencies')
      }
      this.$emit('cancel')
    },
    async onSubmit() {
      try {
        this.loading = true
        const model = this.cleanupModel(this.model)

        if (model.id) {
          const { data } = await axios.put(`/restify/agencies/${model.id}`, model)
          this.$emit('save', data)
          this.$success(this.$t('Agency updated'))
          await this.$store.dispatch('accountsPayable/getAgency', data.id)
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.Agencies,
          //   resourceId: data.id,
          //   isEdit: true,
          // })
        } else {
          const { data } = await axios.post('/restify/agencies', model)
          await this.attachAddressesAndContacts(data.id)
          this.$success(this.$t('Agency created'))

          if (this.$createAnotherEntity && this.redirectToListAfterStore) {
            return this.$emit('create-another')
          }
          if (this.redirectToListAfterStore) {
            await this.$router.push('/accounts-payable/agencies')
          }
          this.$emit('save', data)
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.Agencies,
          //   resourceId: data.id,
          // })
        }
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
      }
    },
    cleanupModel(model) {
      let data = cloneDeep(model)

      for (let key in data) {
        if (data[key]===null) {
          delete data[key]
        }
      }

      return data
    },
    async attachAddressesAndContacts(agencyId) {
      await this.$refs.addressList.attachTemporaryAddresses(agencyId)
      await this.$refs.contactList.attachTemporaryContacts(agencyId)
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        this.model = {
          ...this.model,
          ...value,
        }
        if (!value?.id) {
          this.activeGroups = COLLAPSE_ITEMS
          return
        }
      },
    },
  },
}
</script>
