import VueRouter from 'vue-router'
import authRoutes from '@/modules/auth/routes'
import publicRoutes from '@/modules/public/routes'
import reportPrintRoutes from '@/modules/print/routes'
import wikiRoutes from '@/modules/wiki/routes'
import dashboardRoutes from '@/modules/dashboard/routes'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const routes = [
  ...authRoutes,
  ...publicRoutes,
  ...reportPrintRoutes,
  ...wikiRoutes,
  ...dashboardRoutes
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }

})

function handleRouterError(error) {
  const message = error?.message || ''
  if (!(message?.includes('redundant navigation'))) {
    throw error;
  }
}

const originalReplace = router.replace

router.replace = function replace(location) {
  return originalReplace.call(this, location).catch(handleRouterError)
}

export default router
