import i18n from '@/i18n'
import get from 'lodash/get'
import store from '@/store'
import { $modules, resourceStatuses } from '@/enum/enums'
import { composeLineItemName } from '@/utils/utils'
import { tableColumns } from '@/components/table/tableColumns'
import cloneDeep from "lodash/cloneDeep";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { globalResources } from "@/components/form/util";
import { naturalSortEntries } from "@/modules/accounts-receivable/utils/billingUtils";

const selectColumn = {
  label: '',
  prop: 'select',
  minWidth: 40,
  maxWidth: 40,
  smallCell: true,
}

const baseColumns = [
  {
    label: i18n.t('Invoice #'),
    prop: 'attributes.number',
    minWidth: 100,
    maxWidth: 160,
  },
  {
    label: i18n.t('Job'),
    prop: 'job',
    minWidth: 100,
    maxWidth: 250,
  },
  {
    label: i18n.t('Date'),
    prop: 'attributes.date',
    component: 'FormattedDate',
    minWidth: 100,
    maxWidth: 120,
  },
  {
    label: i18n.t('Created By'),
    prop: 'attributes.created_by',
    minWidth: 100,
    maxWidth: 150,
    component: tableColumns.UserLink,
  },
  {
    label: i18n.t('Description'),
    prop: 'attributes.description',
    minWidth: 120,
    maxWidth: 300,
    visible: false,
  },
  {
    label: i18n.t('Amount'),
    prop: 'attributes.gross_amount',
    align: 'right',
    component: 'FormattedPrice',
  },
  {
    label: i18n.t('Tax'),
    prop: 'attributes.sales_tax_amount',
    align: 'right',
    component: 'FormattedPrice',
  },
  {
    label: i18n.t('Retention'),
    prop: 'attributes.retention_amount',
    align: 'right',
    component: 'FormattedPrice',
  },
  {
    label: i18n.t('Net'),
    prop: 'attributes.net_amount',
    align: 'right',
    component: 'FormattedPrice',
  },
]

export const TABLE_COLUMNS = {
  [resourceStatuses.Pending]: [
    selectColumn,
    ...baseColumns,
  ],
  [resourceStatuses.Posted]: [
    ...baseColumns,
    {
      label: i18n.t('Status'),
      align: 'center',
      prop: 'attributes.status',
      maxWidth: 160,
    },
    {
      label: i18n.t('Posted By'),
      prop: 'attributes.posted_by',
      maxWidth: 150,
      component: 'UserLink',
    },
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.Open]: [
    ...baseColumns,
    {
      label: i18n.t('Status'),
      align: 'center',
      prop: 'attributes.status',
      maxWidth: 160,
    },
    {
      label: i18n.t('Posted By'),
      prop: 'attributes.posted_by',
      maxWidth: 150,
      component: 'UserLink',
    },
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.Paid]: [
    selectColumn,
    ...baseColumns,
    {
      label: i18n.t('Paid'),
      prop: 'attributes.paid_amount',
      align: 'right',
      component: 'FormattedPrice',
    },
    {
      label: i18n.t('Paid At'),
      prop: 'attributes.paid_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.PartialPaid]: [
    selectColumn,
    ...baseColumns,
    {
      label: i18n.t('Paid'),
      prop: 'attributes.paid_amount',
      align: 'right',
      component: 'FormattedPrice',
    },
    {
      label: i18n.t('Paid At'),
      prop: 'attributes.paid_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.Voided]: [
    ...baseColumns,
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
    {
      label: i18n.t('Voided At'),
      prop: 'attributes.voided_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
  ],
  [resourceStatuses.All]: [
    ...baseColumns,
    {
      label: i18n.t('Posted At'),
      prop: 'attributes.posted_at',
      minWidth: 100,
      maxWidth: 150,
      component: 'FormattedDate',
    },
    {
      label: i18n.t('Status'),
      align: 'center',
      prop: 'attributes.status',
      maxWidth: 160,
    },
  ],
}

export const TABLE_ACTIONS = {
  [resourceStatuses.Pending]: [
    'postBulk',
    'bulkSelection',
  ],
  [resourceStatuses.Paid]: [
    'voidBulk',
    'groupBy',
    'bulkSelection',
  ],
  [resourceStatuses.PartialPaid]: [
    'voidBulk',
    'groupBy',
    'bulkSelection',
  ],
  [resourceStatuses.All]: [
    'groupBy',
  ],
  [resourceStatuses.Posted]: [
    'groupBy',
  ],
  [resourceStatuses.Open]: [
    'groupBy',
  ],
  [resourceStatuses.Voided]: [
    'groupBy',
  ],
  default: [],
}

export const billingTypes = {
  Progress: 'progress',
  UnitPrice: 'unit-price',
  CostPlus: 'cost-plus',
  LumpSum: 'lump-sum',
  Service: 'service',
  Init: 'init',
}

export const billingTypeAbbr = {
  Progress: 'PRG',
  UnitPrice: 'UPB',
  CostPlus: 'CPB',
  LumpSum: 'LMP',
  Init: 'INIT',
  Service: 'SB',
}

export const billingAbbrToType = {
  [billingTypeAbbr.Progress]: billingTypes.Progress,
  [billingTypeAbbr.UnitPrice]: billingTypes.UnitPrice,
  [billingTypeAbbr.CostPlus]: billingTypes.CostPlus,
  [billingTypeAbbr.LumpSum]: billingTypes.LumpSum,
  [billingTypeAbbr.Init]: billingTypes.Init,
  [billingTypeAbbr.Service]: billingTypes.Service,
}

export const billingTypeToAbbr = {
  [billingTypes.Progress]: billingTypeAbbr.Progress,
  [billingTypes.UnitPrice]: billingTypeAbbr.UnitPrice,
  [billingTypes.CostPlus]: billingTypeAbbr.CostPlus,
  [billingTypes.LumpSum]: billingTypeAbbr.LumpSum,
  [billingTypes.Init]: billingTypeAbbr.Init,
  [billingTypes.Service]: billingTypeAbbr.Service,
}

export const apiTypes = {
  [billingTypes.Progress]: billingTypeAbbr.Progress,
  [billingTypes.UnitPrice]: billingTypeAbbr.UnitPrice,
  [billingTypes.CostPlus]: billingTypeAbbr.CostPlus,
  [billingTypes.LumpSum]: billingTypeAbbr.LumpSum,
  [billingTypes.Service]: billingTypeAbbr.Service,
}

export const TABLE_URL_PARAMS = {
  [resourceStatuses.Pending]: {
    status: `${resourceStatuses.Pending},${resourceStatuses.NoPost}`,
  },
  [resourceStatuses.Paid]: {
    status: resourceStatuses.Paid,
  },
  [resourceStatuses.PartialPaid]: {
    status: resourceStatuses.PartialPaid,
  },
  [resourceStatuses.Posted]: {
    status: `${resourceStatuses.Posted},${resourceStatuses.PartialPaid}`,
  },
  [resourceStatuses.Open]: {
    status: `${resourceStatuses.Posted},${resourceStatuses.PartialPaid}`,
  },
  [resourceStatuses.Voided]: {
    status: resourceStatuses.Voided,
  },
  [resourceStatuses.All]: {},
}

export function composeEntryModels(budgets, entries, vm) {
  const lineItems = composeLineItemModels(budgets, vm)
  const mappedLineItems = lineItems.map(lineItem => {
    const billingEntry = entries.find(entry => entry.addl_source_id === lineItem.line_item_id) || {}
    const amountToDate = (billingEntry.gross_amount || 0) + lineItem.budget_to_date
    const budget = vm.get(lineItem, 'budgets[0]', {})
    let prior_retention = budget.retention_amount || 0
    let prior_material_stored = budget.material_stored_amount || 0

    let retention_to_date = prior_retention + billingEntry.retention_amount
    let material_stored_to_date = prior_material_stored + billingEntry.material_stored_amount

    const row = {
      ...lineItem,
      ...billingEntry,
      description: billingEntry.description || lineItem.description,
      prior_material_stored,
      prior_retention,
      retention_to_date,
      material_stored_to_date,
      gross_amount: billingEntry.gross_amount ?? 0,
      completion: +vm.$getPercent(lineItem.budget, amountToDate, false),
    }
    if (!vm.isBillingCompleted) {
      row.unit_rate = lineItem.unit_rate
    }
    row.has_change_order = lineItem?.relationships?.addlSource?.change_order !== 0
    return row
  })
  return naturalSortEntries(mappedLineItems, 'relationships.addlSource')
}

export function composeLineItemModels(lineItem, vm) {
  return lineItem.map((lineItem) => {
    const budget = vm.get(lineItem, 'budgets[0]', {})
    const { job_type_id, amount, amount_to_date } = budget
    const jobType = store.getters['jobCosting/getJobTypeById'](job_type_id)
    const { abbr, credit_account, credit_subaccount } = jobType

    const addl_source_id = cloneDeep(lineItem.id)

    lineItem.id = null
    delete budget.id

    const prior_retention_amount = budget.retention_amount || 0
    const prior_material_stored_amount = budget.material_stored_amount || 0
    const budget_quantity = budget.quantity || 0
    const addl_source = store.getters['globalLists/getResourceById'](globalResources.LineItems, addl_source_id)

    let row = {
      ...lineItem,
      ...budget,
      type: abbr, // TODO Add abbr,
      job_id: lineItem.job_id,
      gross_amount: 0,
      budget: amount || 0,
      budget_to_date: amount_to_date || 0,
      retention_amount: 0,
      quantity: 0,
      material_stored_amount: 0,
      prior_material_stored_amount,
      prior_retention_amount,
      account: lineItem.account || credit_account,
      subaccount: lineItem.subaccount || credit_subaccount,
      description: lineItem.description,
      phase_cost_codes: composeLineItemName(lineItem),
      unit_price: amount || 0,
      _localId: crypto.randomUUID(),
      cost_center: costCenterTypes.Job,
      addl_source,
      addl_source_id,
      budget_quantity,
    }
    row.completion = vm.$getPercent(row.budget, row.budget_to_date, false)
    row.initial_completion = +row.completion

    return row
  })
}

export function getNextBillingNumber(billingType, job) {
  let billingNumber = getLastUsedBillingNumber(billingType, job)
  const parts = billingNumber.split('-')

  let number = parts[parts.length - 1]
  number = parseInt(number)
  number++
  parts[parts.length - 1] = number.toString()

  billingNumber = parts.join('-')

  return billingNumber
}

export function getLastUsedBillingNumber(billingType, job) {
  // * Because billingType is uppercase, we need to convert it to lowercase
  const type = billingType.toLowerCase()

  if (!get(job, `meta.last_ar_${type}_number`)) {
    return `${job.number}-${billingType}-0`
  }

  return job.meta[`last_ar_${type}_number`]
}

const typeVsRouteMap = {
  [billingTypeAbbr.Progress]: billingTypes.Progress,
  [billingTypeAbbr.UnitPrice]: billingTypes.UnitPrice,
  [billingTypeAbbr.CostPlus]: billingTypes.CostPlus,
  [billingTypeAbbr.LumpSum]: billingTypes.LumpSum,
  [billingTypeAbbr.Init]: billingTypes.LumpSum,
  [billingTypeAbbr.Service]: billingTypes.Service,
}

export function getBillingType(row) {
  if (typeof row === 'string') {
    return typeVsRouteMap[row]
  }

  const { type } = row.attributes || row
  return typeVsRouteMap[type]
}

export function getBillingTitle(type) {
  const titleMap = {
    [billingTypeAbbr.Service]: i18n.t('Service Invoice'),
    [billingTypeAbbr.Progress]: i18n.t('Progress Billing'),
    [billingTypeAbbr.LumpSum]: i18n.t('Lump Sum Billing'),
    [billingTypeAbbr.UnitPrice]: i18n.t('Unit Price Billing'),
    [billingTypeAbbr.CostPlus]: i18n.t('Cost Plus Billing'),
    'purchase_orders': i18n.t('PO'),
    default: i18n.t('Invoice')
  }
  return titleMap[type] || titleMap.default
}
