<template>
  <DetailLayout
    :loading="equipmentLoading"
    :current-resource="currentResource"
    :get-current-resource="getCurrentResource"
    :select-component="EquipmentSelect"
    :items="items"
  >
    <template #page-title>
      <span class="truncate">
        {{ currentResource.attributes.code }}
        <template v-if="currentResource.attributes.description">
          ({{ currentResource.attributes.description }})
        </template>
      </span>
    </template>
  </DetailLayout>
</template>
<script>
import layoutPermissions from '@/mixins/layoutPermissions'
import detailScreen from "@/mixins/detailScreen";
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import EquipmentSelect from "@/components/select/entities/EquipmentSelect.vue";

export default {
  components: {
    DetailLayout
  },
  data() {
    return {
      EquipmentSelect
    }
  },
  computed: {
    items() {
      const items = [
        {
          name: this.$t('Details'),
          path: `/equipment/list/${ this.$route.params.id }/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/equipment/list/${ this.$route.params.id }/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Track Usage'),
          path: `/equipment/list/${ this.$route.params.id }/track-usage`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Billings'),
          path: `/equipment/list/${this.$route.params.id}/billings`,
          authorizedTo: 'authorizedToShow',
          permission: 'billings_show',
        },
        {
          name: this.$t('Invoices'),
          path: `/equipment/list/${this.$route.params.id}/invoices`,
          authorizedTo: 'authorizedToShow',
          permission: 'invoices_show',
        },
        {
          name: this.$t('Purchase Orders'),
          path: `/equipment/list/${this.$route.params.id}/purchase-orders`,
          authorizedTo: 'authorizedToShow',
          permission: 'purchase_orders_show',
        },
        {
          name: this.$t('Repair Orders'),
          path: `/equipment/list/${this.$route.params.id}/repair-orders`,
          authorizedTo: 'authorizedToShow',
          permission: 'repair_orders_show',
        },
        {
          name: this.$t('Documents'),
          path: `/equipment/list/${this.$route.params.id}/documents`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'equipment_documents_show',
        },
        {
          name: this.$t('Timesheets'),
          path: `/equipment/list/${ this.$route.params.id }/timesheets`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'timesheets_approve'
        },
        {
          name: this.$t('Transactions'),
          path: `/equipment/list/${ this.$route.params.id }/transactions`,
          authorizedTo: 'authorizedToUpdate',
          permission: 'transaction_show'
        },
      ]

      return items
    },
    equipmentLoading() {
      return this.$store.state.equipmentManagement.equipmentLoading
    },
    currentResource() {
      return this.$store.state.equipmentManagement.currentEquipment
    },
  },
  methods: {
    async getCurrentResource(id) {
      await this.$store.dispatch('equipmentManagement/getEquipment', id)
    },
  },
}
</script>
