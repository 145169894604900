<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #thead-infos>
      <th
        v-for="grouping in headerGroupings"
        :colspan="grouping.colspan"
        :class="{
          'thead-infos border-b-2 border-dashed pb-2': grouping.label
        }"
        :key="grouping.key"
      >
        <div
          v-if="grouping.label"
          class="text-center"
        >
          {{ grouping.label }}
        </div>
      </th>
    </template>

    <template #header="{ row }">
      <template v-if="row.header.phase_code">
        <span> {{ $t('Phase Code:') }} </span>
        <span>{{ row.header.phase_code }}</span>
      </template>
      <template v-else>
        <span> {{ $t('Job:') }} </span>
        <JobLink
          :data="row.header.job"
          class="inline"
        />
      </template>
    </template>

    <template #job="{ row }">
      <JobLink
        v-if="row.job?.id"
        :data="row.job"
      />
      <span v-else></span>
    </template>

    <template #subtotal="{ subtotal }">
      <td align="right">
        {{ subtotal?.description }}
      </td>
      <td align="right">
        {{ $formatHours(subtotal?.totals?.manhours?.amount) }}
      </td>
      <td align="right">
        {{ $formatHours(subtotal?.totals?.manhours?.amount_to_date) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.totals?.labor_dollars?.amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.totals?.labor_dollars?.amount_to_date) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.totals?.burden_dollars?.amount) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.totals?.burden_dollars?.amount_to_date) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.totals?.labor_plus_burden_dollars?.remaining) }}
      </td>
      <td align="right">
        {{ subtotal?.totals?.completion ? $formatPercent(subtotal?.totals?.completion) : '' }}
      </td>
      <td align="left">
        {{ $formatDate(subtotal?.totals?.last_updated_at) }}
      </td>
      <td align="right">
        {{ $formatPrice(subtotal?.totals?.labor_plus_burden_dollars?.variance) }}
      </td>
    </template>

    <template #html-row="{ htmlData }">
      <tr class="content-row font-semibold">
        <td colspan="13">
          <div class="py-4 ">
            {{ htmlData }}
          </div>
        </td>
      </tr>
    </template>

  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { LaborStatusReportOptions } from '@/modules/job-costing/components/reports/util'
import { isNumber } from '@/modules/common/util/commonUtils'

export default {
  extends: ReportTableWrapper,
  computed: {
    columns() {
      const lineItemColumn = {
        label: this.$t('Line Item'),
        prop: 'line_item',
        minWidth: 100,
        maxWidth: 450,
        align: 'left',
        component: 'LineItemLink',
      }

      const jobColumn = {
        label: this.$t('Job'),
        prop: 'job',
        minWidth: 100,
        maxWidth: 450,
        align: 'left',
        component: 'JobLink',
      }

      const entityColumn = this.format === LaborStatusReportOptions.Format.LineItemSummary
        ? lineItemColumn
        : jobColumn

      return [
        entityColumn,
        {
          label: this.$t('Budget'),
          prop: 'totals.manhours.amount',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedHours',
        },
        {
          label: this.$t('To-Date'),
          prop: 'totals.manhours.amount_to_date',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedHours',
        },
        {
          label: this.$t('Budget'),
          prop: 'totals.labor_dollars.amount',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('To-Date'),
          prop: 'totals.labor_dollars.amount_to_date',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Budget'),
          prop: 'totals.burden_dollars.amount',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('To-Date'),
          prop: 'totals.burden_dollars.amount_to_date',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Remaining <br> Budget'),
          prop: 'totals.labor_plus_burden_dollars.remaining',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('% <br> Comp'),
          prop: 'completion',
          minWidth: 110,
          maxWidth: 130,
          align: 'right',
          component: 'FormattedPercent',
          hideZero: true,
        },
        {
          label: this.$t('Last <br> Update'),
          prop: 'last_updated_at',
          minWidth: 80,
          maxWidth: 100,
          align: 'center',
          component: 'FormattedDate',
        },
        {
          label: this.$t('Projected <br> Variance'),
          prop: 'totals.labor_plus_burden_dollars.variance',
          minWidth: 100,
          maxWidth: 120,
          align: 'right',
          component: 'FormattedPrice',
        },
      ]
    },
    format() {
      return this.options.format
    },
    headerGroupings() {
      return [
        {
          colspan: 1,
          key: 'space_1'
        },
        {
          colspan: 2,
          label: this.$t('--- Manhours ---'),
          key: 'manhours'
        },
        {
          colspan: 2,
          label: this.$t('--- Labor $ ---'),
          key: 'labor_dollars'
        },
        {
          colspan: 2,
          label: this.$t('--- Burden $ ---'),
          key: 'burden_dollars'
        },
        {
          colspan: 4,
          key: 'space_2'
        }
      ]
    }
  },
  methods: {
    isNumber,
    addJobGroup(jobGroup) {
      this.rows.push({
        header: {
          job: jobGroup.job,
        },
      })

      jobGroup.phases.forEach(phase => {
        phase.line_items.forEach((lineItem) => {
          this.rows.push({
            ...lineItem,
            last_updated_at: lineItem.line_item.last_updated_at,
            completion: lineItem.line_item.completion
          })
        })
      })

      this.addJobTotals(jobGroup)
    },
    addJobTotals(jobGroup) {
      const totals = jobGroup.totals
      this.rows.push({
        subtotal: {
          description: `${this.$t('Job Totals')} (${jobGroup.job.number})`,
          totals: {
            ...totals,
            last_updated_at: jobGroup.last_updated_at,
            completion: jobGroup.job.completion,
          },
        }
      })
    },
    addGrandTotals() {
      const grandTotals = this.data.grand_totals

      this.rows.push(
        {
          subtotal: {
            description: this.$t('Grand Total All Selected Jobs'),
            totals: grandTotals,
          }
        },
      )
    },
    composeRows(data) {
      for (const jobGroup of data) {
        if (this.format === LaborStatusReportOptions.Format.LineItemSummary) {
          this.addJobGroup(jobGroup)
        }
        else {
          this.rows.push({
            job: jobGroup.job,
            last_updated_at: jobGroup.last_updated_at,
            completion: jobGroup.job.completion,
            totals: {
              ...jobGroup.totals,
            },
          })
        }
      }

      this.addGrandTotals()

      return this.rows
    },
  }
}
</script>
