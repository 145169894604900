<template>
  <EntitySelect
    url="/restify/repair-orders"
    :transformData="transformData"
    v-bind="$attrs"
    v-on="$listeners"
    :filter-options="filterOptions"
    :url-params="allUrlParams"
    :name="$attrs.name || $t('Repair Order')"
    :placeholder="$attrs.placeholder || $t('Select Repair Order')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Repair Order')"
    :add-label="$t('Add new Repair Order')"
    :id="$attrs.id || 'repair-orders-select'"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    :hide-label="hideLabel"
    :value-key="valueKey"
    label-key="number"
    class="w-full"
    :dataFilters="dataFilters"
  />
</template>
<script>
import { resourceStatuses } from "@/enum/enums";
export default {
  name: 'RepairOrderSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: '-number',
      }),
    },
    equipment_id: {
      type: Number,
      default: null,
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    showStatusColumn: {
      type: Boolean,
      default: true,
    },
    filterAvailableInTimesheets: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dataFilters: {
        showActive: true
      },
    }
  },
  computed: {
    allUrlParams() {
      if (!this.showStatusColumn) {
        return this.urlParams
      }

      return {
        ...this.urlParams,
        available_in_timesheets: this.filterAvailableInTimesheets ? true : undefined,
        status: this.dataFilters.showActive ? null : null,
        equipment_id: this.equipment_id,
      }
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Number'),
          prop: 'attributes.number',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Description'),
          prop: 'attributes.description',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
          showIf: () => this.showStatusColumn,
        },
      ]

      return columns.filter(col => !col.showIf || col.showIf())
    },
  },
  methods: {
    transformData(data) {
      return data.map((repairOrder) => ({
        ...repairOrder,
        isInactive: repairOrder.attributes.status === resourceStatuses.Cancelled,
      }))
    },
    filterOptions(options) {
      if (!this.excludeIds.length) {
        return options
      }

      return options.filter(o => {
        return !this.excludeIds.includes(o.id)
      })
    },
    formatLabel(repairOrder) {
      if (!repairOrder?.attributes) {
        return ''
      }
      const { number, description } = repairOrder.attributes
      return !description ? number : `${number} (${description})`
    },
  },
}
</script>
