<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
  >

    <template #filters>

      <base-filter-row :title="$t('Format')">
        <base-select
            v-model="model.double_spaced"
            :options="formatOptions"
            :add-entity="false"
            class="col-span-3"
        />
      </base-filter-row>

      <AccountFilterRow
        v-model="model"
        @reset="resetAccountFilters"
      >
        <template #extra-filters>
          <BaseCheckbox
            v-model="model.exclude_inactive"
            :label="$t('Exclude Inactive Accounts')"
            class="col-span-4"
            id="exclude_inactive"
          />
        </template>
      </AccountFilterRow>

      <base-filter-row :title="$t('Fiscal Year')">
        <fiscal-year-select
          v-model="model.fiscal_year"
          :placeholder="$t('Fiscal Year')"
          class="col-span-6 md:col-span-2"
          :show-closed-years="true"
        />
      </base-filter-row>

    </template>

    <template #table="{data, loading}">

      <account-listing-report-table
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script lang="ts">

  import { defineComponent } from 'vue'
  import AccountListingReportTable from '@/modules/ledger/components/reports/AccountListingReportTable.vue'
  import AccountFilterRow from '@/components/entity-filters/AccountFilterRow.vue'

  const formatOptions = [
    {
      label: 'Single Spaced',
      value: false,
    },
    {
      label: 'Double Spaced',
      value: true,
    },
  ]

  export default defineComponent({
    components: {
      AccountFilterRow,
      AccountListingReportTable,
    },
    data() {
      return {
        reportNumber: '1882',
        model: <GL_REPORTS.AccountListing> {
          account_ids: [],
          account_number_from: null,
          account_number_to: null,
          fiscal_year: this.$currentFiscalYear,
          exclude_inactive: 0,
          double_spaced: false,
        },
        formatOptions,
      }
    },
    methods: {
      resetAccountFilters() {
        this.model.exclude_inactive = 0
      },
    },
  })
</script>
