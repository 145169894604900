<template>
  <div>
    <JobCostDetailTable
        v-if="!isSummaryFormat"
        v-bind="$attrs"
    />
    <SummaryTable
        v-else
        v-bind="$attrs"
    />
  </div>
</template>
<script>
  import { jobCostDetailPrintOptions } from '@/modules/job-costing/components/reports/util'
  import SummaryTable from '@/modules/job-costing/components/reports/job-cost-detail/by-line-item/SummaryTable'
  import JobCostDetailTable
    from '@/modules/job-costing/components/reports/job-cost-detail/by-line-item/JobCostDetailTable'

  export default {
    components: {
      SummaryTable,
      JobCostDetailTable,
    },
    computed: {
      isSummaryFormat() {
        return [jobCostDetailPrintOptions.JobSummary].includes(this.$attrs['total-by'])
      },
    },
  }
</script>
