<template>
  <SettingsForm
    :fields="fields"
    :module-name="moduleName"
    :authorized-to-collapse="true"
    :before-init-model="beforeInitModel"
    grid-classes="grid grid-cols-1"
    @change="onModelChange"
    @cancel="$router.push('/payroll/timesheets/list')"
  >
    <template #content="{model}">
      <div class="grid grid-cols-6 gap-x-3 px-4 mb-4">
        <h4 class="section-title col-span-6 mb-4">
          {{ $t('Mobile Application Settings') }}
        </h4>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_work_orders"
                       :label-info="$t('Enable Work Orders')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_equipment"
                       :label-info="$t('Enable Equipment')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_materials"
                       :label-info="$t('Enable Materials')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.enable_pay_codes"
                       :label-info="$t('Enable Pay Codes')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch
            v-model="model.enable_craft_codes"
            :tip="$t('Allow employees to select craft codes when logging time.')"
            :label-info="$t('Enable Craft Codes')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <base-switch v-model="model.generate_daily_job_questions"
                       :label-info="$t('Generate Daily Reports')"
                       :tip="$t('When turned on, daily reports will be generated for each day and job on which work was performed.')"
          />
        </div>
        <div class="col-span-6 mt-2"></div>
        <div class="col-span-3 lg:col-span-1">
          <BaseSwitch
            v-model="model.enable_repair_orders"
            :label-info="$t('Enable Repair Orders')"
            :tip="$t('Enable to allow employees to log time for Repair Orders from the mobile app.')"
          />
        </div>
        <div class="col-span-3 lg:col-span-1 mt-2">
          <BaseInput
            :tip="$t('Enable Time Off to allow employees to request time off from the mobile app.')"
            :label="$t('Enable Time Off')"
          >
            <template #container>
              <BaseSwitch
                v-model="model.enable_time_off"
              />
            </template>
          </BaseInput>
        </div>
        <div class="col-span-3 lg:col-span-1 mt-2">
          <BaseInput
            :tip="$t('Enable breaks to allow employees to log breaks from the mobile app.')"
            :label="$t('Enable Breaks')"
          >
            <template #container>
              <BaseSwitch
                v-model="model.enable_breaks"
              />
            </template>
          </BaseInput>
        </div>
        <div class="col-span-3 lg:col-span-1 mt-2">
          <BaseInput
            :tip="$t('Enable lunch breaks to allow employees to log lunch breaks from the mobile app.')"
            :label="$t('Enable Lunch Breaks')"
          >
            <template #container>
              <BaseSwitch
                v-model="model.enable_lunch"
              />
            </template>
          </BaseInput>
        </div>
        <div class="col-span-3 lg:col-span-1 mt-2">
          <BaseInput
            :label="$t('Expand Payroll Information')"
            :tip="$t('Expand Payroll Information by default when adding or editing timesheets.')"
          >
            <template #container>
              <BaseSwitch
                v-model="model.show_craft_code_details"
              />
            </template>
          </BaseInput>
        </div>
        <div class="col-span-3 lg:col-span-1 mt-2">
          <BaseInput
            :label="$t('Camera only image upload')"
            :tip="$t('When turned off, users can upload images from the gallery as well. When turned on, users can only upload images from the camera.')"
          >
            <template #container>
              <BaseSwitch
                v-model="model.camera_only_image_upload"
              />
            </template>
          </BaseInput>
        </div>
      </div>
    </template>
    <template #default="{model}">
      <div class="my-4">
        <h4 class="section-title">
          {{ $t('Business Hours & Overtime') }}
        </h4>
        <div class="grid grid-cols-6 gap-x-3 mt-4">
          <div class="col-span-3 lg:col-span-2">
            <base-select
              :value="getWorkingDays(model)"
              :options="weekDays"
              :label="$t('Working Days')"
              multiple
              @change="model.working_days = $event"
            />
          </div>
          <div class="col-span-3 lg:col-span-2">
            <base-time-picker
              :value="getWorkingSchedule(model)"
              :label="$t('Working Schedule')"
              is-range
              range-separator="To"
              start-placeholder="Start time"
              end-placeholder="End time"
              format="HH:mm"
              @input="onWorkingScheduleChange(model, $event)"
            />
          </div>
          <div class="col-span-3 lg:col-span-1">
            <base-time-picker
              :value="getStartTime(model)"
              :label="$t('Start Time')"
              :tip="$t('The default start time for the day when logging time. Employees can change this from the mobile app.')"
              format="HH:mm"
              @input="onStartTimeChange(model, $event)"
            />
          </div>
          <div class="col-span-6"></div>
          <div class="col-span-3 lg:col-span-1">
            <base-switch
              v-model="model.has_overtime"
              :label-info="$t('Enable Overtime Hours')"
              @change="onHasOvertimeChange($event, model)"
            />
          </div>
          <div class="col-span-3 lg:col-span-1">
            <base-switch
              v-model="model.has_premium"
              :label-info="$t('Enable Premium Hours')"
              @change="onHasPremiumChange($event, model)"
            />
          </div>
          <div class="col-span-3 lg:col-span-2">
            <BaseInput
              :label="$t('Calculate overtime based on weekly regular hours')"
              :tip="$t('If enabled, overtime will be calculated based on the weekly regular hours limit. If disabled, overtime will be calculated based cumulated weekly regular, overtime and premium hours.')"
            >
              <template #container>
                <BaseSwitch
                  v-model="model.is_overtime_based_on_weekly_regular_hours"
                />
              </template>
            </BaseInput>
          </div>
          <div class="col-span-6 mb-4"></div>
            <div class="col-span-3 lg:col-span-1">
              <base-input
                v-model="model.regular_hours_per_day"
                :label="$t('Reg Hours per Day')"
                :name="$t('Reg Hours per Day')"
                type="number"
              >
                <template #suffix>
                  {{ $t('hrs/day') }}
                </template>
              </base-input>
            </div>
            <div class="col-span-3 lg:col-span-1">
              <base-input
                v-model="model.regular_hours_per_week"
                :label="$t('Reg Hours per Week')"
                :name="$t('Reg Hours per Week')"
                :suffix="$t('hrs/week')"
                type="number"
              >
                <template #suffix>
                  {{ $t('hrs/week') }}
                </template>
              </base-input>
            </div>
            <div class="col-span-3 lg:col-span-1">
              <base-input
                v-model="model.overtime_hours_per_day"
                :label="$t('OVT Hours per day')"
                :name="$t('OVT Hours per day')"
                :disabled="!model.has_overtime"
                type="number"
              >
                <template #suffix>
                  {{ $t('hrs/day') }}
                </template>
              </base-input>
            </div>
          <div class="col-span-3 lg:col-span-1">
            <base-select
              v-model="model.weekend_hours"
              :label="$t('Weekend hours as')"
              :name="$t('Weekend hours as')"
              :disabled="!model.has_overtime && !model.has_premium"
              :options="weekendHourOptions"
            />
          </div>
          <div class="col-span-6"></div>
          <div class="col-span-3 lg:col-span-1">
            <base-input
              v-model="model.lunch_duration"
              :label="$t('Lunch Duration')"
              :name="$t('Lunch Duration')"
              type="number"
            >
              <template #suffix>
                {{ $t('minutes') }}
              </template>
            </base-input>
          </div>
          <div class="col-span-3 lg:col-span-1">
            <base-input
              v-model="model.break_duration"
              :label="$t('Break Duration')"
              :name="$t('Break Duration')"
              type="number"
            >
              <template #suffix>
                {{ $t('minutes') }}
              </template>
            </base-input>
          </div>
          <div class="col-span-3 lg:col-span-1">
            <BaseInput
              v-model="model.round_interval"
              :label="$t('Round Interval')"
              :name="$t('Round Interval')"
              :tip="$t('Round time selection to the nearest interval. For example, if set to 15 minutes, users will be able to select 15 min increments.')"
              rules="min_value:1|max_value:60"
              type="number"
            >
              <template #suffix>
                {{ $t('minutes') }}
              </template>
            </BaseInput>
          </div>
        </div>
      </div>
      <div class="my-4">
        <h4 class="section-title">
          {{ $t('Compliance Questions') }}
        </h4>
        <span class="text-gray-600 text-sm">
          {{
            $t(`Employees will be prompted to answer compliance questions when clocking out each day. Along with the indicated response type, employees will also be able to add additional text to provide detailed answers if necessary.`)
          }}
        </span>
      </div>
      <div class="flex flex-col gap-2 divide-y divide-gray-200">
        <ComplianceQuestions v-model="model.compliance" key="compliance"/>
      </div>
      <div class="my-4">
        <h4 class="section-title">
          {{ $t('Foreman Daily Report Questions') }}
        </h4>
        <span class="text-gray-600 text-sm">
          {{
            $t(`Foremen (Crew Leaders) will be able to answer daily report questions for each day and job on which work was performed by their crew. Along with the indicated response type, foremen will also be able to add additional text to provide detailed answers if necessary.`)
          }}
        </span>
      </div>
      <div class="flex flex-col gap-2 divide-y divide-gray-200">
        <ComplianceQuestions v-model="model.daily_reports" key="daily_report"/>
      </div>
    </template>
  </SettingsForm>
</template>
<script>
import SettingsForm from '@/modules/common/components/SettingsForm';
import PayCodeSelect from '@/components/select/entities/PayCodeSelect';
import { PlusIcon } from "vue-feather-icons";
import { weekDays } from "@/enum/enums";
import format from "date-fns/format";
import parse from "date-fns/parse";
import ComplianceQuestions from "@/modules/payroll/components/compliance/ComplianceQuestions.vue";

const defaultCompliance = [
  {
    "key": "verify_time",
    "question": "Verify your time",
    "description": "I acknowledge that this time card accurately and completely reflects all time worked during this pay period and that no hours were worked that are not recorded on this time card.",
    "setting_title": "Require signature",
    "setting_description": "Require employees to sign clock out",
    "type": "signature",
    "default_value": true,
  },
  {
    "key": "injury",
    "question": "Where you injured?",
    "description": "",
    "type": "boolean",
    "default_value": true,
  },
  {
    "key": "breaks",
    "question": "Did you take your required breaks today?",
    "description": "",
    "type": "boolean",
    "default_value": false,
  },
]

const defaultDailyReportQuestions = [
  {
    "key": "issues",
    "question": "Were there any issues (delays, safety concerns, or material shortages)?",
    "description": "",
    "type": "boolean",
    "default_value": false
  },
  {
    "key": "weather_conditions",
    "question": "What were the weather conditions today? (E.g., sunny, rainy, windy – did it impact work?)",
    "description": "",
    "type": "text",
    "default_value": ""
  }
]

const WeekendHourTypes = {
  Overtime: 'overtime',
  Premium: 'premium',
  Regular: 'regular',
  RegularMaxPerWeek: 'regular-up-to-max-per-week',
}
export default {
  components: {
    ComplianceQuestions,
    PlusIcon,
    SettingsForm,
    PayCodeSelect,
  },
  data() {
    return {
      weekDays,
      moduleName: 'timesheet',
      fields: [
        'compliance',
        'daily_reports',
        'enable_equipment',
        'enable_work_orders',
        'enable_materials',
        'enable_pay_codes',
        'enable_craft_codes',
        'enable_repair_orders',
        'enable_lunch',
        'enable_breaks',
        'enable_time_off',
        'camera_only_image_upload',
        'generate_daily_job_questions',
        'working_days',
        'start_time',
        'business_hours_start',
        'business_hours_end',
        'break_duration',
        'lunch_duration',
        'round_interval',
        'has_overtime',
        'has_premium',
        'show_craft_code_details',
        'overtime_hours_per_day',
        'regular_hours_per_day',
        'regular_hours_per_week',
        'weekend_hours',
        'is_overtime_based_on_weekly_regular_hours',
      ],
    }
  },
  computed: {
    weekendHourOptions() {
      return [
        {
          value: WeekendHourTypes.Overtime,
          label: this.$t('Overtime')
        },
        {
          value: WeekendHourTypes.Premium,
          label: this.$t('Premium')
        },
        {
          value: WeekendHourTypes.Regular,
          label: this.$t('Regular')
        },
        {
          value: WeekendHourTypes.RegularMaxPerWeek,
          label: this.$t('Regular up to max per week')
        }
      ]
    }
  },
  methods: {
    onHasOvertimeChange(value, model) {
      if (!value) {
        model.overtime_hours_per_day = 0
      }
      if (!value && !model.has_premium) {
        model.weekend_hours = WeekendHourTypes.Regular
      }
    },
    onHasPremiumChange(value, model) {
      if (!value && !model.has_overtime) {
        model.overtime_hours_per_day = 0
        model.weekend_hours = WeekendHourTypes.Regular
      }
    },
    getWorkingDays(model) {
      const defaultWorkingDays = [1, 2, 3, 4, 5]
      return model.working_days || defaultWorkingDays
    },
    getStartTime(model) {
      return parse(model.start_time, 'HH:mm', new Date())
    },
    onStartTimeChange(model, value) {
      if (!value) {
        model.start_time = ''
        return
      }
      model.start_time = format(value, 'HH:mm')
    },
    getWorkingSchedule(model) {
      let defaultStart = new Date()
      let defaultEnd = new Date()
      defaultStart.setHours(8, 0, 0)
      defaultEnd.setHours(18, 0, 0)
      const { business_hours_start, business_hours_end } = model
      if (!business_hours_start || !business_hours_end) {
        return [defaultStart, defaultEnd]
      }
      const start = parse(business_hours_start, 'HH:mm', new Date())
      const end = parse(business_hours_end, 'HH:mm', new Date())
      return [start, end]
    },
    onWorkingScheduleChange(model, value) {
      model.business_hours_start = format(value[0], 'HH:mm')
      model.business_hours_end = format(value[1], 'HH:mm')
    },
    beforeInitModel(model) {
      if (!model.compliance?.length) {
        model.compliance = defaultCompliance
      }
      if (!Array.isArray(model.daily_reports)) {
        model.daily_reports = defaultDailyReportQuestions
      }
      if (!model.round_interval) {
        model.round_interval = 15
      }
      return model
    },
    onModelChange(model) {
      if (!Array.isArray(model.daily_reports)) {
        model.daily_reports = defaultDailyReportQuestions
      }
    }
  },
}
</script>
