<template>
  <div class="truncate">
    <router-link
      v-if="payCodeId"
      :to="`/payroll/codes/pay-codes?id=${payCodeId}`"
      :class="linkClass"
    >
      <span>
        {{ valueToDisplay }}
      </span>
    </router-link>
    <template v-else>
      --
    </template>
  </div>
</template>
<script>
  export default {
    name: 'PayCodeLink',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      id: {
        type: String,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      linkClass: String,
    },
    computed: {
      payCodeId() {
        return this.id || this.get(this.data, 'attributes.source_id')
      },
      payCodes() {
        return this.$store.getters['globalLists/getResourceList'](this.$globalResources.PayCodes)
      },
      payCode() {
        return this.payCodes.find(a => a.id === this.payCodeId) || {}
      },
      valueToDisplay() {
        if (!this.payCode?.id) {
          return ''
        }
        const { code, description } = this.payCode

        if (!this.showDescription) {
          return code
        }
        return `${code} (${description})`
      },
    },
  }
</script>
