<template>
  <div>
    <DetailsPageLayout :loading="repairOrderLoading">
      <template #left>
        <RepairOrderMainCard :repair-order="repairOrder"/>
        <RepairOrderCostsToDate :repair-order="repairOrder"/>
      </template>
      <template #right>
        <BaseDetailCard
          :title="$t('Repair Order Details')"
          :key="repairOrder.id"
          :entity="repairOrder"
          :can-expand="false"
          :expanded="true"
          entity-name="repairOrder"
        >
          <BaseDetailCardRow :title="$t('Repair Order')">
            {{ repairOrder.attributes.number }}
          </BaseDetailCardRow>
          <BaseDetailCardRow
            v-if="repairOrder.attributes.description"
            :title="$t('Description')"
          >
            {{ repairOrder.attributes.description }}
          </BaseDetailCardRow>
          <BaseDetailCardRow :title="$t('Review Status')">
            <ReviewStatus
              :row="repairOrder"
            />
          </BaseDetailCardRow>
          <BaseDetailCardRow
            v-if="repairOrder.attributes.rejected_at"
            :title="$t('Reject Reason')"
          >
            <BaseBadge
              type="danger"
            >
              {{ repairOrder.attributes.rejection_reason }}
            </BaseBadge>
          </BaseDetailCardRow>
          <BaseDetailCardRow :title="$t('Created At')">
            {{ repairOrder.attributes?.created_at ? $formatDate(new Date(repairOrder.attributes.created_at), 'datetime') : '' }}
          </BaseDetailCardRow>
          <BaseDetailCardRow
            v-if="repairOrder.attributes?.due_date"
            :title="$t('Due Date')"
          >
            {{ $formatDate(new Date(repairOrder.attributes.due_date)) }}
          </BaseDetailCardRow>
        </BaseDetailCard>

        <BaseDetailCard class="mt-4" :title="$t('Parts')" expanded>
          <RepairOrderEntries
            ref="entriesTable"
            :repairOrder="repairOrder"
            readOnly
          />
        </BaseDetailCard>

        <BaseBackLink
          v-if="showBack"
          to="/equipment/repair-orders"
        />
      </template>
    </DetailsPageLayout>
  </div>
</template>
<script>
import DetailsPageLayout from "@/modules/common/components/DetailsPageLayout.vue";
import RepairOrderMainCard from "@/modules/equipment/components/repair-orders/RepairOrderMainCard.vue";
import RepairOrderCostsToDate from "@/modules/equipment/components/repair-orders/RepairOrderCostsToDate.vue";
import RepairOrderEntries from '@/modules/equipment/components/repair-orders/RepairOrderEntries.vue'
import ReviewStatus from "@/components/links/ReviewStatus.vue";
import { sourceTypes } from "@/modules/service-billing/util/service-billing";

export default {
  components: {
    DetailsPageLayout,
    RepairOrderMainCard,
    RepairOrderCostsToDate,
    RepairOrderEntries,
    ReviewStatus,
  },
  props: {
    showBack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sourceTypes,
    }
  },
  computed: {
    repairOrder() {
      return this.$store.state.equipmentManagement.currentRepairOrder
    },
    repairOrderLoading() {
      return this.$store.state.equipmentManagement.repairOrderLoading
    },
  },
}
</script>
