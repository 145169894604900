<template>
  <div
    :data-source-id="jobId ? jobId : item.id"
    :data-addl-source-id="jobId ? item.id : null"
    :data-cost-center="costCenter"
    :data-is-break="item.is_break"
    :data-is-lunch="item.is_lunch"
    :data-duration="taskDurationInMinutes"
    :data-apply-for-all-crew-members="applyForAllCrewMembers"
    class="px-2 py-1 relative flex items-center fc-event rounded-md border cursor-grab h-9 group"
    :class="`bg-${color}-50 border-${color}-200`"
  >
    <LunchIcon
      v-if="item.is_lunch"
      class="w-4 h-4 mr-2"
    />
    <BreakIcon
      v-if="item.is_break"
      class="w-4 h-4 mr-2"
    />
    <EquipmentLink
      v-if="costCenter === costCenterTypes.Equipment"
      :id="item.id"
      :editable-cell="true"
    />
    <template v-else-if="costCenter === costCenterTypes.Job">
      <JobLink
        v-if="showJobForLineItem"
        :id="jobId"
        :is-link="false"
        :show-description="false"
        class="mr-2"
      />
      <LineItemLink
        :id="item.id"
        :is-link="false"
      />
    </template>
    <WorkOrderLink
      v-else-if="costCenter === costCenterTypes.WorkOrder"
      :data="item"
      :show-prefix="true"
      :editable-cell="true"
    />
    <div v-else-if="costCenter === costCenterTypes.GeneralAndAdministrative">
      {{ item.title || $t('General & Administrative') }}
    </div>
    <div
      :class="`bg-${color}-200`"
      class="hidden group-hover:flex items-center absolute inset-0 z-10 px-2 py-1 opacity-90 text-sm"
    >
      {{ $t('Drag to the calendar to create a task') }}
    </div>
  </div>
</template>
<script>
import BreakIcon from "@/modules/payroll/components/timesheets/BreakIcon.vue";
import LunchIcon from "@/modules/payroll/components/timesheets/LunchIcon.vue";
import { getEventColor } from "@/modules/payroll/components/tasks/taskUtils";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { differenceInMinutes } from "date-fns";

export default {
  components: {
    LunchIcon,
    BreakIcon
  },
  props: {
    costCenter: {
      type: String,
      required: true,
    },
    jobId: {
      type: String,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    applyForAllCrewMembers: {
      type: Boolean,
    },
    showJobForLineItem: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      costCenterTypes,
    }
  },
  computed: {
    color() {
      return getEventColor(this.costCenter)
    },
    taskDurationInMinutes() {
      if (!this.task) {
        return 60
      }
      const { finish_at, start_at } = this.task?.attributes || {}
      return differenceInMinutes(new Date(finish_at), new Date(start_at))
    }
  }
}
</script>
