<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :is-print="isPrint"
    :submitDisabled="loadingSuiPayments"
    :confirmation="confirmation"
  >
    <template #filters>
      <BaseFilterRow
        :title="$t('Calendar Year')"
        :tip="$t('You can change the Calendar Year from the top right corner select')"
      >
        <div class="col-span-3">
          {{ model.calendar_year }}
        </div>
      </BaseFilterRow>
      <base-filter-row
          :title="$t('Quarter')"
          classes="col-span-2"
      >
        <base-select
            v-model="model.quarter"
            :add-entity="false"
            :options="quarterOptions"
            class="col-span-1"
        />

      </base-filter-row>

      <base-filter-row
          :title="$t('Format')"
          classes="col-span-2"
      >
        <base-select
            v-model="reportNumber"
            :add-entity="false"
            :options="availableReportOptions"
            class="col-span-3"
        />

      </base-filter-row>

      <base-filter-row
          :title="$t('Print 12th Employee Listing')"
          classes="col-span-2"
      >
        <base-switch
            v-model="model.include_12th_month_employee_listing"
            class="col-span-3"
        />

      </base-filter-row>
      <template v-if="reportNumber === ReportTypes['941Form']">
        <base-filter-row
            :title="$t('Semiweekly Depositor')"
            classes="col-span-2"
        >
          <base-switch
              v-model="model.semiweekly_depositor"
              class="col-span-2"
          />
        </base-filter-row>

        <base-filter-row
            :title="$t('Total Quarter Deposits')"
            classes="col-span-2"
        >
          <base-input
              v-model="model.total_quarter_deposits"
              :name="$t('Total Quarter Deposits')"
              type="number"
              format="price"
              rules="required"
              class="col-span-3"
          />
        </base-filter-row>

        <base-filter-row
            :title="$t('Adjustment For Sick Pay')"
            classes="col-span-2"
        >
          <base-input
              v-model="model.adjustment_sick_pay"
              :name="$t('Adjustment For Sick Pay')"
              type="number"
              format="price"
              rules="required|min:0"
              class="col-span-3"
          />
        </base-filter-row>
        <base-filter-row
            :title="$t('Adjustment For Tips and Group Term Life Insurance')"
            classes="col-span-2"
        >
          <base-input
              v-model="model.adjustments_tips_and_group_term_life_insurance"
              :name="$t('Adjustment For Group Term Life Insurance')"
              format="price"
              type="number"
              rules="required|min:0"
              class="col-span-3"
          />
        </base-filter-row>
        <base-filter-row
            :title="$t('Overpayment Option')"
            classes="col-span-2"
        >
          <base-select
              v-model="model.overpayment_option"
              :add-entity="false"
              :options="overpaymentOptions"
              class="col-span-3"
          />

        </base-filter-row>
      </template>
      <template v-if="reportNumber === ReportTypes['940Form']">

        <BaseFilterRow
          :title="$t('Total FUTA Tax Deposited')"
          :tip="$t('Includes any overpayment applied from a prior year')"
          classes="col-span-2"
        >
          <BaseInput
            v-model="model.total_deposits"
            :name="$t('Total Deposits')"
            type="number"
            format="price"
            rules="required"
            class="col-span-3"
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('State Unemployment Payments<br>Made By 940 Due Date')"
        >
          <div
            class="text-sm col-span-3"
            :class="{
              'text-gray-500': loadingSuiPayments || model.sui_payments.length === 0,
            }"
          >
            <span v-if="loadingSuiPayments">
              {{ $t('Loading state unemployment payments...') }}
            </span>
            <span v-else-if="model.sui_payments.length === 0">
              {{ $t('No state unemployment payments') }}
            </span>
            <div
              v-for="suiPayment of model.sui_payments"
              :key="suiPayment.state"
              class="flex justify-between"
            >
              <div class="mt-2 text-base">{{ suiPayment.state }}</div>
              <BaseInput
                v-model="suiPayment.amount"
                :name="suiPayment.state"
                type="number"
                format="price"
                rules="required"
              />
            </div>
          </div>
        </BaseFilterRow>
      </template>
    </template>

    <template #table="{loading, data}">

      <PdfPreview
        v-if="isPrint"
        :file="data"
        :loading="loading"
      />
      <QuarterlyEarningsFederalReportTable
        v-else
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />

    </template>

  </BaseReportForm>
</template>
<script>
import { quarterOptions } from '@/enum/enums'
import QuarterlyEarningsFederalReportTable from '@/modules/payroll/components/reports/QuarterlyEarningsFederalReportTable'
import PdfPreview from "@/components/common/PdfPreview.vue";
import axios from 'axios'

const ReportTypes = {
  EmployeeList: '4881',
  '941Worksheet': '4881a',
  '941Form': '4881b',
  '940Form': '4881c',
}
export default {
  components: {
    PdfPreview,
    QuarterlyEarningsFederalReportTable,
  },
  data() {
    return {
      ReportTypes,
      quarterOptions,
      reportOptions: [
        {
          label: this.$t('Employee List'),
          value: ReportTypes.EmployeeList,
        },
        {
          label: this.$t('941 Worksheet'),
          value: ReportTypes['941Worksheet'],
        },
        {
          label: this.$t('941 Form'),
          value: ReportTypes['941Form'],
        },
        {
          label: this.$t('940 Form'),
          value: ReportTypes['940Form'],
          showIf: () => this.model.quarter == 4,
        },
      ],
      overpaymentOptions: [
        {
          value: 'apply_next_return',
          label: this.$t('Apply to next return')
        },
        {
          value: 'send_refund',
          label: this.$t('Send refund')
        }
      ],
      reportNumber: ReportTypes.EmployeeList,
      loadingSuiPayments: false,
      model: {
        quarter: 1,
        calendar_year: null,
        include_12th_month_employee_listing: false,
        semiweekly_depositor: true,
        total_quarter_deposits: 0,
        adjustment_sick_pay: 0,
        adjustments_tips_and_group_term_life_insurance: 0,
        overpayment_option: 'apply_next_return',
        // 940
        total_deposits: 0,
        sui_payments: []
      },
    }
  },
  computed: {
    availableReportOptions() {
      return this.reportOptions.filter(o => !o.showIf || o.showIf())
    },
    isPrint() {
      return [
        ReportTypes['941Worksheet'],
        ReportTypes['941Form'],
        ReportTypes['940Form'],
      ].includes(this.reportNumber)
    },
    confirmation() {
      if (this.reportNumber !== ReportTypes['940Form'] || !this.model.sui_payments.length) {
        return null
      }

      const statesWithoutAmount = this.model.sui_payments.filter(s => !s.amount).map(s => s.state)

      if (!statesWithoutAmount.length) {
        return null
      }

      return {
        title: this.$t('State Unemployment Payments'),
        description: `${this.$t('State Unemployment Payments are required.<br><br>Continue without entering State Unemployment Payments for')} <b>${statesWithoutAmount.join(', ')}</b>?`,
        buttonText: this.$t('Continue'),
        type: this.$promptType.Warning,
      }
    }
  },
  methods: {
    async loadStates() {
      try {
        this.loadingSuiPayments = true
        this.model.sui_payments = []

        const { data } = await axios.get('/restify/ytds/getters/ytd-states', {
          params: {
            year: this.model.calendar_year,
          }}
        )
        
        this.model.sui_payments = data.map(state => ({ state: state.code, amount: 0 }))
      }
      catch (err) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Coult not load State Unemployment Payments'))
      }
      finally {
        this.loadingSuiPayments = false
      }
    }
  },
  watch: {
    reportNumber: {
      handler (val) {
        if (val !== ReportTypes['940Form']) {
          this.model.sui_payments = []
          this.model.total_deposits = 0
          return
        }

        this.loadStates()
      },
      immediate: true
    },
    'model.calendar_year': {
      handler (val) {
        if (this.reportNumber === ReportTypes['940Form']) {
          this.loadStates()
        }
      },
      immediate: true
    },
    'model.quarter': {
      handler (val) {
        if (!this.availableReportOptions.find(o => o.value === this.reportNumber)) {
          this.reportNumber = this.availableReportOptions[0].value
        }
      },
      immediate: true
    }
  }
}
</script>
