<template>
  <div class="flex items-center wrapper justify-between px-4 lg:px-10 max-w-full top-0 right-0 fixed md:sticky border-b border-gray-100">
    <nav class="hidden sm:flex items-center text-sm leading-5 font-medium overflow-y-auto">
      <BreadCrumbs/>
    </nav>
    <div class="flex flex-1 justify-end">
        <NavbarElements/>
    </div>
  </div>
</template>
<script>
  import NavbarElements from '@/modules/dashboard/components/NavbarElements'
  import BreadCrumbs from "@/modules/dashboard/components/BreadCrumbs";

  export default {
    components: {
      BreadCrumbs,
      NavbarElements,
    },
  }
</script>
<style lang="scss">
  .wrapper {
    @apply bg-white;
    box-shadow: 0 3px 20px 0 rgba(22, 28, 52, 0.02);
    z-index: 1001;
  }

  .breadcrumb-link.router-link-exact-active {
    @apply text-primary-600;
  }
</style>
