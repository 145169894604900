<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    size="lg"
    :title="$t('Generate Billings')"
    :appendToBody="true"
  >
    <BaseForm
      layout="modal"
      :show-cancel="true"
      :save-text="$t('Generate Billings')"
      :loading="loading"
      @cancel="$emit('close')"
      @submit="onSubmit"
    >
      <BaseAlert class="col-span-6 mb-4">
        {{ $t('This action will generate billings for the selected recurring receivables until ') }}
        {{ $formatDate(model.date) }}
      </BaseAlert>

      <BaseDatePicker
        v-model="dateUntil"
        :label="$t('Date Until')"
        :placeholder="$t('Date until')"
        :value-format="null"
        class="col-span-6 md:col-span-3"
        :rules="`date_less_than:${maxDateUntil}`"
        id="date_until"
      />
      <BaseSwitch
        v-if="recurringsHaveIssuedBillings"
        v-model="model.force"
        :label-info="$t('Force Initial Start Date')"
        :placeholder="$t('Force Initial Start Date')"
        :tip="$t('Some of the selected recurring receivables already have issued billings. The start date considered is the last issued billing based on the recurring receivable. If you want to generate billings with the initial start date, use the Force Initial Start Date option.')"
        class="col-span-6 md:col-span-3"
      />
      <h5 class="form-section-title">
        {{ $t('Billings to generate') }}
      </h5>
      <div
        v-for="billing in sortedRecurringBillings"
        :key="billing.id"
        class="col-span-6"
      >
        <h5 class="form-section-title">
          
          <span class="font-medium">{{getForRecurringReceivableText(billing) }} </span>
          <CustomerLink
            :id="billing.attributes.customer_id"
            showName
            class="ml-1"
          />
          <span
            v-if="getStartDate(billing)"
            class="ml-1 font-medium flex items-center space-x-1"
          >
            {{ $t('starting from ') }} {{ $formatDate(getStartDate(billing)) }}
          </span>
        </h5>
        <BaseBadge
          type="info"
          class="mb-2"
        >
          <span class="font-semibold text-sm mr-2">{{$t('Schedule:')}}</span>
          <RRuleText
            v-if="billing?.attributes?.recurring_rule"
            :value="billing?.attributes?.recurring_rule"
            :invoice="billing"
            class="font-medium text-sm block"
          />
          <span v-if="hasIssuedBillings(billing)" class="font-medium text-sm ml-2">
            ({{$t('Last Billing Issued On')}}:
            {{ $formatDate(billing.attributes.last_issue_at) }})
          </span>
        </BaseBadge>
        <AgDataTable
          :data="getRecurrenceList(billing)"
          :columns="columns"
          :pagination="false"
          :no-data-text="$t('No billings to generate based on the selected dates.')"
          domLayout="autoHeight"
          class="mb-4"
        />
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import { RRule, rrulestr } from "rrule";
import i18n from "@/i18n";
import axios from "axios";
import { ApiDateFormat, getDateWihoutTimezoneSigned } from "@/plugins/dateFormatPlugin";
import { addMinutes, addDays, getDate } from "date-fns";
import orderBy from "lodash/orderBy";
import RRuleText from "@/modules/accounts-payable/components/recurring-invoice/RRuleText.vue";
import { billingTypesAbbr } from "@/enum/enums";

export default {
  components: {
    RRuleText,
  },
  props: {
    recurringBillings: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: false,
      ApiDateFormat,
      dateUntil: new Date(),
      model: {
        date: new Date(),
        repositories: this.recurringBillings.map(r => r.id),
        force: false,
      }
    }
  },
  computed: {
    currentFiscalYear() {
      return this.$store.state.company.currentFiscalYear || new Date().getFullYear()
    },
    maxDateUntil() {
      return new Date(this.currentFiscalYear + 1, 0, 1).toISOString()
    },
    sortedRecurringBillings() {
      const invoiceConfigs = this.recurringBillings.map(billing => {
        return {
          ...billing,
          count: this.getRecurrenceList(billing)?.length
        }
      })
      return orderBy(invoiceConfigs, 'count', 'desc')
    },
    recurringsHaveIssuedBillings() {
      return this.sortedRecurringBillings.some(billing => this.hasIssuedBillings(billing))
    },
    columns() {
      return [
        {
          headerName: this.$t('Date'),
          field: 'date',
          component: 'FormattedDate',
          minWidth: 150,
        },
        {
          label: this.$t('Amount'),
          prop: 'gross_amount',
          component: 'FormattedPrice',
          minWidth: 140,
          maxWidth: 180,
          align: 'right',
        },
        {
          label: this.$t('Type'),
          align: 'center',
          prop: 'type',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
        {
          label: i18n.t('Post Automatically'),
          align: 'center',
          prop: 'should_post',
          minWidth: 100,
          maxWidth: 120,
          component: 'Status',
        },
      ]
    }
  },
  methods: {
    getNormalizedBillingRule(billing) {
      const rule = rrulestr(billing.attributes.recurring_rule)
      rule.options.until = this.model.date

      if (this.model.force) {
        rule.options.dtstart = new Date(billing.attributes.start_date)
      }
      else {
        rule.options.dtstart = addDays(new Date(billing.attributes.last_issue_at), 1)
      }

      if (rule.options.interval === RRule.HOURLY) {
        return rule
      }

      rule.options.dtstart = new Date(
        rule.options.dtstart.getFullYear(),
        rule.options.dtstart.getMonth(),
        rule.options.dtstart.getDate(),
      )
      rule.options.until = getDateWihoutTimezoneSigned(new Date(
        rule.options.until.getFullYear(),
        rule.options.until.getMonth(),
        rule.options.until.getDate(),
      ))

      // Clear by hour, minute and second for non-hourly recurrences
      if (rule.options.byhour) {
        rule.options.byhour = [0]
      }
      if (rule.options.byminute) {
        rule.options.byminute = [0]
      }
      if (rule.options.bysecond) {
        rule.options.bysecond = [0]
      }

      return rule
    },
    getRecurrenceList(billing) {      
      const rule = this.getNormalizedBillingRule(billing)
      const recurrenceList = rule.all()

      return recurrenceList.map(date => ({
        ...billing.attributes,
        id: crypto.randomUUID(),
        date,
      }))
    },
    getStartDate(billing) {
      if (this.model.force) {
        return billing.attributes.start_date
      }
      return billing.attributes.last_issue_at || billing.attributes.start_date
    },
    getForRecurringReceivableText(billing) {
      const {
        type,
        description
      } = billing.attributes

      const typeLabel = type === billingTypesAbbr.LumpSum
        ? this.$t('Lump Sum')
        : this.$t('Service')

      return this.$t('For Recurring Receivable') + ` (${typeLabel}) ${description || ''} ${this.$t('for')} `
    },
    hasIssuedBillings(billing) {
      return billing.attributes.last_issue_at
    },
    async onSubmit() {
      try {
        this.loading = true
        await axios.post(`/restify/recurring-billings/actions?action=advance-recurrence`, {
          repositories: this.model.repositories,
          date: this.$formatDate(this.model.date, ApiDateFormat),
          force: this.model.force,
        })
        this.$emit('save')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not generate billings'))
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    dateUntil: {
      handler(value) {
        this.model.date = getDateWihoutTimezoneSigned(value)
      },
      immediate: true,
    }
  }
}
</script>
