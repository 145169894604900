<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :queryParamParsers="queryParamParsers"
    :consistentRowColor="true"
  >
    <template #filters>
      <BaseFilterRow :title="$t('Compute Profit %')">
        <BaseSelect
          v-model="model.compute_profit"
          :add-entity="false"
          :options="computeProfitOptions"
          class="col-span-3"
        />
      </BaseFilterRow>

      <CommonReportFilters v-model="model"/>

      <PeriodYearRangeFilter
        v-model="model"
      />

      <BaseFilterRow
        :title="$t('Period Active Only')"
        :tip="$t('Only show Jobs with activity in the selected period range.')"
      >
        <BaseCheckbox
          v-model="model.period_active_only"
          class="col-span-3"
          id="print_period_active_only"
        />
      </BaseFilterRow>

      <BaseFilterRow
        :title="$t('Include Job Description')"
      >
        <BaseCheckbox
          v-model="model.include_description"
          class="col-span-3"
          id="include_description"
        />
      </BaseFilterRow>

      <BaseFilterRow
        :title="$t('Print')"
      >
        <BaseCheckbox
          v-model="model.include.actual_cost"
          :label="$t('Actual Cost')"
          class="col-span-2"
          id="actual_cost"
        />
        <BaseCheckbox
          v-model="model.include.budget_cost"
          :label="$t('Budget Cost')"
          class="col-span-2"
          id="budget_cost"
        />
        <BaseCheckbox
          v-model="model.include.actual_income"
          :label="$t('Actual Income')"
          class="col-span-2"
          id="actual_income"
        />
        <BaseCheckbox
          v-model="model.include.budget_income"
          :label="$t('Budget Income')"
          class="col-span-2"
          id="budget_income"
        />
      </BaseFilterRow>
    </template>

    <template #table="{ loading, data }">
      <PeriodCostIncomeSummaryReportTable
        :data="data"
        :filters="model"
        :data-loading="loading"
        :reportNumber="reportNumber"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>

</template>
<script>
import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
import {
  PeriodYearRangeFilter,
  DateRangeFilter,
  periodYearFilterKeys,
} from '@/components/range-filters'
import { commonFilters, PeriodCostIncomeSummaryReportOptions } from '@/modules/job-costing/components/reports/util'
import PeriodCostIncomeSummaryReportTable from '@/modules/job-costing/components/reports/PeriodCostIncomeSummaryReportTable'

export default {
  components: {
    DateRangeFilter,
    CommonReportFilters,
    PeriodCostIncomeSummaryReportTable,
    PeriodYearRangeFilter,
  },
  data() {
    return {
      model: {
        ...commonFilters,
        compute_profit: PeriodCostIncomeSummaryReportOptions.ComputeProfit.PercentOfCost,
        period_active_only: true,
        include_description: true,
        include: {
          actual_cost: true,
          budget_cost: true,
          actual_income: true,
          budget_income: true,
        },
        ...periodYearFilterKeys,
      },
      queryParamParsers: {
        include: (params, value) => {
          for (let key in value) {
            params[`include[${key}]`] = params.include[key] ? 1 : 0
          }
          delete params.include
        },
      },
      computeProfitOptions: [
        {
          value: PeriodCostIncomeSummaryReportOptions.ComputeProfit.PercentOfCost,
          label: this.$t('As % of Cost'),
        },
        {
          value: PeriodCostIncomeSummaryReportOptions.ComputeProfit.PercentOfIncome,
          label: this.$t('As % of Income'),
        }
      ]
    }
  },
  computed: {
    reportNumber() {
      return '2879'
    },
  },
}
</script>
