<template>
  <component
    :is="asRow ? 'BaseFilterRow' : 'div'"
    :title="title"
  >
    <base-filter-range :class="gridClass">
      <template #start>
        <employee-select-new
            v-model="value.employee_code_from"
            :add-entity="false"
            :placeholder="$t('Employee From')"
            :hide-label="true"
            value-key="code"
            :allow-create-no-options-only="true"
            clearable
            class="flex-1"
            @blur="onBlurStartRange"
        />
      </template>
      <template #end>
        <employee-select-new
            v-model="value.employee_code_to"
            :add-entity="false"
            :placeholder="$t('Employee To')"
            :hide-label="true"
            :rules="value.employee_code_from ? 'required' : ''"
            :allow-create-no-options-only="true"
            value-key="code"
            class="flex-1"
            clearable
        />
      </template>
    </base-filter-range>
  </component>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          employee_code_from: null,
          employee_code_to: null,
        }),
      },
      gridClass: {
        type: String,
        default: 'col-span-4',
      },
      title: {
        type: String,
        default: 'Salespersons',
      },
      asRow: {
        type: Boolean,
        default: true,
      }
    },
    methods: {
      onBlurStartRange() {
        this.value.employee_code_to = this.value.employee_code_to || this.value.employee_code_from
      }
    }
  }
</script>
