<template>
  <div v-if="!params?.node?.footer" class="flex items-center justify-start w-full space-x-2 print:hidden">
    <slot name="extra-actions-before" :row="row" :index="params.rowIndex"/>
    <table-view-button
        v-if="$isAuthorized('authorizedToShow', row) && showAction(TableActions.View)"
        :row="row"
        :skip-focus="tableProps.skipActionButtonFocus"
        :link="getViewEntityPath"
    />
    <table-edit-button
        v-if="$isAuthorized('authorizedToUpdate', row) && showAction(TableActions.Edit)"
        :openEntityInNewTab="tableProps.openEntityInNewTab"
        :row="row"
        :skip-focus="tableProps.skipActionButtonFocus"
        :link="composeEditEntityLink"
        @click="emit('edit')"
    />
    <table-delete-button
        v-if="$isAuthorized('authorizedToDelete', row) && showAction(TableActions.Delete)"
        :skip-focus="tableProps.skipActionButtonFocus"
        @click="onDelete()"
    />
    <table-archive-button
      v-if="$isAuthorized('authorizedToArchive', row) && showAction(TableActions.Archive) && !isArchived"
      :skip-focus="tableProps.skipActionButtonFocus"
      @click="onArchive()"
    />

    <table-restore-button
      v-if="$isAuthorized('authorizedToUnarchive', row) && showAction(TableActions.Archive) && isArchived"
      :skip-focus="tableProps.skipActionButtonFocus"
      @click="onRestore()"
    />
    <slot name="extra-actions" :row="row" :index="params.rowIndex"/>
  </div>
</template>

<script setup>
  import { TableActions } from './tableUtils'
  import TableViewButton from "@/components/table/actions/TableViewButton";
  import TableEditButton from "@/components/table/actions/TableEditButton";
  import TableDeleteButton from "@/components/table/actions/TableDeleteButton";
  import { computed } from 'vue'
  import { useRoute } from 'vue2-helpers/vue-router'
  import capitalize from "lodash/capitalize";
  import axios from "axios";
  import i18n from "@/i18n";
  import pluralize from "pluralize";
  import { $deleteConfirm } from "@/components/common/modal/modalPlugin";
  import { error, success } from "@/components/common/NotificationPlugin";
  import TableArchiveButton from "@/components/table/actions/TableArchiveButton.vue";
  import { $modules } from "@/enum/enums";
  import { getSetting } from "@/plugins/settingsAndEnumsPlugin";
  import TableRestoreButton from "@/components/table/actions/TableRestoreButton.vue";

  const props = defineProps({
    params: {
      type: Object,
      default: () => ({})
    },
    tableProps: {
      type: Object,
      default: () => ({})
    },
    showAction: {
      type: Function,
      default: () => false,
    },
  })

  const emit = defineEmits(['delete', 'remove-row', 'edit'])

  const route = useRoute()

  const row = computed(() => {
    return props.params?.data || {}
  })

  const isArchived = computed(() => {
    return row.value?.attributes?.archived_at
  })

  const getViewEntityPath = computed(() => {
    const rowId = row.value.id
    if (props.tableProps.getEntityViewPath) {
      return props.tableProps.getEntityViewPath(row.value)
    }

    if (!props.tableProps.viewEntityUrlQuery) {
      const path = props.tableProps.baseEntityPath || route.path
      return `${path}/${rowId}/view`
    }

    return `${props.tableProps.viewEntityUrlQuery}?id=${rowId}`
  })

  const composeEditEntityLink = computed(() => {
    const path = props.tableProps.baseEntityPath || route.path
    return `${path}${props.tableProps.editEntityUrlQuery.replace('{ID}', row.value.id)}`
  })

  function getEntityName() {
    let entityName = capitalize(props.tableProps.entity || i18n.t('row'))
    return pluralize(entityName, 1)
  }

  function removeRow(index) {
    if (index === undefined || index < 0) {
      return
    }
    emit('remove-row', index)
  }

  async function onArchive() {
    const rowId = row.value.id
    const index = props.params.rowIndex

    try {
      const archiveDays = getSetting($modules.PR, 'prune_archived_batches_after_days')
      const entityName = getEntityName()
      const defaultDeleteTitle = i18n.t(`Archive ${entityName} ?`)
      const defaultDeleteMessage = i18n.t(`Are you sure you want to archive this ${entityName}? The data will be automatically deleted after ${archiveDays} days`)

      const title = props.tableProps.deleteTitle || defaultDeleteTitle
      const description = props.tableProps.deleteDescription || defaultDeleteMessage

      const confirmed = await $deleteConfirm({
        title,
        description,
        buttonText: i18n.t('Archive'),
      })

      if (!confirmed) {
        return
      }

      if (props.tableProps.url && rowId) {
        await axios.post(`${props.tableProps.url}/${rowId}/actions?action=archive`)
      }

      removeRow(index)
    } catch (err) {
      console.warn(err)
      error('Could not archive the specified row')
    }
    emit('delete', row.value)
  }

  async function onRestore() {
    const rowId = row.value.id
    const index = props.params.rowIndex

    try {
      const entityName = getEntityName()
      const defaultDeleteTitle = i18n.t(`Restore ${entityName} ?`)
      const defaultDeleteMessage = i18n.t(`Do you want to restore this ${entityName}?`)

      const title = props.tableProps.deleteTitle || defaultDeleteTitle
      const description = props.tableProps.deleteDescription || defaultDeleteMessage

      const confirmed = await $deleteConfirm({
        title,
        description,
        buttonText: i18n.t('Restore'),
      })

      if (!confirmed) {
        return
      }

      if (props.tableProps.url && rowId) {
        await axios.post(`${props.tableProps.url}/${rowId}/actions?action=unarchive`)
      }

      removeRow(index)
    } catch (err) {
      console.warn(err)
      error('Could not archive the specified row')
    }

  }

  async function onDelete() {
    const rowId = row.value.id
    const index = props.params.rowIndex

    if (props.tableProps.deleteCustom) {
      emit('delete', row.value)
      return
    }

    try {
      const entityName = getEntityName()

      const defaultDeleteTitle = i18n.t(`Delete ${entityName}?`)
      const defaultDeleteMessage = rowId
        ? i18n.t(`Are you sure you want to delete this ${entityName}? The data will be removed on our servers. This action cannot be undone.`)
        : i18n.t(`Are you sure you want to delete this ${entityName}?`)

      const title = props.tableProps.deleteTitle || defaultDeleteTitle
      const description = props.tableProps.deleteDescription || defaultDeleteMessage

      const confirmed = await $deleteConfirm({
        title,
        description,
        buttonText: i18n.t(props.tableProps.deleteActionText),
      })

      if (!confirmed) {
        return
      }

      if (props.tableProps.deleteAction) {
        await props.tableProps.deleteAction(row.value, index)
        return
      }

      if (props.tableProps.url && rowId) {
        await axios.delete(`${props.tableProps.url}/${rowId}`)
      }

      removeRow(index)
      success('Row deleted successfully')
    } catch (err) {
      console.warn(err)
      error('Could not delete the specified row')
    }
    emit('delete', row.value)
  }

</script>
