// noinspection JSVoidFunctionReturnValueUsed

import Vue, { Component, Ref } from 'vue'
import get from 'lodash/get'
import TableActionsColumn from './TableActions.vue'
import { TableActions } from './tableUtils'
import { formatPrice } from '@/plugins/formatPrice.js'
import { tableColumns } from "@/components/table/tableColumns";
import { gridContext } from "@/components/ag-grid/gridContext";
import { formatPercent } from "@/plugins/formatPercent";
import { Column } from "@/components/ag-grid/tableTypes";
import { ValueGetterParams } from '@ag-grid-community/core'

type ColumnTypesParams = {
    slots: Record<string, any>,
    showAction: boolean,
    props: Record<string, any>,
    emit: (event: string, ...args: any[]) => void,
    tableData: Ref<any[]>,
}
export function getColumnTypes({ slots, showAction, props, emit, tableData }: ColumnTypesParams) {
    const componentTypes: Record<string, any> = {}
    for (let componentName in tableColumns) {
        // @ts-ignore
        const component = tableColumns[componentName] as Component
        componentTypes[componentName] = {
            cellRendererFramework: Vue.extend({
                render(h) {

                    let editableCell = get(this.params.colDef, 'editable', false)

                    if (typeof editableCell === 'function') {
                        editableCell = editableCell(this.params)
                    }

                    const editorParamsShowName = get(this.params.colDef, 'cellEditorParams.showName')
                    const rendererParamsShowName = get(this.params.colDef, 'cellRendererParams.showName')
                    const editorParamsTarget = get(this.params.colDef, 'cellEditorParams.target', '')
                    const rendererParamsTarget = get(this.params.colDef, 'cellRendererParams.target', '')

                    const target = editorParamsTarget || rendererParamsTarget || ''
                    const showName = editorParamsShowName || rendererParamsShowName
                    return h(component, {
                        props: {
                            row: this.params.data,
                            column: this.params.column.colDef,
                            // * Props for link components
                            id: this.params.value,
                            showPreview: false,
                            params: this.params,
                            target,
                            showName,
                            showDescription: showName,
                            editableCell,
                        }
                    })
                }
            }),
        }
    }
    return {
        ...componentTypes,
        custom: {
            cellRendererFramework: Vue.extend({
                name: 'CustomCell',
                render(h) {
                    let { field, prop } = this.params.colDef
                    field = field || prop
                    const fieldScopedSlot = slots[field]
                    if (!fieldScopedSlot) {
                        return h('div', get(this.params.data, field))
                    }

                    return fieldScopedSlot({
                        row: this.params.data,
                        index: this.params.rowIndex,
                        params: this.params,
                    })
                },
            }),
        },
        price: {
            valueGetter: (params: ValueGetterParams) => {
                const field = params.colDef?.field as string
                return formatPrice(params.getValue(field))
            },
        },
        percent: {
            valueGetter: (params: ValueGetterParams) => {
                const field = params.colDef?.field as string
                return formatPercent(params.getValue(field))
            },
        },
        actions: {
            cellRendererFramework: Vue.extend({
                name: 'ActionsColumn',
                render(h) {
                    const actionsSlot = slots.actions
                    const extraActionsSlot = slots['extra-actions']
                    const extraActionsBeforeSlot = slots['extra-actions-before']

                    if (actionsSlot) {
                        return actionsSlot({
                            row: this.params.data,
                        })
                    }


                    return h(TableActionsColumn, {
                        props: {
                            params: this.params,
                            showAction,
                            tableProps: props || {},
                        },
                        on: {
                            view: () => {
                                emit(TableActions.View, this.params.data)
                            },
                            edit: () => {
                                emit(TableActions.Edit, this.params.data)
                            },
                            delete: () => emit(TableActions.Delete, this.params.data, this.params.rowIndex),
                            'remove-row': () => {
                                const index = this.params.rowIndex
                                this.params.api.applyTransaction({
                                    remove: [this.params.data]
                                })
                                emit('remove-row', index, this.params)
                            },
                        },
                        scopedSlots: {
                            'extra-actions-before': (params) => {
                                if (extraActionsBeforeSlot) {
                                    return extraActionsBeforeSlot(params)
                                }
                                return null
                            },
                            'extra-actions': (params) => {
                                if (extraActionsSlot) {
                                    return extraActionsSlot(params)
                                }
                                return null
                            },
                        }
                    })
                },
            }),
        },
    }
}

export function getActionsColumn(_: any, maxWidth = 150): Column {
  const defaultWidth = 150
  let minWidth = 40
  if (maxWidth !== defaultWidth) {
    minWidth = maxWidth
  }
  return {
    headerName: '',
    field: 'actions',
    type: 'actions',
    sortable: false,
    resizable: true,
    lockVisible: true,
    pinned: 'right',
    minWidth,
    flex: 1,
    maxWidth,
    cellClass: 'flex justify-start actions-header',
    headerComponent: 'ActionsHeader',
    hide: gridContext.isPrintMode
  }
}

