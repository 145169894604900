<template>
  <div>
    <div class="flex space-x-4 w-full">
      <BaseButton
        v-if="canReset"
        :loading="resetLoading"
        variant="gray-light"
        @click="onReset"
      >
        <RotateCcwIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Reset') }}</span>
      </BaseButton>
      <BaseButton
        v-if="canReject"
        :loading="rejectLoading"
        variant="gray-light"
        @click="showRejectDialog = true"
      >
        <XIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Reject') }}</span>
      </BaseButton>
      <BaseButton
        v-if="canApprove"
        :loading="approveLoading"
        variant="primary"
        @click="onApprove">
        <CheckIcon class="w-4 h-4 mr-2"/>
        <span>{{ $t('Approve') }}</span>
      </BaseButton>
    </div>
    <BaseFormDialog
      v-if="showRejectDialog"
      :open.sync="showRejectDialog"
      :title="$t('Reject Timesheet')"
      :append-to-body="true"
      :loading="rejectLoading"
    >
      <BaseForm
        :save-text="$t('Reject')"
        layout="modal"
        @submit="onReject">
        <base-textarea
          v-model="rejectReason"
          :label="$t('Reject Reason')"
          rules="required"
          class="col-span-6"
        />
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from "axios";
import { CheckIcon, RotateCcwIcon, XIcon } from "vue-feather-icons";
import { TimesheetStatus } from "@/modules/payroll/components/timesheets/utils";

export default {
  components: { RotateCcwIcon, CheckIcon, XIcon },
  props: {
    timesheet: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      approveLoading: false,
      rejectLoading: false,
      showRejectDialog: false,
      resetLoading: false,
      rejectReason: '',
    }
  },
  computed: {
    canReset() {
      return [TimesheetStatus.Approved, TimesheetStatus.Rejected].includes(this.timesheet.status)
    },
    canReject() {
      return [TimesheetStatus.Approved, TimesheetStatus.Pending, TimesheetStatus.Submitted].includes(this.timesheet.status)
    },
    canApprove() {
      return [TimesheetStatus.Rejected, TimesheetStatus.Pending, TimesheetStatus.Submitted].includes(this.timesheet.status)
    },
  },
  methods: {
    async onReset() {
      try {
        this.resetLoading = true
        await axios.post(`/restify/timesheets/${this.timesheet.id}/actions?action=reset`)
        this.rejectReason = ''
        this.showRejectDialog = false
        this.timesheet.approved_at = null
        this.timesheet.rejected_at = null
        this.timesheet.status = this.timesheet?.clocked_out_at ? TimesheetStatus.Submitted : TimesheetStatus.Pending
        this.$emit('reset')
      } finally {
        this.resetLoading = false
      }
    },
    async onReject() {
      try {
        this.rejectLoading = true
        const rejectReason = this.rejectReason
        await axios.post(`/restify/timesheets/${this.timesheet.id}/actions?action=reject`, {
          rejection_reason: this.rejectReason,
        })
        this.rejectReason = ''
        this.showRejectDialog = false
        this.timesheet.approved_at = null
        this.timesheet.rejected_at = new Date().toISOString()
        this.timesheet.rejection_reason = rejectReason
        this.timesheet.status = TimesheetStatus.Rejected
        this.$emit('reject')
      } catch (err) {
        if (!err.handled) {
          this.$error('Could not reject the timesheet.')
        }
      } finally {
        this.rejectLoading = false
      }
    },
    async onApprove() {
      try {
        this.approveLoading = true
        await axios.post(`/restify/timesheets/${this.timesheet.id}/actions?action=approve`)
        this.timesheet.rejected_at = null
        this.timesheet.approved_at = new Date().toISOString()
        this.timesheet.status = TimesheetStatus.Approved
        this.$emit('approve')
      } catch (err) {
        if (!err.handled) {
          this.$error('Could not approve the timesheet.')
        }
      } finally {
        this.approveLoading = false
      }
    }
  }
}
</script>
