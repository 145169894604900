import * as echarts from 'echarts/core';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import { BarChart, PieChart } from 'echarts/charts';
import { SVGRenderer } from 'echarts/renderers';

echarts.use(
  [
    GridComponent,
    BarChart,
    PieChart,
    SVGRenderer,
    DataZoomComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent,
  ]
);

export default echarts
