<template>
  <BaseForm
    layout="vertical"
    :loading="loading"
    :show-cancel="showCancel"
    :show-back="showBack"
    :can-create-another-entity="!model.id"
    :save-text="$t('Create Daily Report')"
    :update-text="$t('Update Daily Report')"
    grid-classes="grid grid-cols-8 gap-x-4"
    @cancel="onCancel"
    @submit="onSubmit"
  >
    <BaseDatePicker
      v-model="model.date"
      :disabled="true"
      :label="$t('Date')"
      :placeholder="$t('Date')"
      id="name"
      class="col-span-8 md:col-span-4 lg:col-span-2"
    />
    <JobSelect
      v-model="model.job_id"
      :disabled="true"
      :name="$t('Job')"
      :label="$t('Job')"
      :placeholder="$t('Enter the job...')"
      id="job"
      class="col-span-8 md:col-span-4 lg:col-span-2"
    />
    <CrewSelect
      v-model="model.crew_id"
      :name="$t('Crew')"
      :label="$t('Crew')"
      :placeholder="$t('Enter the crew...')"
      id="job"
      class="col-span-8 md:col-span-4 lg:col-span-2"
      @change="getCrewMembers"
    />
    <EmployeeSelectNew
      v-model="model.foreman_id"
      :label="$t('Foreman')"
      :disabled="!model.crew_id"
      :own-filter="filterCrewMembers"
      class="col-span-8 md:col-span-4 lg:col-span-2"
    />
    <DailyReportAnswers
      ref="answers"
      :value="model.answers"
      class="col-span-8"
    />
  </BaseForm>
</template>
<script>
import axios from 'axios'
import { globalResources } from "@/components/form/util";
import DailyReportAnswers from "@/modules/payroll/components/daily-reports/DailyReportAnswers.vue";
import { resourceStatuses } from "@/enum/enums";

export default {
  components: {
    DailyReportAnswers
  },
  props: {
    redirectToListAfterStore: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalResources,
      localLoading: false,
      activeGroups: [],
      crewMembers: [],
      loading: false,
      showCancel: true,
      model: {
        answers: [],
        date: null,
        job_id: null,
        crew_id: null,
        foreman_id: null,
      },
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    filterCrewMembers(data) {
      const crewMemberLeaderIds = this.crewMembers
        .filter(member => member.pivots?.is_leader)
        .map(member => member.id)

      return data.filter(employee => {
        return crewMemberLeaderIds.includes(employee.id)
      })
    },
    async getCrewMembers() {
      const crewId = this.model.crew_id
      if (!crewId) {
        return
      }
      const { data } = await axios.get(`/restify/crews/${this.model.crew_id}`, {
        params: {
          related: 'employees'
        }
      })
      this.crewMembers = data.relationships.employees
    },
    async initModel(value) {
      this.model = {
        ...this.model,
        ...value,
      }
      await this.getCrewMembers()
    },
    hasAnswers() {
      const answers = Object.values(this.model.answers)
      if (!answers.length) {
        return false
      }
      return Object.values(this.model.answers).some((answer) => !!answer.value)
    },
    async onSubmit() {
      try {
        this.loading = true
        this.model.answers = this.$refs.answers?.getValue()
        if (this.hasAnswers()) {
          this.model.status = resourceStatuses.Answered
        }
        if (this.model.id) {
          const { data } = await axios.put(`/restify/daily-job-reports/${this.model.id}`, this.model)
          this.$emit('save', data)
          this.$success(this.$t('Daily report updated'))
          await this.$store.dispatch('timesheets/getDailyReport', data.id)
        } else {
          const { data } = await axios.post('/restify/daily-job-reports', this.model)
          await this.attachAddressesAndContacts(data.id)
          this.$success(this.$t('Daily report created'))

          if (this.$createAnotherEntity && this.redirectToListAfterStore) {
            return this.$emit('create-another')
          }
          this.$emit('save', data)
          await this.$router.push('/payroll/timesheets/daily-job-reports')
        }
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        this.initModel(value)
      },
    },
  },
}
</script>
