<template>

  <base-report-form
      :filters="model"
      :report-number="reportNumber"
      :is-stimul-soft-print="statements"
      :stimulsoft-print-entity="StimulsoftPrintEntities.Billing"
      :can-export-to-csv="!statements"
  >

    <template #filters>

      <aged-thru-filter
          :today.sync="model.today"
          :period.sync="model.period"
          :fiscal_year.sync="model.fiscal_year"
      />

      <base-filter-row
          v-if="agedByJob"
          :title="$t('Sort & Subtotal By')"
      >
        <base-select
            v-model="model.group_by"
            :options="groupByOptions"
            :name="$t('Sort By')"
            rules="required"
            class="col-span-2"
            @change="onChangeGroupBy"
        />
      </base-filter-row>

      <base-filter-row v-if="!statements" :title="$t('Format')">
        <base-select
            v-model="model.format"
            :add-entity="false"
            :options="printFormatOptions"
            class="col-span-3"
            @change="onChangeFormat"
        />
        <base-checkbox
            v-if="showAgedByCheckbox"
            v-model="model.actual_days"
            :label="$t('Actual Days')"
            class="col-span-3"
            id="actual_days"
        />
      </base-filter-row>

      <customer-range-filter v-model="model"/>

      <template v-if="agedByJob">

        <employee-range-filter v-model="model"/>

        <job-and-additional-filters
            v-if="!groupByWorkOrder"
            v-model="model"
        />

        <work-order-range-filter
            v-if="groupByWorkOrder"
            v-model="model"
        />

      </template>

      <base-filter-row :title="$t('Aging Categories (days)')">
        <div class="col-span-8 flex">
          <div class="md:grid md:grid-cols-6">
            <template v-if="!statements">
              <base-input
                  v-model="model.aging_days[0]"
                  :name="$t('Days - 0')"
                  type="number"
                  class="col-span-1 md:mr-4"
              />
              <base-input
                  v-model="model.aging_days[1]"
                  :rules="`min_value:${model.aging_days[0]}`"
                  :name="$t('Days - 1')"
                  type="number"
                  class="col-span-1 md:mr-4"
              />
              <base-input
                  v-model="model.aging_days[2]"
                  :rules="`min_value:${model.aging_days[1]}`"
                  :name="$t('Days - 2')"
                  type="number"
                  class="col-span-1 md:mr-4"
              />
            </template>
            <div class="col-span-2 flex items-baseline">
              <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">{{ $t('As of') }}</span>
              <base-date-picker
                  v-model="model.start_date"
                  :name="$t('Start Date')"
                  rules="required"
              />
            </div>
          </div>

        </div>
      </base-filter-row>

    </template>

    <template #table="{loading, data}">

      <component
          :is="getReportTableComponent"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :format="model.format"
          :days="model.aging_days"
          :actual-days="model.actual_days"
          :group-by="model.group_by"
          ref="reportTable"
      />

    </template>
  </base-report-form>

</template>
<script>
  import {
    agedByGrouping,
    AgedThruFilter,
    CustomerRangeFilter,
    EmployeeRangeFilter,
    WorkOrderRangeFilter,
    JobAndAdditionalFilters,
  } from '@/components/range-filters'
  import { agedByViewFormats } from '@/modules/accounts-receivable/components/reports/util'
  import AgedByJobReportTable from '@/modules/accounts-receivable/components/reports/AgedByJobReportTable'
  import AgedByCustomerReportTable from '@/modules/accounts-receivable/components/reports/AgedByCustomerReportTable'
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";

  const agedBy = {
    InvoiceDate: 'date',
    DueDate: 'due_date',
  }

  export default {
    components: {
      AgedThruFilter,
      CustomerRangeFilter,
      EmployeeRangeFilter,
      WorkOrderRangeFilter,
      AgedByJobReportTable,
      JobAndAdditionalFilters,
      AgedByCustomerReportTable,
    },
    props: {
      printFormatOptions: {
        required: true,
        type: Array,
        default: () => [],
      },
      reportNumber: {
        required: true,
        type: Number,
      },
      agedByJob: Boolean,
      statements: Boolean,
    },
    data() {
      return {
        agedByViewFormats,
        agedByOptions: [
          {
            label: this.$t('Invoice Date'),
            value: agedBy.InvoiceDate,
          },
          {
            label: this.$t('Due Date'),
            value: agedBy.DueDate,
          },
        ],
        groupByOptions: [
          {
            label: this.$t('Job'),
            value: agedByGrouping.Job,
          },
          {
            label: this.$t('Work Order'),
            value: agedByGrouping.WorkOrder,
          },
        ],
        model: {
          group_by: agedByGrouping.Job,
          today: true,
          period: this.$currentPeriod,
          fiscal_year: this.$currentYear,
          format: agedByViewFormats.EachInvoice,
          customer_code_from: null,
          customer_code_to: null,
          actual_days: true,

          age_by: agedBy.InvoiceDate,
          aging_days: [30, 60, 90],
          start_date: this.$now,

          employee_code_from: null,
          employee_code_to: null,
          job_number_from: null,
          job_number_to: null,
          job_ids: [],

          work_order_number_from: null,
          work_order_number_to: null,
        },
      }
    },
    computed: {
      StimulsoftPrintEntities() {
        return StimulsoftPrintEntities
      },
      showAgedByCheckbox() {
        return [agedByViewFormats.EachInvoice, agedByViewFormats.PastInvoice].includes(this.model.format)
      },
      getReportTableComponent() {
        return this.agedByJob ? 'AgedByJobReportTable' : 'AgedByCustomerReportTable'
      },
      groupByWorkOrder() {
        return this.model.group_by === agedByGrouping.WorkOrder
      },
    },
    methods: {
      onChangeFormat() {
        this.model.actual_days = false
      },
      onChangeGroupBy() {
        this.model.job_ids = []
        this.model.job_number_from = this.model.job_number_to = null
        this.model.work_order_number_from = this.model.work_order_number_to = null
      },
    },
    watch: {
      $currentYear(value) {
        this.model.fiscal_year = value
      }
    }
  }
</script>
