import type {Ref} from 'vue'
import {computed, ref, watch} from "vue";
import store from "@/store";
import {globalResources} from "@/components/form/util";

export function usePrintTemplates(entity: Ref<string | undefined>) {
  const templateKey = `${entity.value}-email-template`
  const lastUsedTemplate = localStorage.getItem(templateKey)
  const selectedTemplate = ref<string>(lastUsedTemplate || '')

  const allTemplates = computed(() => {
    return store.getters['globalLists/getResourceList'](globalResources.PrintTemplates)
  })

  const availableTemplates = computed(() => {
    return allTemplates.value
      .filter((t: any) => t.entity === entity.value && t.active)
  })

  const selectedTemplateObject = computed(() => {
    return availableTemplates.value.find((t: any) => t.id === selectedTemplate.value)
  })

  function onTemplateChange(value: string) {
    localStorage.setItem(templateKey, value)
  }

  watch(() => availableTemplates.value, (value) => {
    // Check selected template against available list
    const isTemplateInlist = availableTemplates.value.find((t: any) => t.id === selectedTemplate.value) !== undefined
    if (!isTemplateInlist) {
      selectedTemplate.value = ''
    }

    // Auto-select first available template
    if (!selectedTemplate.value && value.length > 0) {
      selectedTemplate.value = value[0]?.id
    }
  }, { immediate: true })

  return {
    selectedTemplate,
    availableTemplates,
    selectedTemplateObject,
    onTemplateChange
  };
}
