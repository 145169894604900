<template>
  <BaseWrapper
    v-bind="$attrs"
    :items="items"
    :entity="entity"
    :footer="footer"
    title-key="name"
    status-key="status"
  />
</template>
<script>
import BaseWrapper from '@/modules/common/components/entity-preview/BaseWrapper'

export default {
  components: {
    BaseWrapper,
  },
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [
      ],
      footer: {
        address: true,
        link: true,
      },
    }
  },
}
</script>
