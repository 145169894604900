<template>
  <div class="flex items-center">
    <LiveChat/>
    <base-tooltip :content="searchShortCut">
      <search-overlay class="mx-4"/>
    </base-tooltip>
    <router-link v-if="$can('organization_settings')"
                 to="/settings/organization-settings">
      <base-tooltip :content="$t('Organization Settings')">
        <SettingsIcon role="button" class="mx-2 text-gray-400 hover:text-gray-700 w-4 h-4"/>
      </base-tooltip>
    </router-link>
    <ProfileMenuItem/>
  </div>
</template>
<script>
  import { SettingsIcon } from 'vue-feather-icons'
  import SearchOverlay from '@/modules/dashboard/search/SearchOverlay'
  import ProfileMenuItem from '@/modules/dashboard/components/ProfileMenuItem'
  import { isWindows } from "@/utils/utils";
  import LiveChat from "@/modules/support/components/LiveChat.vue";

  export default {
    components: {
      LiveChat,
      SettingsIcon,
      SearchOverlay,
      ProfileMenuItem
    },
    computed: {
      searchShortCut() {
        if (isWindows()) {
          return this.$t('CTRL + K')
        } else {
          return this.$t('Command + K')
        }
      }
    },
  }
</script>
