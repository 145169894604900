<template>
  <AgDataTable
    :columns="columns"
    :url-params="urlParams"
    :groupIncludeFooter="true"
    :groupIncludeTotalFooter="true"
    url="/restify/equipment-utilization-histories"
    actions="search,refresh"
    domLayout="autoHeight"
  >
    <template #attributes.business_id="{row}">
      <component
          v-if="row?.attributes?.business_id"
          :is="getBusinessComponent(row.attributes.business_type)"
          :data="row.relationships.business"
          :show-description="false"
          :show-name="false"
      />
      <span v-else/>
    </template>
    <template #attributes.journal_id="{row}">
      <JournalLink
          v-if="row?.attributes?.journal_id"
          :id="row?.attributes?.journal_id"
          :data="row.relationships.journal"
      />
    </template>
  </AgDataTable>
</template>
<script>

import i18n from "@/i18n";
import { transactionBusinessLinkByTypes } from "@/enum/enums";
import AddlSourceLink from "@/components/links/AddlSourceLink.vue";
import SourceLink from "@/components/links/SourceLink.vue";

const TransactionTypes = {
  Expense: 'expense',
  JobCost: 'job-costed',
  CustomerBilled: 'customer-billed',
}

const TransactionTypeLabels = {
  [TransactionTypes.Expense]: 'Expense',
  [TransactionTypes.JobCost]: 'Job Cost',
  [TransactionTypes.CustomerBilled]: 'Customer Billed',
}
export default {
  components: {
    SourceLink,
    AddlSourceLink,
  },
  computed: {
    columns() {
      return [
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 200,
          maxWidth: 350,
        },
        {
          headerName: this.$t('Trans Type'),
          field: 'attributes.transaction_type',
          minWidth: 100,
          maxWidth: 180,
          valueFormatter: params => {
            return TransactionTypeLabels[params.value] || params.value
          },
        },
        {
          headerName: this.$t('Fiscal Year / Period'),
          field: 'attributes.fiscal_year',
          minWidth: 80,
          maxWidth: 110,
          valueFormatter: params => {
            if (!params.data) {
              return ''
            }
            return `${params.value} / ${params.data?.attributes?.period}`
          },
        },
        {
          headerName: this.$t('Journal'),
          field: 'attributes.journal_id',
          minWidth: 80,
          maxWidth: 110,
        },
        {
          headerName: this.$t('Date'),
          field: 'attributes.reference_date',
          minWidth: 80,
          maxWidth: 110,
          component: 'FormattedDate',
        },
        {
          label: i18n.t('Employee Vend/Cust'),
          prop: 'attributes.business_id',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Account'),
          field: 'attributes.account',
          minWidth: 100,
          maxWidth: 120,
          component: 'AccountLink',
          cellRendererParams: {
            showDescription: false,
          },
        },
        {
          headerName: this.$t('Job'),
          field: 'relationships.source.number',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Addl Source'),
          field: 'relationships.addlSource.description',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Hours'),
          field: 'attributes.hours',
          minWidth: 80,
          maxWidth: 100,
          align: 'right',
          valueFormatter: params => {
            return params.value ? params.value.toFixed(2) : ''
          },
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('$ Amount'),
          field: 'attributes.amount',
          minWidth: 100,
          maxWidth: 150,
          component: 'FormattedPrice',
          align: 'right',
          aggFunc: 'sum',
        },
      ]
    },
    urlParams() {
      return {
        addl_source_id: this.$route.params.id,
        related: 'journal[id|name],source[id|code|name|number],addlSource[id|code|name|phase_code|cost_code|change_order]',
      }
    },
  },
  methods: {
    getBusinessComponent(businessType) {
      return transactionBusinessLinkByTypes[businessType]
    },
  }
}
</script>
