<template>
  <div class="daily-report-details">
    <DetailsPageLayout :loading="dailyReportLoading">
      <template #left>
        <DailyReportDetailsCard :data="dailyReport"/>
      </template>
      <template #right>
        <DailyReportAnswerDetails :data="dailyReportData"/>
        <BaseCard class="border">
          <h4 class="mb-2 form-section-title">
            {{ $t('Timesheets') }}
          </h4>
          <TimesheetsTable
            :custom-url-params="customUrlParams"
            :for-daily-reports="true"
            class="mt-0"
          />
        </BaseCard>
        <BaseCard class="border">
          <h4 class="mb-2 form-section-title">
            {{ $t('Used Equipment') }}
          </h4>
          <TimesheetEquipmentEntriesTable
            :url-params="equipmentUrlParams"
            class="mt-0"
          />
        </BaseCard>
      </template>
    </DetailsPageLayout>
    <base-back-link to="/payroll/timesheets/daily-reports"/>
  </div>
</template>
<script lang="ts" setup>
import {computed} from 'vue'
import store from '@/store'
import DetailsPageLayout from '@/modules/common/components/DetailsPageLayout.vue'
import Data = API.Data;
import TimesheetsTable from "@/modules/payroll/pages/timesheets/TimesheetsTable.vue";
import DailyReportDetailsCard from "@/modules/payroll/components/daily-reports/DailyReportDetailsCard.vue";
import DailyReportAnswerDetails from "@/modules/payroll/components/daily-reports/DailyReportAnswerDetails.vue";
import {sourceTypes} from "@/modules/service-billing/util/service-billing";
import TimesheetEntriesTable from "@/modules/payroll/components/timesheets/TimesheetEntriesTable.vue";
import TimesheetEquipmentEntriesTable from "@/modules/payroll/components/timesheets/TimesheetEquipmentEntriesTable.vue";

const customUrlParams = computed(() => {
  return {
    source_id: dailyReportData.value.source_id,
    date: dailyReportData.value.date,
  }
})

const equipmentUrlParams = computed(() => {
  return {
    special_source_id: dailyReportData.value.source_id,
    special_source_type: sourceTypes.Equipment,
    date: dailyReportData.value.date,
  }
})

const dailyReport = computed((): Data<any> => {
  return store.state.timesheets.currentDailyReport
})

const dailyReportData = computed((): any => {
  return dailyReport.value.attributes
})

const dailyReportLoading = computed((): boolean => {
  return store.state.timesheets.currentDailyReportLoading
})
</script>
