<template>
  <BaseEntityFilterRow
    v-bind="$attrs"
    :title="$t('Accounts')"
    :value="value"
    list-key="account_ids"
    @change="resetFilters"
  >
    <template #default="{ selectList }">
      <AccountSelect
        v-if="selectList"
        v-model="value.account_ids"
        :placeholder="$t('Select Accounts')"
        collapse-tags
        multiple
      />
      <AccountRangeFilter
        v-else
        v-model="value"
      />
    </template>
    <template #extra-filters>
      <slot name="extra-filters" />
    </template>
  </BaseEntityFilterRow>
</template>
<script>
import {
  AccountRangeFilter,
} from '@/components/range-filters'

export default {
  name: 'AccountFilterRow',
  components: {
    AccountRangeFilter,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    resetFilters() {
      this.value.account_ids = [];
      this.value.account_number_from = this.value.account_number_to = null
      this.$emit('reset')
    }
  }
}
</script>
