<template>
  <SettingsForm
    :fields="fields"
    :module-name="moduleName"
    :authorized-to-collapse="true"
    grid-classes="grid grid-cols-1"
    @cancel="$router.push('/payroll/employees')"
  >
    <template #content="{model}">
      <div class="grid grid-cols-6 gap-x-3 px-4">
        <h4 class="section-title col-span-6 mb-4">
          {{ $t('Job Cost') }}
        </h4>
        <div class="col-span-3 lg:col-span-2">
          <BaseSelect
            v-model="model.estimated_burden_method_job"
            :label="$t('Burden Method')"
            :options="methodOptions"
            rules="required"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseInput
            v-model="model.percent_of_estimated_burden_job_to_post"
            :label="$t('Estimated Burden % of Gross Taxable Pay')"
            type="number"
            :min="0"
            :max="100"
            :step="0.00001"
            format="percent"
            rules="max_value:100|min_value:0"
          />
        </div>
        <div class="col-span-3 lg:col-span-2">
          <BaseInput
            v-model="model.dollars_per_hour_of_estimated_burden_job_to_post"
            :label="$t('Estimated Burden $ per Hour')"
            type="number"
            :step="0.01"
            format="price"
            rules="min_value:0"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <AccountSelect
            v-model="model.estimated_burden_job_liability_account"
            :name="$t('Job Accrual Account')"
            :label="$t('Accrual Account')"
            :rules="getAccountRules(model, types.JOB)"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <SubAccountSelect
            v-model="model.estimated_burden_job_liability_subaccount"
            :label="$t('Accrual Sub')"
          />
        </div>
        <div class="col-span-3 lg:col-span-2">
          <AccountSelect
            v-model="model.estimated_burden_job_expense_account"
            :name="$t('Job Expense Account')"
            :label="$t('Expense Account')"
            :rules="getAccountRules(model, types.JOB)"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <SubAccountSelect
            v-model="model.estimated_burden_job_expense_subaccount"
            :label="$t('Expense Sub')"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseSwitch
            v-model="model.is_burden_charged_to_specified_phase_and_cost_code"
            :label-info="$t('Burden Charged to Specified Phase and Cost Code')"
          />
        </div>
        <div
          v-if="model.is_burden_charged_to_specified_phase_and_cost_code"
          class="col-span-3 lg:col-span-3">
          <PhaseCostInput
            :phase_code.sync="model.estimated_burden_job_phase"
            :cost_code.sync="model.estimated_burden_job_cost_code"
            :show-change-order="false"
            :line-item-type="costTypes.Cost"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseGridSelect
            v-model="model.estimated_burden_job_cost_type_id"
            :resource-name="globalResources.JobCostTypes"
            :label="$t('Cost Type')"
          />
        </div>
        <h4 class="section-title col-span-6 mb-4 mt-6">
          {{ $t('Equipment Management') }}
        </h4>
        <div class="col-span-3 lg:col-span-2">
          <BaseSelect
            v-model="model.estimated_burden_method_eqp"
            :label="$t('Burden Method')"
            :options="methodOptions"
            rules="required"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseInput
            v-model="model.percent_of_estimated_burden_eqp_to_post"
            :label="$t('Estimated Burden % of Gross Taxable Pay')"
            type="number"
            :min="0"
            :max="100"
            :step="0.00001"
            format="percent"
            rules="max_value:100|min_value:0"
          />
        </div>
        <div class="col-span-3 lg:col-span-2">
          <BaseInput
            v-model="model.dollars_per_hour_of_estimated_burden_eqp_to_post"
            :label="$t('Estimated Burden $ per Hour')"
            type="number"
            :step="0.01"
            format="price"
            rules="min_value:0"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <AccountSelect
            v-model="model.estimated_burden_eqp_liability_account"
            :name="$t('Equipment Accrual Account')"
            :label="$t('Accrual Account')"
            :rules="getAccountRules(model, types.EQP)"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <SubAccountSelect
            v-model="model.estimated_burden_eqp_liability_subaccount"
            :label="$t('Accrual Sub')"
          />
        </div>
        <div class="col-span-3 lg:col-span-2">
          <AccountSelect
            v-model="model.estimated_burden_eqp_expense_account"
            :name="$t('Equipment Expense Account')"
            :label="$t('Expense Account')"
            :rules="getAccountRules(model, types.EQP)"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <SubAccountSelect
            v-model="model.estimated_burden_eqp_expense_subaccount"
            :label="$t('Expense Sub')"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseGridSelect
            v-model="model.estimated_burden_eqp_cost_type_id"
            :resource-name="globalResources.EquipmentTypes"
            :label="$t('Distribute Burden To')"
          />
        </div>
        <h4 class="section-title col-span-6 mb-4 mt-6">
          {{ $t('Service Billing') }}
        </h4>
        <div class="col-span-3 lg:col-span-2">
          <BaseSelect
            v-model="model.estimated_burden_method_sb"
            :label="$t('Burden Method')"
            :options="methodOptions"
            rules="required"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseInput
            v-model="model.percent_of_estimated_burden_sb_to_post"
            :label="$t('Estimated Burden % of Gross Taxable Pay')"
            type="number"
            :min="0"
            :max="100"
            :step="0.00001"
            format="percent"
            rules="max_value:100|min_value:0"
          />
        </div>
        <div class="col-span-3 lg:col-span-2">
          <BaseInput
            v-model="model.dollars_per_hour_of_estimated_burden_sb_to_post"
            :label="$t('Estimated Burden $ per Hour')"
            type="number"
            :step="0.01"
            format="price"
            rules="min_value:0"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <AccountSelect
            v-model="model.estimated_burden_sb_liability_account"
            :name="$t('Service Billing Accrual Account')"
            :label="$t('Accrual Account')"
            :rules="getAccountRules(model, types.SB)"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <SubAccountSelect
            v-model="model.estimated_burden_sb_liability_subaccount"
            :label="$t('Accrual Sub')"
          />
        </div>
        <div class="col-span-3 lg:col-span-2">
          <AccountSelect
            v-model="model.estimated_burden_sb_expense_account"
            :name="$t('Service Billing Expense Account')"
            :label="$t('Expense Account')"
            :rules="getAccountRules(model, types.SB)"
          />
        </div>
        <div class="col-span-3 lg:col-span-1">
          <SubAccountSelect
            v-model="model.estimated_burden_sb_expense_subaccount"
            :label="$t('Expense Sub')"
          />
        </div>
        <div class="col-span-6"></div>
        <div class="col-span-3 lg:col-span-2">
          <BaseGridSelect
            v-model="model.estimated_burden_sb_cost_type_id"
            :resource-name="globalResources.ServiceBillingCostTypes"
            :label="$t('Distribute Burden To')"
          />
        </div>
      </div>
    </template>
  </SettingsForm>
</template>
<script>
import SettingsForm from "@/modules/common/components/SettingsForm.vue";
import PhaseCostInput from "@/components/common/PhaseCostInput.vue";
import { costTypes } from "@/enum/enums";
import { globalResources } from "@/components/form/util";

export default {
  components: {
    PhaseCostInput,
    SettingsForm
  },
  data() {
    return {
      entries: [],
      moduleName: 'payroll',
      fields: [
        'is_burden_charged_to_specified_phase_and_cost_code',
        'estimated_burden_job_phase',
        'estimated_burden_job_cost_code',

        'estimated_burden_method_job',
        'estimated_burden_job_cost_type_id',
        'estimated_burden_job_liability_account',
        'estimated_burden_job_liability_subaccount',
        'estimated_burden_job_expense_account',
        'estimated_burden_job_expense_subaccount',
        'percent_of_estimated_burden_job_to_post',
        'dollars_per_hour_of_estimated_burden_job_to_post',

        'estimated_burden_method_eqp',
        'estimated_burden_eqp_cost_type_id',
        'estimated_burden_eqp_liability_account',
        'estimated_burden_eqp_liability_subaccount',
        'estimated_burden_eqp_expense_account',
        'estimated_burden_eqp_expense_subaccount',
        'percent_of_estimated_burden_eqp_to_post',
        'dollars_per_hour_of_estimated_burden_eqp_to_post',

        'estimated_burden_method_sb',
        'estimated_burden_sb_cost_type_id',
        'estimated_burden_sb_liability_account',
        'estimated_burden_sb_liability_subaccount',
        'estimated_burden_sb_expense_account',
        'estimated_burden_sb_expense_subaccount',
        'percent_of_estimated_burden_sb_to_post',
        'dollars_per_hour_of_estimated_burden_sb_to_post',
      ],
      types: {
        JOB: 'job',
        EQP: 'eqp',
        SB: 'sb',
      }
    }
  },
  computed: {
    globalResources() {
      return globalResources
    },
    costTypes() {
      return costTypes
    },
    methodOptions() {
      return [
        {
          label: this.$t('Post ACTUAL Burden Only'),
          value: 'actual'
        },
        {
          label: this.$t('Post ACTUAL plus ESTIMATED Burden'),
          value: 'both'
        },
        {
          label: this.$t('Post ESTIMATED Burden Only'),
          value: 'estimated'
        },
      ]
    }
  },
  methods: {
    getAccountRules(model, type) {
      const burdenType = model[`estimated_burden_method_${type}`]
      if (['both', 'estimated'].includes(burdenType)) {
        return 'required'
      }
      return ''
    },
  }
}
</script>

<style scoped lang="scss">

</style>
