<template>
  <base-form :loading="loading"
             :can-create-another-entity="!model.id"
             :save-text="$t('Create maintenance code')"
             :update-text="$t('Update maintenance code')"
             layout="modal"
             @submit="onSubmit"
  >
    <div class="col-span-6 md:col-span-2">
      <code-input
        v-model="model.code"
        :model="model"
        :resource-name="globalResources.EquipmentMaintenances"
      />
    </div>
    <div class="col-span-6 md:col-span-4">
      <base-input v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Description')"
                  id="description"
                  rules="max:150"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-select
          v-model="model.interval"
          :options="intervalTypeOptions"
          :label="$t('Inteval')"
          :name="$t('Inteval')"
          rules="required"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input
          v-model="model.interval_months"
          :label="$t('Months')"
          :name="$t('Months')"
          :step="1"
          :min="0"
          :max="600"
          type="number"
          rules="required|min_value:0|max_value:600"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <base-input
          v-model="model.interval_value"
          :label="$t('Value')"
          :name="$t('Value')"
          :step="1"
          :min="0"
          :max="999999"
          type="number"
          rules="required|min_value:0|max_value:999999"
      />
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { intervalTypeOptions, intervalTypes } from '@/enum/equipment'
  import { globalResources } from "@/components/form/util";


  export default {
    inheritAttrs: false,
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        globalResources,
        loading: false,
        intervalTypeOptions,
        model: {
          code: '',
          description: '',
          interval: intervalTypes.Months,
          interval_months: 0,
          interval_value: 0,
        },
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/maintenance-codes/${this.model.id}`, this.model)
            this.$success(this.$t('Maintenance code updated.'))
            this.$emit('refresh')
          } else {
            await axios.post('/restify/maintenance-codes', this.model)
            this.$success(this.$t('Maintenance code created.'))
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', this.model)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the maintenance code.'))
        } finally {
          this.loading = false
        }
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return
          }
          this.model = {
            ...this.model,
            ...value.attributes,
          }
        },
      },
    },
  }
</script>
