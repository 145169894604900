<template>
  <div
    v-if="hasPrintOptions"
    :class="{
      [`col-span-${colSpan}`]: colSpan,
    }"
  >
    <label class="form-label">
      {{ $t('Print Options') }}
    </label>
    <BaseCheckbox
      v-if="showLineItemsCheckbox"
      v-model="data.flags.print_line_items"
      :label="$t('Print Line Items')"
      id="print_line_items"
    />
    <BaseCheckbox
      v-if="showRetentionCheckbox"
      v-model="data.flags.print_retention"
      :label="$t('Print Retention')"
      id="print_retention"
    />
    <BaseCheckbox
      v-if="showSalesTaxCheckbox"
      v-model="data.flags.print_sales_tax"
      :label="$t('Print Sales Tax')"
      id="print_sales_tax"
    />
    <BaseCheckbox
      v-if="showPrintAdditionalCheckbox"
      v-model="data.flags.print_additional_info"
      :label="$t('Print additional information (Call Placed By and Purchase Order #)')"
      id="print_additional_info"
    />
    <BaseCheckbox
      v-if="showFullLineItemName"
      v-model="data.flags.print_full_line_item"
      :label="$t('Print Full Line Item Name (Phase/Cost Code/Change Order)')"
      id="print_full_line_item"
    />
  </div>
</template>
<script>
import { billingTypes } from '@/modules/accounts-receivable/pages/billings/billings'

export default {
  props: {
    billing: {
      type: Object,
      default: () => ({}),
      required: true
    },
    billingType: {
      type: String,
      default: billingTypes.CostPlus,
      required: true
    },
    data: {
      type: Object,
      default: () => ({
        flags: {
          print_retention: true,
          print_sales_tax: true,
          print_line_items: true,
          print_additional_info: false,
          print_full_line_item: false,
        }
      }),
    },
    selectedTemplateObj: {
      type: Object,
      default: () => ({}),
    },
    colSpan: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    hasRetention() {
      return this.billing.attributes?.retention_amount > 0
    },
    hasSalesTax() {
      return this.billing.attributes?.sales_tax_amount > 0
    },
    showRetentionCheckbox() {
      // If billing has retention, always print it (& hide checkbox)
      if (this.hasRetention) {
        return false
      }

      const progressTemplatesWithRetention = [
        'Progress Billing - Combined Invoice',
        // Will be enabled in the future
        // 'Progress Billing - Phase Subtotals',
        // 'Progress Billing - Phase Totals Only',
      ]

      if (this.billingType === billingTypes.Progress) {
        return progressTemplatesWithRetention.includes(this.selectedTemplateObj?.name)
      }

      const upbTemplatesWithRetention = [
        'Unit Price Billing',
        'Unit Price Billing - Phase Subtotals',
        'Unit Price Billing - Phase Totals Only',
      ]

      if (this.billingType === billingTypes.UnitPrice) {
        return upbTemplatesWithRetention.includes(this.selectedTemplateObj?.name)
      }

      const lumpSumTemplatesWithRetention = [
        'Lump Sum Billing',
      ]

      if (this.billingType === billingTypes.LumpSum) {
        return lumpSumTemplatesWithRetention.includes(this.selectedTemplateObj?.name)
      }

      return false
    },
    showSalesTaxCheckbox() {
      // If billing has sales tax, always print it (& hide checkbox)
      if (this.hasSalesTax) {
        return false
      }

      const lumpSumTemplatesWithSalesTax = [
        'Lump Sum Billing',
      ]

      if (this.billingType === billingTypes.LumpSum) {
        return lumpSumTemplatesWithSalesTax.includes(this.selectedTemplateObj?.name)
      }

      return false
    },
    showLineItemsCheckbox() {
      const lumpSumTemplatesWithLineItems = [
        'Lump Sum Billing',
      ]

      if (this.billingType === billingTypes.LumpSum) {
        return lumpSumTemplatesWithLineItems.includes(this.selectedTemplateObj?.name)
      }

      return false
    },
    showPrintAdditionalCheckbox() {
      const serviceTemplatesWithPrintAdditional = [
        'Service Invoice - Envelope',
      ]

      if (this.billingType === billingTypes.Service) {
        return serviceTemplatesWithPrintAdditional.includes(this.selectedTemplateObj?.name)
      }

      return false
    },
    showFullLineItemName() {
      const progressG703 = [
        'Progress Billing - AIA Form G703',
      ]

      if (this.billingType === billingTypes.Progress) {
        return progressG703.includes(this.selectedTemplateObj?.name)
      }

      return false
    },
    hasPrintOptions() {
      return [
        this.showLineItemsCheckbox,
        this.showRetentionCheckbox,
        this.showSalesTaxCheckbox,
        this.showPrintAdditionalCheckbox,
        this.showFullLineItemName,
      ].some(Boolean)
    },
  }
}
</script>
