import { gridContext } from "@/components/ag-grid/gridContext";
import { cellClasses } from "@/components/ag-grid/columnUtils";

function extendCellOrHeaderClasses(col, prop, addClasses) {
  const originalClass = col[prop]

  if (typeof originalClass === 'function') {
    col[prop] = (params) => {
      return `${addClasses} ${originalClass(params)}`
    }
  }
  else {
    col[prop] = `${col[prop] || ''} ${addClasses}`
  }
}

/**
 * Maps our internal column definitions to Ag Grid column definitions.
 * Used for backwards compatibility with our previous table column definitions.
 * @param col
 * @param tableSlots
 */
export function mapToAgGridColumn(col, tableSlots) {
  // For backwards compatibility with our previous table
  if (Array.isArray(col.children)) {
    col.children = col.children?.map(c =>  {
      c.suppressMovable = col.suppressMovable
      return mapToAgGridColumn(c, tableSlots)
    })
  }
  col.field = col.field || col.prop
  col.prop = col.field
  col.headerName = col.headerName || col.label
  col.type = col.type || col.component || col.cellRenderer
  if (col.type === 'agGroupCellRenderer') {
    col.type = undefined
  }
  if (col.sortable !== false) {
    col.sortable = isColumnSortable(col)
  }

  col.cellClass = col.cellClass || ''
  col.headerClass = col.headerClass || ''

  if (col.align === 'center') {
    const centerClasses = 'flex justify-center items-center'
    extendCellOrHeaderClasses(col, 'cellClass', centerClasses)

    const headerCenterClasses = 'flex justify-center items-center text-center'
    extendCellOrHeaderClasses(col, 'headerClass', headerCenterClasses)
  } else if (col.align === 'right') {
    const rightClasses = 'flex justify-end items-center'
    extendCellOrHeaderClasses(col, 'cellClass', rightClasses)

    const headerRightClasses = 'flex justify-end items-center text-right'
    extendCellOrHeaderClasses(col, 'headerClass', headerRightClasses)
  }

  const keysToDelete = ['label', 'component', 'align']
  keysToDelete.forEach(key => delete col[key])

  const hasSlot = tableSlots[col.field]

  // If the column has a slot, we need to set the cellRenderer to our custom cell renderer
  if (hasSlot) {
    col.type = 'custom'
  }
  return col
}

export function isColumnSortable(column) {
  let field = column.field || ''
  field = field.replace('attributes.', '')
  let sortables = gridContext.filterOptions.sortables || []
  
  if (!sortables.length) {
    return column.sortable || false
  }

  sortables = sortables.map(s => s.column)
  return sortables.includes(field)
}

/**
 * Toggles edit on all grid columns
 * @param grid
 * @param readOnly
 */
export function toggleEdit(grid, readOnly) {
  if (!grid) {
    return
  }
  const isEditable = !readOnly
  let cols = grid.api.getColumnDefs() || []

  cols = cols.map(col => {
    return updateColumnEditing(col, isEditable)
  })
  grid.api.setColumnDefs(cols)
}

function updateColumnEditing(column, isEditable) {
  if (column.editable !== undefined && !isEditable) {
    column.editable = false
  }
  if (!isEditable && typeof column.cellClass === 'string') {
    column.cellClass = column.cellClass.replaceAll('readonly-ag-cell', '')
  }
  if (column.children) {
    column.children.forEach(col => updateColumnEditing(col, isEditable))
  }
  return column
}
