<template>
  <base-form
    layout="vertical"
    v-bind="$attrs"
    :loading="loading"
    :submit-disabled="openYearDisabled"
    :save-text="$t('Open Payroll Calendar Year')"
    @cancel="$emit('cancel')"
    @submit="onSubmit"
  >
    <base-input
      :disabled="true"
      :value="currentOpenYear"
      :label="$t('Current Calendar Year')"
    />

    <base-input
      :disabled="true"
      :value="year"
      :label="$t('New Calendar Year')"
    />
    <div
      v-if="!openYearDisabled"
      class="col-span-6">
      <h4 class="section-title col-span-6 mb-4">
        {{ $t('State Tax Jurisdiction values') }}
      </h4>
      <BaseAlert class="mb-4">
        {{ $t('When left unchanged, the values will be copied from the current calendar year.') }}
      </BaseAlert>
      <AgDataTable
        :columns="columns"
        ref="table"
        url="/restify/state-tax-jurisdictions"
        :url-params="{ related: 'whVendor,uiVendor'}"
        default-sort="code"
        domLayout="autoHeight"
        :transform-data="onMapJurisdictions"
        @update:data="jurisdiction = $event"
      >
      </AgDataTable>
    </div>
    <div class="col-span-6">
      <BaseAlert
        v-if="isAlreadyOpen"
        type="warning"
        class="col-span-6"
      >
        <span>{{ $t(`Calendar Year ${year} is already open`) }}</span>
      </BaseAlert>
      <BaseAlert
        v-if="isNextYearGreater && !isDecember"
        type="warning"
        class="col-span-6"
      >
        <span>{{ $t(`Calendar Year ${currentOpenYear} is already open. Opening calendar year ${year} wil only be available starting with December ${currentOpenYear}`) }}</span>
      </BaseAlert>
    </div>
  </base-form>
</template>
<script>

import axios from "axios";
import { $modules } from "@/enum/enums";
import { cellClasses } from "@/components/ag-grid/columnUtils";

export default {
  data() {
    return {
      loading: false,
      year: this.getNewCalendarYear(),
      jurisdiction: [],
    }
  },
  computed: {
    currentOpenYear() {
      return this.$settings($modules.PR, 'calendar_year')
    },
    isDecember() {
      const currentMonth = new Date().getMonth()
      return currentMonth === 11
    },
    isNextYearGreater() {
      const currentYear = new Date().getFullYear()
      return this.year > currentYear
    },
    isAlreadyOpen() {
      return this.currentOpenYear === this.year
    },
    openYearDisabled() {
      return this.isAlreadyOpen || (this.isNextYearGreater && !this.isDecember)
    },
    columns() {
      return [
        {
          label: this.$t('Code'),
          prop: 'code',
          cellClass: cellClasses.ReadOnlyLight,
        },
        {
          label: this.$t('Description'),
          prop: 'description',
          cellClass: cellClasses.ReadOnlyLight,
        },
        {
          headerName: this.currentOpenYear,
          children: [
            {
              label: this.$t('Unemployment Percent'),
              prop: 'old_sui_rate',
              component: 'FormattedPercent',
              align: 'right',
              cellClass: cellClasses.ReadOnlyLight,
            },
            {
              label: this.$t('Annual Earnings Limit'),
              prop: 'old_sui_limit_amount',
              component: 'FormattedPrice',
              align: 'right',
              cellClass: cellClasses.ReadOnlyLight,
            },
            {
              label: this.$t('Training Percent'),
              prop: 'old_training_percent',
              component: 'FormattedPercent',
              align: 'right',
              cellClass: cellClasses.ReadOnlyLight,
            },
          ]
        },
        {
          headerName: this.year,
          children: [
            {
              label: this.$t('Unemployment Percent'),
              prop: 'sui_rate',
              component: 'FormattedPercent',
              align: 'right',
              editable: true,
            },
            {
              label: this.$t('Annual Earnings Limit'),
              prop: 'sui_limit_amount',
              component: 'FormattedPrice',
              align: 'right',
              editable: true,
            },
            {
              label: this.$t('Training Percent'),
              prop: 'training_percent',
              component: 'FormattedPercent',
              align: 'right',
              editable: true,
            },
          ]
        }

      ]
    }
  },
  methods: {
    getNewCalendarYear() {
      const now = new Date()
      const currentYear = this.$settings($modules.PR, 'calendar_year') || now.getFullYear()

      return currentYear + 1
    },
    onMapJurisdictions(jurisdiction) {
      this.jurisdiction = jurisdiction.map(j => {
        return {
          id: j.id,
          ...j.attributes,
          old_sui_rate: j.attributes.sui_rate,
          old_sui_limit_amount: j.attributes.sui_limit_amount,
          old_training_percent: j.attributes.training_percent,
        }
      })
      return this.jurisdiction
    },
    getJurisdictionInfo() {
      return this.jurisdiction.map(j => {
        return {
          id: j.id,
          sui_rate: j.sui_rate,
          sui_limit_amount: j.sui_limit_amount,
          training_percent: j.training_percent,
        }
      })
    },
    async onSubmit() {
      try {
        this.loading = true
        await axios.post(`/restify/company-settings/actions?action=open-new-payroll-calendar-year`, {
          year: this.year,
          jurisdiction: this.getJurisdictionInfo(),
        })
        await this.$store.dispatch('company/getCompanySettings')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not open the new calendar year'))
      } finally {
        this.loading = false
      }
    }
  },
}
</script>
