<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :has-summary="false"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header" />
    </template>

    <template #thead-infos>
      <th colspan="3" />
      <th
        colspan="2"
        class="thead-infos border-b-2 border-dashed pb-2"
      >
        <div class="text-center">
          {{ $t(`--Units Per Manhour--`)  }}
        </div>
      </th>
      <th colspan="11"/>
    </template>

    <template #header="{ row }">
      <template v-if="row.header.phase_code">
        <span> {{ $t('Phase Code:') }} </span>
        <span>{{ row.header.phase_code }}</span>
      </template>
      <template v-else>
        <span> {{ $t('Job:') }} </span>
        <JobLink
          :data="row.header.job"
          class="inline"
        />
      </template>
    </template>

    <template #totals.units_per_manhour="{ row }">
      <div>
        {{
          row?.totals?.units_per_manhour?.mtl_amount
            ? $formatQuantity(row?.totals?.units_per_manhour?.mtl_amount) + (row.totals?.units_per_manhour.um || '')
            : ' '
        }}
      </div>
      <div>
        {{
          row?.totals?.units_per_manhour?.amount
            ? $formatHours(row?.totals?.units_per_manhour?.amount)
            : ' '
        }}
      </div>
    </template>
    <template #totals.units_per_manhour_to_date="{ row }">
      <div>
        {{
          row.totals?.units_per_manhour.mtl_amount_to_date
            ? $formatQuantity(row.totals?.units_per_manhour.mtl_amount_to_date) + (row.totals?.units_per_manhour.um || '')
            : ' '
        }}
      </div>
      <div>
        {{
          row.totals?.units_per_manhour?.amount_to_date
            ? $formatHours(row.totals?.units_per_manhour?.amount_to_date)
            : ' '
        }}
      </div>
    </template>

    <template #line_item.budget_amount="{ row }">
      <dl class="w-full">
        <dt
          v-for="dataPoint of dataPoints"
          :key="dataPoint.prop"
          class="flex space-x-2 justify-between"
        >
          <span>{{ dataPoint.label }}:</span>
          <span>
            {{ getDataPointValue(dataPoint, row.totals, 'amount') }}
          </span>
        </dt>
      </dl>
    </template>

    <template #line_item.period_amount="{ row }">
      <div
        v-for="dataPoint of dataPoints"
        :key="dataPoint.prop"
      >
        {{ getDataPointValue(dataPoint, row.totals, 'period') }}
      </div>
    </template>

    <template #line_item.to_date_amount="{ row }">
      <div
        v-for="dataPoint of dataPoints"
        :key="dataPoint.prop"
      >
        {{ getDataPointValue(dataPoint, row.totals, 'amount_to_date') }}
      </div>
    </template>

    <template #line_item.remaining_budget="{ row }">
      <div
        v-for="dataPoint of dataPoints.filter(dataPoint => dataPoint.hasRemaining)"
        :key="dataPoint.prop"
      >
        {{ getDataPointValue(dataPoint, row.totals, 'remaining') }}
      </div>
    </template>

    <template #line_item.variance="{ row }">
      <div
        v-for="dataPoint of dataPoints.filter(dataPoint => dataPoint.hasVariance)"
        :key="dataPoint.prop"
      >
        {{ getDataPointValue(dataPoint, row.totals, 'variance') }}
      </div>
    </template>

    <template #subtotal="{ subtotal }">
      <td
        align="center"
        style="vertical-align: top;"
      >
        {{ subtotal?.description }}
      </td>
      <td
        align="center"
        style="vertical-align: top;"
      >
        {{ $formatDate(subtotal.totals.last_updated_at) }}
      </td>
      <td
        align="right"
        style="vertical-align: top;"
      >
        {{ subtotal.totals.completion ? $formatPercent(subtotal.totals.completion) : '' }}
      </td>
      <td colspan="2" />
      <td
        align="right"
        style="vertical-align: top;"
      >
        <dl class="w-full">
          <dt
            v-for="dataPoint of dataPoints"
            :key="dataPoint.prop"
            class="flex space-x-2 justify-between"
          >
            <span>{{ dataPoint.label }}:</span>
            <span>
              {{ getDataPointValue(dataPoint, subtotal.totals, 'amount') }}
            </span>
          </dt>
        </dl>
      </td>
      <td
        v-if="displayPeriodAmount"
        align="right"
        style="vertical-align: top;"
      >
        <div
          v-for="dataPoint of dataPoints"
          :key="dataPoint.prop"
        >
          {{ getDataPointValue(dataPoint, subtotal.totals, 'period') }}
        </div>
      </td>
      <td
        align="right"
        style="vertical-align: top;"
      >
        <div
          v-for="dataPoint of dataPoints"
          :key="dataPoint.prop"
        >
          {{ getDataPointValue(dataPoint, subtotal.totals, 'amount_to_date') }}
        </div>
      </td>
      <td
        align="right"
        style="vertical-align: top;"
      >
        <div
          v-for="dataPoint of dataPoints.filter(dataPoint => dataPoint.hasRemaining)"
          :key="dataPoint.prop"
        >
          {{ getDataPointValue(dataPoint, subtotal.totals, 'remaining') }}
        </div>
      </td>
      <td
        align="right"
        style="vertical-align: top;"
      >
        <div
          v-for="dataPoint of dataPoints.filter(dataPoint => dataPoint.hasVariance)"
          :key="dataPoint.prop"
        >
          {{ getDataPointValue(dataPoint, subtotal.totals, 'variance') }}
        </div>
      </td>
    </template>

    <template #html-row="{ htmlData }">
      <tr class="content-row">
        <td colspan="3" />
        <td colspan="7">
          <div class="py-4">
            <div
              v-if="htmlData.title"
              class="font-semibold my-2"
            >
              {{ htmlData.title }}
            </div>
            <template v-if="htmlData.payroll_totals">
              <div class="grid grid-cols-4 font-semibold">
                <div class="col-span-1">
                  <span>{{ $t('Description') }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $t('Hours') }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $t('Amount') }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $t('% of Labor') }}</span>
                </div>
              </div>
              <div class="my-2 border-b border-gray-500" />
              <div
                v-for="entry of htmlData.payroll_totals"
                class="grid grid-cols-4"
              >
                <div class="col-span-1">
                  <span>{{ entry.special_source.description }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ ['REGPAY', 'OVTPAY', 'PRMPAY'].includes(entry.special_source.code)
                    ? $formatHours(entry.quantity)
                    : ''
                  }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $formatPrice(entry.amount) }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ entry.percent ? $formatPercent(entry.percent, { minimumFractionDigits: 2 }) : '' }}</span>
                </div>
              </div>
            </template>
            <template v-else-if="htmlData.craft_code_totals">
              <div class="grid grid-cols-3 font-semibold">
                <div class="col-span-1">
                  <span>{{ $t('Craft Code/Level') }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $t('Hours') }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $t('Amount') }}</span>
                </div>
              </div>
              <div class="my-2 border-b border-gray-500" />
              <div
                v-for="entry of htmlData.craft_code_totals.items"
                class="grid grid-cols-3"
              >
                <div class="col-span-1">
                  <CraftCodeLink
                    :id="entry.craft_code.id"
                    link-class="text-gray-700"
                  />
                </div>
                <div class="col-span-1">
                  <span>{{ $formatHours(entry.quantity) }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $formatPrice(entry.amount) }}</span>
                </div>
              </div>
              <div class="grid grid-cols-3 font-semibold mt-4">
                <div class="col-span-1">
                  <span>{{ $t('Total') }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $formatHours(htmlData.craft_code_totals.totals.quantity) }}</span>
                </div>
                <div class="col-span-1">
                  <span>{{ $formatPrice(htmlData.craft_code_totals.totals.amount) }}</span>
                </div>
              </div>
            </template>
          </div>
        </td>
      </tr>
    </template>

  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { isNumber } from '@/modules/common/util/commonUtils'
import { LaborStatusReportOptions } from '@/modules/job-costing/components/reports/util'
import CraftCodeLink from "@/components/links/CraftCodeLink.vue";

export default {
  extends: ReportTableWrapper,
  components: {
    CraftCodeLink,
  },
  computed: {
    displayPeriodAmount() {
      return this.format !== LaborStatusReportOptions.Format.NoPeriodAmount
    },
    columns() {
      return [
        {
          label: this.$t('Line Item'),
          prop: 'line_item',
          minWidth: 100,
          maxWidth: 450,
          align: 'left',
          verticalAlign: 'top',
          component: 'LineItemLink',
        },
        {
          label: this.$t('Last <br> Update'),
          prop: 'line_item.last_updated_at',
          minWidth: 80,
          maxWidth: 100,
          align: 'center',
          verticalAlign: 'top',
          component: 'FormattedDate',
        },
        {
          label: this.$t('% <br> Comp'),
          prop: 'line_item.completion',
          minWidth: 130,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedPercent',
          hideZero: true,
        },
        {
          label: this.$t('Budget'),
          prop: 'totals.units_per_manhour',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedQuantity',
        },
        {
          label: this.$t('Actual'),
          prop: 'totals.units_per_manhour_to_date',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedQuantity',
        },
        {
          label: this.$t('Budget <br> Amount'),
          prop: 'line_item.budget_amount',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Period <br> Amount'),
          prop: 'line_item.period_amount',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedPrice',
          visible: this.displayPeriodAmount,
        },

        {
          label: this.$t('To-Date <br> Amount'),
          prop: 'line_item.to_date_amount',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Remaining <br> Budget'),
          prop: 'line_item.remaining_budget',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedPrice',
        },
        {
          label: this.$t('Projected <br> Variance'),
          prop: 'line_item.variance',
          minWidth: 120,
          maxWidth: 150,
          align: 'right',
          verticalAlign: 'top',
          component: 'FormattedPrice',
        },
      ]
    },
    format() {
      return this.options.format
    },
    include() {
      const keys = [
        'hours',
        'labor',
        'labor_plus_burden',
        'burden_dollars',
        'burden_percent',
      ]

      return keys.reduce((acc, key) => {
        acc[key] = this.$route.query[`include[${key}]`] == '1' || this.$route.query[`include[${key}]`] == 'true'
        return acc
      }, {})
    },
    printPhaseTotals() {
      return [1, "1", true].includes(this.options.phase_totals)
    },
    dataPoints() {
      const dataPoints = [
        {
          label: this.$t('Manhours'),
          prop: 'manhours',
          formatter: this.$formatHours,
          show: this.include.hours,
          hasRemaining: true,
          hasVariance: true,
        },
        {
          label: this.$t('Labor $'),
          prop: 'labor_dollars',
          formatter: this.$formatPrice,
          show: this.include.labor && !this.include.labor_plus_burden,
          hasRemaining: true,
          hasVariance: true,
        },
        {
          label: this.$t('Burden $'),
          prop: 'burden_dollars',
          formatter: this.$formatPrice,
          show: this.include.burden_dollars && !this.include.labor_plus_burden,
          hasRemaining: true,
          hasVariance: true,
        },
        {
          label: this.$t('Lab+Brd $'),
          prop: 'labor_plus_burden_dollars',
          formatter: this.$formatPrice,
          getValue: (totals, prop) => {
            return totals.labor_dollars[prop] + totals.burden_dollars[prop]
          },
          show: this.include.labor_plus_burden,
          hasRemaining: true,
          hasVariance: true,
        },
        {
          label: this.$t('Burden %'),
          prop: 'burden_percent',
          formatter: this.$formatPercent,
          show: this.include.burden_percent,
          hasRemaining: false,
          hasVariance: false,
        },
        {
          label: this.$t('$ / Hour'),
          prop: 'dollars_per_hour',
          formatter: this.$formatPrice,
          show: true,
          hasRemaining: false,
          hasVariance: false,
        },
      ]

      return dataPoints.filter(dataPoint => dataPoint.show)
    }
  },
  methods: {
    isNumber,
    addJobGroup(jobGroup) {
      this.rows.push({
        header: {
          job: jobGroup.job,
        },
      })

      if (this.printPhaseTotals) {
        this.addJobPhases(jobGroup)
      } else {
        jobGroup.phases.forEach(phase => {
          this.addLineItems(phase.line_items)
        }) 
      }

      this.addJobTotals(jobGroup)
    },
    addLineItems(line_items) {
      line_items.forEach((lineItem) => {
        this.rows.push(lineItem)
        if (this.format === LaborStatusReportOptions.Format.PeriodDetail) {
          this.addPayrollLines(lineItem)
          this.addCraftCodeLines(lineItem)
        }
      })
    },
    addPayrollLines(lineItem) {
      if (!lineItem.payroll_totals?.length) {
        return
      }

      this.rows.push({
        htmlData: {
          payroll_totals: lineItem.payroll_totals,
        }
      })
    },
    addCraftCodeLines(lineItem) {
      if (!lineItem.craft_code_totals?.items.length) {
        return
      }

      this.rows.push({
        htmlData: {
          craft_code_totals: lineItem.craft_code_totals,
        }
      })

    },
    addJobTotals(jobGroup) {
      const totals = jobGroup.totals
      this.rows.push({
        subtotal: {
          description: `${this.$t('Job Totals')} (${jobGroup.job.number})`,
          totals: {
            ...totals,
            last_updated_at: jobGroup.last_updated_at,
            completion: jobGroup.job.completion
          },
        }
      })
    },
    addJobPhases(jobGroup) {
      jobGroup.phases.forEach((phase) => {
        if (phase.totals && phase.line_items.length > 1) {
          this.rows.push({
            header: {
              phase_code: phase.phase || ' - ',
            },
          })
        }

        this.addLineItems(phase.line_items)

        this.addPhaseTotals(phase)
      })
    },
    addPhaseTotals(phase) {
      if (!phase.totals || phase.line_items.length <= 1) {
        return
      }

      this.rows.push({
        subtotal: {
          description: `${this.$t('Phase Totals')} (${phase.phase || ' - '})`,
          totals: phase.totals,
        }
      })
    },
    addGrandTotals() {
      const grandTotals = this.data.grand_totals

      this.rows.push(
        {
          subtotal: {
            description: this.$t('Grand Total All Selected Jobs'),
            totals: grandTotals,
          }
        },
      )
    },
    addPeriodSummary() {
      this.rows.push({
        htmlData: {
          title: this.$t('Period Amount Detail Summary'),
          payroll_totals: this.data.period_summary,
        }
      })

      this.rows.push({
        htmlData: {
          craft_code_totals: this.data.craft_code_summary,
        }
      })
    },
    composeRows(data) {

      for (const jobGroup of data) {
        this.addJobGroup(jobGroup)
      }

      this.addGrandTotals()
      if (this.format === LaborStatusReportOptions.Format.PeriodDetail) { 
        this.addPeriodSummary()
      }

      return this.rows
    },
    getDataPointValue(dataPoint, totals, prop) {
      const value = dataPoint.getValue
        ? dataPoint.getValue(totals, prop)
        : totals[dataPoint.prop]?.[prop]

      return dataPoint.formatter
        ? dataPoint.formatter(value)
        : value
    },
  }
}
</script>
