<template>
  <el-collapse v-model="activeGroup">
    <base-collapse-section
        :title="$t('Cost Center Defaults')"
        key="cost-center"
        name="cost-center">
      <div class="grid grid-cols-8 gap-x-3">

        <base-select
            v-model="value.cost_center"
            :options="getCostCenterOptions"
            :label="$t('Cost Center')"
            :placeholder="$t('Cost Center')"
            class="col-span-8 md:col-span-2"
            @change="onChangeCostCenter"
        />

        <template v-if="hasSourceAndType">
          <base-grid-select
              v-model="value.source_id"
              :resource-name="getSourceName"
              :label="getSourceLabel"
              :placeholder="getSourcePlaceholder"
              :unauthorized-to-add="true"
              :remote-search="true"
              class="col-span-8 md:col-span-2"
              clearable
              @entity-change="tryMapJob"
          />
          <base-select
              v-if="hasType && showType"
              v-model="value.type_id"
              :options="getTypeOptions"
              :label="$t('Type (optional)')"
              :placeholder="$t('Type')"
              :label-format="formatTypeLabel"
              value-key="id"
              label-key="abbr"
              class="col-span-8 md:col-span-1"
              clearable
          />
        </template>
        <line-item-select
            v-if="hasAdditionalSource"
            v-model="value.addl_source_id"
            :job-id="value.source_id"
            :type-id="value.type_id"
            :label="$t('Line Item (optional)')"
            :placeholder="$t('Line item')"
            :applied-to-cost-center-defaults="true"
            :line-item-type="$attrs.lineItemType"
            class="col-span-8 md:col-span-2"
            clearable
        />
      </div>
    </base-collapse-section>
  </el-collapse>
</template>
<script>
  import LineItemSelect from '@/components/select/entities/LineItemSelect'
  import { costCenterTypes, sourceMap, typeTypesMap } from '@/components/grid-table/utils/cost-center'

  const sourceLabelMap = {
    [costCenterTypes.Job]: 'Job',
    [costCenterTypes.Equipment]: 'Equipment',
    [costCenterTypes.WorkOrder]: 'Work Order',
    [costCenterTypes.Inventory]: 'Material',
    default: '',
  }

  export default {
    components: {
      LineItemSelect,
    },
    props: {
      value: {
        type: Object,
        default: () => ({
          cost_center: '',
          source_id: '',
          source_type: '',
          type_id: '',
          type_type: '',
          addl_source_id: '',
          addl_source_type: '',
        }),
      },
      isCustomerResource: {
        type: Boolean,
        default: false,
      },
      canBeAppliedToInventory: {
        type: Boolean,
        default: false,
      },
      showType: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        costCenterTypes,
        activeGroup: 'cost-center',
      }
    },
    computed: {
      getCostCenterOptions() {
        const options = [
          {
            label: this.$t('Job'),
            value: costCenterTypes.Job,
          },
          {
            label: this.$t('General & Administrative'),
            value: costCenterTypes.GeneralAndAdministrative,
          },
          {
            label: this.$t('Equipment Management'),
            value: costCenterTypes.Equipment,
          },
        ]

        if (!this.isCustomerResource) {
          options.push({
            label: this.$t('Service Billing'),
            value: costCenterTypes.WorkOrder,
          })
        }

        if (this.canBeAppliedToInventory) {
          options.push({
            label: this.$t('Inventory'),
            value: costCenterTypes.Inventory,
          })
        }

        return options
      },
      getSourceMap() {
        if (!this.canBeAppliedToInventory) {
          return sourceMap
        }

        return {
          ...sourceMap,
          [costCenterTypes.Inventory]: this.$globalResources.Materials,
        }
      },
      getSourceName() {
        return this.getSourceMap[this.value.cost_center]
      },
      getSourceLabel() {
        const title = sourceLabelMap[this.value.cost_center]
        return this.$t(title) + ` (${this.$t('optional')})`
      },
      getSourcePlaceholder() {
        const title = sourceLabelMap[this.value.cost_center]
        return this.$t('Select') + ' ' + title.toLowerCase()
      },
      getTypeOptions() {
        let resourceName = typeTypesMap[this.value.cost_center]
        if (!resourceName) {
          return []
        }

        if (this.isCustomerResource) {
          resourceName = this.$globalResources.JobIncomeTypes
        }

        return this.$store.getters['globalLists/getResourceOptions'](resourceName)
      },
      hasSourceAndType() {
        return !this.isGeneralAndAdministrativeCostCenter
      },
      hasAdditionalSource() {
        return this.value.cost_center === costCenterTypes.Job
      },
      isGeneralAndAdministrativeCostCenter() {
        return this.value.cost_center === costCenterTypes.GeneralAndAdministrative
      },
      hasType() {
        return this.value.cost_center === costCenterTypes.Job
      },
    },
    methods: {
      tryMapJob(source) {
        this.$set(this.value, 'type_id', '')
        this.$set(this.value, 'addl_source_id', '')

        if (!source?.id) {
          return
        }

        if (this.hasSourceAndType && !this.hasType) {
          this.setDefaultTypeId()
        }
      },
      setDefaultTypeId() {
        const resourceName = typeTypesMap[this.value.cost_center]
        const type_id = this.$store.getters['globalLists/getLaborTypeIdByModule'](resourceName)

        this.$set(this.value, 'type_id', type_id)
      },
      formatTypeLabel(type) {
        const { abbr, name } = type
        return `${abbr} (${name})`
      },
      onChangeCostCenter() {
        this.$set(this.value, 'type_id', '')
        this.$set(this.value, 'source_id', '')
        this.$set(this.value, 'addl_source_id', '')
      },
    },
  }
</script>
