<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <p class="text-sm font-semibold flex justify-center -mt-2">{{ $t('Interval') }}</p>
      <div
        :title="$t('Number')"
        class="detail-row"
      >
        <CalendarIcon class="detail-icon"/>
        <div class="truncate">
          <span class="text-xs text-gray-500">{{$t('Interval Type')}}</span>
          <div>{{ startCase(maintenanceCodeData.interval) }}</div>
        </div>
      </div>
      <div
        :title="$t('Months')"
        class="detail-row"
      >
        <HashIcon class="detail-icon"/>
        <div class="truncate">
          <span class="text-xs text-gray-500">{{$t('Months')}}</span>
          <div>{{ $formatHours(maintenanceCodeData.interval_months || 0) }}</div>
        </div>
      </div>
      <div
        :title="$t('Value')"
        class="detail-row"
      >
        <HashIcon class="detail-icon"/>
        <div class="truncate">
          <span class="text-xs text-gray-500">{{$t('Value')}}</span>
          <div>{{ $formatHours(maintenanceCodeData.interval_value || 0) }}</div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>
<script setup>
import { computed } from 'vue'
import { CalendarIcon, HashIcon } from 'vue-feather-icons'
import { startCase } from 'lodash'

const props = defineProps({
  maintenanceCode: {
    type: Object,
    required: true,
  },
})

const maintenanceCodeData = computed(() => {
  return props.maintenanceCode?.attributes || {}
})
</script>
