<template>
  <div>
    <InvoiceDetailsPage
        :loading="reconciliationLoading"
        :data="reconciliation"
        history-entity="Reconciliation"
        entity="reconciliations"
    >
      <template #header>
        <div class="flex justify-between items-center border-b border-gray-200 print:border-none px-4 py-2 print:py-1">
          <div class="flex items-center">
            <h5 class="font-semibold mr-3 print:text-print">
              {{ $t('Reconciliation') }} @ {{ reconciliationData.account }}
              <template v-if="accountDescription">
                ({{ accountDescription }})
              </template>
            </h5>
            <status-badge :status="reconciliationData.status"/>
          </div>
          <div
              v-if="reconciliationLoading"
              class="flex space-x-2 h-8 print:h-4"
          >
            <div class="w-16 placeholder-loading"/>
            <div class="w-16 placeholder-loading"/>
          </div>
          <div
              v-else
              class="flex items-center"
          >
            <span class="text-base print:text-print leading-6 font-semibold text-gray-900 mr-2">
              {{ $t('Bank Statement: ') }}
            </span>
            <div class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 print:px-1 print:text-print tracking-wide">
              {{ $formatPrice(reconciliationData.bank_balance_amount) }}
            </div>
          </div>
        </div>
      </template>
      <template #header-left>
        <div
            v-if="reconciliationLoading"
            class="flex-1 grid grid-cols-1 gap-2"
        >
          <div class="w-1/3 placeholder-loading"></div>
          <div class="w-1/3 placeholder-loading"></div>
        </div>

        <div v-else class="flex-1 grid grid-cols-5 gap-x-2 text-sm print:text-print">
          <div class="flex flex-col space-y-1 justify-start">
            <span class="tracking-wide font-medium text-gray-400">
              {{ $t('Bank Statement Date ') }}
            </span>
            <span class="tracking-wide font-medium text-gray-400">
              {{ $t('Trough end of fiscal period ') }}
            </span>
          </div>
          <div class="col-span-4 flex flex-col space-y-1 justify-start border-l border-gray-200 pl-2 capitalize">
            <span class="tracking-wide text-gray-900">
              {{ $formatDate(reconciliationData.date) }}
            </span>
            <span class="tracking-wide text-gray-900">
              {{ reconciliationData.period }} /
              {{ reconciliationData.fiscal_year }}
            </span>
            <UserLink
                v-if="get(reconciliation, 'attributes.created_by')"
                :id="get(reconciliation, 'attributes.created_by')"
                class="tracking-wide text-gray-900"
            />
          </div>
        </div>
      </template>
      <template #header-right>
        <ReconciliationSummary
            class="px-0"
            :entries="entries"
            :loading="reconciliationLoading || entriesLoading"
            :account-balance="accountBalance"
            :reconciliation="reconciliationData"
        />
      </template>
      <template #content>
        <ReconciliationEntries
            :reconciliation-id="$route.params.id"
            :data="reconciliationAttributes"
            :url-params="{
              account_number: reconciliation?.attributes?.account,
            }"
            ref="transactionTable"
            class="p-4"
            readOnly
            @fetch-data="entries = $event"
            @entries-loading="entriesLoading = $event"
        />
      </template>
      <template #totals>

      </template>
    </InvoiceDetailsPage>

    <base-back-link to="/ledger/account-reconciliation"/>
  </div>
</template>
<script>
  import InvoiceDetailsPage from '@/components/invoice/InvoiceDetailsPage'
  import ReconciliationSummary from '@/modules/ledger/components/ReconciliationSummary'
  import ReconciliationEntries from '@/modules/ledger/components/ReconciliationEntries'
  import CompanyContactInfo from '@/modules/accounts-payable/components/invoice/CompanyContactInfo'
  import { computedAccountBalance } from '@/utils/account'

  export default {
    components: {
      CompanyContactInfo,
      InvoiceDetailsPage,
      ReconciliationSummary,
      ReconciliationEntries,
    },
    data() {
      return {
        entries: [],
        entriesLoading: false,
      }
    },
    computed: {
      reconciliationAttributes() {
        return {
          id: this.reconciliation.id,
          ...(this.reconciliation.attributes || {}),
        }
      },
      reconciliation() {
        return this.$store.state.generalLedger.currentReconciliation || {}
      },
      reconciliationData() {
        return this.reconciliation.attributes || {}
      },
      reconciliationLoading() {
        return this.$store.state.generalLedger.reconciliationLoading
      },
      reconciliationAccount() {
        return this.$store.state.generalLedger.currentReconciliationAccount || {}
      },
      accountDescription() {
        return this.reconciliationAccount?.description
      },
      accountBalance() {
        return computedAccountBalance(this.reconciliationAccount, this.reconciliationData?.period) || 0
      }
    },
  }
</script>
