<template>
  <div class="flex flex-col">
    <VendorHistoryTable :url-params="urlParams"/>
  </div>
</template>
<script>
import { resourceStatuses } from '@/enum/enums'
import VendorHistoryTable from "@/modules/accounts-payable/components/vendor-history/VendorHistoryTable.vue";

export default {
  components: {
    VendorHistoryTable,
  },
  data() {
    return {
      status: resourceStatuses.All,
    }
  },
  computed: {
    urlParams() {
      return {
        addl_source_id: this.$route.params.id
      }
    },
  }
}
</script>
