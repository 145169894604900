<template>
  <div class="h-full">
    <FilterTabs
      v-model="currentStatus"
      :items="filterTabs"
    />
    <AgDataTable
      :url="url"
      :columns="columns"
      :url-params="urlParams"
      :add-text="$t('New Repair Order')"
      :add-entity-url-query="addEntityUrlQuery"
      :add-entity-in-new-tab="true"
      :per-page="$pagination.list"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :hide-actions="isReview ? 'view,add,edit,delete': ''"
      ref="table"
      default-sort="number"
      permission="repair_orders"
      entity="repair_order"
      actions="search,refresh"
      baseEntityPath="/equipment/repair-orders"
      :deleteCustom="true"
      @delete="itemToDelete = $event; showDeleteDialog = true"
      @data-fetch="onDataFetch"
    >
     <template #extra-actions="{row, index}">
        <BaseButton
          v-if="isReview"
          variant="gray-light"
          :title="$t('Review')"
          size="icon"
          class="table-action-button"
          @click="setCurrentEntity(row)"
        >
          <CheckCircleIcon class="w-4 h-4"/>
        </BaseButton>
      </template>
      <template #attributes.number="{ row }">
        <RepairOrderLink
          :data="row?.attributes"
          :show-description="false"
        />
      </template>
      <template #attributes.description="{ row }">
        <span
          class="truncate"
          :title="row.attributes.description"
        >
          {{ row.attributes.description }}
        </span>
      </template>
    </AgDataTable>
    <RepairOrderApproveDialog
      v-if="showEntityDetails"
      :open.sync="showEntityDetails"
      :repairOrder="selectedEntity"
      :show-next-button="showNextButton"
      :show-prev-button="showPrevButton"
      @prev="onPrev"
      @next="onNext"
      @close="showEntityDetails = false"
    />
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.RepairOrders"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
import i18n from '@/i18n'
import { computed, ref } from "vue";
import { useRoute } from 'vue2-helpers/vue-router';
import { resourceStatuses } from '@/enum/enums';
import { useEntityReview } from "@/modules/common/composables/useEntityReview";
import { CheckCircleIcon } from "vue-feather-icons";
import RepairOrderApproveDialog from "@/modules/equipment/components/repair-orders/RepairOrderApproveDialog.vue";

const route = useRoute()

const {
  onNext,
  onPrev,
  setCurrentEntity,
  onDataFetch,
  showPrevButton,
  showNextButton,
  selectedEntity,
  showEntityDetails
  } = useEntityReview()

const equipment_id = computed(() => {
  return route.params.id
})

const url = '/restify/repair-orders'

const table = ref(null)
const showDeleteDialog = ref(false)
const itemToDelete = ref(null)

function onItemDeleted() {
  showDeleteDialog.value = false
  // @ts-ignore
  table.value?.refresh()
}

const currentStatus = ref(resourceStatuses.All)
const filterTabs = [
  {
    label: i18n.t('To Do'),
    value: resourceStatuses.ToDo,
    permission: 'repair_orders_show',
  },
  {
    label: i18n.t('Awaiting Materials'),
    value: resourceStatuses.AwaitingMaterials,
    permission: 'repair_orders_show',
  },
  {
    label: i18n.t('In Progress'),
    value: resourceStatuses.InProgress,
    permission: 'repair_orders_show',
  },
  {
    label: i18n.t('Completed'),
    value: resourceStatuses.Completed,
    permission: 'repair_orders_show',
  },
  {
    label: i18n.t('Cancelled'),
    value: resourceStatuses.Cancelled,
    permission: 'repair_orders_show',
  },
  {
    label: i18n.t('All'),
    value: resourceStatuses.All,
    permission: 'repair_orders_show',
  },
  {
    label: i18n.t('Review'),
    value: resourceStatuses.Review,
    permission: 'repair_orders_update',
  }
]

const isReview = computed(() => {
  return currentStatus.value === resourceStatuses.Review
})

const urlParams = computed(() => {
  const status = [
    resourceStatuses.All,
    resourceStatuses.Review,
  ].includes(currentStatus.value) ? undefined : currentStatus.value

  return {
    status,
    equipment_id: equipment_id.value,
    related: 'equipment,employee',
  }
})

const addEntityUrlQuery = computed(() => {
  return `/equipment/repair-orders/add?equipment_id=${equipment_id.value}`
})

const columns = [
  {
    label: i18n.t('Number'),
    prop: 'attributes.number',
    minWidth: 150,
    maxWidth: 180,
  },
  {
    label: i18n.t('Equipment'),
    prop: 'attributes.equipment_id',
    component: 'EquipmentLink',
    minWidth: 300,
    maxWidth: 320,
  },
  {
    label: i18n.t('Description'),
    prop: 'attributes.description',
    minWidth: 250,
    maxWidth: 300,
  },
  {
    label: i18n.t('Hours'),
    prop: 'attributes.usage_hours',
    minWidth: 110,
    maxWidth: 120,
    component: 'FormattedHours',
  },
  {
    label: i18n.t('Mileage'),
    prop: 'attributes.mileage',
    minWidth: 110,
    maxWidth: 120,
    component: 'FormattedHours',
  },
  {
    label: i18n.t('Assigned To'),
    prop: 'attributes.employee_id',
    component: 'EmployeeLink',
    minWidth: 130,
    maxWidth: 150,
  },
  {
    label: i18n.t('Status'),
    prop: 'attributes.status',
    component: 'Status',
    align: 'center',
    minWidth: 160,
    maxWidth: 160,
  },
  {
    label: i18n.t('Review Status'),
    prop: 'attributes.review_status',
    component: 'ReviewStatus',
    align: 'center',
    minWidth: 120,
    maxWidth: 160,
  },
  {
    label: i18n.t('Created By'),
    prop: 'attributes.created_by',
    component: 'UserLink',
    hide: true,
  },
  {
    label: i18n.t('Created At'),
    prop: 'attributes.created_at',
    component: 'FormattedDate',
    hide: true,
  },
]
</script>
