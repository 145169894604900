<template>
  <div class="px-4">
    <AgDataTable
        :url="url"
        :url-params="urlParams"
        :columns="columns"
        :per-page="perPage"
        :show-pagination="false"
        :compact="true"
        :no-borders="true"
        :groupIncludeFooter="true"
        :groupIncludeTotalFooter="true"
        suppressColumnReordering
        dom-layout="autoHeight"
        @cell-focused="onCellFocused"
    />
    <LumpSumBillingOverrideSummary
      :fixed="true"
      :data="lastSelectedRow"
      @close="lastSelectedRow = null"
    />
  </div>
</template>
<script>

  import LumpSumBillingOverrideSummary
    from "@/modules/accounts-receivable/components/lump-sum-billings/LumpSumBillingOverrideSummary.vue";
  import bus from "@/event-bus/EventBus";

  export default {
    components: { LumpSumBillingOverrideSummary },
    props: {
      billing: {
        type: Object,
        default: () => ({}),
      },
      resourceEndpoint: {
        type: String,
        default: '/restify/billing-entries',
      },
      parentEntityField: {
        type: String,
        default: 'billing_id',
      },
    },
    data() {
      return {
        perPage: 200,
        lastSelectedRow: null,
      }
    },
    computed: {
      url() {
        if (!this.billing.id) {
          return ''
        }
        return this.resourceEndpoint
      },
      urlParams() {
        return !this.billing.id ? {} : {
          sort: 'order',
          [this.parentEntityField]: this.billing.id,
          related: 'source[id|number|description],addlSource[id|phase_code|cost_code|type]',
        }
      },
      columns() {
        return [
          {
            headerName: this.$t('Cost Center'),
            field: 'attributes.cost_center',
            minWidth: 70,
            maxWidth: 80,
            sortable: false,
          },
          {
            headerName: this.$t('Source'),
            field: 'attributes.source_id',
            minWidth: 70,
            maxWidth: 320,
            component: 'SourceLink',
            cellRendererParams: {
              showDescription: true,
            },
            sortable: false,
          },
          {
            headerName: this.$t('Type'),
            field: 'attributes.type_id',
            component: 'TypeLink',
            minWidth: 120,
            maxWidth: 160,
            sortable: false,
          },
          {
            headerName: this.$t('Line Item'),
            field: 'attributes.addl_source_id',
            minWidth: 150,
            maxWidth: 200,
            component: 'AddlSourceLink',
            cellRendererParams: {
              showDescription: true,
            },
            sortable: false,
          },
          {
            label: this.$t('Description'),
            field: 'attributes.description',
            minWidth: 180,
            maxWidth: 320,
          },
          {
            label: this.$t('Unit'),
            field: 'attributes.um',
            minWidth: 50,
            maxWidth: 70,
          },
          {
            label: this.$t('Qty'),
            field: 'attributes.quantity',
            align: 'right',
            minWidth: 50,
            maxWidth: 60,
          },
          {
            label: this.$t('Unit Price'),
            field: 'attributes.unit_rate',
            align: 'right',
            minWidth: 60,
            maxWidth: 90,
            component: 'FormattedPrice',
          },
          {
            label: this.$t('Amount'),
            field: 'attributes.gross_amount',
            align: 'right',
            minWidth: 50,
            maxWidth: 120,
            sortable: false,
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            label: this.$t('Retention'),
            field: 'attributes.retention_amount',
            align: 'right',
            minWidth: 50,
            maxWidth: 120,
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            label: this.$t('Tax'),
            field: 'attributes.sales_tax_amount',
            align: 'right',
            minWidth: 50,
            maxWidth: 90,
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            label: this.$t('Net'),
            field: 'attributes.net_amount',
            align: 'right',
            minWidth: 80,
            maxWidth: 120,
            valueFormatter: params => {
              return this.$formatPrice(params.value)
            },
            aggFunc: 'sum',
          },
        ]
      },
    },
    methods: {
      onCellFocused(params) {
        const rowData = params.api.getDisplayedRowAtIndex(params.rowIndex)
        const row = rowData?.data
        if (row) {
          this.closeOtherSummaries()
          this.lastSelectedRow = row?.attributes
        }
      },
      closeOtherSummaries() {
        bus.$emit('close-lump-sum-entry-summary')
      },
      handleBusEvents() {
        bus.$on('close-lump-sum-entry-summary', () => {
          this.lastSelectedRow = null
        })
      },
    },
    created() {
      this.handleBusEvents()
    }
  }
</script>
