<template>
  <entity-preview
    v-bind="$attrs"
    :entity="entryData"
    :key="`preview-${entryData.id}`"
    :url="`/equipment/repair-orders/${repairOrderId}/view`"
    :value-to-display="valueToDisplay"
    :parent-entity-id="repairOrderId"
    :show-preview="showPreview"
    :target="target"
    :is-link="isLink"
    entity-name="repairOrder"
  />
</template>
<script>
import axios from 'axios'
export default {
  name: 'RepairOrderEntryLink',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
    target: String,
    isLink: Boolean,
  },
  data() {
    return {
      loading: false,
      repairOrderEntry: null,
    }
  },
  computed: {
    entryData() {
      if (this.repairOrderEntry) {
        return {
          ...this.repairOrderEntry.attributes,
          ...(this.repairOrderEntry.relationships || {}),
        }
      }

      if (this.data?.attributes) {
        return {
          ...this.data.attributes,
          ...(this.data.relationships || {}),
        }
      }

      return this.data || {}
    },
    repairOrderId() {
      if (this.data?.meta?.repair_order_id) {
        return this.data.meta.repair_order_id
      }

      return this.get(this.entryData, 'repair_order_id')
    },
    equipmentCostTypes() {
      return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.EquipmentTypes) || []
    },
    roNumber() {
      return this.get(this.entryData, 'repairOrder.attributes.number')
    },
    valueToDisplay() {
      if (this.data?.meta?.roe_description) {
        return this.data.meta.roe_description
      }

      const costType = this.equipmentCostTypes.find(t => t.id === this.entryData.type_id)        
      const cost_type = costType?.name || ''

      const { description } = this.entryData
      return `RO#${this.roNumber || '...'} ${cost_type}  - ${description || ''}`
    },
  },
  methods: {
    async loadData() {
      if (!this.entryData?.id || this.roNumber || this.data?.meta?.roe_description) {
        return
      }

      try {
        this.loading = true
        const { data } = await axios.get(`/restify/repair-order-entries/${this.data.id}`, {
          params: {
            related: 'repairOrder',
            ignore404: true,
          }
        })

        this.repairOrderEntry = data
      }
      finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>
