<template>
  <EntitySelect
    v-bind="$attrs"
    v-on="$listeners"
    :url="url"
    :url-params="allUrlParams"
    :tableColumns="tableColumns"
    :transformData="transformData"
    :dataFilters="dataFilters"
    :name="$attrs.name || $t('Employee')"
    :placeholder="$attrs.placeholder || $t('Select Employee')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Employee')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :add-label="addLabel"
    :on-map-entry="onChangeEntry"
    :label-format="formatLabel"
    :id="$attrs.id || 'employee-select'"
    :label-key="labelKey"
    :initial-value="initialValue"
  />
</template>
<script>
import i18n from '@/i18n'

export default {
  inheritAttrs: false,
  name: 'EmployeeSelect',
  props: {
    url: {
      type: String,
      default: '/restify/employees',
    },
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'code',
      }),
    },
    addEntity: {
      type: Boolean,
      default: false,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    displayPayColumn: {
      type: Boolean,
      default: false,
    },
    displayEmailColumn: {
      type: Boolean,
      default: false,
    },
    labelKey: {
      type: String,
      default: 'code',
    },
    showInactive: {
      type: Boolean,
      default: false,
    },
    addLabel: {
      type: String,
      default: i18n.t('Add new Employee'),
    },
    initialValue: {
      type: [Object, Array],
    },
    filterMethod: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      dataFilters: {
        showActive: true,
      }
    }
  },
  computed: {
    allUrlParams() {
      return {
        ...this.urlParams,
        status: this.dataFilters.showActive ? 'hourly,salary' : null,
      }
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 80,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'attributes.code',
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Name'),
          prop: 'attributes.name',
        },
        {
          minWidth: 80,
          maxWidth: 100,
          name: this.$t('Pay'),
          prop: 'attributes.salary_amount',
          component: 'FormattedPrice',
          showIf: () => this.displayPayColumn,
        },
        {
          minWidth: 250,
          maxWidth: 300,
          name: this.$t('Email'),
          prop: 'attributes.email',
          showIf: () => this.displayEmailColumn,
        },
        {
          minWidth: 120,
          maxWidth: 120,
          name: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active employees'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
        },
      ]

      return columns.filter(c => !c.showIf || c.showIf())
    },
  },
  methods: {
    transformData(data) {
      if (this.filterMethod) {
        data = data.filter(this.filterMethod)
      }

      return data.map(employee => ({
        ...employee,
        isInactive: !['hourly', 'salary'].includes(employee.attributes.status),
      }))
    },
    formatLabel(employee) {
      const { code, name } = employee.attributes
      return !name ? code : `${code} (${name})`
    },
    onChangeEntry(employee) {
      this.$emit('on-map-entry', employee)
    },
  },
}
</script>
