import { JobStatus } from "@/modules/job-costing/enum/jobs";

export const jobProfitReportFormats = {
  Short: 'short',
  Long: 'long',
  SingleLine: 'single-line',
  Periods: 'periods',
  ActivePeriods: 'active-periods',
}

export const computeTotalBy = {
  PhaseAndCostCode: 'phase-and-cost-code',
  PhaseCode: 'phase-code',
  Job: 'job',
}

export const commonFilters = {
  job_statuses: [JobStatus.Open],
  job_number_from: null,
  job_number_to: null,
  job_ids: [],
  job_date: null,

  phase_code_from: null,
  phase_code_to: null,
  cost_code_from: null,
  cost_code_to: null,
  change_order_from: null,
  change_order_to: null,
}

// * Report 2881
export const jobCostDetailPrintOptions = {
  HistoryAndBudgets: 'job_history_and_budgets',
  LineItemTotals: 'line_item_totals_only',
  JobDetail: 'job_detail',

  JobSummary: 'job_summary',
  History: 'job_history_only',
  LineItemPhaseTotals: 'line_items_and_phase_totals',
}

export const JobCostVarianceReportOptions = {
  Format: {
    LineItemSummary: 'summary',
    ListCostTypes: 'detail',
  }
}

export const LaborStatusReportOptions = {
  Format: {
    PeriodDetail: 'period_detail',
    PeriodTotals: 'period_totals',
    NoPeriodAmount: 'no_period_amount',
    LineItemSummary: 'line_item_summary',
    JobSummary: 'job_summary',
    LineItemTotals: 'line_item_totals',
  },
}

export const PeriodCostIncomeSummaryReportOptions = {
  ComputeProfit: {
    PercentOfCost: 'cost',
    PercentOfIncome: 'income',
  }
}
