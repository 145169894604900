<template>
  <EntitySelect
    url="/restify/crews"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="urlParams"
    :name="$attrs.name || $t('Crew')"
    :placeholder="$attrs.placeholder || $t('Select Crew')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Crew')"
    :add-label="$t('Add new Crew')"
    :id="$attrs.id || 'crew-select'"
    :table-columns="tableColumns"
    label-key="name"
  />
</template>
<script>
export default {
  name: 'CrewSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'name',
      }),
    },
  },
  data() {
    return {
      tableColumns: [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Name'),
          prop: 'attributes.name',
        },
        {
          minWidth: 180,
          maxWidth: 180,
          name: this.$t('Description'),
          prop: 'attributes.description',
        },
      ],
    }
  },
}
</script>
