<template>
  <div>

    <AgDataTable
      ref="table"
      url="/restify/timesheet-entries"
      :view-entity-url-query="viewEntityUrlQuery"
      :url-params="urlParams"
      :columns="columns"
      :change-page-with-timeout="true"
      :per-page="100"
      :transform-data="transformData"
      :get-empty-row="getEmptyRow"
      :map-duplicate-row="mapDuplicateRow"
      :authorize-to-copy-last-row="true"
      :get-row-id="getRowId"
      :show-cells-legend="true"
      :add-text="$t('Add Entry')"
      v-bind="editableTableProps"
      :showPagination="true"
      domLayout="autoHeight"
      actions="refresh,search,add"
      @add="onAddRow"
      @cell-focused="onCellFocused"
      @cell-value-changed="onCellValueChanged"
      @grid-ready="onGridReady"
    >
      <template #additional-actions-before>
        <base-tooltip
          v-if="false"
          :content="$t('Re-compute Overtime/Premium hours')"
          :tabindex="-1">
          <base-button
            :loading="syncLoading"
            variant="gray-icon"
            size="xs"
            @click="reComputeHours">
            <RepeatIcon class="w-5 h-5 text-gray-500"/>
          </base-button>
        </base-tooltip>
        <slot name="additional-actions-before"/>
      </template>
      <template #header-info>
        <TimesheetsDateFilter
          v-if="!employeeId"
          v-model="startOfWeek"
        />
        <TimesheetsSyncButton
          :start-date="startDate"
          :end-date="endDate"
          class="ml-2"
          @save="refresh"
        />
      </template>
      <template #employee_id="{row}">
        <div class="flex justify-between items-center w-full">
        <span>
          {{ getEmployee(row.employee_id)?.code }}
        </span>
        </div>
      </template>
      <template #date="{row}">
        <div class="flex items-center space-x-2">
          <TimecardEntryDate :row="row" :days="days"/>
          <TimesheetEntryStatus :params="{data: row}"/>
        </div>
      </template>
      <template #edit_action="{row}">
        <div
          v-if="canEditEntry({data: row})"
          class="flex justify-center w-full">
          <TableEditButton :skipFocus="true"/>
        </div>
        <div
          v-else
          class="flex justify-center w-full">
          <TableViewButton
            :skipFocus="true"
            :loading="row.viewLoading"
            @click="viewTimesheetDetails(row)"
          />
        </div>
      </template>
      <template #delete_action="{row}">
        <div
          v-if="canEditEntry({data: row})"
          class="flex justify-center w-full">
          <TableDeleteButton :skipFocus="true"/>
        </div>
      </template>
    </AgDataTable>
    <TimesheetDialog
      v-if="showTimesheetDialog"
      :open.sync="showTimesheetDialog"
      :timesheet="selectedTimesheet"
    />
  </div>
</template>
<script>
import TimesheetsDateFilter from "@/modules/payroll/components/timesheets/TimesheetsDateFilter.vue";
import { getEndOfPayWeek, getStartOfPayWeek } from "@/modules/payroll/components/timesheets/utils";
import format from "date-fns/format";
import { editableTableProps, saveInlineEntry, updateInlineEntry } from "@/components/ag-grid/tableUtils";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { getCostCenterRowDefaults, getTimeCardBatchDays } from "@/modules/payroll/utils/timeCardUtils";
import {
  additionalSourceCol,
  costCenterCol,
  descriptionCol,
  sourceCol,
  updateCostCenterHeaderNames
} from "@/components/ag-grid/columns/costCenterColumns";
import { cellClasses, requiredValueSetter } from "@/components/ag-grid/columnUtils";
import { globalResources } from "@/components/form/util";
import TimecardEntryDate from "@/modules/payroll/components/timecard/TimecardEntryDate.vue";
import { costCenterFields } from "@/modules/common/util/costCenterUtils";
import { setTypeSources } from "@/components/grid-table/utils/cost-center";
import cloneDeep from "lodash/cloneDeep";
import { dateTypes } from "@/plugins/dateFormatPlugin";
import { getEmptyEntry, getStartTime, hoursFormatter, isRowValid } from "@/modules/payroll/utils/timesheetUtils";
import TimesheetEntryDeleteDialog from "@/modules/payroll/components/timesheets/TimesheetEntryDeleteDialog.vue";
import { stopEditingOnTab } from "@/components/ag-grid/columns/editableColumns";
import TimesheetEntryGridDialog from "@/modules/payroll/components/timesheets/TimesheetEntryGridDialog.vue";
import axios from "axios";
import LoadingCircle from "@/modules/wiki/components/LoadingCircle.vue";
import { $modules, employeeStatuses } from "@/enum/enums";
import TimesheetEntryStatus from "@/components/table/cells/TimesheetEntryStatus.vue";
import { RepeatIcon } from "vue-feather-icons";
import TimesheetDialog from "@/modules/payroll/components/timesheets/TimesheetDialog.vue";
import TimesheetsSyncButton from "@/modules/payroll/components/timesheets/TimesheetsSyncButton.vue";
import { getSetting } from "@/plugins/settingsAndEnumsPlugin";

const fieldsToSkip = [
  'cost_center',
  'source_id',
  'addl_source_id',
  'edit_action',
  'delete_action',
]

export default {
  components: {
    TimesheetsSyncButton,
    TimesheetDialog,
    RepeatIcon,
    TimesheetEntryStatus,
    LoadingCircle,
    TimecardEntryDate,
    TimesheetsDateFilter,
    TimesheetEntryGridDialog,
    TimesheetEntryDeleteDialog,
  },
  props: {
    employeeId: {
      type: String,
    }
  },
  data() {
    return {
      editableTableProps,
      syncLoading: false,
      showTimesheetDialog: false,
      selectedTimesheet: null,
      grid: null,
      entries: [],
    }
  },
  computed: {
    startOfWeek: {
      get() {
        return this.$store.state.timesheets.selectedStartOfWeek
      },
      set(value) {
        this.$store.commit('timesheets/SET_START_OF_WEEK', value)
      }
    },
    viewEntityUrlQuery() {
      return this.$route.path
    },
    startDate() {
      const start = getStartOfPayWeek(this.startOfWeek)
      return format(start, 'yyyy-MM-dd')
    },
    endDate() {
      const end = getEndOfPayWeek(this.startOfWeek)
      return format(end, 'yyyy-MM-dd')
    },
    days() {
      return getTimeCardBatchDays(this.endDate)
    },
    urlParams() {
      if (this.employeeId) {
        return {
          sort: 'date',
          employee_id: this.employeeId,
        }
      }
      return {
        sort: 'employee.code,date',
        date: `${this.startDate},${this.endDate}`
      }
    },
    timesheetSettings() {
      return this.$store.state.company.settings.timesheet
    },
    enableLunch() {
      return this.timesheetSettings?.enable_lunch
    },
    enableBreaks() {
      return this.timesheetSettings?.enable_breaks
    },
    breakDuration() {
      return this.timesheetSettings?.break_duration || 15
    },
    lunchDuration() {
      return this.timesheetSettings?.lunch_duration || 30
    },
    showCraftCodeDetails() {
      return this.timesheetSettings?.show_craft_code_details
    },
    columns() {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'employee_id',
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          cellEditor: cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: globalResources.Employees,
            filterMethod: (row) => {
              return ![employeeStatuses.DECEASED, employeeStatuses.INACTIVE].includes(row.status)
            }
          },
          minWidth: 100,
          maxWidth: 140,
        },
        {
          headerName: this.$t('Date'),
          field: 'date',
          minWidth: 200,
          maxWidth: 300,
          cellEditor: cellEditors.BaseSelect,
          cellEditorParams: params => {
            return {
              options: this.mapDayOptions(params),
            }
          },
          required: true,
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          valueSetter: params => {
            const isValid = requiredValueSetter(params)
            if (!isValid) {
              return false
            }
            const value = params.newValue
            const employeeId = params.data?.employee_id
            if (this.hasTransferredTimesheetEntriesForDate(value, employeeId)) {
              this.$error('The selected date has already been transferred to a timecard.')
              return false
            }
            params.data.date = params.newValue
            return true
          },
        },
        {
          ...costCenterCol,
          valueSetter: params => {
            const employee = this.getEmployee(params.data?.employee_id)
            params.data.cost_center = params.newValue
            params.data = getCostCenterRowDefaults(params.data, employee)
            params.node.setData(params.data)
            return true
          },
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
        },
        {
          ...sourceCol,
          timesheets: true,
          editable: params => {
            return sourceCol.editable(params) && this.canEditEntry(params)
          },
          cellClass: params => {
            if (!this.canEditEntry(params)) {
              return cellClasses.ReadOnly
            }
            return sourceCol.cellClass(params)
          },
        },
        {
          ...additionalSourceCol(),
          editable: params => {
            return additionalSourceCol().editable(params) && this.canEditEntry(params)
          },
          cellClass: params => {
            if (!this.canEditEntry(params)) {
              return cellClasses.ReadOnly
            }
            return additionalSourceCol().cellClass(params)
          },
        },
        {
          field: 'craft_code_id',
          headerName: 'Craft Code',
          minWidth: 100,
          maxWidth: 250,
          cellEditor: cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: globalResources.CraftCodes,
            filterMethod: (row) => {
              return row.active === true && row.available_in_timesheets === true
            }
          },
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          component: 'CraftCodeLink',
          hide: !this.showCraftCodeDetails,
        },
        {
          field: 'sub_trade_id',
          headerName: 'Sub Trade',
          minWidth: 100,
          maxWidth: 250,
          cellEditor: cellEditors.GlobalResourceSelect,
          cellEditorParams: params => {
            return {
              resourceName: globalResources.SubTrades,
              filterMethod: (row) => {
                return row.craft_code_id === params.data?.craft_code_id
              }
            }
          },
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          component: 'SubTradeLink',
          hide: !this.showCraftCodeDetails,
        },
        {
          field: 'start_time',
          headerName: 'Start Time',
          minWidth: 50,
          maxWidth: 100,
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          cellEditor: cellEditors.TimesheetStartTime,
        },
        {
          field: 'duration',
          headerName: 'Duration',
          minWidth: 50,
          maxWidth: 100,
          cellEditor: cellEditors.Numeric,
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          valueGetter: params => {
            const duration = +params.data?.duration
            if (!duration) {
              return 0
            }
            return this.round(duration / 60)
          },
          valueSetter: params => {
            const isValid = requiredValueSetter(params, 0)
            if (!isValid) {
              return false
            }
            params.data.duration = +params.newValue * 60
            return true
          },
          valueFormatter: params => {
            params.value = +params.value * 60
            return hoursFormatter(params)
          },
          aggFunc: 'sum',
        },
        {
          field: 'is_lunch',
          headerName: 'Lunch',
          minWidth: 50,
          maxWidth: 70,
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          valueSetter: params => {
            const isValid = requiredValueSetter(params)
            if (!isValid) {
              return
            }
            const value = !!params.newValue
            if (value) {
              params.data.is_break = false
              params.data.duration = this.lunchDuration
            }
            return true
          },
          hide: !this.enableLunch,
        },
        {
          field: 'is_break',
          headerName: 'Break',
          minWidth: 50,
          maxWidth: 70,
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          valueSetter: params => {
            const isValid = requiredValueSetter(params)
            if (!isValid) {
              return
            }
            const value = !!params.newValue
            if (value) {
              params.data.is_lunch = false
              params.data.duration = this.breakDuration
            }
            return true
          },
          hide: !this.enableBreaks,
        },
        {
          ...descriptionCol,
          field: 'notes',
          headerName: this.$t('Notes'),
          editable: this.canEditEntry,
          cellClass: this.editCellClass,
          suppressKeyboardEvent: stopEditingOnTab,
        },
        {
          headerName: ' ',
          field: 'edit_action',
          pinned: 'right',
          minWidth: 40,
          maxWidth: 60,
          editable: this.canEditEntry,
          cellEditor: 'TimesheetEntryGridDialog',
          hide: this.readOnly,
          cellEditorParams: params => {
            return {
              employee_id: params.data?.employee_id,
              saveOrUpdateEntry: this.saveOrUpdateEntry,
            }
          },
          suppressPaste: true,
          cellEditorPopup: true,
          onCellClicked: params => {
            if (!this.canEditEntry(params)) {
              return
            }
            params.api.startEditingCell({
              rowIndex: params.rowIndex,
              colKey: params.column.colId,
            })
          }
        },
        {
          headerName: ' ',
          field: 'delete_action',
          pinned: 'right',
          minWidth: 40,
          maxWidth: 60,
          cellEditor: 'TimesheetEntryDeleteDialog',
          cellEditorPopup: true,
          suppressPaste: true,
          editable: this.canEditEntry,
          onCellClicked: params => {
            if (!this.canEditEntry(params)) {
              return
            }
            params.api.startEditingCell({
              rowIndex: params.rowIndex,
              colKey: params.column.colId,
            })
          }
        }
      ]
    },
  },
  methods: {
    async reComputeHours()  {
      const confirmed = await this.$confirm({
        title: this.$t('Re-compute Overtime/Premium hours'),
        description: this.$t('Are you sure you want to re-compute Overtime/Premium hours? This action will update all the timesheet entries in the current week. If you have multiple pages, run this action on each page.'),
        buttonText: this.$t('Compute'),
        type: 'warning',
      })
      if (!confirmed) {
        return
      }
      try {
        this.syncLoading = true
        let validEntries = this.entries.filter(entry => {
          return !entry.timecard_id && !entry.approved_at
        })
        validEntries = validEntries.map(entry => {
          return {
            ...entry,
            end_time: undefined
          }
        })
        await axios.post(`/restify/timesheet-entries/bulk/update`, validEntries)
      } finally {
        this.syncLoading = false
      }
    },
    async viewTimesheetDetails(row) {
      try {
        this.$set(row, 'viewLoading', true)
        const { data } = await axios.get(`/restify/timesheets/${row.timesheet_id}`)
        this.selectedTimesheet = data?.attributes
        this.showTimesheetDialog = true
      } finally {
        this.$set(row, 'viewLoading', false)
      }
    },
    mapDayOptions(params) {
      return this.days.map(day => {
        const employeeId = params.data?.employee_id
        return {
          ...day,
          disabled: this.hasTransferredTimesheetEntriesForDate(day.value, employeeId),
        }
      })
    },
    hasTransferredTimesheetEntriesForDate(date, employeeId) {
      return this.entries.some(entry => {
        return !!(entry.date === date && entry.employee_id === employeeId && entry.timecard_id);
      })
    },
    getEmployee(id) {
      return this.$store.getters['globalLists/getResourceById'](globalResources.Employees, id)
    },
    editCellClass(params) {
      if (!this.canEditEntry(params)) {
        return cellClasses.ReadOnly
      }
      return ''
    },
    canEditEntry(params) {
      const { timecard_id, approved_at } = params.data
      return !timecard_id && !approved_at
    },
    onCellFocused(params) {
      updateCostCenterHeaderNames(params)
    },
    onGridReady(params) {
      this.grid = params
    },
    getRowId(params) {
      return params.data._localId || params.data.id
    },
    async onAddRow(params, createdRow) {
      if (createdRow) {
        await this.saveOrUpdateEntry(params)
      }
    },
    getTableData() {
      const data = []
      this.grid.api.forEachNode(node => {
        data.push(node.data)
      })
      return data
    },
    getEmptyRow() {
      const entries = this.getTableData()
      let previousRow = entries[entries.length - 1]
      const employee = this.getEmployee(previousRow?.employee_id)
      const date = this.days[0]?.value
      let start_time = getStartTime(entries, date)
      start_time = format(start_time, 'HH:mm')

      return getEmptyEntry({
        order: entries.length - 1,
        employee: employee,
        days: this.days,
        start_time,
      })
    },
    mapDuplicateRow(row) {
      const newRow = cloneDeep(row)
      const start_time = row.start_time
      newRow.start_time = row.end_time?.slice(0, 5)
      if (!newRow.start_time) {
        newRow.start_time = start_time
      }
      delete newRow.timesheet_id
      return newRow
    },
    async onCellValueChanged(params) {
      const field = params?.colDef?.field

      if (field === 'employee_id') {
        await this.handleEmployeeChange(params)
        return
      }

      if (field === 'date') {
        params.data.timesheet_id = undefined
      }

      if (!fieldsToSkip.includes(field)) {
        await this.saveOrUpdateEntry(params)
      }
    },
    async handleEmployeeChange(params) {
      const id = params.data?.id
      if (id) {
        await axios.delete(`/restify/timesheet-entries/${id}`)
        params.data.id = undefined
        params.data.timesheet_id = undefined
      }
      params.data.craft_code_id = this.getEmployee(params.data.employee_id)?.craft_code_id
      await this.saveOrUpdateEntry(params)
    },
    async saveOrUpdateEntry(params) {
      const row = params.data
      const isValid = isRowValid(row)
      if (!isValid) {
        return params.data
      }
      const oldData = { ...params.data }
      const entry = this.mapEntryToRequest(row)
      let savedData = params.data
      try {
        params.data.loading = true
        if (!entry.id) {
          savedData = await saveInlineEntry({ row: entry, params, url: '/restify/timesheet-entries' })
        } else {
          savedData = await updateInlineEntry({ row: entry, params, url: `/restify/timesheet-entries/${entry.id}` })
        }
        params.node.setData(params.data)
      } catch (err) {
        await this.restoreOldEntry(params, oldData)
      }
      return savedData
    },
    async restoreOldEntry(params, oldData) {
      const id = params.data?.id
      if (id) {
        const entry = await axios.get(`/restify/timesheet-entries/${id}`)
        params.data = entry?.data?.attributes
        params.node.setData(params.data)
        return
      }
      params.data = oldData
      params.node.setData(oldData)
    },
    mapEntryToRequest(entry) {
      const employee = this.getEmployee(entry.employee_id)
      const defaultEntry = getEmptyEntry({
        employee: employee,
        days: this.days,
      })
      let mappedEntry = setTypeSources(entry)
      mappedEntry = cloneDeep(entry)

      for (let key in defaultEntry) {
        mappedEntry[key] = entry[key]
      }
      mappedEntry.end_time = undefined
      mappedEntry.date = entry.date
      if (entry.id) {
        mappedEntry.id = entry.id
        mappedEntry.date = this.$formatDate(entry.date, dateTypes.IsoDate, true)
      } else {
        mappedEntry.date = this.$formatDate(new Date(entry.date), dateTypes.IsoDate)
      }
      if (mappedEntry.start_time?.length > 4) {
        mappedEntry.start_time = mappedEntry.start_time.slice(0, 5)
      }
      return mappedEntry
    },
    refresh() {
      this.$refs.table?.refresh()
    },
    transformData(data) {
      const transformedData = data.map(row => {
        let start_time = row?.attributes?.start_time
        start_time = start_time.slice(0, 5)
        return {
          id: row.id,
          ...row.attributes,
          start_time,
        }
      })
      this.entries = cloneDeep(transformedData)
      return transformedData
    }
  }
}
</script>
