import { ref, watch, onUnmounted } from "vue";

export function useScreenCapture() {
  const stream = ref<MediaStream | null>(null);
  const isStreaming = ref<boolean>(false);

  const handleStreamEnded = () => {
    isStreaming.value = false;
    stream.value = null;
  };

  watch(stream, (newStream, oldStream) => {
    if (oldStream) {
      oldStream
        .getTracks()
        .forEach((track) =>
          track.removeEventListener("ended", handleStreamEnded)
        );
    }
    if (newStream) {
      newStream
        .getTracks()
        .forEach((track) => track.addEventListener("ended", handleStreamEnded));
    }
  });

  const start = async () => {
    const mediaStream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        displaySurface: "monitor", // "monitor" selects the entire screen
      },
      // controller
    });
    stream.value = mediaStream;
    isStreaming.value = true;
    return mediaStream;
  };

  const stop = () => {
    if (stream.value) {
      stream.value.getTracks().forEach((track) => track.stop());
      stream.value = null;
      isStreaming.value = false;
    }
  };

  onUnmounted(() => {
    if (stream.value) {
      stream.value.getTracks().forEach((track) =>
        track.removeEventListener("ended", handleStreamEnded)
      );
    }
  });

  return {
    type: "screen",
    start,
    stop,
    isStreaming,
    stream,
  };
}
