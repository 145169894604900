<template>
  <div class="flex flex-col gap-2 divide-y divide-gray-200">
    <div
      v-for="(question, index) in localValue" :key="question.key"
      class="py-2"
    >
      <div class="flex justify-between items-center">
        <div class="flex flex-col">
              <span class="font-semibold">
                {{ question.question }}
              </span>
            <div class="flex text-gray-600 items-center text-sm">
                <span class="min-w-[200px]">
                  {{ questionTypeMapping[question.type] }}
                  <span v-if="question.required" class="font-semibold">
                    {{$t('- Required')}}
                  </span>
                </span>
                <template v-if="question.type === questionTypes.Signature">
                  <base-switch v-model="localValue[index].default_value"/>
                </template>
                <span v-else>
                  {{ getDefaultValue(question) }}
                </span>
            </div>
            <div v-if="question.description" class="max-w-xl">
                <span class="font-semibold text-sm">
                  {{ $t('Description') }}
                </span>
            <div v-if="question.description" class="text-gray-600 text-sm">
              {{ question.description }}
            </div>
          </div>
        </div>
        <div class="flex space-x-2">
          <TableEditButton @click="onEditQuestion(index)"/>
          <TableDeleteButton @click="deleteQuestion(index)"/>
        </div>
      </div>
    </div>
    <div class="border-t border-gray-200 mb-4 pt-4">
      <div class="flex justify-end">
        <TableAddButton variant="primary-light" :text="$t('Add Question')" @click="showAddQuestionDialog = true"/>
      </div>
      <BaseFormDialog
        v-if="showAddQuestionDialog"
        :open.sync="showAddQuestionDialog"
        :title="$t('Add Question')"
      >
        <TimesheetQuestionForm
          :key="addQuestionKey"
          @submit="question => addQuestion(question)"/>
      </BaseFormDialog>
    </div>
    <BaseFormDialog
      v-if="showEditQuestionDialog"
      :open.sync="showEditQuestionDialog"
      :title="$t('Edit Question')"
    >
      <TimesheetQuestionForm
        :data="questionToEdit"
        @submit="question => editQuestion(question)"/>
    </BaseFormDialog>
  </div>
</template>
<script>
import TimesheetQuestionForm from "@/modules/payroll/components/timesheets/TimesheetQuestionForm.vue";
import { questionTypes } from "@/modules/payroll/utils/payrollUtils";
import i18n from "@/i18n";
import snakeCase from "lodash/snakeCase";
import cloneDeep from "lodash/cloneDeep";

const questionTypeMapping = {
  [questionTypes.Boolean]: i18n.t('Yes or No'),
  [questionTypes.Text]: i18n.t('Text'),
  [questionTypes.UrgentText]: i18n.t('Urgent Text'),
  [questionTypes.Signature]: i18n.t('Require Signature'),
}

export default {
  components: {
    TimesheetQuestionForm
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: [],
      questionTypes,
      questionTypeMapping,
      addQuestionKey: 0,
      showAddQuestionDialog: false,
      showEditQuestionDialog: false,
      questionToEdit: null,
    }
  },
  methods: {
    emitChange() {
      this.$emit('input', this.localValue)
    },
    addQuestion(newQuestion) {
      const index = this.localValue.length
      const questionText = newQuestion.question + index
      newQuestion.key = snakeCase(questionText)
      this.localValue.push(newQuestion)
      this.showAddQuestionDialog = false
      this.addQuestionKey++
      this.emitChange()
    },
    editQuestion(question) {
      const index = this.localValue.findIndex(q => q.key === question.key)
      this.localValue.splice(index, 1, question)
      this.showEditQuestionDialog = false
      this.emitChange()
    },
    onEditQuestion(index) {
      const question = this.localValue[index]
      this.questionToEdit = { ...question }
      this.showEditQuestionDialog = true
    },
    async deleteQuestion(index) {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete Question'),
        description: this.$t('Are you sure you want to delete this question?'),
        confirmButtonText: this.$t('Delete'),
      })
      if (!confirmed) {
        return
      }
      this.localValue.splice(index, 1)
      this.emitChange()
    },
    getDefaultValue(question) {
      if (question.default_value === true) {
        return this.$t('Yes')
      }
      if (question.default_value === false) {
        return this.$t('No')
      }
      return question.default_value
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.localValue = []
          return
        }
        this.localValue = cloneDeep(value)
      }
    },
  }
}
</script>
