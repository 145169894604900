<template>
  <AgDataTable
      :columns="columns"
      :masterDetail="true"
      :detailRowAutoHeight="true"
      :add-entity-in-new-tab="true"
      :detailCellRendererParams="detailCellRendererParams"
      :detailCellRenderer="detailCellRenderer"
      :actionsColumnWidth="showActions ? 300: 100"
      :url-params="finalUrlParams"
      :hide-actions="showActions ? 'view' : 'filters,add,view'"
      url="/restify/job-costing-inits"
      :actions="showActions ? 'add,search,refresh,print,import': ''"
      import-url="/job-costing/settings/job-cost-init/import"
      permission="jobs"
      ref="table"
      dom-layout="autoHeight"
      :perPage="9999"
      :showPagination="false"
      @grid-ready="grid = $event"
      @data-fetch="$emit('data-fetch', $event)"
  >

    <template
      v-if="showActions"
      #additional-actions>
      <base-select
          v-model="selectedType"
          :placeholder="$t('Initialize')"
          :options="types"
          rules="required"
          class="w-64"
          label=""
          inline-errors
      />
    </template>

    <template
      v-if="showActions"
      #extra-actions="{row}"
    >
      <ProofListingButton
        v-if="row.meta?.authorizedToPost"
        :path="`/job-costing/settings/job-cost-init/${row.id}/proof-listing`"
      />
      <JobCostInitExport
        :id="row.id"
        size="xs"
        class="!mr-0"
      />
    </template>

  </AgDataTable>
</template>
<script>
  import { costTypes } from '@/enum/enums'
  import { useStorage } from '@vueuse/core'
  import JobCostInitEntries from '@/modules/job-costing/components/job-cost-init/JobCostInitEntries'
  import JobCostInitExport from "@/modules/job-costing/components/job-cost-init/JobCostInitExport.vue";

  export default {
    components: {
      JobCostInitExport,
      JobCostInitEntries,
    },
    props: {
      urlParams: {
        type: Object,
      },
      showActions: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        grid: null,
        detailCellRenderer: null,
        selectedType: useStorage('jc-init-type', costTypes.Cost),
        types: [
          {
            label: this.$t('Costs & Retention Payable'),
            value: costTypes.Cost,
          },
          {
            label: this.$t('Billings & Retention Receive'),
            value: costTypes.Income,
          },
        ],
      }
    },
    computed: {
      columns() {
        return [
          {
            label: this.$t('Period'),
            prop: 'attributes.period',
            cellRenderer: 'agGroupCellRenderer',
          },
          {
            label: this.$t('Fiscal Year'),
            prop: 'attributes.fiscal_year',
          },
          {
            label: this.$t('Entries'),
            prop: 'attributes.entries_count',
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            component: 'StatusLink',
            align: 'center',
          },
        ]
      },
      finalUrlParams() {
        if (this.urlParams) {
          return this.urlParams
        }
        return {
          type: this.selectedType,
        }
      },
      detailCellRendererParams() {
        return {
          parentGrid: this.grid,
          readOnly: true,
          type: this.selectedType,
        }
      }
    },
    created() {
      this.detailCellRenderer = 'JobCostInitEntries'
    },
  }
</script>
