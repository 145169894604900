<template>
  <div
    class="col-span-6"
  >
    <label class="form-label">
      {{ $t('Print Options') }}
    </label>
    <BaseCheckbox
      v-model="data.flags.round_to_whole_dollars"
      :label="$t('Round to Whole Dollars')"
      id="round_to_whole_dollars"
    />
    <BaseCheckbox
      v-model="data.flags.round_to_whole_percent"
      :label="$t('Round to Whole Percent')"
      id="round_to_whole_percent"
    />
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        flags: {
          round_to_whole_dollars: false,
          round_to_whole_percent: false,
        }
      }),
    }
  }
}
</script>
