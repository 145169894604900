<template>
  <BaseEntityFilterRow
    v-bind="$attrs"
    :title="$t('Jobs')"
    :value="value"
    list-key="job_ids"
    @change="resetFilters"
  >
    <template #default="{ selectList }">
      <JobSelect
        v-if="selectList"
        v-model="value.job_ids"
        :add-entity="false"
        collapse-tags
        :placeholder="$t('Select Jobs')"
        :hide-label="true"
        value-key="id"
        multiple
      />
      <JobRangeFilter
        v-else
        v-model="value"
        :as-row="false"
      />
    </template>
    <template #extra-filters>
      <slot name="extra-filters" />
    </template>
  </BaseEntityFilterRow>
</template>
<script>
import {
  JobRangeFilter,
} from '@/components/range-filters'

export default {
  name: 'JobFilterRow',
  components: {
    JobRangeFilter,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    }
  },
  methods: {
    resetFilters() {
      this.value.job_ids = [];
      this.value.job_number_from = this.value.job_number_to = null
      this.$emit('reset')
    }
  }
}
</script>
