<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="rows"
    :has-summary="false"
    :total-rows="rowsLength"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{ row }">
      <div class="flex space-x-1">
        <template v-if="row.header.job">
          {{ $t('Job:') }} &nbsp;
          <JobLink
            :data="row.header.job"
            :show-preview="false"
            :show-name="true"
          />
        </template>
        <div
          v-else-if="row.header.title"
          class="text-gray-900"
        >
          {{ row.header.title }}
        </div>
      </div>
    </template>

    <template #actual.cost="{ row }">
      <div>
        <div
          v-for="actualCost of row.actual.cost"
          :key="actualCost.type?.id"
        >
          <div class="flex justify-between">
            <div class="font-semibold">{{ actualCost.type.name }}</div>
            <div>{{ $formatPrice(actualCost.amount) }}</div>
          </div>
        </div>
      </div>
    </template>

    <template #budget.cost="{ row }">
      <div>
        <div
          v-for="budgetCost of row.budget.cost"
          :key="budgetCost.type?.id"
        >
          <div class="flex justify-between">
            <div class="font-semibold">{{ budgetCost.type.name }}</div>
            <div>{{ $formatPrice(budgetCost.amount) }}</div>
          </div>
        </div>
      </div>
    </template>

    <template #actual.income="{ row }">
      <div>
        <div
          v-for="actualIncome of row.actual.income"
          :key="actualIncome.type?.id"
        >
          <div class="flex justify-between">
            <div class="font-semibold">{{ actualIncome.type.name }}</div>
            <div>{{ $formatPrice(actualIncome.amount) }}</div>
          </div>
        </div>
      </div>
    </template>

    <template #budget.income="{ row }">
      <div>
        <div
          v-for="budgetIncome of row.budget.income"
          :key="budgetIncome.type?.id"
        >
          <div class="flex justify-between">
            <div class="font-semibold">{{ budgetIncome.type.name }}</div>
            <div>{{ $formatPrice(budgetIncome.amount) }}</div>
          </div>
        </div>
      </div>
    </template>
    <template #subtotal="{ subtotal }">
      <td>{{ subtotal.title }}</td>
      <td
        v-if="include.actual_cost"
        align="right"
      >
        <div class="flex justify-between">
          <div class="font-semibold">{{ $t('Actual Cost') }}</div>
          <span>{{ $formatPrice(subtotal.actual.cost)  }}</span>
        </div>
      </td>
      <td
        v-if="include.budget_cost"
        align="right"
      >
        <div class="flex justify-between">
          <div class="font-semibold">{{ $t('Budget Cost') }}</div>
          <span>{{ $formatPrice(subtotal.budget.cost)  }}</span>
        </div>
      </td>
      <td
        v-if="include.actual_income"
        align="right"
      >
        <div class="flex justify-between">
          <div class="font-semibold">{{ $t('Actual Income') }}</div>
          <span>{{ $formatPrice(subtotal.actual.income)  }}</span>
        </div>
      </td>
      <td
        v-if="include.budget_income"
        align="right"
      >
        <div class="flex justify-between">
          <div class="font-semibold">{{ $t('Budget Income') }}</div>
          <span>{{ $formatPrice(subtotal.budget.income)  }}</span>
        </div>
      </td>
      <td align="right">{{ $formatPrice(subtotal.gain_loss) }}</td>
      <td align="right">{{ $formatPercent(subtotal.profit) }}</td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { PeriodCostIncomeSummaryReportOptions } from '@/modules/job-costing/components/reports/util'
export default {
  extends: ReportTableWrapper,
  computed: {
    grandTotals() {
      return this.data.grand_totals
    },
    computeProfit() {
      return this.options.compute_profit
    },
    include() {
      const keys = [
        'actual_cost',
        'budget_cost',
        'actual_income',
        'budget_income',
      ]

      return keys.reduce((acc, key) => {
        acc[key] = this.$route.query[`include[${key}]`] == '1' || this.$route.query[`include[${key}]`] == 'true'
        return acc
      }, {})
    },
    columns() {
      const isProfitByCost = this.computeProfit === PeriodCostIncomeSummaryReportOptions.ComputeProfit.PercentOfCost
      return [
        {
          label: this.$t('Job'),
          prop: 'job_totals',
          align: 'left',
          minWidth: 80,
          maxWidth: 100,
        },
        {
          label: this.$t('Actual Cost'),
          prop: 'actual.cost',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 200,
          maxWidth: 250,
          visible: this.include.actual_cost,
        },
        {
          label: this.$t('Budget Cost'),
          prop: 'budget.cost',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 200,
          maxWidth: 250,
          visible: this.include.budget_cost,
        },
        {
          label: this.$t('Actual Income'),
          prop: 'actual.income',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 200,
          maxWidth: 250,
          visible: this.include.actual_income,
        },
        {
          label: this.$t('Budget Income'),
          prop: 'budget.income',
          align: 'left',
          verticalAlign: 'top',
          minWidth: 200,
          maxWidth: 250,
          visible: this.include.budget_income,
        },
        {
          label: this.$t('Gain / Loss'),
          prop: 'gain_loss_hidden', // (appended _hidden) We hide the value here becase we display it the subtotal row
          align: 'right',
          component: 'FormattedPrice',
          hideZero: true,
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: isProfitByCost ? this.$t('% of <br> Cost') : this.$t('% of <br> Income'),
          prop: 'profit_hidden', // (appended _hidden) We hide the value here because we display it in the subtotal row
          align: 'right',
          component: 'FormattedPercent',
          hideZero: true,
          minWidth: 100,
          maxWidth: 120,
        },
      ]
    },
  },
  methods: {
    addJobGroup(jobGroup) {
      this.rows.push({
        header: {
          job: jobGroup.job,
        }
      })

      this.rows.push(jobGroup)
      
      this.rows.push({
        subtotal: {
          title: this.$t('Job Totals') + ` (${jobGroup.job.number})`,
          ...jobGroup.totals,
          gain_loss: jobGroup.gain_loss,
          profit: jobGroup.profit,
        }
      })
    },
    addGrandTotals() {
      const grandTotals = this.data.grand_totals

      this.rows.push({
        header: {
          title: this.$t('Grand Totals All Selected Jobs'),
        }
      })

      this.rows.push(grandTotals)

      this.rows.push({
        subtotal: {
          title: this.$t('Grand Totals'),
          ...grandTotals.totals,
          gain_loss: grandTotals.gain_loss,
          profit: grandTotals.profit,
        }
      })
    },
    composeRows(data) {
      data.forEach(jobGroup => {
        this.addJobGroup(jobGroup)
      })

      this.addGrandTotals()
    },
  },
}
</script>
