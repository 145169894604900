<template>
  <div>
    <BaseDataTable :columns="columns"
                   :data="mappedOptions"
                   actions="add"
                   ref="table"
                   :can-reorder-columns="false"
                   :show-pagination="showPagination"
                   @add="onOpenFormDialog({})"
    >
      <template v-slot:custom-header>
        <h5 class="form-section-title">
          {{ $t('Manage AP Expiration Dates') }}
        </h5>
      </template>
      <template v-slot:extra-actions="{row, index}">
        <table-edit-button @click="onOpenFormDialog(row, index)"/>
        <table-delete-button v-if="canDelete(row)"
                             @click="deleteExpirationDate(row, index)"
        />
      </template>
    </BaseDataTable>
    <BaseFormDialog v-if="showFormDialog"
                    @close="onCloseFormDialog"
                    :title="expirationDateToEdit.label ? $t('Edit Expiration Date') : $t('Add Expiration Date')"
                    :open.sync="showFormDialog">
      <ExpirationDateForm layout="modal"
                          :save-text="expirationDateToEdit.label ? $t('Update Expiration Date') : $t('Add Expiration Date')"
                          :entity="expirationDateToEdit"
                          @cancel="onCloseFormDialog"
                          @onSubmit="onSubmit"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import TableEditButton from '@/components/table/actions/TableEditButton'
  import TableDeleteButton from '@/components/table/actions/TableDeleteButton'
  import ExpirationDateForm from '@/modules/accounts-payable/components/defined-fields/ExpirationDateForm'

  export default {
    components: {
      TableEditButton,
      TableDeleteButton,
      ExpirationDateForm,
    },
    props: {
      value: {
        type: Object,
        default: () => ({
          general_liability: {
            date: null,
            label: 'General Liability',
            readonly: true,
          },
          workers_compensation: {
            date: null,
            label: `Worker's Compensation`,
            readonly: true,
          },
        }),
      },
      showPagination: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showFormDialog: false,
        expirationDateToEdit: {},
      }
    },
    computed: {
      mappedOptions() {
        const rows = []
        for (const [key, value] of Object.entries(this.value)) {
          rows.push({
            ...value,
            key,
          })
        }
        return rows
      },
      columns() {
        return [
          {
            label: this.$t('Label'),
            prop: 'label',
          },
          {
            label: this.$t('Date'),
            prop: 'date',
            component: 'FormattedDate',
          },
        ]
      },
      allOptionLabels() {
        return this.mappedOptions.map(({ label }) => label)
      },
    },
    methods: {
      onOpenFormDialog(entity, entityIndex) {
        this.expirationDateToEdit = entity
        this.expirationDateToEditIndex = entityIndex
        this.showFormDialog = true
      },
      onCloseFormDialog() {
        this.showFormDialog = false
        this.expirationDateToEdit = this.expirationDateToEditIndex = null
      },
      getOptionKeyByIndex(index) {
        return Object.keys(this.value)[index]
      },
      validateLabel(label, callback) {
        const anotherLabels = this.allOptionLabels.filter((el, index) => index !== this.expirationDateToEditIndex)
        const state = !anotherLabels.includes(label)
        return callback(state)
      },
      onSubmit(expirationDate) {
        this.validateLabel(expirationDate.label, (valid) => {
          if (!valid) {
            return this.$error('The expiration date with this name already exists.')
          }
          if (this.expirationDateToEditIndex > -1) {
            const key = this.getOptionKeyByIndex(this.expirationDateToEditIndex)
            this.value[key] = expirationDate
          } else {
            let key = expirationDate.label
            key = key.toLowerCase().replace(/[, ]+/g, '_')
            this.value[key] = expirationDate
          }
          this.onCloseFormDialog()
          this.refreshTable()
        })
      },
      async refreshTable() {
        this.$emit('refresh-expiration-dates')
        await this.$nextTick()
        await this.$refs?.table?.refresh()
      },
      canDelete(row) {
        const exceptionKeys = ['general_liability', 'workers_compensation']
        if (exceptionKeys.includes(row.key)) {
          return true
        }
        return !row.readonly
      },
      deleteExpirationDate(entity, entityIndex) {
        const key = this.getOptionKeyByIndex(entityIndex)
        delete this.value[key]
        this.refreshTable()
      },
    },
  }
</script>
