<template>
  <DetailLayout
    :loading="maintenanceCodeLoading"
    :current-resource="currentResource"
    :get-current-resource="getCurrentResource"
    :select-component="MaintenanceCodeSelect"
    :items="items"
  >
    <template #page-title>
      <div class="truncate">
        <div
          v-if="currentResource.id"
          class="flex"
        >
          {{ $t('Maintenance Code') }} {{ label }}
        </div>
      </div>
    </template>
  </DetailLayout>
</template>
<script>
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
import MaintenanceCodeSelect from "@/components/select/entities/MaintenanceCodeSelect.vue";

export default {
  components: {
    DetailLayout,
  },
  data() {
    return {
      MaintenanceCodeSelect,
      StimulsoftPrintEntities,
      dataLoading: true,
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/equipment/maintenance-codes/${ this.$route.params.id }/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/equipment/maintenance-codes/${ this.$route.params.id }/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
        {
          name: this.$t('Timesheets'),
          path: `/equipment/maintenance-codes/${ this.$route.params.id }/timesheets`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Invoices'),
          path: `/equipment/maintenance-codes/${ this.$route.params.id }/invoices`,
          authorizedTo: 'authorizedToShow',
          permission: 'invoices_show',
        },
        {
          name: this.$t('Purchase Orders'),
          path: `/equipment/maintenance-codes/${ this.$route.params.id }/purchase-orders`,
          authorizedTo: 'authorizedToShow',
          permission: 'purchase_orders_show',
        },
        {
          name: this.$t('Transactions'),
          path: `/equipment/maintenance-codes/${ this.$route.params.id }/transactions`,
          authorizedTo: 'authorizedToShow',
          permission: 'transaction_show',
        },
      ]
    },
    maintenanceCodeLoading() {
      return this.$store.state.equipmentManagement.maintenanceCodeLoading
    },
    currentResource() {
      return this.$store.state.equipmentManagement.currentMaintenanceCode
    },
    label() {
      const {
        code,
        description,
      } = this.currentResource?.attributes || {}

      return description
        ? `${code} (${description})`
        : code
    },
    pageTitle() {
      return `${this.$t('Maintenance Code')}: ${this.label}`
    }
  },
  methods: {
    async getCurrentResource(id) {
      return await this.$store.dispatch('equipmentManagement/getMaintenanceCode', id)
    },
  },
}
</script>
