<template>
  <DetailLayout
    :loading="agencyLoading"
    :current-resource="currentResource"
    :get-current-resource="getCurrentResource"
    :select-component="AgencySelect"
    :items="items"
  />
</template>
<script>
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import AgencySelect from "@/components/select/entities/AgencySelect.vue";

export default {
  components: { DetailLayout },
  data() {
    return {
      AgencySelect,
    }
  },
  computed: {
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/payroll/agencies/${this.currentAgencyId}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/payroll/agencies/${this.currentAgencyId}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]
    },
    agencyLoading() {
      return this.$store.state.payroll.agencyLoading
    },
    currentResource() {
      return this.$store.state.payroll.currentAgency
    },
    currentAgencyId() {
      return this.$route.params.id
    },
  },
  methods: {
    async getCurrentResource(id) {
      await this.$store.dispatch('payroll/getAgency', id)
    },
  },
}
</script>
