<template>
  <ElCollapseItem
    v-bind="$attrs"
    :class="{
      'collapse-transparent': transparent,
      'has-background': hasBackground,
    }"
    class="collapse-section"
  >
    <template #title>
      <slot name="title"/>
    </template>
    <div :class="wrapperClasses">
      <slot />
    </div>
  </ElCollapseItem>
</template>
<script>
  export default {
    name: 'BaseCollapseSection',
    props: {
      wrapperClasses: {
        type: String,
        default: 'mb-2 ml-2 print:ml-0'
      },
      transparent: {
        type: Boolean,
        default: false,
      },
      hasBackground: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
<style lang="scss">
  .collapse-section.el-collapse-item .el-collapse-item__header {
    @apply py-0;
  }

  .collapse-section.has-background.el-collapse-item,
  .collapse-section.has-background.el-collapse-item .el-collapse-item__wrap,
  .collapse-section.has-background.el-collapse-item .el-collapse-item__header {
    @apply bg-primary-100;
  }

  .collapse-section.has-background.el-collapse-item.is-active,
  .collapse-section.has-background.el-collapse-item.is-active .el-collapse-item__wrap,
  .collapse-section.has-background.el-collapse-item.is-active .el-collapse-item__header {
    @apply bg-transparent;
  }

  .collapse-section .el-collapse-item__content {
    @apply pb-0;
    font-size: initial;
  }

  .collapse-transparent {
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      @apply bg-transparent border-b-0;
    }
  }

  .el-collapse-item__arrow {
    @apply print:hidden;
  }
  .collapse-section.el-collapse-item .el-collapse-item__header {
    @apply font-bold leading-8 text-gray-800 flex items-center text-base print:text-xs;
  }
</style>
