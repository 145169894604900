<!--suppress JSVoidFunctionReturnValueUsed -->
<template>
  <div class="h-full flex flex-col data-table"
       ref="root"
       :id="tableId"
       :class="{
          'compact': compact,
          'no-borders': noBorders,
          'no-side-borders': noSideBorders,
          'has-data': tableData.length > 0 || _data.length > 0,
          'no-data': tableData.length === 0 || _data.length === 0,
          'has-top-header': slots['thead-infos'],
       }"
  >
    <h4 v-if="title"
        class="my-2 font-bold leading-8 text-gray-800 flex items-center text-base print:text-xs print:leading-4 print:my-1">
      <slot name="title">
        <span v-html="title"></span>
      </slot>
    </h4>
    <filter-tags
      class="flex w-full md:hidden"
      :filters="gridContext.displayMatchFilters"
      :full-filters="gridContext.fullMatchFilters"
      :config-options="getFilterConfig()"
      @close="clearFilterValue"
    />
    <TableSelections
        v-if="showAction('selected-rows')"
        :selected-rows="_selectedRows"
        @remove="deselectRow"
        @remove-all="deselectAllRows"
    />
    <div v-if="showTableTopSection"
         class="w-full flex print:hidden"
         :class="{'mb-2': !showAction('search') && !showAction('add')}"
    >
      <div class="flex w-full justify-between table-header overflow-x-auto items-center"
           :class="{
              'compact': compact,
              'custom-header': !!$scopedSlots['custom-header'],
           }"
      >
        <slot name="custom-header">
          <div class="flex items-center">
            <search-input
                v-if="showAction('search')"
                v-model="searchQuery"
                :placeholder="searchPlaceholder"
                data-test="input-table-search"
                @input="onSearchChange"
                @change="(value, event) => onSearch(value, event)"
                @keydown.native.enter="onSearchEnter"
                @clear="onSearch('')"
            />
            <div v-if="showFiltersDropdown"
                 class="ml-2">
              <TableFilters
                  v-if="canFilter"
                  :config-options="getFilterConfig()"
                  :default-presets="getFilterDefaults()"
                  :active-filters="gridContext.matchFilters"
                  :enableFilterDrawer="enableFilterDrawer"
                  @on-change="onChangeFilers"
                  ref="entityFilters"
                  @on-display-change="onChangeDisplayFilters"
                  @on-full-filter-change="gridContext.fullMatchFilters = $event"
              />
            </div>
            <table-refresh-button v-if="showAction('refresh')"
                                  class="mx-2"
                                  @click="onRefreshClick"
            />
            <slot name="header-info"/>
          </div>
          <filter-tags class="hidden md:flex"
                       :filters="gridContext.displayMatchFilters"
                       :config-options="getFilterConfig()"
                       :full-filters="gridContext.fullMatchFilters"
                       @close="clearFilterValue"
          />
        </slot>
        <div class="flex items-center justify-end actions">
          <div class="flex items-center space-x-2">
            <slot
              name="additional-actions-before"
              :selected-rows="_selectedRows"
              :total="pagination.total"
            />
          </div>
          <TableActionsDropdown v-if="hasActions">
            <TableActionItem v-if="showAction('download')">
              <DownloadAction :url="`${url}/download`" :entity="entity"/>
            </TableActionItem>
            <TableActionItem v-if="showAction('export')">
              <ExportAction :export-model="entity"
                            :request-params="gridContext.lastRequestParams"
                            :url="url"
              />
            </TableActionItem>
            <TableActionItem v-if="showAction('upload')">
              <UploadAction :url="url"
                            :entity="entity"
                            @refresh="fetchData"
              />
            </TableActionItem>
            <TableActionItem v-if="showAction('import')">
              <router-link v-if="importUrl"
                           :to="importUrl"
                           class="action-item-text">
                <div class="p-2 bg-primary-50 mr-2 rounded-md">
                  <UploadIcon class="w-4 h-4 text-primary-500"/>
                </div>
                <span>{{ $t('Import') }}</span>
              </router-link>
              <ImportAction
                  v-else
                  :model-name="importModel"
                  @save="fetchData"/>
            </TableActionItem>
            <TableActionItem v-if="showAction('print')">
              <PrintAction
                :entity="entityName"
                :filters="gridContext.displayMatchFilters"
              />
            </TableActionItem>
            <TableActionItem v-if="showAction(TableActions.BulkDelete) && url">
              <button
                  :disabled="_selectedRows.length === 0 && deleteMode"
                  class="action-item-text"
                  type="button"
                  @click="bulkDeleteRows"
              >
                <div class="p-2 bg-red-50 mr-2 rounded-md">
                  <Trash2Icon class="w-4 h-4 text-red-500"/>
                </div>
                <span v-if="!deleteMode" class="text-gray-700">
                  {{ $t('Bulk delete') }}
                </span>
                <span v-else class="text-red-700">{{ $tc(`Delete rows`, _selectedRows.length) }}</span>
              </button>
            </TableActionItem>
            <slot name="dropdown-actions" :selected-rows="_selectedRows"></slot>
          </TableActionsDropdown>
          <TableReportsDropdown v-if="reports.length">
            <TableActionItem
              v-for="report of reports"
              :key="report.path"
              :title="report.title"
              :icon="ListIcon"
              icon-class="bg-primary-50 text-primary-500"
              @click="$router.push(report.path)"
            />
          </TableReportsDropdown>
          <div class="flex ml-2">
            <slot name="additional-actions"
                  :selected-rows="_selectedRows"
                  :total="pagination.total"
            />
          </div>
          <div>
            <div v-if="authorizeToCopyLastRow"
                 class="mr-4">
              <base-checkbox
                  v-model="copyPreviousRow"
                  :label="$t('Copy previous row')"
                  :id="`copy-prev-row-${tableId}`"
              />
            </div>
          </div>
          <table-add-button
              v-if="showAction('add')"
              :addEntityInNewTab="addEntityInNewTab"
              :link="composeAddEntityLink"
              :text="addText"
              :variant="addButtonVariant"
              class="ml-2"
              @click="onAddRow()"
          />
        </div>
      </div>
    </div>
    <div v-if="slots['thead-infos']" class="w-auto flex-1 table-header-top">
      <table class="w-full border border-b-0 rounded-t-md">
        <thead>
        <slot name="thead-infos"></slot>
        </thead>
      </table>
    </div>
    <AgGridVue
        v-bind="{
          ...$attrs,
          ...defaultTableProps,
        }"
        v-on="$listeners"
        class="ag-theme-alpine"
        :class="{
          'flex-1': !customHeightClass,
          [customHeightClass]: customHeightClass,
        }"
        :column-defs="tableColumns"
        :detail-grid-options="detailGridOptionsMerged"
        :row-data="tableData"
        :alwaysShowHorizontalScroll="true"
        :suppressDragLeaveHidesColumns="true"
        :is-row-selectable="canSelectRow"
        @grid-ready="onGridReady"
        @sort-changed="onSortChanged"
        @selection-changed="onSelectionChanged"
        @cell-value-changed="onCellValueChanged"
        @cell-key-down="onCellKeyDown"
        @row-data-updated="onRowDataUpdated"
        @drag-stopped="onDragStopped"
    />
    <div v-if="showPagination && isRemote"
         class="w-full flex justify-between table-pagination print:hidden p-2"
         :class="{
            'pt-4': !compact,
        }"
    >
      <div class="footer-left">
        <slot name="footer-left"></slot>
      </div>
      <div class="w-full flex items-end md:items-center justify-between">
        <div class="flex flex-row items-center text-gray-700 text-sm mt-2 md:mt-0">
          <span class="mr-2 md:mr-4 mb-1 md:mb-0 text-gray-900">
            {{ $t('Total ') }} {{ pagination.total }}
          </span>
          <el-select class="pagination-select"
                     size="mini"
                     v-model="pagination.perPage">
            <el-option v-for="value in gridContext.perPageOptions"
                       :key="value"
                       :label="value"
                       :value="value">
            </el-option>
          </el-select>
        </div>
        <template v-show="tableData.length > 0">
          <ElPagination class="hidden md:flex"
                        v-show="tableData.length > 0"
                        :current-page.sync="pagination.current_page"
                        :page-sizes="gridContext.perPageOptions"
                        :page-size="pagination.perPage"
                        :pager-count="5"
                        layout="prev, pager, next, jumper"
                        :total="pagination.total">
          </ElPagination>
          <ElPagination class="flex md:hidden mobile-pagination"
                        :current-page.sync="pagination.current_page"
                        :page-sizes="gridContext.perPageOptions"
                        :page-size="pagination.perPage"
                        :total="pagination.total"
                        layout="jumper, prev, next"
          >
          </ElPagination>
        </template>
      </div>
    </div>

    <slot name="cells-legend">
      <div v-if="showCellsLegend"
           class="flex space-x-2 mt-2 text-gray-600">
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-gray-200"></div>
          <div>{{ $t('Read only fields') }}</div>
        </div>
        <div class="flex space-x-2 items-center">
          <div class="w-4 h-4 rounded bg-red-200"></div>
          <div>{{ $t('Required fields') }}</div>
        </div>
        <slot name="cells-legend-after"></slot>
      </div>
    </slot>

  </div>
</template>

<script setup lang="ts">
  import axios from 'axios'
  import Vue, {
    computed,
    nextTick,
    onBeforeUnmount,
    onMounted,
    PropType,
    Ref,
    ref,
    useAttrs,
    useSlots,
    watch
  } from 'vue'
  import { UploadIcon, Trash2Icon, ListIcon } from 'vue-feather-icons'
  import { useStorage } from '@vueuse/core'
  import { Option as ElOption, Pagination as ElPagination, Select as ElSelect } from 'element-ui'
  import { AgGridVue } from '@ag-grid-community/vue'
  import '@ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
  import '@ag-grid-community/styles/ag-theme-alpine.css'
  import {
    CellKeyDownEvent,
    ColDef,
    ColumnApi,
    GetContextMenuItemsParams,
    GetRowIdParams,
    GridApi,
    GridReadyEvent,
    ModuleRegistry,
    NewValueParams,
    RowHeightParams,
    SortChangedEvent,
  } from '@ag-grid-community/core';
  import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
  import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
  import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
  import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
  import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
  import { MenuModule } from '@ag-grid-enterprise/menu'
  import TableRefreshButton from '@/components/table/actions/TableRefreshButton.vue';
  import { usePaginatedRequest } from './usePaginatedRequest.ts'
  import { addNewRow, duplicateRow, getSortProp, TableActions } from './tableUtils'
  import { getActionsColumn, getColumnTypes } from './tableColumnTypes.ts'
  import { gridContext } from './gridContext.ts'
  import i18n from '@/i18n';
  import { useRoute } from 'vue2-helpers/vue-router';
  import { can } from '@/modules/auth/plugins/permissionsPlugin';
  import NoDataRow from '@/components/table/NoDataRow.vue';
  import ImportAction from '@/components/table/actions/import/ImportAction.vue';
  import PrintAction from '@/components/table/actions/PrintAction.vue';
  import ExportAction from '@/components/table/actions/export/ExportAction.vue';
  import DownloadAction from '@/components/table/actions/download/DownloadAction.vue';
  import UploadAction from '@/components/table/actions/upload/UploadAction.vue';
  import TableFilters from '@/components/table/TableFilters.vue';
  import FilterTags from '@/components/table/FilterTags.vue';

  import TableAddButton from '@/components/table/actions/TableAddButton.vue';
  import TableActionsDropdown from '@/components/table/actions/TableActionsDropdown.vue';
  import TableReportsDropdown from '@/components/ag-grid/TableReportsDropdown.vue';
  import TableActionItem from '@/components/table/actions/TableActionItem.vue';
  import TableSelections from './TableSelections.vue';
  import { useTableFilters } from '@/components/ag-grid/useTableFilters';
  import uniqBy from 'lodash/uniqBy';
  import TableOverlayLoading from './TableOverlayLoading.vue';
  import { getContextMenu } from '@/components/ag-grid/getContextMenu';
  import { isColumnSortable, mapToAgGridColumn, toggleEdit } from '@/components/ag-grid/columns/columnUtils';
  import orderBy from "lodash/orderBy";
  import get from "lodash/get";
  import bus from '@/event-bus/EventBus'
  import { cellClasses } from '@/components/ag-grid/columnUtils'
  import Notifications from '@/components/common/NotificationPlugin/Notifications.vue'
  import { Column } from "@/components/ag-grid/tableTypes";
  import { prepareTableForPrint, usePrinterFriendly, wrapAgGridInTable } from "@/components/ag-grid/printUtils";
  import {$deleteConfirm} from "@/components/common/modal/modalPlugin";
  import {info, success} from "@/components/common/NotificationPlugin";
  import SearchInput from "@/components/form/SearchInput.vue";
  import { setLicenseKey } from "@/components/ag-grid/licenseUtils";
  import cloneDeep from "lodash/cloneDeep";
  import store from "@/store"

  // @ts-ignore
  Vue.use(Notifications)
  const app = new Vue({})

  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    MasterDetailModule,
    RangeSelectionModule,
    ClipboardModule,
    RowGroupingModule,
    MenuModule,
  ]);

  setLicenseKey()

  const props = defineProps({
    compact: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: '',
    },
    urlQuery: {
      type: String,
      default: '',
    },
    urlParams: {
      type: Object,
      default: () => ({}),
    },
    actionType: {
      type: String as PropType<'get' | 'post'>,
      default: 'get',
    },
    importUrl: {
      type: String,
    },
    importModel: {
      type: String,
      default: 'accounts',
    },
    transformData: {
      type: Function,
    },
    columns: {
      type: Array as PropType<Column[]>,
      default: () => [],
    },
    actions: {
      type: String,
      default: '',
    },
    actionsColumnWidth: {
      type: Number,
      default: 90,
    },
    entity: {
      type: String,
    },
    getRowId: {
      type: Function,
      default: (params: GetRowIdParams) => params.data.id || params.data._localId,
    },
    searchPlaceholder: {
      type: String,
      default: i18n.t('Search...'),
    },
    addText: {
      type: String,
      default: 'New',
    },
    noDataText: {
      type: String,
    },
    deleteDescription: {
      type: String,
    },
    deleteActionText: {
      type: String,
      default: 'Delete',
    },
    deleteAction: {
      type: Function,
    },
    deleteTitle: {
      type: String,
    },
    deleteCustom: {
      type: Boolean,
      default: false,
    },
    addEntityInNewTab: {
      type: Boolean,
      default: false,
    },
    editEntityUrlQuery: {
      type: String,
      default: '/{ID}/edit',
    },
    addEntityUrlQuery: {
      type: String,
      default: '',
    },
    openEntityInNewTab: {
      type: Boolean,
      default: true,
    },
    viewEntityUrlQuery: {
      type: String,
      default: '',
    },
    getEntityViewPath: {
      type: Function,
      default: null,
    },
    baseEntityPath: {
      type: String,
      default: null,
    },
    addButtonVariant: {
      type: String,
      default: 'primary',
    },
    defaultSort: {
      type: String,
      default: 'code',
    },
    defaultFilters: {
      type: Boolean,
      default: true,
    },
    defaultMatchFilters: {
      type: [Object, Boolean],
      default: () => ({}),
    },
    hideActions: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    hideTableTopSection: {
      type: Boolean,
      default: false,
    },
    customEntityFilters: {
      type: Array,
      default: () => [],
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    permission: {
      type: String,
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    addRowOnTab: {
      type: Boolean,
      default: false,
    },
    detailedGridOptions: {
      type: Object,
    },
    getEmptyRow: {
      type: Function as PropType<(row: any) => any>,
    },
    beforeAddRow: {
      type: Function as PropType<(copyPreviousRow: boolean) => any>,
    },
    perPage: {
      type: Number,
    },
    rowHeight: {
      type: Number,
    },
    dataLoading: Boolean,
    showCellsLegend: Boolean,
    authorizeToCopyLastRow: Boolean,
    noBorders: Boolean,
    noSideBorders: Boolean,
    readOnly: {
      type: Boolean,
      default: false
    },
    resizable: {
      type: Boolean,
      default: true
    },
    defaultColDef: {
      type: Object,
      default: () => ({}),
    },
    mapDuplicateRow: {
      type: Function as PropType<(row: any) => any>,
    },
    addNewRowAtTop: {
      type: Boolean,
      default: false,
    },
    allowTableBulkDelete: {
      type: Boolean,
      default: false,
    },
    allowAddEmptyRows: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    suppressColumnReordering: Boolean,
    disableColFlex: {
      type: Boolean,
      default: false,
    },
    isRowSelectable: {
      type: Function,
      default: () => true,
    },
    excludeFilters: {
      type: Array,
      default: () => [],
    },
    localSearch: {
      type: Boolean,
      default: false,
    },
    localSort: {
      type: Boolean,
      default: false,
    },
    copyPreviousRowEnabledByDefault: {
      type: Boolean,
      default: false,
    },
    changePageWithTimeout: {
      type: Boolean,
      default: false,
    },
    enableFilterDrawer: {
      type: Boolean,
      default: true,
    },
    disableCache: {
      type: Boolean,
      default: false,
    },
    resourceName: {
      type: String,
      default: '',
    },
    customHeightClass: {
      type: String,
      default: '',
    },
    reports: {
      type: Array,
      default: () => [],
    }
  })
  const emit = defineEmits([
    'add',
    'edit',
    'view',
    'delete',
    'refresh-click',
    'selection-change',
    'data-updated',
    'update:data',
    'update:selected-rows',
    'grid-ready',
    'cell-value-changed',
    'data-fetch',
    'meta-fetch',
    'drag-stopped',
    'row-delete',
    'after-bulk-delete',
  ])

  const gridApi = ref<GridApi>() // Optional - for accessing Grid's API
  const grid = ref<GridReadyEvent>() // Optional - for accessing Grid's API
  const columnApi = ref<ColumnApi>() // Optional - for accessing Grid's API
  const copyPreviousRow = ref(props.copyPreviousRowEnabledByDefault || false)
  const _selectedRows = ref<any[]>([])
  const _data = ref<any[]>([])

  watch(() => props.selectedRows, async (newVal) => {
    if (!newVal) {
      return
    }
    _selectedRows.value = newVal
    await nextTick()
    setSelectedRows()
  })

  const defaultColDef = computed(() => {
    return {
      sortable: false,
      resizable: props.resizable,
      unSortIcon: true,
      flex: !isPrintPage.value && !props.disableColFlex ? 1 : undefined,
      comparator: props.url ? () => 0 : undefined,
      suppressMenu: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      ...props.defaultColDef,
    }
  })

  const slots = useSlots()

  const isRemote = computed(() => !!props.url)

  const showActionsColumn = computed(() => {
    const hasRowActions = [TableActions.View, TableActions.Edit, TableActions.Delete].some(showAction)
    return hasRowActions || slots['extra-actions'] || slots['extra-actions-before']
  })

  const actionsColumnWidth = computed(() => {
    const actionsCount = [TableActions.View, TableActions.Edit, TableActions.Delete].filter(showAction).length
    const actionsWidth = actionsCount * 42
    return Math.max(actionsWidth, props.actionsColumnWidth)
  })

  const actionsArray = computed(() => {
    return props.actions.split(',').map(action => action.trim().toLowerCase())
  })

  const route = useRoute()
  const composeAddEntityLink = computed(() => {
    return props.addEntityUrlQuery ? props.addEntityUrlQuery : `${route.path}/add`
  })

  const hasActions = computed(() => {
    const actions = ['upload', 'import', 'export', 'download', 'print', 'bulkDelete']
    const hasActionSlot = slots['dropdown-actions']
    return actions.some(action => showAction(action)) || hasActionSlot
  })

  const hasAdditionalActions = computed(() => {
    return slots['additional-actions'] || slots['additional-actions-before']
  })

  const showTableTopSection = computed(() => {
    if (props.hideTableTopSection) {
      return false
    }

    const hasTableActions = showAction('search') || showAction('refresh') || showAction('add')
    return hasTableActions || props.title || hasActions.value || hasAdditionalActions.value
  })

  gridContext.storageKey = props.url + route.path + props.entity
  const storageColumns = useStorage<any[]>(gridContext.storageKey, [])
  const deleteMode = ref(false)

  const tableColumns = computed(() => {
    let columns = [...props.columns]
    if (slots.actions || showActionsColumn.value) {
      columns.push(getActionsColumn(props.actions, actionsColumnWidth.value))
    }

    if (showAction(TableActions.BulkDelete) && can(`${props.permission}_delete`) && deleteMode.value) {
      columns.unshift({
        headerName: '',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        minWidth: 60,
        maxWidth: 60,
        pinned: 'left',
      })
    }
    columns = orderBy(columns, 'order')

    return columns.map(col => {
      if (props.readOnly) {
        col.editable = false
      }

      col.suppressMovable = props.suppressColumnReordering

      return mapToAgGridColumn(col, slots)
    })
  })

  const { data, loading, fetchData, pagination, requestParams, searchQuery } = usePaginatedRequest({
    props,
    url: props.url,
    urlQuery: props.urlQuery,
    defaultFilters: props.defaultFilters,
    actionType: props.actionType,
    perPage: props.perPage,
    changePageWithTimeout: props.changePageWithTimeout,
    disableCache: props.disableCache,
    urlParams: {
      sort: props.defaultSort,
      ...props.urlParams,
    },
    dataFetched: async (tableData: any[]) => {
      emit('data-fetch', tableData, grid.value)
      await nextTick()
      updateInternalData()
      updateColumnSorting()
    },
    metaFetched: async (meta: any[]) => {
      emit('meta-fetch', meta, grid.value)
    },
    transformData: props.transformData,
  })

  const tableId = ref(crypto.randomUUID())

  const isLoading = computed(() => {
    return loading.value || props.dataLoading
  })

  const matchFilters = props.defaultMatchFilters && typeof props.defaultMatchFilters === 'object' ? props.defaultMatchFilters : {}
  gridContext.loading = isLoading.value
  gridContext.matchFilters = {
    ...matchFilters,
  }

  if (props.url) {
    gridContext.displayMatchFilters = {
      ...matchFilters,
    }
  }

  const tableData = computed(() => {
    if (isRemote.value) {
      return data.value
    }
    return props.data
  })

  const isPrintPage = computed(() => {
    return route.path.startsWith('/print')
  })

  const columnTypes = getColumnTypes({
    slots,
    props,
    emit,
    tableData,
    showAction,
  })

  watch(tableData, async (value) => {
    emit('data-updated', value)
    emit('update:data', value)
    if (props.selectedRows?.length !== _selectedRows.value?.length) {
      _selectedRows.value = props.selectedRows
    }
    await nextTick()
    setSelectedRows()
  })

  watch(() => gridContext.storageKey, (newValue) => {
    gridContext.storageKey = newValue
  })

  watch(() => props.urlParams, async (newValue) => {
    await fetchData(newValue)
  })

  watch(() => props.readOnly, value => {
    toggleEdit(grid.value, value)
  }, { immediate: true })

  const entityName = computed(() => {
    return props.entity || ''
  })

  async function refresh() {
    await fetchData({}, true)
  }

  async function onRefreshClick() {
    emit('refresh-click')
    await fetchData({}, true)
  }

  function showAction(action: string) {
    if (props.hideActions.includes(action)) {
      return false
    }
    const actionToPermissionMapping: Record<string, string> = {
      view: 'show',
      add: 'store',
      edit: 'update',
      delete: 'delete',
    }

    const actionPermission = actionToPermissionMapping[action]
    const fullPermission = `${props.permission}_${actionPermission}`
    const exceptionActions = ['search', 'refresh', 'import', 'export', 'download', 'upload', 'print', 'selected-rows', 'bulk-delete']
    const skipPermissionCheck = exceptionActions.includes(action)

    const includedInActions = actionsArray.value.includes(action)

    if (skipPermissionCheck) {
      return includedInActions
    }

    if (!action) {
      return false
    }

    if (props.permission) {
      return can(fullPermission)
    }
    return actionsArray.value.includes(action)
  }

  type GridReadyExtendedEvent = GridReadyEvent & {
    gridId: string
  }

  const root = ref()

  function onGridReady(params: GridReadyExtendedEvent) {
    grid.value = params
    gridApi.value = params.api
    columnApi.value = params.columnApi
    params.gridId = tableId.value
    gridContext.api = params.api

    if (isPrintPage.value) {
      params.api.sizeColumnsToFit();
      wrapAgGridInTable(root.value)
      prepareTableForPrint(root.value, params)
    }

    setSelectedRows()

    if (!tableData.value?.length && props.url && gridContext.loading || props.dataLoading) {
      gridApi?.value?.showLoadingOverlay()
    }

    updateInternalData()
    toggleEdit(grid.value, props.readOnly)
  }

  function updateInternalData() {
    _data.value = getTableData()
    emit('data-updated', _data.value)
    emit('update:data', _data.value)
  }

  function updateColumnSorting() {
    let colDefs = gridApi.value?.getColumnDefs()
    if (!colDefs) {
      return
    }
    colDefs = colDefs.map((col: any) => {
      if (props.url && !props.localSort) {
        col.sortable = isColumnSortable(col)
      }
      return col
    })
    gridApi.value?.setColumnDefs(colDefs)
  }

  function onRowDataUpdated() {
    updateInternalData()
  }

  function onDragStopped({ api }: { api: GridApi }) {
    if (!props.url) {
      return
    }
    storageColumns.value = api.getColumnDefs()
  }

  function onLocalSearch(query: string) {
    if (isRemote.value && !props.localSearch) {
      return
    }
    gridApi.value?.setQuickFilter(query)
  }

  function onSearchChange() {
    if (props.localSearch && !isRemote.value) {
      onLocalSearch(searchQuery.value)
    }
  }

  function onSearchEnter(event: any) {
    event?.preventDefault()
    onSearch(searchQuery.value, event)
  }

  async function onSearch(query: string, event: any = null) {
    if (event) {
      event?.preventDefault()
    }
    if (isRemote.value && !props.localSearch) {
      requestParams.value.search = query
      await fetchData({
        search: query,
      })
      return
    }
    onLocalSearch(query)
  }

  async function onSortChanged(params: SortChangedEvent) {
    if (!isRemote.value || props.localSort) {
      return
    }
    requestParams.value.sort = getSortProp(params, gridApi.value)
    await fetchData({
      sort: requestParams.value.sort,
    })
  }

  function deselectRow(row: any) {
    const node = gridApi.value?.getRowNode(row.id)
    node?.setSelected(false)
  }

  function deselectAllRows() {
    _selectedRows.value.forEach(row => {
      deselectRow(row)
    })
    _selectedRows.value = []
  }

  function onSelectionChanged() {
    if (!gridApi.value) {
      return
    }
    gridApi.value.forEachNode(node => {
      const index = _selectedRows.value.findIndex(row => row?.id === node?.data?.id)
      if (node.isSelected()) {
        if (index !== -1 || !node.data) {
          return
        }
        _selectedRows.value.push(node.data)
      } else {
        if (index === -1) {
          return
        }
        _selectedRows.value.splice(index, 1)
      }
    })
    _selectedRows.value = uniqBy(_selectedRows.value, 'id')
    emit('selection-change', _selectedRows.value)
    emit('update:selected-rows', _selectedRows.value)
  }

  function canSelectRow(params: any) {
    if (props.isRowSelectable) {
      return props.isRowSelectable(params)
    }
    if (!props.url || !showAction(TableActions.BulkDelete)) {
      return false
    }
    return params.data?.meta?.authorizedToDelete
  }
  async function bulkDeleteRows() {
    if (!deleteMode.value) {
      deleteMode.value = true
      info(i18n.t('Select the rows you want to delete'))
      return
    }
    const confirmed = await $deleteConfirm({
      title: i18n.tc('Delete rows', _selectedRows.value.length),
      description: i18n.t('Are you sure you want to delete selected rows? This action cannot be undone.'),
    })
    if (!confirmed) {
      return
    }
    try {
      const repositories = _selectedRows.value.map(row => row.id)
      await axios.delete(`${props.url}/bulk/delete`, {
        data: repositories
      })
      deselectAllRows()
      success(i18n.t('Selected rows have been deleted'))

      if (props.resourceName) {
        store.commit('globalLists/removeResourceEntries', {
          ids: repositories,
          resource: props.resourceName,
        })
      }

      emit('after-bulk-delete', repositories)
      await refresh()
      emit('delete')
      deleteMode.value = false
    } catch (err: any) {
      if (err.handled) {
        return
      }
    }
  }

  function onCellValueChanged(params: NewValueParams) {
    if (!params.data.dirty) {
      params.data.dirty = true
    }
    updateInternalData()
  }

  function setSelectedRows() {
    const selectedIds = _selectedRows.value.map(row => row.id)
    gridApi.value?.forEachNode(node => {
      if (selectedIds.includes(node?.data?.id)) {
        node.setSelected(true)
      } else {
        node.setSelected(false)
      }
    })
  }

  async function onCellKeyDown(params: CellKeyDownEvent) {
    let { rowIndex, colDef } = params
    let event = params.event as KeyboardEvent

    if (!props.addRowOnTab || event?.key !== 'Tab' || event?.shiftKey) {
      return
    }

    if (!isLastCellFocused(rowIndex, colDef)) {
      return
    }

    await onAddRow(params)
  }

  async function onAddRow(params?: any) {
    const noParams = !params
    if (!params) {
      params = grid.value
    } else {
      params.event?.preventDefault()
    }

    if (props.beforeAddRow) {
      await props.beforeAddRow(copyPreviousRow.value)
    }

    let createdRow
    let focusedOnCell = false
    if (props.authorizeToCopyLastRow && copyPreviousRow.value && (!params?.node || noParams)) {
      const nodes = getALlNodes()
      const lastRowNode = props.addNewRowAtTop ? nodes[0] : nodes[nodes.length - 1]
      params.node = lastRowNode
      const rowIndex: number = get(lastRowNode, 'rowIndex', 0) + 1
      focusOnCell(rowIndex)
      focusedOnCell = true
    }
    if (props.authorizeToCopyLastRow && copyPreviousRow.value && params.node) {
      createdRow = duplicateRow(params, props.mapDuplicateRow, props.addNewRowAtTop)
      params.data = createdRow
      await nextTick()
      if (!focusedOnCell) {
        params?.api.tabToNextCell()
      }
    } else if (props.getEmptyRow) {
      createdRow = await addNewRow(params, props.getEmptyRow, props.addNewRowAtTop)
    }

    if (createdRow) {
      params.node = findNodeById(createdRow.id || createdRow._localId)
      params.data = createdRow
    }
    emit('add', params, createdRow)
  }

  function getTableData() {
    const data: any[] = []
    gridApi.value?.forEachNode(node => {
      data.push(node.data)
    })
    return data
  }

  function getALlNodes() {
    const nodes: any[] = []
    gridApi.value?.forEachNode(node => {
      nodes.push(node)
    })
    return nodes
  }

  function findNodeById(id: string) {
    return gridApi.value?.getRowNode(id)
  }

  function isLastCellFocused(rowIndex: number | null, columnDef?: ColDef) {
    const data = getTableData()
    const lastRowFocused = props.addNewRowAtTop
      ? rowIndex === 0
      : rowIndex === (data.length - 1)

    const columns = props.columns.filter(col => !col.hide)
    let { field } = columns.at(-1) as Column
    const lastColumnFocused = field === columnDef?.colId

    return lastRowFocused && lastColumnFocused
  }

  function focusOnCell(rowIndex: number, startEditing = false) {
    // @ts-ignore TODO: fix types
    let firstCol = columnApi.value?.columnModel.displayedColumns[0]

    if (!firstCol) {
      return
    }

    if (startEditing && firstCol.colId === 'empty_column') {
      // @ts-ignore TODO: fix types
      firstCol = columnApi.value?.columnModel.displayedColumns[1]
    }

    gridApi.value?.setFocusedCell(rowIndex, firstCol)

    if (startEditing) {
      gridApi.value?.startEditingCell({
        rowIndex,
        colKey: firstCol.colId,
      })
    }
  }

  async function getEntityById(id: string) {
    if (!props.url || !showAction(TableActions.Edit)) {
      return
    }
    let url = `${props.url}/${id}`
    if (props.urlQuery) {
      url += props.urlQuery
    }

    const { data } = await axios.get(`${url}`, {
      params: {
        ...props.urlParams,
      },
    })

    emit('edit', data, true)
  }

  watch(() => route.query.id, async (id) => {
    if (!id || !props.url) {
      return
    }
    await getEntityById(id as string)
  }, { immediate: true })

  watch(isLoading, async (value) => {
    gridContext.loading = value
    if (value) {
      gridApi.value?.showLoadingOverlay()
    } else if (!value && tableData.value.length !== 0) {
      gridApi.value?.hideOverlay()
    } else if (!value && tableData.value.length === 0) {
      gridApi.value?.showNoRowsOverlay()
    }
  })

  const {
    getFilterConfig,
    onChangeFilers,
    clearFilterValue,
    getFilterDefaults,
    onChangeDisplayFilters,
    showFiltersDropdown,
    hasFilters,
    canFilter,
  } = useTableFilters({
    ...props,
    fetchData,
  })

  const loadingOverlay = Vue.extend({
    render(h) {
      return h(TableOverlayLoading)
    },
  })

  const overlayComponent = Vue.extend({
    render(h) {
      return h(NoDataRow, {
        props: {
          loading: loading.value,
          entity: props.entity,
          addText: props.addText,
          emptyText: props.noDataText,
          link: composeAddEntityLink.value,
          addEntityInNewTab: props.addEntityInNewTab,
          showAction,
        },
        on: {
          add: () => onAddRow(),
        },
      })
    },
  })

  function windowKeydown(event: KeyboardEvent) {
    // @ts-ignore
    if (event.target && event.target.nodeName === 'INPUT') {
      return
    }

    if (event.code === 'Equal' || event.key === '+' || event.key === '=' && showAction(TableActions.View)) {
      event.preventDefault()
      onAddRow()
    }
  }

  function validateTableData(callback: (params: any) => any) {
    try {
      const selector = `.ag-root-wrapper .${cellClasses.Invalid}`
      const firstInvalidCell = document.querySelector(selector)

      if (firstInvalidCell) {
        app.$notify({ type: 'danger', message: 'Please fill in the required fields (marked with red background).' })
      }
      return callback(firstInvalidCell)
    } catch (err) {
      console.error(err)
      return callback(true)
    }
  }

  onMounted(() => {
    if (props.url) {
      gridContext.filterOptions = {}
    }
    window.addEventListener('keydown', windowKeydown)
    bus.$on('validate-grid-data', validateTableData)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', windowKeydown)
    gridApi.value = undefined
    grid.value = undefined
    columnApi.value = undefined
    gridContext.api = null
    bus.$off()
  })

  const attrs = useAttrs()

  const defaultTableProps = computed(() => {
    let rowHeight = props.compact ? 40 : undefined

    const getRowHeight = (params: RowHeightParams) => {
      if (isPrintPage.value) {
        return 26
      }
      if (props.rowHeight) {
        return props.rowHeight
      }
      if (props.compact) {
        return 40
      }
      return undefined
    }

    return {
      columnTypes,
      defaultColDef,
      serverSideSortOnServer: true,
      suppressAggFuncInHeader: true,
      loadingOverlayComponentFramework: loadingOverlay,
      noRowsOverlayComponentFramework: overlayComponent,
      context: gridContext,
      pagination: attrs.pagination !== undefined ? attrs.pagination : !isRemote.value,
      paginationPageSize: props.url ? pagination.perPage : undefined,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 100,
      getRowId: props.getRowId,
      animateRows: true,
      getRowHeight,
      rowHeight: props.rowHeight || rowHeight,
      headerHeight: props.rowHeight || rowHeight,
      suppressRowClickSelection: true,
      enableTextCellSelection: true,
      rowModelType: 'clientSide',
      rowSelection: 'multiple',
      getContextMenuItems: (params: GetContextMenuItemsParams) => getContextMenu({ params, showAction, props, emit }),
    }
  })

  const mergedProps = computed(() => {
    return {
      ...props,
      ...attrs
    }
  })
  const { setPrinterFriendly } = usePrinterFriendly(grid as Ref<GridReadyEvent>, mergedProps)
  const detailGridOptionsMerged = computed(() => {
    if (!props.detailedGridOptions) {
      return undefined
    }
    return {
      ...defaultTableProps.value,
      animateRows: false,
      ...props.detailedGridOptions,
    }
  })

  defineExpose({
    setPrinterFriendly,
    refresh,
    tableData,
    gridApi,
    grid,
    columnApi,
    focusOnCell,
    getTableData,
    searchQuery,
    entries: _data,
    deselectAllRows,
  })
</script>

<script lang="ts">
  import ActionsHeader from './ActionsHeader.vue'
  import AgCustomTooltip from './AgCustomTooltip.vue'
  import { tableColumns } from '@/components/table/tableColumns'
  import { cellEditorComponents } from '@/components/ag-grid/cellEditors/cellEditors'

  export default {
    inheritAttrs: false,
    components: {
      ActionsHeader,
      AgCustomTooltip,
      ...cellEditorComponents,
      ...tableColumns,
    },
  }
</script>

<style lang="scss">
  .compact .ag-theme-alpine, .ag-theme-alpine-dark {
    --ag-icon-size: 16px;
    --ag-font-size: 13px;
    --ag-grid-size: 4px;
    --ag-row-height: 40px;
    --ag-cell-horizontal-padding: 8px;
  }

  .ag-theme-alpine, .ag-theme-alpine-dark {
    --ag-alpine-active-color: theme('colors.primary.500');
    --ag-selected-row-background-color: theme('colors.primary.50');
    --ag-input-focus-border-color: theme('colors.primary.100');
    --ag-range-selection-background-color: theme('colors.primary.100');
    --ag-range-selection-background-color-2: theme('colors.primary.200');
    --ag-range-selection-background-color-3: theme('colors.primary.300');
    --ag-range-selection-background-color-4: theme('colors.primary.300');
    --ag-row-hover-color: theme('colors.gray.50');
    --ag-column-hover-color: theme('colors.gray.200');
    --ag-header-foreground-color: theme('colors.gray.700');
    --ag-header-background-color: theme('colors.gray.50');
    --ag-background-color: theme('colors.white');
    --ag-border-color: theme('colors.gray.200');
    --ag-checkbox-unchecked-color: theme('colors.gray.200');
    --ag-foreground-color: theme('colors.gray.700');
    --ag-odd-row-background-color: theme('colors.white');
    --ag-borders-row: 1px solid;
    --ag-font-family: theme('fontFamily.sans');
    --ag-icon-size: 20px;
    --ag-font-size: 15px;
    --ag-header-height: 45px;

    .ag-row-footer.ag-row-level-0 {
      @apply font-medium;
    }

    .ag-row-footer.ag-row-level--1 {
      @apply font-semibold;
    }

    .ag-row .ag-row-group-leaf-indent,
    .ag-row-footer .ag-row-group-leaf-indent {
      @apply pl-0 ml-0;
    }

    .ag-center-cols-clipper {
      min-height: 150px !important;
    }

    .ag-cell-value a {
      @apply text-gray-700 hover:underline font-medium;
    }

    .ag-cell-value > span {
      @apply truncate;
    }

    .ag-root-wrapper {
      @apply rounded-md;
    }

    .ag-header-cell-text {
      @apply text-xs w-full;
    }

    .ag-row-selected::before {
      z-index: -1;
    }

    .ag-header-cell.header-editable  {
      .ag-header-cell-text::after {
        content: url('/img/icons/edit-icon.svg');
        @apply absolute right-[40px] w-4 h-4 opacity-50;
      }
    }

    .ag-cell {
      @apply flex items-center;
    }

    .ag-cell.actions-header {
      @apply px-2;
    }

    .ag-row-group .ag-group-value {
      @apply w-full;
    }

    .ag-cell-wrapper {
      @apply truncate;
    }

    .ag-cell .ag-input-field-input {
      @apply form-input;
    }

    .ag-root-wrapper-body.ag-layout-normal {
      min-height: 400px;
    }

    .ag-selection-checkbox .ag-checkbox-input,
    .ag-header-select-all .ag-checkbox-input {
      @apply absolute top-0;
    }

    .ag-checkbox-input-wrapper.ag-disabled {
      background-color: theme('colors.gray.200');
    }

    .ag-row .ag-cell-focus.ag-cell-inline-editing input {
      @apply border-none rounded-none;
    }

    .ag-watermark {
      display: none !important;
    }
  }

  .has-top-header .ag-root-wrapper {
    @apply rounded-none;
  }

  .has-data .ag-center-cols-clipper {
    min-height: 40px !important;
  }

  .no-data .ag-center-cols-clipper {
    min-height: 150px !important;
  }

  .no-borders .ag-theme-alpine {
    --ag-borders-row: none;
    --ag-row-border-width: 0;

    .ag-cell:not(.ag-cell-focus) {
      border-bottom: none;
      border-top: none;
      --ag-cell-horizontal-border: 1px solid theme('colors.gray.200');
    }

    .ag-cell:last-child {
      border-right: 1px solid transparent;
    }

    .ag-row-footer .ag-cell {
      border-top: 1px solid theme('colors.gray.200');
      --ag-cell-horizontal-border: 1px solid transparent;
    }

    .ag-row.ag-row-group {
      border-top: 1px solid theme('colors.gray.200');;
      border-bottom: 1px solid theme('colors.gray.200');;
    }

    .ag-row.ag-row-group + .ag-row.ag-row-group {
      border-top: 1px solid transparent;
    }
  }

  .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    border: none;
  }

  .no-side-borders .ag-theme-alpine {
    .ag-root-wrapper {
      @apply rounded-none border-l-0 border-r-0;
    }
  }

  .table-header {
    &.no-overflow {
      overflow: inherit;
    }

    &.compact {
      @apply my-1 lg:my-2;
    }

    &:not(.compact) {
      @apply my-2 lg:mb-4 lg:mt-2;
    }

    &:has(.is-dropdown-open) {
      overflow: inherit;
    }
  }

  .pagination-select {
    width: 80px;
  }

  .table-pagination {
    z-index: 8;
    @apply fixed bottom-0 left-0 bg-gray-50;
    @screen sm {
      @apply relative bg-gray-50;
    }
  }

  .pagination-wrapper {
    --component-size: 32px;
    --el-component-size: 32px;
    @apply print:hidden;
  }

  .invalid-ag-cell {
    @apply bg-red-200;
  }

  .invalid-light-ag-cell {
    @apply bg-red-100;
  }

  .readonly-ag-cell {
    @apply bg-gray-100 cursor-not-allowed;
  }
  .warning-ag-cell {
    @apply bg-orange-100 cursor-not-allowed;
  }
  .highlight-ag-cell {
    @apply bg-green-100;
  }

  .ag-cell-not-inline-editing:after {
    display: none;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.2s;
  }

  .ag-cell-not-inline-editing:not(.readonly-ag-cell):hover:after {
    display: none;
    content: ' ';
    background-image: url('/img/icons/edit-icon.svg');
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    top: calc(50% - 10px);
    @apply absolute right-1 bg-gray-50;
  }

  .readonly-light-ag-cell {
    @apply bg-gray-50 cursor-not-allowed;
  }
</style>
