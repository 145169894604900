<template>
  <BaseDataTable :columns="columns"
                 :url="url"
                 :url-params="urlParams"
                 :default-match-filters="defaultMatchFilters"
                 :add-entity-in-new-tab="addEntityInNewTab"
                 :add-text="$t('New journal')"
                 actions="search,refresh,print"
                 default-sort="-posted_at"
                 permission="journals"
                 ref="table"
                 base-entity-path="/ledger/journal"
                 add-entity-url-query="/ledger/journal/add"
                 @add="$router.push('/journal/add')"
                 @on-apply-filters="onApplyFilters"
  >
    <template v-slot:attributes.type="{row}">
      <status-badge :status="getType(row)"/>
    </template>
    <template v-slot:createdBy="{row}">
      <UserLink :id="row.attributes.created_by" />
    </template>
    <template v-slot:attributes.period="{row}">
      {{ getPeriodName(row.attributes.period) }}
    </template>
    <template v-slot:attributes.name="{row}">
      <div class="flex items-center">
        <div class="truncate">
          <router-link :to="`/ledger/journal/${row.id}/view`">
            {{ row.attributes.name }}
          </router-link>
        </div>
      </div>
    </template>
    <template #attributes.total_credit="{row}">
      {{ $formatPrice(Math.abs(row.attributes?.total_credit)) }}
    </template>
    <template #extra-actions="{row}">
      <JournalEntriesExport
        :id="row.id"
        :is-posted="true"
        size="xs"
        class="!mr-0"
      />
    </template>
  </BaseDataTable>
</template>
<script>
  import { GeneralLedgerJournalCode } from '@/enum/journal'
  import JournalEntriesExport from "@/modules/ledger/components/journals/JournalEntriesExport.vue";

  export default {
    components: { JournalEntriesExport },
    props: {
      moduleCode: {
        type: String,
        default: GeneralLedgerJournalCode,
      },
    },
    data() {
      return {
        url: '/restify/journals',
        addEntityInNewTab: true,
        allPagesSelected: false,
        journals: [],
        postingProgress: false,
        showPostJournalDialog: false,
        entityFilters: {
          created_by: true,
        },
      }
    },
    computed: {
      defaultMatchFilters() {
        return this.createByMeFilter
      },
      columns() {
        let result = [
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            minWidth: 150,
            maxWidth: 200,
          },
        ]
        if (!this.entityFilters?.created_by) {
          result.push(
              {
                label: this.$t('Created By'),
                prop: 'createdBy',
                minWidth: 120,
                maxWidth: 150,
              },
          )
        }
        result.push(
            {
              label: this.$t('Period'),
              prop: 'attributes.period',
              minWidth: 120,
              maxWidth: 200,
            },
            {
              label: this.$t('Year'),
              prop: 'attributes.fiscal_year',
              minWidth: 120,
              maxWidth: 200,
            },
            {
              label: this.$t('Type'),
              prop: 'attributes.type',
              align: 'center',
              maxWidth: 150,
            },
            {
              label: this.$t('Action'),
              prop: 'attributes.action',
              component: 'Status',
              maxWidth: 150,
            },
            {
              label: this.$t('Debit Amount'),
              prop: 'attributes.total_debit',
              component: 'FormattedPrice',
              maxWidth: 150,
            },
            {
              label: this.$t('Credit Amount'),
              prop: 'attributes.total_credit',
              maxWidth: 150,
            },
            {
              label: this.$t('Posted At'),
              prop: 'attributes.posted_at',
              maxWidth: 170,
              component: 'FormattedDate',
              params: {
                format: 'datetime',
              },
              visible: false,
            },
            {
              label: this.$t('Posted By'),
              prop: 'attributes.posted_by',
              maxWidth: 150,
              component: 'UserLink',
              visible: false,
            },
        )
        return result
      },
      urlParams() {
        return {
          code: this.moduleCode,
          'fiscal_year': this.activeFiscalYear,
          'status': 'posted',
          'sort': '-posted_at',
        }
      },
    },
    methods: {
      onApplyFilters(filters) {
        this.entityFilters = filters
      },
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
      getType(row) {
        const { type } = row.attributes
        if (type === 'single_period') {
          return this.$t('Single Period')
        } else if (type === 'recurring') {
          return this.$t('Recurring')
        }
        return ''
      },
      isRowSelectionDisabled(row) {
        return !this.$isAuthorized('authorizedToPost', row)
      },
    },
  }
</script>
