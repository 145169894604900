import i18n from '@/i18n'
import { get } from 'lodash'
import { formatDate } from "@/plugins/dateFormatPlugin";
import parseISO from 'date-fns/parseISO'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

const vendorExpirationDateKeys = [
  'contractor_license.expiration_date',
  'expiration_dates.workers-compensation.date',
  'expiration_dates.general-liability.date'
]

const expirationDateLabels = {
  ['contractor_license.expiration_date']: {
    label: i18n.t('Contractor License'),
  },
  ['expiration_dates.workers-compensation.date']: {
    label: i18n.t('Workers Compensation Date'),
  },
  ['expiration_dates.general-liability.date']: {
    label: i18n.t('General Liability Date'),
  },
}

let date = new Date()
date.setDate(date.getDate() + 30)
const NextMonthTime = date.getTime()

function isDateExpired(dateToCompare) {
  if (!dateToCompare) {
    return false
  }

  return new Date(dateToCompare).getTime() < new Date().getTime()
}

function willDateExpireThisMonth(dateToCompare) {
  if (!dateToCompare) {
    return false
  }

  return new Date(dateToCompare).getTime() < NextMonthTime
}

export function getVendorExpirationWarnings(vendor) {
  if (!vendor?.id) {
    return []
  }

  let messages = []

  const allKeys = [...vendorExpirationDateKeys]
  const allMappings = { ...expirationDateLabels }

  const expirationDates = get(vendor, 'expiration_dates', {})
  const expirationDateKeys = Object.keys(expirationDates)

  expirationDateKeys.forEach(key => {
    if (allKeys.includes(`expiration_dates.${key}.date`)) {
      return
    }

    allKeys.push(`expiration_dates.${key}.date`)
    allMappings[`expiration_dates.${key}.date`] = {
      label: expirationDates[key].label || key
    }
  })

  allKeys.forEach(key => {

    const dateValue = get(vendor, key)

    if (!dateValue) {
      return
    }

    const valueLabel = get(allMappings, key)?.label || key

    if (isDateExpired(dateValue)) {

      let timeAgo = formatDistanceToNow(parseISO(dateValue))
      messages.push(`${valueLabel} expired ${timeAgo} ago (${formatDate(dateValue)})`)

    } else if (willDateExpireThisMonth(dateValue)) {

      let timeAgo = formatDistanceToNow(parseISO(dateValue))
      messages.push(`${valueLabel} will expire in ${timeAgo} (${formatDate(dateValue)})`)
    }
  })

  return messages
}
