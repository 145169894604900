import Vue from 'vue'
import axios from 'axios'
import config from './config'
import router from '@/router'
import AuthService from '@/modules/auth/services/AuthService'
import Notifications from "@/components/common/NotificationPlugin/Notifications";
import Cache from "@/utils/Cache";
import { globalResourceRestifyMapping } from "@/components/form/util";
import store from "@/store";
import { PrerequisitesHasErrorsStatusCode } from "@/modules/common/components/proof-listing/util";
import get from "lodash/get";

Vue.use(Notifications);
const app = new Vue({
  router
})

axios.defaults.baseURL = config.API_HOST
export const statusCodesToHandle = [400, 401, 422];

function updateGlobalLists({ entity, data, method, url }) {
  if (!entity) {
    return
  }
  const resource = globalResourceRestifyMapping[entity]
  if (!resource) {
    return
  }
  const isValidData = typeof data === 'object' && data?.attributes

  if (method === 'delete') {
    const id = url.split('/').pop()
    store.commit('globalLists/removeResource', {
      id,
      resource,
    })
  } else if (method === 'post' && isValidData) {
    store.commit('globalLists/addResource', {
      data: data?.attributes,
      resource,
    })
  } else if (['put', 'patch'].includes(method) && isValidData) {
    store.commit('globalLists/updateResource', {
      data: data?.attributes,
      resource,
    })
  }
}

export function successInterceptor(response) {
  const { url, method } = response.config
  if (method === 'get') {
    return response.data
  }
  const urlParts = url.split('/').filter(p => p !== '')
  let entity = ''
  if (urlParts.length > 1 && urlParts[0] === 'restify') {
    entity = urlParts[1]
  }
  const methodsToClearCache = ['post', 'put', 'patch', 'delete']
  if (entity && methodsToClearCache.includes(method)) {
    Cache.removeForEntity(entity)
  }

  updateGlobalLists({ entity, data: response?.data?.data, method, url })

  const message = response.data?.data?.message
  const exceptionActions = ['set-as-main-dashboard']
  const isExceptionAction = exceptionActions.some(action => url.includes(action))

  if (message && !isExceptionAction) {
    app.$notify({ type: 'success', message });
  }

  return response.data;
}

function isUnauthorized(status) {
  const isOnSecuredRoute = window.app.$route.matched.some(record => record.meta.requiresAuth)

  return status === 401 && isOnSecuredRoute
}

export async function errorInterceptor(error) {
  const { status } = error.response || {};
  const { url, method, params } = error.response?.config || {}
  /*
  * Custom status code that is used to indicate that the user has not completed the prerequisites,
  * used for proof listing pages to warn the user that he needs to complete the prerequisites before he can proceed with the proof.
  */
  if (status === PrerequisitesHasErrorsStatusCode) {
    const data = get(error.response, 'data.data', {});
    return Promise.resolve({ data, status });
  }

  let errors = '';

  if (status === 404 && method === 'get' && url?.includes('restify') && !params?.ignore404) {
    window.app.$router.push('/404');
  }
  if (status === 500 && error.response?.message?.includes('Attempted to lazy load')) {
    app.$notify({ type: 'danger', message: 'Attempted to lazy load entity issue. Please check dev tools console for more details and reach out to backend team.' });
  }

  if (statusCodesToHandle.includes(status)) {
    errors = mapErrors(error.response.data, error.response);
    if (errors === 'Unauthenticated.') {
      errors = 'Your session expired. Please login in again to use the application'
    }

    if (!errors) {
      return Promise.reject(error);
    }

    app.$notify({ type: 'danger', message: errors, once: true });
    error.handled = true
  }

  error.errors = errors;

  if (isUnauthorized(status)) {

    AuthService.logout();
    window.app.$router.push('/login');

    return Promise.reject(error);
  }
  return Promise.reject(error);
}

export function requestInterceptor(config) {
  const token = AuthService.getToken();

  config.headers.Accept = 'application/vnd.api+json'

  if (!config.headers['Authorization'] && token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}

function mapErrors(data, response = {}) {
  let errors = '';
  const url = response?.config?.url

  if (response.status === 401 && url === '/login') {
    return 'Your session expired. Please sign in into your account again.'
  } else if (response.status === 401 && url === '/register') {
    return 'It seems like your invitation url expired. Please request another invitation from the company administrator or try to login if you already registered.'
  }

  if (data.message) {
    return data.message
  }

  if (data.data?.message) {
    return data.data.message
  }

  if (Array.isArray(data.errors)) {
    const hasStringErrors = typeof data.errors[0] === 'string'

    if (hasStringErrors) {
      return data.errors[0]
    }

    data.errors = data.errors[0]
  }
  for (let err in data.errors) {
    let errorRow = data.errors[err].join('</br>');
    if (!errorRow.endsWith('</br>')) {
      errorRow += '</br>';
    }
    errors += errorRow;
  }
  return errors;
}

axios.interceptors.response.use(successInterceptor, errorInterceptor);
axios.interceptors.request.use(requestInterceptor);
