<template>
  <div>
    <InvoiceDetailsPage
      :loading="billingLoading"
      :data="originalBilling"
      :custom-title="title || $t('Recurring Receivable')"
      entity="recurring-billings"
    >
      <template #title>
        <InvoiceStatusPopover
          :invoice="originalBilling"
          placement="left"
          class="print:hidden"
        />
        <ReviewStatus
          v-if="showApproveStatus"
          :row="originalBilling"
          class="ml-2"
        />
        <BaseBadge>
          {{ $t('Post Automatically') }}: {{ billing.should_post ? $t('Yes') : $t('No') }}
        </BaseBadge>
      </template>
      <template #header-left>
        <div class="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <CompanyContactInfo :title="$t('From')" :company="$currentCompany"/>
          <CustomerContactInfo :title="$t('Recurring Receivable For')" :customer="customer"/>
        </div>
      </template>
      <template #header-right-extra>
        <slot name="header-right-extra" :billing="billing" />
      </template>
      <template #content>
        <InvoiceDescription
          v-if="billing.id"
          :invoice="billing"
          entity="recurring-billings"
          :can-edit="false"
        />
        <div class="flex items-center text-sm mb-4 -mt-2 px-6">
          <span class="font-medium leading-normal text-gray-400 mr-2 w-[120px]">{{ $t('Schedule:') }}</span>
          <div class="flex items-center space-x-1">
            <RRuleText
              :value="billing?.recurring_rule"
              class="font-semibold"
            />
          </div>
        </div>
        <div
          v-if="billing.send_automatically"
          class="flex items-start text-sm mb-4 -mt-2 px-6"
        >
          <span class="font-medium leading-normal text-gray-400 mr-2 w-[120px]">{{ $t('Email Options:') }}</span>
          <div class="space-y-2">
            <div>
              <span class="font-semibold mr-2">{{ $t('From Email') }}</span>
              <BaseBadge>
                {{ billing.sending_options.sender_email }}
              </BaseBadge>
            </div>
            <div>
              <span class="font-semibold mr-2">{{ $t('Recipients') }}</span>
              <BaseBadge
                v-for="label of recipientLabels"
                :key="label"
              >
                {{ label }}
              </BaseBadge>
            </div>
            <div>
              <span class="font-semibold mr-2">{{ $t('Options') }}</span>
              <BaseBadge
                v-for="label of sendOptionsLabels"
                :key="label"
              >
                {{ label }}
              </BaseBadge>
            </div>
            <div>
              <span class="font-semibold mr-2">{{ $t('Template') }}</span>
              <BaseBadge>
                {{ templateName }}
              </BaseBadge>
            </div>
            <div>
              <span class="font-semibold mr-2">{{ $t('Print Options') }}</span>
              <BaseBadge
                v-for="label of printOptionsLabels"
                :key="label"
              >
                {{ label }}
              </BaseBadge>
            </div>
          </div>
        </div>

        <slot name="entries" :billing="billing" />

        <div
          v-if="isActiveOrInactive"
          class="px-4 py-4">
          <h5 class="font-semibold print:text-print -my-4">
            {{ $t('Issued Billings') }}
          </h5>
          <BillingsTable
            :hideAllActions="true"
            :billingStatus="resourceStatuses.All"
            :urlParams="{
              recurring_billing_id: billing.id,
              customer_id: billing?.customer_id,
              _cache_key: issuedBillingsKey
            }"
          />
        </div>

      </template>
    </InvoiceDetailsPage>

    <BaseBackLink
      v-if="showBack"
      to="/accounts-receivable/recurring-billings/lump-sum"
    />
  </div>
</template>

<script>
import InvoiceDetails from '@/modules/accounts-payable/pages/invoices/InvoiceDetails'
import CompanyContactInfo from "@/modules/accounts-payable/components/invoice/CompanyContactInfo";
import InvoiceDetailsPage from "@/components/invoice/InvoiceDetailsPage";
import InvoiceDescription from "@/modules/accounts-payable/components/invoice/InvoiceDescription.vue";
import ReviewStatus from "@/components/links/ReviewStatus.vue";
import RRuleText from "@/modules/accounts-payable/components/recurring-invoice/RRuleText.vue";
import RecurringScheduleTable from "@/modules/accounts-payable/components/recurring-invoice/RecurringScheduleTable.vue";
import CustomerContactInfo from '@/modules/accounts-receivable/components/CustomerContactInfo.vue';
import BillingsTable from '@/modules/accounts-receivable/pages/billings/BillingsTable.vue'
import { resourceStatuses } from "@/enum/enums";
import axios from 'axios'
import { capitalize } from 'lodash'

export default {
  props: {
    id: [String, Number],
    showBack: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    BillingsTable,
    RecurringScheduleTable,
    RRuleText,
    ReviewStatus,
    InvoiceDescription,
    InvoiceDetailsPage,
    CompanyContactInfo,
    InvoiceDetails,
    CustomerContactInfo,
  },
  data() {
    return {
      resourceStatuses,
      showScheduleTable: false,
      customerContacts: [],
      issuedBillingsKey: 1,
    }
  },
  computed: {
    allTemplates() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.PrintTemplates)
    },
    templateName() {
      return this.allTemplates.find(template => template.id === this.billing.sending_options.template_id)?.name
    },
    originalBilling() {
      return this.$store.state.accountsReceivable.currentRecurringBilling || {
        attributes: {},
        relationships: {},
        meta: {},
      }
    },
    billingLoading() {
      return this.$store.state.accountsReceivable.recurringBillingLoading
    },
    isActiveOrInactive() {
      const status = this.originalBilling?.attributes?.status
      return [resourceStatuses.Active, resourceStatuses.Inactive].includes(status)
    },
    showApproveStatus() {
      const status = this.originalBilling?.attributes?.status
      return [resourceStatuses.Pending].includes(status)
    },
    billing() {
      return {
        ...this.originalBilling?.attributes || {},
        ...this.originalBilling?.relationships || {},
      }
    },
    customer() {
      const customerData = this.originalBilling?.relationships?.customer || { attributes: {}, relationships: {} }
      return {
        ...customerData?.attributes,
        ...customerData?.relationships,
      }
    },
    billingRecipientIds() {
      return this.billing.recipients || []
    },
    billingRecipients() {
      return this.customerContacts
        .filter(contact => this.billingRecipientIds.includes(contact.id))
    },
    recipientLabels() {
      return this.billingRecipients.map(recipient => {
        const {
          name,
          email
        } = recipient.attributes
        return `${name} (${email})`
      })
    },
    sendOptionsLabels() {
      const opts = this.billing.sending_options.options
      const labels = [
        `Include a PDF version of the billing: ${opts.include_pdf ? 'Yes' : 'No'}`,
        `Include Attachments: ${opts.include_attachments ? 'Yes' : 'No'}`,
      ]
      if (opts.send_a_copy) {
        labels.push(`Send a copy to: ${opts.bcc_email}`)
      }

      return labels
    },
    printOptionsLabels() {
      const flags = Object.keys(this.billing.sending_options.flags || {})
      const labels = []
      for (let key of flags) {
        const optionLabel = key.split('_').map(capitalize).join(' ')
        labels.push(`${optionLabel}: ${this.billing.sending_options.flags[key] ? 'Yes' : 'No'}`)
      }

      return labels
    }
  },
  methods: {
    async getRecipients() {
      if (!this.customer?.id) {
        return
      }

      const { data } = await axios.get(`/restify/customers/${this.customer?.id}`, {
        params: {
          related: 'contacts',
        }
      })

      this.customerContacts = data.relationships?.contacts || []
    }
  },
  watch: {
    billing: {
      handler(value) {
        if (!value.send_automatically) {
          return
        }

        this.getRecipients()
      },
      immediate: true,
    },
    billingLoading(value) {
      if (!value) {
        this.issuedBillingsKey++
      }
    }
  }
}
</script>
