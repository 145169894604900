<template>
  <entity-preview
    v-bind="$attrs"
    :entity="repairOrderData"
    :key="`preview-${repairOrderData.id}`"
    :url="`/equipment/repair-orders/${repairOrderData.id}/view`"
    :value-to-display="valueToDisplay"
    :parent-entity-id="repairOrderData.id"
    :show-preview="showPreview"
    :target="target"
    :is-link="!editableCell"
    entity-name="repairOrder"
  />
</template>
<script>
import axios from 'axios'
export default {
  name: 'RepairOrderLink',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
    target: String,
    editableCell: Boolean,
  },
  data() {
    return {
      loading: false,
      repairOrder: null,
    }
  },
  computed: {
    repairOrderData() {
      return this.repairOrder?.attributes || this.data?.attributes || this.data || {}
    },
    valueToDisplay() {
      const prefix = this.$t('RO #')
      const number = this.repairOrderData.number || '...'
      return prefix ? prefix + number : number
    },
  },
  methods: {
    async loadData() {
      if (!this.repairOrderData?.id || this.repairOrderData?.number) {
        return
      }

      try {
        this.loading = true
        const { data } = await axios.get(`/restify/repair-orders/${this.repairOrderData.id}`, {
          params: {
            ignore404: true,
          }
        })
        this.repairOrder = data
      }
      finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>
