<template>
  <base-data-table
      v-bind="defaultAttributes"
      :columns="columns"
      :data="getData"
      :total-rows="rowsLength"
      @force-pagination="forcePagination"
  >

    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{row}">
      <div class="flex items-center">
        <VendorLink
            :data="row.header"
            :show-preview="false"
            class="pr-24 w-[400px]"
        />
        <span v-if="row.header?.last_payment_date">
          {{ $t('Last Payment Date:') }}
          {{ $formatDate(row.header.last_payment_date) }}
        </span>
      </div>
    </template>

    <template #invoice.number="{row}">
      <div class="flex items-center space-x-1">
        <InvoiceLink :id="row.invoice?.id" :data="row.invoice"/>
        <InvoiceStatusPopover :invoice="{id: row.invoice?.id, attributes: row.invoice}"/>
      </div>
    </template>

    <template #total="{row}">
      {{ $formatPrice(row.totals.open_amount) }}
    </template>

    <template #subtotal="{subtotal}">
      <td/>
      <td colspan="4">
        <div class="flex items-center">
          <span class="py-2">{{ $t('Vendor Total') }}</span>
          <template v-if="subtotal.retention_payable">
            <span class="px-10">({{ $t('Retention Payable:') }}</span>
            <span>{{ $formatPrice(subtotal.retention_payable) }})</span>
          </template>
        </div>
      </td>
      <td v-if="actualDays"/>
      <td v-for="column in amountColumns"
          align="right"
      >
        <span :class="{'top-border flex flex-1 justify-end': format !== agedByViewFormats.VendorTotal}">
          {{ $formatPrice(get(subtotal, column.prop, 0)) }}
        </span>
      </td>
    </template>

  </base-data-table>
</template>
<script>
  import { agedByColumns } from '@/modules/common/components/reports/util'
  import { agedByViewFormats } from '@/modules/accounts-payable/components/reports/util'
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
  import InvoiceLink from "@/components/links/InvoiceLink.vue";

  const module = 'Payable'

  export default {
    components: { InvoiceLink },
    extends: ReportTableWrapper,
    props: {
      format: {
        required: true,
        type: String,
        default: agedByViewFormats.EachInvoice,
      },
      days: {
        required: true,
        type: Array,
        default: () => [30, 60, 90],
      },
      actualDays: {
        type: [Boolean, String],
        default: false,
      },
    },
    data() {
      return {
        agedByViewFormats,
      }
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Invoice'),
            prop: 'invoice.number',
            component: 'EntityLink',
            redirectTo: '/accounts-payable/invoices/{ID}/view',
            entityKey: 'invoice.id',
            minWidth: 170,
            maxWidth: 250,
          },
          {
            label: this.$t('Description'),
            prop: 'invoice.description',
            minWidth: 120,
            maxWidth: 150,
          },
          {
            label: this.$t('Invoice <br> Date'),
            prop: 'invoice.date',
            component: 'FormattedDate',
            minWidth: 90,
            maxWidth: 90,
          },
          {
            label: this.$t('Due <br> Date'),
            prop: 'invoice.due_date',
            component: 'FormattedDate',
            minWidth: 90,
            maxWidth: 90,
            summary: () => `${this.$t('Total Selected Vendors')}<br><br>&nbsp;`,
          },
          {
            label: this.$t('Discount <br> Date'),
            prop: 'invoice.discount_date',
            component: 'FormattedDate',
            minWidth: 90,
            maxWidth: 90,
          },
        ]

        if (!this.data.aging_totals) {
          return columns
        }

        const agingColumns = agedByColumns(this.days, this.data.aging_totals, this.data.grand_totals, module, this.actualDays)

        return [
          ...columns,
          ...agingColumns,
        ]
      },
    },
    methods: {
      composeRows(data) {

        data.forEach(group => {

          const { vendor } = group

          this.rows.push({
            header: vendor,
          })

          if (this.format !== agedByViewFormats.VendorTotal) {
            this.rows.push(...group.invoices)
          }

          this.rows.push({
            subtotal: {
              aging_totals: group.aging_totals,
              retention_amount: group.totals?.retention_amount,
              retention_payable: group.totals?.retention_payable,
              total: group.totals?.open_amount,
            },
          })

        })

        return this.rows
      },
    },
  }
</script>
