<template>
  <div>
    <BaseAlert
      :type="$promptType.Info"
      :closable="true"
      class="mb-4"
    >
      <p>
        {{ $t(`This screen can be used to set unscheduled task and special items visibility in the timesheets.`) }}
      </p>
    </BaseAlert>
    <AgDataTable
      v-bind="tableProps"
      :key="url"
      :url="url"
      :url-params="urlParams"
      :columns="columns"
      default-sort="-updated_at"
      actions="search,refresh"
      :entity="selectedEntity"
      class="-mt-4"
    >
      <template #additional-actions-before>
        <slot name="actions" />
        <div class="min-w-[350px]">
          <BaseSelect
            v-model="selectedType"
            :label="$t('Type')"
            :placeholder="$t('Type')"
            :add-entity="false"
            :options="typeOptions"
            :inline-errors="true"
          />
        </div>
      </template>
      <template #name="{row}">
        <JobLink v-if="selectedType === TaskType.Job" :data="row" :label="row.attributes.number"/>
        <EquipmentLink v-else-if="selectedType === TaskType.Equipment" :data="row"/>
        <MaterialLink v-else-if="selectedType === TaskType.Material" :data="row"/>
        <WorkOrderLink v-else-if="selectedType === TaskType.WorkOrder" :data="row"/>
        <PayCodeLink v-else-if="selectedType === TaskType.PayCode" :data="row" :id="row.id"/>
      </template>
    </AgDataTable>

  </div>
</template>
<script>
import { editableTableProps } from "@/components/ag-grid/tableUtils";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { costTypes } from "@/enum/enums";

const TaskType = {
  Job: 'job',
  LineItem: 'line_item',
  Equipment: 'equipment',
  WorkOrder: 'work_order',
  Material: 'material',
  PayCode: 'pay_code',
  CraftCode: 'craft_code',
}


export default {
  data() {
    return {
      tableProps: {
        ...editableTableProps,
        enableFillHandle: true,
        authorizeToCopyLastRow: false,
        showPagination: true,
      },
      selectedType: TaskType.Job,
      TaskType,
    }
  },
  computed: {
    url() {
      const urlMap = {
        [TaskType.Job]: '/restify/jobs',
        [TaskType.LineItem]: '/restify/line-items',
        [TaskType.Equipment]: '/restify/equipment',
        [TaskType.Material]: '/restify/materials',
        [TaskType.WorkOrder]: '/restify/work-orders',
        [TaskType.PayCode]: '/restify/pay-codes',
        [TaskType.CraftCode]: '/restify/craft-codes',
      }
      return urlMap[this.selectedType]
    },
    urlParams() {
      if (this.selectedType === TaskType.LineItem) {
        return {
          type: costTypes.Cost,
        }
      }
      if (this.selectedType === TaskType.CraftCode) {
        return {
          active: true,
        }
      }

      return {}
    },
    selectedEntity() {
      const entityMap = {
        [TaskType.Job]: 'job',
        [TaskType.LineItem]: 'lineItem',
        [TaskType.Equipment]: 'equipment',
        [TaskType.Material]: 'material',
        [TaskType.WorkOrder]: 'workOrder',
        [TaskType.PayCode]: 'payCode',
        [TaskType.CraftCode]: 'craftCode',
      }
      return entityMap[this.selectedType]
    },
    typeOptions() {
      const options = [
        {
          label: this.$t('Jobs'),
          value: TaskType.Job,
          enabled: () => this.$can('jobs_update'),
        },
        {
          label: this.$t('Line Items'),
          value: TaskType.LineItem,
          enabled: () => this.$can('line_items_update'),
        },
        {
          label: this.$t('Work Orders'),
          value: TaskType.WorkOrder,
          enabled: () => this.$can('work_orders_update'),
        },
        {
          label: this.$t('Equipment'),
          value: TaskType.Equipment,
          enabled: () => this.$can('equipment_update'),
        },
        {
          label: this.$t('Materials'),
          value: TaskType.Material,
          enabled: () => this.$can('materials_update'),
        },
        {
          label: this.$t('Pay Codes'),
          value: TaskType.PayCode,
          enabled: () => this.$can('payroll_settings'),
        },
        {
          label: this.$t('Craft Codes'),
          value: TaskType.CraftCode,
          enabled: () => this.$can('payroll_settings'),
        },
      ]
      return options.filter(option => option.enabled())
    },
    columns() {
      return [
        {
          headerName: this.$t('Name'),
          field: 'name',
          minWidth: 80,
          maxWidth: 320,
          initialHide: [TaskType.LineItem, TaskType.CraftCode].includes(this.selectedType),
        },
        {
          label: this.$t('Phase Code'),
          prop: 'attributes.phase_code',
          minWidth: 45,
          maxWidth: 70,
          initialHide: this.selectedType !== TaskType.LineItem,
        },
        {
          label: this.$t('Cost Code'),
          prop: 'attributes.cost_code',
          minWidth: 40,
          maxWidth: 70,
          initialHide: this.selectedType !== TaskType.LineItem,
        },
        {
          label: this.$t('Change Order'),
          prop: 'attributes.change_order',
          minWidth: 40,
          maxWidth: 70,
          align: 'center',
          initialHide: this.selectedType !== TaskType.LineItem,
        },
        {
          label: this.$t('Code'),
          prop: 'attributes.code',
          minWidth: 40,
          maxWidth: 70,
          align: 'center',
          initialHide: this.selectedType !== TaskType.CraftCode,
        },
        {
          label: this.$t('Level'),
          prop: 'attributes.level',
          minWidth: 40,
          maxWidth: 70,
          align: 'center',
          initialHide: this.selectedType !== TaskType.CraftCode,
        },
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          minWidth: 150,
          maxWidth: 350,
        },
        {
          label: this.$t('Job'),
          prop: 'attributes.job_id',
          minWidth: 100,
          maxWidth: 250,
          component: 'JobLink',
          initialHide: this.selectedType !== TaskType.LineItem,
        },
        {
          headerName: this.$t('Show in Timesheets'),
          field: 'attributes.available_in_timesheets',
          editable: true,
          component: 'Status',
          headerClass: cellClasses.HeaderEditable,
          cellEditor: cellEditors.Boolean,
          valueGetter: params => {
            return !!params.data?.attributes?.available_in_timesheets
          },
          valueSetter: (params) => {
            if (params.newValue === 'false') {
              params.newValue = false
            }
            if (params.newValue === 'true') {
              params.newValue = true
            }
            if (params.newValue !== true && params.newValue !== false) {
              return false
            }
            return this.updateRow(params)
          },
        },
        {
          headerName: this.$t('Show in Timecards'),
          field: 'attributes.available_in_timecards',
          editable: true,
          component: 'Status',
          headerClass: cellClasses.HeaderEditable,
          cellEditor: cellEditors.Boolean,
          hide: this.selectedType !== TaskType.PayCode && this.selectedType !== TaskType.CraftCode,
          valueGetter: params => {
            return !!params.data?.attributes?.available_in_timecards
          },
          valueSetter: (params) => {
            if (params.newValue !== true && params.newValue !== false) {
              return false
            }
            return this.updateRow(params)
          },
        }
      ]
    },
  },
  methods: {
    updateRow(params) {
      const actionMap = {
        [TaskType.Job]: 'jobCosting/patchJob',
        [TaskType.LineItem]: 'jobCosting/patchLineItem',
        [TaskType.Equipment]: 'equipmentManagement/patchEquipment',
        [TaskType.Material]: 'inventoryManagement/patchMaterial',
        [TaskType.WorkOrder]: 'serviceBilling/patchWorkOrder',
        [TaskType.PayCode]: 'payroll/patchPayCode',
        [TaskType.CraftCode]: 'payroll/patchCraftCode',
      }
      const storeAction = actionMap[this.selectedType]
      return valueSetterWithUpdate({
        storeAction,
      })(params)
    }
  }
}
</script>
