<template>
  <BaseTooltip
    v-if="recurringBillingId"
    :content="$t('This billing was created based on a recurring receivable')"
  >
    <RouterLink :to="path">
      <RecurringInvoiceIcon class="w-5 h-5"/>
    </RouterLink>
  </BaseTooltip>
</template>
<script>
import { billingTypesAbbr } from "@/enum/enums";
import RecurringInvoiceIcon from "@/modules/accounts-payable/pages/invoices/RecurringInvoiceIcon.vue";

export default {
  components: {
    RecurringInvoiceIcon
  },
  props: {
    billing: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    recurringBillingId() {
      return this.billing?.attributes?.recurring_billing_id
    },
    path() {
      if (!this.recurringBillingId) {
        return ''
      }

      if (this.billing?.attributes?.type === billingTypesAbbr.LumpSum) {
        return `/accounts-receivable/recurring-billings/lump-sum/${this.recurringBillingId}/view`
      }
      return `/service-billing/recurring-service-invoices/${this.recurringBillingId}/view`
    }
  }
}
</script>
