<template>
  <base-button
    variant="gray-light"
    title="Restore"
    :tabindex="skipFocus ? -1 : 0"
    :class="{
     'hidden md:flex': hideOnMobile,
     'flex': !hideOnMobile,
   }"
    size="icon"
    class="table-action-button hidden md:flex"
    data-test="button-row-archive"
    type="button"
    @click="$emit('click')">
    <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
    </svg>
  </base-button>
</template>
<script>

export default {
  name: 'TableRestoreButton',
  props: {
    skipFocus: {
      type: Boolean,
      default: false,
    },
    hideOnMobile: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
