import get from "lodash/get";
import i18n from "@/i18n";
import { isProduction } from "@/isProduction";

const dateRangePicker = {
  component: 'base-date-picker',
  attrs: {
    type: 'daterange',
  }
}

const accountFilter = {
  component: 'account-select',
  attrs: {
    valueKey: 'number',
  }
}

const bankFilter = {
  component: 'bank-select',
  attrs: {
    editEntity: false,
    clearable: true,
    useDefaultBank: false,
  }
}


const subAccountFilter = {
  component: 'sub-account-select',
  attrs: {
    valueKey: 'number',
  }
}

const entityMappings = {
  account: accountFilter,
  accounts: accountFilter,
  expense_account: accountFilter,
  liability_account: accountFilter,
  bank_id: bankFilter,
  account_id: {
    component: 'account-select',
  },
  'equipment-classes': {
    component: 'equipment-class-select',
  },
  'equipment-specialties': {
    component: 'equipment-specialty-select',
  },
  'purchase-orders': {
    component: 'purchase-order-select'
  },
  'work-orders': {
    component: 'work-order-select'
  },
  materials: {
    component: 'material-select'
  },
  equipment: {
    component: 'equipment-select'
  },
  asset_type: {
    component: 'equipment-asset-type-select',
  },
  'document-types': {
    component: 'document-type-select',
  },
  crews: {
    component: 'crew-select',
  },
  companies: {
    component: 'company-select'
  },
  subaccount: subAccountFilter,
  subaccounts: subAccountFilter,
  fiscalYears: {
    component: 'fiscal-year-select'
  },
  users: {
    component: 'user-select'
  },
  vendors: {
    component: 'vendor-select'
  },
  customers: {
    component: 'customer-select'
  },
  journals: {
    component: 'journal-select'
  },
  jobs: {
    component: 'job-select'
  },
  employees: {
    component: 'employee-select'
  },
  employee_id: {
    component: 'employee-select'
  },
  unions: {
    component: 'union-select'
  },
  departments: {
    component: 'department-select',
  },
  'craft-codes': {
    component: 'craft-code-select',
  },
  'state-tax-jurisdictions': {
    component: 'state-tax-select',
  },
  'gen-liability-rates': {
    component: 'gen-liability-rate-select',
  },
  'time-off-policies': {
    component: 'time-off-policy-select',
  },
  'workers-comp-rates': {
    component: 'worker-comp-rate-select',
  },
  'timecard-batches': {
    component: 'timecard-batch-select',
  },
  'payroll-batches': {
    component: 'payroll-batch-select',
  },
  'payrolls': {
    component: 'payroll-select',
  },
  'timecards': {
    component: 'timecard-select',
  },
  'local-tax-jurisdictions': {
    component: 'local-tax-select',
  },
  fiscal_year: {
    component: 'fiscal-year-select',
    attrs: {
      fullWidth: true,
      showDefaultFiscalYear: false,
      showClosedYears: true,
    }
  },
  period: {
    component: 'period-select',
    attrs: {
      showDefaultPeriod: false,
      placeholder: i18n.t('Period')
    }
  },
  start_date: dateRangePicker,
  expiration_date: dateRangePicker
}

function getAttrs(entity, type, column, filter) {
  const inputOptions = {
    component: 'base-input',
    attrs: {
      type: 'text',
      clearable: true,
      placeholder: filter.placeholder,
    }
  }
  if (['integer', 'int'].includes(type)) {
    inputOptions.attrs.type = 'number'
    inputOptions.attrs.min = 0
  }
  if (type === 'datetime' || type === 'date') {
    inputOptions.attrs.type = 'date'
  }

  if (typeof type === 'object' || type === 'boolean' || type === 'bool') {
    inputOptions.component = 'boolean-filter'
    inputOptions.attrs = {}
    return inputOptions
  }

  if (type === 'multiselect' || Array.isArray(filter.options)) {
    inputOptions.component = 'base-select'
    inputOptions.attrs = {
      multiple: type === 'multiselect',
      clearable: true,
      valueKey: 'value',
      labelKey: 'label',
      options: filter.options.map(o => {
        return {
          value: o.property,
          label: o.label
        }
      })
    }
  }
  let entityProps = entityMappings[entity || column]
  const entityExceptions = [
    'fiscal_year',
    'period',
    'account',
    'subaccount',
    'account_id',
    'bank_id'
  ]

  if (entityExceptions.includes(column)) {
    entityProps = entityMappings[column]
  }

  if (entityProps) {
    const initialAttrs = entityProps.attrs
    const placeholder = column.includes('sales_person') ? i18n.t('Select Salesperson') : ''

    entityProps.attrs = {
      clearable: true,
      multiple: type === 'array',
      label: '',
      ...initialAttrs,
      placeholder,
    }
  }
  return entityProps || inputOptions || {}
}

export function getFilterConfig(filterOptions) {
  if (!filterOptions) {
    return []
  }
  return filterOptions
    .map(f => {
      let url = get(f, 'repository.url', '')
      let entity = get(f, 'repository.key', '')
      let displayKey = get(f, 'repository.display_key', '')
      url = url.replace('/api', '')
      return {
        filterName: f.title,
        url: url,
        valueKey: f.column,
        labelKey: displayKey,
        hidden: f.hidden,
        advanced: f.advanced,
        key: f.key,
        ...getAttrs(entity, f.type, f.column, f)
      }
    })
    .filter(f => {
      const exceptions = ['id']
      if (isProduction()) {
        exceptions.push(...['account', 'subaccount'])
      }
      if (!f.valueKey) {
        return false
      }
      return Object.keys(f).length > 0 && !exceptions.includes(f.valueKey)
    })
}
