<template>
  <div class="grid grid-cols-6 gap-x-3 mt-4 time-card-batches">
    <div class="col-span-6 md:col-span-1">
      <BaseSelect
        v-if="!isSelectedBatch"
        v-model="value.year"
        :options="availableYears"
        :label="$t('Calendar Year')"
        :name="$t('Calendar Year')"
        :tip="$t('The calendar year based on which the payroll taxes are calculated')"
        id="year"
        rules="required"
        @change="value.period_end_date = null"
      />
      <BaseInput v-else
                 v-model="value.year"
                 :label="$t('Year')"
                 :name="$t('Year')"
                 :disabled="true"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <PeriodEndDatePicker
        v-focus
        v-model="value.period_end_date"
        :disabled="isSelectedBatch && !isPendingBatch"
        :year="value.year"
        :showPendingBatchWarning="!$route.params.id"
        @change="changePeriodEndDate"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <PayrollPaymentDatePicker
        v-model="value.payment_date"
        :period-end-date="value.period_end_date"
        :disabled="isSelectedBatch && !isPendingBatch"
        :year="value.year"
        @year-change="value.year = $event"
      />
    </div>
    <div class="col-span-6 md:col-span-2">
      <BaseInput
        v-model="value.description"
        :label="$t('Name')"
        :placeholder="$t('Batch name (optional)')"
        :name="$t('Name')"
      />
    </div>
    <div class="col-span-6"></div>
    <div class="col-span-6 md:col-span-1">
      <BaseSelect
        v-model="value.pay_frequency"
        :options="payFrequencyOptions"
        :label="$t('Employee pay frequency')"
        :placeholder="$t('Employee pay frequency')"
        multiple
        @change="onChangePayFrequencies"
      />
    </div>
    <div class="col-span-6 md:col-span-1">
      <BaseCheckbox v-model="value.include_salaried"
                     :vertical="true"
                     id="include_salaried"
                     class="mr-4"
      >
        <template #label>
          <span class="flex items-center">
            {{ $t('Show salary employees') }}
            <BaseTooltip :content="$t('Display salary employees')">
              <IconInfo class="ml-2 cursor-help"/>
            </BaseTooltip>
          </span>
        </template>
      </BaseCheckbox>
    </div>
    <div class="col-span-6 -mt-6">
      <AgDataTable
        :columns="columns"
        :url-params="urlParams"
        :selected-rows.sync="selectedRows"
        :is-row-selectable="isRowSelectable"
        actions="search,refresh"
        class="p-3"
        url="/restify/employees"
        domLayout="autoHeight"
        ref="table"
      >
        <template #additional-actions-before="{selectedRows}">
          <span class="text-gray-600" v-if="selectedRows.length">
            {{ $tc('employees selected', selectedRows.length) }}
          </span>
        </template>
        <template #attributes.code="{row}">
          <div class="flex items-center">
            <EntityPreview
              :entity="row.attributes"
              :key="`preview-${row.id}`"
              :addresses="get(row, 'relationships.addresses', [])"
              :url="`/payroll/employees/${row.id}/view`"
              :value-to-display="get(row, 'attributes.code')"
              :parent-entity-id="row.id"
              entity-name="employee"
            />
            <BaseTooltip
              v-if="!isRowSelectable({ data: row })">
              <HelpCircleIcon class="w-4 h-4 ml-2 text-gray-500"/>
              <template #content>
                <div>
                  <div v-if="employeeHasTimeCard(row)">
                    <div>
                      {{ $t('This employee has an associated time card in the current batch.') }}
                    </div>
                    <div>
                      {{ $t('Selecting the employee will be disabled unless the time card is removed.') }}
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      {{ $t('This employee is already present in another timecard.') }}
                    </div>
                    <div>{{
                        $t('Selecting the employee will be disabled unless the employee is removed from the other timecard, the timecard is posted or deleted.')
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </BaseTooltip>
          </div>
        </template>
        <template #extra-actions="{row}">
          <RouterLink :to="`/payroll/employees/${row.id}/view`">
            <TableViewButton/>
          </RouterLink>
        </template>
      </AgDataTable>
    </div>
    <BaseFormDialog
      v-if="showPeriodEndDateErrorDialog"
      :open.sync="showPeriodEndDateErrorDialog"
      closeable
    >
      <BaseForm layout="modal"
                :show-buttons="false">
        <div class="col-span-6">
          <div
            v-if="periodEndDateError.message"
            class="font-medium text-base mb-4 text-red-700 my-2">
            <div>{{ periodEndDateError.message }}</div>
            <div class="text-gray-700 mt-2">
              <div>{{$t('Old Interval: ')}} {{$formatDate(batchStartDate)}} - {{$formatDate(batch?.period_end_date)}}</div>
              <div>{{$t('New Interval: ')}} {{$formatDate(periodEndDateError?.period_start_date)}} - {{$formatDate(periodEndDateError?.period_end_date)}}</div>
            </div>
          </div>
          <div v-if="periodEndDateError.errors" class="space-y-1 mb-4">
            <span class="font-medium text-base text-gray-700 mt-6 mb-2">
              {{$t('Timecards outside the new interval')}}
            </span>
            <div
              v-for="(row, index) in periodEndDateError.errors"
              :key="index"
              class="text-gray-700 text-base"
            >
              <div v-for="entry in row.entries">
                <div class="flex justify-between mt-2">
                  <div class="flex space-x-">
                    <EmployeeLink :data="row.employee" class="w-[240px] truncate"/>
                    <span>{{ $formatDate(entry.date) }}</span>
                  </div>
                  <div>
                    <TableEditButton @click="goToTimeCard(entry.timecard_id)"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import { employeeStatuses, payFrequencies } from '@/enum/enums'
import TableViewButton from '@/components/table/actions/TableViewButton'
import PeriodEndDatePicker from "@/modules/payroll/components/timecard/PeriodEndDatePicker";
import TableSelections from "@/components/ag-grid/TableSelections";
import { HelpCircleIcon } from 'vue-feather-icons'
import axios from "axios";
import { getTimeCardBatchStartDate } from "@/modules/payroll/utils/timeCardUtils";
import PayrollPaymentDatePicker from "@/modules/payroll/components/timecard/PayrollPaymentDatePicker.vue";

export default {
  inheritAttrs: false,
  components: {
    PayrollPaymentDatePicker,
    PeriodEndDatePicker,
    TableViewButton,
    TableSelections,
    HelpCircleIcon,
  },
  props: {
    isSelectedBatch: {
      type: Boolean,
      default: false,
    },
    isPendingBatch: {
      type: Boolean,
      default: false,
    },
    batch: {
      type: Object,
    },
    value: {
      type: Object,
      default: () => ({
        period_end_date: null,
        payment_date: null,
        year: null,
        pay_frequency: [],
        include_salaried: false,
        selectedEmployees: [],
        description: '',
      }),
    },
  },
  data() {
    return {
      selectedRows: [],
      payFrequencyOptions: [
        {
          label: this.$t('Weekly'),
          value: payFrequencies.WEEKLY,
        },
        {
          label: this.$t('Bi-Weekly'),
          value: payFrequencies.BI_WEEKLY,
        },
        {
          label: this.$t('Monthly'),
          value: payFrequencies.MONTHLY,
        },
        {
          label: this.$t('Semi Monthly'),
          value: payFrequencies.SEMI_MONTHLY,
        },
      ],
      columns: [
        {
          label: this.$t('Code'),
          prop: 'attributes.code',
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          label: this.$t('Name'),
          prop: 'attributes.name',
        },
        {
          label: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
        },
        {
          label: this.$t('Pay Frequency'),
          prop: 'attributes.pay_frequency',
          component: 'Status',
        },
      ],
      showPeriodEndDateErrorDialog: false,
      periodEndDateError: {},
    }
  },
  computed: {
    payrollCalendarYear() {
      const currentYear = new Date().getFullYear()
      return this.$settings(this.$modules.PR, 'calendar_year') || currentYear
    },
    availableYears() {
      return [
        {
          label: this.payrollCalendarYear,
          value: this.payrollCalendarYear,
        },
        {
          label: this.payrollCalendarYear - 1,
          value: this.payrollCalendarYear - 1,
        },
      ]
    },
    urlParams() {
      let status = employeeStatuses.HOURLY
      if (this.value.include_salaried) {
        status = `${employeeStatuses.HOURLY},${employeeStatuses.SALARY}`
      }
      const pay_frequency = this.value.pay_frequency ? this.value.pay_frequency.join(',') : undefined
      const params = {
        pay_frequency,
        status,
        sort: 'code',
        related: 'computableTimecards'
      }
      return params
    },
    payroll_period_start_day() {
      return this.$settings(this.$modules.PR, 'payroll_period_start_day')
    },
    batchStartDate() {
      return getTimeCardBatchStartDate(this.batch.period_end_date)
    },
  },
  methods: {
    onChangePayFrequencies(frequencies) {
      this.value.include_salaried = !!frequencies.length
    },
    mapInitialSelections(employees) {
      if (employees.length !== this.selectedRows.length && employees.length) {
        this.selectedRows = cloneDeep(employees)
      }
    },
    isRowSelectable(params) {
      let employeeTimeCards = params.data?.relationships?.computableTimecards || []
      return employeeTimeCards.length === 0
    },
    employeeHasTimeCard(row) {
      let employeeTimeCards = row.relationships.computableTimecards || []
      employeeTimeCards = employeeTimeCards.map(t => t?.attributes?.timecard_batch_id)
      const timeCardBatchId = this.$route.params.id
      return employeeTimeCards.includes(timeCardBatchId)
    },
    async changePeriodEndDate(value) {
      const batchId = this.value?.id
      if (!batchId) {
        return
      }
      const oldValue = this.batch?.period_end_date
      try {
        await axios.post(`/restify/timecard-batches/${batchId}/actions?action=change-period-end-date`, {
          period_end_date: value,
        })
      } catch (err) {
        if (err.handled) {
          return
        }
        this.value.period_end_date = oldValue
        this.periodEndDateError = err?.response?.data?.data || {}
        this.showPeriodEndDateErrorDialog = true
      }
    },
    async goToTimeCard(timeCardId) {
      const batchId = this.value?.id
      await this.$router.push(`/payroll/timecard-batches/${batchId}/timecards/${timeCardId}/edit`)
    }
  },
  watch: {
    selectedRows() {
      if (this.value.selectedEmployees?.length && !this.selectedRows.length) {
        this.mapInitialSelections(this.value.selectedEmployees)
        return
      }
      this.value.selectedEmployees = this.selectedRows
    },
    'value.selectedEmployees': {
      immediate: true,
      handler(employees) {
        this.mapInitialSelections(employees)
      }
    }
  }
}
</script>
