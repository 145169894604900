<template>
  <JobTimesheetsLayout>
    <DailyReportsTable :job-id="$route.params.id"/>
  </JobTimesheetsLayout>
</template>
<script>
import TimesheetEntriesTable from "@/modules/payroll/components/timesheets/TimesheetEntriesTable.vue";
import JobTimesheetsLayout from "@/modules/job-costing/layout/JobTimesheetsLayout.vue";
import DailyReportsTable from "@/modules/payroll/components/daily-reports/DailyReportsTable.vue";

export default {
  components: {
    DailyReportsTable,
    JobTimesheetsLayout,
    TimesheetEntriesTable,
  },
}
</script>
