<template>
  <MaintenanceSelect
    v-focus
    v-model="selectedValue"
    :equipment_id="equipmentId"
    :type_id="typeId"
    :filterAvailableInTimesheets="filterAvailableInTimesheets"
    :active="active"
    :sourceType.sync="sourceType"
    class="ag-cell-editor w-full"
    @change="onChange"
    @entity-change="onFullValueChanged"
    @keydown.enter.native="goToNextCell"
  />
</template>
<script>
import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
import { set } from 'lodash'
import { costCenterTypes, equipmentCostAddlSources } from "@/components/grid-table/utils/cost-center";
import {
  sourceTypes,
} from "@/modules/equipment/util/repairOrderUtils";

export default {
  name: 'MaintenanceCell',
  mixins: [selectEditorMixin],
  computed: {
    sourceType: {
      get() {
        return this.params?.data?.addl_source_type
      },
      set(value) {
        set(this.params.data, 'addl_source_type', value)
        this.params.node.setData(this.params.data)
      }
    },
    equipmentId() {
      if (this.params?.data?.equipment_id) {
        return this.params?.data?.equipment_id
      }

      if (this.params?.data?.source_type === 'equipment' || this.params?.data?.cost_center === costCenterTypes.Equipment) {
        return this.params?.data?.source_id
      }

      return null
    },
    canInjectCraftCode() {
      return this.params?.canInjectCraftCode
    },
    typeId() {
      return this.params?.type_id
    },
    active() {
      return this.params?.active
    },
    filterAvailableInTimesheets() {
      return this.params?.available_in_timesheets
    },
    equipmentCostTypes() {
      return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.EquipmentTypes) || []
    },
    craftCodes() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.CraftCodes)
    },
  },
  methods: {
    getRoeDescription(fullValue) {

      if (this.sourceType === equipmentCostAddlSources.MaintenanceCode) {
        return ''
      }

      const ro_number = this.get(fullValue, 'repairOrder.attributes.number')
      const { type_id, description } = fullValue
      const costType = this.equipmentCostTypes.find(t => t.id === type_id)

      return `RO#${ro_number} ${costType?.name || ''}  - ${description || ''}`
    },
    async onFullValueChanged(value) {
      const meta = this.params.data?.meta
      if (Array.isArray(meta)) {
        set(this.params.data, 'meta', {})
      }

      set(this.params.data, 'meta.repair_order_id', value.repair_order_id)
      set(this.params.data, 'meta.roe_description', value.roe_description)

      this.params.node.setData(this.params.data)

      if (!this.canInjectCraftCode) {
        return
      }

      const { addl_source_type, addl_source_id } = value
      const current_craft_code_id = this.params.data.craft_code_id

      if (addl_source_type !== sourceTypes.Labor
        || !addl_source_id
        || current_craft_code_id === addl_source_id) {
        return
      }

      const currentCraftCode = this.craftCodes.find(code => code.id === this.params.data.craft_code_id)
      const craftCode = this.craftCodes.find(code => code.id === addl_source_id)
      const craftCodeDescription = craftCode?.level
        ? `${craftCode.code} - ${craftCode.level}`
        : craftCode?.code || ''

      let description = this.$t(`The selected Repair Order Entry uses Craft Code <span class="font-semibold whitespace-nowrap">${craftCodeDescription}</span>.<br>Use the same Craft Code for this Timecard Entry?`)

      if (currentCraftCode) {
        const currentCraftCodeDescription = currentCraftCode?.level
          ? `${currentCraftCode.code} - ${currentCraftCode.level}`
          : currentCraftCode?.code || ''

        description += this.$t(`<br>This will override the current Craft Code <span class="font-semibold whitespace-nowrap">${currentCraftCodeDescription}</span>.`)
      }

      const confirmed = await this.$confirm({
        title: this.$t('Use Craft Code'),
        description,
        buttonText: this.$t('Yes'),
        cancelText: this.$t('No'),
      });

      if (!confirmed) {
        return
      }

      set(this.params.data, 'craft_code_id', addl_source_id)
      set(this.params.data, 'craft_code_from', 'repair-order-entry')
      this.params.node.setData(this.params.data)

      this.params.onChange?.(this.params)
    }
  },
}
</script>
