<template>
  <ProofListing
      :entity="$t('Invoice Payment')"
      :printOptions="printOptions"
      :show-share="false"
      :urlParams="model"
      :addPostParams="beforePost"
      :post-disabled="noBanks"
      :on-success="onPosted"
      url="/restify/invoice-payments/actions?action=create-invoice-payments-proof-listing"
      post-url="/restify/invoice-payments/action?action=create-invoice-payments"
      ids-field="invoice_ids"
      @data-fetch="onDataFetch"
  >
    <template #header-buttons>
      <PreviewChecksButton
          :download-function="previewChecks"
          class="ml-2 print:hidden"
      />
    </template>
    <template #header-buttons-after="{post}">
      <post-button
          @click="post({ shouldPrint: true })"
          size="xs"
          variant="gray-link"
      >
        {{ $t('Post & Print Checks') }}
      </post-button>
    </template>
    <template #default="{loading}">
      <div class="grid grid-cols-8 gap-4 mt-4 print:hidden">
        <div class="col-span-2">
          <base-date-picker v-model="model.date"
                            :label="$t('Date')"
                            :name="$t('Date')"
                            inline-errors
                            id="date"
                            rules="required"
          />
        </div>
        <div class="col-span-8 md:col-span-2">
          <bank-select
              v-model="model.default_bank_id"
              :used-for="BankUsedInTypes.AccountsPayable"
              @entity-change="onBankChange"
              class="w-full"
              id="bank"
          />
        </div>
        <div class="col-span-8 md:col-span-2">
          <base-input v-model="model.next_check_no"
                      :min="0"
                      :label="$t('Starting Check Number')"
                      :tip="$t('When printed check(s) will start with the provided number below')"
                      type="number"
                      id="next_check_no">
          </base-input>
        </div>
        <div class="col-span-1">
          <base-switch v-model="model.separate_job_checks"
                       :label-info="$t('Separate Job Checks')"
                       id="separate_job_checks"
          />
        </div>
        <div class="col-span-1">
          <base-switch v-model="model.force_discounts"
                       :label-info="$t('Force Discounts')"
                       id="force_discounts"
          />
        </div>
      </div>

      <AgDataTable
          :key="renderKey"
          :data="mappedInvoices"
          :columns="columns"
          :data-loading="loading"
          :pagination="false"
          :groupIncludeFooter="true"
          :groupIncludeTotalFooter="true"
          :suppressAggFuncInHeader="true"
          :groupDefaultExpanded="-1"
          :compact="true"
          :no-borders="true"
          groupDisplayType="groupRows"
          dom-layout="autoHeight"
      >
        <template #totals.net_amount="{row, params}">
          <div class="flex flex-col items-end leading-none">
            <span v-if="params.node.footer">
              {{ $formatPrice(params.value) }}
            </span>
            <template v-else>
              <span>
                {{ $formatPrice(row?.totals?.net_amount) }}
              </span>
              <span v-if="row?.flag" class="mt-2 font-medium">
                {{ row?.flag }}
              </span>
            </template>
          </div>
        </template>
        <template #discount_available.amount="{row, params}">
          <div>
            <template v-if="params.node.footer">
              {{ $formatPrice(params.value) }}
            </template>
            <template v-else>
              {{$formatPrice(row?.discount_available?.amount)}}
              <BaseTooltip
                v-if="row?.discount_available?.flag && row?.discount_available?.amount > 0"
                :content="$t('Discount Lost')">
                <span class="text-red-500">
                  {{ row?.discount_available?.flag }}
                </span>
              </BaseTooltip>
            </template>
          </div>
        </template>
      </AgDataTable>

      <div class="grid lg:grid-cols-2 mt-4 print:break-inside-avoid">
        <div class="print:hidden"/>
        <div>
          <h5 class="form-section-title">
            {{ $t('Account Summary') }}
          </h5>
          <ProofListingAccountSummary
              :data="data.account_summary"
              :loading="loading"
          />
        </div>
      </div>
    </template>
  </ProofListing>
</template>
<script>
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import groupBy from "lodash/groupBy";
  import axios from "axios";
  import PreviewChecksButton from "@/components/common/PreviewChecksButton";
  import Cache from "@/utils/Cache";
  import { BankUsedInTypes } from "@/enum/enums";
  import { paymentTypes } from "@/modules/accounts-payable/components/invoice/paymentUtils";
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";

  const STORAGE_KEY = 'selectedAPInvoicesToPay'

  function parseSelectedInvoices() {
    let selections = []
    try {
      selections = JSON.parse(localStorage.getItem(STORAGE_KEY))
    } catch (err) {
      console.warn(err)
    }
    const invoices = []
    for (let vendorId in selections) {
      const vendorInvoices = selections[vendorId].rows?.map(invoice => {
        return {
          ...invoice,
          number: invoice.number || undefined,
          bank_id: invoice.bank_id || undefined,
          joint_vendor_id: invoice.joint_vendor_id || undefined,
        }
      })
      invoices.push(...vendorInvoices)
    }
    return {
      invoices,
      selections,
    }
  }

  export default {
    components: {
      ProofListing,
      PreviewChecksButton,
      ProofListingAccountSummary,
    },
    props: {
      hideButtons: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      let { invoices, selections } = parseSelectedInvoices()
      const bankId = this.$settings(this.$modules.AP, 'default_bank_id') || null
      const defaultBank = this.getBankById(bankId)
      return {
        selections,
        invoices,
        renderKey: 1,
        data: {
          vendors: [],
          account_summary: [],
          cost_type_summary: {},
        },
        model: {
          invoices,
          default_bank_id: bankId,
          next_check_no: (+defaultBank?.last_ap_check_no + 1 || 0).toString(),
          date: this.$formatDate(new Date(), 'yyyy-MM-dd'),
          force_discounts: this.$settings(this.$modules.AP, 'force_discounts') || false,
          separate_job_checks: false,
        },
      }
    },
    computed: {
      BankUsedInTypes() {
        return BankUsedInTypes
      },
      selectedBank() {
        return this.getBankById(this.model.default_bank_id)
      },
      noBanks() {
        return this.$store.getters['company/hasBanks'] === false
      },
      mappedInvoices() {
        const allInvoices = []
        this.data.vendors.forEach(vendor => {
          for (let key in vendor.payments) {
            const paymentTypePayments = vendor.payments[key] || {}
            paymentTypePayments.forEach(payment => {
              const { invoices } = payment
              allInvoices.push(...invoices.map(invoice => {
                return {
                  ...invoice,
                  vendor,
                  invoice_type: key,
                }
              }))
            })
          }
        })
        return allInvoices
      },
      accountSummaryData() {
        return this.data.account_summary.filter(account => {
          return account.debit_amount > 0 || account.credit_amount > 0
        })
      },
      columns() {
        return [
          {
            headerName: this.$t('Vendor'),
            field: 'vendor.name',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Invoice #'),
            field: 'number',
            minWidth: 80,
            maxWidth: 90,
            component: 'EntityLink',
            redirectTo: '/accounts-payable/invoices/{ID}',
          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            minWidth: 100,
          },
          {
            headerName: this.$t('Due Date'),
            field: 'dates.due_date',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedDate',
          },
          {
            headerName: this.$t('Discount Date'),
            field: 'dates.discount_date',
            minWidth: 100,
            maxWidth: 150,
            component: 'FormattedDate',
          },
          {
            headerName: this.$t('Job'),
            field: 'job_number',
            minWidth: 70,
            maxWidth: 120,
          },
          {
            headerName: this.$t('Open Payable Amount'),
            field: 'open_amount',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Discount Available'),
            field: 'discount_available.amount',
            minWidth: 80,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            valueGetter: params => +params.data?.discount_available?.amount || 0,
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Invoice Payment'),
            field: 'totals.gross_amount',
            minWidth: 100,
            maxWidth: 180,
            align: 'right',
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Less Discount'),
            field: 'totals.discount_amount',
            minWidth: 80,
            maxWidth: 150,
            align: 'right',
            component: 'FormattedPrice',
            cellRendererParams: {
              hideZero: true,
            },
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Net Payment'),
            field: 'totals.net_amount',
            minWidth: 100,
            maxWidth: 180,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
        ]
      },
      postData() {
        const checks = []
        for (let invoiceId in this.selections) {
          const selection = this.selections[invoiceId]
          const checkRows = selection.rows.map(check => {
            return {
              id: invoiceId,
              vendor_id: selection.vendor_id,
              ...this.mapCheck(check)
            }
          })
          checks.push(...checkRows)
        }
        let groupedVendors = groupBy(checks, 'vendor_id')
        const vendors = []
        for (let vendorId in groupedVendors) {
          vendors.push({
            vendor_id: vendorId,
            invoices: groupedVendors[vendorId]
          })
        }
        return {
          date: this.model.date,
          default_bank_id: this.model.default_bank_id,
          next_check_no: this.model.next_check_no,
          force_discounts: this.model.force_discounts,
          separate_job_checks: this.model.separate_job_checks,
          action: 'create-invoice-payments',
          vendors,
        }
      },
      printOptions() {
        return {
          stimulsoft: true,
          data: {
            invoices: this.mappedInvoices,
            account_summary: this.accountSummaryData,
          },
          entity: StimulsoftPrintEntities.APPaymentsProofListing
        }
      }
    },
    methods: {
      getBankById(bankId) {
        return this.$store.getters['company/getBankById'](bankId)
      },
      onBankChange(bank) {
        this.model.next_check_no = (+bank?.attributes?.last_ap_check_no + 1).toString()
      },
      beforePost(payload) {
        let postData = structuredClone(this.postData)
        postData.invoices = postData.vendors.map(vendor => vendor.invoices).flat()
        delete postData.vendors

        for (let key in postData) {
          payload[key] = postData[key]
        }
      },
      async onPosted(data, extraPrams) {
        const journalId = data?.data?.journal_id
        localStorage.setItem(STORAGE_KEY, JSON.stringify({}))
        setTimeout(async () => {
          // TODO update to use Stimulsoft template
          if (extraPrams?.shouldPrint) {
            Cache.removeForEntity('invoice')
            await this.$router.push(`/accounts-payable/payments/batches/${journalId}/view?print=true`)
            return
          } else {
            await this.$router.push(`/accounts-payable/payments/batches/${journalId}/view`)
          }
          Cache.removeForEntity('invoice')
        }, 200)
      },
      mapCheck(check) {
        let type = check.type
        if (check.joint_vendor_id) {
          type = paymentTypes.JointPayment
        }
        const finalCheck = {
          ...check,
          type,
          number: check.number || undefined,
          joint_vendor_id: check.joint_vendor_id || undefined,
        }
        if (!finalCheck.bank_id) {
          delete finalCheck.bank_id
        }
        return finalCheck
      },
      async previewChecks(model = {}) {
        const url = `/restify/invoice-payments/action?action=print-invoice-payments-proof-listing`
        const data = {
          date: this.model.date,
          vendors: this.postData.vendors,
          ...model,
        }
        const params = {
          responseType: 'blob',
        }
        return await axios.post(url, data, params)
      },
      onDataFetch(data) {
        this.renderKey++
        this.data = data
      }
    },
    watch: {
      selectedBank(bank) {
        this.model.next_check_no = (+bank?.last_ap_check_no + 1 || 0).toString()
      }
    },
  }
</script>
