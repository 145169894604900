<template>
  <BaseReportForm
    :report-number="reportNumber"
    :filters="model"
  >
    <template #filters>
      <BaseFilterRow :title="$t('View')">
        <div class="col-span-6 mb-5">
          <BaseCheckbox
            v-model="model.print_transaction_detail"
            :label="$t('Transaction Details')"
            id="print_transaction_detail"
          />
          <BaseCheckbox
            v-model="model.period_totals"
            :label="$t('Period Totals')"
            id="period_totals"
          />
          <BaseCheckbox
            v-model="model.subaccount_totals"
            :label="$t('Subaccount Totals')"
            id="subaccount_totals"
          />
        </div>
      </BaseFilterRow>
      <base-filter-row :title="$t('Period')">
        <period-select
          v-model="model.period_start"
          :label="$t('Beginning Period')"
          class="col-span-2"
        />
        <period-select
          v-model="model.period_end"
          :label="$t('End Period')"
          class="col-span-2"
        />
      </base-filter-row>
      <AccountFilterRow
        v-model="model"
      >
        <template #extra-filters>
          <BaseCheckbox
            v-model="model.exclude_inactive"
            :label="$t('Exclude Inactive Accounts')"
            class="col-span-2"
            id="exclude_inactive"
          />
        </template>
      </AccountFilterRow>
      <BaseFilterRow>
        <p class="col-span-6 form-label">
          {{ $t('View details for the following modules (checked modules will show only summary)') }}</p>
      </BaseFilterRow>
      <BaseFilterRow>
        <div class="col-span-6 flex mb-5">
          <div class="w-1/2">
            <BaseCheckbox
              v-model="model.summary.ap"
              :label="$t('Accounts Payable')"
              id="ap"
            />
            <BaseCheckbox
              v-model="model.summary.ar"
              :label="$t('Accounts Receivable')"
              id="ar"
            />
            <BaseCheckbox
              v-model="model.summary.cd"
              :label="$t('Cash Disbursements')"
              id="cd"
            />
            <BaseCheckbox
              v-model="model.summary.cr"
              :label="$t('Cash Receipts')"
              id="cr"
            />
          </div>
          <div class="w-1/2">
            <BaseCheckbox
              v-model="model.summary.gl"
              :label="$t('General Ledger')"
              id="gl"
            />
            <BaseCheckbox
              v-model="model.summary.jc"
              :label="$t('Job Costing')"
              id="jc"
            />
            <BaseCheckbox
              v-model="model.summary.pr"
              :label="$t('Payroll')"
              id="pr"
            />
            <BaseCheckbox
              v-model="model.summary.eq"
              :label="$t('Equipment')"
              id="eq"
            />
          </div>
        </div>
      </BaseFilterRow>
    </template>
    <template #table="{data, loading}">
      <GeneralLedgerReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import AccountFilterRow from '@/components/entity-filters/AccountFilterRow.vue'
import { NumberRangeFilter } from '@/components/range-filters'
import GeneralLedgerReportTable from '@/modules/ledger/components/reports/GeneralLedgerReportTable.vue'

export default defineComponent({
  components: {
    NumberRangeFilter,
    AccountFilterRow,
    GeneralLedgerReportTable,
  },
  data() {
    return {
      reportNumber: 1881 as number,
      model: {
        account_ids: [],
        exclude_inactive: 0,
        print_transaction_detail: true,
        period_totals: true,
        subaccount_totals: false,
        period_start: this.$currenPeriod,
        period_end: this.$currenPeriod,
        account_number_from: null,
        account_number_to: null,
        summary: {
          ap: 0,
          ar: 0,
          cd: 0,
          cr: 0,
          gl: 0,
          jc: 0,
          pr: 0,
          eq: 0,
        },
      } as GL_REPORTS.GeneralLedger,
    }
  },
})
</script>
