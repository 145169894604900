<template>
  <div
      class="menu-item text-gray-400 hover:text-gray-900 hover:bg-gray-50 focus:bg-gray-50 group flex items-center text-sm leading-5 font-medium rounded-md focus:outline-none focus:text-white"
      @click.prevent.stop="toggleDialog">
    <SearchIcon class="block md:hidden mr-2 h-4 w-4 text-gray-400 hover:text-gray-900 focus:text-gray-900"/>
    <button class="hidden md:flex items-center font-medium pl-4 pr-8 py-2 bg-gray-100 rounded-md focus:outline-none">
      <i>
        <SearchIcon class="mr-2 h-4 w-4 text-gray-400 group-hover:text-gray-900 group-focus:text-gray-900"/>
      </i>
      <span class="whitespace-nowrap">{{ $t('Quick Find') }}</span>
    </button>
    <base-dialog v-if="showDialog"
                 :visible.sync="showDialog"
                 :show-close="false"
                 append-to-body
                 class="search-dialog"
    >
      <div class="search-dialog-input__wrapper">
        <SearchIcon v-if="!loading" class="w-6 h-6 mr-2 text-grey-dark"></SearchIcon>
        <span v-if="loading" class="flex items-center w-6 mr-2">
                    <i class="el-icon-loading text-xl text-grey-dark"></i>
                </span>
        <input class="search-dialog__input form-input"
               :value="searchQuery"
               @input="searchQuery = $event.target.value"
               :placeholder="$t('Search for pages...')"
               type="text"
               ref="searchInput"
               @keydown.down.prevent="focusOnOption(0)"
        >
      </div>
      <div class="search-results">
        <div v-for="(item, index) in searchResults"
             :key="item.path + index"
             :tabindex="0"
             :ref="`result-${index}`"
             class="search-result__item"
             :class="{'mb-3': index === searchResults.length - 1}"
             @keydown.down.prevent="onKeyArrowDown"
             @keydown.up.prevent="onKeyArrowUp"
             @click="navigateToItem(item)"
             @keydown.enter="navigateToItem(item)"
        >

          <div class="flex items-center">
            <SidebarMenuIcon :icon="getRouteIcon(item)" class="mr-2"/>
            <div class="flex-col">
              <div v-html="highlightResult(item.name)"></div>
              <div class="text-sm" v-html="highlightResult(item.description)"></div>
            </div>
          </div>
        </div>


      </div>
    </base-dialog>
  </div>
</template>
<script>
  import get from 'lodash/get'
  import { Tag } from 'element-ui'
  import { SearchIcon } from 'vue-feather-icons'
  import BaseDialog from '@/components/common/BaseDialog';
  import { isCpdesktop, isProduction } from '@/isProduction';
  import { getNestedRoutes } from "@/modules/dashboard/util/routeUtils";
  import SidebarMenuIcon from "@/modules/dashboard/components/SidebarMenuIcon";
  import sidebarMenus from '@/modules/dashboard/enum/sidebarMenus'

  export default {
    components: {
      SearchIcon,
      BaseDialog,
      SidebarMenuIcon,
      [Tag.name]: Tag,
    },
    data() {
      return {
        showDialog: false,
        loading: false,
        searchQuery: '',
        searchResults: [],
        focusedOptionIndex: 0,
      }
    },
    computed: {
      resultsCount() {
        return this.searchResults.length
      },
      routes() {
        let result = []
        if (this.$router.options.routes) {
          this.$router.options.routes.forEach(route => {
            result.push(...getNestedRoutes(route))
          })
        }

        if (isProduction()) {
          result = result.filter(r => get(r, 'meta.productionReady'))
        }

        if (isCpdesktop()) {
          result = result.filter(r => get(r, 'meta.cpdesktopReady') !== false)
        }

        result = result.filter(r => {
          if (r.meta?.hideInSearch) {
            return false
          }

          const permissions = get(r, 'meta.permissions', [])
          const path = get(r, 'path', '')
          if (path.includes(':')) {
            return false
          }
          return this.$can(permissions);
        })

        return result.filter(r => r.title)
      },

    },
    watch: {
      searchQuery(query) {
        this.onSearchChange(query)
      },
    },
    mounted() {
      this.initKeyboardListeners()
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.onKeyDown)
    },
    methods: {
      initKeyboardListeners() {
        window.addEventListener('keydown', this.onKeyDown)
      },
      onKeyDown(event) {
        if (this.showDialog) {
          return
        }
        if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
          this.toggleDialog()
        }
      },
      highlightResult(name = '') {
        const characters = get(this.searchQuery, 'length', 0)
        let query = (this.searchQuery || '').toLowerCase()
        const characterStart = name.toLowerCase().indexOf(query)
        if (characterStart === -1) {
          return name
        }

        const searchedString = name.substr(characterStart, characters)
        return name.substr(0, characterStart) +
            `<span class="highlight">${searchedString}</span>` +
            name.substr(characterStart + characters, name.length)
      },
      routeSearchFilter(query, route) {
        if (!query) {
          return true
        }
        let { title, name, description } = route
        title = this.$t(title) || ''
        description = this.$t(description) || ''

        if (!name && !description) {
          return false
        }
        const queryInTitle = title.toLowerCase().includes(query.toLowerCase())
        const queryInDescription = description.toLowerCase().includes(query.toLowerCase())
        let rank = 2
        if (queryInTitle) {
          rank = 1
        }
        this.$set(route, 'rank', rank)
        return queryInTitle || queryInDescription
      },
      lowerCase(title = '') {
        if (!title || typeof title !== 'string') {
          title = ''
        }
        return this.$t(title).toLowerCase()
      },
      getRouteIcon(item) {
        let { route } = this.$router.resolve(item)
        let menu = sidebarMenus.find(menu => {
          return route.fullPath.includes(menu.path) && menu.icon
        })
        if (!menu) {
          return 'IconDocumentSearch'
        }
        return menu.icon
      },
      async onSearchChange(query) {
        let searchResults = []
        if (!query) {
          this.searchResults = searchResults
        }
        this.$emit('change', query)
        try {
          this.loading = true

          if (!query) {
            searchResults = this.routes.sort((a, b) => {
              return this.lowerCase(a.title).localeCompare(this.lowerCase(b.title))
            })
          } else {
            searchResults = this.routes
                .filter(route => this.routeSearchFilter(query, route))
                .sort((a,b) => {
                  const rankDiff = a.rank -b.rank || 0
                  if (rankDiff === 0) {
                    return a.path.length - b.path.length
                  }
                  return rankDiff
                })
          }
          this.searchResults = searchResults
        } catch (e) {
          this.searchResults = searchResults
        } finally {
          this.loading = false
        }
      },
      toggleDialog() {
        this.showDialog = !this.showDialog
        this.$nextTick(() => {
          if (!this.$refs.searchInput) {
            return
          }
          this.$refs.searchInput.focus()
        })
      },
      onKeyArrowDown() {
        if (this.focusedOptionIndex < this.resultsCount - 1) {
          this.focusedOptionIndex++
        } else {
          this.focusedOptionIndex = 0
        }
        this.focusOnOption(this.focusedOptionIndex)
      },
      onKeyArrowUp() {
        if (this.focusedOptionIndex > 0) {
          this.focusedOptionIndex--
        } else {
          this.focusedOptionIndex = this.resultsCount - 1
        }
        this.focusOnOption(this.focusedOptionIndex)
      },
      focusOnOption(index) {
        if (!this.resultsCount) {
          return
        }
        this.$nextTick(() => {
          const ref = get(this.$refs[`result-${index}`], '[0]')
          if (!ref) {
            return
          }
          ref.focus()
          if (this.focusedOptionIndex !== index) {
            this.focusedOptionIndex = index
          }
        })
      },
      async navigateToItem(item) {
        await this.$router.push(item)
        this.showDialog = false
        this.searchQuery = ''
      },
    },
  }
</script>
<style lang="scss">
  .search-dialog {
    background-color: rgba(0,0,0,0.2);
    @apply rounded;
    .el-dialog {
      margin-top: 5vh !important;
      @screen md {
        margin-top: 10vh !important;
      }
    }

    .el-dialog__body,
    .el-dialog__header {
      @apply p-0;
    }

    .search-dialog-input__wrapper {
      height: 50px;
      @apply px-4 border-b border-gray-100 flex items-center;
    }

    input.search-dialog__input {
      height: 36px;
      width: calc(100% - 55px);
      @apply border-none text-lg ring-0 shadow-none;
      &:focus,
      &:hover,
      &:active {
        @apply shadow-none border-none;
      }

      &::placeholder {
        @apply text-gray-400;
      }
    }

    .el-dialog__headerbtn {
      top: 16px;
      right: 16px;

      .close-icon {
        @apply text-gray-600;
      }
    }

    .search-results {
      max-height: calc(100vh - 10vh);
      @apply flex flex-col overflow-y-auto;
      @screen md {
        max-height: 400px;
      }
    }

    .search-result__item {
      @apply py-3 px-4 flex flex-col justify-center cursor-pointer text-base font-medium;
      &.selected,
      &:focus,
      &:hover {
        @apply bg-gray-100 outline-none;
      }

      .highlight {
        @apply text-primary-500;
      }
    }
  }

  .pl-4 {
    padding-right: 1rem;
  }

  .pr-8 {
    padding-right: 2rem;
  }
</style>
