<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :consistentRowColor="true"
    :autoRun="false"
    :arrayKeys="['include']"
  >
    <template #filters>

      <BaseFilterRow
        :title="$t('Include')"
      >
        <BaseSelect
          v-model="model.include"
          :options="includeOptions"
          :name="$t('Include')"
          class="col-span-3"
          rules="required"
          multiple
        />
      </BaseFilterRow>

      <BaseFilterRow
        :title="$t('With Errors Only')"
      >
        <BaseSwitch
          v-model="model.with_errors_only"
          inline
          class="col-span-3"
        />
      </BaseFilterRow>

      <CommonReportFilters
        v-model="model"
        :showAdditionalFilters="false"
      />

    </template>

    <template #table="{ loading, data }">
      <JobCostOverviewReportTable
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import { commonFilters, JobCostVarianceReportOptions } from '@/modules/job-costing/components/reports/util'
import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
import JobCostOverviewReportTable from '@/modules/job-costing/components/reports/job-cost-overview/JobCostOverviewReportTable.vue'
import BaseFilterRow from '@/components/common/BaseFilterRow.vue'

const include = {
  GL: 'GL',
  AP: 'AP',
  PR: 'PR',
  AR: 'AR',
  IV: 'IV',
}

export default {
  components: {
    CommonReportFilters,
    JobCostOverviewReportTable,
  },
  data() {
    return {
      reportNumber: '2890',
      model: {
        ...commonFilters,
        job_statuses: [
          this.$resourceStatuses.Open,
          this.$resourceStatuses.Inactive,
        ],
        with_errors_only: true,
        include: [
          include.GL,
        ]
      },
      // Select options
      includeOptions: [
        {
          value: include.GL,
          label: this.$t('GL'),
        },
        {
          value: include.AP,
          label: this.$t('AP'),
        },
        {
          value: include.PR,
          label: this.$t('PR'),
        },
        {
          value: include.AR,
          label: this.$t('AR'),
        },
        {
          value: include.IV,
          label: this.$t('IV'),
        },
      ],
    }
  },
}
</script>
