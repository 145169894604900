<template>
  <div>
    <BaseGridSelect
      v-on="$listeners"
      v-bind="$attrs"
      :own-filter="onFilterLineItems"
      :own-policy="authorizedToAdd"
      :own-columns="getColumns"
      :resource-name="lineItemType"
      :filterMethod="filterMethod"
      ref="select"
    >
      <template
        v-if="!appliedToCostCenterDefaults && masterJobId"
        #custom-filter
      >
        <div
          v-show="authorizedToUseMasterLineItems"
          class="flex items-center cursor-pointer justify-center"
          @mousedown.prevent.stop
        >
          <BaseCheckbox
            v-model="showMasterLineItems"
            :label="$t('Show MASTER Line Items')"
            id="show-master-line-items"
            @change="onShowMasterLineItemsChange"
          />
        </div>
      </template>
    </BaseGridSelect>
  </div>
</template>
<script>
  import sortBy from 'lodash/sortBy'
  import { formatLineItemCode, isUUID } from '@/utils/utils'
  import { globalResources } from '@/components/form/util'
  import { codeEdit } from '@/modules/job-costing/enum/jobCategories'
  import { costTypes, uniqueJobTypeAbbreviations } from '@/enum/enums'
  import uniqBy from "lodash/uniqBy";

  const quantityLabelPrefixes = {
    [uniqueJobTypeAbbreviations.Labor]: 'Hrs',
    [uniqueJobTypeAbbreviations.Material]: 'Unt',
    [uniqueJobTypeAbbreviations.UnitPriceBilling]: 'Unt',
    default: 'Qty',
  }

  const actions = {
    [codeEdit.Transaction]: 'add',
    default: '',
  }

  const lineItemTypeTypes = {
    [globalResources.CostLineItems]: costTypes.Cost,
    [globalResources.IncomeLineItems]: costTypes.Income,
    default: costTypes.Cost,
  }

  export default {
    props: {
      jobId: String,
      typeId: String,
      appliedToCostCenterDefaults: {
        type: Boolean,
        default: false,
      },
      lineItemType: {
        type: String,
        default: globalResources.CostLineItems,
      },
      filterAvailableInTimesheets: {
        type: Boolean,
        default: false,
      },
      filterMethod: Function,
    },
    data() {
      return {
        showMasterLineItems: false,
        showAddDialog: false,
        globalResources,
        searchableFields: ['id', 'phase_code', 'cost_code', 'change_order', 'description'],
      }
    },
    computed: {
      masterJobId() {
        return this.$settings(this.$modules.JC, 'master_job_id')
      },
      useMasterLineItems() {
        const isMasterLineItemSelected = !!this.masterLineItems.find(lineItem => lineItem.id === this.$attrs.value)
        const noLineItems = this.getLineItems.length === 0
        return isMasterLineItemSelected || noLineItems
      },
      authorizedToUseMasterLineItems() {
        const jobEditOption = this.job['code_edit_option']
        const settingsEditOption = this.$settings(this.$modules.JC, 'code_edit_option')
        return jobEditOption === codeEdit.JobOrMaster || settingsEditOption === codeEdit.JobOrMaster
      },
      getLineItemType() {
        if (!this.jobType?.type) {
          return this.lineItemType
        }

        const lineItemTypes = {
          [costTypes.Cost]: this.$globalResources.CostLineItems,
          [costTypes.Income]: this.$globalResources.IncomeLineItems,
          default: this.$globalResources.CostLineItems,
        }

        return lineItemTypes[this.jobType.type]
      },
      lineItems() {
        return this.$store.getters['globalLists/getResourceList'](this.getLineItemType) || []
      },
      masterLineItems() {
        const lineItems = this.$store.getters['globalLists/getResourceList'](this.$globalResources.MasterLineItems)
        const type = lineItemTypeTypes[this.getLineItemType]

        return lineItems.filter(lineItem => lineItem.type === type)
      },
      getLineItems() {
        return this.lineItems.filter(lineItem => lineItem.job_id === this.jobId)

      },
      job() {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Jobs, this.jobId)
      },
      jobType() {
        return this.$store.getters['globalLists/getResourceById'](this.$globalResources.JobTypes, this.typeId)
      },
      getTypeAbbreviation() {
        if (!this.typeId) {
          return ''
        }

        return this.jobType.abbr
      },
      getColumns() {
        const quantityLabelPrefix = quantityLabelPrefixes[this.getTypeAbbreviation] || quantityLabelPrefixes.default

        const columns =  [
          {
            minWidth: 120,
            maxWidth: 120,
            name: this.$t('Line Item'),
            prop: 'phase_code',
            toFormat: (_, option) => {
              return formatLineItemCode(option)
            }
          },
          {
            class: 'w-full',
            name: this.$t('Description'),
            prop: 'description',
            maxWidth: 200,
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t(`${this.getTypeAbbreviation} - Budget`),
            prop: `typeTotals.${this.getTypeAbbreviation}.amount`,
            formatPrice: true,
            renderIf: () => this.getTypeAbbreviation,
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t(`${this.getTypeAbbreviation} - Actual`),
            prop: `typeTotals.${this.getTypeAbbreviation}.amount_to_date`,
            formatPrice: true,
            renderIf: () => this.getTypeAbbreviation,
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t(`${quantityLabelPrefix} - Budget`),
            prop: `typeTotals.${this.getTypeAbbreviation}.quantity`,
          },
          {
            minWidth: 105,
            maxWidth: 105,
            name: this.$t(`${quantityLabelPrefix} - Actual`),
            prop: `typeTotals.${this.getTypeAbbreviation}.quantity_to_date`,
          },
        ]

        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
    },
    methods: {
      authorizedToAdd() {
        if (!this.jobId) {
          return false
        }

        return actions[this.job['code_edit_option']] === 'add'
      },
      getJobOrMasterLineItems() {
        // * When applied to cost center defaults, in the case of a job,
        // * we want to show the job's line items, or the master line items if the job has not been defined.
        if (this.appliedToCostCenterDefaults) {
          return this.jobId ? this.getLineItems : this.masterLineItems
        }

        return this.getFilteredOptions()
      },
      getFilteredOptions() {
        if (!this.jobId) {
          return []
        }

        return (this.authorizedToUseMasterLineItems && this.showMasterLineItems) ? this.masterLineItems : this.getLineItems
      },
      onFilterLineItems(searchQuery, perPage) {

        const lineItems = this.getJobOrMasterLineItems()

        if (isUUID(searchQuery)) {
          const index = lineItems.findIndex(item => item.id === searchQuery)
          if (index === -1 || !perPage) {
            return lineItems
          }
          const selectedOption = lineItems[index]
          const paginatedOptions = lineItems.slice(0, perPage)

          return uniqBy([selectedOption, ...paginatedOptions], 'id')
        }

        let options = lineItems.filter(c => {

          return this.searchableFields.some(field => {
            if (c[field]) {
              return c[field]?.toString()?.toLowerCase().includes(searchQuery.toLowerCase())
            }
            return false
          })

        })

        if (this.filterAvailableInTimesheets) {
          options = options.filter(job => job.available_in_timesheets)
        }

        if (this.filterMethod) {
          return options.filter(this.filterMethod)
        }

        if (!perPage) {
          return options
        }
        return options.slice(0, perPage)
      },
      onShowMasterLineItemsChange(state) {
        this.$emit('entity-change', { id: '' })
      },
    },
    watch: {
      useMasterLineItems: {
        handler() {
          this.showMasterLineItems = this.useMasterLineItems
        },
        immediate: true,
      },
    },
  }
</script>
