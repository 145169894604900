<template>
  <JobTimesheetsLayout>
    <TimesheetEntriesTable
      :url-params="{
        source_id: $route.params.id
      }"
    />
  </JobTimesheetsLayout>
</template>
<script>
import TimesheetEntriesTable from "@/modules/payroll/components/timesheets/TimesheetEntriesTable.vue";
import JobTimesheetsLayout from "@/modules/job-costing/layout/JobTimesheetsLayout.vue";

export default {
  components: {
    JobTimesheetsLayout,
    TimesheetEntriesTable,
  },
}
</script>
