<template>
  <EntityPurchaseOrders
    :url-params="urlParams"
  />
</template>
<script>
import EntityPurchaseOrders from "@/modules/purchasing-inventory/components/purchase-orders/EntityPurchaseOrders.vue";

export default {
  components: {
    EntityPurchaseOrders,
  },
  computed: {
    lineItemId() {
      return this.$route.params.id
    },
    urlParams() {
      return {
        line_item_id: this.lineItemId,
      }
    },
  },
}
</script>
