import i18n from '@/i18n'
import store from '@/store'
import en from 'date-fns/locale/en-US'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import addMonths from 'date-fns/addMonths'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { getCurrentPeriod } from "@/utils/dateUtils";
import parse from "date-fns/parse";

const defaultFormat = 'MM/dd/yyyy'
export const LongDateFormat = 'dd MMM yyyy'
export const ApiDateFormat = 'yyyy-MM-dd'

export const dateTypes = {
  IsoDate: 'isoDate',
  IsoDateTime: 'iso',
  Date: 'date',
  DateTime: 'datetime',
  DateTimeMinutes: 'datetimeminutes',
}

function getDateFormat(dateFormat = dateTypes.Date) {
  if (dateFormat === dateTypes.IsoDate) {
    dateFormat = 'yyyy-MM-dd'
  }
  if (dateFormat === dateTypes.IsoDateTime) {
    dateFormat = 'yyyy-MM-dd HH:mm:ss'
  }
  if (dateFormat === dateTypes.Date) {
    dateFormat = store.getters['settings/getByKey']('date_format') || defaultFormat
  }
  if (dateFormat === dateTypes.DateTime) {
    dateFormat = store.getters['settings/getByKey']('datetime_format') || 'MM/dd/yyyy HH:mm:ss'
  }
  if (dateFormat === dateTypes.DateTimeMinutes) {
    dateFormat = store.getters['settings/getByKey']('datetime_format') || 'MM/dd/yyyy HH:mm'
  }
  if (!dateFormat) {
    dateFormat = defaultFormat
  }
  return dateFormat
}

function prepareDate(date)  {
  let dateToFormat = date
  if (typeof date === 'string') {
    // We receive a format like 2021-05-21
    if (date.length === 10) {
      // workaround because date-fns ads the timezone by default which causes formatting issues (i.e adding or subtracting 1 day)
      date = `${date}T00:00:00.000000Z`
    }
    dateToFormat = parseISO(date)
  }
  return dateToFormat
}

export function formatDate(date, dateFormat = dateTypes.Date, ignoreTimezoneCheck = false) {
  try {
    if (!date) {
      return ''
    }
    dateFormat = getDateFormat(dateFormat)
    let dateToFormat = prepareDate(date)
    if (typeof date === 'string') {
      // We receive a format like 2021-05-21
      if (ignoreTimezoneCheck || date.length === 10) {
        dateToFormat = getDateWithoutTimezone(dateToFormat)
      }
      return format(dateToFormat, dateFormat, { locale: en })
    }
    if (!includesHoursOrMinutes(dateFormat) && typeof date !=='string' || ignoreTimezoneCheck) {
      dateToFormat = getDateWithoutTimezone(dateToFormat)
    }
    return format(dateToFormat, dateFormat, { locale: en })
  } catch (err) {
    console.warn('Could not format date', date)
    return 'N/A'
  }
}

export function formatDateClean(date, dateFormat = dateTypes.Date) {
  try {
    if (!date) {
      return ''
    }
    dateFormat = getDateFormat(dateFormat)
    let dateToFormat = prepareDate(date)
    return format(dateToFormat, dateFormat, { locale: en })
  } catch (err) {
    console.warn('Could not format date', date)
    return 'N/A'
  }
}

export function formatHours(timestamp) {
  const date = new Date(timestamp)
  return format(date, 'hh:mm aa')
}

export function formatDateOnly(date, dateFormat = dateTypes.Date) {
  if (dateFormat === dateTypes.Date) {
    dateFormat = store.getters['settings/getByKey']('date_format') || defaultFormat
  }
  if (typeof date === 'string') {
    date = parse(date, 'yyyy-MM-dd', new Date())
  }
  return format(date, dateFormat, { locale: en })
}

export function formatDateNoTimezone(date, dateFormat = dateTypes.Date) {
  return formatDate(date, dateFormat, true)
}

function includesHoursOrMinutes(format = '') {
  return format.includes('h') || format.includes('s')
}

export function getDateWithoutTimezone(date) {
  if (!date) {
    return
  }
  if (!date.getTimezoneOffset) {
    return date
  }
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
}

export function getDateWihoutTimezoneSigned(date) {
  if (!date) {
    return
  }
  if (!date.getTimezoneOffset) {
    return date
  }
  const userTimezoneOffset = date.getTimezoneOffset() * 60000

  return new Date(date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset));
}

export const now = () => {
  return format(new Date(), ApiDateFormat)
}

const currentYear = () => {
  return store.state.company.currentFiscalYear
}

const nextMonth = () => {
  const date = addMonths(new Date(), 1)
  return format(date, ApiDateFormat)
}

export function timeAgo(date) {
  return formatDistanceToNow(parseISO(date))
}

export const dateWithTimeZone = () => format(new Date(), 'yyyy-MM-dd HH:mm:ss zzzz')

export function formatTime(time) {
  if (time.length === 5) {
    time = `${time}:00`
  }
  const date = parse(time, 'HH:mm:ss', new Date())
  return format(date, 'hh:mm aa')
}

export default {
  install(Vue) {
    let app = new Vue({
      i18n,
      methods: {
        formatDate,
      }
    })
    Vue.prototype.$now = now()
    Vue.prototype.$nextMonth = nextMonth()
    Vue.prototype.$currentYear = currentYear()
    Vue.prototype.$currentPeriod = getCurrentPeriod(store.state.company.periods)
    Vue.prototype.$dateTypes = dateTypes
    Vue.prototype.$formatDate = app.formatDate
    Vue.prototype.$formatTime = formatTime
    Vue.prototype.$formatDateOnly = formatDateOnly
    Vue.prototype.$formatDateClean = formatDateClean
    Vue.prototype.$dateWithTimeZone = dateWithTimeZone()
    Vue.prototype.$defaultDateFormat = store.getters['settings/getByKey']('date_format') || defaultFormat
  }
}
