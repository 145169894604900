<template>
  <div>
    <DetailsPageLayout :loading="maintenanceCodeLoading">
      <template #left>
        <MaintenanceCodeIntervalCard :maintenance-code="maintenanceCode"/>
      </template>
      <template #right>
        <BaseDetailCard
          :title="$t('Maintenance Code')"
          :key="maintenanceCode.id"
          :entity="maintenanceCode"
          :can-expand="false"
          :expanded="true"
          entity-name="maintenanceCode"
        >
          <BaseDetailCardRow :title="$t('Code')">
            {{ maintenanceCode.attributes.code }}
          </BaseDetailCardRow>
          <BaseDetailCardRow
            v-if="maintenanceCode.attributes.description"
            :title="$t('Description')"
          >
            {{ maintenanceCode.attributes.description }}
          </BaseDetailCardRow>
          <BaseDetailCardRow :title="$t('Updated At')">
            {{ maintenanceCode.attributes?.updated_at ? $formatDate(new Date(maintenanceCode.attributes.updated_at), 'datetime') : '' }}
          </BaseDetailCardRow>
        </BaseDetailCard>
        <BaseBackLink
          v-if="showBack"
          to="/equipment/maintenance-codes"
        />
      </template>
    </DetailsPageLayout>
  </div>
</template>
<script>
import DetailsPageLayout from "@/modules/common/components/DetailsPageLayout.vue";
import MaintenanceCodeIntervalCard from "@/modules/equipment/components/maintenance-codes/MaintenanceCodeIntervalCard.vue";

export default {
  components: {
    DetailsPageLayout,
    MaintenanceCodeIntervalCard,
  },
  props: {
    showBack: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    maintenanceCode() {
      return this.$store.state.equipmentManagement.currentMaintenanceCode
    },
    maintenanceCodeLoading() {
      return this.$store.state.equipmentManagement.maintenanceCodeLoading
    },
  },
}
</script>
