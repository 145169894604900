<template>
  <settings-form
      :fields="fields"
      :module-name="moduleName"
      :model-mutator="composeModel"
      @cancel="$router.push('/accounts-receivable/customers')"
      @change="onModelChange"
  >
    <template v-slot="{model}">

      <AgDataTable
          :columns="columns"
          :show-pagination="false"
          :pagination="false"
          :data="accounts"
          class="col-span-3 md:mr-3 mb-5"
          domLayout="autoHeight"
      />

      <div class="col-span-3 md:grid md:grid-cols-2 gap-x-3">

        <bank-select
            v-model="model.default_bank_id"
            :used-for="BankUsedInTypes.AccountsReceivable"
            :label="$t('Default Bank')"
            class="col-span-3 md:col-span-1"
            rules="required"
        />

        <base-textarea
            v-model="model.late_charges_phrase"
            :label="$t('Late Charges Phrase')"
            :placeholder="$t('Late Charges Phrase')"
            :rows="1"
            class="col-span-3 lg:col-span-1"
        />

        <base-textarea
            v-model="model.invoice_terms"
            :label="$t('Invoice Terms')"
            :placeholder="$t('Invoice Terms')"
            :rows="1"
            class="col-span-3 lg:col-span-1"
        />

        <base-select
            v-model="model.default_cpb_status"
            :label="$t('Cost Plus Billing Status')"
            :placeholder="$t('Default Status')"
            :options="cpbAndLmpStatuses"
            class="col-span-3 lg:col-span-1"
        />

        <base-select
            v-model="model.default_unt_status"
            :label="$t('Unit Price Billing Status')"
            :placeholder="$t('Default Status')"
            :options="untAndPrgStatuses"
            class="col-span-3 lg:col-span-1"
        />

        <base-select
            v-model="model.default_prg_status"
            :label="$t('Progress Billing Status')"
            :placeholder="$t('Default Status')"
            :options="untAndPrgStatuses"
            class="col-span-3 lg:col-span-1"
        />

        <base-select
            v-model="model.default_lmp_status"
            :label="$t('Lump Sum Billing Status')"
            :placeholder="$t('Default Status')"
            :options="cpbAndLmpStatuses"
            class="col-span-3 lg:col-span-1"
        />

        <BaseInput
          v-if="false /* TODO We will add prefix(es) when supporting multiple sequencing groups */"
          v-model="model.lump_sum_billing_prefix_letter"
          v-uppercase
          :label="$t('Lump Sum Billing Prefix Letter')"
          :name="$t('Lump Sum Billing Prefix Letter')"
          class="col-span-3 lg:col-span-1"
          rules="alpha"
        />

        <BaseInput
          v-model="model.last_lump_sum_billing_number"
          :label="$t('Last Lump Sum Billing Number')"
          :name="$t('Last Lump Sum Billing Number')"
          class="col-span-3 lg:col-span-1"
        />
      </div>

      <state-select
          v-model="model.sales_tax_state"
          :label="$t('Sales Tax State')"
          :placeholder="$t('Sales Tax State')"
          class="col-span-3 md:col-span-1"
          clearable
      />

      <sales-tax-district-select
          v-model="model.sales_tax_district_id"
          :label="$t('Sales Tax District')"
          :placeholder="$t('Sales Tax District')"
          :add-entity="true"
          class="col-span-3 md:col-span-1"
          clearable
      />

      <base-checkbox
          v-model="model.apply_tax_to_billings"
          :label="$t('Apply Tax to Billings')"
          :tip="$t('When turned on, this will apply sales tax to billings based on job sales tax configuration.')"
          class="col-span-3 md:col-span-1"
          id="apply-tax-to-prg"
      />

      <base-checkbox
        v-model="model.show_discount_date"
        :label="$t('Show billing discount dates')"
        :tip="$t('When turned on, this will show billing discount dates on the billing screens.')"
        class="col-span-3 md:col-span-2"
        id="show_discount_date"
      />

      <base-input
        v-model="model.default_bcc_email"
        :label="$t('Default BCC Email')"
        :placeholder="$t('BCC Email')"
        :tip="$t('When sending billing via email, this email will be BCCed.')"
        class="col-span-3 md:col-span-2"
        id="default_bcc_email"
        rules="email"
      />
      <base-checkbox
        v-model="model.send_a_copy"
        :label="$t('Default Send a Copy')"
        :tip="$t('When sending billing via email, this setting will enable or disable send a copy checkbox by default')"
        class="col-span-3 md:col-span-2"
        id="send_copy"
      />

    </template>
  </settings-form>
</template>
<script>
  import SettingsForm from '@/modules/common/components/SettingsForm';
  import { BankUsedInTypes } from "@/enum/enums";

  const accountsFields = [
    'late_charges',
    'discounts_given',
    'sales_tax_payable',
    'joint_payments_clear',
    'customer_cash_on_deposit',
  ]

  const accountsTypeName = {
    account: 'Accounts Receivable',
    discounts_given: 'Discounts Given',
    sales_tax_payable: 'Sales Tax Payable',
    customer_cash_on_deposit: 'Customer Cash on Deposit',
    joint_payments_clear: 'Joint Checks Clearing',
    late_charges: 'Late Charges Income',
  }

  export default {
    components: {
      SettingsForm,
    },
    data() {
      return {
        cpbAndLmpStatuses: [
          {
            label: this.$t('Pending'),
            value: this.$resourceStatuses.Pending,
          },
          {
            label: this.$t('Prepaid'),
            value: this.$resourceStatuses.Prepaid,
          },
          {
            label: this.$t('No Post'),
            value: this.$resourceStatuses.NoPost,
          },
        ],
        untAndPrgStatuses: [
          {
            label: this.$t('Pending'),
            value: this.$resourceStatuses.Pending,
          },
          {
            label: this.$t('No Post'),
            value: this.$resourceStatuses.NoPost,
          },
        ],
        accounts: [],
        moduleName: 'accounts-receivable',
        fields: [
          'account',
          'subaccount',
          'calendar_year',
          'default_bank_id',
          'sales_tax_state',
          'sales_tax_district_id',
          'apply_tax_to_billings',
          'show_discount_date',
          'late_charges_phrase',
          'invoice_terms',
          'late_charges_account',
          'late_charges_subaccount',
          'discounts_given_account',
          'discounts_given_subaccount',
          'last_fiscal_year_posted',
          'last_fiscal_period_posted',
          'sales_tax_payable_account',
          'sales_tax_payable_subaccount',
          'joint_payments_clear_account',
          'joint_payments_clear_subaccount',
          'customer_cash_on_deposit_account',
          'customer_cash_on_deposit_subaccount',
          'default_cpb_status',
          'default_lmp_status',
          'default_prg_status',
          'default_unt_status',
          'default_bcc_email',
          'send_a_copy',
          'lump_sum_billing_prefix_letter',
          'last_lump_sum_billing_number',
        ],
      }
    },
    computed: {
      BankUsedInTypes() {
        return BankUsedInTypes
      },
      columns() {
        return [
          {
            headerName: this.$t('Account Type'),
            field: 'field',
            valueGetter: params => {
              return accountsTypeName[params.data.field]
            },
          },
          {
            headerName: this.$t('G/L Account'),
            field: 'account',
            component: 'AccountLink',
            cellEditor: this.$cellEditors.AccountSelect,
            editable: true,
            cellRendererParams: {
              target: '_blank',
            },
            cellEditorParams: {
              valueKey: 'number',
            },
          },
          {
            headerName: this.$t('G/L Subaccount'),
            field: 'subaccount',
            component: 'SubAccountLink',
            cellEditor: this.$cellEditors.SubAccountSelect,
            editable: true,
            cellRendererParams: {
              target: '_blank',
            },
            cellEditorParams: {
              valueKey: 'number',
            },
          },
        ]
      },
    },
    methods: {
      onModelChange(model) {
        const accounts = accountsFields.map(field => {
          return {
            field,
            id: crypto.randomUUID(),
            account: model[`${field}_account`],
            subaccount: model[`${field}_subaccount`],
          }
        })

        accounts.splice(0, 0, {
          id: crypto.randomUUID(),
          field: 'account',
          account: model.account,
          subaccount: model.subaccount,
        })

        this.accounts = accounts
      },
      composeModel(model) {

        this.accounts.forEach(accountObject => {
          const field = accountObject.field

          if (field === 'account') {
            model.account = accountObject.account
            model.subaccount = accountObject.subaccount
          } else {
            model[`${field}_account`] = accountObject.account
            model[`${field}_subaccount`] = accountObject.subaccount
          }
        })

        return model
      },
    },
  }
</script>
