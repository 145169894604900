<template>
  <div @click="$router.push(data.path)"
       class="bg-white group border border-gray-200 min-h-xs px-10 pt-8 pb-6 rounded-md cursor-pointer flex flex-col hover:bg-gray-50">
    <div class="w-12 h-12 icon-box flex items-center justify-center mb-2"
         :class="`border-${data.color}-200 text-primary before:bg-${data.color}-50`"
    >
      <component :is="data.icon" :class="`text-${data.color}-600`" class="w-4 h-4"/>
    </div>
    <h2 class="section-title">{{ data.name }}</h2>
    <p class="leading-tight font-medium text-sm text-gray-400 mt-2">
      {{ getDescription(data.routeName) }}
    </p>
    <div class="flex flex-1 items-end">
      <router-link :to="data.path" class="w-full flex justify-end mt-2">
        <IconArrowRight class="w-5 h-5 text-gray-300 cursor-pointer transform group-hover:text-primary-700"/>
      </router-link>
    </div>
  </div>
</template>
<script>
  import { getNestedRoutes } from "@/modules/dashboard/util/routeUtils";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      }
    },
    computed: {
      allRoutes() {
        let result = []
        if (this.$router.options.routes) {
          this.$router.options.routes.forEach(route => {
            result.push(...getNestedRoutes(route))
          })
        }
        return result
      }
    },
    methods: {
      findRoute(name) {
        return this.allRoutes.find(r => r.name === name)
      },
      getDescription(name) {
        const route = this.findRoute(name)
        return route?.description || ``
      }
    }
  }
</script>
<style>
.icon-box {
  @apply relative border flex justify-center items-center rounded;
  transform-style: preserve-3d;
}

.icon-box::before {
  left: -11%;
  top: -11%;
  content: '';
  transform: translateZ(-1px);
  filter: blur(0.5px);
  @apply absolute w-full h-full rounded;
}
.icon-box svg path {
  stroke-width: 1.5px;
}
</style>
