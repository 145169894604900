<template>
  <div :key="get(currentResource, 'id', 'invoice')">
    <portal to="page-title">
      {{ pageTitle }},&nbsp;@
      <router-link v-if="customer?.id" :to="`/accounts-receivable/customers/${customer?.id}/billings`">
        {{ customer?.code }}
        <span v-if="customer?.name">({{ customer.name }})</span>
      </router-link>
    </portal>
    <div class="flex justify-between items-center">
      <LayoutTabs :items="filteredItems"/>
      <div class="flex items-center space-x-2">
        <BaseButton
          v-if="canActivate"
          variant="primary-link"
          @click="activateBilling"
        >
          <PlayIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Activate') }}</span>
        </BaseButton>
        <BaseButton
          v-if="canDeactivate"
          variant="primary-link"
          @click="deactivateBilling"
        >
          <PauseIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Make Inactive') }}</span>
        </BaseButton>
        <BaseButton
          v-if="isActive"
          variant="primary-link"
          @click="showGenerateBillingsDialog = true"
        >
          <RecurringInvoiceIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Generate Billings') }}</span>
        </BaseButton>
      </div>
    </div>
    <GenerateBillingsDialog
      v-if="showGenerateBillingsDialog"
      :open.sync="showGenerateBillingsDialog"
      :recurring-billings="[currentResource]"
      @save="onBillingsGenerated"
      @close="showGenerateBillingsDialog = false"
    />
    <portal to="entity-name">
      {{ $t('Billing ') }}{{ currentResource?.attributes?.description }}
      ({{ customer?.name }})
    </portal>
    <router-view/>
  </div>
</template>
<script>
import { PauseIcon, PlayIcon, PrinterIcon } from 'vue-feather-icons'
import layoutPermissions from '@/mixins/layoutPermissions'
import { resourceStatuses } from "@/enum/enums";
import GenerateBillingsDialog from "@/modules/accounts-receivable/components/recurring-billings/GenerateBillingsDialog.vue";
import RecurringInvoiceIcon from "@/modules/accounts-payable/pages/invoices/RecurringInvoiceIcon.vue";
import { billingTypes } from "@/modules/accounts-receivable/pages/billings/billings";

export default {
  components: {
    RecurringInvoiceIcon,
    GenerateBillingsDialog,
    PauseIcon,
    PlayIcon,
    PrinterIcon,
  },
  mixins: [layoutPermissions],
  data() {
    return {
      showGenerateBillingsDialog: false,
    }
  },
  computed: {
    billingType() {
      return this.$route.meta.billingType
    },
    items() {
      const basePath = this.billingType === billingTypes.LumpSum
        ? `/accounts-receivable/recurring-billings/${this.billingType}`
        : `/service-billing/recurring-service-invoices`

      return [
        {
          name: this.$t('Details'),
          path: `${basePath}/${this.$route.params.id}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `${basePath}/${this.$route.params.id}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]
    },
    currentResource() {
      return this.$store.state.accountsReceivable.currentRecurringBilling
    },
    customer() {
      const { customer } = this.currentResource?.relationships || {}
      return customer?.attributes
    },
    pageTitle() {
      const typeLabel = this.billingType === billingTypes.LumpSum
        ? this.$t('Lump Sum')
        : this.$t('Service')

      const invoiceDescription = this.currentResource.attributes.description || ''
      return `${this.$t('Recurring Receivable')} (${typeLabel}) ${invoiceDescription}`
    },
    isActive() {
      return this.currentResource?.attributes?.status === resourceStatuses.Active
    },
    canDeactivate() {
      return this.isActive
    },
    canActivate() {
      return this.currentResource.attributes.status === resourceStatuses.Pending
    },
  },
  methods: {
    async onBillingsGenerated() {
      this.showGenerateBillingsDialog = false
      await this.getCurrentResource()
    },
    async getCurrentResource(id) {
      if (!id) {
        id = this.$route.params.id
      }
      await this.$store.dispatch('accountsReceivable/getRecurringBilling', id)
    },
    async deactivateBilling() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Make Recurring Billing Inactive'),
        description: this.$t('By confirming this action, the recurring billing will be set to inactive and will not create new billings anymore. You can always set it back to active.'),
        buttonText: this.$t('Make Inactive')
      })
      if (!confirmed) {
        return
      }
      const { id } = this.currentResource
      await this.$store.dispatch('accountsReceivable/deactivateRecurringBilling', id)
    },
    async activateBilling() {
      const confirmed = await this.$confirm({
        title: this.$t('Activate Recurring Billing'),
        description: this.$t('By making the recurring billing active, it will start creating new billings based on the provided schedule. Are you sure?'),
        buttonText: this.$t('Activate')
      })
      if (!confirmed) {
        return
      }
      const { id } = this.currentResource
      await this.$store.dispatch('accountsReceivable/activateRecurringBilling', id)
    },
  },
}
</script>
