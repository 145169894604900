<template>
  <div class="agency-details">
    <DetailsPageLayout :loading="agencyLoading">
      <template #left>
        <AgencyMainCard :agency="agency"/>
      </template>
      <template #right>
        <AgencySecondaryCard :agency-data="agencyData"/>
        <AddressesDetailsCard
          :addresses="agency?.relationships?.addresses"
          no-data-text="No addresses were added for this agency"
        />
        <ContactsDetailsCard
          :contacts="agency?.relationships?.contacts"
          no-data-text="No contacts were added for this agency"
        />
      </template>
    </DetailsPageLayout>
    <base-back-link to="/payroll/agencies"/>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import store from '@/store'
import DetailsPageLayout from '@/modules/common/components/DetailsPageLayout.vue'
import AddressesDetailsCard from '@/modules/common/components/AddressesDetailsCard.vue'
import ContactsDetailsCard from '@/modules/common/components/contacts/ContactsDetailsCard.vue'
import AgencyMainCard from '@/modules/payroll/components/agency-details/AgencyMainCard.vue'
import AgencySecondaryCard from '@/modules/payroll/components/agency-details/AgencySecondaryCard.vue'
import { Agency } from '@/modules/common/types/models'
import Data = API.Data

const agency = computed((): Data<Agency> => {
  return store.state.payroll.currentAgency
})

const agencyData = computed((): Agency => {
  return agency.value.attributes
})

const agencyLoading = computed((): boolean => {
  return store.state.payroll.agencyLoading
})
</script>
<style lang="scss">
.agency-details {
  span {
    @apply text-gray-500 font-medium;
  }
}
</style>
