<template>
  <BaseGridSelect
    v-focus
    :focus-on-mount="params.focusOnMount"
    v-model="selectedValue"
    :resource-name="params.resourceName"
    :unauthorized-to-add="unAuthorizedToAdd"
    :filterMethod="filterMethod"
    :own-columns="params.ownColumns"
    :sort-by="params.sortBy"
    :sort-by-function="params.sortByFunction"
    :clearable="params.clearable"
    :multiple="params.multiple"
    :no-data-text="noDataText"
    :no-match-text="noMatchText"
    :class="params.class"
    class="ag-cell-editor w-full"
    @change="onChange"
    @keydown.enter.native="goToNextCell"
  />
</template>
<script>
  import { selectEditorMixin } from '@/components/ag-grid/cellEditors/selectEditorMixin';
  import { getResourceLabelPlural, globalResourceLabels } from "@/components/form/util";
  import pluralize from "pluralize";

  export default {
    name: 'GlobalResourceCell',
    mixins: [selectEditorMixin],
    computed: {
      unAuthorizedToAdd() {
        return this.params.unAuthorizedToAdd !== undefined ?? true
      },
      resourceLabel() {
        return getResourceLabelPlural(this.params.resourceName)
      },
      noDataText() {
        if (this.params.noDataText) {
          return this.params.noDataText
        }
        return this.$t(`No matching ${this.resourceLabel} found`)
      },
      noMatchText() {
        if (this.params.noMatchText) {
          return this.params.noMatchText
        }
        return this.$t(`No matching ${this.resourceLabel} for the search criteria`)
      },
      includeWildcards() {
        return this.params.includeWildcards
      },
    },
    methods: {
      filterMethod(option) {
        if (option.id === '*' && !this.includeWildcards) {
          return false
        }

        if (this.params.filterMethod) {
          return this.params.filterMethod(option)
        }

        return true
      }
    }
  }
</script>
