<template>
  <component :is="icon"
             stroke="currentColor"
             class="h-6 w-6 transition ease-in-out duration-150 sidebar-menu-icon"
             :class="[{
                'text-gray-400 group-hover:text-gray-300 group-focus:text-gray-300': theme === 'dark',
                'text-gray-600 group-hover:text-gray-900 group-focus:text-gray-900': theme === 'white',
                'text-primary-200 group-hover:text-primary-300 group-focus:text-primary-300': theme === 'primary',
             }]"
  />
</template>
<script>
  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },
    },
    computed: {
      theme() {
        return this.$store.state.theme.theme
      },
    }
  }
</script>
<style>
.sidebar-menu-icon,
.sidebar-menu-icon path {
  stroke-width: 1.5px;
}
</style>
