import { gridContext } from "@/components/ag-grid/gridContext";
import isEmpty from "lodash/isEmpty";
import { computed, nextTick } from 'vue'
import { getFilterConfig } from "@/components/table/getFilterConfig";

export function useTableFilters(config = {}) {

  const hasFilters = computed(() => {
    return getFullFilterConfig().length > 0
  })
  const showFiltersDropdown = computed(() => {
    return config.defaultFilters && gridContext.filtersUrl && !isEmpty(gridContext.filterOptions)
  })

  const canFilter = computed(() => {
    return !config.hideActions.includes('filters')
  })

  function getFullFilterConfig() {
    let filters = getFilterConfig(gridContext.filterOptions.matches)
    if (config.excludeFilters) {
      filters = filters.filter(filter => {
        return !config.excludeFilters.includes(filter.valueKey)
      })
    }
    if (!config.customEntityFilters.length) {
      return filters
    }
    return filters.concat(config.customEntityFilters)
  }

  async function onChangeFilers(query, fullQuery) {
    if (!query) {
      return
    }
    const advancedFilters = []
    const baseFilters = { ...query }
    for (let key in fullQuery) {
      let value = fullQuery[key]
      if (typeof value === 'object' && value.advanced) {
        advancedFilters.push(value)
        delete baseFilters[key]
      }
    }
    let newFilters = {
      ...gridContext.matchFilters,
      ...baseFilters,
    }
    for (let key in newFilters) {
      let val = newFilters[key]
      if (Array.isArray(val)) {
        newFilters[key] = val.join(',')
      }
      if (!val) {
        delete newFilters[key]
      }
    }
    gridContext.advancedFilters = advancedFilters
    gridContext.matchFilters = newFilters

    await nextTick()
    await config.fetchData()
  }

  async function clearFilterValue(key, fullValue) {
    if (gridContext.matchFilters[key]) {
      gridContext.matchFilters[key] = typeof gridContext.matchFilters[key] === 'boolean' ? false : ''
    }
    if (gridContext.displayMatchFilters[key]) {
      gridContext.displayMatchFilters[key] = typeof gridContext.displayMatchFilters[key] === 'boolean' ? false : ''
    }
    if (typeof fullValue === 'object' && fullValue.advanced) {
      let index = gridContext.advancedFilters.findIndex(f => f.key === fullValue.key)
      if (index !== -1) {
        gridContext.advancedFilters.splice(index, 1)
      }
    }

    await nextTick()
    await config.fetchData()
  }

  function getFilterDefaults() {
    let map = {}
    if (!gridContext.filterOptions.matches) {
      return {}
    }
    gridContext.filterOptions.matches.forEach(f => {
      if (typeof f.type === 'object' || f.type === 'boolean' || f.type === 'bool') {
        map[f.column] = false
      } else if (f.type === 'multiselect') {
        map[f.column] = []
      } else {
        map[f.column] = ''
      }
    })

    return {
      ...map,
      ...gridContext.displayMatchFilters,
    }
  }

  function onChangeDisplayFilters(filters) {
    if (!filters) {
      return
    }
    let newFilters = {
      ...filters,
    }
    for (let key in newFilters) {
      if (!newFilters[key]) {
        delete newFilters[key]
      }
    }
    gridContext.displayMatchFilters = newFilters
  }

  return {
    getFilterConfig: getFullFilterConfig,
    onChangeFilers,
    clearFilterValue,
    getFilterDefaults,
    onChangeDisplayFilters,
    showFiltersDropdown,
    hasFilters,
    canFilter,
  }
}
