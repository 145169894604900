<template>
  <div>
    <base-form
        :title="showTitle ? $t('Update Line Item Progress') : undefined"
        :focus-on-first-input="false"
        :save-text="$t('Update Progress')"
        :update-text="$t('Update Progress')"
        :show-cancel="true"
        :loading="loading"
        layout="vertical"
        @cancel="onCancel"
        @submit="onSubmit"
    >
      <div class="text-lg col-span-6 font-medium text-center mb-4">
        {{$t('Line Item')}} {{ composeLineItemName(data?.attributes) }} <template v-if="data.attributes?.description">({{data?.attributes.description}})</template>
      </div>
      <div class="tip mb-2 col-span-6">{{ $t('Double click or press Enter on the white cells to edit ') }}</div>
      <AgDataTable
          :limitMaxHeight="false"
          :data="budgets"
          :columns="columns"
          :pagination="false"
          :suppressAggFuncInHeader="true"
          :groupIncludeFooter="true"
          :groupIncludeTotalFooter="true"
          domLayout="autoHeight"
          class="col-span-6"
      >
        <template #name="{ row }">
          {{ getJobTypeName(row) }}
        </template>
      </AgDataTable>
    </base-form>
  </div>
</template>
<script>
  import sumBy from 'lodash/sumBy'
  import cloneDeep from 'lodash/cloneDeep'
  import axios from "axios";
  import { requiredValueSetter } from "@/components/ag-grid/columnUtils";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import { RestifyResources } from "@/components/form/util";
  import { composeLineItemName } from "@/utils/utils";

  export default {
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        loading: false,
        budgets: []
      }
    },
    computed: {
      totals() {
        let totals = {
          amount: sumBy(this.budgets, 'amount'),
          amount_to_date: sumBy(this.budgets, 'amount_to_date'),
          est_final_cost: sumBy(this.budgets, 'est_final_cost'),
          variance: sumBy(this.budgets, this.getVariance),
          completion: 0,
        }
        totals.completion = totals.amount_to_date / totals.est_final_cost * 100
        return totals
      },
      initialBudgets() {
        return this.data?.relationships?.budgets || []
      },
      columns() {
        return [
          {
            label: this.$t('Type'),
            prop: 'name',
            minWidth: 120,
            maxWidth: 200,
            valueGetter: params => {
              return this.getJobTypeName(params.data)
            },
            cellClass: 'bg-gray-50',
          },
          {
            label: this.$t('Budget'),
            prop: 'amount',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 80,
            maxWidth: 150,
            cellClass: 'bg-gray-50',
            aggFunc: 'sum',
          },
          {
            label: this.$t('To Date'),
            prop: 'amount_to_date',
            component: 'FormattedPrice',
            align: 'right',
            minWidth: 80,
            maxWidth: 150,
            cellClass: 'bg-gray-50',
            aggFunc: 'sum',
          },
          {
            label: this.$t('% Est Completion'),
            prop: 'completion',
            component: 'FormattedPercent',
            minWidth: 80,
            maxWidth: 150,
            align: 'right',
            editable: true,
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const validator = value => {
                value = +value
                return value >= 0 && value <= 100 && !isNaN(value)
              }
              let isValid = requiredValueSetter(params, 0, validator)
              if (!isValid) {
                return false
              }
              params.data.completion = +params.newValue
              this.onCompletionUpdate(params.data, params.newValue)
              return true
            },
            aggFunc: () => {
              return this.totals.completion
            },
          },
          {
            label: this.$t('Est Final Cost'),
            prop: 'est_final_cost',
            component: 'FormattedPrice',
            minWidth: 80,
            maxWidth: 200,
            align: 'right',
            editable: true,
            cellEditor: cellEditors.Numeric,
            valueSetter: params => {
              const isValid = requiredValueSetter(params, 0)
              if (!isValid) {
                return false
              }
              params.data.est_final_cost = +params.newValue
              this.onEstimatedFinalCostUpdate(params.data, params.newValue)
              return true
            },
            aggFunc: 'sum',
          },
          {
            label: this.$t('Variance'),
            prop: 'variance',
            minWidth: 80,
            align: 'right',
            cellClass: 'bg-gray-50',
            valueGetter: params => {
              if (!params.data) {
                return 0
              }
              return this.getVariance(params.data)
            },
            valueFormatter: params => {
              return this.$formatPrice(params.value)
            },
            aggFunc: 'sum',
          },
        ]
      }
    },
    methods: {
      sumBy,
      composeLineItemName,
      getVariance(row) {
        const { amount, est_final_cost } = row
        return amount - est_final_cost
      },
      getJobTypeName(row) {
        const { job_type_id } = row || {}
        if (!job_type_id) {
          return ''
        }
        const jobType = this.$store.getters['jobCosting/getJobTypeById'](job_type_id)
        return jobType?.name
      },
      onCompletionUpdate(row, value) {
        const { amount_to_date, amount } = row
        value = +value

        if (value <= 0 || value > 100) {
          return
        }
        if (amount_to_date <= 0 && amount > 0) {
          row.est_final_cost = row.amount
          this.$warning(this.$t('Amount to date is currently $0 for this budget. Changing the % completion is not allowed until the amount to date is greater than $0.'))
          return
        }
        const newAmount = amount_to_date * 100 / value
        row.est_final_cost = this.round(newAmount)
      },
      onEstimatedFinalCostUpdate(row, value) {
        const { amount_to_date } = row
        value = +value
        let newCompletion = amount_to_date / value * 100
        if (newCompletion > 100) {
          newCompletion = 99
        }
        row.completion = this.round(newCompletion)
      },
      mapBudgets(budgets) {
        return budgets.map(budget => {
          return {
            id: budget.id,
            ...budget.attributes,
          }
        })
      },
      onCancel() {
        this.budgets = this.mapBudgets(cloneDeep(this.initialBudgets))
        this.$emit('close')
      },
      getRequestPayload() {
        const changes = {}
        this.budgets.forEach(type => {
          const { id, completion, est_final_cost } = type
          const previousBudget = this.initialBudgets.find(t => t.id === type.id)?.attributes
          const previousCompletion = previousBudget.completion
          const previousEstFinalCost = previousBudget.est_final_cost

          if (completion !== previousCompletion || est_final_cost !== previousEstFinalCost) {
            changes[id] = {
              completion,
              est_final_cost,
            }
          }
        })
        return {
          changes
        }
      },
      async onSubmit() {
        try {
          this.loading = true
          const lineItemId = this.data.id
          const data = this.getRequestPayload()
          await axios.post(`/restify/line-items/${lineItemId}/actions?action=progress-update`, data)

          this.$emit('save')
          await this.getLineItem(lineItemId)
          await this.$addSystemGeneratedNote({
            resourceName: RestifyResources.LineItems,
            resourceId: this.data.id,
            isEdit: true
          })
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not update the progress of the line item'))
        } finally {
          this.loading = false
        }
      },
      async getLineItem(id) {
        if (!id) {
          return
        }
        await this.$store.dispatch('jobCosting/getLineItem', id)
      }
    },
    watch: {
      initialBudgets: {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.budgets = this.mapBudgets(cloneDeep(value))
        }
      }
    }
  }
</script>
