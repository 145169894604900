<template>
  <EntitySelect
    url="/restify/repair-order-entries"
    v-bind="$attrs"
    v-on="$listeners"
    :filter-options="filterOptions"
    :transformData="transformData"
    :url-params="allUrlParams"
    :customGetFullValue="getFullValue"
    :addEntity="false"
    :ignoreInitialOption="true"
    :name="$attrs.name || $t('Repair Order Entry')"
    :placeholder="$attrs.placeholder || $t('Select Repair Order Entry')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Repair Order Entry')"
    :id="$attrs.id || 'repair-order-entries-select'"
    :label-format="formatLabel"
    :hide-label="hideLabel"
    :value-key="valueKey"
    label-key="description"
    class="w-full"
  >
    <template #options-container>
      <ElOptionGroup
        v-for="group in groupedOptions"
        :key="group.id"
        :label="group.label"
      >
        <ElOption
          v-for="option in group.options"
          :key="option.id"
          :value="option.id"
          :label="formatLabel(option)"
        >
          {{ formatOptionLabel(option) }}
        </ElOption>
      </ElOptionGroup>
    </template>
  </EntitySelect>
</template>
<script>
import { equipmentCostTypeFor } from '@/enum/equipment'
import { 
  Option as ElOption,
  OptionGroup as ElOptionGroup,
} from 'element-ui'

export default {
  name: 'RepairOrderEntrySelect',
  inheritAttrs: false,
  components: {
    [ElOption.name]: ElOption,
    [ElOptionGroup.name]: ElOptionGroup,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: '-number',
      }),
    },
    repair_order_id: {
      type: String,
      default: null,
    },
    equipment_id: {
      type: String,
      default: null,
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    showROColumn: {
      type: Boolean,
      default: true,
    },
    filterAvailableInTimesheets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupedOptions: []
    }
  },
  computed: {
    allUrlParams() {
      return {
        ...this.urlParams,
        repair_order_id: this.repair_order_id,
        equipment_id: this.equipment_id,
        related: 'repairOrder',
      }
    },
    equipmentCostTypes() {
      return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.EquipmentTypes) || []
    },
    timesheetCostTypeIds() {
      return this.equipmentCostTypes.filter(t => t.for === equipmentCostTypeFor.Repairs).map(t => t.id)
    }
  },
  methods: {
    getFullValue(value) {
      for (const group of this.groupedOptions) {
        const option = group.options.find(o => o.id === value)
        if (option) {
          return option
        }
      }

      return null
    },
    transformData(options) {
      const groupsMap = {}

      for (const option of options) {
        const result = {
          ...option.attributes,
          ...(option.relationships || {}),
        }

        const costType = this.equipmentCostTypes.find(t => t.id === result.type_id)        
        result.cost_type = costType?.name || ''

        if (!groupsMap[result.repair_order_id]) {
          groupsMap[result.repair_order_id] = {
            label: `RO #${this.get(result, 'repairOrder.attributes.number')}`,
            id: result.repair_order_id,
            options: [],
          }
        }

        groupsMap[result.repair_order_id].options.push(result)
      }


      this.groupedOptions = Object.values(groupsMap)
      return this.groupedOptions
    },
    filterOptions(options) {
      if (this.excludeIds.length) {
        options = options.filter(o => {
          return !this.excludeIds.includes(o.id)
        })
      }

      return options
    },
    formatLabel(option) {
      const ro_number = this.get(option, 'repairOrder.attributes.number')
      const { cost_type, description } = option
      return `RO#${ro_number} ${cost_type}  - ${description || ''}`
    },
    formatOptionLabel(option) {
      const { cost_type, description } = option
      return `${cost_type}  - ${description || ''}`
    }
  },
}
</script>
