import { costCenterTypes, costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";
import { globalResources } from "@/components/form/util";
import store from "@/store";
import { $modules, equipmentTypeWithMaintenance } from "@/enum/enums";
import { getDefaultAccounts } from "@/modules/common/util/costCenterUtils";
import { getSetting } from "@/plugins/settingsAndEnumsPlugin";
import { getDatesFromRange } from "@/modules/payroll/components/timecard/util";
import { round } from "lodash";
import { addMinutes, addWeeks, isSameDay, subDays } from "date-fns";
import sumBy from "lodash/sumBy";
import { ApiDateFormat, formatDate, formatTime } from "@/plugins/dateFormatPlugin";
import orderBy from "lodash/orderBy";
import parse from "date-fns/parse";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import format from "date-fns/format";
import { getRoundedDate, getSettingsStartTime } from "@/modules/payroll/utils/timesheetUtils";

export function getTotals(entries) {
  const totals = {
    regular_hours: 0,
    overtime_hours: 0,
    premium_hours: 0,
    total_hours: 0,
    regular_amount: 0,
    overtime_amount: 0,
    premium_amount: 0,
    special_amount: 0,
    total_amount: 0,
  }
  entries.forEach(entry => {
    const { regular_hours, overtime_hours, premium_hours } = entry?.attributes || entry
    const rowTotals = getTotalForRow(entry)
    totals.regular_hours += +regular_hours || 0
    totals.overtime_hours += +overtime_hours || 0
    totals.premium_hours += +premium_hours || 0
    totals.total_hours = totals.regular_hours + totals.overtime_hours + totals.premium_hours

    totals.regular_amount += rowTotals.regular_amount
    totals.overtime_amount += rowTotals.overtime_amount
    totals.premium_amount += rowTotals.premium_amount
    totals.special_amount += rowTotals.special_amount
  })
  totals.regular_hours = round(totals.regular_hours, 2)
  totals.overtime_hours = round(totals.overtime_hours, 2)
  totals.premium_hours = round(totals.premium_hours, 2)
  totals.total_hours = round(totals.total_hours, 2)

  totals.total_amount = totals.regular_amount + totals.overtime_amount + totals.premium_amount + totals.special_amount
  return totals
}

export function getTotalForRow(row) {
  const totals = {
    regular_amount: 0,
    overtime_amount: 0,
    premium_amount: 0,
    special_amount: 0,
    total_amount: 0,
  }
  const {
    regular_hours,
    overtime_hours,
    premium_hours,
    regular_rate,
    overtime_rate,
    premium_rate,
    special_rate,
    units,
  } = row?.attributes || row

  totals.regular_amount = regular_hours * regular_rate
  totals.overtime_amount = overtime_hours * overtime_rate
  totals.premium_amount = premium_hours * premium_rate
  totals.special_amount = units * special_rate
  totals.total_amount = totals.regular_amount + totals.overtime_amount + totals.premium_amount + totals.special_amount
  return totals
}

export function getLaborTypeId(costCenter) {
  const jobCostLaborType = store.getters['globalLists/getLaborTypeIdByModule'](globalResources.JobCostTypes)
  const serviceBillingLaborType = store.getters['globalLists/getLaborTypeIdByModule'](globalResources.ServiceBillingCostTypes)
  return costCenter === costCenterTypes.Job ? jobCostLaborType : serviceBillingLaborType
}

export function getEmployeeDefaults(employee) {
  const {
    source_id,
    source_type,
    addl_source_id,
    addl_source_type,
    cost_center,
    account,
    subaccount,
    type_id,
  } = employee || {}

  const defaultCostCenter = cost_center || costCenterTypes.GeneralAndAdministrative
  const defaultTypeId = [costCenterTypes.Job, costCenterTypes.WorkOrder].includes(defaultCostCenter) ? getLaborTypeId(defaultCostCenter) : type_id

  return {
    source_id,
    source_type,
    addl_source_id,
    addl_source_type,
    account,
    subaccount,
    type_id: defaultTypeId,
    cost_center: defaultCostCenter,
  }
}

export async function trySetDefaultAccounts(entry, employee) {
  if (entry.cost_center === costCenterTypes.GeneralAndAdministrative) {
    return getEmployeeAccounts(entry, employee)
  }

  entry.cost_or_income = costOrIncomeTypes.Cost
  return await getDefaultAccounts(entry)
}

export const RateFrom = {
  Undefined: 'undefined',
  UserDefined: 'user-defined',
}

export function getOverrideFields() {
  return {
    union_from: RateFrom.Undefined,
    craft_code_from: RateFrom.Undefined,
    sui_sdi_state_from: RateFrom.Undefined,
    withholding_state_from: RateFrom.Undefined,
    withholding_local_from: RateFrom.Undefined,
    withholding_local_2_from: RateFrom.Undefined,
    gen_liability_state_from: RateFrom.Undefined,
    workers_comp_state_from: RateFrom.Undefined,
    gen_liability_rate_from: RateFrom.Undefined,
    workers_comp_rate_from: RateFrom.Undefined,
    certified_payroll_exempt_from: RateFrom.Undefined,
    account_from: RateFrom.Undefined,
    subaccount_from: RateFrom.Undefined,
    special_account_from: RateFrom.Undefined,
    special_subaccount_from: RateFrom.Undefined,
    overtime_rate_from: RateFrom.Undefined,
    premium_rate_from: RateFrom.Undefined,
    regular_rate_from: RateFrom.Undefined,
    special_rate_from: RateFrom.Undefined,
    sub_trade_from: RateFrom.Undefined,
  }
}

export function getEmptyEntry({ employee, days, previousRow, order }) {
  const {
    source_id,
    source_type,
    addl_source_id,
    addl_source_type,
    cost_center,
    type_id,
  } = getEmployeeDefaults(employee)

  const firstDay = days[0]
  let day, date = null
  if (firstDay) {
    date = firstDay.value
    day = 1
  }
  if (previousRow) {
    date = previousRow.date
    day = previousRow.day
  }

  let newEntry = {
    // main fields
    _localId: crypto.randomUUID(),
    order,
    date: date,
    day: day,
    cost_center,
    source_id: source_id || '',
    source_type: source_type || '',
    addl_source_id: addl_source_id || '',
    addl_source_type: addl_source_type || '',
    type_id: type_id || '',
    special_source_type: '',
    special_source_id: '',
    regular_hours: 0,
    overtime_hours: 0,
    premium_hours: 0,
    units: 0,
    //Overrides
    account: null,
    craft_code_id: null,
    gen_liability_rate_code: null,
    has_override: false,
    is_certified_payroll_exempt: false,
    local_id: null,
    overtime_rate: 0,
    premium_rate: 0,
    regular_rate: 0,
    special_account: null,
    special_rate: 0,
    special_subaccount: null,
    state_id: null,
    subaccount: null,
    timecard_id: null,
    union_id: null,
    workers_comp_rate_code: null,
    withholding_state_id: null,
    sui_sdi_state_id: null,
    gen_liability_state_id: null,
    workers_comp_state_id: null,
    withholding_local_id: null,
    withholding_local_2_id: null,
    sub_trade: null,
    meta: {},
    // override from
    ...getOverrideFields(),
  }

  return newEntry
}

function getEmployeeAccounts(row, employee) {
  const { account, subaccount } = employee || {}
  row.account = account
  row.subaccount = subaccount

  return row
}


function getEquipmentTypeIdWithMaintenance() {
  return store.getters['globalLists/getEquipmentTypeIdWithMaintenance']
}

export function getTypeByCostCenter(costCenter) {
  let type_id = ''
  let type_type = ''

  if (costCenter === costCenterTypes.Equipment) {
    type_id = getEquipmentTypeIdWithMaintenance()
    type_type = equipmentTypeWithMaintenance
  }

  if ([costCenterTypes.Job, costCenterTypes.WorkOrder].includes(costCenter)) {
    type_id = getLaborTypeId(costCenter)
  }
  return {
    type_id,
    type_type,
  }
}

export function getCostCenterRowDefaults(row, employee) {
  const costCenter = row.cost_center || costCenterTypes.GeneralAndAdministrative
  let type_id = ''
  let type_type = ''

  if (costCenter === costCenterTypes.Equipment) {
    type_id = getEquipmentTypeIdWithMaintenance()
    type_type = equipmentTypeWithMaintenance
  }

  row = {
    ...row,
    quantity: 0,
    source_id: '',
    source_type: '',
    addl_source_id: '',
    addl_source_type: '',
    type_id,
    type_type,
    special_source_type: '',
    special_source_id: '',
    units: 0,
    special_rate: 0,
    special_subaccount: null,
    special_account: null,
    account_from: RateFrom.Undefined,
    subaccount_from: RateFrom.Undefined,
    special_account_from: RateFrom.Undefined,
    special_subaccount_from: RateFrom.Undefined,
    special_rate_from: RateFrom.Undefined,
  }

  if ([costCenterTypes.Job, costCenterTypes.WorkOrder].includes(costCenter)) {
    row.type_id = getLaborTypeId(costCenter)
  }

  const costCenterDefaults = getEmployeeDefaults(employee)

  if (costCenterDefaults.cost_center === costCenter && employee) {
    Object.keys(costCenterDefaults).forEach(key => {
      row[key] = costCenterDefaults[key]
    })
  }

  if (costCenter === costCenterTypes.GeneralAndAdministrative && employee) {
    row = getEmployeeAccounts(row, employee)
  }

  return row
}

export function isRowValid(row) {
  if (!row) {
    return false
  }
  let requiredFields = ['date', 'cost_center']
  let requiredFieldMapping = {
    [costCenterTypes.GeneralAndAdministrative]: [],
    [costCenterTypes.Job]: ['source_id', 'type_id', 'addl_source_id'],
    [costCenterTypes.Equipment]: ['source_id', 'type_id'],
    [costCenterTypes.WorkOrder]: ['source_id', 'type_id'],
    default: [],
  }

  const { cost_center, special_source_type, units } = row
  const extraRequiredFields = requiredFieldMapping[cost_center] || requiredFieldMapping.default

  let allRequiredFields = [...requiredFields, ...extraRequiredFields]

  if (special_source_type) {
    allRequiredFields.push('special_source_id')
    allRequiredFields.push('units')
  }

  let requiredFieldsInvalid = allRequiredFields.some(field => !row[field])
  if (special_source_type && !+units) {
    requiredFieldsInvalid = true
  }

  return requiredFieldsInvalid === false
}

export function validateTimeCardEntries(data = []) {
  return data.every(row => isRowValid(row))
}

export function getTimeCardBatchDays(periodEndDate) {
  if (!periodEndDate) {
    return []
  }
  const is_biweekly_payroll = getSetting($modules.PR, 'is_biweekly_payroll')
  const range = is_biweekly_payroll ? 14 : 7
  return getDatesFromRange(periodEndDate, range)
}

export function getTimeCardBatchStartDate(periodEndDate) {
  if (!periodEndDate) {
    return null
  }
  const endDate = parse(periodEndDate, ApiDateFormat, new Date())
  const is_biweekly_payroll = getSetting($modules.PR, 'is_biweekly_payroll')
  const range = is_biweekly_payroll ? 14 : 7
  return subDays(endDate, range - 1)
}

export function getTimeCardPaymentDate(periodEndDateStr) {
  if (!periodEndDateStr) {
    return null
  }
  let periodEndDate = periodEndDateStr
  if (typeof periodEndDateStr === 'string') {
    periodEndDate = parse(periodEndDateStr, ApiDateFormat, new Date())
  }
  const endDayOfWeek = periodEndDate.getDay();
  const paymentDayNumber = getSetting($modules.PR, 'payroll_period_payment_day')
  if (paymentDayNumber === null) {
    return null
  }
  let daysToAdd = paymentDayNumber - endDayOfWeek;
  if (daysToAdd <= 0) {
    daysToAdd += 7;
  }
  const paymentDate = new Date(periodEndDate);
  paymentDate.setDate(periodEndDate.getDate() + daysToAdd);
  return format(paymentDate, ApiDateFormat);
}


export function minutesToHours(value, showZero = false) {
  if (!value && showZero) {
    return '00:00'
  }
  if (!value) {
    return ''
  }
  const hours = Math.floor(value / 60)
  const hoursStr = hours.toString().padStart(2, '0')
  const minutes = value % 60
  const minutesStr = minutes.toString().padStart(2, '0')
  return `${hoursStr}:${minutesStr}`
}

export function getTotalMinutesForDay(timeLogs, dayToFilter) {
  const logs = getTimeSheetsForDay(timeLogs, dayToFilter)
  return sumBy(logs, 'attributes.duration')
}

export function getTimesheet(timeLogs, dayToFilter) {
  const logs = getTimeSheetsForDay(timeLogs, dayToFilter)
  if (!logs.length) {
    return null
  }
  return logs[0]?.attributes?.timesheet
}

export function getTimeSheetsForDay(timeLogs, date) {
  const logs = timeLogs.filter((timeLog) => {
    // @ts-expect-error
    const day = parse(timeLog.attributes?.date, 'yyyy-MM-dd', new Date())
    return isSameDay(day, date)
  })
  return orderBy(logs, 'attributes.start_time', 'asc')
}

export function getStartTime(timeLogs, date) {
  let logs = getTimeSheetsForDay(timeLogs, date)
  logs = orderBy(logs, 'attributes.start_time', 'asc')
  if (logs.length === 0) {
    return getRoundedDate(getSettingsStartTime())
  }
  const lastLog = logs[logs.length - 1] || {}
  const clonedLog = cloneDeep(lastLog)
  if (clonedLog.attributes.start_time?.length > 5) {
    clonedLog.attributes.start_time = clonedLog.attributes.start_time.slice(0, 5)
  }
  const startTime = parse(clonedLog.attributes.start_time, 'HH:mm', date)
  const newStartTime = addMinutes(startTime, clonedLog.attributes.duration)
  return getRoundedDate(newStartTime)
}


export function getStartTimeForDate(timeEntries, date, timeFormat = false) {
  const startTime = getStartTime(timeEntries, new Date(date))
  if (timeFormat) {
    return formatTime(startTime)
  }
  const hours = startTime.getHours().toString().padStart(2, '0')
  const minutes = startTime.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export function parseHoursToMinutes(value) {
  const parts = value.split(':')
  if (parts.length === 2) {
    let minutes = +parts[1]
    let hours = +parts[0]
    if (isNaN(hours)) {
      hours = 0
    }
    if (isNaN(minutes)) {
      minutes = 0
    }
    return hours * 60 + minutes
  }
  if (parts.length === 1) {
    const hours = parseFloat(parts[0])
    if (isNaN(hours)) {
      return 0
    }
    return hours * 60
  }
  return 0
}

export function getUnitsFromDuration(duration) {
  if (!duration) {
    return 0
  }
  const units = duration / 60
  return +units.toFixed(2)
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export async function suggestNextPayPeriod() {
  const { data } = await axios.get('/restify/timecard-batches', {
    params: {
      perPage: 1,
      sort: '-period_end_date'
    }
  })
  const lastBatch = data[0]
  if (!lastBatch) {
    return null
  }
  const { period_end_date } = lastBatch.attributes
  const lastPeriodEndDate = parse(period_end_date, ApiDateFormat, new Date())
  const isBiWeeklyPayroll = getSetting($modules.PR, 'is_biweekly_payroll')
  const weeksToAdd = isBiWeeklyPayroll ? 2 : 1
  const newEndDate = addWeeks(lastPeriodEndDate, weeksToAdd)
  if (isNaN(newEndDate.getTime())) {
    return null
  }
  return newEndDate
}
