<template>
  <div class="flex items-center space-x-1">
    <span>{{ fullHumanReadableText }}</span>
  </div>
</template>
<script>
import { rrulestr } from 'rrule'
import upperFirst from "lodash/upperFirst";

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    invoice: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    rule() {
      try {
        return rrulestr(this.value)
      } catch (err) {
        return null
      }
    },
    startDate() {
      return this.rule?.options?.dtstart || null
    },
    hasIssuedInvoice() {
      if (!this.invoice) {
        return false
      }
      return this.invoice?.attributes?.last_issue_at
    },
    humanReadableText() {
      try {
        const text = this.rule.toText() || ''
        return upperFirst(text)
      } catch (err) {
        console.warn('Failed parsing rrule', this.value)
      }
    },
    fullHumanReadableText() {
      let fullText = this.humanReadableText

      if (this.startDate && !this.hasIssuedInvoice) {
        fullText += ` ${this.$t('starting')} ${this.$formatDateOnly(this.startDate)}`
      }

      return fullText
    }
  },
  watch: {
    fullHumanReadableText: {
      handler(value) {
        this.$emit('change', value)
      },
      immediate: true
    }
  }
}
</script>
