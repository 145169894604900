<template>
  <BaseSelect
    v-on="$listeners"
    :name="$t('Crew')"
    :placeholder="$attrs.placeholder || $t('Crew')"
    :label="$attrs.label || $t('Crew')"
    v-bind="$attrs"
    :options="data"
    value-key="id"
    label-key="attributes.name"
    id="crew"
  />
</template>
<script>
import i18n from "@/i18n";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";

export default {
  props: {
    data: {
      type: Array,
      default: () => null
    }
  },
}
</script>
