import {ValueFormatterParams} from '@ag-grid-community/core'
import {formatPrice} from "@/plugins/formatPrice";

export function priceFormatter(params: ValueFormatterParams) {
    return formatPrice(params.value)
}

export function getRateDecimalPlaces(value: number) {
  const decimalPlaces = value.toString().split('.')[1]
  return Math.max(decimalPlaces ? decimalPlaces.length : 0, 2)
}

export function formatRate(value: number) {
  return formatPrice(value, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
  })
}
