export interface FreeObject {
  [key: string | number]: any
}

export type ContactModel = {
  _localId?: string
  id?: string
  name: string,
  email: string | null,
  checked?: boolean,
}

export interface CustomError extends Error {
  handled?: boolean
}

export enum ResourceUsedOnTypes {
  JobCostingInit = 'job-costing-init',
  AccountsReceivableInit = 'accounts-receivable-init',
  AccountsPayableInit = 'accounts-payable-init',
  Billing = 'billing',
  BillingPayment = 'billing-payment',
  Invoice = 'invoice',
  InvoicePayment = 'invoice-payment',
  Payroll = 'payroll',
  PurchaseOrder = 'purchase-order',
  PurchaseOrderAdjustment = 'purchase-order-adjustment',
  RecurringInvoice = 'recurring-invoice',
  RecurringBilling = 'recurring-billing',
  Task = 'task',
  Timecard = 'timecard',
  TimesheetEntry = 'timesheet-entry',
  LineItem = 'line-item',
  JobPreNotice = 'job-pre-notice',
  Employee = 'employee',
  Job = 'job',
  Vendor = 'vendor',
  Vendor1099 = 'vendor1099',
  Customer = 'customer',
  Account = 'account',
  BenefitCode = 'benefit-code',
  PayCode = 'pay-code',
  TaxCode = 'tax-code',
  DeductionCode = 'deduction-code',
  RetirementPlan = 'retirement-plan',
  Proposal = 'proposal',
  JobRate = 'job-rate',
  UnionRate = 'union-rate',
  TimeOffRate = 'time-off-rate',
  CustomerRetention = 'customer-retention',
  VendorRetention = 'vendor-retention',
  WorkItem = 'work-item',
  CustomerBillingRate = 'billing-rate-type',
  WorkSite = 'work-site',
  WorkOrder = 'work-order',
  ReceiveMaterial = 'receive-material',
  Material = 'material',
  Equipment = 'equipment',
  EquipmentAdjustment = 'equipment-adjustment',
  EquipmentMaintenance = 'equipment-maintenance',
  EquipmentDepreciation = 'equipment-depreciation',
  GLSettings = 'gl-settings',
  JCSettings = 'jc-settings',
  APSettings = 'ap-settings',
  PRSettings = 'pr-settings',
  ARSettings = 'ar-settings',
  SBSettings = 'sb-settings',
  EQSettings = 'eq-settings',
  IVSettings = 'iv-settings',
  ServiceBillingHistory = 'service-billing-history',
  EquipmentHistory = 'equipment-history',
}

export type ResourceUsageType = {
  id: string
  type: ResourceUsedOnTypes
  description: string
  billing_type?: string
  line_item_type?: string
  batch_id?: string
}
