<template>
  <BarChartCard
      ref="chart"
      :url-params="urlParams"
      :title="title"
      :format-labels-as-price="true"
      :options="chartOptions"
      response-prop="billings"
      url="/dashboard/accounts-receivable/monthly-billing"
      @data-fetch="onDataFetch"
  >
    <template #filters>
      <div class="mr-4">
        <base-checkbox
            v-model="compareWithPreviousYear"
            id="compare-data"
            :label="$t('Compare mode')"
        />
      </div>
    </template>
  </BarChartCard>
</template>
<script>
  import { resourceStatuses } from "@/enum/enums";
  import BarChartCard from "@/components/charts/BarChartCard";
  import groupBy from "lodash/groupBy";
  import uniq from "lodash/uniq";
  import { verticalDataAxis } from "@/components/charts/chartOptions";
  import { barChartSeriesConfig } from "@/modules/accounts-receivable/pages/dashboard/widgets/chartUtils";
  import { ReportIntervals } from "@/modules/accounts-receivable/utils/billingUtils";
  import format from "date-fns/format";
  import parse from "date-fns/parse";

  export default {
    components: {
      BarChartCard,
    },
    data() {
      return {
        compareMonths: false,
        chartOptions: {
          series: [],
          labels: [],
        },
        compareWithPreviousYear: false,
      }
    },
    computed: {
      urlParams() {
        return {
          show: resourceStatuses.Paid,
          interval: ReportIntervals.Monthly,
        };
      },
      title() {
        return this.$t(`Billings posted`);
      },
    },
    methods: {
      async onCompareChange() {
        const ref = this.$refs.chart
        await ref.getData();
      },
      onDataFetch(data) {
        const mappedData = data.map(row => {
          const date = parse(row.label, 'yyyy-MM', new Date())
          return {
            ...row,
            value: +row.value,
            label: format(date, 'MMMM yyyy'),
            month: format(date, 'MMMM'),
          }
        })
        let labels = []
        let series = []
        if (this.compareWithPreviousYear) {
          const options = this.getCompareSeries(mappedData)
          series = options.series
          labels = options.labels
        } else {
          labels = mappedData.map(row => row.label)
          series = [
            {
              ...barChartSeriesConfig,
              name: `Posted billings`,
              data: mappedData.map(row => row.value),
            }
          ]
        }
        const xAxis = {
          ...verticalDataAxis,
          data: labels,
        }
        this.chartOptions.labels = labels
        this.chartOptions.series = series
        this.chartOptions.xAxis = xAxis
      },
      getCompareSeries(data) {
        const labels = uniq(data.map(row => row.month))
        const monthCount = labels.length
        const groupedData = groupBy(data, 'year');
        const series = []
        for (let key in groupedData) {
          const data = []
          for (let i = 0; i < monthCount; i++) {
            const month = labels[i]
            const row = groupedData[key].find(row => row.month === month)
            data.push(row ? row.value : null)
          }

          series.push({
            ...barChartSeriesConfig,
            name: key,
            data: data
          })
        }
        return {
          labels,
          series,
        }
      }
    },
    watch: {
      compareWithPreviousYear() {
        this.onCompareChange()
      }
    }
  }
</script>
<style>
</style>
