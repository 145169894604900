<template>
  <BaseFilterRow
    :title="title"
  >
    <template #title-after>
      <BaseSwitch
        v-model="selectList"
        inline
        showYesNoLabel
        width="w-[70px]"
        :labelYes="$t('List')"
        :labelNo="$t('Range')"
        @change="$emit('change')"
      />
    </template>
    <div :class="`${colspan}`">
      <slot :selectList="selectList" />
    </div>
    <slot name="extra-filters" />
  </BaseFilterRow>
</template>
<script>
export default {
  name: 'BaseEntityFilterRow',
  props: {
    title: {
      type: String,
      required: true,
    },
    colspan: {
      type: String,
      default: 'col-span-4',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    listKey: {
      type: String,
      default: 'ids',
    },
  },
  data() {
    return {
      selectList: false,
    }
  },
  computed: {
    listValue() {
      return this.value[this.listKey]
    }
  },
  watch: {
    [`listValue.length`]: {
      handler(value) {
        if (this.selectList || !value) {
          return
        }

        this.selectList = true
      },
      immediate: true,
    }
  }
}
</script>
