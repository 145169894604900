import tailwindColors from 'tailwindcss/colors'

export const fontStyles = {
  fontFamily: 'Inter var',
}

export const textStyle = {
  ...fontStyles,
}

export const colors = {
  dark: tailwindColors.gray[900],
  light: tailwindColors.gray[500],
  lightLine: tailwindColors.gray[100],
  lightBg: 'white',
  lightInactiveColor: tailwindColors.gray[300],
}

export const labelAxis = {
  axisLine: {
    show: false
  },
  axisTick: {
    show: false
  },
  axisLabel: {
    letterSpacing: '0.5px',
  },
  splitLine: {
    lineStyle: {}
  },
  type: 'value',
}
export const verticalDataAxis = {
  data: [],
  axisLabel: {
    fontSize: 12,
    overflow: 'truncate',
    lineOverflow: 'truncate',
    fontWeight: 'normal',
    clickable: true,
    hideOverlap: true,
    letterSpacing: '0.5px',
    color: colors.light,
    formatter(label) {
      const maxLines = 3
      const maxLen = 5
      let lines = label.split(' ')
      if (lines.length > maxLines) {
        let [line1, line2, ...remainingLines] = lines
        let lastLine = remainingLines.join(' ')
        if (lastLine.length > maxLen) {
          lastLine = `${lastLine.substr(0, maxLen)}...`
        }
        lines = [line1, line2, lastLine]
      }
      return lines.join('\n')
    }
  },
  axisTick: {
    show: false
  },
  axisLine: {
    show: false
  },
  type: 'category',
}
export const chartOptions = {
  legend: {
    show: true,
    bottom: 0,
    right: 20,
    itemGap: 14,
    itemHeight: 16,
    itemWidth: 16,
    icon: 'circle',
    textStyle: {
      fontWeight: 600,
      fontSize: 14
    },
  },
  tooltip: {
    show: true,
    trigger: 'axis',
  },
  textStyle: textStyle,
}
