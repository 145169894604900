<template>
  <div>
    <DetailLayout
      :loading="dailyReportLoading"
      :current-resource="currentResource"
      :get-current-resource="getCurrentResource"
      :items="items"
    >
      <template #extra-buttons>
        <div class="flex-1">
          <StimulsoftPrintButton
            v-if="stimulsoftEntity"
            :url="`/restify/daily-job-reports/stimulsoft`"
            :url-params="printUrlParams"
            :entity="stimulsoftEntity"
            :extraData="printOptions"
            :transform-data="transformPrintData"
            :alwaysOpenDialog="true"
          />
        </div>
      </template>
      <template #page-title>
        <span>
          {{$t('Daily Job Report ')}}
          <template v-if="currentResource?.attributes?.date">
            {{ $formatDateOnly(currentResource?.attributes?.date) }}
          </template>
          <StatusBadge :status="currentResource.attributes.status"/>
        </span>
      </template>
    </DetailLayout>
  </div>
</template>
<script>
import DetailLayout from "@/modules/common/components/DetailLayout.vue";
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";

export default {
  components: {
    DetailLayout
  },
  data() {
    return {
      stimulsoftEntity: StimulsoftPrintEntities.DailyReport,
      printOptions: {
        flags: {}
      }
    }
  },
  computed: {
    printUrlParams() {
      const { date, job_id, crew_id } = this.currentResource?.attributes || {}
      return {
        start_date: this.$formatDate(date, ApiDateFormat),
        end_date: this.$formatDate(date, ApiDateFormat),
        job_ids: [job_id],
        crew_ids: [crew_id],
      }
    },
    items() {
      return [
        {
          name: this.$t('Details'),
          path: `/payroll/timesheets/daily-reports/${this.currentReportId}/view`,
          authorizedTo: 'authorizedToShow',
        },
        {
          name: this.$t('Edit'),
          path: `/payroll/timesheets/daily-reports/${this.currentReportId}/edit`,
          authorizedTo: 'authorizedToUpdate',
        },
      ]
    },
    dailyReportLoading() {
      return this.$store.state.timesheets.currentDailyReportLoading
    },
    currentResource() {
      return this.$store.state.timesheets.currentDailyReport
    },
    currentReportId() {
      return this.$route.params.id
    },
  },
  methods: {
    transformPrintData(data) {
      data.daily_reports = data.daily_reports.filter(r => r.id === this.currentReportId)
      return data
    },
    async getCurrentResource(id) {
      await this.$store.dispatch('timesheets/getDailyReport', id)
    },
  },
}
</script>
