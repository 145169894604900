import { globalResources, storeMutations } from "@/components/form/util";
import { equipmentTypeWithMaintenance, equipmentTypeWithQuantity, } from "@/enum/enums";
import Cache from '@/utils/Cache';
import { cacheTTL } from "./cacheConfig";

export const equipmentState = {
  [globalResources.Equipments]: [],
  [globalResources.EquipmentTypes]: [],
  [globalResources.EquipmentMaintenances]: [],
  [globalResources.RepairOrders]: [],
};

export const equipmentActions = {
  async getEquipments({ commit }) {
    const data = await Cache.getRequest('/restify/equipment/list', {
      ttl: cacheTTL
    }) || []
    commit(storeMutations.SetResource, { data, resource: globalResources.Equipments })
  },
};

export const equipmentGetters = {
  getEquipmentTypeIdWithMaintenance: state => {
    const type = state[globalResources.EquipmentTypes].find(type => type.abbr === equipmentTypeWithMaintenance) || {}
    return type.id
  },
  getEquipmentTypeIdWithQuantity: state => {
    const type = state[globalResources.EquipmentTypes].find(type => type.abbr === equipmentTypeWithQuantity) || {}
    return type.id
  },
};
