<template>
  <div class="report-page">
    <base-report-form
      :filters="model"
      :report-number="reportNumber"
      :hide-presets="true"
    >
      <template #filters>
        <base-filter-row
          :title="$t('Format')">
          <base-select
            v-model="model.format"
            :options="formatOptions"
            class="col-span-4"
            @change="onFormatChange"
          />

        </base-filter-row>
        <base-filter-row
          :title="$t('Effective Date*')"
        >
          <base-date-picker
            v-model="model.effective_date"
            @change="onEffectiveDateChange"
            id="effective_date"
            :name="$t('Effective Date')"
            class="col-span-4"
            rules="required"
          />
        </base-filter-row>

        <base-filter-row
          :title="$t('Period End Date*')"
        >
          <base-date-picker
            v-model="model.period_end_date"
            :disabled="payrollBatch?.id"
            class="col-span-4"
            rules="required"
          />
        </base-filter-row>
        <base-filter-row
          :title="$t('Bank')" items-align="items-center">
          <bank-select
            v-model="model.bank_id"
            label=" "
            :edit-entity="false"
            :disabled="payrollBatch?.id"
            :inline-errors="true"
            class="col-span-4"
          />
        </base-filter-row>

      </template>

      <template #table="{data, loading}">
        <PayrollDirectDepositTable
          v-if="model.format === formats.Listing"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          ref="reportTable"
        />
        <div v-else-if="[formats.File, formats.FilePreNotice].includes(model.format)">
          <TextFileList
            :data="data.file ? [data.file] : []"
            :loading="loading"
            :file-name="`Direct Deposit ${$formatDate(model.period_end_date)}`"
          />
        </div>
      </template>

    </base-report-form>
  </div>
</template>
<script>
import { resourceStatuses } from "@/enum/enums";
import axios from "axios";
import CertifiedPayrollReportTable from "@/modules/payroll/components/reports/CertifiedPayrollReportTable.vue";
import PayrollDirectDepositTable
  from "@/modules/payroll/components/reports/direct-deposit/PayrollDirectDepositTable.vue";
import XmlFileList from "@/modules/payroll/components/reports/XmlFileList.vue";
import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";
import parseISO from "date-fns/parseISO";
import isBefore from "date-fns/isBefore";

const formats = {
  Listing: 'listing',
  File: 'file',
  FilePreNotice: 'file_pre_notice',
}

export default {
  components: {
    TextFileList,
    XmlFileList,
    PayrollDirectDepositTable,
    CertifiedPayrollReportTable,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    preNoticeFormat: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formats,
      model: {
        format: this.preNoticeFormat ? formats.FilePreNotice : formats.Listing,
        bank_id: null,
        period_end_date: null,
        effective_date: null,
        select_by: 'batch',
        batch_id: this.$route.params.id,
        is_pre_note: this.preNoticeFormat,
        employee_ids: [],
      },
      payrolls: [],
    }
  },
  computed: {
    payrollBatch() {
      return this.$store.state.payroll.currentPayrollBatch
    },
    isPayrollPending() {
      return this.payrollBatch?.attributes?.status === resourceStatuses.Pending
    },
    formatOptions() {
      if (this.preNoticeFormat) {
        return [
          {
            label: this.$t('File with Pre-Notice'),
            value: formats.FilePreNotice,
          }
        ]
      }
      return [
        {
          label: this.$t('Listing'),
          value: formats.Listing,
        },
        {
          label: this.$t('File'),
          value: formats.File,
        },
        {
          label: this.$t('File with Pre-Notice'),
          value: formats.FilePreNotice,
        },
      ]
    },
    reportNumber() {
      const reportMap = {
        [formats.Listing]: '4060a',
        [formats.File]: '4060ae',
        [formats.FilePreNotice]: '4060ae',
        default: '4060a'
      }
      return reportMap[this.model.format] || reportMap.default
    },
  },
  methods: {
    async initModel() {
      if (!this.payrollBatch?.attributes) {
        return
      }
      const { bank_id, period_end_date } = this.payrollBatch?.attributes || {}
      this.model.bank_id = bank_id
      this.model.period_end_date = period_end_date
    },
    initEmployeeIds() {
      this.model.employee_ids = this.payrolls.map(p => p.attributes.employee_id)
    },
    async getPayrolls() {
      try {
        if (this.payrolls.length) {
          this.initEmployeeIds()
          return
        }
        this.loading = true
        const { data } = await axios.get(`/restify/payrolls`, {
          params: {
            payroll_batch_id: this.model.batch_id,
          }
        })
        this.payrolls = data
        this.initEmployeeIds()
      } finally {
        this.loading = false
      }
    },
    onEffectiveDateChange(dateStr) {
      const date = parseISO(dateStr)
      const periodEndDate = parseISO(this.model.period_end_date)
      if (isBefore(date, periodEndDate)) {
        this.$error({
          message: this.$t('Effective Date must be on or after the Period End Date'),
          timeout: 0,
        })
      }
    },
    async onFormatChange(format) {
      this.model.is_pre_note = format === formats.FilePreNotice
      if (this.model.is_pre_note) {
        await this.getPayrolls()
      }
    }
  },
  watch: {
    payrollBatch: {
      immediate: true,
      handler() {
        this.initModel()
      }
    },
    employees: {
      immediate: true,
      handler(value) {
        if (!value?.length) {
          return
        }
        this.model.employee_ids = value
      }
    }
  }
}
</script>
