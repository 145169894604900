<template>
  <EntitySelect
    ref="entitySelectRef"
    v-bind="{
      ...$attrs,
      ...selectAttrs,
    }"
    v-on="$listeners"
    :filter-options="filterOptions"
    :transformData="transformData"
    :url-params="allUrlParams"
    :addEntity="false"
    :ignoreInitialOption="true"
    :id="$attrs.id || 'maintenance-select'"
    value-key="id"
    label-key="description"
    class="w-full"
  >
    <template #empty>
      <div class="min-w-[280px]">
        <MaintenanceSelectHeader
          v-model="selectType"
          :tableColumns="tableColumns"
          @change="$emit('input', null)"
          @refresh="$refs.entitySelectRef?.forceFetchData()"
        />
        <div class="text-sm text-center text-gray-400 mt-16 py-2 w-full">
          {{ $t('No data') }}
        </div>
      </div>
    </template>
    <template #options-container="{ options }">
      <MaintenanceSelectHeader
        v-model="selectType"
        :tableColumns="tableColumns"
        @change="$emit('input', null)"
        @refresh="$refs.entitySelectRef?.forceFetchData()"
      />
      <div
        class="min-w-[280px]"
        :class="{
          'mt-20': selectType === selectTypes.RepairOrderEntry,
          'mt-16': selectType === selectTypes.MaintenanceCode,
        }"
      >
        <template v-if="selectType === selectTypes.MaintenanceCode">
          <ElOption
            v-for="option of options"
            :key="option.id"
            :label="selectAttrs.formatLabel(option)"
            :title="selectAttrs.formatLabel(option)"
            :value="option.id"
          >
            <MaintenanceSelectOption
              :option="option"
              :formatLabel="selectAttrs.formatLabel"
              :tableColumns="tableColumns"
            />
          </ElOption>
        </template>
        <template v-else>
          <ElOptionGroup
            v-for="group in groupedOptions"
            :key="group.id"
            :label="group.label"
          >
            <ElOption
              v-for="option of group.options"
              :key="option.id"
              :label="selectAttrs.formatLabel(option)"
              :title="selectAttrs.formatLabel(option)"
              :value="option.id"
            >
              <MaintenanceSelectOption
                :option="option"
                :formatLabel="selectAttrs.formatLabel"
                :tableColumns="tableColumns"
              />
            </ElOption>
          </ElOptionGroup>
        </template>
      </div>
    </template>
  </EntitySelect>
</template>
<script>
import {
  Option as ElOption,
  OptionGroup as ElOptionGroup,
} from 'element-ui'
import { equipmentCostAddlSources } from "@/components/grid-table/utils/cost-center";
import MaintenanceSelectHeader from "@/components/select/entities/MaintenanceSelectHeader.vue";
import MaintenanceSelectOption from "@/components/select/entities/MaintenanceSelectOption.vue";

export default {
  name: 'MaintenanceSelect',
  inheritAttrs: false,
  components: {
    [ElOption.name]: ElOption,
    [ElOptionGroup.name]: ElOptionGroup,
    MaintenanceSelectHeader,
    MaintenanceSelectOption,
  },
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: '-number',
      }),
    },
    repair_order_id: {
      type: String,
      default: null,
    },
    equipment_id: {
      type: String,
      default: null,
    },
    type_id: {
      type: String,
      default: null,
    },
    excludeIds: {
      type: Array,
      default: () => [],
    },
    filterAvailableInTimesheets: {
      type: [Boolean, null],
      default: null,
    },
    active: {
      type: [Boolean, null],
      default: null,
    },
    sourceType: {
      type: String,
    },
  },
  data() {
    return {
      groupedOptions: [],
      selectTypes: equipmentCostAddlSources,
    }
  },
  computed: {
    selectType: {
      get() {
        return this.sourceType
      },
      set(value) {
        this.$emit('update:sourceType', value)
      }
    },
    selectAttrs() {
      const attrs = {
        [equipmentCostAddlSources.MaintenanceCode]: {
          url: '/restify/maintenance-codes',
          name: this.$t('Maintenance Code'),
          label: this.$t('Maintenance Code'),
          placeholder: this.$t('Select Maintenance Code'),
          formatLabel: (option) => `${option.code} - ${option.description}`,
          customGetFullValue: null
        },
        [equipmentCostAddlSources.RepairOrderEntry]: {
          url: '/restify/repair-order-entries',
          name: this.$t('Repair Order Entry'),
          label: this.$t('Repair Order Entry'),
          placeholder: this.$t('Select Repair Order Entry'),
          formatLabel: (option) => {
            const ro_number = this.get(option, 'repairOrder.attributes.number')
            const { cost_type, description } = option
            return `RO#${ro_number} ${cost_type}  - ${description || ''}`
          },
          customGetFullValue: (value) => {
            for (const group of this.groupedOptions) {
              const option = group.options.find(o => o.id === value)
              if (option) {
                return option
              }
            }
            return null
          }
        }
      }

      return attrs[this.selectType]
    },
    tableColumns() {
      if (this.selectType === equipmentCostAddlSources.MaintenanceCode) {
        return [
          {
            name: this.$t('Code'),
            prop: 'code',
            minWidth: 100,
            maxWidth: 100,
          },
          {
            name: this.$t('Description'),
            prop: 'description',
            minWidth: 100,
            maxWidth: 150,
          },
        ]
      }
      return [
        {
          name: this.$t('Type'),
          prop: 'cost_type',
          minWidth: 100,
          maxWidth: 100,
        },
        {
          name: this.$t('Description'),
          prop: 'description',
          minWidth: 100,
          maxWidth: 150,
        },
      ]
    },
    allUrlParams() {
      if (this.selectType === equipmentCostAddlSources.MaintenanceCode) {
        return {}
      }

      return {
        ...this.urlParams,
        repair_order_id: this.repair_order_id,
        available_in_timesheets: this.filterAvailableInTimesheets,
        active: this.active,
        equipment_id: this.equipment_id,
        type_id: this.type_id,
        related: 'repairOrder',
      }
    },
    equipmentCostTypes() {
      return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.EquipmentTypes) || []
    },
  },
  methods: {
    transformData(options) {
      if (this.selectType === equipmentCostAddlSources.MaintenanceCode) {
        return options.map(o => o.attributes)
      }

      const groupsMap = {}

      for (const option of options) {
        const result = {
          ...option.attributes,
          ...(option.relationships || {}),
        }

        const costType = this.equipmentCostTypes.find(t => t.id === result.type_id)
        result.cost_type = costType?.name || ''

        result.roe_description = this.selectAttrs?.formatLabel(result)

        if (!groupsMap[result.repair_order_id]) {
          groupsMap[result.repair_order_id] = {
            label: `RO #${this.get(result, 'repairOrder.attributes.number')}`,
            id: result.repair_order_id,
            options: [],
          }
        }

        groupsMap[result.repair_order_id].options.push(result)
      }


      this.groupedOptions = Object.values(groupsMap)

      return this.groupedOptions
    },
    filterOptions(options) {
      if (this.excludeIds.length) {
        options = options.filter(o => {
          return !this.excludeIds.includes(o.id)
        })
      }

      return options
    },
  },
}
</script>
