<template>
  <div
    :class="itemsAlign"
    class="grid md:grid-cols-8 xl:grid-cols-12 gap-x-4"
  >
    <div
      v-if="title"
      class="text-sm text-gray-600 font-medium mb-5 relative"
      :class="classes"
    >
      <span v-html="title" />
      <BaseTooltip
        v-if="tip"
        :content="tip"
        :tabindex="-1"
      >
        <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help inline" />
      </BaseTooltip>
      <div class="absolute right-2 top-0 -mt-2">
        <slot name="title-after" />
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
import { HelpCircleIcon } from 'vue-feather-icons'
export default {
  components: {
    HelpCircleIcon,
  },
  props: {
    title: String,
    classes: {
      type: String,
      default: 'col-span-2',
    },
    itemsAlign: {
      type: String,
      default: 'items-baseline',
    },
    tip: String,
  },
  data() {
    return {
      inputVisible: false,
    }
  },
}
</script>
