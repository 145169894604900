<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="showNotification" class="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-2xl ring-1 ring-black/5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="ml-3 w-0 flex-1">
                <p class="font-medium text-gray-900">{{$t('New version available')}}</p>
                <p class="mt-1 text-sm text-gray-500">{{$t(`There's a new version of the app available. Use the buttons below to get the new version or refresh the page.`)}}</p>
                <div class="mt-4 flex">
                  <BaseButton variant="primary" size="sm" @click="refreshPage">
                    {{$t('Refresh')}}
                  </BaseButton>
                  <BaseButton class="ml-3" variant="white" size="sm" @click="showNotification = false">
                    {{$t('Close')}}
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'

const showNotification = ref(false)

const { updateServiceWorker } = useRegisterSW({
  onNeedRefresh() {
    showNotification.value = true
  },
})

function refreshPage() {
  showNotification.value = false
  updateServiceWorker(true)
}

const interval = ref()

onMounted(() => {
  interval.value = setInterval(() => {
    updateServiceWorker()
  }, 60000)
})

onBeforeUnmount(() => {
  clearInterval(interval.value)
})
</script>
