<template>
  <BarChartCard
      ref="chart"
      :url="url"
      :url-params="urlParams"
      :title="title"
      :format-labels-as-price="true"
      :options="chartOptions"
      :show-title-section="showTitleSection"
      response-prop="payments"
      @data-fetch="onDataFetch"
  >
    <template #filters>
      <div class="mr-2 w-[200px]">
        <ReportIntervalSelect
            v-model="interval"
            @change="onIntervalChange"
        />
      </div>
    </template>
  </BarChartCard>
</template>
<script>
  import BarChartCard from "@/components/charts/BarChartCard";
  import { verticalDataAxis } from "@/components/charts/chartOptions";
  import { ReportIntervals } from "@/modules/accounts-receivable/utils/billingUtils";
  import { barChartSeriesConfig } from "@/modules/accounts-receivable/pages/dashboard/widgets/chartUtils";
  import ReportIntervalSelect from "@/modules/accounts-receivable/pages/dashboard/widgets/ReportIntervalSelect";
  import { ChartColors } from "@/components/charts/chartColors";
  import { graphic } from "echarts";

  export default {
    components: {
      BarChartCard,
      ReportIntervalSelect,
    },
    props: {
      title: {
        type: String,
      },
      url: {
        type: String,
        default: '/dashboard/accounts-receivable/monthly-payment',
      },
      showTitleSection: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        chartOptions: {
          series: [],
          labels: [],
          dataZoom: [
            {
              start: 0,
              end: 100
            },
          ],
        },
        interval: ReportIntervals.Monthly,
      }
    },
    computed: {
      urlParams() {
        return {
          interval: this.interval,
        };
      },
    },
    methods: {
      async onIntervalChange() {
        await this.$nextTick()
        const ref = this.$refs.chart
        await ref.getData();
      },
      onDataFetch(data) {
        const mappedData = data.map(row => {
          const monthStr = row.month?.toString().padStart(2, '0')
          const date = `${row.year}-${monthStr}-01`
          let label = this.$formatDate(date, 'MMM yyyy', true)
          let month = this.$formatDate(date, 'MMM', true)
          let week

          const shortMonth = this.$formatDate(date, 'MMM', true)

          if (this.interval === ReportIntervals.Weekly) {
            label = `Week ${row.week}, ${shortMonth}`
            week = row.week
          }

          return {
            ...row,
            value: +row.value,
            label,
            month,
            week,
          }
        })
        let labels = []
        let series = []
        labels = mappedData.map(row => row.label)
        series = [
          {
            ...barChartSeriesConfig,
            name: this.title,
            data: mappedData.map(row => row.value),
            itemStyle: {
              borderRadius: [4, 4, 0, 0],
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: ChartColors.Green },  // Blue at top
                { offset: 0.7, color: 'rgba(16, 185, 129, 0.8)' },  // Blue at top
                { offset: 1, color: 'rgba(16, 185, 129, 0.4)' }  // White/transparent at bottom
              ]),
            },
          }
        ]
        const xAxis = {
          ...verticalDataAxis,
          data: labels,
        }
        if (this.interval === ReportIntervals.Weekly) {
          xAxis.axisLabel = {}
        }
        this.chartOptions.labels = labels
        this.chartOptions.series = series
        this.chartOptions.xAxis = xAxis
      },
    },
  }
</script>

