<template>
  <BaseForm
    :loading="loading"
    :show-cancel="true"
    :save-text="$t('Create Crew')"
    :update-text="$t('Update Crew')"
    :can-create-another-entity="!model.id"
    layout="modal"
    grid-classes="grid grid-cols-8 gap-x-3"
    @cancel="$emit('cancel')"
    @submit="onSubmit"
  >
    <div class="col-span-8 md:col-span-2 xl:col-span-2">
      <BaseInput
        v-model="model.name"
        :label="$t('Name')"
        :placeholder="$t('Name')"
        id="description"
        rules="max:50"
      />
    </div>
    <div class="col-span-8 md:col-span-6 xl:col-span-4">
      <BaseInput
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        id="description"
        rules="max:250"
      />
    </div>
    <div class="col-span-8">
      <h5 class="form-section-title">{{ $t('Crew Members') }}</h5>
      <AgDataTable
        :data.sync="employees"
        :columns="employeeColumns"
        :default-filters="false"
        v-bind="editableTableProps"
        :get-empty-row="getNewCrewMember"
        domLayout="autoHeight"
        actions="add"
      />
    </div>
  </BaseForm>
</template>
<script>
import axios from "axios";
import { editableTableProps } from "@/components/ag-grid/tableUtils";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { globalResources } from "@/components/form/util";
import { employeeStatuses } from "@/enum/enums";
import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableTableProps,
      loading: false,
      model: {
        name: '',
        description: '',
      },
      employees: [],
      employeesToRemove: [],
    }
  },
  computed: {
    employeeColumns() {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'employee_id',
          editable: true,
          minWidth: 200,
          component: 'EmployeeLink',
          cellRendererParams: {
            isLink: false,
          },
          cellEditor: cellEditors.GlobalResourceSelect,
          cellEditorParams: {
            resourceName: globalResources.Employees,
            filterMethod: (row) => {
              const isNotInactiveOrDeceased = ![employeeStatuses.DECEASED, employeeStatuses.INACTIVE].includes(row.status)
              const isNotAlreadyInCrew = !this.employees.find(e => e.employee_id === row.id)
              return isNotInactiveOrDeceased && isNotAlreadyInCrew
            }
          },
        },
        {
          headerName: this.$t('Is Foreman'),
          field: 'is_leader',
          editable: true,
          component: 'Status',
          minWidth: 100,
          cellEditor: cellEditors.Boolean,
        },
        {
          ...getDeleteColumn({
            onConfirm: (params) => {
              this.employeesToRemove.push(params.data.id)
            }
          })
        }
      ]
    },
  },
  methods: {
    async attachOrDetachEmployees(crewId) {
      if (!crewId) {
        return
      }
      const updatedEmployees = this.employees.filter(e => e.dirty && e.id).map(e => e.employee_id)
      const employeesToRemove = updatedEmployees.concat(this.employeesToRemove)
      const leadersToAdd = this.employees.filter(e => e.dirty && e.is_leader).map(e => e.employee_id)
      const membersToAdd = this.employees.filter(e => e.dirty && !e.is_leader).map(e => e.employee_id)
      if (employeesToRemove.length) {
        await axios.post(`/restify/crews/${crewId}/detach/employees`, {
          employees: employeesToRemove
        })
      }
      if (leadersToAdd.length) {
        await axios.post(`/restify/crews/${crewId}/attach/employees`, {
          employees: leadersToAdd,
          is_leader: true
        })
      }
      if (membersToAdd.length) {
        await axios.post(`/restify/crews/${crewId}/attach/employees`, {
          employees: membersToAdd,
          is_leader: false
        })
      }
    },
    async onSubmit() {
      try {
        this.loading = true
        if (this.model.id) {
          await axios.put(`/restify/crews/${this.model.id}`, this.model)
          await this.attachOrDetachEmployees(this.model.id)
          this.$success(this.$t('Crew updated'))
          this.$emit('save', this.model)
        } else {
          const { data } = await axios.post('/restify/crews', this.model)
          await this.attachOrDetachEmployees(data.id)
          this.$success(this.$t('Crew created'))
          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        console.log('err', err)
        this.$error(this.$t('Could not update the crew.'))
      } finally {
        this.loading = false
      }
    }
    ,
    setEmployees(employees = []) {
      this.employees = employees.map(e => {
        return {
          employee_id: e.id,
          is_leader: e.pivots?.is_leader,
          ...e.attributes,
        }
      })
    }
    ,
    getNewCrewMember() {
      return {
        _localId: crypto.randomUUID(),
        employee_id: null,
        is_leader: false,
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
        this.setEmployees(value?.relationships?.employees)
      },
    },
  }
}
</script>
