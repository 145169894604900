<template>
  <base-report-form
      :filters="model"
      :report-number="reportNumber"
      :focusOnFirstInput="!$route.query.disable_focus"
  >

    <template #filters>

      <base-filter-row :title="$t('Format')">
        <base-select
            v-model="model.format"
            :add-entity="false"
            :options="formatOptions"
            class="col-span-3"
            @change="onChangeFormat"
        />
        <div v-if="groupByVendor"
             class="col-span-3 flex items-baseline">
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">{{ $t('Sort By') }}</span>
          <base-select
              v-model="model.sort_by"
              :add-entity="false"
              :options="sortOptions"
          />
        </div>
      </base-filter-row>

      <base-filter-row :title="$t('View')">
        <base-select
            v-model="model.view_type"
            :add-entity="false"
            :options="viewTypeOptions"
            class="col-span-2"
        />
        <div class="col-span-3 flex items-baseline">
          <span class="text-sm text-gray-600 font-medium whitespace-nowrap md:mr-3">{{ $t('Status') }}</span>
          <base-select
              v-model="model.invoice_statuses"
              :add-entity="false"
              :options="invoiceStatusOptions"
              :name="$t('Status')"
              rules="required"
              class="flex-1"
              collapse-tags
              multiple
          />
        </div>
      </base-filter-row>

      <vendor-range-filter v-model="model"/>

      <template v-if="!groupByVendor">

        <job-range-filter
            v-model="model"
        >

          <base-select
              v-model="model.job_statuses"
              :add-entity="false"
              :options="jobStatusOptions"
              class="col-span-2"
              collapse-tags
              multiple
          />

          <base-select
              v-model="model.job_type_ids"
              :add-entity="false"
              :options="jobTypes"
              value-key="id"
              label-key="name"
              class="col-span-2"
              collapse-tags
              multiple
          />

        </job-range-filter>

      </template>

      <base-filter-row :title="$t('Invoice Date Range')">
        <DateRangeFilter
          ref="dateRangeFilter"
          v-model="model"
          class="col-span-4"
        />
      </base-filter-row>

      <period-year-range-filter v-model="model"/>

      <template v-if="!groupByPayments">

        <base-filter-row>
          <base-checkbox
              v-model="model.init_invoices_only"
              :label="$t('Include Initialized ( non G/L ) Invoices Only')"
              class="col-span-8 mb-2"
              id="init_invoices_only"
          />
        </base-filter-row>

        <base-filter-row :title="$t('Journal Range')">
          <number-range-filter
              :from="model.journal_number_from"
              :to="model.journal_number_to"
              :from.sync="model.journal_number_from"
              :to.sync="model.journal_number_to"
              grid-class="col-span-3"
          />
        </base-filter-row>

      </template>

    </template>

    <template #table="{loading, data}">

      <component
          :is="getTableComponent"
          :data="data"
          :filters="model"
          :report-number="reportNumber"
          :data-loading="loading"
          :format="model.format"
          ref="reportTable"
      />

    </template>

  </base-report-form>
</template>
<script>

  import { allJobStatuses, jobStatusOptions } from '@/modules/job-costing/enum/jobs'
  import { vendorHistoryFormats } from '@/modules/accounts-payable/components/reports/util'
  import VendorHistoryReportTable from '@/modules/accounts-payable/components/reports/VendorHistoryReportTable'
  import VendorHistoryPaymentsTable from '@/modules/accounts-payable/components/reports/VendorHistoryPaymentsTable'
  import {
    VendorRangeFilter,
    DateRangeFilter,
    PeriodYearRangeFilter,
    NumberRangeFilter,
    JobRangeFilter, periodYearFilterKeys,
  } from '@/components/range-filters'

  const sortBy = {
    InvoiceNumber: 'number',
    InvoiceDate: 'date',
  }

  const viewTypes = {
    InvoiceDetails: 'invoice-details',
    InvoiceSummary: 'invoice-summary',
    VendorTotals: 'vendor-totals',
  }

  export default {
    components: {
      JobRangeFilter,
      DateRangeFilter,
      VendorRangeFilter,
      NumberRangeFilter,
      PeriodYearRangeFilter,
      VendorHistoryReportTable,
      VendorHistoryPaymentsTable,
    },
    data() {
      return {
        jobStatusOptions,
        model: {
          format: vendorHistoryFormats.ByVendor,
          sort_by: sortBy.InvoiceNumber,
          view_type: viewTypes.InvoiceDetails,
          invoice_statuses: [this.$resourceStatuses.Posted, this.$resourceStatuses.PartialPaid, this.$resourceStatuses.Paid],
          descendent: false,
          vendor_code_from: null,
          vendor_code_to: null,
          start_date: null,
          end_date: this.$now,

          ...periodYearFilterKeys,

          init_invoices_only: false,
          journal_number_from: 0,
          journal_number_to: 0,

          job_number_from: null,
          job_number_to: null,
          job_statuses: allJobStatuses,
          job_type_ids: [],
        },
        formatOptions: [
          {
            label: this.$t('History by Vendor'),
            value: vendorHistoryFormats.ByVendor,
          },
          {
            label: this.$t('History by Job'),
            value: vendorHistoryFormats.ByJob,
          },
          {
            label: this.$t('Payments by Job'),
            value: vendorHistoryFormats.Payments,
          },
          {
            label: this.$t('History by Job-Vendor Cost Type'),
            value: vendorHistoryFormats.CostType,
          },
        ],
        sortOptions: [
          {
            label: this.$t('Invoice Number'),
            value: sortBy.InvoiceNumber,
          },
          {
            label: this.$t('Invoice Date'),
            value: sortBy.InvoiceDate,
          },
        ],
        viewTypeOptions: [
          {
            label: this.$t('Invoice Details'),
            value: viewTypes.InvoiceDetails,
          },
          {
            label: this.$t('Invoice Summary'),
            value: viewTypes.InvoiceSummary,
          },
          {
            label: this.$t('Vendor Totals Only'),
            value: viewTypes.VendorTotals,
          },
        ],
        invoiceStatusOptions: [
          {
            label: this.$t('Open & Unpaid'),
            value: this.$resourceStatuses.Posted,
          },
          {
            label: this.$t('Fully Paid'),
            value: this.$resourceStatuses.Paid,
          },
          {
            label: this.$t('Partially Paid'),
            value: this.$resourceStatuses.PartialPaid,
          },
          {
            label: this.$t('Prepaid'),
            value: this.$resourceStatuses.Prepaid,
          },
          {
            label: this.$t('Pending'),
            value: this.$resourceStatuses.Pending,
          },
          {
            label: this.$t('On Hold'),
            value: this.$resourceStatuses.Hold,
          },
          {
            label: this.$t('Voided'),
            value: this.$resourceStatuses.Voided,
          },
        ],
      }
    },
    computed: {
      getTableComponent() {
        return this.groupByPayments ? 'VendorHistoryPaymentsTable' : 'VendorHistoryReportTable'
      },
      groupByPayments() {
        return this.model.format === vendorHistoryFormats.Payments
      },
      groupByVendor() {
        return this.model.format === vendorHistoryFormats.ByVendor
      },
      jobTypes() {
        return this.$store.getters['globalLists/getResourceOptions'](this.$globalResources.JobCostTypes)
      },
      allJobTypes() {
        return this.jobTypes.map(type => type.id)
      },
      reportNumber() {
        if (this.groupByVendor) {
          return '3888'
        }

        if (this.groupByPayments) {
          return '3888b'
        }

        return '3888a'
      },
      getJobSubcontractTypeId() {
        return this.$store.getters['globalLists/getJobSubcontractTypeId']
      },
    },
    methods: {
      async onChangeFormat() {
        await this.$nextTick()

        this.model.job_statuses = allJobStatuses
        this.model.job_type_ids = this.model.format === vendorHistoryFormats.CostType ? [this.getJobSubcontractTypeId] : this.allJobTypes
        this.model.job_number_from = this.model.job_number_to = null
      },
      async initDateRange() {
        if (!this.$route.query?.clear_date_range) {
          return
        }

        await this.$nextTick()
        this.$refs.dateRangeFilter?.clearRange()
        this.model.start_date = null
        this.model.end_date = null
      }
    },
    mounted() {
      this.initDateRange()
    },
  }
</script>
