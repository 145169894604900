<template>
  <base-form-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :append-to-body="true"
    :title="data?.id ? $t('Update Timesheet Entry') : $t('Create Timesheet Entry')"
  >
    <base-form
      layout="modal"
      :model="model"
      :loading="loading"
      :save-text="data?.id ? $t('Update Timesheet Entry') : $t('Create Timesheet Entry')"
      :show-cancel="true"
      :focus-on-first-input="!data.id"
      @cancel="onCancel"
      @submit="onSubmit"
    >
      <EmployeeSelect
        v-if="showEmployeeSelect"
        :value="model.employee_id"
        class="col-span-6"
        :disabled="true"
      />
      <TaskTypeSelect
        v-model="model.cost_center"
        class="col-span-3 md:col-span-2"
        :label="$t('Cost Center')"
        @change="onCostCenterChange"
      />
      <template v-if="model.cost_center === costCenterTypes.Job">
        <JobSelectNew
          v-model="model.source_id"
          class="col-span-3 md:col-span-2"
          :filter-available-in-timesheets="true"
          rules="required"
          @change="onJobChange"
        />
        <LineItemSelect
          v-model="model.addl_source_id"
          ref="lineItemSelect"
          :job-id="model.source_id"
          :label="$t('Line Item')"
          :filter-available-in-timesheets="true"
          class="col-span-3 md:col-span-2"
          rules="required"
        />
      </template>
      <template v-else-if="model.cost_center === costCenterTypes.Equipment">
        <EquipmentSelect
          v-model="model.source_id"
          :filter-available-in-timesheets="true"
          class="col-span-3 md:col-span-2"
          rules="required"
          @change="onEquipmentChanged"
        />
        <MaintenanceSelect
          v-model="model.addl_source_id"
          :disabled="!model.source_id"
          :sourceType.sync="model.addl_source_type"
          :equipment_id="model.source_id"
          :filter-available-in-timesheets="true"
          :active="true"
          class="col-span-3 md:col-span-2"
          @entity-change="onMaintenanceChanged"
        />
      </template>
      <WorkOrderSelect
        v-else-if="model.cost_center === costCenterTypes.WorkOrder"
        v-model="model.source_id"
        :filter-available-in-timesheets="true"
        class="col-span-3 md:col-span-2"
        rules="required"
      />
      <div class="col-span-6"/>
      <base-date-picker
        v-model="model.date"
        :label="$t('Date')"
        :placeholder="$t('Date')"
        class="col-span-6 md:col-span-2"
      />
      <TimesheetEntryStartTime
        v-model="model.start_time"
        :label="$t('Start Time')"
        :placeholder="$t('Start Time')"
        class="col-span-6 md:col-span-2"
      />
      <TimesheetEntryDuration
        v-model="model.duration"
        class="col-span-6 md:col-span-2"
        @blur="onDurationChange"
      />

      <CrewSelect
        v-model="model.crew_id"
        class="col-span-6 md:col-span-2"
      />
      <base-switch
        v-model="model.is_lunch"
        class="col-span-6 md:col-span-2 lg:col-span-1"
        :label-info="$t('Is Lunch')"
        @change="onLunchChange"
      />
      <base-switch
        v-model="model.is_break"
        class="col-span-6 md:col-span-2 lg:col-span-1"
        :label-info="$t('Is Break')"
        @change="onBreakChange"
      />
      <div class="col-span-6 mb-4"></div>

      <base-textarea
        v-model="model.notes"
        :label="$t('Notes')"
        :placeholder="$t('Notes')"
        :rows="2"
        id="notes"
        rules="max:300"
        class="col-span-6"
      />
      <TimesheetEntryPhotos
        v-model="model.files"
        class="col-span-6"
      />

      <el-collapse v-model="activeGroups" class="col-span-6">
        <base-collapse-section
          :title="$t('Payroll Information')"
          key="payroll_information"
          name="payroll_information"
        >
          <div class="grid grid-cols-6 gap-4">
            <craft-select
              v-model="model.craft_code_id"
              :label="$t('Craft Code')"
              :name="$t('Craft Code')"
              :add-entity="false"
              :display-level="true"
              :for-timesheets="true"
              id="craft_code_id"
              class="col-span-6 md:col-span-3"
              clearable
              @change="onCraftCodeChange"
            />
            <sub-trade-select
              v-model="model.sub_trade_id"
              :label="$t('Sub Trade')"
              :add-entity="false"
              :disabled="!model.craft_code_id"
              :craft-code-id="model.craft_code_id"
              :allow-selection-without-craft-code="false"
              key="sub_trade_id"
              id="sub_trade_id"
              clearable
              class="col-span-6 md:col-span-3"
            />
          </div>
        </base-collapse-section>
      </el-collapse>

      <TimesheetEntrySpecialSources
        v-model="model.special_sources"
        :duration="model.duration"
        :employee-id="employeeId"
        class="col-span-6"
      />

      <template #extra-buttons-left>
        <DeleteButton
          v-if="canDelete"
          @click="deleteEntry"
        />
      </template>
    </base-form>
  </base-form-dialog>
</template>
<script>
import LineItemSelect from "@/components/select/entities/LineItemSelect.vue";
import TaskTypeSelect from "@/modules/payroll/components/tasks/TaskTypeSelect.vue";
import { costCenterTypes, equipmentCostAddlSources } from "@/components/grid-table/utils/cost-center";
import { getAddlSourceType, getSourceType } from "@/components/grid-table/utils/cost-center-cell";
import TimesheetEntryStartTime from "@/modules/payroll/components/timesheets/TimesheetEntryStartTime.vue";
import TimesheetEntryDuration
  from "@/modules/payroll/components/timesheets/weekly-timesheets/TimesheetEntryDuration.vue";
import TimesheetEntryPhotos from "@/modules/payroll/components/timesheets/weekly-timesheets/TimesheetEntryPhotos.vue";
import { getTimezone, getUnitsFromDuration } from "@/modules/payroll/utils/timeCardUtils";
import DeleteButton from "@/components/common/DeleteButton.vue";
import { isTimesheetEditable } from "@/modules/payroll/components/timesheets/utils";
import TimesheetEntrySpecialSources
  from "@/modules/payroll/components/timesheets/weekly-timesheets/TimesheetEntrySpecialSources.vue";
import { codeTypes } from "@/modules/payroll/components/rates/util";
import SubTradeSelect from "@/components/select/entities/SubTradeSelect.vue";
import { useStorage } from "@vueuse/core";
import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";
import { globalResources } from "@/components/form/util";
import {
  sourceTypes,
} from "@/modules/equipment/util/repairOrderUtils";

const storageKey = 'last-timesheet-entry'
export default {
  components: {
    SubTradeSelect,
    DeleteButton,
    TaskTypeSelect,
    JobSelectNew,
    LineItemSelect,
    TimesheetEntryPhotos,
    TimesheetEntryDuration,
    TimesheetEntryStartTime,
    TimesheetEntrySpecialSources,
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    date: {
      type: String,
    },
    employeeId: {
      type: String,
    },
    showEmployeeSelect: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const lastModel = useStorage(storageKey, {})
    return {
      lastModel
    }
  },
  data() {
    const expandPayroll = this.$settings('timesheet', 'show_craft_code_details') || false
    return {
      loading: false,
      costCenterTypes,
      activeGroups: expandPayroll ? ['payroll_information'] : [],
      model: this.getInitialModel(),
    }
  },
  computed: {
    canDelete() {
      if (!this.data?.id) {
        return false
      }
      return isTimesheetEditable(this.data?.attributes?.timesheet)
    },
    craftCodes() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.CraftCodes)
    },
  },
  methods: {
    getEmployee(employeeId) {
      return this.$store.getters['globalLists/getResourceById'](globalResources.Employees, employeeId) || {}
    },
    getInitialModel() {
      const { cost_center, source_id, sub_trade_id, source_type, addl_source_id, addl_source_type } = this.lastModel || {}
      const craft_code_id = this.getEmployee(this.employeeId)?.craft_code_id

      return {
        cost_center: cost_center || costCenterTypes.GeneralAndAdministrative,
        source_id: source_id || null,
        craft_code_id: craft_code_id || null,
        sub_trade_id: sub_trade_id || null,
        source_type: source_type || null,
        addl_source_id: addl_source_id || null,
        addl_source_type: addl_source_type || null,
        is_lunch: false,
        is_break: false,
        crew_id: null,
        notes: '',
        date: this.date,
        duration: 0,
        employee_id: this.employeeId,
        start_time: this.$store.getters['timesheets/startTime'](this.date),
        special_sources: [],
        meta: {
        }
      }

    },
    onCostCenterChange() {
      this.model.source_id = null
      this.model.addl_source_id = null
    },
    onCraftCodeChange(craftCode) {
      if (!craftCode) {
        this.model.sub_trade_id = undefined
      }
    },
    async onJobChange() {
      this.model.addl_source_id = null
      await this.$nextTick()
      this.$refs.lineItemSelect.showMasterLineItems = false
    },
    async onDurationChange() {
      this.model.special_sources = this.model.special_sources.map(item => {
        if (item.type !== codeTypes.EQUIPMENT) {
          return item
        }
        item.units = getUnitsFromDuration(this.model.duration)
        return item
      })
    },
    onLunchChange(value) {
      if (!value) {
        this.model.duration = 0
        return
      }
      this.model.duration = this.$settings(this.$modules.TSHEET, 'lunch_duration') || 30
      this.model.notes = this.$t('Lunch')
    },
    onBreakChange(value) {
      if (!value) {
        this.model.duration = 0
        return
      }
      this.model.duration = this.$settings(this.$modules.TSHEET, 'break_duration') || 15
      this.model.notes = this.$t('Break')
    },
    getAddlSourceType() {
      if (!this.model.addl_source_id) {
        return null
      }

      if (this.model.cost_center === costCenterTypes.Equipment) {
        return this.model.addl_source_type
      }

      return getAddlSourceType(this.model.cost_center)
    },
    getPayload() {
      const cost_center = this.model.cost_center
      const special_sources = this.model.special_sources.map(item => ({
        id: item.id,
        type: item.type,
        units: item.units,
        special_rate: item.special_rate,
      }))

      return {
        ...this.model,
        end_time: undefined,
        timezone: getTimezone(),
        source_type: getSourceType(cost_center),
        addl_source_type: this.getAddlSourceType(),
        special_sources,
      }
    },
    onCancel() {
      this.$emit('close')
    },
    async onEquipmentChanged(value) {
      this.model.meta.repair_order_id = null
      this.model.addl_source_id = null
    },
    async onMaintenanceChanged(value) {
      this.model.meta.repair_order_id = value?.repair_order_id
      this.model.meta.roe_description = value?.roe_description

      const { addl_source_type, addl_source_id } = value
      const current_craft_code_id = this.model.craft_code_id

      if (addl_source_type !== sourceTypes.Labor
        || !addl_source_id
        || addl_source_id === current_craft_code_id
      ) {
        return
      }

      const currentCraftCode = this.craftCodes.find(code => code.id === current_craft_code_id)
      const craftCode = this.craftCodes.find(code => code.id === addl_source_id)
      const craftCodeDescription = craftCode?.level
        ? `${craftCode.code} - ${craftCode.level}`
        : craftCode?.code || ''

        let description = this.$t(`The selected Repair Order Entry uses Craft Code <span class="font-semibold whitespace-nowrap">${craftCodeDescription}</span>.<br>Use the same Craft Code for this Timesheet Entry?`)
      if (currentCraftCode) {
        const currentCraftCodeDescription = currentCraftCode?.level
          ? `${currentCraftCode.code} - ${currentCraftCode.level}`
          : currentCraftCode?.code || ''

        description += this.$t(`<br>This will override the current Craft Code <span class="font-semibold whitespace-nowrap">${currentCraftCodeDescription}</span>.`)
      }

      const confirmed = await this.$confirm({
        title: this.$t('Use Craft Code'),
        description,
        buttonText: this.$t('Yes'),
        cancelText: this.$t('No'),
      });

      if (!confirmed) {
        return
      }

      this.model.craft_code_id = addl_source_id
    },
    async deleteEntry() {
      try {
        this.loading = true
        const confirmed = await this.$deleteConfirm({
          title: this.$t('Delete Timesheet Entry'),
          description: this.$t('Are you sure you want to delete this timesheet entry?'),
        })
        if (!confirmed) {
          return
        }
        await this.$store.dispatch('timesheets/deleteEntry', this.data)
        this.$emit('close')
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not delete the timesheet entry'))
      } finally {
        this.loading = false
      }
    },
    async onSubmit() {
      try {
        this.loading = true
        const data = this.getPayload()

        if (data.addl_source_type !== equipmentCostAddlSources.RepairOrderEntry) {
          delete data.meta.repair_order_id
          delete data.meta.roe_description
        }

        let response = null
        if (this.model.id) {
          response = await this.$store.dispatch('timesheets/updateEntry', data)
          this.$success(this.$t('Timesheet entry updated'))
        } else {
          response =  await this.$store.dispatch('timesheets/addEntry', data)
          this.$success(this.$t('Timesheet entry created'))
        }
        localStorage.setItem(storageKey, JSON.stringify(this.model))
        this.$emit('submit', response)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Could not save timesheet entry'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    data: {
      handler(val) {
        if (!val?.id) {
          return
        }
        this.model = {
          ...this.model,
          ...(val?.attributes || {})
        }
        if (this.model.start_time?.length > 4) {
          this.model.start_time = this.model.start_time.slice(0, 5)
        }

        if (!this.model.meta) {
          this.model.meta = {
            repair_order_id: null,
          }
        }
      },
      immediate: true,
    },
  },
}
</script>
