<template>
  <base-form-dialog v-bind="$attrs"
                    v-on="$listeners"
                    :title="title"
                    :has-padding="true"
                    size="xl"
                    class="payroll-modal"
  >
    <div class="prose flex flex-col w-full max-w-full mt-4">
      <div class="flex justify-between items-end w-full">
        <div>
          <h5 class="text-gray-700">{{ company.name || '' }}</h5>
          <div v-if="address"
               class="flex text-xs leading-5 text-cool-gray-500 font-medium">
            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-cool-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clip-rule="evenodd"></path>
            </svg>
            <span>{{ address.address_1 }}</span>
            <span>{{ address.city }}, {{ address.state }}</span>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <span class="whitespace-nowrap text leading-5 text-gray-900 font-semibold">
            {{ get(payroll, 'payment_number') }}
          </span>
          <status-badge
            v-if="!myChecks"
            :status="payroll.status"
          />
          <time class="whitespace-nowrap text-sm leading-5 text-cool-gray-500">
            {{ $formatDate(get(payroll, 'period_end_date')) }}
          </time>
        </div>
      </div>
      <hr>
      <div class="flex justify-between">
        <div class="flex w-full text-gray-700">
          <h5 class="text-gray-700">{{ $t('Net Pay') }}</h5>
        </div>
        <div class="text-gray-900 font-semibold leading-normal inline-block">
          {{ $formatPrice(get(payroll, 'totals.net_pay')) }}
        </div>
      </div>
      <hr>
    </div>
    <slot/>
  </base-form-dialog>
</template>
<script>

  export default {
    name: 'PayrollPreviewDialog',
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      myChecks: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('Code'),
            prop: 'source_code',
            maxWidth: 150,
            padding: '10px 12px',
          },
          {
            label: this.$t('Amount'),
            prop: 'amount',
            maxWidth: 150,
            component: 'FormattedPrice',
            padding: '10px 12px',
          },
          {
            label: this.$t('To-Date'),
            prop: 'ytd',
            maxWidth: 150,
            component: 'FormattedPrice',
            padding: '10px 12px',
          },
        ],
      }
    },
    computed: {
      payroll() {
        return this.data.payroll || {}
      },
      company() {
        return this.$store.getters['company/getCurrentCompany']
      },
      address() {
        const { addresses } = this.company || []
        return addresses.find(address => address.attributes.primary)?.attributes || addresses[0]?.attributes
      },
      employee() {
        return this.data.employee
      },
      title() {
        if (this.myChecks) {
          return this.$t('Check Details')
        }
        return this.$t(`Payroll register for ${this.employee.name}`)
      },
    },
  }
</script>
<style>
  .payroll-modal .prose hr {
    @apply my-4;
  }
</style>
