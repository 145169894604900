<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :id="$attrs.id || 'maintenance-code-select'"
    :name="$attrs.name || $t('Maintenance Code')"
    :placeholder="$attrs.placeholder || $t('Select Maintenance Code')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Maintenance Code')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :options="options"
    :label-format="formatLabel"
    :table-columns="tableColumns"
    :resource-name="$globalResources.EquipmentMaintenances"
    :value-key="valueKey"
    :label-key="labelKey"
    :initial-value="initialValue"
  />
</template>
<script>
import i18n from '@/i18n'

export default {
  name: 'MaintenanceCodeSelect',
  props: {
    addEntity: {
      type: Boolean,
      default: false,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'code',
    },
    initialValue: {
      type: [Object, Array],
    },
  },
  computed: {
    maintenanceCodes() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.EquipmentMaintenances) || []
    },
    options() {
      return this.maintenanceCodes
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 80,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'code',
        },
        {
          minWidth: 130,
          maxWidth: 130,
          name: this.$t('Description'),
          prop: 'description',
        },
      ]

      return columns.filter(c => !c.showIf || c.showIf())
    },
  },
  methods: {
    formatLabel(maintenanceCode) {
      const { code, description } = maintenanceCode
      return !description ? code : `${code} (${description})`
    },
  },
}
</script>
