<template>
  <span class="flex space-x-2 my-2">
    <UserLink :id="data.attributes?.created_by"/>
    <span>{{ $t('updated') }}  {{ $tc('fields', fieldCount) }}</span>
    <div
      v-if="throughEntityId"
      class="inline-flex items-center space-x-2"
    >
      <span>{{ $t('through') }}</span>
      <component
        :is="throughEntityComponent"
        :data="throughEntityData"
      />
    </div>
    <span
      v-if="!hideName"
      class="font-medium capitalize"
    >
        <template v-if="formattedName">
          {{formattedName}}
        </template>
        <template v-else>
        {{ formattedText(module) }} {{ $t('Settings') }}
        </template>
    </span>
    <span>{{ timeAgo(data.attributes.updated_at) }} {{ $t('ago') }}</span>
  </span>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { formattedText } from 'src/utils/utils'
  import { timeAgo } from 'src/plugins/dateFormatPlugin'
  import { globalResources } from "@/components/form/util";

  const throughComponentsMap = {
    [globalResources.RepairOrders]: 'RepairOrderLink',
  }

  export default defineComponent({
    methods: {
      timeAgo,
      formattedText,
    },
    computed: {
      data(): any {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      module(): string {
        return this.params.module
      },
      hideName(): boolean {
        return this.params.hideName
      },
      current(): any {
        return this.data?.attributes?.current || {}
      },
      formattedName(): string {
        return this.params.name
      },
      ignoreKeys() {
        if (!this.throughEntity) {
          return []
        }
        return [this.throughEntity.id, ...this.throughEntity.props.map((prop: any) => prop.value_key)]
      },
      fieldCount() {
        const currentKeys = Object.keys(this.current)
          .filter(key => !this.ignoreKeys.includes(key))

        return currentKeys.length || 0
      },
      throughEntity(): any {
        return this.params.throughEntity
      },
      throughEntityId() {
        if (!this.throughEntity) {
          return null
        }

        return this.current[this.throughEntity?.id]
      },
      throughEntityComponent() {
        // @ts-ignore
        return throughComponentsMap[this.throughEntity?.type]
      },
      throughEntityData() {
        if (!this.throughEntity) {
          return null
        }

        const data: any = {
          id: this.throughEntityId,
        }

        this.throughEntity.props.forEach((prop: any) => {
          data[prop.entity_prop] = this.current[prop.value_key]
        })

        return data
      },
    },
  })
</script>
