import axios from 'axios'
import get from 'lodash/get'
import { onMounted, reactive, ref, watch } from 'vue'
import i18n from "@/i18n";
import { error } from "@/components/common/NotificationPlugin";
import { gridContext } from "@/components/ag-grid/gridContext";
import isEmpty from "lodash/isEmpty";
import Cache from "@/utils/Cache";
import store from "@/store";
import { useStorage } from "@vueuse/core";
import cloneDeep from "lodash/cloneDeep";

function getMatchFilters() {
    let filters: Record<string, any> = {}
    for (let key in gridContext.matchFilters) {
        const value = gridContext.matchFilters[key]
        if (value) {
            filters[key] = value
        }
    }
    return filters
}

function getAdvancedFilters() {
    const filters = gridContext.advancedFilters.filter((f: Record<string, any>) => {
        let key = get(Object.keys(f.value || {}), '[0]')
        let keyStr = key || ''
        const value = get(f.value, keyStr, [])
        return Array.isArray(value) && value.length > 0
    })
    return btoa(JSON.stringify(filters))
}

type PaginatedRequestConfig = {
    page?: number;
    perPage?: number;
    url?: string;
    urlQuery?: string;
    actionType: 'get' | 'post' | 'put';
    defaultFilters?: [];
    urlParams: Record<string, any>,
    props: Record<string, any>,
    transformData?: (data: any) => any[] | Promise<any[]>,
    dataFetched?: (data: any) => any[] | Promise<any[]>,
    metaFetched?: (data: any) => any[] | Promise<any[]>,
    appendData?: boolean,
    showPagination?: boolean,
    changePageWithTimeout?: boolean,
    disableCache?: boolean,
}

export function usePaginatedRequest(config: PaginatedRequestConfig) {
    const paginatedData = ref<any[]>([])
    const loading = ref<boolean>(false)
    const allParams = ref<Record<string, any>>({})
    const metaData = ref<Record<string, any>>({})
    const perPage = config.perPage || 50
    let controller: AbortController;

    const searchQuery = ref('')

    let pagination = reactive({
        page: config.page || 1,
        perPage: perPage,
        per_page: perPage,
        current_page: config.page || 1,
        total: 5,
        last_page: 1,
    })

    watch(() => pagination.page, async (newValue) => {
        if (!newValue) {
            return
        }
        await fetchData({ page: newValue })
    })
    watch(() => pagination.current_page, async (newValue) => {
        if (!newValue) {
            return
        }
        await fetchData({ page: newValue })
    })

    watch(() => pagination.perPage, async (newValue, oldValue) => {
        if (!newValue) {
            return
        }
        await fetchData({
            page: 1,
            perPage: newValue,
        })
    })

    async function getFilters() {
        if (!config.url || !config.defaultFilters || !gridContext.filtersUrl || !isEmpty(gridContext.filterOptions)) {
            return
        }
        try {
            const url = gridContext.filtersUrl.replace('/api', '')
            const filterParams = {
                include: 'matches,sortables',
            }

            const result = await Cache.getRequest(url, {
                ttl: 60 * 60 * 1000, // 1 hour
                params: filterParams,
            })
            gridContext.filterOptions = {
                sortables: [],
                matches: [],
            }
            gridContext.lastRequestParams = allParams

            result.data.forEach((filter: any) => {
                if (filter.type === 'sortables' || filter.key === 'sortables') {
                    gridContext.filterOptions.sortables.push(filter)
                } else {
                    gridContext.filterOptions.matches.push(filter)
                }
            })
        } catch (err) {
            console.warn('Could not retrieve filters')
        }
    }

    async function fetchData(requestParams: Record<string, any> = {}, force = false) {
        if (!config.url) {
            return
        }
        try {
            loading.value = true

            if (requestParams?.search) {
                pagination.page = 1
            }

            allParams.value = {
                ...pagination,
                ...config.urlParams,
                ...config.props.urlParams,
                ...getMatchFilters(),
                ...requestParams,
                search: requestParams?.search || searchQuery.value,
            }

            cleanupParams(allParams.value)

            if (gridContext.advancedFilters.length) {
                allParams.value.filters = getAdvancedFilters()
            }

            let fullUrl = config.url + config.urlQuery
            const actionType = config.actionType
            let response
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()

            if (actionType === 'get') {
                response = await Cache.getRequest(fullUrl, {
                    params: allParams.value,
                    invalidateCache: force || config.disableCache,
                    signal: controller.signal,
                })
            } else {
                response = await axios[actionType](fullUrl, { ...allParams.value })
            }
            let data = get(response, 'data', [])

            if (config.transformData) {
                const dataCopy = cloneDeep(data)
                data = await config.transformData(dataCopy)
            }

            if (pagination.page > 1 && config.appendData) {
                paginatedData.value.push(...data)
            } else {
                if (config.changePageWithTimeout) {
                    paginatedData.value = []
                    setTimeout(() => {
                        paginatedData.value = data
                    }, 0)
                }
                else {
                    paginatedData.value = data
                }
            }

            if (config.showPagination) {
                store.commit('table/SET_CURRENT_PER_PAGE', pagination.per_page)
            }

            pagination.page = response?.meta?.current_page
            pagination.current_page = response?.meta?.current_page
            pagination.perPage = response?.meta?.per_page
            pagination.per_page = response?.meta?.per_page
            pagination.total = response?.meta?.total
            pagination.last_page = response?.meta?.last_page

            metaData.value = response.meta

            const { links } = response
            if (links?.filters) {
                gridContext.filtersUrl = links.filters
                await getFilters()
            }
            if (config.dataFetched) {

                config.dataFetched(data)
            }
          if (config.metaFetched) {
            config.metaFetched(response.meta)
          }
        } catch (err: any) {
            if (err?.name === 'CanceledError') {
              loading.value = false
              return
            }
            console.error('Could not fetch the data', err)
            error(i18n.t('Could not fetch the data'))
        } finally {
            loading.value = false
        }

        return paginatedData
    }

    function cleanupParams(params: Record<string, any>) {
        const keysToDelete = ['total', 'current_page', 'last_page', 'per_page']
        keysToDelete.forEach(key => {
            delete params[key]
        })
        return params
    }

    onMounted(async () => {
        await fetchData()
    })

    return {
        loading,
        fetchData,
        data: paginatedData,
        requestParams: allParams,
        searchQuery,
        pagination,
        metaData,
    }
}
