<template>
  <RecurringLumpSumBillingForm
    :data="recurringBilling"
    @refresh="getRecurringBilling"
  />
</template>
<script>
import RecurringLumpSumBillingForm from '@/modules/accounts-receivable/components/recurring-billings/lump-sum/RecurringLumpSumBillingForm.vue'

export default {
  components: {
    RecurringLumpSumBillingForm,
  },
  computed: {
    recurringBilling() {
      return this.$store.state.accountsReceivable.currentRecurringBilling
    },
  },
  methods: {
    getRecurringBilling() {
      this.$store.dispatch('accountsReceivable/getRecurringBilling', this.$route.params.id)
    },
  },
  watch: {
    recurringBilling: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value.id) {
          return
        }
        const { meta } = value || {}
        if (meta.hasOwnProperty('authorizedToUpdate') && !meta.authorizedToUpdate) {
          this.$router.push(`/accounts-receivable/recurring-billings/${value.id}/view`)
        }
      },
    },
  },
}
</script>
