import type {FunctionDeclaration} from "@google/generative-ai";
import {SchemaType} from "@google/generative-ai";
import {globalResources} from "@/components/form/util";
import store from "@/store";
import {can} from "@/modules/auth/plugins/permissionsPlugin";
import {ToolCall} from "@/modules/support/types/multimodal-live-types";
import {MultimodalLiveClient} from "@/modules/support/lib/multimodal-live-client";
import {ref, Ref} from "vue";
import get from "lodash/get";
import orderBy from "lodash/orderBy";

const EntityTypes = [
  'accounts',
  globalResources.Vendors,
  globalResources.Customers,
  globalResources.Jobs,
  globalResources.LineItems,
  globalResources.Employees,
  globalResources.Equipments,
  globalResources.Materials,
  globalResources.WorkOrders,
  globalResources.JobCategories,
  globalResources.PurchaseOrders,
]

const Operators = [
  'eq', 'gte', 'lte', 'between', 'contains',
]

export const searchFunctionTool: FunctionDeclaration = {
  name: "search_entities",
  description: "Search for entities based on a query.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      entity: {
        type: SchemaType.STRING,
        enum: EntityTypes,
        description: "The entity type to search within (vendors, customers, accounts, etc.).",
      },
      query: {
        type: SchemaType.STRING,
        description: "The search query string.",
      },
    },
    required: ["entity", "query"],
  },
};

export const searchByFieldFunctionTool: FunctionDeclaration = {
  name: "search_entities_by_field",
  description: "Search for entities based on a specific field value.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      entity: {
        type: SchemaType.STRING,
        enum: EntityTypes,
        description: "The entity type to search within (vendors, customers, accounts, etc.).",
      },
      field: {
        type: SchemaType.STRING,
        description: "The field to search by",
      },
      field_value: {
        type: SchemaType.STRING,
        description: "The field value to search by. Can only be string or number. No dates supported for now",
      },
      operator: {
        type: SchemaType.STRING,
        enum: Operators,
        description: "The operator to use for the search",
      },
    },
    required: ["entity", "field", "field_value", "operator"],
  },
};

export const sortByFieldFunctionTool: FunctionDeclaration = {
  name: "sort_entities_by_field",
  description: "Sort entities based on a specific field in ascending or descending order.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      entity: {
        type: SchemaType.STRING,
        enum: EntityTypes,
        description: "The entity type to sort within (vendors, customers, accounts, etc.).",
      },
      field: {
        type: SchemaType.STRING,
        description: "The field to sort by.",
      },
      order: {
        type: SchemaType.STRING,
        enum: ["asc", "desc"],
        description: "The sorting order. Use 'asc' for ascending and 'desc' for descending.",
      },
    },
    required: ["entity", "field", "order"],
  },
};

export const renderChartsTool: FunctionDeclaration = {
  name: "render_charts",
  description: "Displays a graph in json format using vega embed.",
  parameters: {
    type: SchemaType.OBJECT,
    properties: {
      json_graph: {
        type: SchemaType.STRING,
        description:
          "JSON STRING representation of the graph to render. Must be a string, not a json object. Make sure to assign width and height",
      },
    },
    required: ["json_graph"],
  },
};

function getEntityMap() {
  const globalLists = store.state.globalLists
  return {
    accounts: {
      show: can('accounts_show'),
      data: store.state.company.accounts
    },
    [globalResources.Vendors]: {
      show: can('vendors_show'),
      data: globalLists[globalResources.Vendors]
    },
    [globalResources.Customers]: {
      show: can('customers_show'),
      data: globalLists[globalResources.Customers]
    },
    [globalResources.Jobs]: {
      show: can('jobs_show'),
      data: globalLists[globalResources.Jobs]
    },
    [globalResources.LineItems]: {
      show: can('line_items_show'),
      data: globalLists[globalResources.LineItems]
    },
    [globalResources.Employees]: {
      show: can('employees_show'),
      data: globalLists[globalResources.Employees]
    },
    [globalResources.Equipments]: {
      show: can('equipment_show'),
      data: globalLists[globalResources.Equipments]
    },
    [globalResources.Materials]: {
      show: can('materials_show'),
      data: globalLists[globalResources.Materials]
    },
    [globalResources.WorkOrders]: {
      show: can('work_orders_show'),
      data: globalLists[globalResources.WorkOrders]
    },
    [globalResources.JobCategories]: {
      show: can('job_categories_show'),
      data: globalLists[globalResources.JobCategories]
    },
    [globalResources.PurchaseOrders]: {
      show: can('purchase_orders_show'),
      data: globalLists[globalResources.PurchaseOrders]
    },
  }

}

export function search_entities(entityName: string, query: string) {
  if (!entityName || !query) {
    return []
  }

  const entityMap = getEntityMap()
  let entity = entityMap[entityName]
  let data = []
  if (entity?.show) {
    data = entity.data || []
  }
  if (query) {
    data = data.filter((item: SchemaType) => {
      return JSON.stringify(item).toLowerCase().includes(query?.toLowerCase())
    })
  }
  const maxResults = 10
  return data.slice(0, maxResults)
}


export function search_entities_by_field(entityName: string, field: string, field_value: string, operator: string) {
  if (!entityName) {
    return;
  }

  const entityMap = getEntityMap();
  let entity = entityMap[entityName];
  let data = [];

  if (entity?.show) {
    data = entity.data || [];
  }

  if (!field || !field_value) {
    return []
  }
  const fieldDate = isValidDate(field_value) ? new Date(field_value) : null;

  data = data.filter((item: SchemaType) => {
    const value = get(item, field);

    if (isValidDate(value) && fieldDate) {
      const valueDate = new Date(value);

      switch (operator) {
        case 'eq':
          return valueDate.getTime() === fieldDate.getTime();
        case 'gte':
          return valueDate.getTime() >= fieldDate.getTime();
        case 'lte':
          return valueDate.getTime() <= fieldDate.getTime();
        default:
          return false;
      }
    }

    switch (operator) {
      case 'eq':
        return value === field_value;
      case 'gte':
        return typeof value === 'number' && value >= Number(field_value);
      case 'lte':
        return typeof value === 'number' && value <= Number(field_value);
      case 'contains':
        return typeof value === 'string' && value?.includes(field_value);
      default:
        return false;
    }
  });


  const maxResults = 10;
  return data.slice(0, maxResults);
}

export function sort_entities_by_field(entityName: string, field: string, order: "asc" | "desc") {
  if (!entityName || !field) {
    return;
  }

  const entityMap = getEntityMap();
  let entity = entityMap[entityName];
  let data = [];

  if (entity?.show) {
    data = entity.data || [];
  }

  const maxItems = 20
  return orderBy(data, field, order).slice(0, maxItems);
}

function isValidDate(date: any): boolean {
  return !isNaN(Date.parse(date));
}

export const chartDataJson = ref<string>()

export function onToolCall(toolCall: ToolCall, client: Ref<MultimodalLiveClient>) {
  console.log('got toolcall', toolCall);
  let responses: any[] = []
  toolCall.functionCalls.forEach(fc => {
    if (fc?.name === 'search_entities') {
      const data = search_entities(fc.args?.entity, fc.args?.query)
      responses.push({
        fc,
        data,
      })
    } else if (fc?.name === 'search_entities_by_field') {
      const data = search_entities_by_field(fc.args?.entity, fc.args?.field, fc.args?.field_value, fc.args?.operator)
      responses.push({
        fc,
        data,
      })
    } else if (fc?.name === 'sort_entities_by_field') {
      const data = sort_entities_by_field(fc.args?.entity, fc.args?.field, fc.args?.order)
      responses.push({
        fc,
        data,
      })
    } else if (fc?.name === 'render_charts') {
      chartDataJson.value = (fc.args as any)?.json_graph as string
      let success = true
      try {
        JSON.parse(chartDataJson.value)
        success = true
      } catch (err) {
        success = false
      }
      responses.push({
        fc,
        data: {
          success,
        },
      })
    }
  })
  console.log('toolcall response', responses);
  client.value.sendToolResponse({
    functionResponses: responses.map((r) => {
      return {
        response: {
          output: {
            success: true,
            data: r.data
          }
        },
        id: r.fc?.id as string,
      }
    })
  })
}
