<template>
  <div class="h-full">
    <AgDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :add-entity-in-new-tab="addEntityInNewTab"
      :default-match-filters="defaultMatchFilters"
      :add-text="$t('New agency')"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :disable-col-flex="true"
      :per-page="$pagination.list"
      actions="search,refresh,view"
      entity="agencies"
      ref="table"
      permission="agencies"
      @add="$router.push('/payroll/agencies/add')"
    >
      <template #attributes.code="{row}">
        <EntityPreview
          :entity="row.attributes"
          :key="`preview-${row.id}`"
          :addresses="get(row, 'relationships.addresses', [])"
          :url="`/payroll/agencies/${row.id}/view`"
          :value-to-display="get(row, 'attributes.code')"
          :parent-entity-id="row.id"
          entity-name="agency"
        />
      </template>
    </AgDataTable>
    <!-- TODO: Will leave this dialog here, most likely we will implement delete preflight in the near future -->
    <DeleteResourcePreflightDialog
      v-if="showDeleteDialog"
      :open.sync="showDeleteDialog"
      :resource="itemToDelete"
      :resourceName="$globalResources.Agencies"
      @deleted="onItemDeleted"
      @close="showDeleteDialog = false"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { Column } from "@/components/ag-grid/tableTypes";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { VendorStatusOptions } from "@/modules/accounts-payable/enum/vendor";
import { ValueSetterParams } from "@ag-grid-community/core";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import { getDefaultMatchFilter } from "@/components/ag-grid/filterUtils";

export default defineComponent({
  data() {
    return {
      url: '/restify/agencies',
      urlParams: {
        related: 'addresses'
      },
      addEntityInNewTab: true,
      showDeleteDialog: false,
      itemToDelete: null,
    }
  },
  computed: {
    columns(): Column[] {
      return [
        {
          headerName: this.$t('Code'),
          field: 'attributes.code',
          minWidth: 120,
          maxWidth: 150,
        },
        {
          headerName: this.$t('Name'),
          prop: 'attributes.name',
          minWidth: 160,
          maxWidth: 300,
          editable: true,
          headerClass: cellClasses.HeaderEditable,
          valueSetter: (params: ValueSetterParams) => {
            return valueSetterWithUpdate({
              storeAction: 'payroll/patchAgency',
            })(params)
          },
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 200,
          flex: 1,
        },
        {
          headerName: this.$t('Status'),
          prop: 'attributes.status',
          minWidth: 120,
          component: 'Status',
          editable: true,
          cellEditor: cellEditors.Status,
          cellEditorParams: {
            options: VendorStatusOptions,
          },
          headerClass: cellClasses.HeaderEditable,
          valueSetter: (params: ValueSetterParams) => {
            return valueSetterWithUpdate({
              storeAction: 'payroll/patchAgency',
            })(params)
          },
        },
        {
          headerName: this.$t('Address'),
          prop: 'addresses',
          minWidth: 120,
          maxWidth: 240,
          component: 'PrimaryAddressLink',
          hide: true,
        },
      ]
    },
    defaultMatchFilters() {
      return getDefaultMatchFilter('show_inactive_agencies')
    }
  },
  methods: {
    onItemDeleted() {
      this.showDeleteDialog = false
      this.$refs.table?.refresh()
    }
  }
})
</script>
