<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :queryParamParsers="queryParamParsers"
    :urlParamParsers="urlParamParsers"
  >
    <template #filters>

      <BaseFilterRow :title="$t('Format')">
        <BaseSelect
          v-model="model.format"
          :options="formatOptions"
          class="col-span-3"
          @change="onFormatChanged"
        />
      </BaseFilterRow>

      <CommonReportFilters
        v-model="model"
      />

      <BaseFilterRow
        v-if="isPeriodFormat"
        :title="$t('Period End Date Range')"
      >
        <DateRangeFilter
          v-model="model"
          :name="$t('Period End Date Range')"
          class="col-span-4"
          rules="required"
        />
      </BaseFilterRow>
      <BaseFilterRow
        v-if="isPeriodFormat"
        :title="$t('Period Active Only')"
        :tip="$t('Only show Line Items with activity in the selected date range.')"
      >
        <BaseCheckbox
          v-model="model.period_active_only"
          class="col-span-3"
          id="print_period_active_only"
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="isPeriodOrNoPeriodAmountsFormat"
        :title="$t('Print Phase Totals')"
      >
        <BaseCheckbox
          v-model="model.phase_totals"
          class="col-span-3"
          id="print_phase_totals"
        />
      </BaseFilterRow>

      <BaseFilterRow
        v-if="isPeriodOrNoPeriodAmountsFormat"
        :title="$t('Print')"
      >
        <BaseCheckbox
          v-model="model.include.hours"
          :label="$t('Hours')"
          class="col-span-2"
          id="print_hours"
        />
        <BaseCheckbox
          v-model="model.include.labor"
          :label="$t('Labor $')"
          class="col-span-2"
          id="print_labor"
        />
        <BaseCheckbox
          v-model="model.include.burden_dollars"
          :label="$t('Burden $')"
          class="col-span-2"
          id="print_burden_dollars"
        />
        <BaseCheckbox
          v-model="model.include.burden_percent"
          :label="$t('Burden %')"
          class="col-span-2"
          id="print_burden_percent"
        />
        <BaseCheckbox
          v-model="model.include.labor_plus_burden"
          :label="$t('Labor + Burden')"
          class="col-span-2"
          id="print_labor_plus_burden"
        />
      </BaseFilterRow>

    </template>

    <template #table="{ loading, data }">
      <component
        :is="tableComponent"
        :data="data"
        :filters="model"
        :report-number="reportNumber"
        :data-loading="loading"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import { commonFilters, LaborStatusReportOptions } from '@/modules/job-costing/components/reports/util'
import CommonReportFilters from '@/modules/job-costing/components/reports/CommonReportFilters'
import LaborStatusByPeriodReportTable from '@/modules/job-costing/components/reports/labor-status-report/LaborStatusByPeriodReportTable.vue'
import LaborStatusSummaryReportTable from '@/modules/job-costing/components/reports/labor-status-report/LaborStatusSummaryReportTable.vue'
import { DateRangeFilter } from '@/components/range-filters'

export default {
  components: {
    CommonReportFilters,
    LaborStatusByPeriodReportTable,
    LaborStatusSummaryReportTable,
    DateRangeFilter,
  },
  data() {
    return {
      reportNumber: '2883',
      LaborStatusReportOptions,
      queryParamParsers: {
        include: (params, value) => {
          for (let key in value) {
            params[`include[${key}]`] = params.include[key] ? 1 : 0
          }
          delete params.include
        },
      },
      urlParamParsers: {
        include: (params, subKey, value) => {
          if (!params.include) {
            params.include = {}
          }

          params.include[subKey] = (value === '1')
        },
      },
      model: {
        ...commonFilters,
        format: LaborStatusReportOptions.Format.PeriodTotals,
        phase_totals: false,
        start_date: null,
        end_date: null,
        period_active_only: true,
        include: {
          hours: true,
          labor: true,
          burden_dollars: true,
          burden_percent: true,
          labor_plus_burden: false,
        }
      },
      // Select options
      formatOptions: [
        {
          label: this.$t('Period Totals'),
          value: LaborStatusReportOptions.Format.PeriodTotals,
        },
        {
          label: this.$t('Period Detail'),
          value: LaborStatusReportOptions.Format.PeriodDetail,
        },
        {
          label: this.$t('No Period Amounts'),
          value: LaborStatusReportOptions.Format.NoPeriodAmount,
        },
        {
          label: this.$t('Line Item Summary'),
          value: LaborStatusReportOptions.Format.LineItemSummary,
        },
        {
          label: this.$t('Job Summary'),
          value: LaborStatusReportOptions.Format.JobSummary,
        },
        // This option is for 28.83b, not yet implemented BE-side
        // {
        //   label: this.$t('Line Item Totals'),
        //   value: LaborStatusReportOptions.Format.LineItemTotals,
        // },
      ],
    }
  },
  computed: {
    tableComponent() {
      const tableComponents = {
        [LaborStatusReportOptions.Format.PeriodTotals]: LaborStatusByPeriodReportTable,
        [LaborStatusReportOptions.Format.PeriodDetail]: LaborStatusByPeriodReportTable,
        [LaborStatusReportOptions.Format.NoPeriodAmount]: LaborStatusByPeriodReportTable,
        [LaborStatusReportOptions.Format.LineItemSummary]: LaborStatusSummaryReportTable,
        [LaborStatusReportOptions.Format.JobSummary]: LaborStatusSummaryReportTable,
        // [LaborStatusReportOptions.Format.LineItemTotals]: TBD,
      }

      return tableComponents[this.model.format]
    },
    isPeriodFormat() {
      return [
        LaborStatusReportOptions.Format.PeriodDetail,
        LaborStatusReportOptions.Format.PeriodTotals,
      ].includes(this.model.format)
    },
    isPeriodOrNoPeriodAmountsFormat() {
      return [
        LaborStatusReportOptions.Format.PeriodDetail,
        LaborStatusReportOptions.Format.PeriodTotals,
        LaborStatusReportOptions.Format.NoPeriodAmount,
      ].includes(this.model.format)
    },
  },
  methods: {
    onFormatChanged() {
      if (!this.isPeriodFormat) {
        this.model.period_active_only = false
        this.model.start_date = null
        this.model.end_date = null
      }

      this.model.phase_totals = false
      this.model.include = {
        hours: true,
        labor: true,
        burden_dollars: true,
        burden_percent: true,
        labor_plus_burden: false,
      }
    }
  }
}
</script>
