<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :can-export-to-csv="true"
  >
    <template #filters>
      <BaseFilterRow :title="$t('Sort By')">
        <BaseSelect
          v-model="model.sort_by"
          :options="sortByOptions"
          class="col-span-4"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t(`Include`)">
        <BaseSelect
          v-model="model.include"
          :options="includeOptions"
          class="col-span-4"
          @change="model.code_ids = []"
        />
      </BaseFilterRow>

      <BaseFilterRow :title="$t(`State`)">
        <StateSelect
          v-model="model.state"
          class="col-span-4"
        />
      </BaseFilterRow>

      <EmployeeFilterRow
        v-model="model"
      />

      <BaseFilterRow :title="$t('Union Range')">
        <RangeFilter
          :config="RangeFilterConfig.Union"
          :model="model"
          :from.sync="model.union_code_from"
          :to.sync="model.union_code_to"
          class="col-span-4"
        />
      </BaseFilterRow>

      <DepartmentRangeFilter
        v-model="model"
      />

      <BaseFilterRow>
        <BaseCheckbox
          v-model="model.group_by_department"
          :label="$t('Group By Department')"
          class="mb-2 col-span-4"
          id="group_by_department"
        />
      </BaseFilterRow>

    </template>

    <template #table="{ data, loading }">
      <EmployeeListingReportTable
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
    </template>
  </BaseReportForm>
</template>
<script>
import EmployeeListingReportTable from '@/modules/payroll/components/reports/employee-listing/EmployeeListingReportTable.vue'
import { DepartmentRangeFilter } from '@/components/range-filters'
import { RangeFilterConfig } from '@/components/range-filters'
import { EmployeeListingOptions } from '@/modules/payroll/components/reports/util'
import EmployeeFilterRow from '@/components/entity-filters/EmployeeFilterRow.vue'

export default {
  components: {
    DepartmentRangeFilter,
    EmployeeListingReportTable,
    EmployeeFilterRow,
  },
  setup() {
    return {
      EmployeeListingOptions,
      RangeFilterConfig,
    }
  },
  data() {
    return {
      reportNumber: 4885,
      model: {
        include: EmployeeListingOptions.Include.All,
        sort_by: EmployeeListingOptions.SortBy.Employee,
        state: null,
        employee_code_from: null,
        employee_code_to: null,
        employee_ids: [],
        department_code_from: null,
        department_code_to: null,
        union_code_from: null,
        union_code_to: null,
        group_by_department: false,
      },
      // Select options
      sortByOptions: [
        {
          label: this.$t('Employee Code'),
          value: EmployeeListingOptions.SortBy.Employee,
        },
        {
          label: this.$t('Union Code'),
          value: EmployeeListingOptions.SortBy.Union,
        },
        {
          label: this.$t('State'),
          value: EmployeeListingOptions.SortBy.State,
        },
        {
          label: this.$t('Birth Date'),
          value: EmployeeListingOptions.SortBy.Birthday,
        },
        {
          label: this.$t('Social Security No'),
          value: EmployeeListingOptions.SortBy.SSN,
        },
        {
          label: this.$t('Original Hire Date'),
          value: EmployeeListingOptions.SortBy.OriginalHireDate,
        },
        {
          label: this.$t('Termination Date'),
          value: EmployeeListingOptions.SortBy.TerminationDate,
        },
        {
          label: this.$t('Rehire Date'),
          value: EmployeeListingOptions.SortBy.RehireDate,
        },
        {
          label: this.$t('Other Date'),
          value: EmployeeListingOptions.SortBy.OtherDate,
        },
        {
          label: this.$t('Last Check Date'),
          value: EmployeeListingOptions.SortBy.LastCheckDate,
        },
      ],
      includeOptions: [
        {
          label: this.$t('All'),
          value: EmployeeListingOptions.Include.All,
        },
        {
          label: this.$t('Active'),
          value: EmployeeListingOptions.Include.Active,
        },
        {
          label: this.$t('Inactive'),
          value: EmployeeListingOptions.Include.Inactive,
        },
        {
          label: this.$t('Hourly'),
          value: EmployeeListingOptions.Include.Hourly,
        },
        {
          label: this.$t('Salary'),
          value: EmployeeListingOptions.Include.Salary,
        },
      ],
    }
  },
}
</script>
