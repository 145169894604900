<template>
  <div class="cursor-pointer flex p-1"
       :class="{
            'items-center': !vertical,
            'flex-col-reverse items-start': vertical,
         }"
  >
    <input :id="$attrs.id"
           :disabled="$attrs.disabled"
           :checked="value"
           @change="onChange"
           :class="{
                 'cursor-not-allowed text-gray-300 bg-gray-200': $attrs.disabled,
                 'ml-0.5': vertical,
               }"
           class="h-4 w-4 text-primary-600 border-gray-300 rounded transition duration-150 ease-in-out"
           type="checkbox"
    />
    <label :for="$attrs.id"
           class="mt-1 form-label no-select items-center space-x-2"
           :class="{
              'mx-2 cursor-pointer': !vertical,
              'vertical-checkbox-label': vertical,
           }"
    >
      <slot name="label">
        <span>{{ label }}</span>
      </slot>
      <BaseTooltip v-if="tip" :tabindex="-1">
        <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help"/>
        <template #content>
          <div
            class="max-w-[300px]"
            v-html="tip"
          />
        </template>
      </BaseTooltip>
    </label>
  </div>
</template>
<script>
  import { HelpCircleIcon } from 'vue-feather-icons'

  export default {
    components: {
      HelpCircleIcon
    },
    inheritAttrs: false,
    props: {
      value: {
        type: [Boolean, String, Number],
        description: 'Switch value'
      },
      label: {
        type: String,
        default: ''
      },
      tip: {
        type: String,
        default: ''
      },
      vertical: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      onChange(evt) {
        let value = evt.target?.checked
        // * When we send value as query param, it is converted to string, so we need to convert it to number
        value = Number(value)

        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
</script>
<style scoped>
  .form-label {
    @apply flex;
  }
  .form-label.vertical-checkbox-label {
    @apply mb-2;
  }
</style>
