<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :data-loading="dataLoading"
    @force-pagination="forcePagination"
  >
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
    </template>

    <template #header="{ row }">
      <div class="flex">
        <template v-if="row.header?.job">
          <div class="w-1/3 flex">
            {{ $t('Job:') }} &nbsp;
            <JobLink
              :data="row.header.job"
              :show-preview="false"
              :show-name="true"
            />
          </div>
          <template v-if="get(row, 'header.customer.id')">
            {{ $t('Customer:') }} &nbsp;
            <CustomerLink
              :data="row.header.customer"
              :show-preview="false"
              :show-name="true"
            />
          </template>
          <template v-if="get(row, 'header.billing_rate.id')">
            <span>
               - {{ $t('Billing Rate:') }} {{ row.header.billing_rate.title }}
            </span>
          </template>
        </template>
        <template v-else-if="row.header?.line_item">
          {{ $t('Line Item:') }} &nbsp;
          <LineItemLink
            :data="row.header.line_item"
            :show-preview="false"
            :show-name="true"
          />
        </template>
        <template v-else-if="row.header?.cost_type">
          {{ $t('Cost Type:') }} &nbsp;
          <span>{{ row.header.cost_type.abbr }}</span>
        </template>
      </div>
    </template>

    <template #business="{row}">
      <EmployeeLink
        v-if="row.business.type === 'employee'"
        :data="row.business"
        :show-description="false"
        :show-preview="false"
      />
      <VendorLink
        v-else-if="row.business.type === 'vendor'"
        :data="row.business"
        :show-description="true"
        :show-preview="false"
      />
      <JournalLink
        v-else-if="row.business.type === 'journal'"
        :data="row.business"
        :show-description="true"
        :show-preview="false"
      />
      <span v-else>
        ? {{ row.business.type }} not mapped ?
      </span>
    </template>

    <template #subtotal="{subtotal}">
      <td :colspan="subtotal.totals.hasOwnProperty('quantity') ? 3 : 5" />

      <td align="right" style="vertical-align: top;">
        {{ $t(subtotal.name) }}
      </td>
      <template v-if="subtotal.totals.hasOwnProperty('quantity')">
        <td
          align="right" style="vertical-align: top;"
        >
          <span> {{ $formatQuantity(subtotal.totals.quantity) }}</span>
        </td>
        <td />
      </template>
      <td align="right" style="vertical-align: top;">
        <span>{{ $formatPrice(subtotal.totals.subtotal) }}</span>
      </td>
      <td
        v-if="showMarkupColumn"
        align="right" style="vertical-align: top;"
      >
        <span>{{ $formatPrice(subtotal.totals.markup) }}</span>
      </td>

      <td
        v-if="showOtherColumn"
        align="right" style="vertical-align: top;"
      >
        <span>{{ $formatPrice(subtotal.totals.other) }}</span>
      </td>
      <td
        v-if="showTotalColumn"
        align="right" style="vertical-align: top;"
      >
        <span>{{ $formatPrice(subtotal.totals.total) }}</span>
      </td>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import {
  preBillingViewFormats,
  PreBillingReportOptions,
  QuantityLabels,
} from '@/modules/accounts-receivable/components/reports/util'
import { isNumber } from '@/modules/common/util/commonUtils';

export default {
  extends: ReportTableWrapper,
  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      preBillingViewFormats,
    }
  },
  computed: {
    showOtherColumn() {
      return this.format === preBillingViewFormats.TMMarkups
    },
    showMarkupColumn() {
      return this.format !== preBillingViewFormats.TMHistory
    },
    showTotalColumn() {
      return this.format !== preBillingViewFormats.TMHistory
    },
    columns() {
      const employeeLabel = this.format === preBillingViewFormats.TMActualCost
        ? this.$t('Employee')
        : this.$t('Vendor / <br>Employee')
      
      const rateLabel = this.format === preBillingViewFormats.TMActualCost
        ? this.$t('Rate')
        : this.$t('Rate Or <br> Inv. Amount')
      return [
        {
          label: this.$t('Date'),
          prop: 'date',
          component: 'FormattedDate',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: employeeLabel,
          prop: 'business',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Ref No.'),
          prop: 'reference_no',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Description'),
          prop: 'description',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: this.$t('Units <br> LAB/MTL/EQP'),
          prop: 'units',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
        },
        {
          label: rateLabel,
          prop: 'rate_or_inv_amount',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
        },
        {
          label: this.$t('Subtotal $'),
          prop: '_subtotal',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
        },
        {
          label: this.$t('Markup $'),
          prop: 'markup',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
          visible: this.showMarkupColumn,
        },
        {
          label: this.$t('Other $'),
          prop: 'other',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
          visible: this.showOtherColumn,
        },
        {
          label: this.$t('Total $'),
          prop: 'total',
          align: 'right',
          minWidth: 130,
          maxWidth: 160,
          component: 'FormattedPrice',
          hideZero: true,
          visible: this.showTotalColumn,
        },
      ]
    },
    sort_by() {
      return this.filters.sort_by
    },
    format() {
      return this.filters.format
    }
  },
  methods: {
    addJobGroup(jobGroup) {
      this.rows.push({
        header: {
          job: jobGroup.job,
          customer: jobGroup.customer,
          billing_rate: jobGroup.billing_rate,
        }
      })

      this.addJobDetailLines(jobGroup)

      this.addJobTotals(jobGroup)
    },
    addJobDetailLines(jobGroup) {
      if (this.sort_by === PreBillingReportOptions.SortBy.DateCostTypeVendor) {
        this.addItemsSortedByDate(jobGroup)
        return
      }

      if (this.sort_by === PreBillingReportOptions.SortBy.PhaseCostCodeDate) {
        this.addItemsSortedByPhase(jobGroup)
        return
      }

      if (this.sort_by === PreBillingReportOptions.SortBy.CostTypeCodeDate) {
        this.addItemsSortedByCostType(jobGroup)
        return
      }
    },
    addItemsSortedByDate(jobGroup) {
      for (const dateGroup of jobGroup.items) {
        for (const item of dateGroup) {
          this.addDetailLine(item)
        }
      }
    },
    addItemsSortedByPhase(jobGroup) {
      for (const phaseGroup of jobGroup.items) {
        const line_item = phaseGroup.items[0].line_item
        this.rows.push({
          header: {
            line_item,
          }
        })

        for (const item of phaseGroup.items) {
          this.addDetailLine(item)
        }

        const {
          phase,
          cost_code,
          change_order
        } = line_item

        const lineItemDescription = [
          phase,
          cost_code,
          change_order,
        ].map(prop => prop || isNumber(prop) ? prop : '-').join(' / ')

        this.rows.push({
          subtotal: {
            name: this.$t('Phase / Cost Code Totals') +  ` (${lineItemDescription})`,
            totals: phaseGroup.totals,
          }
        })
      }
    },
    addItemsSortedByCostType(jobGroup) {
      for (const costTypeGroup of jobGroup.items) {
        const cost_type = costTypeGroup.items[0].type
        this.rows.push({
          header: {
            cost_type,
          }
        })

        for (const item of costTypeGroup.items) {
          this.addDetailLine(item)
        }

        this.rows.push({
          subtotal: {
            name: this.$t('Cost Type Totals') +  ` (${cost_type.abbr})`,
            totals: costTypeGroup.totals,
          }
        })
      }
    },
    addDetailLine(item) {
      const { type } = item
      const qtyUnit = QuantityLabels[item?.quantity_from] || item?.quantity_from || ''
      const units = item?.quantity
        ? `${type?.abbr || ''} ${this.$formatQuantity(item.quantity)} ${qtyUnit}`
        : ''

      const originalDate = item?.date

      const prevDate = this.rows[this.rows.length - 1]?.originalDate
      const lineDate = originalDate === prevDate ? '' : originalDate

      const lineResult = {
        ...item,
        // Workaround because subtotal prop is conventionally used for adding subtotal rows
        _subtotal: item.subtotal,
        rate_or_inv_amount: item.rate || item.amount,
        date: lineDate,
        originalDate,
        units,
      }

      delete lineResult.subtotal

      this.rows.push(lineResult)
    },
    addJobTotals(jobGroup) {
      this.rows.push({
        subtotal: {
          name: this.$t('Job Totals') +  ` (${jobGroup.job.number})`,
          totals: jobGroup.totals,
        }
      })
    },
    addGrandTotals(data) {
      const grand_total_keys = [
        'subtotal',
        'markup',
        'total',
      ]

      const grand_totals = grand_total_keys.reduce((acc, key) => {

        acc[key] = data.reduce((acc, jobGroup) => {
          acc += jobGroup.totals[key] || 0
          return acc
        }, 0)

        return acc
      }, {})

      this.rows.push({
        subtotal: {
          name: this.$t('Grand Total All Selected Jobs'),
          totals: grand_totals,
        }
      })
    },
    composeRows(data) {
      data.forEach(jobGroup => {
        this.addJobGroup(jobGroup)
      })

      this.addGrandTotals(data)
      return this.rows
    },
  },
}
</script>
