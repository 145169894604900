<template>
  <div class="w-full">
    <label
      v-if="label"
      :for="$attrs.id"
      class="block text-sm font-medium leading-5 text-gray-700 truncate"
    >
      {{ label }}
    </label>
    <BaseRadioGroup
      v-bind="$attrs"
      v-on="$listeners"
      v-model="model"
      :size="size"
      :options="selectTypeOptions"
      :id="$attrs.id || 'eqp-cost-radio'"
      class="eqp-cost-source-radio w-full"
    />
  </div>
</template>
<script setup>
import i18n from "@/i18n";
import { computed, onMounted } from "vue";
import { equipmentCostAddlSources } from "@/components/grid-table/utils/cost-center";
import { getSetting } from "@/plugins/settingsAndEnumsPlugin";
import { $modules } from "@/enum/enums";

const props = defineProps({
  value: {
    type: String,
  },
  label: {
    type: String,
  },
  size: {
    type: String,
    default: 'mini',
  },
})

const emit = defineEmits(['input'])

const model = computed({
  get: () => props.value,
  set: (value) => {
    emit('input', value)
  },
})

const selectTypeOptions = [
  {
    value: equipmentCostAddlSources.MaintenanceCode,
    label: i18n.t('Maint Code'),
  },
  {
    value: equipmentCostAddlSources.RepairOrderEntry,
    label: i18n.t('RO Entry'),
  }
]

function tryAssignDefaultSource() {
  if (model.value) {
    return
  }

  model.value = getSetting('equipment-management', 'default_eqp_cost_addl_source') || equipmentCostAddlSources.MaintenanceCode
}

onMounted(() => {
  tryAssignDefaultSource()
})
</script>
<style lang="scss">
.eqp-cost-source-radio {
  label {
    @apply w-1/2;

    .el-radio-button__inner {
      @apply w-full;
    }
  }
}
</style>
