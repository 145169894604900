import i18n from "@/i18n";
import {
  deleteSelectedRows,
  duplicateRow,
  getCellRangeRowCount,
  insertEmptyRows, TableActions
} from "@/components/ag-grid/tableUtils";
import { $deleteConfirm } from "@/components/common/modal/modalPlugin";

const duplicateIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
</svg>`

const deleteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`
const plusIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"/><line x1="5" y1="12" x2="19" y2="12"/></svg><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`

export function getContextMenu({ params, showAction, props, emit }) {
  if (props.readOnly) {
    return []
  }
  const rowCount = getCellRangeRowCount(params) || 1
  let editingMenus = [
    'separator',
    {
      name: i18n.t('Duplicate Row '),
      action: () => {
        duplicateRow(params, props.mapDuplicateRow)
      },
      icon: duplicateIcon,
      disabled: !showAction('add') || params?.node?.footer,
    },
    {
      name: i18n.tc(`Insert Row Below`, rowCount),
      action: () => {
        insertEmptyRows(params, rowCount, props.getEmptyRow)
      },
      icon: plusIcon,
      disabled: props.allowAddEmptyRows === false || params?.node?.footer
    },
    {
      name: i18n.tc('Delete Rows', rowCount),
      action: () => {
        deleteSelectedRows(params)
      },
      icon: deleteIcon,
      disabled: props.allowTableBulkDelete === false || params?.node?.footer
    },
    {
      name: i18n.tc('Delete Row'),
      action: async () => {
        const confirmed = await $deleteConfirm({
          title: i18n.t('Delete Row'),
          description: i18n.t('Are you sure you want to delete this row?'),
        })
        if (!confirmed || !params.node?.data) {
          return
        }
        params.api.applyTransaction({
          remove: [params.node?.data]
        })
        emit('row-delete', params.node?.data)
      },
      icon: deleteIcon,
      disabled: !props.actions.includes(TableActions.RowDelete) || params?.node?.footer || rowCount > 1
    },
  ]
  editingMenus = editingMenus.filter(menu => menu.disabled !== true)
  return [
    'copy',
    'copyWithHeaders',
    'paste',
    ...editingMenus,
  ]
}
