<template>
  <AgencyForm
    :key="renderKey"
    @create-another="renderKey++"
  />
</template>
<script>
import AgencyForm from '@/modules/payroll/components/AgencyForm'

export default {
  components: {
    AgencyForm,
  },
  data() {
    return {
      renderKey: 1,
    }
  },
}
</script>
