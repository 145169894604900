import {getNestedRoutes} from "@/modules/dashboard/util/routeUtils";
import {isProduction} from "@/isProduction";
import get from "lodash/get";
import {computed} from "vue";
import {can} from "@/modules/auth/plugins/permissionsPlugin";
import {useRouter} from "vue2-helpers/vue-router";

export function useAllRoutes() {
  const router = useRouter()
  const routes = computed(() => {
    let result: any[] = []
    if (router?.options?.routes) {
      router.options.routes.forEach(route => {
        result.push(...getNestedRoutes(route))
      })
    }

    if (isProduction()) {
      result = result.filter(r => get(r, 'meta.productionReady'))
    }

    result = result.filter(r => {
      const permissions = get(r, 'meta.permissions', [])
      const path = get(r, 'path', '')
      if (path.includes(':')) {
        return false
      }
      return can(permissions);
    })

    return result.filter(r => r.title)
  })

  const cleanRoutes = computed(() => {
    return routes.value.map(r => {
      return {
        title: r.title,
        description: r.description,
        path: r.path,
      }
    })
  })

  return {
    cleanRoutes,
    routes
  }
}
