import {resourceStatuses} from "@/enum/enums";
import i18n from "@/i18n";


export const VendorStatus = {
  Active: resourceStatuses.Active,
  Inactive: resourceStatuses.Inactive,
}
export const VendorStatusOptions = [
  {
    label: i18n.t('Active'),
    value: VendorStatus.Active,
  },
  {
    label: i18n.t('Inactive'),
    value: VendorStatus.Inactive,
  },
]

export const VendorTinTypes = {
  EIN: 'ein',
  SSN: 'ssn',
}

export const VendorTinTypeOptions = [
  {
    label: i18n.t('EIN'),
    value: VendorTinTypes.EIN,
  },
  {
    label: i18n.t('SSN'),
    value: VendorTinTypes.SSN,
  },
]
