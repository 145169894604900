<template>
  <base-date-picker
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    :label="$t('Payroll period ending date')"
    :name="$t('Payroll period ending date')"
    :palaceholder="$t('Payroll period ending date')"
    :disabled="disabled"
    :picker-options="{
        cellClassName,
    }"
    id="period_end_date"
    rules="required"
    @change="onPeriodEndDateChange"
  />
</template>
<script>
import parseISO from 'date-fns/parseISO'
import { getPeriodEndDay } from "@/modules/payroll/components/timecard/util";
import axios from "axios";
import { resourceStatuses } from "@/enum/enums";

  export default {
    props: {
      value: {
        type: [String, Object],
      },
      disabled: Boolean,
      year: {
        type: [String, Number],
      },
      showPendingBatchWarning: {
        type: Boolean,
        default: true,
      }
    },
    computed: {
      payroll_period_start_day() {
        return this.$settings(this.$modules.PR, 'payroll_period_start_day')
      },
      model: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      isCorrectPeriodEndDay(date) {
        if (typeof date === 'string') {
          date = parseISO(date)
        }
        const day = date.getDay()
        const startDay = getPeriodEndDay(this.payroll_period_start_day)
        return day === startDay
      },
      cellClassName(date) {
        if (this.isCorrectPeriodEndDay(date)) {
          return 'text-primary-500'
        }
      },
      async onPeriodEndDateChange(date) {
        if (!this.isCorrectPeriodEndDay(date)) {
          this.$error(this.$t('The selected period ending date is different from the one provided in Payroll settings!'))
        }
        const { data } = await axios.get(`/restify/timecard-batches`, {
          params: {
            period_end_date: date,
            year: this.year,
          }
        })
        const matchingBatches = data
        const areBatchesPosted = matchingBatches.length > 0 && matchingBatches.every(batch => {
          return [resourceStatuses.Posted, resourceStatuses.Voided].includes(batch.attributes.status)
        })
        const pendingBatches = matchingBatches.filter(batch => {
          return [resourceStatuses.Pending, resourceStatuses.Computed].includes(batch.attributes.status)
        })

        if (areBatchesPosted) {
          this.$warning(this.$t(`There's already a posted batch with the same period end date.`))
        }
        if (!pendingBatches.length) {
          return
        }
        this.$emit('batches-change', pendingBatches)

        if (!this.showPendingBatchWarning) {
          return
        }
        const matchingBatch = pendingBatches[0]
        const confirmed = await this.$confirm({
          title: this.$t('We found another batch with the same period end date'),
          description: this.$t('There is another batch with the same period end date. Do you want to use it?'),
          buttonText: this.$t('Navigate to batch'),
        })
        if (confirmed) {
          await this.$router.push(`/payroll/timecard-batches/${matchingBatch.id}/view`)
        } else {
          this.model = null
        }
      }
    },
    mounted() {
      if (this.value) {
        this.onPeriodEndDateChange(this.value)
      }
    }
  }
</script>
