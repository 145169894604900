<template>
  <EntityApproveDialog
    v-bind="$attrs"
    v-on="$listeners"
    :entity-name="$t('recurring receivable')"
    :data="billing"
    api-base-path="/restify/recurring-billings"
    :base-path="basePath"
  >
    <RecurringLumpSumBillingDetails
      v-if="billingType === billingTypesAbbr.LumpSum"
      :show-back="false"
      class="col-span-6"
    />
    <RecurringServiceBillingDetails
      v-else-if="billingType === billingTypesAbbr.Service"
      :show-back="false"
      class="col-span-6"
    />
  </EntityApproveDialog>
</template>
<script>
import EntityApproveDialog from "@/modules/common/components/review/EntityApproveDialog.vue";
import RecurringLumpSumBillingDetails from "@/modules/accounts-receivable/pages/recurring-billings/forms/lump-sum/recurring-lump-sum-billing-details.vue";
import RecurringServiceBillingDetails from "@/modules/accounts-receivable/pages/recurring-billings/forms/service/recurring-service-billing-details.vue";
import { billingTypesAbbr } from "@/enum/enums";

export default {
  components: {
    EntityApproveDialog,
    RecurringLumpSumBillingDetails,
    RecurringServiceBillingDetails,
  },
  props: {
    billing: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      billingTypesAbbr,
    }
  },
  computed: {
    billingType() {
      return this.billing?.attributes?.type
    },
    basePath() {
      if (this.billingType === billingTypesAbbr.LumpSum) {
        return `/accounts-receivable/recurring-billings/lump-sum`
      }
      return `/service-billing/recurring-service-invoices`
    }
  },
  watch: {
    billing: {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }
        this.$store.commit('accountsReceivable/SET_CURRENT_RECURRING_BILLING', value)
      },
    }
  }
}
</script>
