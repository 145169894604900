<template>
  <BaseCollapseSection
    key="email-options"
    name="email-options"
  >
    <template #title>
      <div class="flex items-center justify-between w-full">
        <h5 class="form-section-title">
          {{ $t('Email Options') }}
        </h5>
        <BaseSwitch
          v-model="value.send_automatically"
          :label-info="$t('Send Automatically')"
          inline
          id="send_automatically"
          class="mr-4"
          @click.native.stop
          @change="$emit('update:sendAutomatically', $event)"
        />
      </div>
    </template>

    <div class="grid grid-cols-8 gap-2">
      <template v-if="value.send_automatically">
        <BaseInput
          v-model="value.sending_options.sender_email"
          :label="$t('From Email')"
          :tip="$t('Will be used as reply-to email')"
          :placeholder="$t('From Email')"
          rules="required"
          id="sender_email"
          class="col-span-8 lg:w-1/2"
        />
        <ContactSelect
          :key="value.customer_id"
          v-model="value.recipients"
          parentEntityName="customers"
          :disabled="!value.customer_id"
          :name="$t('Recipients')"
          :label="$t('Recipients')"
          :placeholder="value.customer_id
            ? $t('Select or add Contacts')
            : $t('Select a Customer first')"
          :contactableId="value.customer_id"
          multiple
          id="recipients"
          rules="required"
          class="col-span-8 lg:col-span-4"
        />
        <div class="col-span-8 lg:col-span-4">
          <label class="form-label">
            {{ $t('Options') }}
          </label>
          <BaseCheckbox
            v-model="value.sending_options.options.include_pdf"
            :label="$t('Include a PDF version of the invoice')"
            id="include_pdf"
            class="col-span-full"
          />
          <BaseCheckbox
            v-model="value.sending_options.options.include_attachments"
            :label="$t('Include attachments')"
            id="include_attachments"
            class="col-span-full"
          />

          <BaseCheckbox
            v-model="value.sending_options.options.send_a_copy"
            :label="$t('Send a copy to')"
            id="send_a_copy"
            class="col-span-6 mb-4"
          />
        </div>

        <div
          v-if="value.sending_options.options.send_a_copy"
          class="col-span-8 lg:w-1/2"
        >
          <BaseInput
            v-model="value.sending_options.options.bcc_email"
            :label="$t('BCC Email')"
            :placeholder="$t('Email address to send a copy to')"
            rules="required|email"
            id="bcc_email"
          />
        </div>
        <BaseSelect
          v-model="selectedTemplate"
          :label="$t('Template')"
          :options="availableTemplates"
          value-key="id"
          label-key="name"
          class="col-span-8 lg:col-span-4"
          rules="required"
          @change="onTemplateChange"
        />
        <BillingPrintOptions
          :billing="billing"
          :billingType="billingType"
          :data="value.sending_options"
          :selectedTemplateObj="selectedTemplateObject"
          class="col-span-8 lg:col-span-4"
        />
      </template>
    </div>
  </BaseCollapseSection>
</template>
<script>
import BillingPrintOptions from '@/modules/accounts-receivable/components/billings/BillingPrintOptions.vue';
import { set } from 'lodash';
import { usePrintTemplates } from "@/components/form/composables/usePrintTemplates";

export default {
  components: {
    BillingPrintOptions,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    billing: {
      type: Object,
      required: true,
    },
    billingType: {
      type: String,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const {
      availableTemplates,
      selectedTemplate,
      selectedTemplateObject,
      onTemplateChange,
    } = usePrintTemplates({ value: props.entity })

    return {
      availableTemplates,
      selectedTemplate,
      selectedTemplateObject,
      onTemplateChange,
    }
  },
  methods: {
    initDefaultOptions() {
      if (this.value?.sending_options?.sender_email) {
        return
      }

      const companyEmail =  this.$store.state.company.activeCompany?.email
      const userEmail = this.$store.state.auth.user.email
 
      set(this.value.sending_options, 'sender_email', companyEmail || userEmail)
    }
  },
  watch: {
    selectedTemplate: {
      immediate: true,
      handler(value) {
        set(this.value.sending_options, 'template_id', value)
      },
    }
  },
  mounted() {
    this.initDefaultOptions()
  }
}
</script>
