<template>
  <validation-provider :rules="$attrs.rules"
                       :name="$attrs.name || label"
                       v-slot="{ errors }">
    <div>
      <label
          v-if="hasLabel"
          :for="$attrs.id"
          class="block text-sm font-medium leading-5 text-gray-700 truncate">
        <slot name="label">
          <div class="flex items-center">
            <span v-html="label"
                  :class="labelClass"
                  class="truncate"/>
            <span v-if="isRequired"
                  class="text-gray-500">
                *
              </span>
            <base-tooltip v-if="tip" :content="tip" :tabindex="-1">
              <HelpCircleIcon class="ml-2 w-4 h-4 text-gray-500 hover:text-gray-700 cursor-help"/>
            </base-tooltip>
          </div>
        </slot>
      </label>

      <slot name="container"
            :errors="errors">
        <div class="relative rounded-md">
          <span v-if="$slots.prefix || format"
                class="absolute inset-y-0 left-0 pl-3 flex items-center z-10"
                :class="{'mb-5': !inlineErrors}"
          >
              <span class="text-gray-500 sm:text-sm sm:leading-5">
                  <slot name="prefix">
                    <template v-if="format === 'price'">
                      {{ $t('$') }}
                    </template>
                    <template v-if="format === 'percent'">
                      {{ $t('%') }}
                    </template>
                    <template v-if="format === 'hours'">
                      <span class="-ml-1">⏱️</span>
                    </template>
                  </slot>
              </span>
          </span>
          <base-input-error :errors="errors"
                            :show-tooltip="inlineErrors">
            <input v-bind="$attrs"
                   ref="input"
                   :value="displayValue"
                   :type="type"
                   :name="$attrs.name || label"
                   :tabindex="skipFocus ? -1 : 0"
                   :spellcheck="true"
                   @focus="onFocus"
                   @blur="onBlur"
                   v-on="listeners"
                   :class="{
                      'form-input-error': errors.length,
                      'pl-8': hasPrefix,
                      'pr-8': hasSuffix,
                      'pr-24': inlineDropdown,
                      'bg-gray-100 cursor-not-allowed text-gray-400': $attrs.disabled,
                      'cursor-not-allowed bg-gray-100 focus:shadow-none focus:border-transparent': $attrs.readonly !== undefined && $attrs.readonly !== false,
                      'py-1': size === 'sm',
                   }"
                   class="form-input block w-full sm:text-sm sm:leading-5"/>

          </base-input-error>
          <div v-if="hasSuffix"
               class="absolute inset-y-0 right-0 pr-3 flex items-center z-10"
               :class="{'mb-5': !inlineErrors}"
          >
            <span class="text-gray-500 sm:text-sm sm:leading-5">
                <slot name="suffix"></slot>
                <IconEmail v-if="isEmailType"
                           class="w-4 h-4"/>
            </span>
            <XCircleIcon v-if="value && clearable"
                         class="w-4 h-4 text-gray-400 cursor-pointer"
                         @click.prevent.stop="clearInput"
            />
          </div>

          <div v-if="$slots['prefix-icon']"
               class="absolute inset-y-0 left-0 pl-3 flex items-center">
            <slot name="prefix"/>
          </div>
          <div v-if="$slots['suffix-icon'] || clearable"
               class="absolute inset-y-0 right-0 pr-3 flex items-center">
            <slot name="suffix"></slot>
          </div>

          <div v-if="error"
               class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
        </div>
      </slot>
    </div>
  </validation-provider>
</template>
<script>
  import { HelpCircleIcon, XCircleIcon } from 'vue-feather-icons'

  export default {
    components: {
      XCircleIcon,
      HelpCircleIcon,
    },
    inheritAttrs: false,
    props: {
      value: {
        type: [String, Number, Date],
        default: '',
      },
      type: {
        type: [String, Number],
        default: 'text',
      },
      label: {
        type: String,
        default: '',
      },
      labelClass: {
        type: [String, Object, Array],
        default: ''
      },
      tip: {
        type: String,
        default: '',
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      inlineErrors: {
        type: Boolean,
        default: false,
      },
      skipFocus: {
        type: Boolean,
        default: false,
      },
      format: {
        type: String,
        default: '', // price|percent|hours
      },
      size: {
        type: String,
        default: 'md',
      },
    },
    data() {
      return {
        error: '',
        isFocused: false,
        displayValue: this.value,
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.onInput,
          change: this.onChange,
        }
      },
      hasLabel() {
        return (this.label || this.$slots.label) && !this.$attrs['hide-label']
      },
      hasPrefix() {
        return this.$slots.prefix || this.format
      },
      hasSuffix() {
        return this.$slots.suffix || this.isEmailType || (this.clearable && this.value)
      },
      isEmailType() {
        return this.type === 'email'
      },
      inlineDropdown() {
        return this.$attrs['inline-dropdown']
      },
      isRequired() {
        const rules = this.$attrs.rules
        if (!rules) {
          return false
        }
        return rules?.includes && rules?.includes('required') || rules?.required
      },
      isNumberInput() {
        return this.type === 'number'
      },
    },
    methods: {
      onFocus() {
        this.isFocused = true
        if (this.type !== 'number') return
        if (parseFloat(this.value) === 0) {
          this.displayValue = ''
          this.$emit('input', '')
        }
      },
      focus() {
        this.$refs.input?.focus?.()
      },
      onBlur() {
        this.isFocused = false
        if (this.type !== 'number') return
        if (this.value === '') {
          this.$emit('input', 0)
        }
        if (this.isNumberInput) {
          this.$emit('input', Number(this.value))
        }
        this.formatDisplayValue()
      },
      getEventValue(evt) {
        let value = evt.target.value
        return value
      },
      onChange(evt) {
        this.$emit('change', this.getEventValue(evt), evt)
      },
      onInput(evt) {
        const value = this.getEventValue(evt)
        this.displayValue = value
        this.$emit('input', value)
      },
      clearInput() {
        this.$emit('input', '')
        this.$emit('clear', '')
      },
      formatDisplayValue() {
        if (this.type !== 'number') return
        if (!['percent', 'price', 'hours'].includes(this.format)) {
          return this.displayValue = this.value
        }
        const toFormat = this.value || 0

        if (this.format === 'hours') {
          this.displayValue = this.$formatHours(toFormat)
          return
        }

        const fractionDigits = this.$attrs.fractionDigits || 2
        this.displayValue = parseFloat(toFormat).toFixed(fractionDigits)
      },
    },
    mounted() {
      this.formatDisplayValue()
    },
    watch: {
      value(val) {
        if (!this.isFocused) {
          this.displayValue = val
          this.formatDisplayValue()
        }
      },
    },
  }
</script>
<style lang="scss">
  .form-input-error {
    @apply border-red-600 bg-red-50;

    &:focus {
      @apply border-red-600 bg-white ring-red-500;
    }
  }

  input::placeholder {
    @apply text-gray-400;
  }
</style>
