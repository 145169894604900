<template>
  <div class="flex flex-col">
    <div class="flex h-full items-start">
      <EmployeeLink
        v-if="data.employee"
        :id="data?.employee_id"
        :show-name-only="true"
      />
      <div v-else class="font-medium">
        {{ data.name }}
      </div>
      <div v-if="data.employee?.is_leader" class="ml-2">
        {{$t(' (Foreman)')}}
      </div>
    </div>
    <base-tooltip v-if="totalDuration">
      <template #content>
        <template v-if="totalLoggedDuration > totalDuration">
          <div>{{$t('The logged time is greater than the scheduled time')}}</div>
          <div class="mt-1">{{$t('Logged Time')}}: {{formattedLoggedDuration}}</div>
          <div class="mt-1">{{$t('Scheduled Time')}}: {{formattedDuration}}</div>
        </template>
      </template>
      <div
        class="absolute right-[8px] bottom-[8px] flex flex-col items-end justify-end text-gray-500 text-xs"
      >
      <span
        :class="{
          'text-red-600': isOverTime || totalLoggedDuration > totalDuration
        }"
      >
        <template v-if="totalLoggedDuration">
          {{ formattedLoggedDuration }} /
        </template>
        {{ formattedDuration }} hrs
      </span>
      </div>
    </base-tooltip>
  </div>
</template>
<script>

import { getDurationForEvents, getLoggedDurationForEvents } from "@/modules/payroll/components/tasks/taskUtils";
import { minutesToHours } from "@/modules/payroll/utils/timeCardUtils";

export default {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  computed: {
    viewContext() {
      return this.resource._context?.viewApi || {}
    },
    isDayView() {
      return this.viewContext.type === 'resourceTimelineDay'
    },
    isWeekView() {
      return this.viewContext.type === 'resourceTimelineWeek'
    },
    calendarStart() {
      return this.viewContext.activeStart
    },
    calendarEnd() {
      return this.viewContext.activeEnd
    },
    data() {
      return this.resource?.extendedProps || {}
    },
    visibleEvents() {
      return this.resource.getEvents().filter(this.isEventVisible)
    },
    totalDuration() {
      return getDurationForEvents(this.visibleEvents || [])
    },
    totalLoggedDuration() {
      return getLoggedDurationForEvents(this.visibleEvents || [])
    },
    formattedDuration() {
      return minutesToHours(this.totalDuration)
    },
    formattedLoggedDuration() {
      return minutesToHours(this.totalLoggedDuration)
    },
    isOverTime() {
      const dayOvertime = 8 * 60
      const weekOvertime = 5 * dayOvertime
      if (this.isDayView) {
        return this.totalDuration > dayOvertime
      }
      if (this.isWeekView) {
        return this.totalDuration > weekOvertime
      }
      return false
    }
  },
  methods: {
    isEventVisible(event) {
      if (event.start > this.calendarEnd || event.end < this.calendarStart) {
        return false
      }
      return true
    }
  }
}
</script>
<style>
.fc-datagrid-cell-frame {
  @apply relative;
}
</style>
