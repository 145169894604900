<template>
  <div class="group">
    <BaseTooltip :content="$t('Real Time Support')">
      <BaseButton
        :variant="connected ? 'primary': 'gray-light'"
        size="sm"
        @click="startRealTimeSupport"
      >
        <MonitorIcon class="w-4 h-4"/>
      </BaseButton>
    </BaseTooltip>
    <section v-show="showButtons" class="control-tray">
      <canvas style="display: none" ref="renderCanvasRef"></canvas>
      <video
        ref="videoRef"
        autoplay
        playsinline
        class="hidden"
      />
      <div class="absolute top-0 right-0 opacity-0 group-hover:opacity-100" @click="showButtons = false">
        <CloseButton size="sm"></CloseButton>
      </div>
      <nav class="actions-nav space-x-2">
        <BaseButton
          btn-class="w-12 h-12 rounded-xl"
          :disabled="!connected"
          :variant="connected ? 'danger-light': 'white'"
          @click="muted = !muted">
          <component :is="muted ? MicOffIcon : MicIcon" class="w-4 h-4"/>
        </BaseButton>

        <div class="action-button no-action outlined">
          <AudioPulse :volume="volume" :active="connected" :hover="false"/>
        </div>

        <BaseTooltip
          :content="screenCapture.isStreaming.value ? $t('Share current page information'): $t('Stop sharing current page information')"
          :disabled="!connected"
        >
          <BaseButton
            btn-class="w-12 h-12 rounded-xl"
            :disabled="!connected"
            :variant="shareCurrentPage ? 'primary-light': 'white'"
            @click="shareCurrentPage = !shareCurrentPage"
          >
            <component :is="shareCurrentPage ? MonitorXIcon: MonitorUpIcon" class="w-5 h-5"/>
          </BaseButton>
        </BaseTooltip>

        <template v-if="supportsVideo">
          <BaseTooltip
            :content="screenCapture.isStreaming.value ? $t('Stop screen sharing'): $t('Start screen sharing')"
            :disabled="!connected"
          >
            <BaseButton
              btn-class="w-12 h-12 rounded-xl"
              :disabled="!connected"
              :variant="screenCapture.isStreaming.value ? 'primary-light': 'white'"
              @click="screenCapture.isStreaming.value ? changeStreams(): changeStreams(screenCapture)"
            >
              <component :is="screenCapture.isStreaming.value ? ScreenShareStopIcon: ScreenShareIcon" class="w-5 h-5"/>
            </BaseButton>
          </BaseTooltip>
        </template>

        <slot></slot>
      </nav>

      <div class="ml-2">
        <BaseTooltip :content="connected ? $t('Real Time Support Ongoing. Click to stop the session.'): $t('Click to start a Real Time Support Session')"
        >
          <BaseButton
            :variant="connected ? 'primary-light': 'white'"
            btn-class="w-12 h-12 rounded-xl"
            @click="connected ? disconnect() : connect()"
          >
            <component :is="connected ? PauseIcon: PlayIcon" class="w-5 h-5"/>
          </BaseButton>
        </BaseTooltip>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import {onUnmounted, PropType, Ref, ref, watch} from "vue";
import MediaStreamButton from "./MediaStreamButton.vue";
import useLiveApi from "@/modules/support/composables/useLiveApi";
import {AudioRecorder} from "@/modules/support/lib/audio-recorder";
import {useScreenCapture} from "@/modules/support/composables/use-screen-capture";
import {useWebcam} from "@/modules/support/composables/use-webcam";
import {UseMediaStreamResult} from "@/modules/support/composables/use-media-stream-mux";
import AudioPulse from "@/modules/support/components/AudioPulse.vue";
import {MicIcon, MicOffIcon, PauseIcon, PlayIcon, MonitorIcon, ShareIcon} from "vue-feather-icons";
import ScreenShareIcon from "@/modules/support/components/ScreenShareIcon.vue";
import ScreenShareStopIcon from "@/modules/support/components/ScreenShareStopIcon.vue";
import {useRoute} from "vue2-helpers/vue-router";
import i18n from "@/i18n";
import {$confirm} from "@/components/common/modal/modalPlugin";
import MonitorXIcon from "@/modules/support/components/MonitorXIcon.vue";
import MonitorUpIcon from "@/modules/support/components/MonitorUpIcon.vue";
import CloseButton from "@/components/common/CloseButton.vue";
import {onToolCall} from "@/modules/support/lib/tools";
import {ToolCall} from "@/modules/support/types/multimodal-live-types"; // Separate component
const props = defineProps({
  videoRef: Object as PropType<Ref<HTMLVideoElement>>,
  supportsVideo: Boolean,
})

const videoStreams = [useWebcam(), useScreenCapture()];
const [webcam, screenCapture] = videoStreams;

const activeVideoStream = ref<MediaStream | null>(null);
const inVolume = ref(0);
const audioRecorder = ref(new AudioRecorder());
const muted = ref(false);
const renderCanvasRef = ref<HTMLCanvasElement | null>(null);

const showButtons = ref(false)

const {client, connected, connect, disconnect, volume} = useLiveApi();

watch(inVolume, (newVal) => {
  document.documentElement.style.setProperty(
    "--volume",
    `${Math.max(5, Math.min(newVal * 200, 8))}px`
  );
});

const shareCurrentPage = ref(false)

function sendCurrentPageContents() {
  if (!connected.value || !shareCurrentPage.value) {
    return
  }
  setTimeout(() => {
    const currentPageText = document.body.innerText
    client.value.send([{ text: `Current page contents: ${currentPageText}` }])
  }, 3000)
}

const route = useRoute()
watch(() => route.path, async () => {
  sendCurrentPageContents()
})
watch(() => shareCurrentPage.value, async () => {
  sendCurrentPageContents()
})

watch([connected, muted, audioRecorder], ([isConnected, isMuted, recorder]) => {
  const onData = (base64: string) => {
    client.value.sendRealtimeInput([
      {mimeType: "audio/pcm;rate=16000", data: base64},
    ]);
  };

  if (isConnected && !isMuted && recorder) {
    recorder.on("data", onData).on("volume", (vol) => (inVolume.value = vol)).start();
  } else {
    recorder.stop();
  }

  return () => {
    recorder.off("data", onData).off("volume", (vol) => (inVolume.value = vol));
  };
});

const videoRef = ref<HTMLVideoElement>()

watch([connected, activeVideoStream], ([isConnected, stream]) => {
  if (videoRef?.value) {
    videoRef.value.srcObject = stream;
  }

  let timeoutId = -1;
  sendCurrentPageContents()

  function sendVideoFrame() {
    const video = videoRef?.value;
    const canvas = renderCanvasRef.value;
    if (!video || !canvas) return;

    const ctx = canvas.getContext("2d");
    canvas.width = video.videoWidth * 0.25;
    canvas.height = video.videoHeight * 0.25;
    if (canvas.width + canvas.height > 0) {
      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      const base64 = canvas.toDataURL("image/jpeg", 1.0);
      const data = base64.slice(base64.indexOf(",") + 1);
      client.value.sendRealtimeInput([{mimeType: "image/jpeg", data}]);
    }

    if (isConnected) {
      timeoutId = window.setTimeout(sendVideoFrame, 1000 / 0.5);
    }
  }

  if (isConnected && stream !== null) {
    requestAnimationFrame(sendVideoFrame);
  }

  return () => {
    clearTimeout(timeoutId);
  };
});

const emit = defineEmits(["videoStreamChange"]);

async function changeStreams(next?: UseMediaStreamResult) {
  if (next) {
    const mediaStream = await next.start();
    activeVideoStream.value = mediaStream;
    emit('videoStreamChange', mediaStream);
  } else {
    activeVideoStream.value = null;
    emit('videoStreamChange', null);
  }

  videoStreams.filter((msr) => msr !== next).forEach((msr) => msr.stop());
}

async function startRealTimeSupport() {
  if (connected.value) {
    showButtons.value = true
    return
  }
  const confirmed = await $confirm({
    title: i18n.t('Start Real Time Support (Beta)'),
    description: i18n.t('Real Time Support is currently in beta. The sessions are not recorded and are not stored but they are limited to 10 minutes. For optimal results make sure your microphone is connected and we recommend sharing your screen during the session.'),
    buttonText: i18n.t('Start Real Time Support Session'),
  })
  if (!confirmed) {
    return
  }
  showButtons.value = !showButtons.value
  await connect()
}

function toolCall(toolCall: ToolCall) {
  onToolCall(toolCall, client)
}

watch(() => connected.value, (value) => {
  if (value) {
    client.value?.on('toolcall', toolCall);
  } else {
    client.value?.off('toolcall', toolCall);
  }
}, { immediate: true })

onUnmounted(() => {
  client.value?.off('toolcall', toolCall);
});

</script>

<style lang="scss">
.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply bg-gray-200;
  color: var(--Neutral-60);
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-transform: lowercase;
  cursor: pointer;
  animation: opacity-pulse 3s ease-in infinite;
  transition: all 0.2s ease-in-out;
  width: 48px;
  height: 48px;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0);
  user-select: none;
  cursor: pointer;

  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Neutral-80);
  }

  &.outlined {
    background: var(--Neutral-2);
    border: 1px solid var(--Neutral-20);
  }

  .no-action {
    pointer-events: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0);
    border: 1px solid var(--Neutral-20);
  }

  &.connected {
    background: var(--Blue-800);
    color: var(--Blue-500);

    &:hover {
      border: 1px solid var(--Blue-500);
    }
  }
}

@property --volume {
  syntax: "length";
  inherit: false;
  initial-value: 0px;
}

.disabled .mic-button,
.mic-button.disabled {
  &:before {
    background: rgba(0, 0, 0, 0);
  }
}

.mic-button {
  position: relative;
  background-color: var(--accent-red);
  z-index: 1;
  color: black;
  transition: all 0.2s ease-in;

  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Red-500);
  }

  &:hover {
    background-color: var(--Red-400);
  }

  &:before {
    position: absolute;
    z-index: -1;
    top: calc(var(--volume) * -1);
    left: calc(var(--volume) * -1);
    display: block;
    content: "";
    opacity: 0.35;
    background-color: var(--Red-500);
    width: calc(100% + var(--volume) * 2);
    height: calc(100% + var(--volume) * 2);
    border-radius: 24px;
    transition: all 0.02s ease-in-out;
  }
}

.connect-toggle {
  &:focus {
    border: 2px solid var(--Neutral-20);
    outline: 2px solid var(--Neutral-80);
  }

  &:not(.connected) {
    background-color: var(--Blue-500);
    color: var(--Neutral-5);
  }
}

.control-tray {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  gap: 4px;
  @apply border bg-white border-gray-300 rounded-t-md py-4 px-6 shadow-lg flex justify-center items-start;

  .disabled .action-button,
  .action-button.disabled {
    background: rgba(0, 0, 0, 0);
    border: 1px solid var(--Neutral-30, #404547);
    color: var(--Neutral-30);
  }

  .connection-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;


  }
}

.actions-nav {
  display: inline-flex;
  align-items: center;
  overflow: clip;

  transition: all 0.6s ease-in;
}

@keyframes opacity-pulse {
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.9;
  }
}

</style>
