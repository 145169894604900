<template>
 <PeriodCostIncomeSummaryReport />
</template>
<script>
import PeriodCostIncomeSummaryReport from '@/modules/job-costing/components/reports/PeriodCostIncomeSummaryReport'

export default {
  components: {
    PeriodCostIncomeSummaryReport,
  },
}
</script>
